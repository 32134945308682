import styled from "../Themes";

export const BlockIconContainer = styled<any>(`div`)`
  fill: ${({ theme }) => theme.color.font_bold[2]};
  float: left;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BlockSpan = styled<any>(`span`)`
  color: ${({ theme }) => theme.color.font_bold[2]};
  line-height: 40px;
`;

export const Block = styled<any>(`div`)`
  background-color: ${({ theme }) => theme.color_15.primary[0]};
  border: 1px solid ${({ theme }) => theme.color_50.primary[2]};
  color: ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 3px;
  text-align: center;
  margin: 16px;
  padding: 26px 12px;
  width: 240px;
  transition: all 250ms;

  @media (max-width: 544px) {
    width: 200px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color_30.primary[0]};
    border: 1px solid ${({ theme }) => theme.color.primary[2]};
  }
`;

export const UpdateImageButton = styled(`div`)`
  position: absolute;
  bottom: -5px;
  right: -5px;
  background: ${({ theme }) => theme.color.main_bg[2]};
  fill: ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 50%;
  padding: 5px;
`;

export const UpdateImageButtonContainer = styled(`div`)`
  border: 1px solid ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;

  &:hover {
    fill: ${({ theme }) => theme.color.primary[2]};
    border: 1px solid ${({ theme }) => theme.color.primary[2]};
  }
`;

export const CameraIconContainer = styled(`div`)`
  width: 16px;
  height: 16px;
  margin-top: -1px;
`;
