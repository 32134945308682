import React, { FC } from "react";

const DeleteIcon: FC<any> = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51.976 51.976">
    <path
      fill={fill}
      fillOpacity={0.6}
      d="M44.373 7.603c-10.137-10.137-26.632-10.138-36.77 0-10.138 10.138-10.137 26.632 0 36.77s26.632 10.138 36.77 0c10.137-10.138 10.137-26.633 0-36.77zm-8.132 28.638a2 2 0 0 1-2.828 0l-7.425-7.425-7.778 7.778a2 2 0 1 1-2.828-2.828l7.778-7.778-7.425-7.425a2 2 0 1 1 2.828-2.828l7.425 7.425 7.071-7.071a2 2 0 1 1 2.828 2.828l-7.071 7.071 7.425 7.425a2 2 0 0 1 0 2.828z"
    />
    <path
      fill={fill}
      fillOpacity={0.9}
      d="M26 0C11.664 0 0 11.663 0 26s11.664 26 26 26 26-11.663 26-26S40.336 0 26 0zm0 3.25c12.544 0 22.75 10.205 22.75 22.75S38.544 48.75 26 48.75 3.25 38.545 3.25 26 13.456 3.25 26 3.25z"
    />
  </svg>
);

export default DeleteIcon;
