import React, { FC } from "react";

type Props = {
  url: string;
  label?: string;
  target?: string;
  rel?: string;
  style?: object;
};

const ExternalLink: FC<Props> = ({ url, label, target = "_blank", rel = "noopener noreferrer", style = {} }) => {
  const href = url.startsWith("http") ? url : "https://" + url;

  return (
    <a style={style} target={target} rel={rel} href={href}>
      {label !== undefined ? label : url}
    </a>
  );
};

export default ExternalLink;
