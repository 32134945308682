import React, { FC } from "react";
import { BreadcrumbsContainer, BreadcrumbLink, BreadcrumbPage, LeftContent, RightContent } from "./styles";

const PageBreadcrumbs: FC<any> = ({ height, prevRoutes, currRoute, rightContent }) => {
  return (
    <BreadcrumbsContainer height={height}>
      <LeftContent>
        {prevRoutes.map((route: any) => (
          <span key={route.slug}>
            <BreadcrumbLink to={route.slug}>{route.title}</BreadcrumbLink>
            {" / "}
          </span>
        ))}
        <BreadcrumbPage>{currRoute}</BreadcrumbPage>
      </LeftContent>
      <RightContent>{rightContent}</RightContent>
    </BreadcrumbsContainer>
  );
};

export default PageBreadcrumbs;
