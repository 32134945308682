import React, { FC, useContext, useEffect, useState } from "react";
import { InfoModal } from "../Modal";
import Table from "../Table";
import matchSorter from "match-sorter";
import axios from "axios";
import { ThemeContext } from "styled-components";
import { fetchBatch } from "../../services/editBatch";
import errToStr from "../../util/errToStr";
import { Link } from "react-router-dom";
import { kegsOrTrackers, kegOrTracker } from "../../util/kegOrTracker";
import moment from "moment";
import FlatpickrRangePicker from "../FlatpickrRangePicker";
import Bold from "../Bold";
import LoadingContainer from "../LoadingContainer";

const BeerBatchInfoModal: FC<any> = ({ modalOpen, setModalOpen, batchId }) => {
  const { short_datetime, short_date } = useContext(ThemeContext);

  const [data, setData] = useState<any>({ batch: {}, kegs: [] });
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (batchId !== undefined) {
      setDataLoading(true);
      setDataErr("");

      fetchBatch(source, {}, undefined, batchId)
        .then((response) => {
          if (response.data) {
            const { kegsFull, kegsEmptied, ...batch } = response.data;
            if (kegsFull !== undefined && kegsEmptied !== undefined) {
              const kegs = [...kegsFull, ...kegsEmptied];
              setData({ batch, kegs });
            }
          }
          setDataLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setData({ batch: {}, kegs: [] });
            setDataErr(errToStr(err));
            setDataLoading(false);
          }
        });
    }

    return () => {
      source.cancel();
    };
  }, [batchId]);

  const columns = [];

  columns.push({
    id: "sensorId",
    Header: "Tracker ID",
    accessor: "sensorId",
    minWidth: 160,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["sensorId"],
      }),
    filterAll: true,
    Cell: (props: any) => {
      const url = kegOrTracker("kegs", "trackers");
      return (
        <Link onClick={() => setModalOpen(false)} title={props.value} to={`/${url}/${props.original.sensorId}`}>
          {props.value}
        </Link>
      );
    },
  });

  columns.push({
    id: "dateFilled",
    Header: "Date Filled",
    accessor: "dateFilled",
    minWidth: 180,
    maxWidth: 180,
    filterMethod: (filter: any, row: any) => {
      if (filter.value.length === 2) {
        if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
        else return false;
      } else return true;
    },
    Filter: ({ onChange }: any) => (
      <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
    ),
    Cell: (props: any) => (props.value ? moment.unix(props.value).format(short_datetime) : ""),
  });

  columns.push({
    id: "dateEmptied",
    Header: "Date Emptied",
    accessor: "dateEmptied",
    minWidth: 180,
    maxWidth: 180,
    filterMethod: (filter: any, row: any) => {
      if (filter.value.length === 2) {
        if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
        else return false;
      } else return true;
    },
    Filter: ({ onChange }: any) => (
      <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
    ),
    Cell: (props: any) => (props.value ? moment.unix(props.value).format(short_datetime) : "-"),
  });

  const defaultSorted = [
    {
      id: "dateFilled",
      desc: true,
    },
  ];

  return (
    <InfoModal
      isOpen={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      title="Beer Batch Details"
      okayBtnText="Close"
      okayBtnProps={{ width: "100%" }}
      body={
        <LoadingContainer loading={dataLoading} err={dataErr}>
          <div>
            {data.batch && (
              <>
                <p>
                  This batch of <Bold>{data.batch.beerName}</Bold> was brewed at{" "}
                  <Link to={`/places/${data.batch.placeId}`}>
                    <Bold>{data.batch.placeName}</Bold>
                  </Link>{" "}
                  on <Bold>{moment.unix(data.batch.dateCreated).format(short_datetime)}</Bold>
                  <br />
                </p>
                {data.batch.notes && (
                  <>
                    <br />
                    <p>
                      <Bold>Notes:</Bold> {data.batch.notes}
                    </p>
                    <br />
                  </>
                )}
              </>
            )}
            <Table
              loading={dataLoading}
              filterable={true}
              style={{ clear: "both" }}
              data={data.kegs}
              columns={columns}
              defaultSorted={defaultSorted}
              defaultPageSize={15}
            />
          </div>
        </LoadingContainer>
      }
    />
  );
};

export default BeerBatchInfoModal;
