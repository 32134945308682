import axios, { CancelTokenSource } from "axios";
import { FC, useEffect, useState } from "react";
import { postGiveFeedback } from "../../services/giveFeedback";
import errToStr from "../../util/errToStr";
import { exists, maxLength, minLength } from "../../util/formValidations";
import { FormError, FormTextareaInput } from "../FormComponents";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";

const PhoneScanFeedbackModal: FC<any> = ({ scanCode, modalOpen, setModalOpen }) => {
  const [formData, setFormData] = useState<any>({ feedback: "" });
  const [formErrors, setFormErrors] = useState<any>({});

  const [location, setLocation] = useState<any>(undefined);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (pos: any) => {
        const { coords } = pos;
        setLocation({
          lat: coords.latitude,
          lng: coords.longitude,
          accuracy: coords.accuracy,
        });
      },
      () => {
        setLocation({
          lat: undefined,
          lng: undefined,
          accuracy: undefined,
        });
      }
    );
  }, []);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "feedback":
          currValid = exists(name, value, setFormErrors) && minLength(name, value, 1, setFormErrors) && maxLength(name, value, 4096, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const handleSubmit = () => {
    const body = {
      scanCode: scanCode,
      Feedback: formData.feedback,
      PhoneLatitude: location.lat,
      PhoneLongitude: location.lng,
      PhoneAccuracy: Math.round(location.accuracy) || null,
    };
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      postGiveFeedback(source, body)
        .then(() => {
          setSubmittedMsg("Feedback Submitted");
          setSubmittingErr("");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };
  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      title="Leave Feedback"
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Submit Feedback"
      body={
        <LoadingContainer loading={submitting}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer>
              <label>Feedback</label>
              <FormTextareaInput
                name="feedback"
                rows={6}
                maxLength={512}
                value={formData.feedback}
                error={formErrors.feedback}
                onChange={handleChange}
                placeholder="e.g. the tracker is damaged"
              />
              <FormError error={formErrors.feedback}>{formErrors.feedback}</FormError>
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default PhoneScanFeedbackModal;
