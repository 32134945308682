import { createGlobalStyle } from "../Themes";

export default createGlobalStyle`
.tippy-popper {
	z-index: 9999999 !important;
}

.tippy-content {
	text-align: center;
}

.tippy-tooltip.binary-theme {
	background-color: ${({ theme }) => theme.color.tooltip_bg[2]};
	background-clip: padding-box;
	border: 1px solid ${({ theme }) => theme.color.border[2]};
	color: ${({ theme }) => theme.color.font_bold[2]};
	box-shadow: 0 1px 14px 0 ${({ theme }) => theme.color.tooltip_shadow[2]};
}

.tippy-tooltip.binary-theme>.tippy-backdrop {
	background-color: ${({ theme }) => theme.color.tooltip_bg[2]};
}

.tippy-tooltip.binary-theme>.tippy-arrow:after,
.tippy-tooltip.binary-theme>.tippy-arrow:before,
.tippy-tooltip.binary-theme>.tippy-svg-arrow:after,
.tippy-tooltip.binary-theme>.tippy-svg-arrow:before {
	content: "";
	position: absolute;
	z-index: -1
}

.tippy-tooltip.binary-theme>.tippy-svg-arrow {
	fill: ${({ theme }) => theme.color.tooltip_bg[2]};
}

.tippy-tooltip.binary-theme>.tippy-svg-arrow:after {
	background-image: url(data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEuNDE0IiB2aWV3Qm94PSIwIDAgMTggNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCA3czIuMDIxLS4wMTUgNS4yNTMtNC4yMThDNi41ODQgMS4wNTEgNy43OTcuMDA3IDkgMGMxLjIwMy0uMDA3IDIuNDE2IDEuMDM1IDMuNzYxIDIuNzgyQzE2LjAxMiA3LjAwNSAxOCA3IDE4IDd6IiBmaWxsPSIjMzMzIiBmaWxsLW9wYWNpdHk9Ii4yMzUiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
	background-size: 18px 7px;
	width: 18px;
	height: 7px;
	left: 0;
	top: 0;
	fill: rgba(0, 8, 16, .15)
}

.tippy-tooltip.binary-theme[data-placement^=top]>.tippy-svg-arrow:after {
	top: 1px;
	transform: rotate(180deg)
}

.tippy-tooltip.binary-theme[data-placement^=top]>.tippy-arrow {
	border-top-color: ${({ theme }) => theme.color.tooltip_bg[2]};
}

.tippy-tooltip.binary-theme[data-placement^=top]>.tippy-arrow:after {
	border-top: 7px solid ${({ theme }) => theme.color.tooltip_bg[2]};
	top: -7px
}

.tippy-tooltip.binary-theme[data-placement^=top]>.tippy-arrow:before {
	border-top: 7px solid ${({ theme }) => theme.color.border[2]};
	bottom: -1px
}

.tippy-tooltip.binary-theme[data-placement^=bottom]>.tippy-svg-arrow:after {
	top: -1px
}

.tippy-tooltip.binary-theme[data-placement^=bottom]>.tippy-arrow {
	border-bottom-color: ${({ theme }) => theme.color.tooltip_bg[2]};
}

.tippy-tooltip.binary-theme[data-placement^=bottom]>.tippy-arrow:after {
	border-bottom: 7px solid ${({ theme }) => theme.color.tooltip_bg[2]};
	bottom: -7px
}

.tippy-tooltip.binary-theme[data-placement^=bottom]>.tippy-arrow:before {
	border-bottom: 7px solid ${({ theme }) => theme.color.border[2]};
	bottom: -6px
}

.tippy-tooltip.binary-theme[data-placement^=left]>.tippy-svg-arrow:after {
	left: 1px;
	top: 0;
	transform: rotate(90deg)
}

.tippy-tooltip.binary-theme[data-placement^=left]>.tippy-arrow {
	border-left-color: ${({ theme }) => theme.color.tooltip_bg[2]};
}

.tippy-tooltip.binary-theme[data-placement^=left]>.tippy-arrow:after {
	border-left: 7px solid ${({ theme }) => theme.color.tooltip_bg[2]};
	left: -7px
}

.tippy-tooltip.binary-theme[data-placement^=left]>.tippy-arrow:before {
	border-left: 7px solid ${({ theme }) => theme.color.border[2]};
	left: -6px
}

.tippy-tooltip.binary-theme[data-placement^=right]>.tippy-svg-arrow:after {
	left: -1px;
	top: 0;
	transform: rotate(-90deg)
}

.tippy-tooltip.binary-theme[data-placement^=right]>.tippy-arrow {
	border-right-color: ${({ theme }) => theme.color.tooltip_bg[2]};
}

.tippy-tooltip.binary-theme[data-placement^=right]>.tippy-arrow:after {
	border-right: 7px solid ${({ theme }) => theme.color.tooltip_bg[2]};
	right: -7px
}

.tippy-tooltip.binary-theme[data-placement^=right]>.tippy-arrow:before {
	border-right: 7px solid ${({ theme }) => theme.color.border[2]};
	right: -6px
}

.tippy-tooltip.binary-theme[data-placement^=bottom]>.tippy-arrow,
.tippy-tooltip.binary-theme[data-placement^=bottom]>.tippy-svg-arrow,
.tippy-tooltip.binary-theme[data-placement^=top]>.tippy-arrow,
.tippy-tooltip.binary-theme[data-placement^=top]>.tippy-svg-arrow {
	transform: translateX(-1px)
}

.tippy-tooltip.binary-theme[data-placement^=bottom]>.tippy-arrow:after,
.tippy-tooltip.binary-theme[data-placement^=bottom]>.tippy-arrow:before,
.tippy-tooltip.binary-theme[data-placement^=top]>.tippy-arrow:after,
.tippy-tooltip.binary-theme[data-placement^=top]>.tippy-arrow:before {
	left: -7px;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent
}

.tippy-tooltip.binary-theme[data-placement^=left]>.tippy-arrow,
.tippy-tooltip.binary-theme[data-placement^=left]>.tippy-svg-arrow,
.tippy-tooltip.binary-theme[data-placement^=right]>.tippy-arrow,
.tippy-tooltip.binary-theme[data-placement^=right]>.tippy-svg-arrow {
	transform: translateY(-1px)
}

.tippy-tooltip.binary-theme[data-placement^=left]>.tippy-arrow:after,
.tippy-tooltip.binary-theme[data-placement^=left]>.tippy-arrow:before,
.tippy-tooltip.binary-theme[data-placement^=right]>.tippy-arrow:after,
.tippy-tooltip.binary-theme[data-placement^=right]>.tippy-arrow:before {
	top: -7px;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent
}

/************** Theme that removes padding from the main content div *****************/

.tippy-tooltip.binary-no-padding-theme {
	background-color: ${({ theme }) => theme.color.tooltip_bg[2]};
	background-clip: padding-box;
	border: 1px solid ${({ theme }) => theme.color.border[2]};
	color: ${({ theme }) => theme.color.font_bold[2]};
	box-shadow: 0 1px 14px 0 ${({ theme }) => theme.color.tooltip_shadow[2]};
	padding: 0;
}

.tippy-tooltip.binary-no-padding-theme>.tippy-content {
	padding: 0;
}

.tippy-tooltip.binary-no-padding-theme>.tippy-backdrop {
	background-color: ${({ theme }) => theme.color.tooltip_bg[2]};
}

.tippy-tooltip.binary-no-padding-theme>.tippy-arrow:after,
.tippy-tooltip.binary-no-padding-theme>.tippy-arrow:before,
.tippy-tooltip.binary-no-padding-theme>.tippy-svg-arrow:after,
.tippy-tooltip.binary-no-padding-theme>.tippy-svg-arrow:before {
	content: "";
	position: absolute;
	z-index: -1
}

.tippy-tooltip.binary-no-padding-theme>.tippy-svg-arrow {
	fill: ${({ theme }) => theme.color.tooltip_bg[2]};
}

.tippy-tooltip.binary-no-padding-theme>.tippy-svg-arrow:after {
	background-image: url(data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEuNDE0IiB2aWV3Qm94PSIwIDAgMTggNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMCA3czIuMDIxLS4wMTUgNS4yNTMtNC4yMThDNi41ODQgMS4wNTEgNy43OTcuMDA3IDkgMGMxLjIwMy0uMDA3IDIuNDE2IDEuMDM1IDMuNzYxIDIuNzgyQzE2LjAxMiA3LjAwNSAxOCA3IDE4IDd6IiBmaWxsPSIjMzMzIiBmaWxsLW9wYWNpdHk9Ii4yMzUiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
	background-size: 18px 7px;
	width: 18px;
	height: 7px;
	left: 0;
	top: 0;
	fill: rgba(0, 8, 16, .15)
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=top]>.tippy-svg-arrow:after {
	top: 1px;
	transform: rotate(180deg)
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=top]>.tippy-arrow {
	border-top-color: ${({ theme }) => theme.color.tooltip_bg[2]};
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=top]>.tippy-arrow:after {
	border-top: 7px solid ${({ theme }) => theme.color.tooltip_bg[2]};
	top: -7px
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=top]>.tippy-arrow:before {
	border-top: 7px solid ${({ theme }) => theme.color.border[2]};
	bottom: -1px
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=bottom]>.tippy-svg-arrow:after {
	top: -1px
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=bottom]>.tippy-arrow {
	border-bottom-color: ${({ theme }) => theme.color.tooltip_bg[2]};
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=bottom]>.tippy-arrow:after {
	border-bottom: 7px solid ${({ theme }) => theme.color.tooltip_bg[2]};
	bottom: -7px
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=bottom]>.tippy-arrow:before {
	border-bottom: 7px solid ${({ theme }) => theme.color.border[2]};
	bottom: -6px
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=left]>.tippy-svg-arrow:after {
	left: 1px;
	top: 0;
	transform: rotate(90deg)
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=left]>.tippy-arrow {
	border-left-color: ${({ theme }) => theme.color.tooltip_bg[2]};
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=left]>.tippy-arrow:after {
	border-left: 7px solid ${({ theme }) => theme.color.tooltip_bg[2]};
	left: -7px
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=left]>.tippy-arrow:before {
	border-left: 7px solid ${({ theme }) => theme.color.border[2]};
	left: -6px
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=right]>.tippy-svg-arrow:after {
	left: -1px;
	top: 0;
	transform: rotate(-90deg)
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=right]>.tippy-arrow {
	border-right-color: ${({ theme }) => theme.color.tooltip_bg[2]};
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=right]>.tippy-arrow:after {
	border-right: 7px solid ${({ theme }) => theme.color.tooltip_bg[2]};
	right: -7px
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=right]>.tippy-arrow:before {
	border-right: 7px solid ${({ theme }) => theme.color.border[2]};
	right: -6px
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=bottom]>.tippy-arrow,
.tippy-tooltip.binary-no-padding-theme[data-placement^=bottom]>.tippy-svg-arrow,
.tippy-tooltip.binary-no-padding-theme[data-placement^=top]>.tippy-arrow,
.tippy-tooltip.binary-no-padding-theme[data-placement^=top]>.tippy-svg-arrow {
	transform: translateX(-1px)
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=bottom]>.tippy-arrow:after,
.tippy-tooltip.binary-no-padding-theme[data-placement^=bottom]>.tippy-arrow:before,
.tippy-tooltip.binary-no-padding-theme[data-placement^=top]>.tippy-arrow:after,
.tippy-tooltip.binary-no-padding-theme[data-placement^=top]>.tippy-arrow:before {
	left: -7px;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=left]>.tippy-arrow,
.tippy-tooltip.binary-no-padding-theme[data-placement^=left]>.tippy-svg-arrow,
.tippy-tooltip.binary-no-padding-theme[data-placement^=right]>.tippy-arrow,
.tippy-tooltip.binary-no-padding-theme[data-placement^=right]>.tippy-svg-arrow {
	transform: translateY(-1px)
}

.tippy-tooltip.binary-no-padding-theme[data-placement^=left]>.tippy-arrow:after,
.tippy-tooltip.binary-no-padding-theme[data-placement^=left]>.tippy-arrow:before,
.tippy-tooltip.binary-no-padding-theme[data-placement^=right]>.tippy-arrow:after,
.tippy-tooltip.binary-no-padding-theme[data-placement^=right]>.tippy-arrow:before {
	top: -7px;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent
}
`;
