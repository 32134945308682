import styled from "../Themes";
import { FormInputContainer } from "../FormComponents";

export const ModalHeading = styled(`div`)`
  overflow: hidden;
  min-width: 0;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 0;
  padding: 15px 60px 15px 20px;
  font-size: 18px;
  line-height: 30px;
  background-color: ${({ theme }) => theme.color.modal_header_background[2]};
  color: ${({ theme }) => theme.color.font_bold[2]};
`;

export const ModalExit = styled(`button`)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 30px;
  cursor: pointer;
  fill: ${({ theme }) => theme.color_50.font_bold[2]};

  &:hover {
    fill: ${({ theme }) => theme.color.font_bold[2]};
  }
`;

export const ModalBody = styled<any>(`div`)`
  position: relative;
  padding: ${({ padding }) => (padding !== undefined ? padding : "20px")};
  overflow: auto;
  min-height: 100px;
  display: flex;
  flex-direction: column;
`;

export const ModalSuccess = styled(`div`)`
  padding: 20px;
  overflow: auto;
  min-height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
`;

export const ModalError = styled(`div`)`
  padding: 20px;
  overflow: auto;
  min-height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
`;

export const ModalFormContainer = styled<any>(FormInputContainer)`
  padding: 5px 0;
  flex: 1 1 ${({ halfWidth }) => (halfWidth ? "48%" : "100%")};
  box-sizing: border-box;

  &:last-child {
    padding-bottom: 0;
  }

  @media (max-width: 576px) {
    flex: 1 1 100%;
  }
`;

export const ModalFooter = styled(`div`)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.color.modal_header_background[2]};
  flex-wrap: wrap-reverse;
  column-gap: 8px;
  row-gap: 8px;
`;

export const ModalIconContainer = styled(`div`)`
  width: 50px;
  height: 50px;
  margin-right: 16px;
  vertical-align: middle;
  display: inline-block;
`;
