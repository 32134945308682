import React, { FC, useEffect, useState } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import UpdateLocationModal from "../UpdateLocationModal";
import { markManifestForPickup } from "../../services/manifests";

const ManifestMarkForPickupModal: FC<any> = ({ manifestId, onSuccess, modalOpen, setModalOpen }) => {
  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [updateLocationModalOpen, setUpdateLocationModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleSubmit = () => {
    setSubmitting(true);
    markManifestForPickup(source, manifestId)
      .then((response) => {
        onSuccess();
        setSubmittedMsg(response);
        setSubmittingErr("");
        setSubmitting(false);
        setModalOpen(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  return (
    <>
      <SubmitModal
        isOpen={modalOpen}
        onSubmit={() => handleSubmit()}
        onClose={() => {
          if (!submitting) setModalOpen(false);
        }}
        title="Mark for Pickup"
        success={submittedMsg}
        error={submittingErr}
        submitBtnText="Yes"
        body={
          <LoadingContainer loading={submitting}>
            <span>Are you sure you want to mark this manifest for pickup?</span>
          </LoadingContainer>
        }
      />
      {updateLocationModalOpen && (
        <UpdateLocationModal
          sensor={{ manifestId: manifestId }}
          onSuccess={onSuccess}
          modalOpen={updateLocationModalOpen}
          setModalOpen={setUpdateLocationModalOpen}
        />
      )}
    </>
  );
};

export default ManifestMarkForPickupModal;
