import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import queryString from "query-string";
import Drawer from "rc-drawer";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { TbDownload } from "react-icons/tb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { fetchAutoComplete } from "../../../services/autoComplete";
import { fetchFreshnessReport } from "../../../services/freshnessReport";
import Cross from "../../../svgs/Cross";
import downloadFile from "../../../util/downloadFile";
import errToStr from "../../../util/errToStr";
import getParameterByName from "../../../util/getParamByName";
import { googleMapsLink } from "../../../util/googleMapsLink";
import Badge from "../../Badge";
import { PrimaryBtn } from "../../Buttons";
import Checkbox from "../../Checkbox";
import { DrawBody, DrawCloseButton, DrawContent, DrawFooter, DrawHeader, FilterInputContainer } from "../../Drawer/styles";
import LoadingContainer from "../../LoadingContainer";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import { Subtitle, Title } from "../../PageTitle/styles";
import { ClearPanel, Panel, StatPanel } from "../../Panel";
import { AsyncSelect } from "../../Select";
import Table from "../../Table";
import { FreshnessContainer, StatBlock, StatLabel, StatValue } from "../styles";

const formatDataToCsv = (tableRef: any) => {
  const headers = ["Tracker ID", "Location", "Place Type", "Empty/Full", "Freshness (%)"];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [
        row.binaryId,
        row.placeName ? row.placeName : row.location ? row.location : `${row.latitude}, ${row.longitude}`,
        row.placeType,
        row.empty ? "Empty" : "Full",
        row.freshness,
      ];
    }, []),
  ];
};

const FreshnessReport: FC<any> = () => {
  const { color } = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const tableRef = useRef<any>(null);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  // Report filters
  const [includeEmpty, setIncludeEmpty] = useState<boolean>(getParameterByName("empty", location.search) === "true");
  const [placeTypes, setPlaceTypes] = useState<any>([]);

  const [appliedFilters, setAppliedFilters] = useState<number>(0);

  const [autoCompletePlaceTypes, setAutoCompletePlaceTypes] = useState<any>(undefined);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Fetch all place types from autoComplete to use for matching place type params
  // e.g. match "?place-types=Venue" to {value: "a980bcaa-7ede-45b5-8ced-63e10284f994", label: "Venue", colour: "#c16807",…}
  useEffect(() => {
    const placeTypeParam = getParameterByName("place-types", location.search) !== null ? getParameterByName("place-types", location.search)?.split(",") : [];

    fetchAutoComplete("placetypes", "").then((response) => {
      const temp = [...response, { label: "In Transit", value: "In Transit", colour: color.primary[2] }];
      if (placeTypeParam && placeTypeParam.length > 0) {
        setPlaceTypes(temp.filter((type: any) => placeTypeParam.includes(type.label)));
      }
      setAutoCompletePlaceTypes(temp);
    });
  }, []);

  // On location change (e.g. url parameter changes because filters changed), update filters and fetch report
  useEffect(() => {
    if (autoCompletePlaceTypes !== undefined) {
      const empty = getParameterByName("empty", location.search) === "true";
      setIncludeEmpty(empty);

      const placeTypeParam = getParameterByName("place-types", location.search) !== null ? getParameterByName("place-types", location.search)?.split(",") : [];
      const filteredPlaceTypes = autoCompletePlaceTypes.filter((type: any) => placeTypeParam?.includes(type.label));
      setPlaceTypes(filteredPlaceTypes);

      fetchReport(empty, filteredPlaceTypes);
    }
  }, [location]);

  // After autoCompletePlaceTypes is initially set on mount, fetch report
  useEffect(() => {
    if (autoCompletePlaceTypes !== undefined) {
      fetchReport();
    }
  }, [autoCompletePlaceTypes]);

  const updateQueryParams = () => {
    const parsed = queryString.parse(location.search);
    const newQuery = { ...parsed };

    if (includeEmpty === true) {
      newQuery.empty = "true";
    } else {
      delete newQuery.empty;
    }

    if (placeTypes && placeTypes.length > 0) {
      newQuery["place-types"] = placeTypes.map((type: any) => type.label).join(",");
    } else if (autoCompletePlaceTypes !== undefined) {
      delete newQuery["place-types"];
    }

    const stringified = queryString.stringify(newQuery);
    navigate({ ...location, search: stringified });
  };

  const fetchReport = (empty?: any, types?: any) => {
    setDataLoading(true);
    setDataErr("");

    const emptyArg = empty !== undefined ? empty : includeEmpty;
    const placeTypesArg = types !== undefined ? types : placeTypes;

    fetchFreshnessReport(source, emptyArg, placeTypesArg.map((type: any) => type.label).join(","))
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData([]);
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    let filters = 0;

    if (emptyArg) filters++;
    if (placeTypesArg.length) filters += placeTypesArg.length;

    setAppliedFilters(filters);
  };

  const columns = [
    {
      id: "binaryId",
      Header: "Tracker ID",
      accessor: "binaryId",
      Cell: (props: any) => {
        let title = props.value ? props.value : "";
        title += `${props.original.sensorName ? " (" + props.original.sensorName + ")" : ""}`;

        return (
          <Link to={`/kegs/${props.value}`} title={title}>
            {title}
          </Link>
        );
      },
      minWidth: 130,
    },
    {
      id: "placeName",
      Header: "Location",
      accessor: "placeName",
      Cell: (props: any) =>
        props.original.placeId ? (
          <Link to={`/places/${props.original.placeId}`}>{props.value}</Link>
        ) : props.original.location ? (
          googleMapsLink(props.original.latitude, props.original.longitude, props.original.location)
        ) : (
          googleMapsLink(props.original.latitude, props.original.longitude)
        ),
      minWidth: 180,
    },
    {
      id: "placeType",
      Header: "Place Type",
      accessor: "placeType",
      minWidth: 180,
    },
    {
      id: "empty",
      Header: "Empty/Full",
      accessor: "empty",
      Cell: (props: any) => (
        <Badge title={props.value ? "Empty" : "Full"} background={props.value ? color.danger_dark[2] : color.success_dark[2]}>
          {props.value ? "Empty" : "Full"}
        </Badge>
      ),
      minWidth: 180,
    },
    {
      id: "placeId",
      accessor: "placeId",
      show: false,
    },
    {
      id: "location",
      accessor: "location",
      show: false,
    },
    {
      id: "latitude",
      accessor: "latitude",
      show: false,
    },
    {
      id: "longitude",
      accessor: "longitude",
      show: false,
    },
    {
      id: "freshness",
      Header: "Freshness",
      accessor: "freshness",
      Cell: (props: any) => <FreshnessContainer freshness={props.value}>{props.value === 0 ? "0% (Stale)" : `${props.value}%`}</FreshnessContainer>,
      minWidth: 160,
    },
  ];

  const defaultSorted = [
    {
      id: "placeName",
      desc: true,
    },
    {
      id: "freshness",
    },
  ];

  const handleCheckboxChange = (e: any) => {
    e.persist();
    setIncludeEmpty(e.target.checked);
  };

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback([...response, { label: "In Transit", value: "In Transit", colour: color.primary[2] }]);
    });
  };

  return (
    <>
      <Drawer
        placement="right"
        level={null}
        open={filtersOpen}
        onClose={() => {
          setFiltersOpen(false);
          updateQueryParams();
        }}
        onHandleClick={() => setFiltersOpen(!filtersOpen)}
        handler={false}
      >
        <DrawContent>
          <DrawHeader>
            <div
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                fontSize: "0",
                marginRight: "6px",
              }}
            >
              <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                <HiOutlineAdjustmentsHorizontal />
              </IconContext.Provider>
            </div>
            Filters
            <DrawCloseButton
              onClick={() => {
                setFiltersOpen(false);
              }}
              aria-label={`Close filters`}
            >
              <Cross />
            </DrawCloseButton>
          </DrawHeader>
          <DrawBody>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <label>Place Type</label>
              <FilterInputContainer>
                <AsyncSelect
                  name="placeTypes"
                  defaultOptions={true}
                  closeMenuOnSelect={false}
                  isClearable={true}
                  isMulti={true}
                  isSearchable={true}
                  value={placeTypes}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("placetypes", inputValue, callback)}
                  onChange={(selected: any) => {
                    if (selected) {
                      setPlaceTypes(selected);
                    } else {
                      setPlaceTypes([]);
                    }
                  }}
                  placeholder="Select..."
                />
              </FilterInputContainer>
              <FilterInputContainer>
                <Checkbox style={{ display: "inline-block" }} name="active" label="Include Empty Kegs" checked={includeEmpty} onChange={handleCheckboxChange} />
              </FilterInputContainer>
            </form>
          </DrawBody>
          <DrawFooter>
            <PrimaryBtn
              onClick={() => {
                setFiltersOpen(false);
                updateQueryParams();
              }}
            >
              Apply Filters
            </PrimaryBtn>
          </DrawFooter>
        </DrawContent>
      </Drawer>
      <PageBreadcrumbs prevRoutes={[{ slug: "/reports", title: "Reports" }]} currRoute="Freshness Report" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading} err={dataErr}>
            <ClearPanel style={{ textAlign: "center" }}>
              <Title style={{ marginBottom: "6px" }}>Freshness Report</Title>
              <Subtitle>Kegs that are below a specified freshness</Subtitle>
            </ClearPanel>
            <ClearPanel style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ position: "relative" }}>
                  <PrimaryBtn style={{ height: "40px", minWidth: "unset", marginRight: "12px" }} padding="0 6px" onClick={() => setFiltersOpen(!filtersOpen)}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <div
                        style={{
                          display: "inline-block",
                          width: "20px",
                          height: "20px",
                          fontSize: "0",
                          marginRight: "6px",
                        }}
                      >
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiOutlineAdjustmentsHorizontal />
                        </IconContext.Provider>
                      </div>
                      <span>Filters</span>
                    </div>
                  </PrimaryBtn>
                </div>
                <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                  {appliedFilters} filter{appliedFilters === 1 ? "" : "s"} applied
                </span>
              </div>
              <PrimaryBtn
                style={{ height: "40px", minWidth: "unset", marginRight: "12px" }}
                padding="0 6px"
                onClick={() =>
                  downloadFile(
                    stringify(formatDataToCsv(tableRef), {
                      quoted: true,
                      quoted_string: true,
                    }),
                    "text/csv;charset=utf-8",
                    "Freshness Report.csv"
                  )
                }
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "inline-block",
                      width: "20px",
                      height: "20px",
                      fontSize: "0",
                      marginRight: "6px",
                    }}
                  >
                    <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                      <TbDownload />
                    </IconContext.Provider>
                  </div>
                  <span
                    style={{
                      marginRight: "6px",
                    }}
                  >
                    CSV
                  </span>
                </div>
              </PrimaryBtn>
            </ClearPanel>
            <StatPanel>
              <StatBlock>
                <StatLabel>Less than 75% fresh</StatLabel>
                <StatValue>{data.filter((e: any) => e.freshness < 75).length}</StatValue>
              </StatBlock>
              <StatBlock>
                <StatLabel>Stale</StatLabel>
                <StatValue>{data.filter((e: any) => e.freshness === 0).length}</StatValue>
              </StatBlock>
            </StatPanel>
            <Panel>
              <Table loading={dataLoading} style={{ clear: "both" }} data={data} columns={columns} defaultSorted={defaultSorted} ref={tableRef} />
            </Panel>
          </LoadingContainer>
        </div>
      </PageContainer>
    </>
  );
};

export default FreshnessReport;
