import axios from "axios";
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { getTrackerDistribution } from "../../services/trackerDistribution";
import InfoIcon from "../../svgs/Legend";
import { isBinaryBeer } from "../../util/checkDomain";
import errToStr from "../../util/errToStr";
import hexToRgba from "../../util/hexToRgba";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import ChartHeading from "../ChartHeading";
import LoadingContainer from "../LoadingContainer";
import SensorListModal from "../SensorListModal";
import InfoTooltip from "../Tooltip";
import { ChartContainer, DateText, InfoIconContainer } from "./styles";

const TrackerDistribution: FC<any> = ({ filterDates }) => {
  const { color, echarts_theme, echarts_opacity, short_date, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>({ series: [], total: 0 });
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sensorList, setSensorList] = useState<boolean>(false);
  const [sensorListModalOpen, setSensorListModalOpen] = useState<boolean>(false);

  const options = useRef<any>({});

  const [echartsInstance, setEchartsInstance] = useState<any>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    // If end date is undefined set to current date
    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined ? filterDates.end.unix() : moment().unix(),
    };

    getTrackerDistribution(source, dates)
      .then((response) => {
        let total = 0;
        let series: any = [];

        if (response && response.length > 0) {
          // if not binarybeer combine series with the same legendName
          if (isBinaryBeer()) {
            total = response.reduce((count: number, el: any) => (count += el.value), 0);
            series = response;
          } else {
            response.forEach((el: any) => {
              const index = series.findIndex((_el: any) => _el.legendName === el.legendName);
              total += el.value;

              if (index > -1) {
                series[index] = {
                  ...series[index],
                  name: el.legendName,
                  value: series[index].value + el.value,
                  empty: false,
                };
              } else {
                series.push({
                  ...el,
                  name: el.legendName,
                  empty: false,
                });
              }
            }, []);
          }
        }

        setData({ series, total });
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData({ series: [], total: 0 });
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [filterDates]);

  const returnPercent = (value: number, total: number) => {
    const ratio = total > 0 ? value / total : 0;
    return ratio * 100;
  };

  const formatTooltip = (param: any) => {
    if (param) {
      let tooltip = `<span>${param.name}<br />`;
      tooltip += `${param.marker} ${param.value} (${returnPercent(param.value, data.total).toFixed(1)}%)`;
      tooltip += "</span>";
      return tooltip;
    }
  };

  const formatData = () => {
    if (data.series && data.series.length > 0) {
      return data.series.map(
        (el: any) => ({
          name: el.name,
          value: el.value,
          sensors: el.sensors,
          itemStyle: {
            color: hexToRgba(el.colour, el.empty ? 30 : 80),
            borderWidth: 1.5,
            borderColor: el.colour,
          },
        }),
        []
      );
    }
  };

  useEffect(() => {
    if (echartsInstance) {
      options.current = {
        tooltip: {
          trigger: "item",
          formatter: formatTooltip,
        },
        legend: {
          show: false,
        },
        series: [
          {
            type: "pie",
            radius: ["45%", "65%"],
            avoidLabelOverlap: false,
            data: formatData(),
          },
        ],
      };

      // Updates the options of the chart each render
      // This is mainly to update the splitNumber on the bottom time axis
      if (options && options.current) {
        echartsInstance.setOption(options.current);
      }
    }
  }, [echartsInstance, data, echarts_opacity]);

  // listens for window resize events and triggers a chart resize so it fits
  // properly within it's container, fixes issue on iOS tablet when screen rotates
  const handleResizeEvent = useCallback(() => {
    echartsInstance.resize();
  }, [echartsInstance]);

  useEffect(() => {
    window.removeEventListener("resize", handleResizeEvent);

    if (echartsInstance) {
      window.addEventListener("resize", handleResizeEvent);
    }

    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, [echartsInstance]);

  const onClick = useCallback((props: any) => {
    if (props && props.data && props.data.sensors) {
      setSensorList(props.data.sensors);
      setSensorListModalOpen(true);
    }
  }, []);

  return (
    <>
      <LoadingContainer loading={dataLoading} err={dataErr}>
        {!dataLoading && (
          <ChartContainer>
            <ChartHeading>{kegsOrTrackers("Keg", "Tracker")} Distribution</ChartHeading>
            {options && (
              <ReactEcharts
                key="current-dist-chart"
                onChartReady={(chart: any) => setEchartsInstance(chart)}
                style={{ height: "100%", width: "100%" }}
                option={options.current}
                notMerge={true}
                lazyUpdate={true}
                theme={echarts_theme}
                onEvents={{ click: onClick }}
                opts={{ renderer: "svg" }}
              />
            )}
            <DateText style={{ textAlign: "left", marginTop: "6px" }} title={moment(filterDates.end).format(long_datetime)}>
              {filterDates.isNow ? "Now" : `On ${moment(filterDates.end).format(short_date)}`}
            </DateText>
            <InfoTooltip content={`The most recent distribution of ${kegsOrTrackers("kegs", "trackers")} throughout different place types`} touch={true}>
              <InfoIconContainer>
                <InfoIcon fill={color.font[2]} />
              </InfoIconContainer>
            </InfoTooltip>
          </ChartContainer>
        )}
      </LoadingContainer>
      {sensorListModalOpen && (
        <SensorListModal
          sensors={sensorList}
          sensorColumns={["placeName", "lastSampleDate"]}
          size="lg"
          loading={dataLoading}
          err={dataErr}
          modalOpen={sensorListModalOpen}
          setModalOpen={setSensorListModalOpen}
        />
      )}
    </>
  );
};

export default TrackerDistribution;
