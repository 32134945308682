import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" {...props}>
      <path
        fill={props.fill}
        opacity={0.8}
        d="M192 0C86.112 0 0 86.112 0 192c0 133.088 173.312 307.936 180.672 315.328a16.069 16.069 0 0022.656 0C210.688 499.936 384 325.088 384 192 384 86.112 297.888 0 192 0zm95.1 181.312A16.042 16.042 0 01272 192h-16v80a16.006 16.006 0 01-16 16h-96a16.006 16.006 0 01-16-16v-80h-16a16 16 0 01-9.984-28.512l80-64a16.021 16.021 0 0120 0l80 64a16.046 16.046 0 015.084 17.824z"
      />
      <path
        d="M191.986 95.984a16.024 16.024 0 00-9.97 3.504l-80 64c-11.843 9.44-5.16 28.523 9.984 28.512h16v80c.003 8.835 7.165 15.997 16 16h96c8.835-.003 15.997-7.165 16-16v-80h16a16.041 16.041 0 0015.1-10.688 16.046 16.046 0 00-5.084-17.824l-80-64a16.021 16.021 0 00-10.03-3.504z"
        fill="#fff"
        opacity={0.8}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
