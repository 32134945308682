import axios, { CancelTokenSource } from "axios";

export const fetchAlertDetails = async (source: CancelTokenSource, id: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + `/api/alertdetails/${id ? id : ""}`,
  });
  return data;
};
