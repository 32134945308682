import styled from "../Themes";

export const Bar = styled<any>(`div`)`
  position: absolute;
  width: 100%;
  height: 57px;
  padding: 8px;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  border-bottom: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
  z-index: 1;

  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

export const Toggle = styled(`div`)`
  width: 25px;
  height: 70px;

  position: absolute;
  left: 400px;
  top: 160px;
  padding: 0;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    background-color: ${({ theme }) => theme.color.panel_bg[2]};
    box-shadow: 6px 0 6px -2px ${({ theme }) => theme.color_15.shadow[2]};
    border-radius: 0 4px 4px 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transform: perspective(50px) rotateY(25deg);
  }

  @media (max-width: 950px) {
    top: 160px;
    left: auto;
    right: -25px;

    &::before {
      box-shadow: 6px 0 6px -2px ${({ theme }) => theme.color_15.shadow[2]};
      background-color: ${({ theme }) => theme.color.panel_bg[2]};
      border-radius: 0 4px 4px 0;
      transform: perspective(50px) rotateY(25deg);
    }
  }
`;

export const Form = styled("form")`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ToggleText = styled(`div`)`
  transform: rotate(90deg) translateZ(100px);
  font-size: 14px;
  font-weight: 500;
  padding: 2px 0 0 14px;
  cursor: inherit;

  @media (max-width: 950px) {
    padding: 16px 0 14px 0;
  }
`;

export const Header = styled<any>(`div`)`
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
`;

export const Content = styled<any>(`div`)`
  position: absolute;
  top: 20px;
  bottom: 0;
  width: 100%;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
  transition: ${({ theme }) => theme.transition.m};
`;

export const SaveFilterContainer = styled(`div`)`
  height: 64px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
  padding: 12px 12px 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ModalFilterContainer = styled(`div`)`
  padding: 32px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subtle_border[2]};

  &:last-child {
    border-bottom: none;
  }
`;

export const ModalFilterHeader = styled(`p`)`
  text-align: left;
  margin-bottom: 12px;
`;

export const Badge = styled(`div`)`
  box-sizing: border-box;
  color: white;
  font-size: 16px;
  min-width: 1.4em;
  height: 1.4em;
  background-color: ${({ theme }) => theme.color.danger[2]};
  border-radius: 1.4em;
  text-align: center;
  line-height: 1.4em;
`;

export const BadgeNumber = styled(`div`)`
  font-size: 0.7em;
`;
