import axios, { CancelTokenSource } from "axios";
import QrScanner from "qr-scanner";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { GiCube } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { getTrackerInfo } from "../../services/trackerInfo";
import DeletePickupRequestIcon from "../../svgs/DeletePickupRequestIcon";
import CreatePickupRequestIcon from "../../svgs/CreatePickupRequestIcon";
import ErrIcon from "../../svgs/ErrIcon";
import KegIcon from "../../svgs/KegIcon";
import SuccessIcon from "../../svgs/SuccessIcon";
import UpdateLocationIcon from "../../svgs/UpdateLocationIcon";
import { isBinaryBeer } from "../../util/checkDomain";
import errToStr from "../../util/errToStr";
import { kegOrTracker } from "../../util/kegOrTracker";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { PageContainer } from "../PageStyles";
import PageTitle from "../PageTitle";
import { BigButton, ButtonContent, ButtonText, ButtonsContainer, IconContainer, VideoContainer } from "./styles";

const ScannerScreen: FC<any> = () => {
  const navigate = useNavigate();

  const { color } = useContext(ThemeContext);

  const videoRefCallback = useCallback((node: any) => {
    if (node) {
      setVideoRef(node);
    }
  }, []);

  const [videoRef, setVideoRef] = useState<any>(undefined);

  const [codeReader, setCodeReader] = useState<any>(undefined);

  const [trackerInfo, setTrackerInfo] = useState<any>(undefined);
  const [trackerInfoErr, setTrackerInfoErr] = useState<string>("");
  const [trackerInfoLoading, setTrackerInfoLoading] = useState<boolean>(false);

  const [nextRoute, setNextRoute] = useState<any>(undefined);

  const [trackerInfoModalOpen, setTrackerInfoModalOpen] = useState<any>(undefined);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, []);

  // Initialise codeReader and stop/destroy codeReader and stop camera access on clean up
  useEffect(() => {
    let codeReader: any = undefined;

    if (videoRef) {
      codeReader = new QrScanner(videoRef, (result) => {
        if (result) {
          let code = result;
          if (code.includes("10b.in/")) {
            code = code.split("10b.in/")[1];
          }
          fetchTrackerInfo(code);
        }
      });
      codeReader.setInversionMode("both");
      setCodeReader(codeReader);
    }

    return () => {
      if (codeReader) {
        codeReader.stop();
        codeReader.destroy();
      }
      if (videoRef && videoRef.srcObject) {
        videoRef.srcObject.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
    };
  }, [videoRef]);

  // Stop code reader when tracker info is loading or the tracker info modal is open
  useEffect(() => {
    if (codeReader) {
      if (trackerInfoLoading || trackerInfoModalOpen) {
        codeReader.stop();
        if (videoRef && videoRef.srcObject) {
          videoRef.srcObject.getTracks().forEach((track: any) => {
            track.stop();
          });
        }
      } else {
        codeReader.start();
      }
    }
  }, [codeReader, trackerInfoLoading, trackerInfoModalOpen, videoRef]);

  const fetchTrackerInfo = (trackerId: string) => {
    setTrackerInfoLoading(true);
    getTrackerInfo(source, trackerId)
      .then((response) => {
        if (response && response.activated) {
          setNextRoute(`/${kegOrTracker("kegs", "trackers")}/${response.trackerId}`);
        } else {
          setTrackerInfoModalOpen(true);
        }
        setTrackerInfo(response);
        setTrackerInfoLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setTrackerInfoModalOpen(true);
          setTrackerInfoErr(errToStr(err));
          setTrackerInfoLoading(false);
        }
      });
  };

  const handleTrackerInfoModalClose = () => {
    setTrackerInfo(undefined);
    setTrackerInfoErr("");
    setTrackerInfoLoading(false);
    setTrackerInfoModalOpen(false);
  };

  const handleActivateTracker = () => {
    if (trackerInfo.trackerId) {
      setNextRoute(`/scanner/activate?trackerId=${trackerInfo.trackerId}`);
    }
    handleTrackerInfoModalClose();
  };

  if (nextRoute) {
    navigate(nextRoute);
  }

  return (
    <>
      <PageTitle title="Scanner" />
      <PageContainer>
        <LoadingContainer loading={trackerInfoLoading}>
          <VideoContainer>
            <video
              id="video"
              ref={videoRefCallback}
              style={{
                display: "inline-block",
                width: "100%",
                maxWidth: "768px",
                border: "1px solid gray",
                borderRadius: "3px",
              }}
            />
          </VideoContainer>
          <ButtonsContainer>
            <BigButton to="/scanner/activate">
              <ButtonContent>
                <IconContainer style={{ width: "30px", height: "30px" }}>
                  <SuccessIcon />
                </IconContainer>
                <ButtonText>Activate Trackers</ButtonText>
              </ButtonContent>
            </BigButton>
            {isBinaryBeer() && (
              <BigButton to="/scanner/mark-as-filled">
                <ButtonContent>
                  <IconContainer>
                    <KegIcon outline={color.font_bold[2]} />
                  </IconContainer>
                  <ButtonText>Mark as Filled</ButtonText>
                </ButtonContent>
              </BigButton>
            )}
            {isBinaryBeer() && (
              <BigButton to="/scanner/mark-as-emptied">
                <ButtonContent>
                  <IconContainer
                    style={{
                      fill: "none",
                    }}
                  >
                    <KegIcon outline={color.font_bold[2]} />
                  </IconContainer>
                  <ButtonText>Mark as Emptied</ButtonText>
                </ButtonContent>
              </BigButton>
            )}
            <BigButton to="/scanner/create-pickup-request">
              <ButtonContent>
                <IconContainer style={{ width: "30px", height: "30px" }}>
                  <CreatePickupRequestIcon />
                </IconContainer>
                <ButtonText>Create Pickup Request</ButtonText>
              </ButtonContent>
            </BigButton>
            <BigButton to="/scanner/delete-pickup-request">
              <ButtonContent>
                <IconContainer style={{ width: "30px", height: "30px" }}>
                  <DeletePickupRequestIcon />
                </IconContainer>
                <ButtonText>Delete Pickup Request</ButtonText>
              </ButtonContent>
            </BigButton>
            <BigButton to="/scanner/update-location">
              <ButtonContent>
                <IconContainer style={{ width: "30px", height: "30px" }}>
                  <UpdateLocationIcon />
                </IconContainer>
                <ButtonText>Update Location</ButtonText>
              </ButtonContent>
            </BigButton>
            <BigButton to="/scanner/assign-asset-id">
              <ButtonContent>
                <IconContext.Provider value={{ color: color.font[2], size: "30px" }}>
                  <GiCube style={{ marginRight: "16px" }} />
                </IconContext.Provider>
                <ButtonText>Assign Asset ID</ButtonText>
              </ButtonContent>
            </BigButton>
          </ButtonsContainer>
        </LoadingContainer>
      </PageContainer>
      {trackerInfoModalOpen && (
        <SubmitModal
          isOpen={trackerInfoModalOpen}
          loading={trackerInfoLoading}
          onClose={handleTrackerInfoModalClose}
          error={trackerInfoErr}
          title={"Tracker Info"}
          body={
            <>
              {trackerInfo && !trackerInfo.activated ? (
                <>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ width: "50px", height: "50px", margin: "20px 20px 20px 0" }}>
                      <ErrIcon fill={color.error[2]} />
                    </div>
                    <div>
                      <p>Tracker not activated.</p>
                      <p>Would you like to start the activation process?</p>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          }
          footer={
            <>
              {trackerInfoErr ? (
                <div style={{ justifyContent: "end", display: "flex" }}>
                  <OutlineBtn onClick={handleTrackerInfoModalClose}>Okay</OutlineBtn>
                </div>
              ) : (
                <div style={{ justifyContent: "end", display: "flex" }}>
                  <GhostBtn onClick={handleTrackerInfoModalClose}>Cancel</GhostBtn>
                  <PrimaryBtn onClick={handleActivateTracker}>Activate</PrimaryBtn>
                </div>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default ScannerScreen;
