import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { FormInput, FormError } from "../FormComponents";
import { exists, maxLength } from "../../util/formValidations";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { postBatch, putBatch } from "../../services/editBatch";
import { AsyncCreatableSelect, AsyncSelect } from "../Select";
import { fetchAutoComplete } from "../../services/autoComplete";
import Flatpickr from "react-flatpickr";

const defaultBatch = {
  id: null,
  beer: null,
  place: null,
  dateCreated: new Date(),
  notes: "",
};

const initBatchForm = (batch: any) => {
  if (batch) {
    return {
      ...defaultBatch,
      ...batch,
      beer: batch.beerId
        ? {
            value: batch.beerId,
            label: batch.beerName,
          }
        : null,
      place: batch.placeId
        ? {
            value: batch.placeId,
            label: batch.placeName,
          }
        : null,
      dateCreated: batch.dateCreated ? new Date(batch.dateCreated * 1000) : new Date(),
    };
  } else {
    return defaultBatch;
  }
};

const EditBeerBatchModal: FC<any> = ({ modalOpen, setModalOpen, batch, placeId, beerId, onCreate, onUpdate }) => {
  const [formData, setFormData] = useState<any>(initBatchForm(batch));
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "beer":
          if (beerId === undefined) {
            currValid = exists(name, value ? value.value : null, setFormErrors);
          }
          break;

        case "place":
          if (placeId === undefined) {
            currValid = exists(name, value ? value.value : null, setFormErrors);
          }
          break;

        case "date":
          currValid = exists(name, value, setFormErrors);
          break;

        case "notes":
          currValid = maxLength(name, value, 200, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    return {
      id: formData.id,
      // if no placeId is given, use the placeId passed into the component
      placeId: formData.place && formData.place.value !== undefined ? formData.place.value : placeId,
      // if no beerId is given, use the beerId passed into the component
      beerId: formData.beer && formData.beer.value !== undefined ? formData.beer.value : beerId,
      notes: formData.notes,
      dateCreated: formData.dateCreated && Math.round(formData.dateCreated.getTime() / 1000),
    };
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      // if no id new batch is being created
      if (formData.id == null) {
        postBatch(source, body)
          .then((response) => {
            onCreate(response);
            setSubmittedMsg("Beer Batch Created");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      } else {
        putBatch(source, body)
          .then((response) => {
            onUpdate(response);
            setSubmittedMsg("Beer Batch Updated");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      }
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const handleDateChange = (date: any) => {
    setFormData((prev: any) => ({ ...prev, dateCreated: date[0] }));
    setFormErrors((prev: any) => ({ ...prev, dateCreated: undefined }));
  };

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  return (
    <>
      <SubmitModal
        isOpen={modalOpen}
        onSubmit={() => handleSubmit()}
        onClose={() => {
          if (!submitting) setModalOpen(false);
        }}
        title={formData.id ? "Edit Beer Batch" : "Create Beer Batch"}
        success={submittedMsg}
        error={submittingErr}
        submitBtnText={formData.id ? "Update Batch" : "Create Batch"}
        body={
          <LoadingContainer loading={submitting}>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              {placeId === undefined && (
                <ModalFormContainer>
                  <label>Place</label>
                  <AsyncSelect
                    closeMenuOnSelect={true}
                    defaultOptions={true}
                    isClearable={true}
                    isError={formErrors.place}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("breweries", inputValue, callback)}
                    name="place"
                    onChange={handleSelectChange}
                    placeholder="Select..."
                    value={formData.place}
                  />
                  <FormError error={formErrors.place}>{formErrors.place}</FormError>
                </ModalFormContainer>
              )}
              {beerId === undefined && (
                <ModalFormContainer>
                  <label>Beer</label>
                  <AsyncCreatableSelect
                    name="beer"
                    defaultOptions={true}
                    isClearable={true}
                    isError={formErrors.beer}
                    value={formData.beer}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("beers", inputValue, callback)}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                  <FormError error={formErrors.beer}>{formErrors.beer}</FormError>
                </ModalFormContainer>
              )}
              <ModalFormContainer>
                <label>Date Brewed</label>
                <Flatpickr data-enable-time value={formData.dateCreated} onChange={handleDateChange} options={{ maxDate: Date.now() }} />
                <FormError error={formErrors.dateCreated}>{formErrors.dateCreated}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label>Notes</label>
                <FormInput type="text" name="notes" value={formData.notes} error={formErrors.notes} onChange={handleChange} />
                <FormError error={formErrors.notes}>{formErrors.notes}</FormError>
              </ModalFormContainer>
            </form>
          </LoadingContainer>
        }
      />
    </>
  );
};

export default EditBeerBatchModal;
