import React, { FC, useState, useEffect, useCallback, useRef, useContext } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { FormInput, FormError } from "../FormComponents";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { getAllContents, removeContents } from "../../services/contents";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import { QrScanButton } from "./styles";
import Quagga, { QuaggaJSCodeReader, QuaggaJSResultObject } from "@ericblade/quagga2";
// @ts-ignore
import QrCodeReader from "@ericblade/quagga2-reader-qr";
import matchSorter from "match-sorter";
import Table from "../Table";
import Bold from "../Bold";
import Tooltip from "../Tooltip";
import DeleteIcon from "../../svgs/DeleteIcon";
import { ThemeContext } from "styled-components";
import ScreenHeading from "../ScreenHeading";
import { AsyncSelect } from "../Select";
import { fetchAutoComplete } from "../../services/autoComplete";
import { getTableFilters } from "../../util/urlParamFilters";
import { WarningAlert } from "../Alerts";

Quagga.registerReader("qr_code", QrCodeReader);

function getMedian(arr: any[]) {
  arr.sort((a: number, b: number) => a - b);
  const half = Math.floor(arr.length / 2);
  if (arr.length % 2 === 1) {
    return arr[half];
  }
  return (arr[half - 1] + arr[half]) / 2;
}

function getMedianOfCodeErrors(decodedCodes: any[]) {
  const errors = decodedCodes.filter((x) => x.error !== undefined).map((x) => x.error);
  const medianOfErrors = getMedian(errors);
  return medianOfErrors;
}

const defaultConstraints = {
  width: 640,
  height: 480,
};

const defaultLocatorSettings = {
  patchSize: "medium",
  halfSample: true,
  willReadFrequently: true,
};

const defaultDecoders: (QuaggaJSCodeReader | "qr_code")[] = [
  "qr_code",
  "code_128_reader",
  "ean_reader",
  "ean_8_reader",
  "code_39_reader",
  "code_39_vin_reader",
  "codabar_reader",
  "upc_reader",
  "upc_e_reader",
  "i2of5_reader",
  "2of5_reader",
  "code_93_reader",
];

const defaultItem = {
  id: null,
  identifier: "",
  placeRemoved: null,
  name: "",
  description: "",
};

const ScanRemoveContentsModal: FC<any> = ({ manifestId = "", onSuccess, onClose, modalOpen, setModalOpen }) => {
  const { color } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);
  const scannerRef = useRef<any>(undefined);

  const [contents, setContents] = useState<any>([]);
  const [contentsErr, setContentsErr] = useState<string>("");
  const [contentsLoading, setContentsLoading] = useState<boolean>(true);

  const [formData, setFormData] = useState<any>(defaultItem);
  const [formErrors, setFormErrors] = useState<any>({});

  const [items, setItems] = useState<any>([]);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [scanning, setScanning] = useState<boolean>(false);

  const [multipleIdsRemaining, setMultipleIdsRemaining] = useState<any>({ id: "", count: 0 });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    setContentsLoading(true);
    setContentsErr("");

    getAllContents(source, true, { filters: getTableFilters(manifestId === "" ? [] : [{ id: "manifestId", value: { type: "number", value: manifestId } }]) })
      .then((response) => {
        setContents(response.data);
        setContentsLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setContentsErr(errToStr(err));
          setContentsLoading(false);
        }
      });
  }, []);

  // On barcode detect, add result to ID input and stop scanning
  const onDetected = (result: any) => {
    setFormData((prev: any) => ({ ...prev, identifier: result }));
    setScanning(false);
  };

  const errorCheck = useCallback(
    (result: { codeResult: { decodedCodes: any[]; code: any } }) => {
      if (!onDetected) {
        return;
      }
      // @ts-ignore
      if (result.codeResult.format !== "qr_code") {
        const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
        // if Quagga is at least 92% certain that it read correctly, then accept the code.
        if (err < 0.08) {
          onDetected(result.codeResult.code);
        }
      } else {
        onDetected(result.codeResult.code);
      }
    },
    [onDetected]
  );

  const handleProcessed = (result: QuaggaJSResultObject) => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;
    drawingCtx.font = "24px Arial";
    drawingCtx.fillStyle = "green";

    if (result) {
      if (result.boxes) {
        // @ts-ignore
        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
        result.boxes
          .filter((box: any) => box !== result.box)
          .forEach((box: any[]) => {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "purple", lineWidth: 2 });
          });
      }
      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "blue", lineWidth: 2 });
      }
    }
  };

  useEffect(() => {
    if (scanning === false) {
      Quagga.offDetected(errorCheck);
      Quagga.offProcessed(handleProcessed);
      Quagga.stop();
    } else {
      Quagga.init(
        {
          inputStream: {
            type: "LiveStream",
            constraints: defaultConstraints,
            target: scannerRef.current,
            willReadFrequently: true,
          },
          locator: defaultLocatorSettings,
          numOfWorkers: navigator.hardwareConcurrency || 0,
          // @ts-ignore
          decoder: { readers: defaultDecoders },
          locate: true,
          debug: false,
        },
        (err) => {
          Quagga.onProcessed(handleProcessed);

          if (err) {
            return console.log("Error starting Quagga:", err);
          }
          if (scannerRef && scannerRef.current) {
            Quagga.start();
          }
        }
      );
      Quagga.onDetected(errorCheck);
      return () => {
        Quagga.offDetected(errorCheck);
        Quagga.offProcessed(handleProcessed);
        Quagga.stop();
      };
    }
  }, [scanning, onDetected, scannerRef, errorCheck]);

  const handleSubmit = () => {
    const contents = items.map((item: any) => {
      return {
        id: item.id,
        placeRemovedId: item.placeRemovedId,
      };
    }, []);

    setSubmitting(true);
    removeContents(source, contents)
      .then((response) => {
        onSuccess(response);
        setSubmittedMsg("Contents Removed");
        setSubmitting(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  const handleEnableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setSubmittedMsg("");
    setSubmittingErr("");
    setScanning(true);
  };

  const handleDisableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setScanning(false);
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
    if (onClose) onClose();
  };

  const handleAddItem = (e?: any) => {
    if (e) e.preventDefault();

    if (formData.identifier === "" || formData.placeRemoved == null) {
      if (formData.identifier === "") setFormErrors((prev: any) => ({ ...prev, identifier: "ID is required" }));
      if (formData.placeRemoved == null) setFormErrors((prev: any) => ({ ...prev, placeRemoved: "Place Removed is required" }));
      return;
    }

    // Find items in contents that match the scanned ID
    let matchingItems = contents.filter((item: any) => item.identifier === formData.identifier);

    // If no items match the scanned ID, set error
    if (matchingItems.length === 0) {
      setFormErrors((prev: any) => ({ ...prev, identifier: "Item not found" }));
      return;
    }

    // Filter out items that have been removed already
    if (matchingItems.some((item: any) => item.dateRemoved !== undefined)) {
      matchingItems = matchingItems.filter((item: any) => item.dateRemoved === undefined);
    }

    // If all the items have been removed already, set error
    if (matchingItems.length === 0) {
      setFormErrors((prev: any) => ({ ...prev, identifier: "Item already removed" }));
      // If only one item matches the scanned ID and it hasn't been removed or added to the list, add it to the list
    } else if (matchingItems.length === 1 && formData.placeRemoved !== null && formData.identifier !== "") {
      const remainingMatchingItems = matchingItems.filter((item: any) => !items.some((i: any) => i.id === item.id));
      if (remainingMatchingItems.length === 0) {
        setFormErrors((prev: any) => ({ ...prev, identifier: "Item already scanned" }));
      } else {
        setItems((prev: any) => [...prev, { ...matchingItems[0], placeRemovedId: formData.placeRemoved.value, placeRemovedName: formData.placeRemoved.label }]);
        setFormData((prev: any) => ({ ...prev, identifier: "" }));
      }
      // If multiple items match the scanned ID and none have been removed or added to the list, add the oldest one to the list
    } else if (matchingItems.length > 1) {
      const remainingMatchingItems = matchingItems.filter((item: any) => !items.some((i: any) => i.id === item.id));
      // If all the items have been added to the list, set error
      if (remainingMatchingItems.length === 0) {
        setFormErrors((prev: any) => ({ ...prev, identifier: "Item(s) already scanned" }));
        // If there are still items that haven't been added to the list, add the oldest one to the list
      } else {
        const oldestItem = remainingMatchingItems.reduce((prev: any, current: any) => (prev.dateAddedUnix < current.dateAddedUnix ? prev : current));
        setItems((prev: any) => [...prev, { ...oldestItem, placeRemovedId: formData.placeRemoved.value, placeRemovedName: formData.placeRemoved.label }]);
        setFormData((prev: any) => ({ ...prev, identifier: "" }));
        // If there are still multiple items that haven't been added to the list, set warning
        if (remainingMatchingItems.length > 1) {
          setMultipleIdsRemaining({ id: formData.identifier, count: remainingMatchingItems.length - 1 });
        } else {
          setMultipleIdsRemaining({ id: "", count: 0 });
        }
      }
    }
  };

  const handleDeleteItem = (original: any) => {
    setItems((prev: any) => prev.filter((item: any) => item.id !== original.id));
    setFormErrors((prev: any) => ({ ...prev, identifier: undefined }));
    setMultipleIdsRemaining({ id: "", count: 0 });
  };

  const columns = [
    {
      Header: "Actions",
      minWidth: 110,
      maxWidth: 110,
      filterable: false,
      sortable: false,
      Cell: ({ original }: any) => {
        return (
          <>
            <Tooltip content="Delete">
              <div
                onClick={() => {
                  handleDeleteItem(original);
                }}
                style={{ display: "flex", width: "100%", justifyContent: "center", height: "21px", padding: 0, cursor: "pointer" }}
              >
                <DeleteIcon fill={color.danger[2]} />
              </div>
            </Tooltip>
          </>
        );
      },
      Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
    },
    {
      id: "identifier",
      Header: "ID",
      accessor: "identifier",
      minWidth: 180,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["identifier"],
        }),
      filterAll: true,
    },
    {
      id: "name",
      Header: "Name",
      accessor: "name",
      minWidth: 180,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["name"],
        }),
      filterAll: true,
    },
    {
      id: "description",
      Header: "Description",
      accessor: "description",
      minWidth: 180,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["description"],
        }),
      filterAll: true,
    },
    {
      id: "placeRemovedName",
      Header: "Place Removed",
      accessor: "placeRemovedName",
      minWidth: 180,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["placeRemovedName"],
        }),
      filterAll: true,
    },
  ];

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={handleSubmit}
      onClose={handleClose}
      size={!submittedMsg && !submittingErr ? "lg" : "sm"}
      title="Remove Contents"
      success={submittedMsg}
      error={contentsErr || submittingErr}
      body={
        <LoadingContainer loading={contentsLoading || submitting}>
          <div ref={scannerRef} style={{ position: "relative", display: scanning ? "flex" : "none" }}>
            <canvas
              className="drawingBuffer"
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                border: "1px solid gray",
              }}
            />
          </div>
          <div style={{ display: scanning ? "none" : "block" }}>
            <form noValidate onSubmit={handleAddItem}>
              {multipleIdsRemaining.count > 0 && (
                <WarningAlert style={{ margin: "0" }}>
                  {multipleIdsRemaining.count} more instances of "{multipleIdsRemaining.id}" remain
                </WarningAlert>
              )}
              <ModalFormContainer>
                <label>ID</label>
                <QrScanButton title="Scan QR Code" onClick={handleEnableScan} />
                <FormInput
                  type="text"
                  name="identifier"
                  placeholder="XXXXXX"
                  value={formData.identifier}
                  error={formErrors.identifier}
                  onChange={handleChange}
                  required={true}
                />
                <FormError error={formErrors.identifier}>{formErrors.identifier}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label>Place Removed</label>
                <AsyncSelect
                  closeMenuOnSelect={true}
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.placeRemoved}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                  name="placeRemoved"
                  onChange={handleSelectChange}
                  placeholder="Select..."
                  value={formData.placeRemoved}
                />
                <FormError error={formErrors.placeRemoved}>{formErrors.placeRemoved}</FormError>
              </ModalFormContainer>
              {items.length > 0 && (
                <>
                  <ScreenHeading>Contents</ScreenHeading>
                  <Table filterable={true} style={{ clear: "both" }} data={items} columns={columns} ref={tableRef} defaultPageSize={15} />
                </>
              )}
              <input type="submit" hidden />
            </form>
          </div>
        </LoadingContainer>
      }
      footer={
        submittedMsg ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : submittingErr ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : (
          <>
            {scanning ? (
              <OutlineBtn onClick={handleDisableScan} width="100%">
                Back
              </OutlineBtn>
            ) : items.length > 0 ? (
              <>
                <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
                <OutlineBtn onClick={handleAddItem}>Remove</OutlineBtn>
                <PrimaryBtn onClick={handleSubmit}>Submit</PrimaryBtn>
              </>
            ) : (
              <>
                <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
                <PrimaryBtn onClick={handleAddItem}>Remove</PrimaryBtn>
              </>
            )}
          </>
        )
      }
    />
  );
};

export default ScanRemoveContentsModal;
