import React, { FC, useState, useEffect } from "react";
import Checkbox from "../Checkbox";
import { ModalFilterContainer } from "./styles";

// Sets the initial values of the checkboxes if there are URL query parameters.
// If there are none we use the default initial values
const getValues = (sensorFilters: any) => {
  const { readyForCollection } = sensorFilters;

  if (readyForCollection) {
    return { readyForCollection: true };
  }

  return {
    readyForCollection: false,
  };
};

const ReadyForCollection: FC<any> = ({ sensorFilters, setModalQuery }) => {
  const [values, setValues] = useState<any>(getValues(sensorFilters));

  // This is to update the values and button state when a user changes the query
  // parameters (e.g. when a user navigates using the back/forward buttons).
  // Each time the sensorFilters change (whenever the sensor query parameters change),
  // if the new values don't match the current values, update the values and button state
  useEffect(() => {
    const newValues = getValues(sensorFilters);
    if (JSON.stringify(values) !== JSON.stringify(newValues)) {
      setValues(newValues);
    }
  }, [sensorFilters]);

  // when a checkbox changes, updates the modal
  // query string state in the parent component
  useEffect(() => {
    updateModalQuery(values);
  }, [values]);

  const updateModalQuery = (values: any) => {
    if (values.readyForCollection) {
      setModalQuery((prev: any) => ({
        ...prev,
        readyForCollection: true,
      }));
    } else {
      setModalQuery((prev: any) => ({
        ...prev,
        readyForCollection: undefined,
      }));
    }
  };

  return (
    <ModalFilterContainer>
      <Checkbox
        style={{
          display: "block",
          padding: "6px",
          textAlign: "left",
        }}
        name="readyForCollection"
        label="Ready for Collection"
        checked={values.readyForCollection}
        onChange={(e: any) => {
          e.persist();
          setValues((prev: any) => ({
            ...prev,
            [e.target.name]: e.target.checked,
          }));
        }}
      />
    </ModalFilterContainer>
  );
};

export default ReadyForCollection;
