import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { FaPowerOff } from "react-icons/fa";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce";
import { getTrackerTableSettings, saveTrackerTableSettings, trackerTableDefaults } from "../../services/localStorage";
import { getTrackers } from "../../services/trackers";
import MoreIcon from "../../svgs/MoreIcon";
import { isAdmin, isAdminOrUser, isSuper } from "../../util/checkRole";
import { UPLOAD_RATE_CONVERSIONS } from "../../util/constants";
import copyToClipboard from "../../util/copyToClipboard";
import downloadFile from "../../util/downloadFile";
import errToStr from "../../util/errToStr";
import { humaniseMinutes } from "../../util/humaniseDurations";
import { kegOrTracker, kegsOrTrackers, onlyStickers } from "../../util/kegOrTracker";
import sortTags from "../../util/sortTags";
import { getTableFilters } from "../../util/urlParamFilters";
import ActivateSelectedSensorModal from "../ActivateSelectedSensorModal";
import ActivateSensorModal from "../ActivateSensorModal";
import AssetTypeLabel from "../AssetTypeLabel";
import Badge from "../Badge";
import { OutlineBtn, PrimaryBtn } from "../Buttons";
import CreateSensorsModal from "../CreateSensorsModal";
import DeactivateSelectedSensorModal from "../DeactivateSelectedSensorModal";
import EditTrackerModal from "../EditTrackerModal";
import { OutlineIconBtn, PrimaryIconBtn } from "../IconButtons";
import NewTable from "../NewTable";
import { DesktopDiv, MobileDiv } from "../NewTable/styles";
import Tag from "../Tag";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

type Tracker = {
  id: string;
  assetId: string;
  assetTypeId: string;
  assetTypeName: string;
  assetTypeColour: string;
  assetTypeIcon: string;
  nickname: string;
  latestSampleDateUnix: number;
  nextSampleDueUnix: number;
  dateCreatedUnix: number;
  dateProvisionedUnix: number;
  dateActivatedUnix: number;
  uploadRate: number;
  sleepCount: number;
  sleepDuration: number;
  organisationName: string;
  pickup: boolean;
  trackerTypeId: number;
  trackerTypeName: string;
  hasBeer: boolean;
  active: boolean;
};

const TrackersTable: FC<any> = () => {
  const { color, short_date, short_datetime, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>(isSuper() ? [{ id: "dateCreatedUnix", desc: true }] : [{ id: "id", desc: false }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [selectedSensor, setSelectedSensor] = useState<any>(undefined);

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [deactivateSelectedModalOpen, setDeactivateSelectedModalOpen] = useState<boolean>(false);
  const [activateSelectedModalOpen, setActivateSelectedModalOpen] = useState<boolean>(false);
  const [activateModalOpen, setActivateModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<any>(false);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getTrackerTableSettings() ? getTrackerTableSettings().pageSize : 20,
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const fetchData = () => {
    setDataLoading(true);
    setDataErr("");

    getTrackers(source, { pageIndex, pageSize, orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        setData(response.data);
        setCount(response.count);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const fetchCsv = (download: boolean) => {
    setDataLoading(true);
    setDataErr("");

    getTrackers(source, { orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        if (download) {
          downloadFile(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            }),
            "text/csv;charset=utf-8",
            kegsOrTrackers("Keg List.csv", "Tracker List.csv")
          );
        } else {
          copyToClipboard(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            })
          );
          toast.info("Copied to Clipboard");
        }
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const formatDataToCsv = (data: any) => {
    let headers = [];

    if (isSuper()) {
      headers = [
        "Tracker ID",
        "Asset ID",
        "Asset Type",
        kegsOrTrackers("Keg Tags", "Tracker Tags"),
        "Name",
        "Notes",
        "Last Seen",
        "Tracker Type",
        "Date Activated",
        "Marked for Pickup",
        "Date Created",
        "Date Provisioned",
        "Last Service Date",
        "Organisation",
        "Active",
      ];

      if (!onlyStickers()) {
        headers.splice(8, 0, "Next Sample Due");
        headers.splice(5, 0, "Upload Rate");
      }

      return [
        headers,
        ...data.map((row: any) => {
          let uploadRate = "";

          if (row.hasBeer && UPLOAD_RATE_CONVERSIONS[+row.uploadRate]) {
            uploadRate = UPLOAD_RATE_CONVERSIONS[+row.uploadRate];
          } else if (row.sleepCount && row.sleepDuration) {
            uploadRate = `1 upload / ${humaniseMinutes(row.sleepCount * row.sleepDuration, ["d", "h", "m", "s"])}`;
          }

          const rows = [
            row.id,
            row.assetId,
            row.assetTypeName,
            row.trackerTags
              .sort(sortTags)
              .map((tag: any) => tag.name)
              .join(", "),
            row.nickname,
            row.notes,
            row.latestSampleDateUnix ? moment.unix(row.latestSampleDateUnix).format() : "",
            row.trackerTypeName,
            row.dateActivatedUnix ? moment.unix(row.dateActivatedUnix).format() : "",
            row.pickup ? "True" : "False",
            row.dateCreatedUnix ? moment.unix(row.dateCreatedUnix).format() : "",
            row.dateProvisionedUnix ? moment.unix(row.dateProvisionedUnix).format() : "",
            row.lastServiceDateUnix ? moment.unix(row.lastServiceDateUnix).format() : "",
            row.organisationName,
            row.organisationName !== undefined ? "True" : "False",
          ];

          if (!onlyStickers()) {
            rows.splice(8, 0, row.nextSampleDueUnix ? moment.unix(row.nextSampleDueUnix).format() : "");
            rows.splice(5, 0, uploadRate);
          }

          return rows;
        }, []),
      ];
    } else {
      headers = [
        "Tracker ID",
        "Asset ID",
        "Asset Type",
        kegsOrTrackers("Keg Tags", "Tracker Tags"),
        "Name",
        "Notes",
        "Last Seen",
        "Tracker Type",
        "Date Activated",
        "Marked for Pickup",
      ];

      if (!onlyStickers()) {
        headers.splice(8, 0, "Next Sample Due");
        headers.splice(5, 0, "Upload Rate");
      }

      return [
        headers,
        ...data.map((row: any) => {
          let uploadRate = "";

          if (row.hasBeer && UPLOAD_RATE_CONVERSIONS[+row.uploadRate]) {
            uploadRate = UPLOAD_RATE_CONVERSIONS[+row.uploadRate];
          } else if (row.sleepCount && row.sleepDuration) {
            uploadRate = `1 upload / ${humaniseMinutes(row.sleepCount * row.sleepDuration, ["d", "h", "m", "s"])}`;
          }

          const rows = [
            row.id,
            row.assetId,
            row.assetTypeName,
            row.trackerTags
              .sort(sortTags)
              .map((tag: any) => tag.name)
              .join(", "),
            row.nickname,
            row.notes,
            row.latestSampleDateUnix ? moment.unix(row.latestSampleDateUnix).format() : "",
            row.trackerTypeName,
            row.dateActivatedUnix ? moment.unix(row.dateActivatedUnix).format() : "",
            row.pickup ? "True" : "False",
          ];

          if (!onlyStickers()) {
            rows.splice(8, 0, row.nextSampleDueUnix ? moment.unix(row.nextSampleDueUnix).format() : "");
            rows.splice(5, 0, uploadRate);
          }
          return rows;
        }, []),
      ];
    }
  };

  const columns = React.useMemo<ColumnDef<Tracker>[]>(
    () => [
      {
        id: "actions",
        header: "",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  {isAdminOrUser() ? (
                    <MenuButton
                      onClick={() => {
                        setSelectedSensor(row.original);
                        setEditModalOpen(true);
                      }}
                    >
                      Edit {kegOrTracker("Keg", "Tracker")}
                    </MenuButton>
                  ) : (
                    <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                      <div
                        style={{
                          cursor: "not-allowed",
                          userSelect: "none",
                        }}
                      >
                        <MenuButton disabled={true}>Edit {kegOrTracker("Keg", "Tracker")}</MenuButton>
                      </div>
                    </Tooltip>
                  )}

                  {isSuper() && row.original.organisationName === undefined && (
                    <MenuButton
                      onClick={() => {
                        setSelectedSensor(row.original);
                        setActivateSelectedModalOpen(true);
                      }}
                    >
                      Activate Tracker
                    </MenuButton>
                  )}
                  {isAdmin() && row.original.organisationName !== undefined && (
                    <DangerMenuButton
                      onClick={() => {
                        setSelectedSensor(row.original);
                        setDeactivateSelectedModalOpen(true);
                      }}
                    >
                      Deactivate Tracker
                    </DangerMenuButton>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        size: 65,
        minSize: 65,
      },
      {
        header: "Tracker ID",
        accessorKey: "id",
        cell: (props: any) => {
          const url = kegOrTracker("kegs", "trackers");
          return (
            <Link title={props.getValue()} to={`/${url}/${props.getValue()}`}>
              {props.getValue()}
            </Link>
          );
        },
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 130,
      },
      {
        header: "Asset ID",
        accessorKey: "assetId",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 130,
      },
      {
        header: "Asset Type",
        accessorKey: "assetTypeName",
        cell: (props: any) => (
          <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
            <AssetTypeLabel name={props.getValue()} colour={props.row.original.assetTypeColour} icon={props.row.original.assetTypeIcon} />
          </div>
        ),
        meta: {
          filterType: "autoComplete",
          selectKey: "label",
          loadOptionsKey: "assettypes",
        },
        filterFn: undefined,
        size: 200,
      },
      {
        accessorKey: "trackerTags",
        cell: (props: any) =>
          props.row.original.trackerTags ? (
            props.row.original.trackerTags
              .sort(sortTags)
              .map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
          ) : (
            <></>
          ),
        header: kegsOrTrackers("Keg Tags", "Tracker Tags"),
        enableSorting: false,
        meta: {
          filterType: "autoComplete",
          filterKey: "trackerTags.name",
          loadOptionsKey: "trackerTags",
        },
        filterFn: undefined,
        size: 170,
      },
      {
        header: "Name",
        accessorKey: "nickname",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 230,
      },
      {
        header: "Notes",
        accessorKey: "notes",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 230,
      },
      ...(!onlyStickers()
        ? [
            {
              accessorKey: "uploadRate",
              header: "Upload Rate",
              cell: (props: any) => {
                if (props.row.original.hasBeer && UPLOAD_RATE_CONVERSIONS[props.getValue() as number]) {
                  return <span>{UPLOAD_RATE_CONVERSIONS[props.getValue() as number]}</span>;
                } else if (props.row.original.sleepCount && props.row.original.sleepDuration) {
                  return <span>1 upload / {humaniseMinutes(props.row.original.sleepCount * props.row.original.sleepDuration, ["d", "h", "m", "s"])}</span>;
                } else {
                  return <></>;
                }
              },
              meta: {
                filterType: "number",
                title: "Minutes",
              },
              filterFn: undefined,
              size: 170,
            },
          ]
        : []),
      {
        header: "Last Seen",
        accessorKey: "latestSampleDateUnix",
        cell: (props: any) =>
          props.getValue() ? (
            <ReactTimeago live={false} date={(props.getValue() as number) * 1000} title={moment.unix(props.getValue()).format(long_datetime)} />
          ) : (
            ""
          ),
        meta: {
          filterType: "dateRangeUnix",
        },
        filterFn: undefined,
        size: 150,
      },
      {
        header: "Tracker Type",
        accessorKey: "trackerTypeName",
        meta: {
          filterType: "autoComplete",
          selectKey: "label",
          loadOptionsKey: "trackertypes",
        },
        filterFn: undefined,
        size: 160,
      },
      {
        header: "Date Activated",
        accessorKey: "dateActivatedUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRangeUnix",
        },
        filterFn: undefined,
        size: 180,
      },
      ...(!onlyStickers()
        ? [
            {
              header: "Next Sample Due",
              accessorKey: "nextSampleDueUnix",
              cell: (props: any) =>
                props.getValue() ? (
                  <ReactTimeago live={false} date={(props.getValue() as number) * 1000} title={moment.unix(props.getValue()).format(long_datetime)} />
                ) : (
                  ""
                ),
              meta: {
                filterType: "dateRangeUnix",
              },
              filterFn: undefined,
              size: 190,
            },
          ]
        : []),
      {
        header: "Marked for Pickup",
        accessorKey: "pickup",
        cell: (props: any) => (
          <div style={{ textAlign: "center" }}>
            <Badge title={props.getValue() ? "True" : "False"} background={props.getValue() ? color.success_dark[2] : color.danger_dark[2]}>
              {props.getValue() ? "True" : "False"}
            </Badge>
          </div>
        ),
        meta: {
          filterType: "select",
          loadOptionsKey: "pickup",
          selectKey: "value",
          selectOptions: [
            { value: true, label: "True", colour: color.success_dark[2] },
            { value: false, label: "False", colour: color.danger_dark[2] },
          ],
        },
        filterFn: undefined,
        size: 190,
      },
      ...(isSuper()
        ? [
            {
              header: "Date Created",
              accessorKey: "dateCreatedUnix",
              cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
              meta: {
                filterType: "dateRangeUnix",
              },
              filterFn: undefined,
              size: 180,
            },
          ]
        : []),
      ...(isSuper()
        ? [
            {
              header: "Date Provisioned",
              accessorKey: "dateProvisionedUnix",
              cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
              meta: {
                filterType: "dateRangeUnix",
              },
              filterFn: undefined,
              size: 180,
            },
          ]
        : []),
      ...(isSuper()
        ? [
            {
              header: "Last Service Date",
              accessorKey: "lastServiceDateUnix",
              cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_date) : ""),
              meta: {
                filterType: "dateRangeUnix",
              },
              filterFn: undefined,
              size: 180,
            },
          ]
        : []),
      ...(isSuper()
        ? [
            {
              header: "Organisation",
              accessorKey: "organisationName",
              meta: {
                filterType: "autoComplete",
                selectKey: "label",
                loadOptionsKey: "organisations",
              },
              filterFn: undefined,
              size: 200,
            },
          ]
        : []),
      ...(isSuper()
        ? [
            {
              header: "Active",
              accessorKey: "active",
              cell: (props: any) => {
                const active = props.row.original.organisationName !== undefined;
                return (
                  <div style={{ textAlign: "center" }}>
                    <Badge title={active ? "Active" : "Inactive"} background={active ? color.success_dark[2] : color.danger_dark[2]}>
                      {active ? "Active" : "Inactive"}
                    </Badge>
                  </div>
                );
              },
              meta: {
                filterType: "select",
                loadOptionsKey: "active",
                selectKey: "value",
                selectOptions: [
                  { value: true, label: "Active", colour: color.success_dark[2] },
                  { value: false, label: "Inactive", colour: color.danger_dark[2] },
                ],
              },
              filterFn: undefined,
              size: 190,
            },
          ]
        : []),
    ],
    [color]
  );

  return (
    <>
      <NewTable
        data={data}
        count={count}
        dataErr={dataErr}
        dataLoading={dataLoading}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        columnFiltersDebounced={columnFiltersDebounced}
        setColumnFilters={setColumnFilters}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        fetchData={fetchData}
        fetchCsv={fetchCsv}
        defaultTableSettings={trackerTableDefaults}
        getTableSettings={getTrackerTableSettings}
        saveTableSettings={saveTrackerTableSettings}
        dataTypeName={kegsOrTrackers("Kegs", "Trackers")}
        emptyDataMsg="Activate a tracker above to get started"
        TableButtons={
          <div style={{ display: "flex" }}>
            {isAdmin() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setActivateModalOpen(true);
                    }}
                  >
                    Activate
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Activate">
                    <PrimaryIconBtn
                      onClick={() => {
                        setActivateModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryBtn disabled={true}>Activate</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div style={{ cursor: "not-allowed", userSelect: "none" }}>
                      <PrimaryIconBtn disabled={true}>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
            {isSuper() && (
              <>
                <DesktopDiv>
                  <OutlineBtn
                    style={{ marginLeft: "12px" }}
                    onClick={() => {
                      setCreateModalOpen(true);
                    }}
                  >
                    Create
                  </OutlineBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <OutlineIconBtn
                      onClick={() => {
                        setCreateModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.font[2], size: "16px" }}>
                        <FaPowerOff />
                      </IconContext.Provider>
                    </OutlineIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
        }
      />
      {editModalOpen && <EditTrackerModal id={selectedSensor.id} onSuccess={fetchData} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
      {activateModalOpen && <ActivateSensorModal onSuccess={fetchData} modalOpen={activateModalOpen} setModalOpen={setActivateModalOpen} />}
      {activateSelectedModalOpen && (
        <ActivateSelectedSensorModal
          id={selectedSensor.id}
          onSuccess={fetchData}
          modalOpen={activateSelectedModalOpen}
          setModalOpen={setActivateSelectedModalOpen}
        />
      )}
      {deactivateSelectedModalOpen && (
        <DeactivateSelectedSensorModal
          id={selectedSensor.id}
          onSuccess={fetchData}
          modalOpen={deactivateSelectedModalOpen}
          setModalOpen={setDeactivateSelectedModalOpen}
        />
      )}
      {createModalOpen && <CreateSensorsModal onSuccess={fetchData} modalOpen={createModalOpen} setModalOpen={setCreateModalOpen} />}
    </>
  );
};

export default TrackersTable;
