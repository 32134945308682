import React, { FC, useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";
import Tooltip from "../Tooltip";
import downloadFile from "../../util/downloadFile";
import { stringify } from "csv-stringify/browser/esm/sync";
import copyToClipboard from "../../util/copyToClipboard";
import { toast } from "react-toastify";
import { getSuggestedPlaces, dismissSuggestedPlace } from "../../services/suggestedPlaces";
import DeleteModal from "../DeleteModal";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { isAdminOrUser } from "../../util/checkRole";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import MoreIcon from "../../svgs/MoreIcon";
import { MenuList, MenuButton, DangerMenuButton } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";
import { PaginationState, ColumnDef, SortingState, ColumnFiltersState } from "@tanstack/react-table";
import { getTableFilters } from "../../util/urlParamFilters";
import { getSuggestedPlaceTableSettings, saveSuggestedPlaceTableSettings, suggestedPlaceTableDefaults } from "../../services/localStorage";
import moment from "moment";
import { useDebounce } from "use-debounce";
import Tag from "../Tag";
import CreatePlaceModal from "../CreatePlaceModal";
import NewTable from "../NewTable";

type SuggestedPlace = {
  id: number;
  name: string;
  organisationId: string;
  latitude: number;
  longitude: number;
  timestamp: string;
  timestampUnix: number;
  sampleCount: number;
  trackerCount: number;
  formattedAddress: string;
};

const SuggestedPlacesTable: FC<any> = () => {
  const { color, short_datetime, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>([{ id: "trackerCount", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [selectedId, setSelectedId] = useState<any>(undefined);
  const [createModalOpen, setCreateModalOpen] = useState<any>(false);
  const [dismissModalOpen, setDismissModalOpen] = useState<boolean>(false);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getSuggestedPlaceTableSettings() ? getSuggestedPlaceTableSettings().pageSize : 20,
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const fetchSuggestedPlaces = () => {
    setDataLoading(true);
    setDataErr("");

    getSuggestedPlaces(source, { pageIndex, pageSize, orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        setData(response.data);
        setCount(response.count);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const fetchCsv = (download: boolean) => {
    setDataLoading(true);
    setDataErr("");

    getSuggestedPlaces(source, { orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        if (download) {
          downloadFile(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            }),
            "text/csv;charset=utf-8",
            "Suggested Places List.csv"
          );
        } else {
          copyToClipboard(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            })
          );
          toast.info("Copied to Clipboard");
        }
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const formatDataToCsv = (data: any) => {
    const headers = ["Name", "Address", "Types", "Latest Sample Date", "Sample Count", kegsOrTrackers("Keg Count", "Tracker Count")];

    return [
      headers,
      ...data.map((row: any) => {
        const rows = [
          row.name,
          row.formattedAddress,
          row.types.sort((a: any, b: any) => a.localeCompare(b)).join(", "),
          row.timestampUnix !== undefined ? moment.unix(row.timestampUnix).format(long_datetime) : "",
          row.sampleCount,
          row.trackerCount,
        ];

        return rows;
      }, []),
    ];
  };

  const columns = React.useMemo<ColumnDef<SuggestedPlace>[]>(
    () => [
      {
        id: "actions",
        header: "",
        enableColumnFilter: false,
        enableSorting: false,
        enableHiding: false,
        cell: ({ row }) => {
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  {isAdminOrUser() ? (
                    <>
                      <MenuButton
                        onClick={() => {
                          setSelectedId(row.original.id);
                          setCreateModalOpen(true);
                        }}
                      >
                        Create Place
                      </MenuButton>

                      <DangerMenuButton
                        onClick={() => {
                          setSelectedId(row.original.id);
                          setDismissModalOpen(true);
                        }}
                      >
                        Dismiss Suggestion
                      </DangerMenuButton>
                    </>
                  ) : (
                    <>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Create Place</MenuButton>
                        </div>
                      </Tooltip>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Dismiss Suggestion</MenuButton>
                        </div>
                      </Tooltip>
                    </>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        size: 65,
        minSize: 65,
      },
      {
        accessorKey: "name",
        header: "Name",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 220,
      },
      {
        accessorKey: "formattedAddress",
        header: "Address",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 290,
      },
      {
        accessorKey: "types",
        cell: (props: any) =>
          props.getValue() ? (
            props
              .getValue()
              .sort((a: any, b: any) => a.localeCompare(b))
              .map((type: any) => <Tag key={type} name={type} colour={color.default[2]} />)
          ) : (
            <></>
          ),
        header: "Types",
        enableSorting: false,
        meta: {
          filterType: "stringList",
          selectKey: "Types",
        },
        filterFn: undefined,
        size: 170,
      },
      {
        header: "Latest Sample Date",
        accessorKey: "timestampUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRangeUnix",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        accessorKey: "sampleCount",
        header: "Sample Count",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 170,
      },
      {
        accessorKey: "trackerCount",
        header: "Tracker Count",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 170,
      },
    ],
    [color]
  );

  return (
    <>
      <NewTable
        data={data}
        count={count}
        dataErr={dataErr}
        dataLoading={dataLoading}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        columnFiltersDebounced={columnFiltersDebounced}
        setColumnFilters={setColumnFilters}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        fetchData={fetchSuggestedPlaces}
        fetchCsv={fetchCsv}
        defaultTableSettings={suggestedPlaceTableDefaults}
        getTableSettings={getSuggestedPlaceTableSettings}
        saveTableSettings={saveSuggestedPlaceTableSettings}
        dataTypeName="Suggested Places"
        emptyDataMsg="No suggested places have been found yet"
      />
      {createModalOpen && (
        <CreatePlaceModal suggestedPlaceId={selectedId} updateData={fetchSuggestedPlaces} modalOpen={createModalOpen} setModalOpen={setCreateModalOpen} />
      )}
      {dismissModalOpen && (
        <DeleteModal
          title="Dismiss Suggested Place"
          body={
            <>
              <span>Are you sure you want to dismiss this suggested place?</span>
            </>
          }
          successMsg="Suggestion Dismissed"
          onDelete={() => setData((prev: any) => prev.filter((row: any) => row.id !== selectedId))}
          onClose={() => setSelectedId(undefined)}
          modalOpen={dismissModalOpen}
          setModalOpen={setDismissModalOpen}
          deleteService={dismissSuggestedPlace}
          serviceParams={[selectedId]}
          submitBtnText="Dismiss"
        />
      )}
    </>
  );
};

export default SuggestedPlacesTable;
