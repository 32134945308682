import React from "react";
import Tippy from "@tippy.js/react";
import { IProps } from "./types";
import isTouch from "../../util/isTouch";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";

const Tooltip: React.FC<IProps> = ({ touch = !isTouch, trigger = "mouseenter click", theme = "binary", ...rest }) => {
  return (
    <>
      <Tippy {...rest} theme={theme} animation="shift-away" duration={[100, 100]} touch={touch} arrow={true} trigger={trigger} boundary="window" />
    </>
  );
};

export default Tooltip;
