interface UploadRateType {
  [minutes: number]: string;
}

export const UPLOAD_RATE_CONVERSIONS: UploadRateType = {
  1440: "1 upload / day",
  720: "1 upload / 12 hours",
  480: "1 upload / 8 hours",
  360: "1 upload / 6 hours",
  180: "1 upload / 3 hours",
  120: "1 upload / 2 hours",
  60: "1 upload / 1 hour",
};
