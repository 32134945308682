const isDark = (bgColour: string) => {
  if (bgColour) {
    const colour = bgColour.charAt(0) === "#" ? bgColour.substring(1, 7) : bgColour;
    const r = parseInt(colour.substring(0, 2), 16); // hexToR
    const g = parseInt(colour.substring(2, 4), 16); // hexToG
    const b = parseInt(colour.substring(4, 6), 16); // hexToB
    const uiColours = [r / 255, g / 255, b / 255];
    const c = uiColours.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92;
      }
      return Math.pow((col + 0.055) / 1.055, 2.4);
    });
    const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
    return L < 0.179;
  } else {
    return false;
  }
};

export default isDark;
