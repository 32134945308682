import axios, { CancelTokenSource } from "axios";
import { FC, useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { createPickupRequestType, editPickupRequestType } from "../../services/pickupRequestTypes";
import errToStr from "../../util/errToStr";
import { exists, maxLength, validName } from "../../util/formValidations";
import ColorPicker from "../ColorPicker";
import { FormError, FormInput } from "../FormComponents";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { isBinaryLoop } from "../../util/checkDomain";

const initType = (type: any, echarts_palette: any) => {
  return {
    id: null,
    name: "",
    colour: echarts_palette[Math.floor(Math.random() * echarts_palette.length)],
    ...type,
  };
};

const EditPickupRequestTypeModal: FC<any> = ({ type, fetch, modalOpen, setModalOpen }) => {
  const { echarts_palette } = useContext(ThemeContext);

  const [formData, setFormData] = useState<any>(initType(type, echarts_palette));
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "name":
          currValid = exists(name, value, setFormErrors) && maxLength(name, value, 200, setFormErrors) && validName(name, value, setFormErrors);
          break;

        case "colour":
          currValid = exists(name, value, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const formattedData: any = {
      id: formData.id,
      name: formData.name,
      colour: formData.colour,
    };

    return formattedData;
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);

      if (formData.id) {
        editPickupRequestType(source, formData.id, body)
          .then((response) => {
            if (fetch) fetch();
            setSubmittedMsg(isBinaryLoop() ? "Contents Type Updated" : "Pickup Request Type Updated");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      } else {
        createPickupRequestType(source, body)
          .then((response) => {
            if (fetch) fetch();
            setSubmittedMsg(isBinaryLoop() ? "Contents Type Created" : "Pickup Request Type Created");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      }
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      size={!submittedMsg && !submittingErr ? "lg" : "sm"}
      title={
        formData.id ? `Edit ${isBinaryLoop() ? "Contents Type" : "Pickup Request Type"}` : `Create ${isBinaryLoop() ? "Contents Type" : "Pickup Request Type"}`
      }
      success={submittedMsg}
      error={submittingErr}
      submitBtnText={formData.id ? "Update" : "Create"}
      body={
        <LoadingContainer loading={submitting}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer>
              <label>Name</label>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <FormInput type="text" name="name" value={formData.name} error={formErrors.name} onChange={handleChange} />
                </div>
                <div style={{ width: "100%", maxWidth: "34px" }}>
                  <ColorPicker color={formData.colour} setColor={(color: any) => setFormData((prev: any) => ({ ...prev, colour: color.hex }))} />
                </div>
              </div>
              <FormError error={formErrors.name}>{formErrors.name}</FormError>
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default EditPickupRequestTypeModal;
