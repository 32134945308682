const hexLum = (hex: string, lum: number) => {
  // validate hex string
  let hexArray = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hexArray.length < 6) {
    hexArray = hexArray[0] + hexArray[0] + hexArray[1] + hexArray[1] + hexArray[2] + hexArray[2];
  }
  lum = lum || 0;

  const opacity = hexArray.length === 8 ? [hexArray[6], hexArray[7]].join("") : "";

  // convert to decimal and change luminosity
  let rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hexArray.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  rgb = rgb + opacity;

  return rgb;
};

export default hexLum;
