import * as React from "react";
import { SVGProps } from "react";

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
    <path d="M157 45c-16.5 6.1-27.3 20-28.7 37.1-2.1 25.4 20.2 47.7 45.6 45.6 17.6-1.5 32.2-13.2 37.5-30.2 2.8-8.8 1.9-21.1-2.1-29.7-4.4-9.3-10.9-16-20.1-20.5-6.8-3.4-8.3-3.8-17.1-4-7.7-.3-10.7.1-15.1 1.7zM328.5 44.7c-12.1 4.4-20.5 11.8-25.8 23.1-3 6.3-3.2 7.4-3.2 17.7 0 10.5.2 11.4 3.4 18 13.3 27 48.5 32.7 69 11 20.1-21.2 14-54.6-12.3-67.5-6.2-3.1-8-3.5-16.5-3.7-7.3-.3-10.8.1-14.6 1.4zM162 214.4c-8.4 1.8-14.9 5.4-21.3 11.7-16.8 16.7-16.8 43 0 59.8 16.6 16.5 42 16.9 58.8 1 9.2-8.7 13.5-18.5 13.5-30.9 0-12.3-4.3-22.2-13.3-30.8-6.5-6.1-11.9-8.9-20.6-10.8-7.8-1.6-9.8-1.6-17.1 0zM332.4 214.5c-8.2 1.8-13.7 4.7-20.1 10.7-9 8.6-13.3 18.5-13.3 30.8 0 12.4 4.3 22.2 13.5 30.9 16.8 15.9 42.2 15.5 58.8-1 16.8-16.8 16.8-43 0-59.8-6.5-6.4-13.9-10.4-22.7-12-7.2-1.3-8.4-1.3-16.2.4zM159.9 385.4c-11.2 3.2-22.1 12.1-27.2 22.2-11.8 23.6.3 51.8 25.8 59.8 4.2 1.4 7.9 1.7 14.5 1.4 7.7-.4 9.8-.9 16.3-4.1 14.3-7.1 22.8-19.9 23.5-35.8.5-10.6-1.2-17.1-6.7-25.6-4.5-7-12.5-13.5-20.3-16.5-6.7-2.5-19.6-3.3-25.9-1.4zM330 385.6c-11.6 3.1-21.4 11.5-27.1 22.9-3.2 6.6-3.4 7.5-3.4 18 0 10.3.2 11.4 3.2 17.7 4.4 9.4 10.9 16 20.2 20.6 7.3 3.6 7.8 3.7 18.6 3.7 10.6 0 11.3-.2 18-3.4 26.4-13 32.5-46.3 12.4-67.6-10.9-11.5-26.7-16-41.9-11.9z" />
  </svg>
);

export default SvgComponent;
