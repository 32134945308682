import styled from "../Themes";

export const Panel = styled(`div`)`
  overflow: hidden;
  padding: 12px;
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  box-shadow: ${({ theme }) => theme.boxShadow.s};

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);

  @media (max-width: 768px) {
    padding: 6px;
    margin-bottom: 12px;
  }
`;

export const StatPanel = styled(`div`)`
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;

  overflow: hidden;
  padding: 12px 16px;
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  box-shadow: ${({ theme }) => theme.boxShadow.s};

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);

  @media (max-width: 768px) {
    padding: 6px 8px;
    margin-bottom: 12px;
  }
`;

export const ClearPanel = styled(`div`)`
  overflow: hidden;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    margin-bottom: 12px;
  }
`;
