import axios from "axios";
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { fetchPlaceTrackerChart } from "../../services/placeTrackerChart";
import { isBinaryBeer } from "../../util/checkDomain";
import errToStr from "../../util/errToStr";
import hexToRgba from "../../util/hexToRgba";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import ChartHeading from "../ChartHeading";
import LoadingContainer from "../LoadingContainer";
import { ChartWrapper } from "../PlaceScreen/styles";
import { NoDataComponent } from "../Table";

const PlaceTrackerChart: FC<any> = ({ id, filterDates, meta }) => {
  const { echarts_theme, short_datetime } = useContext(ThemeContext);

  const echartsRef = useRef(null);
  const echartsContainerRef = useRef(null);

  const [trackerCount, setTrackerCount] = useState<any>([]);
  const [trackerCountLoading, setTrackerCountLoading] = useState<boolean>(true);
  const [trackerCountErr, setTrackerCountErr] = useState<string>("");

  useEffect(() => {
    const source = axios.CancelToken.source();

    // if end date is undefined set to current date
    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined ? filterDates.end.unix() : moment().unix(),
    };

    setTrackerCountLoading(true);
    fetchPlaceTrackerChart(source, id, dates)
      .then((response) => {
        if (response && response.length > 0) {
          // if not binarybeer combine series full and empty counts
          if (isBinaryBeer()) {
            setTrackerCount(response);
          } else {
            setTrackerCount(
              response.map((el: any) => {
                return { ...el, full: 0, empty: 0 };
              })
            );
          }
        }
        setTrackerCountErr("");
        setTrackerCountLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setTrackerCount([]);
          setTrackerCountErr(errToStr(err));
          setTrackerCountLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [id, filterDates]);

  const getOptions = (): any => {
    return {
      legend: {
        type: "scroll",
        bottom: 0,
      },
      grid: { top: 20, right: 80, bottom: 60, left: 80 },
      dataset: [
        {
          dimensions: isBinaryBeer() ? ["ts", "full", "empty"] : ["ts", "total"],
          source: trackerCount,
        },
      ],
      tooltip: {
        confine: true,
        trigger: "axis",
        transitionDuration: 0,
        axisPointer: {
          type: "cross",
          snap: true,
        },
        formatter: (params: any) => {
          const tooltips: any = [];
          let tooltip = `<span>`;

          if (params.length > 0) {
            tooltip += `${moment.unix(params[0].value["ts"]).format(short_datetime)}<br />`;

            if (isBinaryBeer()) {
              tooltip += `Total: ${params[0].value["total"]}<br />`;
              for (let i = 0; i < params.length; i++) {
                const param = params[i];
                if (param.seriesId !== "total") {
                  const value = param.value[param.seriesId] || 0;
                  const tooltipStr = `${param.marker} ${param.seriesName}: ${value}<br />`;
                  if (!tooltips.includes(tooltipStr)) tooltips.push(tooltipStr);
                }
              }
            } else {
              for (let i = 0; i < params.length; i++) {
                const param = params[i];
                if (param.seriesId === "total") {
                  const value = param.value[param.seriesId] || 0;
                  const tooltipStr = `${param.marker} ${param.seriesName}: ${value}<br />`;
                  if (!tooltips.includes(tooltipStr)) tooltips.push(tooltipStr);
                }
              }
            }
          }

          tooltip += tooltips.reverse().join("") + "</span>";
          return tooltip;
        },
      },
      xAxis: {
        type: "time",
        min: "dataMin",
        max: "dataMax",
        axisLabel: {
          hideOverlap: true,
          formatter: (value: any): string => moment.unix(value).format(short_datetime),
        },
        axisPointer: {
          label: {
            show: false,
          },
        },
      },
      yAxis: [
        {
          name: kegsOrTrackers("Kegs Here", "Trackers Here"),
          type: "value",
          minInterval: 1,
          nameLocation: "middle",
          nameGap: 60,
          position: "left",
          axisLabel: {
            formatter: (value: any) => +value.toFixed(0),
          },
          axisPointer: {
            label: {
              formatter: ({ value }: any) => +value.toFixed(0),
            },
          },
          axisLine: {
            show: true,
          },
        },
      ],
      dataZoom: [
        {
          type: "inside",
        },
      ],
      series: [
        ...(isBinaryBeer()
          ? [
              {
                id: "full",
                name: "Full",
                type: "line",
                stack: "Total",
                symbol: "none",
                yAxisIndex: 0,
                itemStyle: {
                  color: hexToRgba(meta.placeGroupColour ? meta.placeGroupColour : undefined, 100),
                },
                areaStyle: {
                  color: hexToRgba(meta.placeGroupColour ? meta.placeGroupColour : undefined, 80),
                },
                smooth: true,
                smoothMonotone: "x",
                connectNulls: true,
                encode: {
                  x: "ts",
                  y: "full",
                },
              },
            ]
          : []),
        ...(isBinaryBeer()
          ? [
              {
                id: "empty",
                name: "Empty",
                type: "line",
                stack: "Total",
                symbol: "none",
                yAxisIndex: 0,
                itemStyle: {
                  color: hexToRgba(meta.placeGroupColour ? meta.placeGroupColour : undefined, 30),
                },
                areaStyle: {
                  color: hexToRgba(meta.placeGroupColour ? meta.placeGroupColour : undefined, 30),
                },
                smooth: true,
                smoothMonotone: "x",
                connectNulls: true,
                encode: {
                  x: "ts",
                  y: "empty",
                },
              },
            ]
          : []),
        ...(!isBinaryBeer()
          ? [
              {
                id: "total",
                name: "Total",
                type: "line",
                stack: "Total",
                symbol: "none",
                yAxisIndex: 0,
                itemStyle: {
                  color: hexToRgba(meta.placeGroupColour ? meta.placeGroupColour : undefined, 100),
                },
                areaStyle: {
                  color: hexToRgba(meta.placeGroupColour ? meta.placeGroupColour : undefined, 80),
                },
                smooth: true,
                smoothMonotone: "x",
                connectNulls: true,
                encode: {
                  x: "ts",
                  y: "total",
                },
              },
            ]
          : []),
      ],
      animation: false,
    };
  };

  if (trackerCount.length === 0) {
    return <></>;
  }

  return (
    <LoadingContainer loading={trackerCountLoading} err={trackerCountErr}>
      {!trackerCountLoading && (
        <ChartWrapper>
          <>
            <ChartHeading>{kegsOrTrackers("Kegs", "Trackers")}</ChartHeading>
            {trackerCountLoading ? (
              <div></div>
            ) : trackerCount.length > 0 ? (
              <div style={{ height: "calc(100% - 42px)", width: "100%" }} ref={echartsContainerRef}>
                <ReactEcharts
                  ref={echartsRef}
                  style={{ height: "100%", width: "100%" }}
                  option={getOptions()}
                  notMerge={true}
                  lazyUpdate={true}
                  theme={echarts_theme}
                  opts={{ renderer: "svg" }}
                />
              </div>
            ) : (
              <NoDataComponent style={{ top: "65%" }}>No logs found</NoDataComponent>
            )}
          </>
        </ChartWrapper>
      )}
    </LoadingContainer>
  );
};

export default PlaceTrackerChart;
