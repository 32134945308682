import { createGlobalStyle } from "../Themes";

export default createGlobalStyle`
.block-picker {
    background: ${({ theme }) => theme.color.tooltip_bg[2]} !important;
    color: ${({ theme }) => theme.color.font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color.border[2]};
	box-shadow: 0 1px 14px 0 ${({ theme }) => theme.color.tooltip_shadow[2]};
}

.block-picker input {
    background: ${({ theme }) => theme.color.input_bg[2]} !important;
    color: ${({ theme }) => theme.color.font_bold[2]} !important;
    box-shadow: none !important;
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-weight: ${({ theme }) => theme.fontWeight.medium}; 
    border: 1px solid ${({ theme }) => theme.color_30.panel_fg[0]} !important;
}

.sketch-picker {
    background: ${({ theme }) => theme.color.tooltip_bg[2]} !important;
    color: ${({ theme }) => theme.color.font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color.border[2]};
	box-shadow: 0 1px 14px 0 ${({ theme }) => theme.color.tooltip_shadow[2]};
}

.sketch-picker input {
    background: ${({ theme }) => theme.color.input_bg[2]} !important;
    color: ${({ theme }) => theme.color.font_bold[2]} !important;
    box-shadow: none !important;
    border: 1px solid ${({ theme }) => theme.color_30.panel_fg[0]} !important;
}

.sketch-picker label {
    color: ${({ theme }) => theme.color.font_bold[2]} !important;
}

.sketch-picker div:last-child {
  border-top: 1px solid ${({ theme }) => theme.color.border[2]} !important;
}
`;
