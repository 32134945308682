import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path d="M506.603 372.477l-96-80A14.999 14.999 0 00386 304v33h-49c-44.448 0-82-37.093-82-81 0-38.526-12.121-74.858-35.012-105.016C187.031 107.162 134.699 81 80 81H47c-25.916 0-47 21.084-47 47s21.084 47 47 47h33c44.664 0 81 36.336 81 81 0 38.526 12.121 74.858 35.012 105.016C228.969 404.838 281.675 431 337 431h49v33a15 15 0 0024.603 11.523l96-80a15 15 0 000-23.046zM172.077 379.103c-15.441-20.357-26.69-43.074-33.475-67.273C123.839 327.319 103.036 337 80 337H47c-25.916 0-47 21.084-47 47s21.084 47 47 47h33c38.359 0 75.543-12.88 105.708-35.68a201.144 201.144 0 01-13.631-16.217z" />
      <path d="M506.603 116.477l-96-80A14.999 14.999 0 00386 48v33h-49c-38.818 0-76.337 12.895-106.66 35.717a202.96 202.96 0 0113.592 16.192c15.579 20.542 26.89 43.488 33.653 67.935C292.657 185.043 313.889 175 337 175h49v33a15 15 0 0024.603 11.523l96-80a15 15 0 000-23.046z" />
    </svg>
  );
}

export default SvgComponent;
