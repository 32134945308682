import React, { FC } from "react";

const Close: FC<any> = ({ background, cross }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill={background} d="M505.1 256C505.1 119 394 7.9 257 7.9S8.9 119 8.9 256 120 504.1 257 504.1 505.1 393 505.1 256z" />
    <path
      fill={cross}
      d="M286 256l72.5-84.2c7.9-9.2 6.9-23-2.3-31-9.2-7.9-23-6.9-30.9 2.3L257 222.4l-68.2-79.2c-7.9-9.2-21.8-10.2-31-2.3-9.2 7.9-10.2 21.8-2.3 31L228 256l-72.5 84.2c-7.9 9.2-6.9 23 2.3 31 4.1 3.6 9.2 5.3 14.3 5.3 6.2 0 12.3-2.6 16.6-7.6l68.2-79.2 68.2 79.2c4.3 5 10.5 7.6 16.6 7.6 5.1 0 10.2-1.7 14.3-5.3 9.2-7.9 10.2-21.8 2.3-31L286 256z"
    />
  </svg>
);

export default Close;
