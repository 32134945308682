import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const fetchReport5 = async (source: CancelTokenSource) => {
  const params = {
    organisationId: getAccount().organisationId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/report5",
    params,
  });

  return data;
};
