import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const getTrackerInfo = async (source: CancelTokenSource, trackerId: string) => {
  const params = {
    organisationId: getAccount().organisationId,
    trackerId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + `/v1/trackerinfo`,
    params,
  });

  return data;
};
