import React, { FC, useState, useEffect, useRef, useContext } from "react";
import { fetchPlace, putPlace } from "../../services/editPlace";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import EditPlaceMap from "../EditPlaceMap";
import { FormError, FormInput, FormTextareaInput, InfoIconContainer, InputUnitWrapper } from "../FormComponents";
import { exists, max, min, maxLength, validName, validPhoneNumber, validEmail } from "../../util/formValidations";
import { ModalFormContainer } from "../Modal/styles";
import { AsyncCreatableSelect, AsyncSelect } from "../Select";
import { fetchAutoComplete } from "../../services/autoComplete";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import InfoIcon from "../../svgs/Legend";
import InfoTooltip from "../Tooltip";
import { ThemeContext } from "styled-components";
import { isBinaryBeer } from "../../util/checkDomain";
import { convertRoundedLength, printLengthUnit, toMeters } from "../../util/formatUnits";
import PlaceAlertsTable from "../PlaceAlertsTable";
import PhoneNumberInput from "../PhoneNumberInput";
import moment from "moment";
import { fetchCountry } from "../../services/fetchCountry";
import { getCountry, saveCountry } from "../../services/localStorage";
import sortTags from "../../util/sortTags";
import PlaceAllocationsTable from "../PlaceAllocationsTable";
import Checkbox from "../Checkbox";
import { trimString } from "../../util/strings";
import { deletePhoto } from "../../services/photo";
import DeleteModal from "../DeleteModal";
import UploadPhotoModal from "../UploadPhotoModal";
import { isAdmin } from "../../util/checkRole";
import { CameraIconContainer, UpdateImageButton, UpdateImageButtonContainer } from "./styles";
import CameraIcon from "../../svgs/CameraIcon";
import { MenuList, MenuButton, DangerMenuButton } from "../Tooltip/styles";
import Tooltip from "../Tooltip";
import { hideAll } from "tippy.js";

const defaultPlace = {
  id: undefined,
  name: "",
  placeType: null,
  placeTags: [],
  address: "",
  city: "",
  state: "",
  postcode: "",
  country: "",
  latitude: undefined,
  longitude: undefined,
  radius: 50,
  beer: null,
  contactName: "",
  email: "",
  phoneNumber: "",
  notes: "",
  placeNotFound: false,
  blur: 0,
  website: "",
  openHours: "",
  imageUrl: "",
  trackerIds: [],
  fillsBeerKegs: false,
  emptiesBeerKegs: false,
  fuzzy: false,
};

const initForm = (place?: any) => {
  if (place) {
    const formData = {
      ...defaultPlace,
      ...place,
      placeType: place.placeType
        ? {
            value: place.placeType,
            label: place.placeType,
            colour: place.placeTypeColour,
            fillsBeerKegs: place.fillsBeerKegs,
            emptiesBeerKegs: place.emptiesBeerKegs,
          }
        : null,
      placeTags:
        place.placeTags && place.placeTags.length > 0
          ? place.placeTags.sort(sortTags).map((tag: any) => ({ value: tag.name, label: tag.name, colour: tag.colour, description: tag.description }))
          : [],
      beer: place.beerId
        ? {
            value: place.beerId,
            label: place.beerName,
          }
        : null,
    };

    formData.radius = convertRoundedLength(formData.radius);

    return formData;
  } else {
    const formData = defaultPlace;
    formData.radius = convertRoundedLength(formData.radius);
    return formData;
  }
};

const EditPlaceModal: FC<any> = ({ id, updateData, modalOpen, setModalOpen }) => {
  const { color } = useContext(ThemeContext);

  const autocompleteRef = useRef(null);
  const photoMenuRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const [formData, setFormData] = useState<any>({});
  const [formErrors, setFormErrors] = useState<any>({});

  const [placeErr, setPlaceErr] = useState<string>("");
  const [placeLoading, setPlaceLoading] = useState<boolean>(true);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [inputPhoto, setInputPhoto] = useState<any>(undefined);
  const [uploadPhotoModalOpen, setUploadPhotoModalOpen] = useState<boolean>(false);
  const [deletePhotoModalOpen, setDeletePhotoModalOpen] = useState<boolean>(false);

  const [generalTabValid, setGeneralTabValid] = useState<boolean>(true);
  const [contactTabValid, setContactTabValid] = useState<boolean>(true);
  const [detailsTabValid, setDetailsTabValid] = useState<boolean>(true);

  const [country, setCountry] = useState<string>("AU");

  const [tabIndex, setTabIndex] = useState(0);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    getPlace();
  }, [id, source]);

  useEffect(() => {
    const currCountry = getCountry();

    // If no country stored or older than 24 hours, fetch country code again
    if (currCountry && moment().diff(moment(currCountry.date), "hours") < 24) {
      setCountry(currCountry.code);
    } else {
      fetchCountry(source)
        .then((response) => {
          if (response) {
            setCountry(response);
            saveCountry({ date: moment(), code: response });
          } else {
            setCountry("AU");
            saveCountry({ date: moment(), code: "AU" });
          }
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            saveCountry({ date: moment(), code: "AU" });
            setCountry("AU");
          }
        });
    }
  }, [source]);

  const getPlace = () => {
    // If an id is provided we are editing a place so fetch the data to populate the inputs and map
    // If no id then display error message
    if (id) {
      setPlaceLoading(true);
      fetchPlace(source, id)
        .then((response) => {
          setFormData(initForm(response));
          setPlaceLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setPlaceErr(errToStr(err));
            setPlaceLoading(false);
          }
        });
    } else {
      setPlaceErr("Place id not found");
    }
  };

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;
    let generalValid = true;
    let contactValid = true;
    let detailsValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "name":
          currValid = exists(name, value, setFormErrors) && maxLength(name, value, 200, setFormErrors);
          if (!currValid) generalValid = false;
          break;

        case "placeType":
          if (value) {
            currValid = maxLength(name, value.value, 200, setFormErrors) && validName(name, value.value, setFormErrors);
            if (!currValid) generalValid = false;
          } else {
            currValid = true;
          }
          break;

        case "radius":
          currValid =
            exists(name, value, setFormErrors) &&
            min(name, value, convertRoundedLength(1), setFormErrors) &&
            max(name, value, convertRoundedLength(10000000), setFormErrors);
          if (!currValid) {
            generalValid = false;
            detailsValid = false;
          }
          break;

        case "contactName":
          currValid = maxLength(name, value, 200, setFormErrors);
          if (!currValid) contactValid = false;
          break;

        case "email":
          currValid = value ? validEmail(name, value, setFormErrors) : true;
          if (!currValid) contactValid = false;
          break;

        case "phoneNumber":
          currValid = value ? validPhoneNumber(name, value, setFormErrors) : true;
          if (!currValid) contactValid = false;
          break;

        case "website":
          currValid = maxLength(name, value, 1000, setFormErrors);
          if (!currValid) contactValid = false;
          break;

        case "notes":
          currValid = maxLength(name, value, 512, setFormErrors);
          if (!currValid) contactValid = false;
          break;

        case "address":
          currValid = maxLength(name, value, 200, setFormErrors);
          if (!currValid) detailsValid = false;
          break;

        case "city":
          currValid = maxLength(name, value, 200, setFormErrors);
          if (!currValid) detailsValid = false;
          break;

        case "state":
          currValid = maxLength(name, value, 200, setFormErrors);
          if (!currValid) detailsValid = false;
          break;

        case "postcode":
          currValid = maxLength(name, value, 200, setFormErrors);
          if (!currValid) detailsValid = false;
          break;

        case "country":
          currValid = exists(name, value, setFormErrors) && maxLength(name, value, 200, setFormErrors);
          if (!currValid) detailsValid = false;
          break;

        case "latitude":
          currValid = exists(name, value, setFormErrors) && min(name, value, -90, setFormErrors) && max(name, value, 90, setFormErrors);
          if (!currValid) detailsValid = false;
          break;

        case "longitude":
          currValid = exists(name, value, setFormErrors) && min(name, value, -180, setFormErrors) && max(name, value, 180, setFormErrors);
          if (!currValid) detailsValid = false;
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }

    setGeneralTabValid(generalValid);
    setContactTabValid(contactValid);
    setDetailsTabValid(detailsValid);

    return allValid;
  };

  const formatFormData = () => {
    const formattedData: any = {
      name: trimString(formData.name),
      address: trimString(formData.address),
      city: trimString(formData.city),
      contactName: trimString(formData.contactName),
      country: trimString(formData.country),
      email: trimString(formData.email),
      latitude: formData.latitude,
      longitude: formData.longitude,
      notes: trimString(formData.notes),
      phoneNumber: formData.phoneNumber,
      postcode: trimString(formData.postcode),
      state: trimString(formData.state),
      website: trimString(formData.website),
      placeType: formData.placeType && formData.placeType.label ? formData.placeType.label : null,
      placeTags: formData.placeTags ? formData.placeTags.map((tag: any) => ({ id: tag.value, name: tag.label, colour: tag.colour })) : [],
      beerId: isBinaryBeer() && formData.placeType && formData.placeType.fillsBeerKegs && formData.beer && formData.beer.value ? formData.beer.value : null,
      radius: toMeters(formData.radius),
      fuzzy: formData.fuzzy,
      openHours: trimString(formData.openHours),
    };

    return formattedData;
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      if (formData.id) {
        putPlace(source, id, body)
          .then((response) => {
            updateData(response);
            setSubmittedMsg("Place Updated");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      }
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
    if (e.target.name === "radius" && e.target.value >= 250) {
      setFormData((prev: any) => ({ ...prev, fuzzy: true }));
      setFormErrors((prev: any) => ({ ...prev, fuzzy: undefined }));
    }
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const handlePhoneChange = (value?: string) => {
    setFormData((prev: any) => ({ ...prev, phoneNumber: value }));
    setFormErrors((prev: any) => ({ ...prev, phoneNumber: undefined }));
  };

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  // handleBlur and handleKeyDown will increment the blur number which causes the
  // useEffect in EditPlaceMap to trigger, updating the circle marker on the map.
  // We don't use onChange as moving the marker also causes the inputs
  // to update, making it difficult to input the desired numbers.
  const handleBlur = () => {
    setFormData((prev: any) => ({ ...prev, blur: formData.blur + 1 }));
  };

  const handleKeyDown = (e: any) => {
    // 13 = ENTER
    if (e.keyCode === 13) {
      setFormData((prev: any) => ({ ...prev, blur: formData.blur + 1 }));
    }
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
  };

  const handleCheckboxChange = (e: any) => {
    e.persist();
    setFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleFileChange = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setInputPhoto(e.target.files[0]);
    if (e.target.files[0]) setUploadPhotoModalOpen(true);
    e.target.value = "";
  };

  // Show loading modal when formData is undefined
  if (!formData) {
    return (
      <SubmitModal
        size="lg"
        isOpen={modalOpen}
        onClose={() => handleClose()}
        title="Edit Place"
        error={placeErr}
        submitBtnText="Update Place"
        body={<LoadingContainer loading={placeLoading}></LoadingContainer>}
      />
    );
  }

  return (
    <>
      <SubmitModal
        isOpen={modalOpen}
        onSubmit={() => handleSubmit()}
        onClose={() => handleClose()}
        size={!submittedMsg && !submittingErr ? "lg" : "sm"}
        title="Edit Place"
        success={submittedMsg}
        error={placeErr || submittingErr}
        submitBtnText="Update Place"
        body={
          <LoadingContainer loading={placeLoading || submitting}>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
              <TabList>
                <Tab className={!generalTabValid ? "react-tabs__tab__error" : "react-tabs__tab"}>General</Tab>
                <Tab className={!contactTabValid ? "react-tabs__tab__error" : "react-tabs__tab"}>Contact</Tab>
                <Tab className={!detailsTabValid ? "react-tabs__tab__error" : "react-tabs__tab"}>Details</Tab>
                {isAdmin() && <Tab>Photo</Tab>}
                <Tab>Allocations</Tab>
                <Tab>Alerts</Tab>
              </TabList>
              <TabPanel>
                <form noValidate onSubmit={(e) => e.preventDefault()} style={{ display: "flex", flexWrap: "wrap", columnGap: "10px" }}>
                  <ModalFormContainer>
                    <label>Place Name</label>
                    <FormInput type="text" name="name" value={formData.name} error={formErrors.name} onChange={handleChange} placeholder="Binary Beer HQ" />
                    <FormError error={formErrors.name}>{formErrors.name}</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer halfWidth={true}>
                    <label>Place Type</label>
                    <AsyncSelect
                      name="placeType"
                      defaultOptions={true}
                      isClearable={true}
                      isError={formErrors.placeType}
                      value={formData.placeType}
                      loadOptions={(inputValue: any, callback: any) => loadOptions("placetypes", inputValue, callback)}
                      onChange={handleSelectChange}
                      placeholder="Select..."
                    />
                    <FormError error={formErrors.placeType}>{formErrors.placeType}</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer halfWidth={true}>
                    <label>Place Tags</label>
                    <AsyncCreatableSelect
                      name="placeTags"
                      defaultOptions={true}
                      isMulti={true}
                      isClearable={true}
                      isError={formErrors.placeTags}
                      value={formData.placeTags}
                      loadOptions={(inputValue: any, callback: any) => loadOptions("placeTags", inputValue, callback)}
                      onChange={handleSelectChange}
                      placeholder="Select..."
                    />
                    <FormError error={formErrors.placeTags}>{formErrors.placeTags}</FormError>
                  </ModalFormContainer>
                  {isBinaryBeer() && formData.placeType && formData.placeType.fillsBeerKegs && (
                    <ModalFormContainer>
                      <label style={{ float: "left" }}>Default Beer</label>
                      <div style={{ cursor: "pointer" }}>
                        <InfoTooltip
                          content="Kegs will be filled with the default beer if they leave this place without a fill event"
                          interactive={true}
                          touch={true}
                          appendTo={document.body}
                        >
                          <InfoIconContainer>
                            <InfoIcon fill={color.font[2]} />
                          </InfoIconContainer>
                        </InfoTooltip>
                      </div>
                      <AsyncSelect
                        name="beer"
                        defaultOptions={true}
                        isClearable={true}
                        isError={formErrors.beer}
                        value={formData.beer}
                        loadOptions={(inputValue: any, callback: any) => loadOptions("beers", inputValue, callback)}
                        onChange={handleSelectChange}
                        placeholder="Select..."
                      />
                      <FormError error={formErrors.beer}>{formErrors.beer}</FormError>
                    </ModalFormContainer>
                  )}
                  <ModalFormContainer style={{ padding: "10px" }}>
                    {formData && (
                      <EditPlaceMap
                        place={formData}
                        setPlace={setFormData}
                        autocompleteRef={autocompleteRef}
                        error={formErrors.latitude || formErrors.longitude}
                      />
                    )}
                    <FormError error={formErrors.latitude || formErrors.longitude}>Please select location on map</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer halfWidth={true}>
                    <label>Radius</label>
                    <InputUnitWrapper unit={printLengthUnit()}>
                      <FormInput
                        type="number"
                        name="radius"
                        value={formData.radius}
                        error={formErrors.radius}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        required={true}
                        placeholder="50"
                        min={convertRoundedLength(1)}
                        max={convertRoundedLength(10000000)}
                      />
                    </InputUnitWrapper>
                    <FormError error={formErrors.radius}>{formErrors.radius}</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer halfWidth={true} style={{ alignContent: "center" }}>
                    <Checkbox
                      style={{ display: "inline-block" }}
                      name="fuzzy"
                      label="Fuzzy Location Matching"
                      checked={formData.fuzzy}
                      onChange={handleCheckboxChange}
                    />
                    <div
                      style={{
                        cursor: "pointer",
                        display: "inline-block",
                        marginBottom: "-3px",
                      }}
                    >
                      <InfoTooltip
                        content="Allows trackers to enter a place on a cell match if there is only one place in range"
                        interactive={true}
                        touch={true}
                        appendTo={document.body}
                      >
                        <InfoIconContainer>
                          <InfoIcon fill={color.font[2]} />
                        </InfoIconContainer>
                      </InfoTooltip>
                    </div>
                  </ModalFormContainer>
                </form>
              </TabPanel>
              <TabPanel>
                <form noValidate onSubmit={(e) => e.preventDefault()} style={{ display: "flex", flexWrap: "wrap", columnGap: "10px" }}>
                  <ModalFormContainer>
                    <label>Contact Name</label>
                    <FormInput type="text" name="contactName" value={formData.contactName} error={formErrors.contactName} onChange={handleChange} />
                    <FormError error={formErrors.contactName}>{formErrors.contactName}</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer>
                    <label>Email</label>
                    <FormInput type="email" name="email" value={formData.email} error={formErrors.email} onChange={handleChange} autoComplete="email" />
                    <FormError error={formErrors.email}>{formErrors.email}</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer>
                    <label>Phone Number</label>
                    <PhoneNumberInput
                      international={true}
                      defaultCountry={country}
                      value={formData.phoneNumber}
                      error={formErrors.phoneNumber}
                      onChange={handlePhoneChange}
                      autoComplete="tel"
                    />
                    <FormError error={formErrors.phoneNumber}>{formErrors.phoneNumber}</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer>
                    <label>URL</label>
                    <FormInput type="text" name="website" value={formData.website} error={formErrors.website} onChange={handleChange} />
                    <FormError error={formErrors.website}>{formErrors.website}</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer>
                    <label>Notes</label>
                    <FormTextareaInput name="notes" rows={6} maxLength={512} value={formData.notes} error={formErrors.notes} onChange={handleChange} />
                    <FormError error={formErrors.notes}>{formErrors.notes}</FormError>
                  </ModalFormContainer>
                </form>
              </TabPanel>
              <TabPanel>
                <form noValidate onSubmit={(e) => e.preventDefault()} style={{ display: "flex", flexWrap: "wrap", columnGap: "10px" }}>
                  <ModalFormContainer>
                    <label>Open Hours</label>
                    <FormTextareaInput
                      name="openHours"
                      rows={7}
                      maxLength={512}
                      value={formData.openHours}
                      error={formErrors.openHours}
                      onChange={handleChange}
                    />
                  </ModalFormContainer>
                  <ModalFormContainer halfWidth={true}>
                    <label>Address</label>
                    <FormInput
                      type="text"
                      name="address"
                      value={formData.address}
                      error={formErrors.address}
                      onChange={handleChange}
                      placeholder="Bldg 239 Puckey Avenue"
                    />
                    <FormError error={formErrors.address}>{formErrors.address}</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer halfWidth={true}>
                    <label>City</label>
                    <FormInput type="text" name="city" value={formData.city} error={formErrors.city} onChange={handleChange} placeholder="North Wollongong" />
                    <FormError error={formErrors.city}>{formErrors.city}</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer halfWidth={true}>
                    <label>State</label>
                    <FormInput type="text" name="state" value={formData.state} error={formErrors.state} onChange={handleChange} placeholder="NSW" />
                    <FormError error={formErrors.state}>{formErrors.state}</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer halfWidth={true}>
                    <label>Country</label>
                    <FormInput type="text" name="country" value={formData.country} error={formErrors.country} onChange={handleChange} placeholder="Australia" />
                    <FormError error={formErrors.country}>{formErrors.country}</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer halfWidth={true}>
                    <label>Postcode</label>
                    <FormInput type="text" name="postcode" value={formData.postcode} error={formErrors.postcode} onChange={handleChange} placeholder="2500" />
                    <FormError error={formErrors.postcode}>{formErrors.postcode}</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer halfWidth={true}>
                    <label>Radius</label>
                    <InputUnitWrapper unit={printLengthUnit()}>
                      <FormInput
                        type="number"
                        name="radius"
                        value={formData.radius}
                        error={formErrors.radius}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyDown}
                        required={true}
                        placeholder="50"
                        min={convertRoundedLength(1)}
                        max={convertRoundedLength(10000000)}
                      />
                    </InputUnitWrapper>
                    <FormError error={formErrors.radius}>{formErrors.radius}</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer halfWidth={true}>
                    <label>Latitude</label>
                    <FormInput
                      type="number"
                      name="latitude"
                      value={formData.latitude}
                      error={formErrors.latitude}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyDown}
                      required={true}
                      placeholder="-34.401740"
                      min={-90}
                      max={90}
                    />
                    <FormError error={formErrors.latitude}>{formErrors.latitude}</FormError>
                  </ModalFormContainer>
                  <ModalFormContainer halfWidth={true}>
                    <label>Longitude</label>
                    <FormInput
                      type="number"
                      name="longitude"
                      value={formData.longitude}
                      error={formErrors.longitude}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyDown}
                      required={true}
                      placeholder="150.897407"
                      min={-180}
                      max={180}
                    />
                    <FormError error={formErrors.longitude}>{formErrors.longitude}</FormError>
                  </ModalFormContainer>
                </form>
              </TabPanel>
              {isAdmin() && (
                <TabPanel>
                  {formData.imageUrl && (
                    <div style={{ position: "relative", width: "100%", margin: "0px auto", maxWidth: "400px" }}>
                      <img width="100%" height="100%" style={{ maxWidth: "400px", margin: "0 auto", borderRadius: "3px" }} src={formData.imageUrl} />
                      <Tooltip
                        onCreate={(instance) => (photoMenuRef.current = instance)}
                        interactive={true}
                        touch={true}
                        appendTo={document.body}
                        trigger="click"
                        placement="bottom-start"
                        maxWidth="none"
                        theme="binary-no-padding"
                        content={
                          <MenuList>
                            <MenuButton
                              onClick={() => {
                                inputRef.current.click();
                                hideAll();
                              }}
                            >
                              Change
                            </MenuButton>
                            {formData.imageUrl && (
                              <DangerMenuButton
                                onClick={() => {
                                  setDeletePhotoModalOpen(true);
                                  hideAll();
                                }}
                              >
                                Delete
                              </DangerMenuButton>
                            )}
                          </MenuList>
                        }
                      >
                        <UpdateImageButton>
                          <UpdateImageButtonContainer>
                            <CameraIconContainer>
                              <CameraIcon />
                            </CameraIconContainer>
                          </UpdateImageButtonContainer>
                        </UpdateImageButton>
                      </Tooltip>
                    </div>
                  )}
                  <input
                    type="file"
                    style={{ display: formData.imageUrl ? "none" : "block" }}
                    ref={inputRef}
                    onChange={handleFileChange}
                    accept="image/png, image/jpeg"
                  />
                </TabPanel>
              )}
              <TabPanel>
                <form noValidate onSubmit={(e) => e.preventDefault()}>
                  <ModalFormContainer style={{ padding: "10px" }}>
                    <PlaceAllocationsTable placeId={formData.id} />
                  </ModalFormContainer>
                </form>
              </TabPanel>
              <TabPanel>
                <form noValidate onSubmit={(e) => e.preventDefault()}>
                  <ModalFormContainer style={{ padding: "10px" }}>
                    <PlaceAlertsTable placeId={formData.id} />
                  </ModalFormContainer>
                </form>
              </TabPanel>
            </Tabs>
          </LoadingContainer>
        }
      />
      {uploadPhotoModalOpen && (
        <UploadPhotoModal
          modalOpen={uploadPhotoModalOpen}
          setModalOpen={setUploadPhotoModalOpen}
          photo={inputPhoto}
          trackerId={undefined}
          organisationId={undefined}
          placeId={id}
          userId={undefined}
          onSuccess={() => {
            getPlace();
            setTabIndex(3);
          }}
        />
      )}
      {deletePhotoModalOpen && (
        <DeleteModal
          title="Delete Place Photo"
          body={<span>Are you sure you want to delete this photo?</span>}
          successMsg="Place Photo Deleted"
          modalOpen={deletePhotoModalOpen}
          setModalOpen={setDeletePhotoModalOpen}
          deleteService={deletePhoto}
          serviceParams={[undefined, undefined, id, undefined]}
          onDelete={() => {
            getPlace();
            setTabIndex(3);
          }}
        />
      )}
    </>
  );
};

export default EditPlaceModal;
