import styled from "../Themes";

export const Panel = styled(`div`)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 6px;
  margin-bottom: 12px;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  box-shadow: ${({ theme }) => theme.boxShadow.s};

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);
`;

export const ContentContainer = styled(`div`)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

export const ValueContainer = styled(`div`)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 10px;
  overflow: hidden;
  height: 100%;
  justify-content: center;
`;

export const Header = styled(`span`)`
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.font[2]};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Value = styled(`span`)`
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.font[2]};
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  width: 100%;
`;
