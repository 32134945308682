import styled from "../Themes";

const ChartHeading = styled(`h1`)`
  margin-bottom: ${({ theme }) => theme.space[3]};
  font-size: 17px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
`;

export default ChartHeading;
