import React, { FC } from "react";
import { Content } from "./styles";
import TopNav from "../../components/TopNav";

const NoSidebarLayout: FC<any> = ({ children }) => {
  return (
    <>
      <TopNav enableNav={false} open={false} />
      <Content open={false}>{children}</Content>
    </>
  );
};

export default NoSidebarLayout;
