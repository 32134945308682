import axios, { CancelTokenSource } from "axios";
import QrScanner from "qr-scanner";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { postActivateSensor } from "../../services/activateSensors";
import { fetchEditAccount } from "../../services/editAccount";
import { saveAccount } from "../../services/localStorage";
import errToStr from "../../util/errToStr";
import { exists } from "../../util/formValidations";
import getParameterByName from "../../util/getParamByName";
import isMobileDevice from "../../util/isMobileDevice";
import { DangerAlert, SuccessAlert } from "../Alerts";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import { FormError, FormInput } from "../FormComponents";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import StateContext from "../StateContext";
import { QrScanButton } from "./styles";

const ActivateSensorModal: FC<any> = ({ onSuccess, modalOpen, setModalOpen }) => {
  const location = useLocation();

  const { forceUpdate } = useContext(StateContext);

  const sensorId = getParameterByName("sensorId", location.search) || "";

  const videoRefCallback = useCallback((node: any) => {
    if (node) {
      setVideoRef(node);
    }
  }, []);

  const [videoRef, setVideoRef] = useState<any>(undefined);
  const [formData, setFormData] = useState<any>({
    sensorId,
    phoneLatitude: undefined,
    phoneLongitude: undefined,
    phoneAccuracy: undefined,
  });
  const [formErrors, setFormErrors] = useState<any>({});

  const [codeReader, setCodeReader] = useState<any>(undefined);
  const [scanning, setScanning] = useState<boolean>(false);
  const [scan, setScan] = useState<any>(undefined);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Initialise codeReader and stop/destroy codeReader and stop camera access on clean up
  useEffect(() => {
    let codeReader: any = undefined;
    if (videoRef) {
      codeReader = new QrScanner(videoRef, (result) => {
        if (result) {
          let code = result;
          if (code.includes("10b.in/")) {
            code = code.split("10b.in/")[1];
          }
          setScan(code);
        }
      });
      codeReader.setInversionMode("both");
      setCodeReader(codeReader);
    }

    return () => {
      if (codeReader) {
        codeReader.stop();
        codeReader.destroy();
      }
      if (videoRef && videoRef.srcObject) {
        videoRef.srcObject.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
    };
  }, [videoRef]);

  // If user is on a mobile device, get their location to update the tracker's location
  useEffect(() => {
    if (isMobileDevice()) {
      navigator.geolocation.getCurrentPosition((pos: any) => {
        const { coords } = pos;

        setFormData((prev: any) => ({
          ...prev,
          phoneLatitude: coords.latitude,
          phoneLongitude: coords.longitude,
          phoneAccuracy: coords.accuracy,
        }));
      });
    }
  }, []);

  // Stop code reader when tracker info is loading or the tracker info modal is open
  useEffect(() => {
    if (codeReader) {
      if (scanning) {
        codeReader.start();
      } else {
        codeReader.stop();
        if (videoRef && videoRef.srcObject) {
          videoRef.srcObject.getTracks().forEach((track: any) => {
            track.stop();
          });
        }
      }
    }
  }, [codeReader, scanning, videoRef]);

  // On QR scan, add string to sensor ID input
  useEffect(() => {
    if (scan) {
      setFormData((prev: any) => ({ ...prev, sensorId: scan }));
      setScan(undefined);
      setScanning(false);
    }
  }, [scan]);

  const validateActivateSensorForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "sensorId":
          currValid = exists(name, value, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatActivateSensorForm = () => {
    return {
      sensorId: formData.sensorId,
      phoneLatitude: formData.phoneLatitude,
      phoneLongitude: formData.phoneLongitude,
      phoneAccuracy: Math.round(formData.phoneAccuracy) || null,
    };
  };

  const handleActivateSensorSubmit = () => {
    setSubmittedMsg("");
    setSubmittingErr("");

    const formattedData = formatActivateSensorForm();
    const valid = validateActivateSensorForm();

    if (valid) {
      setSubmitting(true);
      postActivateSensor(source, formattedData)
        .then((response) => {
          onSuccess();
          updateAccountInfo();
          setSubmittedMsg("Tracker Activated");
          setFormData((prev: any) => ({ ...prev, sensorId: "" }));
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const updateAccountInfo = () => {
    fetchEditAccount(source).then((response) => {
      saveAccount(response);
      const accountInfoUpdatedEvent = new Event("accountinfoupdated");
      document.dispatchEvent(accountInfoUpdatedEvent);
      forceUpdate();
    });
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleEnableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setSubmittedMsg("");
    setSubmittingErr("");
    setScanning(true);
  };

  const handleDisableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setScan(undefined);
    setScanning(false);
  };

  const onClose = () => {
    if (!submitting) setModalOpen(false);
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onClose={onClose}
      title={scanning ? "Scan QR Code" : "Activate Tracker"}
      body={
        <LoadingContainer loading={submitting}>
          <div
            style={{
              display: scanning ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <video
              id="video"
              ref={videoRefCallback}
              style={{
                display: "inline-block",
                border: "1px solid gray",
                borderRadius: "3px",
                maxHeight: "50vh",
                maxWidth: "100%",
              }}
            />
          </div>
          <div
            style={{
              display: scanning ? "none" : "block",
            }}
          >
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <ModalFormContainer>
                <label>Tracker ID</label>
                <QrScanButton title="Scan QR Code" onClick={handleEnableScan} />
                <FormInput autoComplete="no" type="text" name="sensorId" value={formData.sensorId} error={formErrors.sensorId} onChange={handleChange} />
                <FormError error={formErrors.sensorId}>{formErrors.sensorId}</FormError>
                {submittingErr && <DangerAlert style={{ marginBottom: "-2px", float: "left", width: "100%" }}>{submittingErr}</DangerAlert>}
                {submittedMsg && <SuccessAlert style={{ marginBottom: "-2px", float: "left", width: "100%" }}>{submittedMsg}</SuccessAlert>}
              </ModalFormContainer>
            </form>
          </div>
        </LoadingContainer>
      }
      footer={
        scanning ? (
          <OutlineBtn onClick={handleDisableScan} width="100%">
            Back
          </OutlineBtn>
        ) : (
          <>
            <GhostBtn onClick={() => setModalOpen(false)}>Cancel</GhostBtn>
            <PrimaryBtn onClick={() => handleActivateSensorSubmit()}>Activate</PrimaryBtn>
          </>
        )
      }
    />
  );
};

export default ActivateSensorModal;
