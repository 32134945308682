/**
 * Determines if the user is on a mobile or tablet device.
 *
 * @returns {boolean} - True if the user is on a mobile/tablet, false otherwise.
 */
const isMobileDevice = (): boolean => {
  // Use the newer userAgentData API if available
  const userAgentData = (navigator as any).userAgentData;

  if (userAgentData?.mobile) return true;

  // Fallback to user agent string detection
  const userAgent = navigator.userAgent;
  const isMobile = /android|iphone|ipad|ipod|tablet|playbook|silk/i.test(userAgent);

  // Additional check: touch capability and screen width
  const isTouchScreen = navigator.maxTouchPoints > 1;
  const isSmallScreen = window.innerWidth <= 1024;

  return isMobile || (isTouchScreen && isSmallScreen);
};

export default isMobileDevice;
