import axios from "axios";
import moment from "moment";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { getAccount } from "../../../services/localStorage";
import { fetchReport17 } from "../../../services/report17";
import errToStr from "../../../util/errToStr";
import { printFixedLength, printLength, printLengthUnit } from "../../../util/formatUnits";
import { kegOrTracker } from "../../../util/kegOrTracker";
import Bold from "../../Bold";
import FlatpickrRangePicker from "../../FlatpickrRangePicker";
import LoadingContainer from "../../LoadingContainer";
import { TableHeaderButtons } from "../../NewTable/styles";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import { Panel } from "../../Panel";
import Table, { CsvButtonsComponent } from "../../Table";

const formatDataToCsv = (tableRef: any, accountInfo: any, short_datetime: string) => {
  const sortedData = tableRef.current.getResolvedState().sortedData;
  let headers = [];

  if (accountInfo.hasBeer) {
    headers = [
      "Scan Date",
      "Tracker ID",
      "User Name",
      "Place Name",
      "Beer Name",
      "Phone IP Address",
      "Phone Latitude",
      "Phone Longitude",
      `Phone Accuracy (${printLengthUnit()})`,
      "Feedback",
    ];

    return [
      headers,
      ...sortedData.map((row: any) => {
        return [
          moment.unix(row.scanDate).format(short_datetime),
          row.sensorId,
          row.userName,
          row.placeName,
          row.beerName,
          row.phoneIPAddress,
          row.phoneLatitude,
          row.phoneLongitude,
          printFixedLength(row.phoneAccuracy),
          row.feedback,
        ];
      }, []),
    ];
  } else {
    headers = [
      "Scan Date",
      "Tracker ID",
      "User Name",
      "Place Name",
      "Phone IP Address",
      "Phone Latitude",
      "Phone Longitude",
      `Phone Accuracy (${printLengthUnit()})`,
      "Feedback",
    ];

    return [
      headers,
      ...sortedData.map((row: any) => {
        return [
          moment.unix(row.scanDate).format(short_datetime),
          row.sensorId,
          row.userName,
          row.placeName,
          row.phoneIPAddress,
          row.phoneLatitude,
          row.phoneLongitude,
          printFixedLength(row.phoneAccuracy),
          row.feedback,
        ];
      }, []),
    ];
  }
};

const PhoneScans: FC<any> = () => {
  const [accountInfo] = useState<any>(getAccount());
  const { short_datetime, short_date } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchReport17(source)
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData([]);
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  const columns = [];

  columns.push({
    id: "scanDate",
    Header: "Scan Date",
    accessor: "scanDate",
    filterMethod: (filter: any, row: any) => {
      if (filter.value.length === 2) {
        if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
        else return false;
      } else return true;
    },
    Filter: ({ onChange }: any) => (
      <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
    ),
    Cell: (props: any) => (props.value ? moment.unix(props.value).format(short_datetime) : ""),
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  columns.push({
    id: "sensorId",
    Header: "Tracker ID",
    accessor: "sensorId",
    Cell: (props: any) => {
      const url = kegOrTracker("kegs", "trackers");
      let title = props.value ? props.value : "";
      title += `${props.original.sensorName ? " (" + props.original.sensorName + ")" : ""}`;

      return (
        <Link to={`/${url}/${props.value}`} title={title}>
          {title}
        </Link>
      );
    },
  });

  columns.push({
    id: "userName",
    Header: "User Name",
    accessor: "userName",
  });

  columns.push({
    id: "placeName",
    Header: "Place Name",
    accessor: "placeName",
    Cell: (props: any) => <Link to={`/places/${props.original.placeId}`}>{props.value}</Link>,
  });

  if (accountInfo.hasBeer) {
    columns.push({
      id: "beerName",
      Header: "Beer Name",
      accessor: "beerName",
    });
  }

  columns.push({
    id: "phoneIPAddress",
    Header: "Phone IP Address",
    accessor: "phoneIPAddress",
  });

  columns.push({
    id: "phoneLatitude",
    Header: "Phone Latitude",
    accessor: "phoneLatitude",
  });

  columns.push({
    id: "phoneLongitude",
    Header: "Phone Longitude",
    accessor: "phoneLongitude",
  });

  columns.push({
    id: "phoneAccuracy",
    Header: "Phone Accuracy",
    accessor: "phoneAccuracy",
    Cell: (props: any) => (props.value !== undefined ? printLength(props.value) : ""),
  });

  columns.push({
    id: "feedback",
    Header: "Feedback",
    accessor: "feedback",
  });

  const defaultSorted = [
    {
      id: "scanDate",
      desc: true,
    },
  ];

  return (
    <>
      <PageBreadcrumbs prevRoutes={[{ slug: "/admin-reports", title: "Admin Reports" }]} currRoute="Phone Scans" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading} err={dataErr}>
            <Panel>
              <TableHeaderButtons>
                <div style={{ display: "flex" }}></div>
                <CsvButtonsComponent
                  data={data}
                  formatCsv={formatDataToCsv}
                  formatCsvParams={[tableRef, accountInfo, short_datetime]}
                  fileName="Phone Scans.csv"
                />
              </TableHeaderButtons>
              <Table
                loading={dataLoading}
                filterable={true}
                style={{ clear: "both" }}
                data={data}
                columns={columns}
                defaultSorted={defaultSorted}
                ref={tableRef}
              />
            </Panel>
          </LoadingContainer>
        </div>
      </PageContainer>
    </>
  );
};

export default PhoneScans;
