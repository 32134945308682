import React, { FC, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import { ClearButton } from "./styles";
import Cross from "../../svgs/Cross";

const FlatpickrRangePicker: FC<any> = ({ onClose, value = [], error, noClear, ...props }) => {
  const [date, setDate] = useState<any>(value);

  const flatpickrRef = useRef<any>(null);

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <Flatpickr
        data-input
        ref={flatpickrRef}
        value={date}
        onClose={(d: any) => {
          setDate(d);
          if (onClose) onClose(d);
        }}
        {...props}
      />
      {noClear !== true && date.length > 0 && (
        <ClearButton
          onClick={() => {
            if (flatpickrRef && flatpickrRef.current && flatpickrRef.current.flatpickr) {
              flatpickrRef.current.flatpickr.clear();
              setDate([]);
              onClose([]);
            }
          }}
        >
          <Cross />
        </ClearButton>
      )}
    </div>
  );
};

export default FlatpickrRangePicker;
