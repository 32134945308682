import styled from "../Themes";
import { Link } from "react-router-dom";

/********* Styles for tooltip menus *********/

export const MenuList = styled(`div`)`
  text-align: left;
  min-width: 180px;
  padding: 8px 0;
`;

export const MenuLink = styled(Link)`
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.color.font[2]};
  width: 100%;
  min-height: 35px;

  &:hover {
    color: ${({ theme }) => theme.color.font_bold[2]};
    background-color: ${({ theme }) => theme.color.tooltip_hover[2]};
  }

  &:active {
    color: ${({ theme }) => theme.color.font_bold[2]};
    background-color: ${({ theme }) => theme.color.tooltip_hover[2]};
  }

  :disabled {
    opacity: 0.33;
  }
`;

export const MenuButton = styled<any>(`button`)`
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.color.font[2]};
  width: 100%;
  min-height: 35px;

  &:hover {
    color: ${({ theme }) => theme.color.font_bold[2]};
    background-color: ${({ theme }) => theme.color.tooltip_hover[2]};
  }

  &:active {
    color: ${({ theme }) => theme.color.font_bold[2]};
    background-color: ${({ theme }) => theme.color.tooltip_hover[2]};
  }

  :disabled {
    opacity: 0.33;
  }
`;

export const SuccessMenuButton = styled(`button`)`
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.color.font[2]};
  width: 100%;
  min-height: 35px;

  &:hover {
    color: ${({ theme }) => theme.color.font_bold[2]};
    background-color: ${({ theme }) => theme.color.tooltip_success_hover[2]};
  }

  &:active {
    color: ${({ theme }) => theme.color.font_bold[2]};
    background-color: ${({ theme }) => theme.color.tooltip_success_hover[1]};
  }

  :disabled {
    opacity: 0.33;
  }
`;

export const WarningMenuButton = styled(`button`)`
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.color.font[2]};
  width: 100%;
  min-height: 35px;

  &:hover {
    color: ${({ theme }) => theme.color.font_bold[2]};
    background-color: ${({ theme }) => theme.color.tooltip_warning_hover[2]};
  }

  &:active {
    color: ${({ theme }) => theme.color.font_bold[2]};
    background-color: ${({ theme }) => theme.color.tooltip_warning_hover[1]};
  }

  :disabled {
    opacity: 0.33;
  }
`;

export const DangerMenuButton = styled(`button`)`
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.color.font[2]};
  width: 100%;
  min-height: 35px;

  &:hover {
    color: ${({ theme }) => theme.color.font_bold[2]};
    background-color: ${({ theme }) => theme.color.tooltip_danger_hover[2]};
  }

  &:active {
    color: ${({ theme }) => theme.color.font_bold[2]};
    background-color: ${({ theme }) => theme.color.tooltip_danger_hover[1]};
  }

  :disabled {
    opacity: 0.33;
  }
`;

export const IconContainer = styled(`div`)`
  width: 16px;
  height: 16px;
  float: left;
  margin-right: 10px;
  fill: ${({ theme }) => theme.color.font[2]};

  ${/* sc-selector */ MenuLink}:hover & {
    fill: ${({ theme }) => theme.color.font_bold[2]};
  }
  ${/* sc-selector */ MenuButton}:hover & {
    fill: ${({ theme }) => theme.color.font_bold[2]};
  }
  ${/* sc-selector */ DangerMenuButton}:hover & {
    fill: ${({ theme }) => theme.color.font_bold[2]};
  }
`;
