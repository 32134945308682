import { getAccount } from "../services/localStorage";

export const isSuper = (): boolean => {
  if (getAccount().super) return true;
  else return false;
};

export const isAdmin = (): boolean => {
  if (getAccount().role === "Admin") return true;
  else return false;
};

export const isUser = (): boolean => {
  if (getAccount().role === "User") return true;
  else return false;
};

export const isAdminOrUser = (): boolean => {
  if (getAccount().role === "Admin" || getAccount().role === "User") return true;
  else return false;
};

export const isAlertsOnly = (): boolean => {
  if (getAccount().role === "AlertsOnly") return true;
  else return false;
};

export const isReadOnly = (): boolean => {
  if (getAccount().role === "ReadOnly") return true;
  else return false;
};

export const getRole = (role?: string): string => {
  if (!role && getAccount().super) {
    return "Super Admin";
  } else {
    switch (role || getAccount().role) {
      case "Admin":
        return "Admin";

      case "User":
        return "User";

      case "AlertsOnly":
        return "Alerts Only";

      case "ReadOnly":
        return "Read Only";

      default:
        return "Read Only";
    }
  }
};
