import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { InfoModal } from "../Modal";
import { saveAccount } from "../../services/localStorage";
import { fetchEditOrganisations } from "../../services/editOrganisation";
import { updateOrganisationId } from "../../services/localStorage";
import { fetchEditAccount } from "../../services/editAccount";
import { DangerAlert } from "../Alerts";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import Avatar from "react-avatar";
import matchSorter from "match-sorter";
import { useDebounce } from "use-debounce";
// import { useQueryCache } from "react-query";
import { FormInput } from "../FormComponents";
import { getRole } from "../../util/checkRole";
import { ModalFormContainer } from "../Modal/styles";
import { FilterContainer, OrgCard, OrgAvatar, OrgDetails, OrgName, OrgInfoContainer, OrgRoleHeading, OrgInfoCount, OrgInfoHeading } from "./styles";
import isTouch from "../../util/isTouch";
import { kegsOrTrackers } from "../../util/kegOrTracker";

const SelectOrganisationModal: FC<any> = ({ modalOpen, setModalOpen }) => {
  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [filteredOrgs, setFilteredOrgs] = useState<any>([]);
  const [filter, setFilter] = useState<string>("");

  const [filterValue] = useDebounce(filter, 250);

  // const queryCache = useQueryCache();

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Filter out the places that don't match the filtered text
  useEffect(() => {
    const filtered = matchSorter(data, filterValue, {
      threshold: matchSorter.rankings.CONTAINS,
      keys: ["name"],
    });
    const sorted = filtered.sort((a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    setFilteredOrgs(sorted);
  }, [filterValue, data]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchEditOrganisations(source)
      .then((response) => {
        const sorted = response.sort((a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        setData(sorted);
        setFilteredOrgs(sorted);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [source]);

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFilter(e.target.value);
  };

  const handleOrgClick = (id: string) => {
    updateOrganisationId(id);
    handleOrgChange();
  };

  const handleOrgChange = () => {
    setDataLoading(true);
    setDataErr("");
    fetchEditAccount(source)
      .then((response) => {
        saveAccount(response);
        // queryCache.invalidateQueries(() => true, { refetchInactive: true });
        // queryCache.clear();
        // Only clear query params for map page as it may be confusing if filters/map position aren't reset on org change
        const accountInfoUpdatedEvent = new Event("accountinfoupdated");
        document.dispatchEvent(accountInfoUpdatedEvent);
        if (window.location.pathname === "/map") window.location.search = "";
        else window.location.reload();
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  return (
    <InfoModal
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      title="Switch Organisation"
      okayBtnText="Close"
      okayBtnProps={{ width: "100%" }}
      padding={0}
      body={
        <>
          <FilterContainer>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <ModalFormContainer>
                <FormInput placeholder="Filter Organisations..." type="text" onChange={handleChange} value={filter} autoFocus={!isTouch()} />
              </ModalFormContainer>
            </form>
          </FilterContainer>
          <div style={{ overflowX: "hidden", overflowY: "auto", padding: "0 20px 20px" }}>
            <LoadingContainer loading={dataLoading}>
              {dataErr && <DangerAlert style={{ margin: "12px 0 0" }}>Error: {dataErr}</DangerAlert>}
              <div
                style={{
                  position: "relative",
                  height: "100%",
                  width: "100%",
                }}
              >
                <div style={{ padding: "6px 0" }}>
                  {filteredOrgs.map((org: any) => (
                    <OrgCard onClick={() => handleOrgClick(org.id)} key={org.id}>
                      <OrgAvatar>
                        <Avatar name={org.name} src={org.orgPhotoUrl} size="50px" round={true} />
                      </OrgAvatar>
                      <OrgDetails>
                        <OrgName>{org.name}</OrgName>
                        <OrgInfoContainer>
                          <OrgRoleHeading>
                            Role: <OrgInfoCount>{getRole(org.role)}</OrgInfoCount>
                          </OrgRoleHeading>
                        </OrgInfoContainer>
                        <OrgInfoContainer>
                          <OrgInfoHeading>
                            Users: <OrgInfoCount>{org.userCount}</OrgInfoCount>
                          </OrgInfoHeading>
                          <OrgInfoHeading>
                            {kegsOrTrackers("Kegs", "Trackers")}: <OrgInfoCount>{org.sensorCount}</OrgInfoCount>
                          </OrgInfoHeading>
                          <OrgInfoHeading>
                            Places: <OrgInfoCount>{org.placeCount}</OrgInfoCount>
                          </OrgInfoHeading>
                          {org.beerCount > 0 && (
                            <OrgInfoHeading>
                              Beers: <OrgInfoCount>{org.beerCount}</OrgInfoCount>
                            </OrgInfoHeading>
                          )}
                        </OrgInfoContainer>
                      </OrgDetails>
                    </OrgCard>
                  ))}
                </div>
              </div>
            </LoadingContainer>
          </div>
        </>
      }
    />
  );
};

export default SelectOrganisationModal;
