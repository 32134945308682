import React, { FC, useContext } from "react";
import ErrIcon from "../../svgs/ErrIcon";
import { Div, P } from "./styles";
import { ThemeContext } from "styled-components";

const NotFoundScreen: FC = () => {
  const { color } = useContext(ThemeContext);

  return (
    <Div>
      <div
        style={{
          width: "35px",
          height: "35px",
          marginRight: "10px",
        }}
      >
        <ErrIcon fill={color.error[1]} />
      </div>
      <P>Page Not Found</P>
    </Div>
  );
};

export default NotFoundScreen;
