import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const fetchInvite = async (source: CancelTokenSource) => {
  const params = {
    organisationId: getAccount().organisationId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/invite",
    params,
  });

  return data;
};

export const postInvite = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/api/invite",
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};
