import React, { FC, useState, useContext } from "react";
import { Container, Toggle, LegendTitle, LegendList, LegendItem, LegendImage, LegendText } from "./styles";
import SensorMarker from "../../svgs/SensorMarker";
import PlaceMarker from "../../svgs/PlaceMarker";
import Cluster from "../../svgs/Cluster";
import Close from "../../svgs/Close";
import Legend from "../../svgs/Legend";
import { ThemeContext } from "styled-components";
import { kegsOrTrackers } from "../../util/kegOrTracker";

const MapLegend: FC<any> = ({ sensors }): any => {
  const { color } = useContext(ThemeContext);

  const [legendOpen, toggleLegend] = useState<boolean>(false);

  return (
    <>
      <Toggle onClick={() => toggleLegend(!legendOpen)}>
        {legendOpen ? <Close background={color.main_bg[2]} cross={color.font[2]} /> : <Legend fill={color.main_bg[2]} />}
      </Toggle>
      <Container legendOpen={legendOpen}>
        <LegendTitle>Legend</LegendTitle>
        <LegendList>
          <LegendItem>
            <LegendText>{sensors === undefined ? 123 : sensors}</LegendText>
            {kegsOrTrackers("Keg Count", "Tracker Count")}
          </LegendItem>
          <LegendItem>
            <LegendImage style={{ width: "17px", margin: "0 8px 0 3px" }}>
              <SensorMarker fill={color.primary[2]} />
            </LegendImage>
            {kegsOrTrackers("Kegs", "Trackers")}
          </LegendItem>
          <LegendItem>
            <LegendImage>
              <Cluster fill={color.primary[2]} />
            </LegendImage>
            {kegsOrTrackers("Keg Cluster", "Tracker Cluster")}
          </LegendItem>
          <LegendItem>
            <LegendImage style={{ width: "17px", margin: "0 8px 0 3px" }}>
              <PlaceMarker fill={color.secondary[2]} />
            </LegendImage>
            Place
          </LegendItem>
          <LegendItem>
            <LegendImage>
              <Cluster fill={color.secondary[2]} />
            </LegendImage>
            Place Cluster
          </LegendItem>
        </LegendList>
      </Container>
    </>
  );
};

export default MapLegend;
