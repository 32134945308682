import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" {...props}>
      <path
        d="M194.531 11.448c-18.144 2.206-31.612 9.821-41.931 23.708-3.238 4.359-141.754 258.363-147.242 270.006-15.753 33.424 3.701 73.634 39.711 82.079 9.481 2.223 300.381 2.223 309.862 0 36.001-8.443 55.448-48.631 39.717-82.079-5.457-11.604-144.005-265.639-147.254-269.998-12.352-16.573-33.517-26.069-52.863-23.716m13.584 97.961c5.375 2.011 10.177 6.62 12.861 12.345l2.071 4.418v62.5c0 70.363.263 67.078-5.929 74.113-9.175 10.422-25.061 10.422-34.236 0-6.192-7.035-5.929-3.75-5.929-74.113v-62.5l2.071-4.418c5.381-11.478 17.577-16.653 29.091-12.345m0 187.5c14.628 5.473 19.337 24.447 8.986 36.207-14.074 15.989-39.784 5.976-39.75-15.482.025-15.495 15.992-26.252 30.764-20.725"
        fillRule="evenodd"
        opacity={0.6}
        fill={props.fill}
      />
      <path
        d="M200.014 11.094a48.979 48.979 0 00-5.48.353c-18.145 2.206-31.613 9.822-41.932 23.71-3.238 4.358-141.755 258.362-147.243 270.005-15.753 33.424 3.701 73.633 39.711 82.078 9.481 2.223 300.383 2.223 309.864 0 36-8.443 55.447-48.63 39.716-82.078-5.457-11.604-144.005-265.639-147.254-269.998-11.194-15.02-29.626-24.227-47.382-24.07zm0 17.556c15.72-.143 32.038 8.279 41.949 22.016 2.876 3.987 125.538 236.341 130.369 246.955 13.927 30.593-3.289 67.35-35.162 75.072-8.394 2.034-265.94 2.034-274.334 0-31.881-7.724-49.105-44.5-35.158-75.072 4.858-10.65 127.492-242.976 130.36-246.963 9.135-12.702 21.059-19.668 37.122-21.685a42.016 42.016 0 014.854-.323z"
        opacity={0.9}
        fill={props.fill}
      />
    </svg>
  );
}

export default SvgComponent;
