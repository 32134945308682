import React, { FC } from "react";
import { InfoModal } from "../Modal";
import PlaceAllocationsTable from "../PlaceAllocationsTable";

const PlaceAllocationsListModal: FC<any> = ({ placeId, modalOpen, setModalOpen, onClose }) => {
  return (
    <InfoModal
      isOpen={modalOpen}
      onClose={() => {
        if (onClose) onClose();
        setModalOpen(false);
      }}
      size="lg"
      title="Place Allocations"
      okayBtnText="Close"
      okayBtnProps={{ width: "100%" }}
      body={<PlaceAllocationsTable placeId={placeId} />}
    />
  );
};

export default PlaceAllocationsListModal;
