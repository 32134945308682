import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { postDeactivateSensor } from "../../services/activateSensors";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";

const DeactivateSelectedSensorModal: FC<any> = ({ id, onSuccess, modalOpen, setModalOpen }) => {
  const [deactivatedMsg, setDeactivatedMsg] = useState<string>("");
  const [deactivatedErr, setDeactivatedErr] = useState<string>("");
  const [deactivatedLoading, setDeactivatedLoading] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleDeactivate = () => {
    setDeactivatedLoading(true);

    const body = {
      sensorId: id,
    };

    postDeactivateSensor(source, body)
      .then(() => {
        onSuccess();
        setDeactivatedMsg("Tracker Deactivated");
        setDeactivatedLoading(false);
      })
      .catch((err) => {
        setDeactivatedErr(errToStr(err));
        setDeactivatedLoading(false);
      });
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleDeactivate()}
      onClose={() => {
        if (!deactivatedLoading) {
          setModalOpen(false);
        }
      }}
      title="Deactivate Selected Tracker"
      success={deactivatedMsg}
      error={deactivatedErr}
      submitBtnText="Deactivate Tracker"
      submitBtn="danger"
      body={
        <LoadingContainer loading={deactivatedLoading}>
          <span>Are you sure you want to deactivate tracker {id}?</span>
          <br />
          <span>This will remove the tracker's history.</span>
        </LoadingContainer>
      }
    />
  );
};

export default DeactivateSelectedSensorModal;
