import axios, { CancelTokenSource } from "axios";
import matchSorter from "match-sorter";
import moment from "moment";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { fetchEditUser } from "../../services/editUser";
import MoreIcon from "../../svgs/MoreIcon";
import { getRole, isAdmin, isSuper } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import Bold from "../Bold";
import ChangePasswordModal from "../ChangePasswordModal";
import EditUserModal from "../EditUserModal";
import FlatpickrRangePicker from "../FlatpickrRangePicker";
import LoadingContainer from "../LoadingContainer";
import { TableHeaderButtons } from "../NewTable/styles";
import RemoveUserModal from "../RemoveUserModal";
import ResetPasswordModal from "../ResetPasswordModal";
import Table, { CsvButtonsComponent } from "../Table";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

const UsersTable: FC<any> = () => {
  const { color, short_date, long_datetime, short_datetime } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [changePassModalOpen, setChangePassModalOpen] = useState<boolean>(false);
  const [resetPassModalOpen, setResetPassModalOpen] = useState<boolean>(false);
  const [removeUserModalOpen, setRemoveUserModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchEditUser(source)
      .then((response) => {
        setData(response);

        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [source]);

  const formatDataToCsv = (tableRef: any) => {
    const data = tableRef.current.getResolvedState().sortedData;

    const headers = ["Name", "Phone Number", "Email", "Role", "Last Logged In"];

    return [
      headers,
      ...data.map((row: any) => {
        return [row.fullName, row.phoneNumber, row.email, row.role, moment.unix(row.lastLoggedIn).format()];
      }, []),
    ];
  };

  const columns: any = [];

  columns.push({
    Header: "Actions",
    minWidth: 110,
    maxWidth: 110,
    filterable: false,
    sortable: false,
    Cell: ({ original }: any) => {
      return (
        <Tooltip
          maxWidth="none"
          theme="binary-no-padding"
          content={
            <MenuList>
              <MenuButton
                onClick={() => {
                  setSelectedUser(original);
                  setEditModalOpen(true);
                }}
              >
                Edit User
              </MenuButton>
              {isSuper() && (
                <MenuButton
                  onClick={() => {
                    setSelectedUser(original);
                    setChangePassModalOpen(true);
                  }}
                >
                  Change Password
                </MenuButton>
              )}
              {isAdmin() && (
                <MenuButton
                  onClick={() => {
                    setSelectedUser(original);
                    setResetPassModalOpen(true);
                  }}
                >
                  Send Password Reset
                </MenuButton>
              )}
              {isAdmin() && (
                <DangerMenuButton
                  onClick={() => {
                    setSelectedUser(original);
                    setRemoveUserModalOpen(true);
                  }}
                >
                  Remove User
                </DangerMenuButton>
              )}
            </MenuList>
          }
          interactive={true}
          touch={true}
          appendTo={document.body}
          trigger="click"
          placement="bottom-start"
        >
          <MoreIconContainer>
            <MoreIconSize>
              <MoreIcon fill={color.font[2]} />
            </MoreIconSize>
          </MoreIconContainer>
        </Tooltip>
      );
    },
  });

  columns.push({
    id: "fullName",
    Header: "Name",
    accessor: "fullName",
    minWidth: 180,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["fullName"],
      }),
    filterAll: true,
    Cell: (props: any) => <span title={props.value}>{props.value}</span>,
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  columns.push({
    id: "phoneNumber",
    Header: "Phone Number",
    accessor: "phoneNumber",
    minWidth: 175,
    maxWidth: 175,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["phoneNumber"],
      }),
    filterAll: true,
    Cell: (props: any) => <span title={props.value}>{props.value}</span>,
  });

  columns.push({
    id: "email",
    Header: "Email",
    accessor: "email",
    minWidth: 260,
    maxWidth: 260,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["email"],
      }),
    filterAll: true,
  });

  columns.push({
    id: "role",
    Header: "Role",
    accessor: "role",
    minWidth: 150,
    maxWidth: 150,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["role"],
      }),
    filterAll: true,
    Cell: (props: any) => {
      const role = getRole(props.value);
      return <span title={role}>{role}</span>;
    },
  });

  columns.push({
    id: "lastLoggedIn",
    Header: "Last Logged In",
    accessor: "lastLoggedIn",
    minWidth: 175,
    maxWidth: 175,
    filterMethod: (filter: any, row: any) => {
      if (filter.value.length === 2) {
        if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
        else return false;
      } else return true;
    },
    Filter: ({ onChange }: any) => (
      <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
    ),
    Cell: (props: any) =>
      props.value ? <span title={moment.unix(props.value).format(long_datetime)}>{moment.unix(props.value).format(short_datetime)}</span> : "",
  });

  const defaultSorted = [
    {
      id: "fullName",
      desc: false,
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <LoadingContainer loading={dataLoading} err={dataErr}>
        <TableHeaderButtons>
          <div style={{ display: "flex" }}></div>
          <CsvButtonsComponent data={data} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName="User List.csv" />
        </TableHeaderButtons>
        <Table
          loading={dataLoading}
          filterable={true}
          style={{ clear: "both" }}
          data={data}
          columns={columns}
          defaultSorted={defaultSorted}
          ref={tableRef}
          defaultPageSize={15}
        />
      </LoadingContainer>
      {editModalOpen && <EditUserModal user={selectedUser} setData={setData} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
      {changePassModalOpen && <ChangePasswordModal user={selectedUser} modalOpen={changePassModalOpen} setModalOpen={setChangePassModalOpen} />}
      {resetPassModalOpen && <ResetPasswordModal user={selectedUser} modalOpen={resetPassModalOpen} setModalOpen={setResetPassModalOpen} />}
      {removeUserModalOpen && <RemoveUserModal user={selectedUser} setData={setData} modalOpen={removeUserModalOpen} setModalOpen={setRemoveUserModalOpen} />}
    </div>
  );
};

export default UsersTable;
