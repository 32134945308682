import React, { FC } from "react";
import { Section } from "./styles";
import ResetPasswordForm from "../ResetPasswordForm";
import { useLocation } from "react-router-dom";

const ResetPasswordScreen: FC<any> = () => {
  const location = useLocation();

  return (
    <Section>
      <ResetPasswordForm location={location} />
    </Section>
  );
};

export default ResetPasswordScreen;
