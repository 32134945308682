import React, { FC, useState, useEffect } from "react";
import RcSlider from "rc-slider";
import { FormInput, InputUnitWrapper } from "../FormComponents";

const Slider: FC<any> = ({
  value,
  setValue,
  onChange,
  onAfterChange,
  minRange = 0,
  maxRange = 9999,
  unit = "",
  step = 1,
  hideNumberInput = false,
  marks = undefined,
}) => {
  const [displayedValue, setDisplayedValue] = useState<any>(value);

  useEffect(() => {
    setDisplayedValue(value);
  }, [value]);

  const handleKeyDown = (e: any) => {
    // 13 = ENTER
    if (e.keyCode === 13) {
      handleBlur({ target: { value: displayedValue } });
    }
  };

  const handleBlur = (e: any) => {
    const val = e.target.value;
    const regex = /^[-]?[0-9\b]+(\.[0-9]+)?$/;

    // set value if number else don't change
    if (val === "" || regex.test(val)) {
      const num = +val;
      if (num < minRange) {
        setValue(minRange);
        setDisplayedValue(minRange);
      } else if (num > maxRange) {
        setValue(maxRange);
        setDisplayedValue(maxRange);
      } else {
        // Snap to the nearest mark or step
        const nearestValue = marks ? getNearestMark(num) : getNearestStep(num);
        setValue(nearestValue);
        setDisplayedValue(nearestValue);
      }
    } else {
      setValue(value);
      setDisplayedValue(value);
    }
  };

  const getNearestMark = (num: number) => {
    const markValues = Object.keys(marks).map(Number);
    return markValues.reduce((prev, curr) => (Math.abs(curr - num) < Math.abs(prev - num) ? curr : prev));
  };

  const getNearestStep = (num: number) => {
    const precision = getDecimalPlaces(step);
    const stepsFromMin = Math.round((num - minRange) / step);
    const result = minRange + stepsFromMin * step;
    return parseFloat(result.toFixed(precision));
  };

  const getDecimalPlaces = (num: number) => {
    const numStr = num.toString();
    if (numStr.includes(".")) {
      return numStr.split(".")[1].length;
    }
    return 0;
  };

  return (
    <>
      <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
        {!hideNumberInput && (
          <div style={{ paddingRight: "16px" }}>
            <div style={{ textAlign: "center" }}>
              <InputUnitWrapper unit={unit}>
                <FormInput
                  style={{ textAlign: "center" }}
                  type="text"
                  value={displayedValue}
                  onChange={(e: any) => {
                    setDisplayedValue(e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                  onBlur={handleBlur}
                />
              </InputUnitWrapper>
            </div>
          </div>
        )}
        <RcSlider min={minRange} max={maxRange} value={value} step={step} marks={marks} onChange={onChange} onAfterChange={onAfterChange} />
      </div>
    </>
  );
};

export default Slider;
