import React, { FC, useContext, useEffect, useRef, useState } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { fetchAutoComplete } from "../../services/autoComplete";
import { FormError } from "../FormComponents";
import { ModalFormContainer } from "../Modal/styles";
import { AsyncSelect } from "../Select";
import { ThemeContext } from "styled-components";
import { INITIAL_VIEW_STATE } from "../../util/mapUtils";
import { MapContainer } from "./styles";
import { postUpdateLocation } from "../../services/updateLocation";

declare global {
  interface Window {
    google: any;
  }
}

const UpdateLocationModal: FC<any> = ({ sensor, onSuccess, modalOpen, setModalOpen }) => {
  const { map_style } = useContext(ThemeContext);

  const googleMapRef = useRef(null);

  const [place, setPlace] = useState<any>(undefined);
  const [placeErr, setPlaceErr] = useState<any>(undefined);

  const [googleMap, setGoogleMap] = useState<any>(undefined);
  const [marker, setMarker] = useState<any>(undefined);

  const [location, setLocation] = useState<any>(undefined);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, []);

  // If user allows geolocation use their location to center the map otherwise if the sensor
  // has a current location use that otherwise use the default location
  useEffect(() => {
    setLocationLoading(true);
    navigator.geolocation.getCurrentPosition(
      (pos: any) => {
        const { coords } = pos;
        setLocation({
          lat: coords.latitude,
          lng: coords.longitude,
          zoom: 16,
        });
        setLocationLoading(false);
      },
      () => {
        if (sensor.latitude && sensor.longitude) {
          setLocation({
            lat: sensor.latitude,
            lng: sensor.longitude,
            zoom: 16,
          });
        } else {
          setLocation({
            lat: INITIAL_VIEW_STATE.latitude,
            lng: INITIAL_VIEW_STATE.longitude,
            zoom: INITIAL_VIEW_STATE.zoom,
          });
        }
        setLocationLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    if (location && !googleMap) {
      createGoogleMap();
    }
  }, [location]);

  useEffect(() => {
    if (googleMap && location && !marker) {
      createMarker();
    }
  }, [googleMap, location]);

  useEffect(() => {
    if (googleMap) {
      googleMap.setOptions({
        styles: map_style,
      });
    }
  }, [map_style]);

  const handleSubmit = () => {
    const body = {
      sensorId: sensor.trackerId,
      placeId: place ? place.value : undefined,
      latitude: marker.position.lat(),
      longitude: marker.position.lng(),
    };

    setSubmitting(true);
    postUpdateLocation(source, body)
      .then(() => {
        if (onSuccess) onSuccess();
        setSubmittedMsg("Location Updated");
        setSubmittingErr("");
        setSubmitting(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  const createGoogleMap = () => {
    if (googleMapRef.current) {
      const newMap = new window.google.maps.Map(googleMapRef.current, {
        disableDefaultUI: true,
        controlSize: 26,
        zoomControl: true,
        mapTypeControl: true,
        fullscreenControl: true,
        styles: map_style,
        center: { lat: location.lat, lng: location.lng },
        zoom: location.zoom,
      });
      setGoogleMap(newMap);
    }
  };

  const createMarker = () => {
    const newMarker = new window.google.maps.Marker({
      position: { lat: location.lat, lng: location.lng },
      map: googleMap,
      draggable: true,
    });

    // deselect place when marker is moved
    newMarker.addListener("dragend", (e: any) => {
      setPlace(undefined);
      setPlaceErr(undefined);
    });

    // deselect place when marker is moved
    googleMap.addListener("click", (e: any) => {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      newMarker.setPosition({ lat, lng });
      setPlace(undefined);
      setPlaceErr(undefined);
    });

    setMarker(newMarker);
  };

  const handleSelectChange = (selected: any) => {
    setPlace(selected);
    setPlaceErr(undefined);
    if (selected) {
      googleMap.panTo({ lat: selected.latitude, lng: selected.longitude });
      marker.setPosition({ lat: selected.latitude, lng: selected.longitude });
    }
  };

  const loadPlaces = (inputValue: string, callback: any) => {
    fetchAutoComplete("places", inputValue).then((response) => {
      callback(response);
    });
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      title="Update Location"
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Update"
      body={
        <LoadingContainer loading={locationLoading || submitting}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer>
              <label>Place</label>
              <AsyncSelect
                name="place"
                defaultOptions={true}
                isClearable={true}
                isSearchable={true}
                isError={placeErr}
                value={place || ""}
                loadOptions={(inputValue: any, callback: any) => loadPlaces(inputValue, callback)}
                onChange={handleSelectChange}
                placeholder="Select..."
              />
              <FormError error={placeErr}>{placeErr}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <MapContainer id="update-location-google-map" ref={googleMapRef} />
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default UpdateLocationModal;
