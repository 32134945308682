export const postToken = async (email: string, password: string, noExpire: boolean, twoFactor?: string) => {
  let data = `grant_type=password&username=${email}&password=${password}`;
  if (twoFactor) data += `&twoFactor=${twoFactor}`;
  if (noExpire) data += "&noExpire=true";

  const url = process.env.REACT_APP_API_URL + "/token";

  const headers = new Headers();
  headers.append("Content-Type", "application/x-www-form-urlencoded");
  headers.append("cache-control", "no-cache");

  const req = new Request(url, {
    method: "POST",
    headers,
    body: data,
  });

  const res = await fetch(req);
  if (res.status !== 200) {
    const errMsg = await res.json();
    throw Error(errMsg.message ? errMsg.message : res.statusText);
  }
  const jsonData = await res.json();
  return jsonData;
};
