import React, { FC } from "react";

const Marker: FC<any> = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 263.3 263.3">
    <path
      fill={fill}
      fillOpacity=".88"
      stroke="#000"
      strokeWidth={2}
      d="M45.5 157.5a291.4 291.4 0 0083.3 97.9c.8.4 1.8.6 2.7.6h.4c1 0 2.1-.2 3.1-.7.8-.4 1.5-.9 2-1.5 75.8-58 90.6-118.9 91.2-121.3a100 100 0 10-196.3-25.4 99.9 99.9 0 003.5 26.6c.6 1.7 6.3 17.2 10.1 23.8zm85.6-78.1a36 36 0 00-32.4 20 6.8 6.8 0 01-12-6 49.9 49.9 0 0189.4 1.1 6.8 6.8 0 11-12.3 5.7A36.3 36.3 0 00131 79.4zm-.2-34.5a58.4 58.4 0 00-52.5 32.5 6.8 6.8 0 11-12-6c12.2-24.7 37-40 64.5-40 28 0 53.6 16.3 65.4 41.6a6.8 6.8 0 01-12.2 5.7A58.9 58.9 0 00130.9 45z"
    />
    <path
      fill={fill}
      stroke="#000"
      strokeWidth={2}
      d="M131.7 0a105.8 105.8 0 00-102 133.8c.7 1.8 6.7 18.3 10.8 25.2a308.5 308.5 0 0088.1 103.6c1 .5 2 .7 3 .7h.3c1.1 0 2.3-.2 3.3-.7.8-.4 1.6-1 2.2-1.7 80.2-61.4 95.8-125.8 96.4-128.3A105.8 105.8 0 00131.6 0zm0 15.4a91.7 91.7 0 0188.5 114.9c-.5 2.2-14 58-83.6 111.2-.5.6-1.1 1.1-1.9 1.5-.9.4-1.9.6-2.8.6h-.4a6 6 0 01-2.5-.6 267.3 267.3 0 01-76.4-89.8c-3.5-6-8.7-20.3-9.3-21.9l-.3-1.5a91.7 91.7 0 0188.7-114.4z"
    />
  </svg>
);

export default Marker;
