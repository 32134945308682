import {
  ButtonGroup as ZenButtonGroup,
  Button,
  //@ts-ignore
} from "@zendeskgarden/react-buttons";
// @ts-ignore
import "@zendeskgarden/react-buttons/dist/styles.css";
import styled from "../Themes";

const ButtonGroup = styled(ZenButtonGroup)``;

const PrimaryToggleBtn = styled<any>(Button)`
  transition: none;
  background-color: ${({ selected, theme }) => (selected ? theme.color.primary[2] : "transparent")} !important;
  border: 1px solid ${({ selected, theme }) => (selected ? theme.color.primary[2] : theme.color.font[2])} !important;
  color: ${({ selected, theme }) => (selected ? theme.color.button_font_bold[2] : theme.color.font[2])} !important;
  padding: ${({ padding }) => (padding !== undefined ? padding : "0px 10px")} !important;
  width: ${({ width }) => (width !== undefined ? width : "auto")} !important;
  float: ${({ float }) => (float !== undefined ? float : "unset")} !important;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")} !important;
  pointer-events: ${({ disabled }) => (disabled ? "all" : "default")} !important;

  &:hover {
    background-color: ${({ selected, theme }) => (selected ? theme.color.primary[1] : theme.color.tooltip_hover[3])} !important;
    border: 1px solid ${({ selected, theme }) => (selected ? theme.color.primary[1] : theme.color.font_bold[2])} !important;
    color: ${({ selected, theme }) => (selected ? theme.color.button_font_bold[2] : theme.color.font_bold[2])} !important;
  }

  &:focus {
    background-color: ${({ selected, theme }) => (selected ? theme.color.primary[1] : theme.color.tooltip_hover[3])} !important;
    border: 1px solid ${({ selected, theme }) => (selected ? theme.color.primary[1] : theme.color.font_bold[2])} !important;
    color: ${({ selected, theme }) => (selected ? theme.color.button_font_bold[2] : theme.color.font_bold[2])} !important;
  }

  &:active {
    background-color: ${({ selected, theme }) => (selected ? theme.color.primary[1] : theme.color.tooltip_hover[3])} !important;
    border: 1px solid ${({ selected, theme }) => (selected ? theme.color.primary[1] : theme.color.font_bold[2])} !important;
    color: ${({ selected, theme }) => (selected ? theme.color.button_font_bold[2] : theme.color.font_bold[2])} !important;
  }
`;

const SecondaryToggleBtn = styled<any>(Button)`
  transition: none;
  background-color: ${({ selected, theme }) => (selected ? theme.color.secondary[2] : "transparent")} !important;
  border: 1px solid ${({ selected, theme }) => (selected ? theme.color.secondary[2] : theme.color.font[2])} !important;
  color: ${({ selected, theme }) => (selected ? theme.color.button_font_bold[2] : theme.color.font[2])} !important;
  padding: ${({ padding }) => (padding !== undefined ? padding : "0px 10px")} !important;
  width: ${({ width }) => (width !== undefined ? width : "auto")} !important;
  float: ${({ float }) => (float !== undefined ? float : "unset")} !important;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")} !important;
  pointer-events: ${({ disabled }) => (disabled ? "all" : "default")} !important;

  &:hover {
    background-color: ${({ selected, theme }) => (selected ? theme.color.secondary[1] : theme.color.tooltip_hover[3])} !important;
    border: 1px solid ${({ selected, theme }) => (selected ? theme.color.secondary[1] : theme.color.font_bold[2])} !important;
    color: ${({ selected, theme }) => (selected ? theme.color.button_font_bold[2] : theme.color.font_bold[2])} !important;
  }

  &:focus {
    background-color: ${({ selected, theme }) => (selected ? theme.color.secondary[1] : theme.color.tooltip_hover[3])} !important;
    border: 1px solid ${({ selected, theme }) => (selected ? theme.color.secondary[1] : theme.color.font_bold[2])} !important;
    color: ${({ selected, theme }) => (selected ? theme.color.button_font_bold[2] : theme.color.font_bold[2])} !important;
  }

  &:active {
    background-color: ${({ selected, theme }) => (selected ? theme.color.secondary[1] : theme.color.tooltip_hover[3])} !important;
    border: 1px solid ${({ selected, theme }) => (selected ? theme.color.secondary[1] : theme.color.font_bold[2])} !important;
    color: ${({ selected, theme }) => (selected ? theme.color.button_font_bold[2] : theme.color.font_bold[2])} !important;
  }
`;

const WarningBtn = styled<any>(Button)`
  transition: none;
  background-color: ${({ theme }) => theme.color.warning[2]} !important;
  border: 1px solid ${({ theme }) => theme.color.warning[2]} !important;
  color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
  padding: ${({ padding }) => (padding !== undefined ? padding : "0px 10px")} !important;
  width: ${({ width }) => (width !== undefined ? width : "auto")} !important;
  float: ${({ float }) => (float !== undefined ? float : "unset")} !important;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")} !important;
  pointer-events: ${({ disabled }) => (disabled ? "all" : "default")} !important;

  &:hover {
    background-color: ${({ theme }) => theme.color.warning[1]} !important;
    color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color.warning[1]} !important;
    svg {
      color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    }
  }

  &:focus {
    background-color: ${({ theme }) => theme.color.warning[1]} !important;
    color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    background-color: ${({ theme }) => theme.color.warning[1]} !important;
    svg {
      color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.color.warning[1]} !important;
    color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    background-color: ${({ theme }) => theme.color.warning[1]} !important;
    svg {
      color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    }
  }
`;

const DangerBtn = styled<any>(Button)`
  transition: none;
  background-color: ${({ theme }) => theme.color.danger[2]} !important;
  border: 1px solid ${({ theme }) => theme.color.danger[2]} !important;
  color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
  padding: ${({ padding }) => (padding !== undefined ? padding : "0px 10px")} !important;
  width: ${({ width }) => (width !== undefined ? width : "auto")} !important;
  float: ${({ float }) => (float !== undefined ? float : "unset")} !important;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")} !important;
  pointer-events: ${({ disabled }) => (disabled ? "all" : "default")} !important;

  &:hover {
    background-color: ${({ theme }) => theme.color.danger[1]} !important;
    color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color.danger[1]} !important;
    svg {
      color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    }
  }

  &:focus {
    background-color: ${({ theme }) => theme.color.danger[1]} !important;
    color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    background-color: ${({ theme }) => theme.color.danger[1]} !important;
    svg {
      color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.color.danger[1]} !important;
    color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    background-color: ${({ theme }) => theme.color.danger[1]} !important;
    svg {
      color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    }
  }
`;

const PrimaryBtn = styled<any>(Button)`
  transition: none;
  background-color: ${({ theme }) => theme.color.primary[2]} !important;
  border: 1px solid ${({ theme }) => theme.color.primary[2]} !important;
  color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
  padding: ${({ padding }) => (padding !== undefined ? padding : "0px 10px")} !important;
  width: ${({ width }) => (width !== undefined ? width : "auto")} !important;
  float: ${({ float }) => (float !== undefined ? float : "unset")} !important;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")} !important;
  pointer-events: ${({ disabled }) => (disabled ? "all" : "default")} !important;

  &:hover {
    background-color: ${({ theme }) => theme.color.primary[1]} !important;
    color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color.primary[1]} !important;
    svg {
      color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    }
  }

  &:focus {
    background-color: ${({ theme }) => theme.color.primary[1]} !important;
    color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    background-color: ${({ theme }) => theme.color.primary[1]} !important;
    svg {
      color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.color.primary[1]} !important;
    color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    background-color: ${({ theme }) => theme.color.primary[1]} !important;
    svg {
      color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    }
  }
`;

const OutlineBtn = styled<any>(Button)`
  transition: none;
  background-color: transparent !important;
  border: 1px solid ${({ theme }) => theme.color.font[2]} !important;
  color: ${({ theme }) => theme.color.font[2]} !important;
  padding: ${({ padding }) => (padding !== undefined ? padding : "0px 10px")} !important;
  width: ${({ width }) => (width !== undefined ? width : "auto")} !important;
  float: ${({ float }) => (float !== undefined ? float : "unset")} !important;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")} !important;
  pointer-events: ${({ disabled }) => (disabled ? "all" : "default")} !important;

  &:hover {
    background-color: ${({ theme }) => theme.color.tooltip_hover[3]} !important;
    color: ${({ theme }) => theme.color.font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color.font_bold[2]} !important;
    svg {
      color: ${({ theme }) => theme.color.font_bold[2]} !important;
    }
  }

  &:focus {
    background-color: ${({ theme }) => theme.color.tooltip_hover[3]} !important;
    color: ${({ theme }) => theme.color.font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color.font_bold[2]} !important;
    svg {
      color: ${({ theme }) => theme.color.font_bold[2]} !important;
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.color.tooltip_hover[3]} !important;
    color: ${({ theme }) => theme.color.font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color.font_bold[2]} !important;
    svg {
      color: ${({ theme }) => theme.color.font_bold[2]} !important;
    }
  }
`;

const GhostBtn = styled<any>(Button)`
  transition: none;
  background-color: transparent !important;
  border: 1px solid transparent !important;
  color: ${({ theme }) => theme.color.font[2]} !important;
  padding: ${({ padding }) => (padding !== undefined ? padding : "0px 10px")} !important;
  width: ${({ width }) => (width !== undefined ? width : "auto")} !important;
  float: ${({ float }) => (float !== undefined ? float : "unset")} !important;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")} !important;
  pointer-events: ${({ disabled }) => (disabled ? "all" : "default")} !important;

  &:hover {
    background-color: ${({ theme }) => theme.color.tooltip_hover[3]} !important;
    color: ${({ theme }) => theme.color.font_bold[2]} !important;
    border: 1px solid transparent !important;
    svg {
      color: ${({ theme }) => theme.color.font_bold[2]} !important;
    }
  }

  &:focus {
    background-color: ${({ theme }) => theme.color.tooltip_hover[3]} !important;
    color: ${({ theme }) => theme.color.font_bold[2]} !important;
    border: 1px solid transparent !important;
    svg {
      color: ${({ theme }) => theme.color.font_bold[2]} !important;
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.color.tooltip_hover[3]} !important;
    color: ${({ theme }) => theme.color.font_bold[2]} !important;
    border: 1px solid transparent !important;
    svg {
      color: ${({ theme }) => theme.color.font_bold[2]} !important;
    }
  }
`;

export { ButtonGroup, PrimaryToggleBtn, SecondaryToggleBtn, WarningBtn, DangerBtn, PrimaryBtn, OutlineBtn, GhostBtn };
