import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import matchSorter from "match-sorter";
import moment from "moment";
import queryString from "query-string";
import Drawer from "rc-drawer";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { TbDownload } from "react-icons/tb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AsyncSelect } from "../../Select";
import ReactTimeago from "react-timeago";
import { ThemeContext } from "styled-components";
import { fetchAutoComplete } from "../../../services/autoComplete";
import { fetchReport11 } from "../../../services/report11";
import Cross from "../../../svgs/Cross";
import downloadFile from "../../../util/downloadFile";
import errToStr from "../../../util/errToStr";
import getParameterByName from "../../../util/getParamByName";
import { kegOrTracker, kegsOrTrackers } from "../../../util/kegOrTracker";
import roundBatteryPercent from "../../../util/roundBatteryPercent";
import sortTags from "../../../util/sortTags";
import AssetTypeLabel from "../../AssetTypeLabel";
import Bold from "../../Bold";
import { PrimaryBtn } from "../../Buttons";
import { DrawBody, DrawCloseButton, DrawContent, DrawFooter, DrawHeader, FilterInputContainer } from "../../Drawer/styles";
import FlatpickrRangePicker from "../../FlatpickrRangePicker";
import LoadingContainer from "../../LoadingContainer";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import { Subtitle, Title } from "../../PageTitle/styles";
import { ClearPanel, Panel } from "../../Panel";
import Table from "../../Table";
import Tag from "../../Tag";
import { BatteryContainer } from "../styles";
import { InputUnitWrapper, FormInput } from "../../FormComponents";

const formatDataToCsv = (tableRef: any, long_datetime: string) => {
  const headers = [
    "Tracker ID",
    "Name",
    "Asset Type",
    kegsOrTrackers("Keg Tags", "Tracker Tags"),
    "Battery (%)",
    "Last Seen",
    "Latest Visit",
    "Visit Start Date",
    "Visit End Date",
  ];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [
        row.trackerId,
        row.trackerName,
        row.assetTypeName,
        row.trackerTags
          ? row.trackerTags
              .sort(sortTags)
              .map((tag: any) => tag.name)
              .join(", ")
          : "",
        roundBatteryPercent(row.batteryPercentage),
        row.latestSampleDateUnix ? moment.unix(row.latestSampleDateUnix).format(long_datetime) : "",
        row.latestVisitPlaceName,
        row.latestVisitStartDate ? moment.unix(row.latestVisitStartDate).format(long_datetime) : "",
        row.latestVisitEndDate ? moment.unix(row.latestVisitEndDate).format(long_datetime) : "",
      ];
    }, []),
  ];
};

const LowBatteries: FC<any> = () => {
  const { color, long_datetime, short_date, short_datetime } = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const tableRef = useRef<any>(null);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  // Report filters
  const [maxBattery, setMaxBattery] = useState<any>(25);
  const [trackerTags, setTrackerTags] = useState<any>([]);
  const [assetType, setAssetType] = useState<any>(null);
  const [latestVisit, setLatestVisit] = useState<any>(null);

  const [appliedFilters, setAppliedFilters] = useState<number>(0);

  const [autoCompleteTrackerTags, setAutoCompleteTrackerTags] = useState<any>(undefined);
  const [autoCompleteAssetTypes, setAutoCompleteAssetTypes] = useState<any>(undefined);
  const [autoCompletePlaces, setAutoCompletePlaces] = useState<any>(undefined);

  const [filterOptions, setFilterOptions] = useState<any>({
    assetTypes: [],
    trackerTags: [],
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Fetch all tracker tags and asset types from autoComplete to use for matching params
  // e.g. match "?asset-types=Keg" to {colour: "#e6b600", icon: "BbKeg", label: "Keg", value: "b08d3cf1-cb6e-4026-998b-5702ca4a4829"}
  useEffect(() => {
    const tagsParam = getParameterByName("tags", location.search) !== null ? getParameterByName("tags", location.search)?.split(",") : [];
    const assetTypeParam = getParameterByName("asset-type", location.search) !== null ? getParameterByName("asset-type", location.search) : null;
    const latestVisitParam = getParameterByName("latest-visit", location.search) != null ? getParameterByName("latest-visit", location.search) : null;

    fetchAutoComplete("trackertags", "").then((response) => {
      if (tagsParam && tagsParam.length > 0) {
        setTrackerTags(response.filter((tag: any) => tagsParam.includes(tag.label)));
      }
      setAutoCompleteTrackerTags(response);
    });

    fetchAutoComplete("assettypes", "").then((response) => {
      if (assetTypeParam !== null) {
        setAssetType(response.find((type: any) => assetTypeParam === type.label));
      }
      setAutoCompleteAssetTypes(response);
    });

    fetchAutoComplete("places", "").then((response) => {
      if (latestVisitParam != null) {
        setLatestVisit(response.find((place: any) => latestVisitParam === place.label));
      }
      setAutoCompletePlaces(response);
    });
  }, []);

  // On location change (e.g. url parameter changes because filters changed), update filters and fetch report
  useEffect(() => {
    let filteredMaxBattery = undefined;
    let filteredTrackerTags = [];
    let filteredAssetType = undefined;
    let filteredLatestVisit = undefined;

    const maxBatteryParam = getParameterByName("battery", location.search) !== null ? getParameterByName("battery", location.search) : null;
    if (maxBatteryParam !== null) {
      filteredMaxBattery = maxBatteryParam;
      setMaxBattery(filteredMaxBattery != null ? +filteredMaxBattery : undefined);
    }

    if (autoCompleteTrackerTags != null) {
      const tagsParam = getParameterByName("tags", location.search) !== null ? getParameterByName("tags", location.search)?.split(",") : [];
      filteredTrackerTags = autoCompleteTrackerTags.filter((type: any) => tagsParam?.includes(type.label));
      setTrackerTags(filteredTrackerTags);
    }

    if (autoCompleteAssetTypes != null) {
      const assetTypeParam = getParameterByName("asset-type", location.search) !== null ? getParameterByName("asset-type", location.search) : null;
      filteredAssetType = autoCompleteAssetTypes.find((type: any) => assetTypeParam === type.label);
      setAssetType(filteredAssetType);
    }

    if (autoCompletePlaces != null) {
      const latestVisitParam = getParameterByName("latest-visit", location.search) != null ? getParameterByName("latest-visit", location.search) : null;
      filteredLatestVisit = autoCompletePlaces.find((place: any) => latestVisitParam === place.label);
      setLatestVisit(filteredLatestVisit);
    }

    fetchReport(filteredMaxBattery, filteredTrackerTags, filteredAssetType, filteredLatestVisit);
  }, [location]);

  // After autoCompleteTrackerTags & autoCompleteAssetTypes is initially set on mount, fetch report
  useEffect(() => {
    if (autoCompleteTrackerTags != null && autoCompleteAssetTypes != null && autoCompletePlaces != null) {
      fetchReport();
    }
  }, [autoCompleteTrackerTags, autoCompleteAssetTypes, autoCompletePlaces]);

  const updateQueryParams = () => {
    const parsed = queryString.parse(location.search);
    const newQuery = { ...parsed };

    if (maxBattery) newQuery["battery"] = maxBattery.toString();
    else delete newQuery["battery"];

    if (trackerTags && trackerTags.length > 0) {
      newQuery["tags"] = trackerTags.map((tag: any) => tag.label).join(",");
    } else if (autoCompleteTrackerTags != null) {
      delete newQuery["tags"];
    }

    if (assetType !== null) {
      newQuery["asset-type"] = assetType?.label;
    } else if (autoCompleteAssetTypes != null) {
      delete newQuery["asset-type"];
    }

    if (latestVisit != null) {
      newQuery["latest-visit"] = latestVisit?.label;
    } else if (autoCompletePlaces != null) {
      delete newQuery["latest-visit"];
    }

    const stringified = queryString.stringify(newQuery);
    navigate({ ...location, search: stringified });
  };

  const fetchReport = (battery?: any, tags?: any, type?: any, visit?: any) => {
    setDataLoading(true);
    setDataErr("");

    const batteryArg = battery != null ? battery : !maxBattery ? undefined : maxBattery;
    const tagsArg = tags?.length > 0 ? tags : trackerTags;
    const assetTypeArg = type != null ? type : assetType;
    const latestVisitArg = visit != null ? visit : latestVisit;

    setMaxBattery(batteryArg);

    fetchReport11(source, batteryArg, tagsArg.map((tag: any) => tag.label).join(","), assetTypeArg?.label, latestVisitArg?.value)
      .then((response) => {
        // get distinct strings for use in the select input filters
        if (response.length > 0) {
          const assetTypes = new Set();
          const trackerTags = new Set();

          for (let i = 0; i < response.length; i++) {
            if (response[i].assetTypeName) assetTypes.add(response[i].assetTypeName);
            if (response[i].trackerTags) response[i].trackerTags.forEach((tag: any) => trackerTags.add(tag.name));
          }

          setFilterOptions({
            assetTypes: Array.from(assetTypes).sort(),
            trackerTags: Array.from(trackerTags).sort(),
          });
        }

        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData([]);
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    let filters = 0;

    if (batteryArg) filters++;
    if (tagsArg.length) filters += tagsArg.length;
    if (assetTypeArg) filters++;
    if (latestVisitArg != null) filters++;

    setAppliedFilters(filters);
  };

  const columns = [
    {
      id: "trackerId",
      Header: "Tracker ID",
      accessor: "trackerId",
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["trackerId"],
        }),
      filterAll: true,
      Cell: (props: any) => {
        return (
          <Link to={`/${kegOrTracker("kegs", "trackers")}/${props.value}`} title={props.value}>
            {props.value}
          </Link>
        );
      },
      Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
      minWidth: 130,
    },
    {
      id: "trackerName",
      Header: "Name",
      accessor: "trackerName",
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["trackerName"],
        }),
      filterAll: true,
      minWidth: 130,
    },
    {
      id: "assetTypeName",
      Header: "Asset Type",
      accessor: "assetTypeName",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") return true;
        if (filter.value === "none") return row[filter.id] === undefined;
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          <option value="none">None</option>
          {filterOptions.assetTypes.map((name: any) => {
            return (
              <option key={name} value={name}>
                {name}
              </option>
            );
          }, [])}
        </select>
      ),
      Cell: (props: any) => (
        <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
          <AssetTypeLabel name={props.value} colour={props.original.assetTypeColour} icon={props.original.assetTypeIcon} />
        </div>
      ),
      minWidth: 125,
    },
    {
      id: "trackerTags",
      Header: kegsOrTrackers("Keg Tags", "Tracker Tags"),
      accessor: "trackerTags",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") return true;
        if (filter.value === "none") return row[filter.id].length === 0;
        if (Array.isArray(row[filter.id])) return row[filter.id].some((tag: any) => tag.name === filter.value);
        return false;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          <option value="none">None</option>
          {filterOptions.trackerTags.map((tag: any) => {
            return (
              <option key={tag} value={tag}>
                {tag}
              </option>
            );
          }, [])}
        </select>
      ),
      Cell: (props: any) =>
        props.value ? (
          props.value.sort(sortTags).map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
        ) : (
          <></>
        ),
      minWidth: 140,
    },
    {
      id: "batteryPercentage",
      Header: "Battery",
      accessor: "batteryPercentage",
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: [(item: any) => String(roundBatteryPercent(item.batteryPercentage) || 0)],
        }),
      filterAll: true,
      Cell: (props: any) => (
        <BatteryContainer percent={roundBatteryPercent(props.value)}>
          {props.value != null ? `${roundBatteryPercent(props.value)}%` : undefined}
        </BatteryContainer>
      ),
      minWidth: 110,
    },
    {
      id: "latestSampleDateUnix",
      Header: "Last Seen",
      accessor: "latestSampleDateUnix",
      filterMethod: (filter: any, row: any) => {
        if (filter.value.length === 2) {
          if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
          else return false;
        } else return true;
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
      ),
      Cell: (props: any) => <ReactTimeago live={false} date={props.value * 1000} title={moment.unix(props.value).format(long_datetime)} />,
      minWidth: 150,
    },
    {
      id: "latestVisitPlaceId",
      Header: "Latest Visit",
      accessor: "latestVisitPlaceId",
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["latestVisitPlaceName"],
        }),
      filterAll: true,
      Cell: (props: any) => {
        return (
          <Link to={`/places/${props.value}`} title={props.original.latestVisitPlaceName}>
            {props.original.latestVisitPlaceName}
          </Link>
        );
      },
      minWidth: 150,
    },
    {
      id: "latestVisitPlaceName",
      accessor: "latestVisitPlaceName",
      show: false,
    },
    {
      id: "latestVisitStartDate",
      Header: "Visit Start Date",
      accessor: "latestVisitStartDate",
      filterMethod: (filter: any, row: any) => {
        if (filter.value.length === 2) {
          if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
          else return false;
        } else return true;
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
      ),
      Cell: (props: any) => (props.value ? moment.unix(props.value).format(short_datetime) : ""),
      minWidth: 120,
    },
    {
      id: "latestVisitEndDate",
      Header: "Visit End Date",
      accessor: "latestVisitEndDate",
      filterMethod: (filter: any, row: any) => {
        if (filter.value.length === 2) {
          if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
          else return false;
        } else return true;
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
      ),
      Cell: (props: any) => (props.value ? moment.unix(props.value).format(short_datetime) : ""),
      minWidth: 120,
    },
  ];

  const defaultSorted = [
    {
      id: "batteryPercentage",
      desc: false,
    },
  ];

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  return (
    <>
      <Drawer
        placement="right"
        level={null}
        open={filtersOpen}
        onClose={() => {
          setFiltersOpen(false);
          updateQueryParams();
        }}
        onHandleClick={() => setFiltersOpen(!filtersOpen)}
        handler={false}
      >
        <DrawContent>
          <DrawHeader>
            <div
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                fontSize: "0",
                marginRight: "6px",
              }}
            >
              <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                <HiOutlineAdjustmentsHorizontal />
              </IconContext.Provider>
            </div>
            Filters
            <DrawCloseButton
              onClick={() => {
                setFiltersOpen(false);
              }}
              aria-label={`Close filters`}
            >
              <Cross />
            </DrawCloseButton>
          </DrawHeader>
          <DrawBody>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <label>Max Battery Percent</label>
              <FilterInputContainer>
                <InputUnitWrapper style={{ width: "100%" }} unit="%">
                  <FormInput
                    type="number"
                    name="maxBattery"
                    value={maxBattery}
                    onChange={(e: any) => {
                      e.persist();
                      e.preventDefault();
                      setMaxBattery(e.target.value);
                    }}
                    required={true}
                    placeholder="25"
                    step={5}
                    min={0}
                    max={100}
                  />
                </InputUnitWrapper>
              </FilterInputContainer>
              <label>Asset Type</label>
              <FilterInputContainer>
                <AsyncSelect
                  name="assetType"
                  defaultOptions={true}
                  isClearable={true}
                  isSearchable={true}
                  value={assetType}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("assetTypes", inputValue, callback)}
                  onChange={(selected: any) => {
                    if (selected) {
                      setAssetType(selected);
                    } else {
                      setAssetType(null);
                    }
                  }}
                  placeholder="Select..."
                />
              </FilterInputContainer>
              <label>{kegsOrTrackers("Keg Tags", "Tracker Tags")}</label>
              <FilterInputContainer>
                <AsyncSelect
                  name="trackerTags"
                  defaultOptions={true}
                  closeMenuOnSelect={false}
                  isClearable={true}
                  isMulti={true}
                  isSearchable={true}
                  value={trackerTags}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("trackerTags", inputValue, callback)}
                  onChange={(selected: any) => {
                    if (selected) {
                      setTrackerTags(selected);
                    } else {
                      setTrackerTags([]);
                    }
                  }}
                  placeholder="Select..."
                />
              </FilterInputContainer>
              <label>Latest Visit</label>
              <FilterInputContainer>
                <AsyncSelect
                  name="latestVisit"
                  defaultOptions={true}
                  isClearable={true}
                  isSearchable={true}
                  value={latestVisit}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                  onChange={(selected: any) => {
                    if (selected) {
                      setLatestVisit(selected);
                    } else {
                      setLatestVisit(null);
                    }
                  }}
                  placeholder="Select..."
                />
              </FilterInputContainer>
            </form>
          </DrawBody>
          <DrawFooter>
            <PrimaryBtn
              onClick={() => {
                setFiltersOpen(false);
                updateQueryParams();
              }}
            >
              Apply Filters
            </PrimaryBtn>
          </DrawFooter>
        </DrawContent>
      </Drawer>
      <PageBreadcrumbs prevRoutes={[{ slug: "/admin-reports", title: "Admin Reports" }]} currRoute="Low Batteries" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading} err={dataErr}>
            <ClearPanel style={{ textAlign: "center" }}>
              <Title style={{ marginBottom: "6px" }}>Low Batteries</Title>
              <Subtitle>Trackers with low batteries that may need replacing.</Subtitle>
            </ClearPanel>
            <ClearPanel style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <PrimaryBtn style={{ height: "40px", minWidth: "unset", marginRight: "12px" }} padding="0 6px" onClick={() => setFiltersOpen(!filtersOpen)}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div
                      style={{
                        display: "inline-block",
                        width: "20px",
                        height: "20px",
                        fontSize: "0",
                        marginRight: "6px",
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiOutlineAdjustmentsHorizontal />
                      </IconContext.Provider>
                    </div>
                    <span>Filters</span>
                  </div>
                </PrimaryBtn>
                <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                  {appliedFilters} filter{appliedFilters === 1 ? "" : "s"} applied
                </span>
              </div>
              <PrimaryBtn
                style={{ height: "40px", minWidth: "unset", marginRight: "12px" }}
                padding="0 6px"
                onClick={() =>
                  downloadFile(
                    stringify(formatDataToCsv(tableRef, long_datetime), {
                      quoted: true,
                      quoted_string: true,
                    }),
                    "text/csv;charset=utf-8",
                    "Low Batteries.csv"
                  )
                }
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "inline-block",
                      width: "20px",
                      height: "20px",
                      fontSize: "0",
                      marginRight: "6px",
                    }}
                  >
                    <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                      <TbDownload />
                    </IconContext.Provider>
                  </div>
                  <span
                    style={{
                      marginRight: "6px",
                    }}
                  >
                    CSV
                  </span>
                </div>
              </PrimaryBtn>
            </ClearPanel>
            <Panel>
              <Table
                loading={dataLoading}
                style={{ clear: "both" }}
                data={data}
                columns={columns}
                defaultSorted={defaultSorted}
                ref={tableRef}
                filterable={true}
              />
            </Panel>
          </LoadingContainer>
        </div>
      </PageContainer>
    </>
  );
};

export default LowBatteries;
