import axios from "axios";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { fetchReport4 } from "../../../services/report4";
import errToStr from "../../../util/errToStr";
import { printFixedTemp, printTempUnit } from "../../../util/formatUnits";
import { humaniseHours } from "../../../util/humaniseDurations";
import Bold from "../../Bold";
import LoadingContainer from "../../LoadingContainer";
import { TableHeaderButtons } from "../../NewTable/styles";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import { Panel } from "../../Panel";
import { DurationSpan, TemperatureSpan } from "../../ReportsScreen/styles";
import Table, { CsvButtonsComponent } from "../../Table";

const formatDataToCsv = (tableRef: any) => {
  const headers = [
    "State",
    "Kegs Total",
    "Kegs Updated Today",
    "Kegs In Known Places",
    "Kegs In Transit",
    `Average Temperatures (Breweries) (${printTempUnit()})`,
    `Average Temperatures (Warehouses) (${printTempUnit()})`,
    `Average Temperatures (Venues) (${printTempUnit()})`,
    "Average Durations (Breweries)",
    "Average Durations (Warehouses)",
    "Average Durations (Venues)",
  ];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [
        row.state,
        row.kegsTotal,
        row.kegsUpdatedToday,
        row.kegsInPlaces,
        row.kegsInTransit,
        printFixedTemp(row.avgTempBreweries),
        printFixedTemp(row.avgTempWarehouses),
        printFixedTemp(row.avgTempVenues),
        humaniseHours(row.avgDurationBreweries),
        humaniseHours(row.avgDurationWarehouses),
        humaniseHours(row.avgDurationVenues),
      ];
    }, []),
  ];
};

const KegFleetReport: FC<any> = () => {
  const { color } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchReport4(source)
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData([]);
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  const columns = [
    {
      columns: [
        {
          id: "state",
          Header: "State",
          accessor: "state",
          minWidth: 115,
        },
      ],
    },
    {
      Header: "Counts",
      columns: [
        {
          id: "kegsTotal",
          Header: "Total Kegs",
          accessor: "kegsTotal",
          Footer: ({ data }: any) => <Bold>Total: {data.reduce((a: any, d: any) => a + d.kegsTotal, 0)}</Bold>,
          minWidth: 130,
        },
        {
          id: "kegsUpdatedToday",
          Header: (
            <span>
              Updated
              <br />
              Today
            </span>
          ),
          accessor: "kegsUpdatedToday",
          Footer: ({ data }: any) => <Bold>Total: {data.reduce((a: any, d: any) => a + d.kegsUpdatedToday, 0)}</Bold>,
          minWidth: 130,
        },
        {
          id: "kegsInPlaces",
          Header: (
            <span>
              In Known
              <br />
              Places
            </span>
          ),
          accessor: "kegsInPlaces",
          Footer: ({ data }: any) => <Bold>Total: {data.reduce((a: any, d: any) => a + d.kegsInPlaces, 0)}</Bold>,
          minWidth: 130,
        },
        {
          id: "kegsInTransit",
          Header: "In Transit",
          accessor: "kegsInTransit",
          Footer: ({ data }: any) => <Bold>Total: {data.reduce((a: any, d: any) => a + d.kegsInTransit, 0)}</Bold>,
          minWidth: 130,
        },
      ],
    },
    {
      Header: "Average Temperatures",
      columns: [
        {
          id: "avgTempBreweries",
          Header: "Breweries",
          accessor: "avgTempBreweries",
          Cell: (props: any) => <TemperatureSpan temp={props.value} />,
          minWidth: 130,
        },
        {
          id: "avgTempWarehouses",
          Header: "Warehouses",
          accessor: "avgTempWarehouses",
          Cell: (props: any) => <TemperatureSpan temp={props.value} />,
          minWidth: 150,
        },
        {
          id: "avgTempVenues",
          Header: "Venues",
          accessor: "avgTempVenues",
          Cell: (props: any) => <TemperatureSpan temp={props.value} />,
          minWidth: 110,
        },
      ],
    },
    {
      Header: "Average Durations",
      columns: [
        {
          id: "avgDurationBreweries",
          Header: "Breweries",
          accessor: "avgDurationBreweries",
          Cell: (props: any) => <DurationSpan hours={props.value} />,
          minWidth: 160,
        },
        {
          id: "avgDurationWarehouses",
          Header: "Warehouses",
          accessor: "avgDurationWarehouses",
          Cell: (props: any) => <DurationSpan hours={props.value} />,
          minWidth: 160,
        },
        {
          id: "avgDurationVenues",
          Header: "Venues",
          accessor: "avgDurationVenues",
          Cell: (props: any) => <DurationSpan hours={props.value} />,
          minWidth: 160,
        },
      ],
    },
  ];

  const defaultSorted = [
    {
      id: "state",
      desc: false,
    },
  ];

  return (
    <>
      <PageBreadcrumbs prevRoutes={[{ slug: "/reports", title: "Reports" }]} currRoute="Keg Fleet Report" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading} err={dataErr}>
            <Panel>
              <TableHeaderButtons>
                <div style={{ display: "flex" }}></div>
                <CsvButtonsComponent data={data} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName="Keg Fleet Report.csv" />
              </TableHeaderButtons>
              <Table loading={dataLoading} style={{ clear: "both" }} data={data} columns={columns} defaultSorted={defaultSorted} ref={tableRef} />
            </Panel>
          </LoadingContainer>
        </div>
      </PageContainer>
    </>
  );
};

export default KegFleetReport;
