import { FC, useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { convertRoundedLength, toMeters } from "../../util/formatUnits";
import { INITIAL_VIEW_STATE } from "../../util/mapUtils";
import { LocationLoading, MapContainer } from "./styles";

declare global {
  interface Window {
    google: any;
  }
}

window.google = window.google || {};

const EditPlaceMap: FC<any> = ({ place, setPlace, autocompleteRef, error }) => {
  const { color, map_style } = useContext(ThemeContext);

  const googleMapRef = useRef(null);

  const [googleMap, setGoogleMap] = useState<any>(undefined);
  const [location, setLocation] = useState<any>(undefined);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);
  const [circle, setCircle] = useState<any>(undefined);

  useEffect(() => {
    if (place.latitude && place.longitude) {
      setLocation({
        lat: place.latitude,
        lng: place.longitude,
        zoom: 18,
      });
    } else {
      setLocationLoading(true);
      navigator.geolocation.getCurrentPosition(
        (pos: any) => {
          const { coords } = pos;
          setLocation({
            lat: coords.latitude,
            lng: coords.longitude,
            zoom: 16,
          });
          setLocationLoading(false);
        },
        () => {
          setLocation({
            lat: INITIAL_VIEW_STATE.latitude,
            lng: INITIAL_VIEW_STATE.longitude,
            zoom: 16,
          });
          setLocationLoading(false);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (location) {
      const newMap = new window.google.maps.Map(googleMapRef.current, {
        zoom: location.zoom,
        center: { lat: location.lat, lng: location.lng },
        disableDefaultUI: true,
        keyboardShortcuts: false,
        controlSize: 26,
        zoomControl: true,
        mapTypeControl: true,
        mapTypeId: "hybrid",
        fullscreenControl: true,
        styles: map_style,
      });
      setGoogleMap(newMap);
    }
  }, [location]);

  useEffect(() => {
    if (googleMap && !circle) {
      const newCircle = new window.google.maps.Circle({
        strokeColor: color.primary[2],
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: color.primary[2],
        fillOpacity: 0.25,
        map: googleMap,
        editable: true,
        center: { lat: location.lat, lng: location.lng },
        radius: toMeters(place.radius),
      });

      newCircle.addListener("center_changed", () => {
        const lat = newCircle.getCenter().lat();
        const lng = newCircle.getCenter().lng();
        geocodeLatLng({ lat, lng });
      });
      newCircle.addListener("radius_changed", () => {
        const radius = convertRoundedLength(Math.round(newCircle.getRadius()));
        setPlace((prev: any) => ({ ...prev, radius }));
      });

      setCircle(newCircle);

      // If editing a place, set the map bounds to the size of the radius (circle)
      if (place.latitude && place.longitude) {
        googleMap.fitBounds(newCircle.getBounds());
      }
    }
  }, [googleMap]);

  useEffect(() => {
    if (circle) {
      createAutocompleteBox();
      googleMap.addListener("click", (e: any) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        circle.setOptions({ center: { lat, lng } });
        geocodeLatLng({ lat, lng });
      });
    }
  }, [circle]);

  useEffect(() => {
    if (circle) {
      circle.setRadius(toMeters(place.radius));
      circle.setOptions({ center: { lat: +place.latitude, lng: +place.longitude } });
      googleMap.fitBounds(circle.getBounds());
    }
  }, [place.blur]);

  useEffect(() => {
    if (googleMap) {
      googleMap.setOptions({ styles: map_style });
    }
  }, [map_style]);

  const createAutocompleteBox = () => {
    if (autocompleteRef.current !== null) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        //@ts-ignore
        autocompleteRef.current
      );

      autocomplete.bindTo("bounds", googleMap);

      // Set the data fields to return when the user selects a place.
      autocomplete.setFields(["address_components", "geometry", "name", "opening_hours", "international_phone_number", "url", "website", "icon"]);

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        if (!place.geometry) {
          setPlace((prev: any) => ({
            ...prev,
            placeNotFound: true,
          }));
          return;
        }

        if (place.geometry.viewport) {
          // If viewport available, calculate the approximate radius
          const ne = place.geometry.viewport.getNorthEast();
          const radius = google.maps.geometry.spherical.computeDistanceBetween(place.geometry.location, ne);

          // Update circles center and radius and move map bounds
          circle.setOptions({ center: place.geometry.location, radius });
          googleMap.fitBounds(place.geometry.viewport);
        } else {
          googleMap.fitBounds(circle.getBounds());
          circle.setOptions({ center: place.geometry.location });
        }

        const addressComponents = formatAddress(place);

        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();

        setPlace((prev: any) => ({
          ...prev,
          name: place.name ? place.name : "",
          address: addressComponents.address,
          city: addressComponents.city,
          state: addressComponents.state,
          postcode: addressComponents.postcode,
          country: addressComponents.country,
          latitude,
          longitude,
          openHours: place.opening_hours ? place.opening_hours.weekday_text.join("\r\n") : "",
          phoneNumber: place.international_phone_number ? place.international_phone_number : "",
          website: place.website ? place.website : place.url ? place.url : "",
          placeNotFound: false,
          imageUrl: place.icon ? place.icon : "",
        }));
      });
    }
  };

  const geocodeLatLng = ({ lat, lng }: any) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results: any, status: any) => {
      if (status === "OK") {
        if (results[0]) {
          const addressComponents = formatAddress(results[0]);
          setPlace((prev: any) => ({
            ...prev,
            address: addressComponents.address,
            city: addressComponents.city,
            state: addressComponents.state,
            postcode: addressComponents.postcode,
            country: addressComponents.country,
            latitude: lat,
            longitude: lng,
          }));
        } else {
          setPlace((prev: any) => ({
            ...prev,
            latitude: lat,
            longitude: lng,
          }));
        }
      } else {
        setPlace((prev: any) => ({ ...prev, latitude: lat, longitude: lng }));
      }
    });
  };

  const formatAddress = (place: any) => {
    const componentProps: any = {
      street_number: true,
      route: true,
      locality: true,
      administrative_area_level_1: true,
      postal_code: true,
      country: true,
    };

    const components: any = {
      street_number: undefined,
      route: undefined,
      locality: undefined,
      administrative_area_level_1: undefined,
      postal_code: undefined,
      country: undefined,
    };

    // Get each component of the address from the place details
    // and then fill-in the corresponding field on the form.
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentProps[addressType]) {
        components[addressType] = place.address_components[i].long_name;
      }
    }

    const address = `${(components.street_number && components.street_number) || ""} ${(components.route && components.route) || ""}`.trim();

    const city = `${(components.locality && components.locality) || ""}`;
    const state = `${(components.administrative_area_level_1 && components.administrative_area_level_1) || ""}`;
    const postcode = `${(components.postal_code && components.postal_code) || ""}`;
    const country = `${(components.country && components.country) || ""}`;

    return {
      address,
      city,
      state,
      postcode,
      country,
    };
  };

  return (
    <div style={{ position: "relative" }}>
      <MapContainer id="create-place-google-map" ref={googleMapRef} error={error} />
      {locationLoading && <LocationLoading>Loading Location...</LocationLoading>}
    </div>
  );
};

export default EditPlaceMap;
