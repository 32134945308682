import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { ThemeContext } from "styled-components";
import { printTempUnit } from "../../util/formatUnits";
import { OutlineBtn } from "../Buttons";
import FlatpickrPicker from "../FlatpickrPicker";
import { FormError, FormInput, InputUnitWrapper } from "../FormComponents";
import { ClearIconBtn } from "../IconButtons";
import { PlainTemperatureSpan } from "../ReportsScreen/styles";
import Table from "../Table";
import Tooltip from "../Tooltip";

const DateTemperaturePairManager: FC<any> = ({ pairs = [], setPairs, minDate, maxDate, error }) => {
  const { color, short_datetime } = useContext(ThemeContext);

  const [date, setDate] = useState<any>(undefined);
  const [temp, setTemp] = useState<any>("");

  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    if (date != null) {
      setErrors((prev: any) => ({ ...prev, date: "" }));
    }
  }, [date]);

  const handleAddPair = () => {
    if (date == null) {
      setErrors((prev: any) => ({ ...prev, date: "Please select a date" }));
    } else {
      setErrors((prev: any) => ({ ...prev, date: "" }));
    }

    if (temp == "") {
      setErrors((prev: any) => ({ ...prev, temp: "Please enter a temperature" }));
    } else {
      setErrors((prev: any) => ({ ...prev, temp: "" }));
    }

    if (date != null && pairs.some((pair: any) => pair.date && pair.date.valueOf() === date.valueOf() / 1000)) {
      setErrors((prev: any) => ({ ...prev, date: "Date already exists in list" }));
    }

    // If valid date, temp, and date is not already in list then add pair
    if (date != null && temp != "" && !pairs.some((pair: any) => pair.date && pair.date.valueOf() === date.valueOf() / 1000)) {
      setPairs([...pairs, { date: date.valueOf() / 1000, temp }]);
      setTemp("");
    }
  };

  const handleRemovePair = ({ date, temp }: any) => {
    setPairs((prev: any) => {
      const index = prev.findIndex((item: any) => item.date === date && item.temp === temp);

      if (index !== -1) {
        const newLogs = [...prev];
        newLogs.splice(index, 1);
        return newLogs;
      }

      return prev;
    });
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setTemp(e.target.value);
    setErrors((prev: any) => ({ ...prev, temp: "" }));
  };

  const columns = React.useMemo<any>(
    () => [
      {
        id: "date",
        Header: "Date",
        accessor: "date",
        Cell: (props: any) => moment.unix(props.value).format(short_datetime),
      },
      {
        id: "temp",
        Header: "Temperature",
        accessor: "temp",
        Cell: (props: any) => <PlainTemperatureSpan temp={props.value} />,
      },
      {
        id: "actions",
        Header: "",
        Cell: (props: any) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Tooltip content="Remove">
              <ClearIconBtn onClick={() => handleRemovePair(props.original)} color={color.danger[2]} hoverColor={color.danger[1]} size="20px">
                <IconContext.Provider value={{ color: color.danger[2], size: "20px" }}>
                  <IoClose />
                </IconContext.Provider>
              </ClearIconBtn>
            </Tooltip>
          </div>
        ),
        minWidth: 50,
        maxWidth: 50,
      },
    ],
    []
  );

  const defaultSorted = [
    {
      id: "date",
      desc: false,
    },
  ];

  return (
    <>
      <div style={{ display: "flex", gap: "6px" }}>
        <FlatpickrPicker
          style={{ flex: "1 1 70%" }}
          enableTime={true}
          value={date}
          onChange={(d: any) => setDate(d[0])}
          onClose={(d: any) => setDate(d[0])}
          onClear={() => setDate(undefined)}
          placeholder="Select Date/Time"
          error={errors.date}
          options={{ minDate, maxDate: maxDate ?? Date.now() }}
        />
        <InputUnitWrapper style={{ flex: "1 1 30%" }} unit={printTempUnit()}>
          <FormInput type="number" name="temp" value={temp} onChange={handleChange} required={true} placeholder="50" error={errors.temp} />
        </InputUnitWrapper>
        <Tooltip content="Add">
          <OutlineBtn style={{ minWidth: "34px", alignItems: "center", display: "flex" }} width="34px" padding="0 8px" onClick={handleAddPair}>
            <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
              <HiPlus />
            </IconContext.Provider>
          </OutlineBtn>
        </Tooltip>
      </div>
      <FormError error={errors.date || errors.temp}>{errors.date || errors.temp}</FormError>
      <Table style={{ marginTop: "12px" }} columns={columns} data={pairs} defaultSorted={defaultSorted} error={error} />
      <FormError error={error}>{error}</FormError>
    </>
  );
};

export default DateTemperaturePairManager;
