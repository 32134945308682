const downloadFile = (text: any, fileType: string, fileName: string) => {
  const blob = new Blob(["\uFEFF" + text], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  a.style.display = "none";
  a.onclick = function (e: any) {
    document.body.removeChild(e.target);
  };
  document.body.appendChild(a);
  a.click();
};

export default downloadFile;
