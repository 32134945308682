import axios from "axios";
import moment from "moment";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { ThemeContext } from "styled-components";
import { fetchReport8 } from "../../../services/report8";
import errToStr from "../../../util/errToStr";
import { printFixedTemp, printTempUnit } from "../../../util/formatUnits";
import { googleMapsAddressLink } from "../../../util/googleMapsLink";
import { kegOrTracker, kegsOrTrackers } from "../../../util/kegOrTracker";
import Bold from "../../Bold";
import FlatpickrRangePicker from "../../FlatpickrRangePicker";
import LoadingContainer from "../../LoadingContainer";
import { TableHeaderButtons } from "../../NewTable/styles";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import { Panel } from "../../Panel";
import { TemperatureSpan } from "../../ReportsScreen/styles";
import Table, { CsvButtonsComponent } from "../../Table";

const formatDataToCsv = (tableRef: any) => {
  const headers = ["Tracker ID", "Last Seen", `Temperature (${printTempUnit()})`, "Address"];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [row.sensorId, moment.unix(row.lastSeen).format(), printFixedTemp(row.temperature), row.address];
    }, []),
  ];
};

const StationaryNotAtPlace: FC<any> = () => {
  const { long_datetime, short_date } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchReport8(source)
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData([]);
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  const columns = [
    {
      id: "sensorId",
      Header: "Tracker ID",
      accessor: "sensorId",
      Cell: (props: any) => {
        const url = kegOrTracker("kegs", "trackers");
        let title = props.value ? props.value : "";
        title += `${props.original.sensorName ? " (" + props.original.sensorName + ")" : ""}`;

        return (
          <Link to={`/${url}/${props.value}`} title={title}>
            {title}
          </Link>
        );
      },
      Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
      minWidth: 130,
    },
    {
      id: "lastSeen",
      Header: "Last Seen",
      accessor: "lastSeen",
      filterMethod: (filter: any, row: any) => {
        if (filter.value.length === 2) {
          if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
          else return false;
        } else return true;
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
      ),
      Cell: (props: any) => <ReactTimeago live={false} date={props.value * 1000} title={moment.unix(props.value).format(long_datetime)} />,
      minWidth: 150,
    },
    {
      id: "temperature",
      Header: "Temperature",
      accessor: "temperature",
      Cell: (props: any) => <TemperatureSpan temp={props.value} />,
      minWidth: 160,
    },
    {
      id: "address",
      Header: "Address",
      accessor: "address",
      Cell: (props: any) => googleMapsAddressLink(props.value),
      minWidth: 310,
    },
  ];

  const defaultSorted = [
    {
      id: "lastSeen",
      desc: true,
    },
  ];

  return (
    <>
      <PageBreadcrumbs prevRoutes={[{ slug: "/reports", title: "Reports" }]} currRoute={`Stationary ${kegsOrTrackers("Kegs", "Trackers")} not at a Place`} />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading} err={dataErr}>
            <Panel>
              <TableHeaderButtons>
                <div style={{ display: "flex" }}></div>
                <CsvButtonsComponent
                  data={data}
                  tableRef={tableRef}
                  formatCsv={formatDataToCsv}
                  fileName={`Stationary ${kegsOrTrackers("Kegs", "Trackers")} not at a Place.csv`}
                />
              </TableHeaderButtons>
              <Table
                loading={dataLoading}
                filterable={true}
                style={{ clear: "both" }}
                data={data}
                columns={columns}
                defaultSorted={defaultSorted}
                ref={tableRef}
              />
            </Panel>
          </LoadingContainer>
        </div>
      </PageContainer>
    </>
  );
};

export default StationaryNotAtPlace;
