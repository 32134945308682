import styled from "../Themes";

export const Section = styled("section")`
  width: 100%;
  height: 100%;
  padding: 64px;

  display: flex;
  align-items: center;
  flex-direction: column;

  text-align: center;

  background-image: radial-gradient(circle at top center, rgba(0, 0, 0, 0) 50%, ${({ theme }) => theme.color_50.sign_in_bg[2]} 75%);

  @media (max-width: 768px) {
    padding: 48px;
  }

  @media (max-width: 576px) {
    padding: 32px;
  }
`;

export const LogoContainer = styled("div")`
  width: 100%;
  max-width: 300px;
  margin-bottom: 64px;
`;

export const Heading = styled(`span`)`
  font-size: 48px;
  font-weight: 500;
  margin-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 36px;
  }

  @media (max-width: 576px) {
    font-size: 28px;
  }
`;

export const SubHeading = styled(`span`)`
  font-size: 15px;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;
