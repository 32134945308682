import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const fetchBatteryEstimate = async (source: CancelTokenSource, sensorId: string, sleepCount?: number, sleepDuration?: number) => {
  const params = {
    organisationId: getAccount().organisationId,
    sleepCount,
    sleepDuration,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/batteryestimate/" + sensorId,
    params,
  });

  return data;
};
