import styled from "../Themes";

export const MoreIconContainer = styled(`div`)`
  display: inline-block;
  cursor: pointer;
  padding: 3px 6px;
  border-radius: 3px;
  transition: background-color 200ms;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.color.more_hover[2]};
  }
`;

export const MoreIconSize = styled(`div`)`
  width: 17px;
  height: 17px;
`;
