import queryString from "query-string";
import axios, { CancelTokenSource } from "axios";
import { fetchSensorExists } from "../services/sensorExists";

const getNextRoute = (source: CancelTokenSource, location: any, setNextRoute: any) => {
  const queryParams = queryString.parse(location.search);
  let nextRoute: any = "/";

  if (queryParams.path) {
    const { path, ...rest } = queryParams;

    nextRoute = path;

    const pathComponents = path.toString().split("/");

    const stringified = queryString.stringify(rest);

    // Checks if redirecting to a sensor page based on the "path" query parameter
    if (pathComponents.length === 3) {
      // If redirecting to a keg/tracker page, check the sensor belongs to the user
      if (pathComponents[1] === "kegs" || pathComponents[1] === "trackers") {
        fetchSensorExists(source, pathComponents[2])
          .then(() => {
            // If the sensor belongs to the user continue with the redirect
            if (stringified) nextRoute += `?${stringified}`;
            setNextRoute(nextRoute);
          })
          .catch((err) => {
            // If the sensor DOESN'T belong to the user redirect to the home page
            if (!axios.isCancel(err)) {
              nextRoute = "/";
              if (stringified) nextRoute += `?${stringified}`;
              setNextRoute(nextRoute);
            }
          });
      } else {
        // Not redirecting to sensor page so continue with redirect as normal
        if (stringified) nextRoute += `?${stringified}`;
        setNextRoute(nextRoute);
      }
    } else {
      // Not redirecting to sensor page so continue with redirect as normal
      if (stringified) nextRoute += `?${stringified}`;
      setNextRoute(nextRoute);
    }
  } else {
    // No path query parameter so redirect to home page
    setNextRoute(nextRoute);
  }
};

export default getNextRoute;
