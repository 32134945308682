import styled from "../Themes";

export const OrgCard = styled(`div`)`
  border: 1px solid ${({ theme }) => theme.color.border[4]};
  display: flex;
  padding: 12px;
  margin: 6px 0;
  cursor: pointer;
  place-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.color.tooltip_hover[2]};
  }
`;

export const OrgAvatar = styled(`div`)`
  margin: 4px 24px 4px 4px;
`;

export const OrgDetails = styled(`div`)`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: start;
`;

export const OrgName = styled(`span`)`
  color: ${({ theme }) => theme.color.font_bold[2]};
  font-weight: 500;
  margin-bottom: 6px;
  text-align: left;
`;

export const OrgInfoHeading = styled(`span`)`
  white-space: nowrap;
`;

export const OrgInfoCount = styled(`span`)`
  color: ${({ theme }) => theme.color.font_bold[2]};
  font-weight: 500;
  margin-right: 32px;
`;

export const OrgInfoContainer = styled(`div`)`
  display: flex;
  align-items: start;
  flex-flow: row wrap;
`;

export const OrgRoleHeading = styled(`span`)`
  white-space: nowrap;
  margin-bottom: 6px;
`;

export const FilterContainer = styled(`div`)`
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
`;
