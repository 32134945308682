import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import QrScanner from "qr-scanner";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { createPickupRequest } from "../../../services/pickupRequests";
import SuccessIcon from "../../../svgs/SuccessIcon";
import errToStr from "../../../util/errToStr";
import { exists, maxLength } from "../../../util/formValidations";
import { kegsOrTrackers } from "../../../util/kegOrTracker";
import Bold from "../../Bold";
import { PrimaryBtn } from "../../Buttons";
import { FormContainer, FormError, FormInput, FormInputContainer, FormTextareaInput } from "../../FormComponents";
import LoadingContainer from "../../LoadingContainer";
import { InfoModal, SubmitModal } from "../../Modal";
import { ModalIconContainer, ModalSuccess } from "../../Modal/styles";
import { TableHeaderButtons } from "../../NewTable/styles";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import Table, { CsvButtonsComponent } from "../../Table";
import { AsyncCreatableSelect } from "../../Select";
import { isBinaryLoop } from "../../../util/checkDomain";
import { fetchAutoComplete } from "../../../services/autoComplete";

const defaultRequest = {
  trackerId: "",
  pickupRequestType: null,
  notes: "",
};

const formatDataToCsv = (tableRef: any, short_datetime: string) => {
  const headers = ["Date", "Tracker ID"];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [moment.unix(row.date).format(short_datetime), row.trackerId];
    }, []),
  ];
};

const CreatePickupRequest: FC<any> = () => {
  const { color, short_datetime } = useContext(ThemeContext);

  const tableRef = useRef<any>(undefined);

  const videoRefCallback = useCallback((node: any) => {
    if (node) {
      setVideoRef(node);
    }
  }, []);

  const [videoRef, setVideoRef] = useState<any>(undefined);

  const [formData, setFormData] = useState<any>(defaultRequest);
  const [formErrors, setFormErrors] = useState<any>({});

  const [requestedSensors, setRequestedSensors] = useState<any>([]);

  const [scanResponseMsg, setResponseMsg] = useState<string>("");
  const [scanResponseErr, setResponseErr] = useState<string>("");
  const [scanResponseLoading, setResponseLoading] = useState<boolean>(false);

  const [requestModalOpen, setRequestModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Initialise codeReader and stop/destroy codeReader and stop camera access on clean up
  useEffect(() => {
    let codeReader: any = undefined;
    if (videoRef) {
      codeReader = new QrScanner(videoRef, (result) => {
        if (result) {
          let code = result;
          if (code.includes("10b.in/")) {
            code = code.split("10b.in/")[1];
          }
          setFormData((prev: any) => ({ ...prev, trackerId: code }));
          setFormErrors((prev: any) => ({
            ...prev,
            trackerId: undefined,
          }));
          setResponseMsg("");
          setResponseErr("");
          setRequestModalOpen(true);
        }
      });
      codeReader.setInversionMode("both");
      codeReader.start();
    }

    return () => {
      if (codeReader) {
        codeReader.stop();
        codeReader.destroy();
      }
      if (videoRef && videoRef.srcObject) {
        videoRef.srcObject.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
    };
  }, [videoRef]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "trackerId":
          currValid = exists(name, value, setFormErrors);
          break;

        case "notes":
          currValid = maxLength(name, value, 500, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const formattedData: any = {
      trackerId: formData.trackerId,
      pickupRequestTypeName: formData.pickupRequestType?.label,
      notes: formData.notes.trim(),
    };

    return formattedData;
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setResponseLoading(true);
      createPickupRequest(source, body)
        .then(() => {
          // On successful post, add scan to local table (or modify scan if already present)
          setResponseMsg("Pickup Request Created");
          setResponseErr("");
          setRequestedSensors((prev: any) => [
            ...prev.filter((prevScan: any) => {
              if (prevScan.trackerId === formData.trackerId) {
                return false;
              }
              return true;
            }, []),
            { date: Math.floor(Date.now() / 1000), trackerId: formData.trackerId },
          ]);
          setResponseLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setResponseErr(errToStr(err));
            setResponseLoading(false);
          }
        });
    }
  };

  const handleManualSubmit = () => {
    if (validateForm()) {
      setResponseMsg("");
      setResponseErr("");
      setRequestModalOpen(true);
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setFormErrors((prev: any) => ({
      ...prev,
      [e.target.name]: undefined,
    }));
  };

  const columns = [
    {
      id: "date",
      Header: "Date",
      accessor: "date",
      Cell: (props: any) => moment.unix(props.value).format(short_datetime),
      minWidth: 160,
    },
    {
      id: "trackerId",
      Header: "Tracker ID",
      accessor: "trackerId",
      minWidth: 120,
      Cell: (props: any) => {
        return (
          <Link title={props.value} to={`/${kegsOrTrackers("kegs", "trackers")}/${props.value}`}>
            {props.value}
          </Link>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      id: "date",
      desc: true,
    },
  ];

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  // Auto-populates select input on search.
  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  return (
    <>
      <PageBreadcrumbs prevRoutes={[{ slug: "/scanner", title: "Scanner" }]} currRoute="Create Pickup Request" />
      <PageContainer top="40px">
        <FormContainer>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <div>
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <label>Scan {kegsOrTrackers("Kegs", "Trackers")}</label>
                <div>
                  <video
                    id="video"
                    ref={videoRefCallback}
                    style={{
                      display: "inline-block",
                      width: "100%",
                      border: "1px solid gray",
                      borderRadius: "3px",
                    }}
                  />
                </div>
              </FormInputContainer>
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <label>Tracker ID</label>
                <FormInput
                  type="text"
                  name="trackerId"
                  value={formData.trackerId}
                  error={formErrors.trackerId}
                  onChange={handleChange}
                  style={{ height: "40px" }}
                />
                <FormError error={formErrors.trackerId}>{formErrors.trackerId}</FormError>
              </FormInputContainer>
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <label>{isBinaryLoop() ? "Contents Type" : "Pickup Request Type"}</label>
                <AsyncCreatableSelect
                  name="pickupRequestType"
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.pickupRequestType}
                  value={formData.pickupRequestType}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("pickuprequesttypes", inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                />
                <FormError error={formErrors.pickupRequestType}>{formErrors.pickupRequestType}</FormError>
              </FormInputContainer>
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <label>Notes</label>
                <FormTextareaInput name="notes" rows={6} maxLength={4096} value={formData.notes} error={formErrors.notes} onChange={handleChange} />
                <FormError error={formErrors.notes}>{formErrors.notes}</FormError>
              </FormInputContainer>
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <PrimaryBtn width="100%" onClick={handleManualSubmit}>
                  Create Pickup Request
                </PrimaryBtn>
              </FormInputContainer>
              <div
                style={{
                  paddingTop: "16px",
                  width: "100%",
                  float: "left",
                  display: requestedSensors.length > 0 ? "block" : "none",
                }}
              >
                <TableHeaderButtons>
                  <div style={{ display: "flex" }}></div>
                  <CsvButtonsComponent
                    data={requestedSensors}
                    formatCsv={formatDataToCsv}
                    formatCsvParams={[tableRef, short_datetime]}
                    fileName="Created Pickup Requests.csv"
                  />
                </TableHeaderButtons>
                <Table style={{ clear: "both" }} data={requestedSensors} columns={columns} defaultSorted={defaultSorted} ref={tableRef} />
              </div>
            </div>
            {scanResponseMsg ? (
              <InfoModal
                isOpen={requestModalOpen}
                onClose={() => {
                  setResponseMsg("");
                  setResponseErr("");
                  setRequestModalOpen(false);
                }}
                title="Create Pickup Request"
                okayBtnText="Okay"
                body={
                  <ModalSuccess>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <ModalIconContainer>
                        <SuccessIcon fill={color.success[2]} />
                      </ModalIconContainer>
                      <div>{scanResponseMsg}</div>
                    </div>
                  </ModalSuccess>
                }
              />
            ) : (
              <SubmitModal
                isOpen={requestModalOpen}
                setRequestModalOpen
                onSubmit={() => handleSubmit()}
                onClose={() => {
                  setResponseMsg("");
                  setResponseErr("");
                  setRequestModalOpen(false);
                }}
                title="Create Pickup Request"
                success={scanResponseMsg}
                error={scanResponseErr}
                body={
                  <LoadingContainer loading={scanResponseLoading}>
                    <p>
                      Tracker ID: <Bold>{formData.trackerId}</Bold>
                    </p>
                    {formErrors.trackerId && <FormError error={formErrors.trackerId}>{formErrors.trackerId}</FormError>}
                    {formData.pickupRequestType && (
                      <p>
                        {isBinaryLoop() ? "Contents Type" : "Pickup Request Type"}: <Bold>{formData.pickupRequestType?.label}</Bold>
                      </p>
                    )}
                    {formData.notes && (
                      <p style={{ overflowWrap: "break-word" }}>
                        Notes: <Bold>{formData.notes}</Bold>
                      </p>
                    )}
                    {formErrors.notes && <FormError error={formErrors.notes}>{formErrors.notes}</FormError>}
                  </LoadingContainer>
                }
              />
            )}
          </form>
        </FormContainer>
      </PageContainer>
    </>
  );
};

export default CreatePickupRequest;
