import React, { FC } from "react";
import { PageContainer } from "../PageStyles";
import OrganisationsTable from "../OrganisationsTable";
import PageTitle from "../PageTitle";
import { Panel } from "../Panel";

const OrganisationsScreen: FC<any> = () => {
  return (
    <>
      <PageTitle title="Organisations" />
      <PageContainer>
        <Panel>
          <OrganisationsTable />
        </Panel>
      </PageContainer>
    </>
  );
};

export default OrganisationsScreen;
