import axios from "axios";
import moment from "moment";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { ThemeContext } from "styled-components";
import { fetchInvite } from "../../services/invite";
import MoreIcon from "../../svgs/MoreIcon";
import { isAdminOrUser } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import { PrimaryBtn } from "../Buttons";
import CancelInviteModal from "../CancelInviteModal";
import CreateInviteModal from "../CreateInviteModal";
import FlatpickrRangePicker from "../FlatpickrRangePicker";
import { PrimaryIconBtn } from "../IconButtons";
import LoadingContainer from "../LoadingContainer";
import { DesktopDiv, MobileDiv, TableHeaderButtons } from "../NewTable/styles";
import Table, { CsvButtonsComponent } from "../Table";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

const InvitesTable: FC<any> = () => {
  const { color, short_datetime, short_date } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<any>({});
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);

  const tableRef = useRef<any>(null);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchInvite(source)
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  const formatDataToCsv = (tableRef: any) => {
    const headers = ["Sent To", "Accepted Date", "Invited By", "Invited Date", "Organisation", "Role"];

    const data = tableRef.current.getResolvedState().sortedData;

    return [
      headers,
      ...data.map((row: any) => {
        const dataRows = [
          row.sentTo,
          row.acceptedByDate ? moment.unix(row.acceptedByDate).format() : "",
          row.invitedByEmail,
          row.invitedDate ? moment.unix(row.invitedDate).format() : "",
          row.organisationName,
          row.role,
        ];

        return dataRows;
      }, []),
    ];
  };

  const columns: any = [];

  columns.push({
    Header: "Actions",
    minWidth: 110,
    maxWidth: 110,
    filterable: false,
    sortable: false,
    Cell: ({ original }: any) => {
      return (
        <Tooltip
          maxWidth="none"
          theme="binary-no-padding"
          content={
            <MenuList>
              {!original.acceptedByDate ? (
                <DangerMenuButton
                  onClick={() => {
                    setSelectedRow(original);
                    setCancelModalOpen(true);
                  }}
                >
                  Cancel Invite
                </DangerMenuButton>
              ) : (
                <Tooltip trigger="mouseenter" content="You cannot cancel an already accepted invite">
                  <div
                    style={{
                      cursor: "not-allowed",
                      userSelect: "none",
                    }}
                  >
                    <MenuButton disabled={true}>Cancel Invite</MenuButton>
                  </div>
                </Tooltip>
              )}
            </MenuList>
          }
          interactive={true}
          touch={true}
          appendTo={document.body}
          trigger="click"
          placement="bottom-start"
        >
          <MoreIconContainer>
            <MoreIconSize>
              <MoreIcon fill={color.font[2]} />
            </MoreIconSize>
          </MoreIconContainer>
        </Tooltip>
      );
    },
  });

  columns.push({
    id: "sentTo",
    Header: "Sent To",
    accessor: "sentTo",
    minWidth: 260,
    Cell: (props: any) => <span title={props.value}>{props.value}</span>,
  });

  columns.push({
    id: "acceptedByDate",
    Header: "Accepted Date",
    accessor: "acceptedByDate",
    minWidth: 170,
    maxWidth: 170,
    filterMethod: (filter: any, row: any) => {
      if (filter.value.length === 2) {
        if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
        else return false;
      } else return true;
    },
    Filter: ({ onChange }: any) => (
      <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
    ),
    Cell: (props: any) => (props.value ? moment.unix(props.value).format(short_datetime) : ""),
  });

  columns.push({
    id: "invitedByEmail",
    Header: "Invited By",
    accessor: "invitedByEmail",
    minWidth: 260,
    Cell: (props: any) => <span title={props.value}>{props.value}</span>,
  });

  columns.push({
    id: "invitedDate",
    Header: "Invited Date",
    accessor: "invitedDate",
    minWidth: 170,
    maxWidth: 170,
    filterMethod: (filter: any, row: any) => {
      if (filter.value.length === 2) {
        if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
        else return false;
      } else return true;
    },
    Filter: ({ onChange }: any) => (
      <FlatpickrRangePicker
        options={{
          mode: "range",
          formatDate: (d: any) => moment(d).format(short_date),
        }}
        onClose={(d: any) => onChange(d)}
      />
    ),
    Cell: (props: any) => (props.value ? moment.unix(props.value).format(short_datetime) : ""),
  });

  columns.push({
    id: "organisationName",
    Header: "Organisation",
    accessor: "organisationName",
    minWidth: 200,
    maxWidth: 200,
    Cell: (props: any) => <span title={props.value}>{props.value}</span>,
  });

  columns.push({
    id: "role",
    Header: "Role",
    accessor: "role",
    minWidth: 120,
    maxWidth: 120,
    Cell: (props: any) => <span title={props.value}>{props.value}</span>,
  });

  const defaultSorted = [
    {
      id: "invitedDate",
      desc: true,
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <LoadingContainer loading={dataLoading} err={dataErr}>
        <TableHeaderButtons>
          <div style={{ display: "flex" }}>
            {isAdminOrUser() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setCreateModalOpen(true);
                    }}
                  >
                    Create
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <PrimaryIconBtn
                      onClick={() => {
                        setCreateModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryBtn disabled={true}>Create</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryIconBtn>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
          <CsvButtonsComponent data={data} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName="Invite List.csv" />
        </TableHeaderButtons>
        <Table
          loading={dataLoading}
          filterable={true}
          style={{ clear: "both" }}
          data={data}
          columns={columns}
          defaultSorted={defaultSorted}
          ref={tableRef}
          defaultPageSize={15}
        />
      </LoadingContainer>
      {cancelModalOpen && (
        <CancelInviteModal
          setData={setData}
          modalOpen={cancelModalOpen}
          setModalOpen={setCancelModalOpen}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
        />
      )}
      {createModalOpen && <CreateInviteModal setData={setData} modalOpen={createModalOpen} setModalOpen={setCreateModalOpen} />}
    </div>
  );
};

export default InvitesTable;
