import react from "react";
import styled from "styled-components";

const Screen = styled<any>(`div`)`
  display: ${({ isError }) => (isError ? "block" : "none")};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(2px);
`;

const ErrorScreen = ({ children, error }: any) => {
  return (
    <Screen isError={error}>
      <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>{children}</div>
    </Screen>
  );
};

export default ErrorScreen;
