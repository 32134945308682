import React, { FC, useState, useEffect } from "react";
import Checkbox from "../Checkbox";
import { ModalFilterContainer, ModalFilterHeader } from "./styles";
import { kegsOrTrackers } from "../../util/kegOrTracker";

// Sets the initial values of the checkboxes if there are URL query parameters.
// If there are none we use the default initial values
const getValues = (sensorFilters: any, placeFilters: any) => {
  const { hidePlaces } = placeFilters;
  const { hideAtPlaces, hideInTransit } = sensorFilters;

  const markers: any = {
    hidePlaces: false,
    hideAtPlaces: false,
    hideInTransit: false,
  };

  if (hidePlaces) {
    markers.hidePlaces = true;
  }

  if (hideAtPlaces) {
    markers.hideAtPlaces = true;
  }

  if (hideInTransit) {
    markers.hideInTransit = true;
  }

  return markers;
};

const HideMarkers: FC<any> = ({ sensorFilters, placeFilters, setModalQuery }) => {
  const [values, setValues] = useState<any>(getValues(sensorFilters, placeFilters));

  // This is to update the values and button state when a user changes the query
  // parameters (e.g. when a user navigates using the back/forward buttons).
  // Each time the sensorFilters change (whenever the sensor query parameters change),
  // if the new values don't match the current values, update the values and button state
  useEffect(() => {
    const newValues = getValues(sensorFilters, placeFilters);
    if (JSON.stringify(values) !== JSON.stringify(newValues)) {
      setValues(newValues);
    }
  }, [sensorFilters, placeFilters]);

  // when a checkbox changes, updates the modal
  // query string state in the parent component
  useEffect(() => {
    updateModalQuery(values);
  }, [values]);

  const updateModalQuery = (values: any) => {
    setModalQuery((prev: any) => ({
      ...prev,
      hidePlaces: values.hidePlaces ? true : undefined,
      hideAtPlaces: values.hideAtPlaces ? true : undefined,
      hideInTransit: values.hideInTransit ? true : undefined,
    }));
  };

  return (
    <ModalFilterContainer>
      <ModalFilterHeader>Hide Markers</ModalFilterHeader>
      <Checkbox
        style={{
          display: "block",
          padding: "6px",
          textAlign: "left",
        }}
        name="hidePlaces"
        label="Places"
        checked={values.hidePlaces}
        onChange={(e: any) => {
          e.persist();
          setValues((prev: any) => ({
            ...prev,
            [e.target.name]: e.target.checked,
          }));
        }}
      />
      <Checkbox
        style={{
          display: "block",
          padding: "6px",
          textAlign: "left",
        }}
        name="hideAtPlaces"
        label={kegsOrTrackers("Kegs at places", "Trackers at places")}
        checked={values.hideAtPlaces}
        onChange={(e: any) => {
          e.persist();
          setValues((prev: any) => ({
            ...prev,
            [e.target.name]: e.target.checked,
          }));
        }}
      />
      <Checkbox
        style={{
          display: "block",
          padding: "6px",
          textAlign: "left",
        }}
        name="hideInTransit"
        label={kegsOrTrackers("Kegs in transit", "Trackers in transit")}
        checked={values.hideInTransit}
        onChange={(e: any) => {
          e.persist();
          setValues((prev: any) => ({
            ...prev,
            [e.target.name]: e.target.checked,
          }));
        }}
      />
    </ModalFilterContainer>
  );
};

export default HideMarkers;
