import styled from "../Themes";

export const UpdateImageButton = styled(`div`)`
  position: absolute;
  bottom: -10px;
  right: -10px;
  background: ${({ theme }) => theme.color.modal_background[2]};
  fill: ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 50%;
  padding: 5px;
`;

export const UpdateImageButtonContainer = styled(`div`)`
  border: 1px solid ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;

  &:hover {
    fill: ${({ theme }) => theme.color.primary[2]};
    border: 1px solid ${({ theme }) => theme.color.primary[2]};
  }
`;

export const CameraIconContainer = styled(`div`)`
  width: 16px;
  height: 16px;
  margin-top: -1px;
`;
