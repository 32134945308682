import React, { FC, useState, useEffect, useContext } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { FormError, FormInput } from "../FormComponents";
import { exists, validName, maxLength } from "../../util/formValidations";
import { ModalFormContainer } from "../Modal/styles";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import ColorPicker from "../ColorPicker";
import { addPlaceTag, editPlaceTag } from "../../services/placeTags";
import { ThemeContext } from "styled-components";
import { fetchPlaces } from "../../services/editPlace";
import { getAccount } from "../../services/localStorage";
import matchSorter from "match-sorter";
import { Link } from "react-router-dom";
import sortTags from "../../util/sortTags";
import Bold from "../Bold";
import Checkbox from "../Checkbox";
import Tag from "../Tag";
import Table from "../Table";

const initTag = (tag: any, echarts_palette: any) => {
  return {
    id: null,
    name: "",
    colour: echarts_palette[Math.floor(Math.random() * echarts_palette.length)],
    description: "",
    placeCount: 0,
    organisationId: "",
    organisationName: "",
    ...tag,
  };
};

const EditPlaceTagModal: FC<any> = ({ tag, fetch, modalOpen, setModalOpen }) => {
  const { echarts_palette } = useContext(ThemeContext);

  const [formData, setFormData] = useState<any>(initTag(tag, echarts_palette));
  const [formErrors, setFormErrors] = useState<any>({});

  const [places, setPlaces] = useState<any>([]);
  const [placesErr, setPlacesErr] = useState<string>("");
  const [placesLoading, setPlacesLoading] = useState<boolean>(false);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [placeChanges, setPlaceChanges] = useState<any>({});

  const [filterOptions, setFilterOptions] = useState<any>({
    placeTags: [],
    placeTypes: [],
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setPlacesLoading(true);
    fetchPlaces(source, { filters: `organisationId eq ${getAccount().organisationId}` })
      .then(({ data }) => {
        // Sets selected and initSelected property to indicate if places have the current tag or not and to sort the rows
        // by initSelected to prevent the rows bouncing around when editing
        setPlaces(
          data.map((place: any) => {
            const selected = place.placeTags.some((tag: any) => formData.id === tag.id);
            return {
              ...place,
              selected: selected,
              initSelected: selected,
            };
          })
        );
        setPlacesLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setPlacesErr(errToStr(err));
          setPlacesLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [formData.id, source]);

  // Get distinct strings for use in the select input filters when places changes
  useEffect(() => {
    if (places.length > 0) {
      const placeTags = new Set();
      const placeTypes = new Set();

      for (let i = 0; i < places.length; i++) {
        if (places[i].placeTags) places[i].placeTags.forEach((tag: any) => placeTags.add(tag.name));
        if (places[i].placeType) placeTypes.add(places[i].placeType);
      }

      setFilterOptions({
        placeTags: Array.from(placeTags).sort(),
        placeTypes: Array.from(placeTypes).sort(),
      });
    }
  }, [places]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "name":
          currValid = exists(name, value, setFormErrors) && maxLength(name, value, 50, setFormErrors) && validName(name, value, setFormErrors);
          break;

        case "description":
          currValid = maxLength(name, value, 512, setFormErrors);
          break;

        case "colour":
          currValid = exists(name, value, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const placeChangesArr = Object.values(placeChanges);

    const addPlaceIds: any = [];
    const removePlaceIds: any = [];

    placeChangesArr.forEach((place: any) => {
      // If the place is selected it will be updated with the current tag
      if (place.selected) addPlaceIds.push(place.id);

      // If the place is not selected and we are editing an existing tag and we have
      // de-selected a place within the current tag, remove tag from the place
      if (!place.selected && formData.id && place.placeTags.some((tag: any) => formData.id === tag.id)) removePlaceIds.push(place.id);
    });

    // If the tag has an id then we are editing it therefore include the removePlaceIds array
    const formattedData: any = {
      id: formData.id,
      name: formData.name,
      colour: formData.colour,
      description: formData.description,
      addPlaceIds,
    };

    if (formData.id) {
      formattedData.removePlaceIds = removePlaceIds;
    }

    return formattedData;
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      // If the tag has an id then we are editing it
      if (body.id) {
        setSubmitting(true);
        editPlaceTag(source, body)
          .then(() => {
            if (fetch) fetch();
            setSubmittedMsg("Place Tag Updated");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      } else {
        setSubmitting(true);
        addPlaceTag(source, body)
          .then(() => {
            if (fetch) fetch();
            setSubmittedMsg("Place Tag Created");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      }
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleCheckboxChange = (e: any) => {
    e.persist();
    const index = e.target.name;
    const place = places[index];

    setPlaceChanges((prev: any) => {
      const curr = JSON.parse(JSON.stringify(prev));
      curr[place.id] = place;
      return curr;
    });
    setPlaces((prev: any) => {
      const curr = [...prev];
      curr[index].selected = e.target.checked;
      return curr;
    });
  };

  const columns: any = [];

  columns.push({
    id: "selected",
    Header: "Selected",
    accessor: "selected",
    minWidth: 120,
    maxWidth: 120,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "true") {
        return row[filter.id] === true;
      }
      if (filter.value === "false") {
        return row[filter.id] === false;
      }
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
    ),
    Cell: (props: any) => <Checkbox onChange={handleCheckboxChange} name={props.index} checked={props.value} />,
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  columns.push({
    id: "name",
    Header: "Place Name",
    accessor: "name",
    minWidth: 200,
    maxWidth: 200,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["name"],
      }),
    filterAll: true,
    Cell: (props: any) => {
      return (
        <Link title={props.value} to={`/places/${props.original.id}`}>
          {props.value}
        </Link>
      );
    },
  });

  columns.push({
    id: "placeType",
    Header: "Place Type",
    accessor: "placeType",
    minWidth: 160,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "none") {
        return row[filter.id] === undefined;
      }
      return row[filter.id] === filter.value;
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="none">None</option>
        {filterOptions.placeTypes.map((label: any) => {
          return (
            <option key={label} value={label}>
              {label}
            </option>
          );
        }, [])}
      </select>
    ),
  });

  columns.push({
    id: "placeTags",
    Header: "Place Tags",
    accessor: "placeTags",
    minWidth: 140,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "none") {
        return row[filter.id] === undefined;
      }
      return row[filter.id] === filter.value;
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="none">None</option>
        {filterOptions.placeTags.map((tag: any) => {
          return (
            <option key={tag} value={tag}>
              {tag}
            </option>
          );
        }, [])}
      </select>
    ),
    Cell: (props: any) =>
      props.original.placeTags ? (
        props.original.placeTags.sort(sortTags).map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
      ) : (
        <></>
      ),
  });

  // exists here to sort the rows by selected and prevent them bouncing around when editing
  columns.push({
    id: "initSelected",
    accessor: "initSelected",
    show: false,
  });

  const defaultSorted = [
    {
      id: "initSelected",
      desc: true,
    },
    {
      id: "id",
      desc: false,
    },
  ];

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      size={!submittedMsg && !submittingErr ? "lg" : "sm"}
      title={formData.id ? "Edit Place Tag" : "Create Place Tag"}
      success={submittedMsg}
      error={submittingErr}
      submitBtnText={formData.id ? "Update Tag" : "Create Tag"}
      body={
        <LoadingContainer loading={placesLoading || submitting} err={placesErr}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer>
              <label>Tag Name</label>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <FormInput type="text" name="name" value={formData.name} error={formErrors.name} onChange={handleChange} />
                </div>
                <div style={{ width: "100%", maxWidth: "34px" }}>
                  <ColorPicker color={formData.colour} setColor={(color: any) => setFormData((prev: any) => ({ ...prev, colour: color.hex }))} />
                </div>
              </div>
              <FormError error={formErrors.name}>{formErrors.name}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <label>Description</label>
              <FormInput type="text" name="description" value={formData.description} error={formErrors.description} onChange={handleChange} />
              <FormError error={formErrors.description}>{formErrors.description}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <Table
                loading={placesLoading}
                filterable={true}
                style={{ clear: "both" }}
                data={places}
                columns={columns}
                defaultSorted={defaultSorted}
                defaultPageSize={10}
              />
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default EditPlaceTagModal;
