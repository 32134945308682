import styled from "../Themes";

const DefaultIconBtn = styled<any>(`div`)`
  display: flex;
  margin: 0 4px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.color.font[2]} !important;
  border: 1px solid ${({ theme }) => theme.color.font[2]} !important;
  color: ${({ theme }) => theme.color.inverse_font_bold[2]} !important;
  height: ${({ size }) => (size !== undefined ? size : "30px")} !important;
  width: ${({ size }) => (size !== undefined ? size : "30px")} !important;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")} !important;
  pointer-events: ${({ disabled }) => (disabled ? "all" : "default")} !important;
  svg {
    color: ${({ theme }) => theme.color.inverse_font_bold[2]} !important;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.font_bold[2]} !important;
    color: ${({ theme }) => theme.color.inverse_font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color.font_bold[2]} !important;
    svg {
      color: ${({ theme }) => theme.color.inverse_font_bold[2]} !important;
    }
  }

  &:focus {
    background-color: ${({ theme }) => theme.color.font_bold[2]} !important;
    color: ${({ theme }) => theme.color.inverse_font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color.font_bold[2]} !important;
    svg {
      color: ${({ theme }) => theme.color.inverse_font_bold[2]} !important;
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.color.font_bold[2]} !important;
    color: ${({ theme }) => theme.color.inverse_font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color.font_bold[2]} !important;
    svg {
      color: ${({ theme }) => theme.color.inverse_font_bold[2]} !important;
    }
  }
`;

const PrimaryIconBtn = styled<any>(`div`)`
  display: flex;
  margin: 0 4px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.color.primary[2]} !important;
  border: 1px solid ${({ theme }) => theme.color.primary[2]} !important;
  color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
  height: ${({ size }) => (size !== undefined ? size : "30px")} !important;
  width: ${({ size }) => (size !== undefined ? size : "30px")} !important;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")} !important;
  pointer-events: ${({ disabled }) => (disabled ? "all" : "default")} !important;
  svg {
    color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color_80.primary[2]} !important;
    color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color_80.primary[2]} !important;
    svg {
      color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    }
  }

  &:focus {
    background-color: ${({ theme }) => theme.color_80.primary[2]} !important;
    color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color_80.primary[2]} !important;
    svg {
      color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.color_80.primary[2]} !important;
    color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color_80.primary[2]} !important;
    svg {
      color: ${({ theme }) => theme.color.button_font_bold[2]} !important;
    }
  }
`;

const OutlineIconBtn = styled<any>(`span`)`
  display: flex;
  margin: 0 4px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: transparent !important;
  border: 1px solid ${({ theme }) => theme.color.font[2]} !important;
  color: ${({ theme }) => theme.color.font[2]} !important;
  height: ${({ size }) => (size !== undefined ? size : "30px")} !important;
  width: ${({ size }) => (size !== undefined ? size : "30px")} !important;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")} !important;
  pointer-events: ${({ disabled }) => (disabled ? "all" : "default")} !important;
  svg {
    color: ${({ theme }) => theme.color.font[2]} !important;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.tooltip_hover[3]} !important;
    color: ${({ theme }) => theme.color.font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color.font_bold[2]} !important;
    svg {
      color: ${({ theme }) => theme.color.font_bold[2]} !important;
    }
  }

  &:focus {
    background-color: ${({ theme }) => theme.color.tooltip_hover[3]} !important;
    color: ${({ theme }) => theme.color.font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color.font_bold[2]} !important;
    svg {
      color: ${({ theme }) => theme.color.font_bold[2]} !important;
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.color.tooltip_hover[3]} !important;
    color: ${({ theme }) => theme.color.font_bold[2]} !important;
    border: 1px solid ${({ theme }) => theme.color.font_bold[2]} !important;
    svg {
      color: ${({ theme }) => theme.color.font_bold[2]} !important;
    }
  }
`;

const ClearIconBtn = styled<any>(`div`)`
  display: flex;
  margin: 0 4px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
  height: ${({ size }) => (size !== undefined ? size : "30px")} !important;
  width: ${({ size }) => (size !== undefined ? size : "30px")} !important;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")} !important;
  pointer-events: ${({ disabled }) => (disabled ? "all" : "default")} !important;
  svg {
    color: ${({ color }) => color} !important;
  }

  &:hover {
    color: ${({ hoverColor }) => hoverColor} !important;
    svg {
      color: ${({ hoverColor }) => hoverColor} !important;
    }
  }

  &:focus {
    color: ${({ hoverColor }) => hoverColor} !important;
    svg {
      color: ${({ hoverColor }) => hoverColor} !important;
    }
  }

  &:active {
    color: ${({ hoverColor }) => hoverColor} !important;
    svg {
      color: ${({ hoverColor }) => hoverColor} !important;
    }
  }
`;
export { DefaultIconBtn, PrimaryIconBtn, OutlineIconBtn, ClearIconBtn };
