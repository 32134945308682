import axios from "axios";
import { getAccount } from "../localStorage";

export const fetchAutoComplete = async (field: string, search: string, orgId?: string) => {
  const params = {
    organisationId: orgId ? orgId : getAccount().organisationId,
    search,
  };

  const { data } = await axios({
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/autocomplete/" + field,
    params,
  });
  return data;
};

export const fetchAutoCompleteBatches = async (field: string, placeId: number, beerId: string, orgId?: string) => {
  const params = {
    organisationId: orgId ? orgId : getAccount().organisationId,
    placeId,
    beerId,
  };

  const { data } = await axios({
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/autocomplete/" + field,
    params,
  });
  return data;
};

export const fetchAutoCompleteTriggers = async (
  trackerTagId?: string,
  trackerTypeId?: string,
  trackerId?: string,
  assetId?: string,
  assetTypeId?: string,
  contentTagId?: string,
  geofence?: boolean
) => {
  const params = {
    organisationId: undefined,
    trackerTagId,
    trackerTypeId,
    trackerId,
    assetId,
    assetTypeId,
    contentTagId,
    geofence,
  };

  if (!trackerTagId && !trackerTypeId && !trackerId && !assetId && !assetTypeId && !contentTagId) {
    params.organisationId = getAccount().organisationId;
  }

  const { data } = await axios({
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/autocomplete/triggers",
    params,
  });
  return data;
};

export const fetchAutoCompletePickupRequestTypes = async (field: string, search: string, trackerId: string, orgId?: string) => {
  const params = {
    organisationId: orgId ? orgId : getAccount().organisationId,
    search,
    trackerId,
  };

  const { data } = await axios({
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/autocomplete/" + field,
    params,
  });
  return data;
};
