import axios, { CancelTokenSource } from "axios";
import { FC, useEffect, useState } from "react";
import { fetchAutoCompletePickupRequestTypes } from "../../services/autoComplete";
import { createPickupRequestPublic } from "../../services/pickupRequests";
import errToStr from "../../util/errToStr";
import { maxLength } from "../../util/formValidations";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import { FormError, FormTextareaInput } from "../FormComponents";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { AsyncSelect } from "../Select";
import { isBinaryLoop } from "../../util/checkDomain";
import isMobileDevice from "../../util/isMobileDevice";

const PublicEditPickupRequestModal: FC<any> = ({ scanCode, trackerId, onSuccess, modalOpen, setModalOpen }) => {
  const [formData, setFormData] = useState<any>({
    pickupRequestType: null,
    notes: "",
  });
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [coords, setCoords] = useState<any>({});

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // If user is on a mobile device, get their location to update the tracker's location
  useEffect(() => {
    if (isMobileDevice()) {
      navigator.geolocation.getCurrentPosition((pos: any) => {
        if (pos.coords) setCoords(pos.coords);
      });
    }
  }, [scanCode, source]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "notes":
          currValid = maxLength(name, value, 500, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const formattedData: any = {
      scanCode,
      notes: formData.notes.trim(),
      pickupRequestTypeName: formData.pickupRequestType?.label,
      phoneLatitude: coords.latitude,
      phoneLongitude: coords.longitude,
      PhoneAccuracy: Math.round(coords.accuracy) || null,
    };

    return formattedData;
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      createPickupRequestPublic(source, body)
        .then((response) => {
          if (onSuccess) onSuccess(response);
          setSubmittedMsg("Pickup Request Created");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  // Auto-populates select input on search.
  const loadPickupRequestTypes = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoCompletePickupRequestTypes(inputName, inputValue, trackerId).then((response) => {
      callback(response);
    });
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={handleClose}
      size={!submittedMsg && !submittingErr ? "lg" : "sm"}
      title="Create Pickup Request"
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Create"
      body={
        <LoadingContainer loading={submitting}>
          <div>
            <form noValidate onSubmit={(e) => e.preventDefault()} style={{ display: "flex", flexWrap: "wrap", columnGap: "10px" }}>
              <ModalFormContainer halfWidth={true}>
                <label>{isBinaryLoop() ? "Contents Type" : "Pickup Request Type"}</label>
                <AsyncSelect
                  name="pickupRequestType"
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.pickupRequestType}
                  value={formData.pickupRequestType}
                  loadOptions={(inputValue: any, callback: any) => loadPickupRequestTypes("pickuprequesttypes", inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                />
                <FormError error={formErrors.pickupRequestType}>{formErrors.pickupRequestType}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label>Notes</label>
                <FormTextareaInput name="notes" rows={6} maxLength={4096} value={formData.notes} error={formErrors.notes} onChange={handleChange} />
                <FormError error={formErrors.notes}>{formErrors.notes}</FormError>
              </ModalFormContainer>
            </form>
          </div>
        </LoadingContainer>
      }
      footer={
        submittedMsg ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : submittingErr ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : (
          <>
            <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
            <PrimaryBtn onClick={handleSubmit}>Create</PrimaryBtn>
          </>
        )
      }
    />
  );
};

export default PublicEditPickupRequestModal;
