import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { kegOrTracker } from "../../util/kegOrTracker";
import { postEditBulkAlerts } from "../../services/alertNotifications";

const BulkUnresolveSensorAlerts: FC<any> = ({ alert = {}, onSuccess, modalOpen, setModalOpen }) => {
  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleSubmit = () => {
    setSubmittedMsg("");
    setSubmittingErr("");
    setSubmitting(true);
    postEditBulkAlerts(source, { sensorId: alert.sensorId, action: "unresolve" })
      .then(() => {
        onSuccess();
        setSubmittedMsg(`All alerts for ${alert.sensorId} were unresolved`);
        setSubmitting(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      title={"Unresolve Alerts"}
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Yes"
      cancelBtnText="Cancel"
      body={
        <LoadingContainer loading={submitting}>
          <span>
            Are you sure you want to unresolve all alerts for {kegOrTracker("Keg", "Tracker")} {alert.sensorId}?
          </span>
        </LoadingContainer>
      }
    />
  );
};

export default BulkUnresolveSensorAlerts;
