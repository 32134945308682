import React, { useContext } from "react";
import styled, { keyframes } from "../Themes";
import ErrIcon from "../../svgs/ErrIcon";
import { ThemeContext } from "styled-components";
import Bold from "../Bold";

const Content = styled<any>(`div`)`
  width: 100%;
  height: 100%;
  filter: ${({ filter }) => filter};
  visibility: ${({ visibility }) => visibility};
`;

const ErrorContainer = styled(`div`)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 150;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 24px;
`;

const SpinnerContainer = styled<any>("div")`
  visibility: ${({ visibility }) => visibility};
  opacity: ${({ opacity }) => opacity};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: ${({ theme }) => theme.color_30.loading_bg[2]};
  transition: visibility 0.25s, opacity 0.25s linear;
`;

const Spinner = styled("div")`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  text-align: center;
`;

const StretchDelay = keyframes`
  0%, 40%, 100% { 
    transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
  }
`;

const Rect = styled("div")`
  background-color: ${({ theme }) => theme.color.loading_icon[2]};
  height: 100%;
  width: 8px;
  margin: 0 1px;
  display: inline-block;
  animation: ${StretchDelay} 1.2s infinite ease-in-out;
`;

const Rect1 = styled(Rect)``;

const Rect2 = styled(Rect)`
  animation-delay: -1.1s;
`;

const Rect3 = styled(Rect)`
  animation-delay: -1s;
`;

const Rect4 = styled(Rect)`
  animation-delay: -0.9s;
`;

const Rect5 = styled(Rect)`
  animation-delay: -0.8s;
`;

const Rect6 = styled(Rect)`
  animation-delay: -0.7s;
`;

const LoadingContainer = ({ children, loading, err, style }: any) => {
  const { color } = useContext(ThemeContext);

  return (
    <>
      {!loading && err && (
        <ErrorContainer>
          <div
            style={{
              width: "35px",
              height: "35px",
              marginRight: "10px",
            }}
          >
            <ErrIcon fill={color.error[1]} />
          </div>
          <Bold style={{ color: color.error[1] }}>{err}</Bold>
        </ErrorContainer>
      )}
      {children && (
        <Content visibility={err ? "hidden" : "visible"} filter={loading ? "blur(2px)" : "none"} style={style}>
          {children}
        </Content>
      )}
      <SpinnerContainer opacity={loading ? 1 : 0} visibility={loading ? "visible" : "hidden"}>
        <Spinner>
          <Rect1 />
          <Rect2 />
          <Rect3 />
          <Rect4 />
          <Rect5 />
          <Rect6 />
        </Spinner>
      </SpinnerContainer>
    </>
  );
};
export default LoadingContainer;
