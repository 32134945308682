export const beerLayout = {
  lg: [
    {
      w: 3,
      h: 5,
      x: 6,
      y: 5,
      i: "1",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 5,
      x: 8,
      y: 0,
      i: "2",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 5,
      x: 4,
      y: 0,
      i: "3",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 5,
      x: 9,
      y: 5,
      i: "4",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 5,
      x: 3,
      y: 5,
      i: "5",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 5,
      x: 0,
      y: 5,
      i: "6",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 5,
      x: 0,
      y: 0,
      i: "7",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 20,
      x: 0,
      y: 10,
      i: "8",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 20,
      x: 8,
      y: 10,
      i: "9",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 4,
      h: 20,
      x: 4,
      y: 10,
      i: "10",
      minW: 1,
      minH: 4,
      moved: false,
      static: false,
    },
    {
      w: 12,
      h: 20,
      x: 0,
      y: 30,
      i: "11",
      minW: 1,
      minH: 4,
      moved: false,
      static: false,
    },
  ],
  md: [
    {
      w: 6,
      h: 5,
      x: 0,
      y: 25,
      i: "1",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 5,
      x: 0,
      y: 10,
      i: "2",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 5,
      x: 0,
      y: 5,
      i: "3",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 5,
      x: 0,
      y: 30,
      i: "4",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 5,
      x: 0,
      y: 20,
      i: "5",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 5,
      x: 0,
      y: 15,
      i: "6",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 5,
      x: 0,
      y: 0,
      i: "7",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 20,
      x: 0,
      y: 35,
      i: "8",
      minW: 1,
      minH: 4,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 20,
      x: 0,
      y: 75,
      i: "9",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 20,
      x: 0,
      y: 55,
      i: "10",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 20,
      x: 0,
      y: 95,
      i: "11",
      minW: 1,
      minH: 4,
      moved: false,
      static: false,
    },
  ],
  sm: [
    {
      w: 2,
      h: 5,
      x: 0,
      y: 25,
      i: "1",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 10,
      i: "2",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 5,
      i: "3",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 30,
      i: "4",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 20,
      i: "5",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 15,
      i: "6",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 0,
      i: "7",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 20,
      x: 0,
      y: 35,
      i: "8",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 20,
      x: 0,
      y: 75,
      i: "9",
      minW: 1,
      minH: 4,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 20,
      x: 0,
      y: 55,
      i: "10",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 20,
      x: 0,
      y: 95,
      i: "11",
      minW: 1,
      minH: 4,
      moved: false,
      static: false,
    },
  ],
};

export const techLayout = {
  lg: [
    {
      w: 6,
      h: 5,
      x: 6,
      y: 0,
      i: "3",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 5,
      x: 0,
      y: 0,
      i: "7",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 20,
      x: 6,
      y: 5,
      i: "9",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 20,
      x: 0,
      y: 5,
      i: "10",
      minW: 1,
      minH: 4,
      moved: false,
      static: false,
    },
    {
      w: 12,
      h: 20,
      x: 0,
      y: 25,
      i: "11",
      minW: 1,
      minH: 4,
      moved: false,
      static: false,
    },
  ],
  md: [
    {
      w: 3,
      h: 5,
      x: 3,
      y: 0,
      i: "3",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 3,
      h: 5,
      x: 0,
      y: 0,
      i: "7",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 20,
      x: 0,
      y: 25,
      i: "9",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 20,
      x: 0,
      y: 5,
      i: "10",
      minW: 1,
      minH: 4,
      moved: false,
      static: false,
    },
    {
      w: 6,
      h: 20,
      x: 0,
      y: 45,
      i: "11",
      minW: 1,
      minH: 4,
      moved: false,
      static: false,
    },
  ],
  sm: [
    {
      w: 2,
      h: 5,
      x: 0,
      y: 5,
      i: "3",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 5,
      x: 0,
      y: 0,
      i: "7",
      minW: 1,
      minH: 2,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 20,
      x: 0,
      y: 30,
      i: "9",
      minW: 1,
      minH: 4,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 20,
      x: 0,
      y: 10,
      i: "10",
      minW: 1,
      minH: 4,
      moved: false,
      static: false,
    },
    {
      w: 2,
      h: 20,
      x: 0,
      y: 50,
      i: "11",
      minW: 1,
      minH: 4,
      moved: false,
      static: false,
    },
  ],
};
