import React from "react";
import BinaryTechLogo from "../svgs/BinaryTechLogo";
import BinaryBeerLogo from "../svgs/BinaryBeerLogo";
import BinaryMedLogo from "../svgs/BinaryMedLogo";
import BinaryLoopLogo from "../svgs/BinaryLoopLogo";
import SafaricomLogo from "../svgs/SafaricomLogo";
import UasLogo from "../svgs/UasLogo";
import iKegLogoDark from "../imgs/i-keg-logo-dark.png";
import iKegLogoLight from "../imgs/i-keg-logo-light.png";
import { checkDomain } from "./checkDomain";

export const getLogo = (color: any, theme: string) => {
  const { hostname } = window.location;

  if (hostname === "i-keg.binarybeer.io") {
    return <img width="100%" src={theme === "dark" ? iKegLogoLight : iKegLogoDark} alt="i-Keg" style={{ objectFit: "inherit", maxHeight: "inherit" }} />;
  } else if (hostname === "safaricom.binarybeer.io") {
    return <SafaricomLogo color={color} />;
  } else if (hostname === "uas.binarytech.io") {
    return <UasLogo color={color} />;
  } else {
    return checkDomain(<BinaryTechLogo color={color} />, <BinaryBeerLogo color={color} />, <BinaryMedLogo color={color} />, <BinaryLoopLogo color={color} />);
  }
};
