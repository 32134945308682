import React, { FC, useState, useRef } from "react";
import { SubmitModal } from "../Modal";
import { OutlineBtn } from "../Buttons";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Tooltip from "../Tooltip";

function renderEventContent(eventInfo: any) {
  return (
    <Tooltip
      content={
        <span style={{ cursor: "pointer" }} onClick={() => eventInfo.event.remove()}>
          Delete
        </span>
      }
      interactive={true}
      touch={true}
      appendTo={document.body}
      trigger="click"
    >
      <div
        style={{
          fontSize: ".85em",
          height: "100%",
        }}
      >
        {eventInfo.timeText}
      </div>
    </Tooltip>
  );
}

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

const dayToDate = (day: string) => {
  switch (day) {
    case "Sunday":
      return new Date(Date.UTC(1970, 0, 4));

    case "Monday":
      return new Date(Date.UTC(1970, 0, 5));

    case "Tuesday":
      return new Date(Date.UTC(1970, 0, 6));

    case "Wednesday":
      return new Date(Date.UTC(1970, 0, 7));

    case "Thursday":
      return new Date(Date.UTC(1970, 0, 8));

    case "Friday":
      return new Date(Date.UTC(1970, 0, 9));

    case "Saturday":
      return new Date(Date.UTC(1970, 0, 10));

    default:
      break;
  }
};

// If the date is out of range (x < 04/01/1970 or x > 10/01/1970),
// minus/add a week so it appears on the visible calendar
const correctDate = (date: Date) => {
  if (date.getDate() > 10) {
    date.setDate(date.getDate() - 7);
  } else if (date.getDate() < 4) {
    date.setDate(date.getDate() + 7);
  }
};

const scheduleToEvent = (schedule: any) => {
  // Create dates in UTC from schedules from the API
  const startDate = dayToDate(schedule.startDay);
  const endDate = dayToDate(schedule.endDay);

  if (startDate && endDate) {
    startDate.setUTCHours(schedule.startHour);
    startDate.setUTCMinutes(schedule.startMinute);

    endDate.setUTCHours(schedule.endHour);
    endDate.setUTCMinutes(schedule.endMinute);

    // Check if dates are equal, if so the schedule spans every day for the whole week
    // so return a full calendar
    if (startDate.valueOf() === endDate.valueOf()) {
      return [
        {
          start: new Date(1970, 0, 4, 0, 0, 0, 0),
          end: new Date(1970, 0, 11, 0, 0, 0, 0),
        },
      ];
    }

    correctDate(startDate);
    correctDate(endDate);

    // If the start date is greater than the end date the event needs to be split into 2 events
    // and added to the start and end of the visible calendar
    if (startDate.valueOf() > endDate.valueOf()) {
      const startDateOne = dayToDate(schedule.startDay);
      const endDateOne = new Date(1970, 0, 11, 0, 0, 0, 0);
      const startDateTwo = new Date(1970, 0, 4, 0, 0, 0, 0);
      const endDateTwo = dayToDate(schedule.endDay);

      if (startDateOne && endDateOne) {
        // Set date objects to correct UTC time
        startDateOne.setUTCHours(schedule.startHour);
        startDateOne.setUTCMinutes(schedule.startMinute);
        correctDate(startDateOne);
      }

      if (startDateTwo && endDateTwo) {
        // Set date objects to correct UTC time
        endDateTwo.setUTCHours(schedule.endHour);
        endDateTwo.setUTCMinutes(schedule.endMinute);
        correctDate(endDateTwo);
      }

      // Only add the events if the dates are not equal.
      // This can happen due to the code above splitting 1 schedule into 2 events when the end day is before the start day (e.g. starts Sat and ends Sun).
      // This can happen if an event is created in one timezone and then edited in a different timezone.
      // If this does happen an extra event is created due to the code above and this extra event will have the same start and end date.
      // This can probably be achieved in a cleaner way but this appears to work and timezones suck.
      const events = [];

      if (
        startDateOne &&
        (startDateOne.getDay() !== endDateOne.getDay() ||
          startDateOne.getHours() !== endDateOne.getHours() ||
          startDateOne.getMinutes() !== endDateOne.getMinutes())
      ) {
        events.push({
          start: startDateOne,
          end: endDateOne,
        });
      }

      if (
        endDateTwo &&
        (startDateTwo.getDay() !== endDateTwo.getDay() ||
          startDateTwo.getHours() !== endDateTwo.getHours() ||
          startDateTwo.getMinutes() !== endDateTwo.getMinutes())
      ) {
        events.push({
          start: startDateTwo,
          end: endDateTwo,
        });
      }

      return events;
    }

    return [
      {
        start: startDate,
        end: endDate,
      },
    ];
  }

  return [];
};

const eventToSchedule = (event: any) => {
  const startDate = event.start;
  const endDate = event.end;

  if (startDate && endDate) {
    return {
      startDay: days[startDate.getUTCDay()],
      startHour: startDate.getUTCHours(),
      startMinute: startDate.getUTCMinutes(),
      endDay: days[endDate.getUTCDay()],
      endHour: endDate.getUTCHours(),
      endMinute: endDate.getUTCMinutes(),
    };
  }
};

const setInitialEvents = (data: any) => {
  if (data && data.schedules && data.schedules.length > 0) {
    const events = data.schedules.flatMap((schedule: any) => {
      return scheduleToEvent(schedule);
    });
    return events;
  }
};

const ScheduleModal: FC<any> = ({ modalOpen, setModalOpen, data, setData, editable = true }) => {
  const [events] = useState<any>(setInitialEvents(data));
  const [updatedEvents, setUpdatedEvents] = useState<any>([]);
  // const [selectedTimezone, setSelectedTimezone] = useState({});

  const calendarRef = useRef();

  const handleSave = () => {
    const schedules = updatedEvents.map((event: any) => {
      return eventToSchedule(event);
    });
    setData((prev: any) => ({
      ...prev,
      schedules,
    }));

    setModalOpen(false);
  };

  const handleDateSelect = (selectInfo: any) => {
    if (editable) {
      const calendarApi = selectInfo.view.calendar;

      calendarApi.unselect();

      calendarApi.addEvent({
        start: selectInfo.startStr,
        end: selectInfo.endStr,
      });
    }
  };

  const handleEvents = (events: any) => {
    setUpdatedEvents(events);
  };

  const clearEvents = () => {
    const calendar: any = calendarRef.current;
    if (calendar) {
      const api = calendar.getApi();
      const eventSources = api.getEventSources();
      for (let i = 0; i < eventSources.length; i++) {
        eventSources[i].remove();
      }
    }
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSave()}
      onClose={() => setModalOpen(false)}
      size="lg"
      title={editable ? "Edit Schedule" : "View Schedule"}
      submitBtn={editable ? "primary" : null}
      submitBtnText="Update Schedule"
      cancelBtnText={editable ? "Cancel" : "Close"}
      cancelBtnProps={editable ? {} : { width: "100%" }}
      body={
        <>
          {/* <TimezoneSelect
            value={selectedTimezone}
            onChange={(tz: any) => {
              setSelectedTimezone(tz);
              if (calendarRef.current) {
                // @ts-ignore
                const calendar = calendarRef.current.getApi();
                calendar.setOption("timeZone", tz.value);
              }
            }}
          /> */}
          {editable && (
            <div style={{ textAlign: "right", padding: "6px 0" }}>
              <OutlineBtn onClick={() => clearEvents()}>Clear Schedule</OutlineBtn>
            </div>
          )}
          <FullCalendar
            timeZone="local"
            ref={(ref: any) => (calendarRef.current = ref)}
            plugins={[timeGridPlugin, interactionPlugin]}
            eventContent={editable ? renderEventContent : undefined}
            headerToolbar={false}
            initialView="timeGridFourDay"
            firstDay={0}
            allDaySlot={false}
            eventResizableFromStart={true}
            editable={editable}
            selectable={true}
            expandRows={true}
            snapDuration={"00:15"}
            initialDate={new Date(1970, 0, 4)}
            selectMirror={editable ? true : false}
            select={handleDateSelect}
            eventsSet={handleEvents}
            validRange={{
              start: "1970-01-04",
              end: "1970-01-11",
            }}
            eventTimeFormat={{
              hour: "numeric",
              minute: "2-digit",
              meridiem: "short",
            }}
            views={{
              timeGridFourDay: {
                type: "timeGrid",
                duration: { week: 1 },
                dayHeaderFormat: { weekday: "short" },
                slotDuration: "01:00",
              },
            }}
            events={events}
          />
        </>
      }
    />
  );
};

export default ScheduleModal;
