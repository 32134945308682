import axios from "axios";
import moment from "moment";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { fetchReport16 } from "../../../services/report16";
import errToStr from "../../../util/errToStr";
import { humaniseDays } from "../../../util/humaniseDurations";
import Bold from "../../Bold";
import FlatpickrRangePicker from "../../FlatpickrRangePicker";
import LoadingContainer from "../../LoadingContainer";
import { TableHeaderButtons } from "../../NewTable/styles";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import { Panel } from "../../Panel";
import Table, { CsvButtonsComponent } from "../../Table";

const formatDataToCsv = (tableRef: any) => {
  const headers = ["Tracker ID", "Start Date", "End Date", "Duration", "Beer", "Place Count"];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [
        row.kegId,
        row.startDate ? moment.unix(row.startDate).format() : "",
        row.endDate ? moment.unix(row.endDate).format() : "",
        humaniseDays(row.days),
        row.beerName,
        row.placeCount,
      ];
    }, []),
  ];
};

const RecentKegTurns: FC<any> = () => {
  const { short_datetime, short_date } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchReport16(source)
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData([]);
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  const columns = [
    {
      id: "kegId",
      Header: "Tracker ID",
      accessor: "kegId",
      Cell: (props: any) => <Link to={`/kegs/${props.value}?start=${props.original.startDate}&end=${props.original.endDate}`}>{props.value}</Link>,
      Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
      minWidth: 130,
    },
    {
      id: "startDate",
      Header: "Start Date",
      accessor: "startDate",
      filterMethod: (filter: any, row: any) => {
        if (filter.value.length === 2) {
          if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
          else return false;
        } else return true;
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
      ),
      Cell: (props: any) => moment.unix(props.value).format(short_datetime),
      minWidth: 160,
    },
    {
      id: "endDate",
      Header: "End Date",
      accessor: "endDate",
      filterMethod: (filter: any, row: any) => {
        if (filter.value.length === 2) {
          if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
          else return false;
        } else return true;
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
      ),
      Cell: (props: any) => moment.unix(props.value).format(short_datetime),
      minWidth: 160,
    },
    {
      id: "days",
      Header: "Duration",
      accessor: "days",
      Cell: (props: any) => humaniseDays(props.value),
      minWidth: 180,
    },
    {
      id: "beerName",
      Header: "Beer",
      accessor: "beerName",
      minWidth: 120,
    },
    {
      id: "placeCount",
      Header: "Place Count",
      accessor: "placeCount",
      minWidth: 150,
    },
  ];

  const defaultSorted = [
    {
      id: "days",
      desc: true,
    },
  ];

  return (
    <>
      <PageBreadcrumbs prevRoutes={[{ slug: "/reports", title: "Reports" }]} currRoute="Keg Turn Audit" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading} err={dataErr}>
            <Panel>
              <TableHeaderButtons>
                <div style={{ display: "flex" }}></div>
                <CsvButtonsComponent data={data} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName="Keg Turn Audit.csv" />
              </TableHeaderButtons>
              <Table
                loading={dataLoading}
                filterable={true}
                style={{ clear: "both" }}
                data={data}
                columns={columns}
                defaultSorted={defaultSorted}
                ref={tableRef}
              />
            </Panel>
          </LoadingContainer>
        </div>
      </PageContainer>
    </>
  );
};

export default RecentKegTurns;
