import { useContext } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { ThemeContext } from "styled-components";
import isDark from "../../util/isDark";
import ReactIcon from "../ReactIcon";

const getIcon = (defaultColour: string, colour: string, name?: string) => {
  if (name) {
    const content = encodeURIComponent(renderToStaticMarkup(<ReactIcon name={name} defaultColour={defaultColour} colour={colour} size="16px" />));

    return {
      alignItems: "center",
      display: "flex",

      ":before": {
        content: `url("data:image/svg+xml,${content}")`,
        display: "block",
        margin: "0 8px 0 0",
        height: 16,
        width: 16,
      },
    };
  } else if (colour) {
    return {
      alignItems: "center",
      display: "flex",

      ":before": {
        backgroundColor: colour,
        borderRadius: 10,
        content: '" "',
        display: "block",
        margin: "0 8px 0 0",
        height: 10,
        width: 10,
      },
    };
  }
};

const ReactSelectStyles = (isError: any, center?: boolean) => {
  const theme = useContext(ThemeContext);

  return {
    clearIndicator: (provided: any) => ({
      ...provided,
      padding: 6,
      cursor: "pointer",
      color: theme.color_30.font_bold[2],
      ":hover": {
        color: theme.color.font_bold[2],
      },
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      marginBottom: 0,
      fontFamily: theme.fontFamily.body,
      fontSize: theme.fontSize.m,
      fontWeight: theme.fontWeight.medium,
      width: "100%",
      clear: "both",
      opacity: state.isDisabled ? 0.33 : 1,
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      width: "100%",
      backgroundColor: theme.color.input_bg[2],
      transition: theme.transition.m,
      borderColor: isError ? theme.color.error[2] : theme.color_30.panel_fg[0],
      borderRadius: 3,
      borderStyle: "solid",
      borderWidth: 1,
      minHeight: 34,
      boxShadow: state.isFocused ? `0 0 0 1px ${theme.color.primary[2]}` : null,
      cursor: "pointer",
      "&:hover": {
        borderColor: isError ? theme.color.error[2] : theme.color_80.panel_fg[0],
      },
    }),
    dropdownIndicator: (provided: any) => ({
      ...provided,
      padding: 6,
      cursor: "pointer",
      color: theme.color_30.font_bold[2],
      ":hover": {
        color: theme.color.font_bold[2],
      },
    }),
    group: (provided: any) => provided,
    groupHeading: (provided: any) => provided,
    indicatorsContainer: (provided: any) => provided,
    indicatorSeparator: (provided: any) => ({
      ...provided,
      backgroundColor: theme.color.main_fg[2],
    }),
    input: (provided: any) => ({
      ...provided,
      color: theme.color.font_bold[2],
      margin: 0,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    loadingIndicator: (provided: any) => provided,
    loadingMessage: (provided: any) => provided,
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: 0,
      backgroundColor: theme.color.main_bg[2],
      marginTop: 4,
      marginBottom: 4,
      zIndex: 10,
      borderRight: `1px solid ${theme.color_30.panel_fg[0]}`,
      borderBottom: `1px solid ${theme.color_30.panel_fg[0]}`,
      borderLeft: `1px solid ${theme.color_30.panel_fg[0]}`,
    }),
    menuList: (provided: any) => ({
      ...provided,
      color: theme.color.font[2],
    }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 1010002 }),
    multiValue: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.data && state.data.colour ? state.data.colour : theme.color.primary[0],
      color: isDark(state.data && state.data.colour ? state.data.colour : theme.color.primary[0]) ? "#fff" : "#000",
    }),
    multiValueLabel: (provided: any, state: any) => ({
      ...provided,
      color: isDark(state.data && state.data.colour ? state.data.colour : theme.color.primary[0]) ? "#fff" : "#000",
      fontFamily: theme.fontFamily.body,
      fontSize: theme.fontSize.s,
      fontWeight: theme.fontWeight.medium,
      cursor: "default",
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      cursor: "pointer",
    }),
    noOptionsMessage: (provided: any) => provided,
    option: (provided: any, { data, isDisabled, isFocused, isSelected }: any) => ({
      ...provided,
      padding: "6px 12px",
      color: isSelected ? theme.color.font_bold[2] : theme.color.font[2],
      fontSize: theme.fontSize.m,
      backgroundColor: isDisabled ? undefined : isSelected ? theme.color.primary[0] : isFocused ? theme.color_15.primary[0] : theme.color.main_bg[2],
      textAlign: center ? "center" : "unset",
      ...getIcon(theme.color.font[2], data.colour, data.icon),

      ":active": {
        ...provided[":active"],
        backgroundColor: isSelected ? theme.color_30.primary[0] : theme.color.main_bg[4],
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: theme.color.font[2],
      opacity: theme.opacity.placeholder,
      margin: 0,
    }),
    singleValue: (provided: any, { data }: any) => ({
      ...provided,
      backgroundColor: theme.color.input_bg[2],
      color: theme.color.font_bold[2],
      overflow: "visible",
      ...getIcon(theme.color.font[2], data.colour, data.icon),
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      padding: "2px 7px",
    }),
  };
};

export default ReactSelectStyles;
