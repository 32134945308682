import React, { FC } from "react";

const SuccessIcon: FC<any> = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461.072 461.072">
    <path
      fill={fill}
      fillOpacity={0.6}
      d="M230.535 0C103.22 0 0 103.215 0 230.535s103.22 230.537 230.535 230.537c127.33 0 230.537-103.217 230.537-230.537C461.072 103.215 357.861 0 230.535 0zm115.24 123.125l35.457 35.457-195.013 195.02-106.38-106.375 35.458-35.456 70.922 70.917z"
    />
    <path
      fill={fill}
      fillOpacity={0.9}
      d="M230.535 0C103.22 0 0 103.215 0 230.535s103.22 230.537 230.535 230.537c127.33 0 230.537-103.217 230.537-230.537C461.072 103.215 357.861 0 230.535 0zm0 31.098c110.15 0 199.44 89.292 199.44 199.437 0 110.145-89.286 199.44-199.44 199.44-110.141 0-199.437-89.295-199.437-199.44S120.394 31.098 230.535 31.098z"
    />
  </svg>
);

export default SuccessIcon;
