import axios, { CancelTokenSource } from "axios";
import { FC, useEffect, useState } from "react";
import { markAsCollected } from "../../services/pickupRequests";
import { checkDomain } from "../../util/checkDomain";
import errToStr from "../../util/errToStr";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";

const MarkAsCollectedModel: FC<any> = ({ id, onSuccess, modalOpen, setModalOpen }) => {
  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleSubmit = () => {
    setSubmitting(true);
    markAsCollected(source, id)
      .then(() => {
        if (onSuccess) onSuccess();
        setSubmittedMsg("Marked as Collected");
        setSubmittingErr("");
        setSubmitting(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) {
          setSubmittingErr("");
          setModalOpen(false);
        }
      }}
      title="Mark as Collected"
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Yes"
      body={
        <LoadingContainer loading={submitting}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <span>Are you sure you want to mark this {checkDomain("tracker", "keg", "tracker", "asset")} as collected?</span>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default MarkAsCollectedModel;
