import axios, { CancelTokenSource } from "axios";
import { renderToStaticMarkup } from "react-dom/server";
import { getAccount, getToken } from "../localStorage";

export const fetchAssetTypes = async (source: CancelTokenSource) => {
  const params = {
    organisationId: getAccount().organisationId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/assettypes",
    params,
  });
  return data;
};

export const putAssetTypes = async (source: CancelTokenSource, body: any, id: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + `/v1/assettypes/${id ? id : ""}`,
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};

export const postAssetTypes = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + `/v1/assettypes/`,
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};

export const deleteAssetTypes = async (source: CancelTokenSource, id: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "delete",
    url: process.env.REACT_APP_API_URL + `/v1/assettypes/${id ? id : ""}`,
  });

  return data;
};
