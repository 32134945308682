import React, { FC, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import { ClearButton, CalendarIconContainer } from "./styles";
import Cross from "../../svgs/Cross";
import CalendarIcon from "../../svgs/CalendarIcon";

const FlatpickrRangePicker: FC<any> = ({ value, onClose, ...props }) => {
  const flatpickrRef = useRef<any>(null);

  return (
    <div>
      <Flatpickr data-input ref={flatpickrRef} value={value} onClose={onClose} {...props} />
      {value && value.length > 0 ? (
        <ClearButton
          onClick={() => {
            if (flatpickrRef && flatpickrRef.current && flatpickrRef.current.flatpickr) {
              flatpickrRef.current.flatpickr.clear();
              onClose([]);
            }
          }}
        >
          <Cross />
        </ClearButton>
      ) : (
        <CalendarIconContainer
          onClick={() => {
            if (flatpickrRef && flatpickrRef.current && flatpickrRef.current.flatpickr) {
              flatpickrRef.current.flatpickr.open();
            }
          }}
        >
          <CalendarIcon />
        </CalendarIconContainer>
      )}
    </div>
  );
};

export default FlatpickrRangePicker;
