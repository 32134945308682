import React, { FC } from "react";
import styled from "styled-components";
import { humaniseHours } from "../../util/humaniseDurations";

const Span = styled<any>(`span`)`
    color: ${({ theme, hours, long, longer }) =>
      hours > (longer !== undefined ? longer : 720)
        ? theme.color.worse[2]
        : hours > (long !== undefined ? long : 504)
        ? theme.color.bad[2]
        : theme.color.font[2]};
    font-weight: ${({ hours, long }) => (hours > (long !== undefined ? long : 504) ? 500 : 200)};
  }
`;

const DurationSpan: FC<any> = ({ hours }) => {
  const text = humaniseHours(hours);
  return (
    <Span hours={hours} title={text}>
      {text}
    </Span>
  );
};

export default DurationSpan;
