import L from "leaflet";
import "leaflet-easybutton";
import "leaflet-easybutton/src/easy-button.css";
import "leaflet.gridlayer.googlemutant/dist/Leaflet.GoogleMutant";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet/dist/leaflet.css";
import moment from "moment";
import queryString from "query-string";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useLocation, useNavigate } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce";
import { printTemp } from "../../util/formatUnits";
import getMarkerIcon from "../../util/getMarkerIcon";
import { kegOrTracker, kegsOrTrackers } from "../../util/kegOrTracker";
import { INITIAL_VIEW_STATE } from "../../util/mapUtils";
import useWindowSize from "../../util/useWindowSize";
import LeafletStyling from "../GlobalStyles/leaflet";
import LoadingContainer from "../LoadingContainer";
import MapFilters from "../MapFilters";
import MapLegend from "../MapLegend";
import MapList from "../MapList";
import { MapContainer, MapLoading } from "./styles";

const initActiveLayer = (layer: any) => {
  const validLayers = ["Map", "Terrain", "Satellite", "Hybrid"];
  if (layer && validLayers.includes(layer)) return layer;
  else return "Map";
};

const initListOpen = (width: any, listOpen: any) => {
  if (listOpen != "false") {
    if (width >= 1150) {
      if (listOpen === "places") return "places";
      else return "sensors";
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const MainMap: FC<any> = ({
  sensors,
  places,
  sensorsRanges,
  placeRanges,
  sensorsErr,
  placesErr,
  sensorsLoading,
  placesLoading,
  sensorsLoaded,
  placesLoaded,
  sensorFilters,
  placeFilters,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { color, map_style, long_datetime } = useContext(ThemeContext);
  const { width } = useWindowSize();

  const mapRef = useRef<HTMLDivElement>(null);

  const [queryParams] = useState<any>(queryString.parse(location.search));
  const [map, setMap] = useState<any>(undefined);
  const [bounds, setBounds] = useState<any>(undefined);
  const [mapControl, setMapControl] = useState<any>(undefined);
  const [mapLayer, setMapLayer] = useState<any>(undefined);
  const [activeLayer, setActiveLayer] = useState<string>(initActiveLayer(queryParams.layer));
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [sensorCluster, setKegCluster] = useState<any>(undefined);
  const [placeCluster, setPlaceCluster] = useState<any>(undefined);
  const [markers, setMarkers] = useState<any>({ sensors: [], places: [] });
  const [listOpen, toggleList] = useState<any>(initListOpen(width, queryParams.list_open));
  const [locationNotSet, toggleLocationNotSet] = useState<boolean>(false);

  // used to update the query parameters of the map bounds if they haven't changed in 500 milliseconds
  const [boundsValue] = useDebounce(bounds, 500);

  const showAllBtn = useRef<any>(null);

  // On sensors and places loaded, initialise leaflet map
  useEffect(() => {
    if (sensorsLoaded && placesLoaded) {
      const newMap = L.map("map", {
        // @ts-ignore
        fullscreenControl: true,
        doubleClickZoom: true,
        worldCopyJump: true,
        attributionControl: false,
        maxBoundsViscosity: 1,
        maxBounds: new L.LatLngBounds(new L.LatLng(-90, -Infinity), new L.LatLng(90, Infinity)),
        center: [INITIAL_VIEW_STATE.latitude, INITIAL_VIEW_STATE.longitude],
        zoomControl: false,
        zoom: INITIAL_VIEW_STATE.zoom,
        minZoom: 1,
      });

      L.control
        .zoom({
          position: "topleft",
        })
        .addTo(newMap);

      setBounds(newMap.getBounds());

      createMapControl(newMap);

      newMap.on("baselayerchange", (event: any) => {
        // If the user changes the baselayer, update the state with new layer and name
        setActiveLayer(event.name);
        setMapLayer(event.layer);
      });

      newMap.on("dragend", (e: any) => {
        setBounds(newMap.getBounds());
      });

      newMap.on("zoomend", (e: any) => {
        setBounds(newMap.getBounds());
      });

      newMap.on("enterFullscreen", () => {
        setFullscreen(true);
      });

      newMap.on("exitFullscreen", () => {
        setFullscreen(false);
      });

      newMap.whenReady(() => {
        if (queryParams.ne_lat && queryParams.ne_lng && queryParams.sw_lat && queryParams.sw_lng) {
          const latLngBounds = L.latLngBounds([+queryParams.ne_lat, +queryParams.ne_lng], [+queryParams.sw_lat, +queryParams.sw_lng]);
          newMap.fitBounds(latLngBounds);
          setBounds(latLngBounds);
        } else if (sensors.length > 0 || places.length > 0) {
          const sensorBounds = new L.LatLngBounds(
            sensors.map((sensor: any) => {
              if (sensor.coordinates.latitude !== undefined && sensor.coordinates.longitude !== undefined) {
                return [sensor.coordinates.latitude, sensor.coordinates.longitude];
              }
            }, [])
          );
          const placeBounds = new L.LatLngBounds(
            places.map((place: any) => {
              if (place.coordinates.latitude !== undefined && place.coordinates.longitude !== undefined) {
                return [place.coordinates.latitude, place.coordinates.longitude];
              }
            }, [])
          );

          if (sensorBounds.isValid() && placeBounds.isValid()) {
            const bounds = sensorBounds.extend(placeBounds);
            newMap.fitBounds(bounds, {
              padding: [50, 50],
              maxZoom: 18,
            });
            setBounds(bounds);
          } else if (sensorBounds.isValid()) {
            newMap.fitBounds(sensorBounds, {
              padding: [50, 50],
              maxZoom: 18,
            });
            setBounds(sensorBounds);
          } else if (placeBounds.isValid()) {
            newMap.fitBounds(placeBounds, {
              padding: [50, 50],
              maxZoom: 18,
            });
            setBounds(placeBounds);
          } else {
            toggleLocationNotSet(true);
          }
        } else {
          toggleLocationNotSet(true);
        }
      });

      setMap(newMap);
    }
  }, [sensorsLoaded, placesLoaded]);

  // If the user has no trackers/places/query params then get the user's location and update the map view
  useEffect(() => {
    if (map && locationNotSet) {
      navigator.geolocation.getCurrentPosition((pos: any) => {
        const { coords } = pos;
        map.setView(new L.LatLng(coords.latitude, coords.longitude), 14);
      });
    }
  }, [map, locationNotSet]);

  // Updates the query string when the boundsValue changes (which is a debounce value)
  useEffect(() => {
    if (boundsValue) updateQueryString(boundsValue, activeLayer, listOpen);
  }, [boundsValue, activeLayer, listOpen]);

  useEffect(() => {
    if (map) {
      updateSensors(map, sensors);
    }
  }, [map, sensors]);

  useEffect(() => {
    if (map) {
      updatePlaces(map, places);
    }
  }, [map, places]);

  // On theme change, update the map's base layers and layer controls
  useEffect(() => {
    if (map) {
      createMapControl(map);
    }
  }, [map_style]);

  // Checks if the map container size changed and updates the map if so
  useEffect(() => {
    const mapDiv = document.getElementById("map");

    if (!mapDiv || !map) return;

    const observer = new ResizeObserver(() => {
      if (map) {
        map.invalidateSize();
      }
    });

    observer.observe(mapDiv);

    return () => {
      observer.unobserve(mapDiv);
      observer.disconnect();
    };
  }, [map]);

  useEffect(() => {
    if (map) {
      // Remove the show all button if it exists
      if (showAllBtn.current) {
        map.removeControl(showAllBtn.current);
      }

      if (markers.sensors.length > 0 || markers.places.length > 0) {
        // create button and add to map
        const newShowAllBtn = L.easyButton({
          position: "topleft",
          leafletClasses: true,
          states: [
            {
              stateName: "showAll",
              onClick: () => {
                // push all sensor and place markers into list, add to group, then fit map bounds to show all
                const markerList = [];

                for (let i = 0; i < markers.sensors.length; i++) {
                  const row = markers.sensors[i];
                  if (row.lat !== undefined && row.lng !== undefined) {
                    const marker = L.marker(new L.LatLng(row.lat, row.lng));
                    markerList.push(marker);
                  }
                }

                for (let i = 0; i < markers.places.length; i++) {
                  const row = markers.places[i];
                  if (row.lat !== undefined && row.lng !== undefined) {
                    const marker = L.marker(new L.LatLng(row.lat, row.lng));
                    markerList.push(marker);
                  }
                }

                // @ts-ignore
                const group = new L.featureGroup(markerList);
                map.fitBounds(group.getBounds(), {
                  padding: [50, 50],
                  maxZoom: 18,
                });
                setBounds(group.getBounds());
              },
              title: "Show all",
              icon: `<div class="show-all-button"></div>`,
            },
          ],
        }).addTo(map);

        // store reference to button so it can be removed/updated
        showAllBtn.current = newShowAllBtn;
      } else {
        // remove reference to button to show it doesn't exist
        showAllBtn.current = null;
      }
    }
  }, [map, markers]);

  // Triggered on moveend and zoomend events to update the query params and replace the history object
  const updateQueryString = (newBounds: any, newActiveLayer: any, newListOpen: any) => {
    const parsed = queryString.parse(location.search);

    const query = {
      sw_lat: newBounds._southWest.lat,
      sw_lng: newBounds._southWest.lng,
      ne_lat: newBounds._northEast.lat,
      ne_lng: newBounds._northEast.lng,
      layer: newActiveLayer,
      list_open: newListOpen,
    };

    const newQuery = {
      ...parsed,
      ...query,
    };

    const stringified = queryString.stringify(newQuery);

    window.history.replaceState(null, "", `${location.pathname}?${stringified}`);
  };

  const updateSensors = (map: any, sensors: any) => {
    // Remove the sensor cluster layer from the map if it exists
    if (map && sensorCluster) {
      sensorCluster.clearLayers();
      map.removeLayer(sensorCluster);
    }

    const markers: any = [];

    if (sensors.length > 0) {
      const sensorAccuracyGroup = L.layerGroup().addTo(map);

      const newKegCluster = L.markerClusterGroup({
        spiderfyDistanceMultiplier: 1.5,
        spiderLegPolylineOptions: {
          weight: 1,
          color: color.font[2],
          opacity: 0.5,
        },
        polygonOptions: {
          color: color.primary[2],
          fillColor: color.primary[2],
        },
        iconCreateFunction: (cluster: any) => {
          return getMarkerIcon(color, cluster.getChildCount(), "sensorCluster");
        },
      });

      for (let i = 0; i < sensors.length; i++) {
        const row = sensors[i];
        if (row.coordinates.latitude !== undefined && row.coordinates.longitude !== undefined) {
          const marker = L.marker(new L.LatLng(row.coordinates.latitude, row.coordinates.longitude), {
            title: row.id,
            icon: getMarkerIcon(color, 1, "sensor"),
          });

          const accuracy = L.circle([row.coordinates.latitude, row.coordinates.longitude], {
            radius: row.locationAccuracy,
            color: color.primary[2],
          });

          marker.on("click", () => {
            sensorAccuracyGroup.clearLayers();
            sensorAccuracyGroup.addLayer(accuracy);
          });

          marker.on("dblclick", () => {
            map.fitBounds(accuracy.getBounds());
            setBounds(accuracy.getBounds());
          });

          marker.bindPopup(createSensorPopup(row));
          newKegCluster.addLayer(marker);
          markers.push({
            lat: row.coordinates.latitude,
            lng: row.coordinates.longitude,
          });
        }
      }

      map.on("click", () => {
        sensorAccuracyGroup.clearLayers();
      });

      map.addLayer(newKegCluster);
      setKegCluster(newKegCluster);
    }

    setMarkers((prev: any) => ({ ...prev, sensors: markers }));
  };

  const updatePlaces = (map: any, places: any) => {
    // Remove the place cluster layer from the map if it exists
    if (map && placeCluster) {
      map.removeLayer(placeCluster);
    }

    const markers: any = [];

    if (places.length > 0) {
      const placeRadiusGroup = L.layerGroup().addTo(map);

      const newPlaceCluster = L.markerClusterGroup({
        spiderfyDistanceMultiplier: 1.5,
        spiderLegPolylineOptions: {
          weight: 1,
          color: color.font[2],
          opacity: 0.5,
        },
        polygonOptions: {
          color: color.secondary[2],
          fillColor: color.secondary[2],
        },
        iconCreateFunction: (cluster: any) => {
          return getMarkerIcon(color, cluster.getChildCount(), "placeCluster");
        },
      });

      for (let i = 0; i < places.length; i++) {
        const row = places[i];
        if (row.coordinates.latitude !== undefined && row.coordinates.longitude !== undefined) {
          const marker = L.marker(new L.LatLng(row.coordinates.latitude, row.coordinates.longitude), {
            title: row.name,
            icon: getMarkerIcon(color, 1, "place"),
            //@ts-ignore
            sensors: row.sensors,
          });

          const placeRadius = L.circle([row.coordinates.latitude, row.coordinates.longitude], {
            radius: row.radius,
            color: color.secondary[2],
          });
          marker.on("click", () => {
            placeRadiusGroup.clearLayers();
            placeRadiusGroup.addLayer(placeRadius);
          });
          marker.on("dblclick", () => {
            map.fitBounds(placeRadius.getBounds());
            setBounds(placeRadius.getBounds());
          });

          marker.bindPopup(createPlacePopup(row));
          newPlaceCluster.addLayer(marker);
          markers.push({
            lat: row.coordinates.latitude,
            lng: row.coordinates.longitude,
          });
        }
      }

      map.on("click", () => {
        placeRadiusGroup.clearLayers();
      });

      map.addLayer(newPlaceCluster);
      setPlaceCluster(newPlaceCluster);
    }

    setMarkers((prev: any) => ({ ...prev, places: markers }));
  };

  const createSensorPopup = (sensor: any) => {
    const container = L.DomUtil.create("div");
    const trackerId = L.DomUtil.create("span", "popup-title-link");
    const assetId = L.DomUtil.create("span", "popup-text");
    const assetType = L.DomUtil.create("span", "popup-text");
    const trackerTags = L.DomUtil.create("span", "popup-text");
    const lastSeen = L.DomUtil.create("span", "popup-text");
    const temperature = L.DomUtil.create("span", "popup-text");
    const url = kegOrTracker("kegs", "trackers");

    trackerId.addEventListener("mouseup", (e: any) => {
      if (e.which === 2) window.open(`/${url}/${sensor.id}`, "_blank");
      else navigate(`/${url}/${sensor.id}`);
    });

    trackerId.innerHTML = sensor.sensorName ? `${sensor.sensorName} (${sensor.id})` : sensor.id;
    assetId.innerHTML = sensor.assetId ? `Asset ID: ${sensor.assetId}` : "";
    assetType.innerHTML = sensor.assetTypeName ? `Asset Type: ${sensor.assetTypeName}` : "";
    trackerTags.innerHTML =
      sensor.trackerTags && sensor.trackerTags.length > 0
        ? `${kegsOrTrackers("Kegs Tags", "Tracker Tags")}: ${sensor.trackerTags.map((tag: any) => tag.name).join(", ")}`
        : "";
    lastSeen.innerHTML = `Last Seen: ${renderToStaticMarkup(
      <ReactTimeago live={false} date={sensor.lastSampleDate * 1000} title={moment.unix(sensor.lastSampleDate).format(long_datetime)} />
    )}`;
    temperature.innerHTML = sensor.temperature !== undefined ? `Temperature: ${printTemp(sensor.temperature)}` : ``;

    container.appendChild(trackerId);
    container.appendChild(assetId);
    container.appendChild(assetType);
    container.appendChild(trackerTags);
    container.appendChild(lastSeen);
    container.appendChild(temperature);

    return L.popup().setContent(container);
  };

  const createPlacePopup = (place: any) => {
    const container = L.DomUtil.create("div");
    const name = L.DomUtil.create("span", "popup-title-link");
    const placeGroup = L.DomUtil.create("span", "popup-text");
    const sensors = L.DomUtil.create("span", "popup-text");

    name.addEventListener("mouseup", (e: any) => {
      if (e.which === 2) window.open(`/places/${place.id}`, "_blank");
      else navigate(`/places/${place.id}`);
    });

    name.innerHTML = place.name;
    placeGroup.innerHTML = place.placeGroup ? `Place Type: ${place.placeGroup}` : "";
    sensors.innerHTML = `${kegsOrTrackers("Kegs", "Trackers")}: ${place.sensors}`;

    container.appendChild(name);
    container.appendChild(placeGroup);
    container.appendChild(sensors);

    return L.popup().setContent(container);
  };

  const createMapControl = (currentMap: any) => {
    const roadLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "roadmap",
      styles: map_style,
    });

    const terrainLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "terrain",
      styles: map_style,
    });

    const satelliteLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "satellite",
    });

    const hybridLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "hybrid",
    });

    const layers: any = {
      Map: roadLayer,
      Terrain: terrainLayer,
      Satellite: satelliteLayer,
      Hybrid: hybridLayer,
    };

    const control = L.control.layers(
      layers,
      {},
      // @ts-ignore
      { sortLayers: true, position: "topright" }
    );

    // Remove old baselayer/control from map if set in state
    if (mapControl) currentMap.removeControl(mapControl);
    if (mapLayer) currentMap.removeLayer(mapLayer);

    // Add new baselayer/control to map
    layers[activeLayer].addTo(currentMap);
    control.addTo(currentMap);

    // Store new baselayer/control in state
    setMapLayer(layers[activeLayer]);
    setMapControl(control);
  };

  if (sensorsErr || placesErr) {
    return <LoadingContainer err={sensorsErr || placesErr}></LoadingContainer>;
  }

  return (
    <LoadingContainer err={sensorsErr || placesErr}>
      <LeafletStyling fullscreen={fullscreen} />
      <MapContainer id="map" ref={mapRef} listOpen={listOpen}></MapContainer>
      {(sensorsLoading || placesLoading) && <MapLoading>Loading...</MapLoading>}
      {map && (
        <>
          <MapFilters sensorsRanges={sensorsRanges} placeRanges={placeRanges} sensorFilters={sensorFilters} placeFilters={placeFilters} />
          <MapList map={map} sensors={sensors} places={places} bounds={boundsValue} toggleList={toggleList} listOpen={listOpen} />
          <MapLegend sensors={sensors.length} />
        </>
      )}
    </LoadingContainer>
  );
};

export default MainMap;
