import React, { FC, useEffect, useState } from "react";
import { Bar, DashboardContainer, DashboardElement } from "./styles";
import { PageContainer } from "../PageStyles";
import moment from "moment";
import DateRangeList from "../DateRangeList";
import getParameterByName from "../../util/getParamByName";
import { useLocation } from "react-router-dom";
import ReadyToFill from "./ReadyToFill";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import FleetSize from "./FleetSize";
import FreshnessOnTap from "./FreshnessOnTap";
import ReadyForCollection from "./ReadyForCollection";
import AverageFreshnessOnTap from "./AverageFreshnessOnTap";
import AverageTemperatureOnTap from "./AverageTemperatureOnTap";
import TrackerDurations from "./TrackerDurations";
import TrackerDistribution from "./TrackerDistribution";
import TrackerDistributionHistory from "./TrackerDistributionHistory";
import AverageTurnDuration from "./AverageTurnDuration";
import AverageTimeToTap from "./AverageTimeToTap";
import { beerLayout, techLayout } from "./layouts";
import { isBinaryBeer } from "../../util/checkDomain";

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard: FC<any> = () => {
  const location = useLocation();

  // Gets details from URL query parameters if available to set date range
  const startDateParam = getParameterByName("start", location.search);
  const endDateParam = getParameterByName("end", location.search);
  const dateLabelParam = getParameterByName("label", location.search);
  const isNowParam = getParameterByName("is-now", location.search);

  const [filterDates, setFilterDates] = useState<any>({
    start: startDateParam !== null ? moment.unix(+startDateParam) : moment().subtract(3, "months"),
    end: endDateParam !== null ? moment.unix(+endDateParam) : moment(),
    dateLabel: startDateParam !== null || endDateParam !== null ? dateLabelParam : "Last 3 months",
    isNow: isNowParam !== null ? isNowParam === "true" : true,
  });

  const [layouts, setLayouts] = useState<any>(isBinaryBeer() ? beerLayout : techLayout);

  useEffect(() => {
    const startDateParam = getParameterByName("start", location.search);
    const endDateParam = getParameterByName("end", location.search);
    const dateLabelParam = getParameterByName("label", location.search);
    const isNowParam = getParameterByName("is-now", location.search);

    setFilterDates({
      start: startDateParam !== null ? moment.unix(+startDateParam) : moment().subtract(3, "months"),
      end: endDateParam !== null ? moment.unix(+endDateParam) : moment(),
      dateLabel: startDateParam !== null || endDateParam !== null ? dateLabelParam : "Last 3 months",
      isNow: isNowParam !== null ? isNowParam === "true" : true,
    });
  }, [location]);

  const onLayoutChange = (layout: any, layouts: any) => {
    setLayouts(layouts);
  };

  return (
    <>
      <Bar>
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <DateRangeList filterDates={filterDates} setFilterDates={setFilterDates} maxDate={moment()} enableAllTime={true} enableTime={false} />
        </div>
      </Bar>
      <PageContainer top="57px">
        <DashboardContainer>
          <ResponsiveGridLayout
            className="layout"
            breakpoints={{ lg: 1000, md: 600, sm: 480 }}
            cols={{ lg: 12, md: 6, sm: 2 }}
            rowHeight={10}
            layouts={layouts}
            onLayoutChange={(layout, newLayouts) => onLayoutChange(layout, newLayouts)}
            isDraggable={false}
            isResizable={false}
          >
            {isBinaryBeer() && (
              <DashboardElement key="1" data-grid={{ w: 2, h: 3, x: 0, y: 0, minW: 1, minH: 4 }}>
                <AverageFreshnessOnTap filterDates={filterDates} />
              </DashboardElement>
            )}
            {isBinaryBeer() && (
              <DashboardElement key="2" data-grid={{ w: 2, h: 3, x: 2, y: 0, minW: 1, minH: 4 }}>
                <ReadyToFill filterDates={filterDates} />
              </DashboardElement>
            )}
            <DashboardElement key="3" data-grid={{ w: 2, h: 3, x: 4, y: 0, minW: 1, minH: 4 }}>
              <ReadyForCollection filterDates={filterDates} />
            </DashboardElement>
            {isBinaryBeer() && (
              <DashboardElement key="4" data-grid={{ w: 2, h: 3, x: 4, y: 0, minW: 1, minH: 4 }}>
                <AverageTemperatureOnTap filterDates={filterDates} />
              </DashboardElement>
            )}
            {isBinaryBeer() && (
              <DashboardElement key="5" data-grid={{ w: 2, h: 3, x: 0, y: 0, minW: 1, minH: 4 }}>
                <AverageTimeToTap filterDates={filterDates} />
              </DashboardElement>
            )}
            {isBinaryBeer() && (
              <DashboardElement key="6" data-grid={{ w: 2, h: 3, x: 0, y: 0, minW: 1, minH: 4 }}>
                <AverageTurnDuration filterDates={filterDates} />
              </DashboardElement>
            )}
            <DashboardElement key="7" data-grid={{ w: 2, h: 3, x: 0, y: 0, minW: 1, minH: 4 }}>
              <FleetSize filterDates={filterDates} />
            </DashboardElement>
            {isBinaryBeer() && (
              <DashboardElement key="8" data-grid={{ w: 2, h: 3, x: 8, y: 0, minW: 1, minH: 4 }}>
                <FreshnessOnTap filterDates={filterDates} />
              </DashboardElement>
            )}
            <DashboardElement key="9" data-grid={{ w: 2, h: 3, x: 8, y: 0, minW: 1, minH: 4 }}>
              <TrackerDistribution filterDates={filterDates} />
            </DashboardElement>
            <DashboardElement key="10" data-grid={{ w: 2, h: 3, x: 6, y: 0, minW: 1, minH: 4 }}>
              <TrackerDurations filterDates={filterDates} />
            </DashboardElement>
            <DashboardElement key="11" data-grid={{ w: 2, h: 3, x: 10, y: 0, minW: 1, minH: 4 }}>
              <TrackerDistributionHistory filterDates={filterDates} />
            </DashboardElement>
          </ResponsiveGridLayout>
        </DashboardContainer>
      </PageContainer>
    </>
  );
};

export default Dashboard;
