import { stringify } from "csv-stringify/browser/esm/sync";
import React, { FC, forwardRef, useContext, useImperativeHandle, useRef } from "react";
import { IconContext } from "react-icons";
import { TbCopy, TbDownload } from "react-icons/tb";
import ReactTable from "react-table-6";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import copyToClipboard from "../../util/copyToClipboard";
import downloadFile from "../../util/downloadFile";
import { DefaultIconBtn } from "../IconButtons";
import Tooltip from "../Tooltip";
import { NoRowsContainer } from "./styles";

export const NoDataComponent: FC<any> = ({ children, style }) => {
  return children === "" ? null : <NoRowsContainer style={style}>{children}</NoRowsContainer>;
};

export const CsvButtonsComponent: FC<any> = ({ data, formatCsv, formatCsvParams, fileName }) => {
  const { color } = useContext(ThemeContext);

  if (data.length > 0) {
    return (
      <div style={{ display: "flex" }}>
        <Tooltip trigger="mouseenter" content="Download CSV">
          <DefaultIconBtn
            onClick={() =>
              downloadFile(
                stringify(formatCsv(...formatCsvParams), {
                  quoted: true,
                  quoted_string: true,
                }),
                "text/csv;charset=utf-8",
                fileName
              )
            }
          >
            <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
              <TbDownload />
            </IconContext.Provider>
          </DefaultIconBtn>
        </Tooltip>
        {document.queryCommandSupported("copy") && (
          <Tooltip trigger="mouseenter" content="Copy CSV">
            <DefaultIconBtn
              onClick={() => {
                copyToClipboard(
                  stringify(formatCsv(...formatCsvParams), {
                    quoted: true,
                    quoted_string: true,
                  })
                );
                toast.info("Copied to Clipboard");
              }}
            >
              <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                <TbCopy />
              </IconContext.Provider>
            </DefaultIconBtn>
          </Tooltip>
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
};

const Table: React.ForwardRefRenderFunction<any, any> = (
  { data, columns, defaultSorted, showPagination = false, defaultPageSize = 25, style, filterable, loading = false, error, ...rest },
  ref
) => {
  const tableRef: any = useRef(null);
  useImperativeHandle(ref, () => tableRef.current);

  return (
    <>
      <ReactTable
        filterable={filterable}
        ref={tableRef}
        noDataText={loading ? "" : "No rows found"}
        NoDataComponent={NoDataComponent}
        data={data}
        columns={columns}
        defaultSorted={defaultSorted}
        showPagination={data.length > defaultPageSize ? true : showPagination}
        defaultPageSize={defaultPageSize}
        showPageJump={false}
        showPageSizeOptions={false}
        minRows={1}
        sortable={true}
        multiSort={true}
        resizable={true}
        style={style}
        className={error ? "ReactTableError" : "ReactTable"}
        {...rest}
      />
    </>
  );
};
export default forwardRef(Table);
