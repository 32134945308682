import React, { FC } from "react";

const Legend: FC<any> = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path fill="none" d="M-1-1h582v402H-1z" />
    <g>
      <path
        fill={fill}
        fillRule="evenodd"
        stroke="null"
        d="M.182 10a9.818 9.818 0 1 0 19.636 0A9.818 9.818 0 1 0 .182 10m8.182-4.91a1.636 1.636 0 1 0 3.272 0 1.636 1.636 0 1 0-3.272 0m0 4.91a1.636 1.636 0 1 1 3.272 0v4.91a1.636 1.636 0 1 1-3.272 0"
      />
    </g>
  </svg>
);

export default Legend;
