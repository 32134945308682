import React, { FC } from "react";

const SvgComponent: FC<any> = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101.6 135.467" {...props}>
      {/* <path
        fill={props.fill}
        fillOpacity={0.8}
        d="M50.8 0C22.784 0 0 22.784 0 50.8c0 35.213 45.855 81.475 47.803 83.43a4.252 4.252 0 005.994 0c1.948-1.955 47.803-48.217 47.803-83.43C101.6 22.784 78.816 0 50.8 0zm-.091 27.046a23.073 23.073 0 01.091 0A23.073 23.073 0 0173.873 50.12 23.073 23.073 0 0150.8 73.193a23.073 23.073 0 01-23.073-23.074 23.073 23.073 0 0122.982-23.073z"
      /> */}
      <g transform="matrix(.26458 0 0 .26458 -8.51 16.617)">
        <path
          fill={props.fill}
          fillOpacity={0.8}
          d="M224.167-62.804c-105.888 0-192 86.112-192 192 0 133.088 173.312 307.936 180.672 315.328 6.268 6.23 16.389 6.23 22.656 0 7.36-7.392 180.672-182.24 180.672-315.328 0-105.888-86.112-192-192-192zm-.345 95.78a87.206 87.206 0 01.345 0 87.206 87.206 0 0187.205 87.206 87.206 87.206 0 01-87.205 87.205 87.206 87.206 0 01-87.205-87.205 87.206 87.206 0 0186.86-87.205z"
        />
        <circle fill="#fff" fillOpacity={0.8} cx={224.167} cy={120.182} r={87.206} fillRule="evenodd" />
      </g>
    </svg>
  );
};

export default SvgComponent;
