import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import QrScanner from "qr-scanner";
import queryString from "query-string";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { ThemeContext } from "styled-components";
import { postActivateSensor } from "../../../services/activateSensors";
import { fetchAutoComplete } from "../../../services/autoComplete";
import { getDefaults, saveDefaults } from "../../../services/localStorage";
import { getTrackerActivationDetail } from "../../../services/trackerActivationDetail";
import ErrIcon from "../../../svgs/ErrIcon";
import SuccessIcon from "../../../svgs/SuccessIcon";
import { isSuper } from "../../../util/checkRole";
import errToStr from "../../../util/errToStr";
import { exists } from "../../../util/formValidations";
import isEmpty from "../../../util/isEmpty";
import isMobileDevice from "../../../util/isMobileDevice";
import { kegOrTracker } from "../../../util/kegOrTracker";
import Bold from "../../Bold";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../../Buttons";
import EditTrackerModal from "../../EditTrackerModal";
import { FormContainer, FormError, FormInput, FormInputContainer } from "../../FormComponents";
import LoadingContainer from "../../LoadingContainer";
import { SubmitModal } from "../../Modal";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import { AsyncSelect } from "../../Select";
import UploadPhotoModal from "../../UploadPhotoModal";

const Activate: FC<any> = () => {
  const location = useLocation();

  const { color, long_datetime } = useContext(ThemeContext);

  const inputRef = useRef<any>(null);

  const videoRefCallback = useCallback((node: any) => {
    if (node) {
      setVideoRef(node);
    }
  }, []);

  const [videoRef, setVideoRef] = useState<any>(undefined);

  const [queryParams] = useState<any>(queryString.parse(location.search));

  const [formData, setFormData] = useState<any>({
    sensorId: queryParams.trackerId ? queryParams.trackerId : "",
    organisation: undefined,
    phoneLatitude: undefined,
    phoneLongitude: undefined,
    phoneAccuracy: undefined,
  });
  const [formErrors, setFormErrors] = useState<any>({});
  const [settings, setSettings] = useState<any>({}); // (getDefaults().defaultSensorSettings || {})

  const [codeReader, setCodeReader] = useState<any>(undefined);

  const [activateStep, setActivateStep] = useState<number>(0);

  const [trackerDetails, setTrackerDetails] = useState<any>(undefined);
  const [trackerDetailsErr, setTrackerDetailsErr] = useState<string>("");
  const [trackerDetailsLoading, setTrackerDetailsLoading] = useState<boolean>(false);

  const [activateSuccess, setActivateSuccess] = useState<boolean>(false);
  const [activateErr, setActivateErr] = useState<string>("");
  const [activateLoading, setActivateLoading] = useState<boolean>(false);

  const [editPhotoSuccess, setEditPhotoSuccess] = useState<boolean>(false);
  const [editPhotoErr, setEditPhotoErr] = useState<string>("");

  const [editSettingsSuccess, setEditSettingsSuccess] = useState<boolean>(false);
  const [editSettingsErr, setEditSettingsErr] = useState<string>("");

  const [activateModalOpen, setActivateModalOpen] = useState<boolean>(false);
  const [uploadPhotoModalOpen, setUploadPhotoModalOpen] = useState<boolean>(false);
  const [editSettingsModalOpen, setEditSettingsModalOpen] = useState<boolean>(false);

  const [inputPhoto, setInputPhoto] = useState<any>(undefined);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, []);

  // Trigger activation on load if trackerId query param provided and not super user
  useEffect(() => {
    if (queryParams.trackerId) {
      handleManualSubmit();
    }
  }, [queryParams]);

  // Initialise codeReader and stop/destroy codeReader and stop camera access on clean up
  useEffect(() => {
    let codeReader: any = undefined;

    if (videoRef) {
      codeReader = new QrScanner(videoRef, (result) => {
        if (result) {
          let code = result;
          if (code.includes("10b.in/")) {
            code = code.split("10b.in/")[1];
          }
          setFormData((prev: any) => ({ ...prev, sensorId: code }));
          setFormErrors((prev: any) => ({
            ...prev,
            sensorId: undefined,
          }));
          setActivateModalOpen(true);
        }
      });
      codeReader.setInversionMode("both");
      setCodeReader(codeReader);
    }

    return () => {
      if (codeReader) {
        codeReader.stop();
        codeReader.destroy();
      }
      if (videoRef && videoRef.srcObject) {
        videoRef.srcObject.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
    };
  }, [videoRef]);

  // If user is on a mobile device, get their location to update the tracker's location
  useEffect(() => {
    if (isMobileDevice()) {
      navigator.geolocation.getCurrentPosition((pos: any) => {
        const { coords } = pos;

        setFormData((prev: any) => ({
          ...prev,
          phoneLatitude: coords.latitude,
          phoneLongitude: coords.longitude,
          phoneAccuracy: coords.accuracy,
        }));
      });
    }
  }, []);

  // Stop code reader if super user and org isn't selected
  useEffect(() => {
    if (codeReader) {
      if (isSuper() && !formData.organisation) {
        codeReader.stop();
        if (videoRef && videoRef.srcObject) {
          videoRef.srcObject.getTracks().forEach((track: any) => {
            track.stop();
          });
        }
      } else {
        codeReader.start();
      }
    }
  }, [codeReader, formData.organisation, videoRef]);

  // If organisation is changed, set asset type and tracker tags to undefined so the select input is empty since organisations most
  // likely won't share asset types and tracker tags and we don't want to accidentally create them in a different organisation
  useEffect(() => {
    if (!isEmpty(settings)) {
      setSettings((prev: any) => ({
        ...prev,
        assetType: null,
        trackerTags: [],
      }));
    }
  }, [formData.organisation]);

  // Fetch basic tracker details on scan before activating, will also return error message if trackerId is not valid
  useEffect(() => {
    fetchTrackerActivationDetails();
  }, [activateModalOpen]);

  const fetchTrackerActivationDetails = () => {
    if (activateModalOpen && formData.sensorId) {
      setTrackerDetailsLoading(true);
      getTrackerActivationDetail(source, formData.sensorId)
        .then((response) => {
          setTrackerDetails(response);
          setTrackerDetailsLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setTrackerDetailsErr(errToStr(err));
            setTrackerDetailsLoading(false);
          }
        });
    }
  };

  const handleActivate = () => {
    if (formData.sensorId) {
      const activateBody: any = {
        sensorId: formData.sensorId ? formData.sensorId.trim() : "",
        phoneLatitude: formData.phoneLatitude,
        phoneLongitude: formData.phoneLongitude,
        phoneAccuracy: Math.round(formData.accuracy) || null,
      };

      if (formData.organisation) activateBody.organisationId = formData.organisation.value;

      setActivateLoading(true);
      postActivateSensor(source, activateBody)
        .then(() => {
          setActivateSuccess(true);
          setActivateStep(1);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setActivateErr(errToStr(err));
            setActivateLoading(false);
          }
        });
    }
  };

  const handleManualSubmit = () => {
    const valid = exists("sensorId", formData.sensorId, setFormErrors);

    if (valid) {
      setActivateModalOpen(true);
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setFormErrors((prev: any) => ({
      ...prev,
      [e.target.name]: undefined,
    }));
  };

  const handleSelectChange = (selected: any) => {
    setFormData((prev: any) => ({
      ...prev,
      organisation: selected,
    }));
    setFormErrors((prev: any) => ({
      ...prev,
      organisation: undefined,
    }));
    saveDefaults({ ...getDefaults(), organisation: selected });
  };

  // Loads the organisation options for the organisation select input
  // Once loaded, checks if it contains the default organisation.
  // If it does, update the selected organisation to the default.
  const loadOrgs = (inputValue: string, callback: any) => {
    fetchAutoComplete("organisations", inputValue).then((response) => {
      callback(response);
      if (getDefaults().organisation && getDefaults().organisation.value) {
        if (response.some((organisation: any) => organisation.value === getDefaults().organisation.value)) {
          setFormData((prev: any) => ({
            ...prev,
            organisation: getDefaults().organisation,
          }));
        }
      }
    });
  };

  const handleActivateModalClose = () => {
    setActivateStep(0);
    setTrackerDetails(undefined);
    setTrackerDetailsErr("");
    setTrackerDetailsLoading(false);
    setActivateSuccess(false);
    setActivateErr("");
    setActivateLoading(false);
    setActivateModalOpen(false);
  };

  const handleFileChange = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setInputPhoto(e.target.files[0]);
    if (e.target.files[0]) setUploadPhotoModalOpen(true);
    e.target.value = "";
  };

  const handleRefresh = () => {
    fetchTrackerActivationDetails();
  };

  return (
    <>
      <PageBreadcrumbs prevRoutes={[{ slug: "/scanner", title: "Scanner" }]} currRoute="Activate Tracker" />
      <PageContainer top="40px">
        <FormContainer>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            {isSuper() && (
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <label>Organisation</label>
                <AsyncSelect
                  name="organisation"
                  defaultOptions={true}
                  isClearable={true}
                  isSearchable={true}
                  isError={formErrors.organisation}
                  value={formData.organisation || ""}
                  loadOptions={(inputValue: any, callback: any) => loadOrgs(inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                />
              </FormInputContainer>
            )}
            <div
              style={{
                display: !isSuper() || !!formData.organisation ? "block" : "none",
              }}
            >
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <label>Scan Tracker</label>
                <div>
                  <video
                    id="video"
                    ref={videoRefCallback}
                    style={{
                      display: "inline-block",
                      width: "100%",
                      border: "1px solid gray",
                      borderRadius: "3px",
                    }}
                  />
                </div>
              </FormInputContainer>
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <label>Tracker ID</label>
                <FormInput
                  type="text"
                  name="sensorId"
                  value={formData.sensorId}
                  error={formErrors.sensorId}
                  onChange={handleChange}
                  style={{ height: "40px" }}
                />
                <FormError error={formErrors.sensorId}>{formErrors.sensorId}</FormError>
              </FormInputContainer>
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <PrimaryBtn width="100%" onClick={handleManualSubmit}>
                  Activate
                </PrimaryBtn>
              </FormInputContainer>
            </div>
          </form>
        </FormContainer>
      </PageContainer>
      {activateModalOpen && (
        <SubmitModal
          isOpen={activateModalOpen}
          loading={trackerDetailsLoading}
          onClose={handleActivateModalClose}
          error={trackerDetailsErr || activateErr}
          title={activateStep === 0 ? "Activate Tracker" : activateStep === 1 ? "Upload Photo" : activateStep === 2 ? "Edit Settings" : "Activate Tracker"}
          body={
            activateStep === 0 ? ( // Confirm activation details
              <LoadingContainer loading={trackerDetailsLoading || activateLoading}>
                {trackerDetails !== undefined && (
                  <>
                    {trackerDetails.activated && <p style={{ marginBottom: "16px" }}>This tracker is already activated to this organisation.</p>}
                    <p>
                      Tracker ID:{" "}
                      <Link to={`/${kegOrTracker("kegs", "trackers")}/${formData.sensorId}`}>
                        <Bold>{formData.sensorId}</Bold>
                      </Link>
                    </p>
                    {trackerDetails.productName && (
                      <p>
                        Product Name: <Bold>{trackerDetails.productName}</Bold>
                      </p>
                    )}
                    {trackerDetails.lastUploadDateUnix && (
                      <p>
                        Last Upload Date:{" "}
                        <Bold>
                          <ReactTimeago
                            live={false}
                            date={trackerDetails.lastUploadDateUnix * 1000}
                            title={moment.unix(trackerDetails.lastUploadDateUnix).format(long_datetime)}
                          />
                        </Bold>
                      </p>
                    )}
                    {trackerDetails.nextUploadDateUnix && (
                      <p>
                        Next Upload Date:{" "}
                        <Bold style={{ color: trackerDetails.nextUploadDateUnix * 1000 >= Date.now() ? color.success[2] : color.danger[2] }}>
                          <ReactTimeago
                            live={false}
                            date={trackerDetails.nextUploadDateUnix * 1000}
                            title={moment.unix(trackerDetails.nextUploadDateUnix).format(long_datetime)}
                          />
                        </Bold>
                      </p>
                    )}
                    {formData.organisation && (
                      <p>
                        Organisation: <Bold>{formData.organisation.label}</Bold>
                      </p>
                    )}
                  </>
                )}
              </LoadingContainer>
            ) : activateStep === 1 ? ( // Upload photo step
              <>
                {activateSuccess && (
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ width: "50px", height: "50px", margin: "20px 20px 20px 0" }}>
                      <SuccessIcon fill={color.success[2]} />
                    </div>
                    <div>
                      <p>Tracker activated successfully.</p>
                      <p>
                        Would you like to upload a photo for tracker{" "}
                        <Link to={`/${kegOrTracker("kegs", "trackers")}/${formData.sensorId}`}>{formData.sensorId}</Link>?
                      </p>
                    </div>
                  </div>
                )}
                <input type="file" ref={inputRef} onChange={handleFileChange} style={{ display: "none" }} accept="image/png, image/jpeg" />
              </>
            ) : activateStep === 2 ? ( // Edit settings step
              <LoadingContainer loading={trackerDetailsLoading} style={{ margin: "auto 0" }}>
                {editPhotoSuccess && !editPhotoErr && (
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ width: "50px", height: "50px", margin: "20px 20px 20px 0" }}>
                      <SuccessIcon fill={color.success[2]} />
                    </div>
                    <div>
                      <p>Photo uploaded successfully.</p>
                      <p>
                        Would you like to edit the settings of tracker{" "}
                        <Link to={`/${kegOrTracker("kegs", "trackers")}/${formData.sensorId}`}>{formData.sensorId}</Link>?
                      </p>
                    </div>
                  </div>
                )}
                {editPhotoErr && !editPhotoSuccess && (
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ width: "50px", height: "50px", margin: "20px 20px 20px 0" }}>
                      <ErrIcon fill={color.danger[2]} />
                    </div>
                    <div>
                      <p>Error uploading photo: {editPhotoErr}</p>
                      <p>
                        Would you like to edit the settings of tracker{" "}
                        <Link to={`/${kegOrTracker("kegs", "trackers")}/${formData.sensorId}`}>{formData.sensorId}</Link>?
                      </p>
                    </div>
                  </div>
                )}
                {!editPhotoErr && !editPhotoSuccess && (
                  <p>
                    Would you like to edit the settings of tracker{" "}
                    <Link to={`/${kegOrTracker("kegs", "trackers")}/${formData.sensorId}`}>{formData.sensorId}</Link>?
                  </p>
                )}
              </LoadingContainer>
            ) : (
              <>
                {editSettingsSuccess && !editSettingsErr && (
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ width: "50px", height: "50px", margin: "20px 20px 20px 0" }}>
                      <SuccessIcon fill={color.success[2]} />
                    </div>
                    <div>
                      <p>
                        Settings updated successfully for tracker{" "}
                        <Link to={`/${kegOrTracker("kegs", "trackers")}/${formData.sensorId}`}>{formData.sensorId}</Link>.
                      </p>
                    </div>
                  </div>
                )}
                {editSettingsErr && !editSettingsSuccess && (
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ width: "50px", height: "50px", margin: "20px 20px 20px 0" }}>
                      <ErrIcon fill={color.danger[2]} />
                    </div>
                    <div>
                      <p>
                        Error updating settings for tracker <Link to={`/${kegOrTracker("kegs", "trackers")}/${formData.sensorId}`}>{formData.sensorId}</Link>:{" "}
                        {editSettingsErr}
                      </p>
                    </div>
                  </div>
                )}
              </>
            )
          }
          footer={
            activateStep === 0 ? ( // Confirm activation details
              <>
                {!trackerDetails || trackerDetailsLoading ? (
                  <div style={{ justifyContent: "end", display: "flex" }}>
                    <OutlineBtn onClick={handleActivateModalClose}>Cancel</OutlineBtn>
                  </div>
                ) : (
                  <>
                    {trackerDetailsErr ? (
                      <div style={{ justifyContent: "end", display: "flex" }}>
                        <OutlineBtn onClick={handleActivateModalClose}>Okay</OutlineBtn>
                      </div>
                    ) : trackerDetails?.activated ? (
                      <div style={{ justifyContent: "end", display: "flex" }}>
                        <GhostBtn onClick={handleActivateModalClose}>Close</GhostBtn>
                        <PrimaryBtn onClick={handleRefresh}>Refresh</PrimaryBtn>
                      </div>
                    ) : (
                      <div style={{ justifyContent: "end", display: "flex" }}>
                        <GhostBtn onClick={handleActivateModalClose}>Cancel</GhostBtn>
                        <PrimaryBtn onClick={handleActivate}>Activate</PrimaryBtn>
                      </div>
                    )}
                  </>
                )}
              </>
            ) : activateStep === 1 ? ( // Upload photo step
              <div style={{ justifyContent: "end", display: "flex" }}>
                <OutlineBtn onClick={() => setActivateStep(2)}>Skip</OutlineBtn>
                <PrimaryBtn onClick={() => inputRef.current.click()}>Upload Photo</PrimaryBtn>
              </div>
            ) : activateStep === 2 ? ( // Edit settings step
              <div style={{ justifyContent: "end", display: "flex" }}>
                <OutlineBtn onClick={handleActivateModalClose}>Done</OutlineBtn>
                <PrimaryBtn onClick={() => setEditSettingsModalOpen(true)}>Edit Settings</PrimaryBtn>
              </div>
            ) : (
              <div style={{ justifyContent: "end", display: "flex" }}>
                <PrimaryBtn onClick={handleActivateModalClose}>Done</PrimaryBtn>
              </div>
            )
          }
        />
      )}
      {uploadPhotoModalOpen && formData.sensorId && (
        <UploadPhotoModal
          modalOpen={uploadPhotoModalOpen}
          setModalOpen={setUploadPhotoModalOpen}
          photo={inputPhoto}
          trackerId={formData.sensorId}
          organisationId={undefined}
          placeId={undefined}
          userId={undefined}
          onSuccess={() => {
            setUploadPhotoModalOpen(false);
            setEditPhotoSuccess(true);
            setActivateStep(2);
          }}
          onError={(msg: string) => {
            setUploadPhotoModalOpen(false);
            setEditPhotoErr(msg);
            setActivateStep(2);
          }}
        />
      )}
      {editSettingsModalOpen && formData.sensorId && (
        <EditTrackerModal
          id={formData.sensorId}
          organisationId={formData.organisation ? formData.organisation.value : undefined}
          onSuccess={() => {
            setEditSettingsModalOpen(false);
            setEditSettingsSuccess(true);
            setActivateStep(3);
          }}
          onError={(msg: string) => {
            setEditSettingsModalOpen(false);
            setEditSettingsErr(msg);
            setActivateStep(3);
          }}
          modalOpen={editSettingsModalOpen}
          setModalOpen={setEditSettingsModalOpen}
        />
      )}
    </>
  );
};

export default Activate;
