import styled from "../Themes";

export const Container = styled("div")`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Section = styled("section")`
  min-height: 0;
  padding: 24px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;

  @media (max-width: 500px) {
    padding: 12px 0;
  }
`;

export const LogoContainer = styled("div")`
  width: 100%;
  max-width: 300px;
  margin: 24px;
`;

export const SelectOrgContainer = styled("section")`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;

  border: 1px solid ${({ theme }) => theme.color.subtle_border[3]};
  border-radius: 4px;
  margin: 18px 0;

  overflow-y: auto;
  overflow-x: hidden;

  min-height: 0;
  max-height: 560px;

  @media (max-width: 500px) {
    border-top: 1px solid ${({ theme }) => theme.color.subtle_border[3]};
    border-bottom: 1px solid ${({ theme }) => theme.color.subtle_border[3]};
    border-left: none;
    border-right: none;
    border-radius: 0;
  }
`;

export const OrgList = styled(`div`)`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 18px;

  @media (max-width: 500px) {
    padding: 12px;
  }
`;

export const OrgErrContainer = styled(`div`)`
  padding: 6px 18px 18px;
  width: 100%;
  max-width: 500px;

  @media (max-width: 500px) {
    padding: 6px 12px 12px;
  }
`;

export const OrgCard = styled(`div`)`
  border-bottom: 1px solid ${({ theme }) => theme.color.subtle_border[3]};
  display: flex;
  padding: 12px;
  cursor: pointer;
  place-items: center;

  &:last-child {
    border: none;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.tooltip_hover[2]};
  }

  @media (max-width: 500px) {
    padding: 6px;
  }
`;

export const OrgAvatar = styled(`div`)`
  margin: 4px 24px 4px 4px;
`;

export const OrgDetails = styled(`div`)`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: start;
`;

export const OrgName = styled(`span`)`
  color: ${({ theme }) => theme.color.font_bold[2]};
  font-weight: 500;
  margin-bottom: 6px;
  text-align: left;
`;

export const OrgInfoHeading = styled(`span`)`
  white-space: nowrap;
`;

export const OrgInfoCount = styled(`span`)`
  color: ${({ theme }) => theme.color.font_bold[2]};
  font-weight: 500;
  margin-right: 32px;
`;

export const OrgInfoContainer = styled(`div`)`
  display: flex;
  align-items: start;
  flex-flow: row wrap;
`;

export const OrgRoleHeading = styled(`span`)`
  white-space: nowrap;
`;

export const SelectOrgForm = styled(`form`)`
  padding: 18px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subtle_border[3]};

  @media (max-width: 500px) {
    padding: 12px;
  }
`;

export const Heading = styled(`h4`)`
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin: 12px 0;
`;

export const CreateOrgContainer = styled("section")`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;

  padding-top: 18px;

  @media (max-width: 500px) {
    padding-top: 0;
  }
`;

export const CreateOrgForm = styled(`form`)`
  padding: 18px;

  @media (max-width: 500px) {
    padding: 12px;
  }
`;

export const JoinDemoContainer = styled("section")`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
`;

export const JoinDemoForm = styled(`form`)`
  margin: 18px 18px 36px;

  @media (max-width: 500px) {
    margin: 12px 12px 36px;
  }
`;
