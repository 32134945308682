import styled from "../Themes";

export const ClearButton = styled(`div`)`
  position: absolute;
  fill: ${({ theme }) => theme.color.font[2]};
  right: 0;
  top: 0;
  padding: 7px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
  display: flex;

  &:hover {
    fill: ${({ theme }) => theme.color.font_bold[2]};
  }
`;

export const CalendarIconContainer = styled(`div`)`
  fill: ${({ theme }) => theme.color.font[2]};
  width: 18px;
  height: 18px;
  display: block;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;

  &:hover {
    fill: ${({ theme }) => theme.color.font_bold[2]};
  }
`;
