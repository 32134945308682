import React, { FC, useRef, useEffect, useState } from "react";
import { InfoModal } from "../Modal";
import matchSorter from "match-sorter";
import Table, { CsvButtonsComponent } from "../Table";
import LoadingContainer from "../LoadingContainer";
import axios from "axios";
import errToStr from "../../util/errToStr";
import Tag from "../Tag";
import { getAllContents } from "../../services/contents";
import sortTags from "../../util/sortTags";
import { TableHeaderButtons } from "../NewTable/styles";

const ContentListModal: FC<any> = ({ data, filters, columns, modalOpen, setModalOpen, onClose }) => {
  const [contents, setContents] = useState<any>(data !== undefined ? data : []);
  const [contentsErr, setContentsErr] = useState<string>("");
  const [contentsLoading, setContentsLoading] = useState<boolean>(false);

  const [selectedColumns, setSelectedColumns] = useState<any>([]);

  const tableRef = useRef<any>(null);

  useEffect(() => {
    if (data !== undefined) setContents(data);
  }, [data]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (data === undefined && filters) {
      setContentsLoading(true);
      getAllContents(source, true, {
        filters,
      })
        .then(({ data }) => {
          setContents(data);
          setContentsLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setContentsErr(errToStr(err));
            setContentsLoading(false);
          }
        });
    }

    return () => {
      source.cancel();
    };
  }, [filters]);

  useEffect(() => {
    setSelectedColumns(columnDefinitions.filter((columnDef: any) => columns.some((column: any) => column === columnDef.id)));
  }, [columns]);

  const formatDataToCsv = (tableRef: any) => {
    const sortedData = tableRef.current.getResolvedState().sortedData;
    const headers = [];

    if (columns.includes("identifier")) headers.push("ID");
    if (columns.includes("name")) headers.push("Name");
    if (columns.includes("description")) headers.push("Description");
    if (columns.includes("contentTags")) headers.push("Content Tags");

    return [
      headers,
      ...sortedData.map((row: any) => {
        const rows = [];

        if (columns.includes("identifier")) rows.push(row.identifier);
        if (columns.includes("name")) rows.push(row.name);
        if (columns.includes("description")) rows.push(row.description);
        if (columns.includes("contentTags"))
          rows.push(
            row.contentTags
              ? row.contentTags
                  .sort(sortTags)
                  .map((tag: any) => tag.name)
                  .join(", ")
              : ""
          );

        return rows;
      }, []),
    ];
  };

  const columnDefinitions = React.useMemo<any>(
    () => [
      {
        id: "identifier",
        Header: "ID",
        accessor: "identifier",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["identifier"],
          }),
        filterAll: true,
      },
      {
        id: "name",
        Header: "Name",
        accessor: "name",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["name"],
          }),
        filterAll: true,
      },
      {
        id: "description",
        Header: "Description",
        accessor: "description",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["description"],
          }),
        filterAll: true,
      },
      {
        id: "contentTags",
        Header: "Content Tags",
        accessor: "contentTags",
        style: { textOverflow: "unset", whiteSpace: "normal" },
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["contentTags"],
          }),
        filterAll: true,
        Cell: (props: any) =>
          props.value ? (
            props.value.sort(sortTags).map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
          ) : (
            <></>
          ),
      },
    ],
    []
  );

  const defaultSorted = [
    {
      id: "identifier",
      desc: false,
    },
  ];

  return (
    <InfoModal
      isOpen={modalOpen}
      onClose={() => {
        if (onClose) onClose();
        setModalOpen(false);
      }}
      title={"Content List"}
      okayBtnText="Close"
      okayBtnProps={{ width: "100%" }}
      body={
        <LoadingContainer loading={contentsLoading} err={contentsErr}>
          <TableHeaderButtons>
            <div style={{ display: "flex" }}></div>
            <CsvButtonsComponent data={contents} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName="Content List.csv" />
          </TableHeaderButtons>
          <Table
            loading={contentsLoading}
            filterable={true}
            style={{ clear: "both" }}
            data={contents}
            columns={selectedColumns}
            defaultSorted={defaultSorted}
            ref={tableRef}
            defaultPageSize={15}
          />
        </LoadingContainer>
      }
    />
  );
};

export default ContentListModal;
