import React, { FC, useState, useContext, useEffect } from "react";
import { SignInContainer, LogoContainer } from "./styles";
import { Form, FormInputContainer, FormInput, FormError } from "../FormComponents";
import { Link, useLocation } from "react-router-dom";
import { ThemeContext } from "styled-components";
import LoadingContainer from "../LoadingContainer";
import { PrimaryBtn } from "../Buttons";
import { getLogo } from "../../util/getLogo";
import ScreenHeading from "../ScreenHeading";
import { postForgotPassword } from "../../services/forgotPassword";
import { exists, validEmail, passwordsMatch, validPassword } from "../../util/formValidations";
import { SuccessAlert, DangerAlert } from "../Alerts";
import { AlertSpan } from "../RegisterScreen/styles";
import queryString from "query-string";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import StateContext from "../StateContext";

const ResetPasswordForm: FC<any> = () => {
  const location = useLocation();

  const { color } = useContext(ThemeContext);
  const { theme } = useContext(StateContext);

  const [queryParams] = useState<any>(queryString.parse(location.search));
  const [formData, setFormData] = useState<any>({
    email: queryParams.email || "",
    token: queryParams.token || "",
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState<any>({});

  const [success, setSuccess] = useState<any>(undefined);
  const [error, setError] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];
      const password = formData.password || "";
      const confirmPassword = formData.confirmPassword || "";

      switch (name) {
        case "email":
          currValid = exists(name, value, setFormErrors) && validEmail(name, value, setFormErrors);
          break;

        case "token":
          currValid = exists(name, value, setFormErrors);
          break;

        case "password":
          currValid = validPassword(name, value, setFormErrors) && passwordsMatch("confirmPassword", password, confirmPassword, setFormErrors);
          break;

        case "confirmPassword":
          currValid = exists(name, value, setFormErrors) && passwordsMatch(name, password, confirmPassword, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const handleSubmit = (e: any) => {
    e.persist();
    e.preventDefault();
    setError("");
    setSuccess(undefined);

    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      postForgotPassword(source, formData)
        .then((response) => {
          setSuccess(response);
          setError("");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setError(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  return (
    <LoadingContainer loading={submitting}>
      <SignInContainer>
        <Form onSubmit={handleSubmit} noValidate>
          <LogoContainer>{getLogo(color, theme)}</LogoContainer>
          <ScreenHeading style={{ marginBottom: "24px" }}>Reset Password</ScreenHeading>
          <FormInputContainer
            style={{
              marginBottom: "14px",
            }}
          >
            <label>Email</label>
            <FormInput
              type="email"
              name="email"
              autoComplete="email"
              value={formData.email}
              error={formErrors.email}
              onChange={handleChange}
              autoFocus={!formData.email}
            />
            <FormError error={formErrors.email}>{formErrors.email}</FormError>
          </FormInputContainer>
          <FormInputContainer
            style={{
              marginBottom: "14px",
            }}
          >
            <label>Token</label>
            <FormInput
              type="text"
              name="token"
              value={formData.token}
              error={formErrors.token}
              onChange={handleChange}
              autoFocus={!formData.token && formData.email}
            />
            <FormError error={formErrors.token}>{formErrors.token}</FormError>
          </FormInputContainer>
          <FormInputContainer
            style={{
              marginBottom: "14px",
            }}
          >
            <label>Password</label>
            <FormInput
              type="password"
              name="password"
              autoComplete="new-password"
              value={formData.password}
              error={formErrors.password}
              onChange={handleChange}
              autoFocus={formData.email && formData.token}
            />
            <FormError error={formErrors.password}>{formErrors.password}</FormError>
          </FormInputContainer>
          <FormInputContainer
            style={{
              marginBottom: "14px",
            }}
          >
            <label>Confirm Password</label>
            <FormInput
              type="password"
              name="confirmPassword"
              autoComplete="new-password"
              value={formData.confirmPassword}
              error={formErrors.confirmPassword}
              onChange={handleChange}
            />
            <FormError error={formErrors.confirmPassword}>{formErrors.confirmPassword}</FormError>
          </FormInputContainer>
          {!success && (
            <div style={{ padding: "16px 0px 32px", width: "100%" }}>
              <PrimaryBtn width="100%" type="submit">
                Change password
              </PrimaryBtn>
            </div>
          )}
          {error && (
            <DangerAlert>
              <AlertSpan>{error}</AlertSpan>
            </DangerAlert>
          )}
          {success && (
            <SuccessAlert>
              <AlertSpan>{success}</AlertSpan>
            </SuccessAlert>
          )}
          <Link to="/sign-in">Sign in</Link>
        </Form>
      </SignInContainer>
    </LoadingContainer>
  );
};

export default ResetPasswordForm;
