import { useEffect, useRef } from "react";
import { HiddenCheckbox, Label, LabelText, Polyline, StyledCheckbox, Svg } from "./styles";

const Checkbox = (props: any) => {
  const { label, checked, indeterminate = false, disabled = false, loading = false, style, ...rest } = props;
  const checkboxRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  return (
    <Label style={style} disabled={disabled} loading={loading ? 1 : 0}>
      <HiddenCheckbox ref={checkboxRef} checked={checked} disabled={disabled || loading ? true : false} {...rest} />
      <StyledCheckbox checked={checked} disabled={disabled} loading={loading ? 1 : 0} indeterminate={indeterminate}>
        <Svg width="20px" height="20px" viewBox="0 0 20 20">
          <Polyline points="4 11 8 15 16 6" checked={checked} disabled={disabled} loading={loading ? 1 : 0}></Polyline>
        </Svg>
        {indeterminate && <div className="indeterminate-line" />}
      </StyledCheckbox>
      {label && (
        <LabelText disabled={disabled} loading={loading ? 1 : 0}>
          {label}
        </LabelText>
      )}
    </Label>
  );
};

export default Checkbox;
