import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import QrScanner from "qr-scanner";
import queryString from "query-string";
import Drawer from "rc-drawer";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { fetchAutoComplete } from "../../../services/autoComplete";
import { fetchTrackerCalibrationReport } from "../../../services/trackerCalibrationReport";
import Cross from "../../../svgs/Cross";
import errToStr from "../../../util/errToStr";
import getParameterByName from "../../../util/getParamByName";
import { DangerAlert } from "../../Alerts";
import { OutlineBtn, PrimaryBtn } from "../../Buttons";
import DateTemperaturePairManager from "../../DateTemperaturePairManager";
import { DrawBody, DrawCloseButton, DrawContent, DrawFooter, DrawHeader, FilterInputContainer } from "../../Drawer/styles";
import FlatpickrPicker from "../../FlatpickrPicker";
import { FormError } from "../../FormComponents";
import LoadingContainer from "../../LoadingContainer";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import { Subtitle, Title } from "../../PageTitle/styles";
import { ClearPanel, Panel } from "../../Panel";
import { PlainTemperatureSpan } from "../../ReportsScreen/styles";
import { AsyncSelect } from "../../Select";
import Table from "../../Table";
import Tooltip from "../../Tooltip";
import CalibrationResultsModal from "../CalibrationResultsModal";
import { QrScanButton } from "./styles";

const TrackerCalibration: FC<any> = () => {
  const { color, color_30 } = useContext(ThemeContext);

  const location = useLocation();
  const navigate = useNavigate();

  const tableRef = useRef<any>(null);
  const videoRefCallback = useCallback((node: any) => {
    if (node) {
      setVideoRef(node);
    }
  }, []);

  const [videoRef, setVideoRef] = useState<any>(undefined);
  const startDateParam = getParameterByName("start-date", location.search) != null ? getParameterByName("start-date", location.search) : null;
  const endDateParam = getParameterByName("end-date", location.search) != null ? getParameterByName("end-date", location.search) : null;

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [filtersOpen, setFiltersOpen] = useState<boolean>(true);
  const [filtersLoading, setFiltersLoading] = useState<boolean>(true);

  const [selectedTracker, setSelectedTracker] = useState<any>([]);
  const [resultsModalOpen, setResultsModalOpen] = useState<boolean>(false);

  const [codeReader, setCodeReader] = useState<any>(undefined);
  const [scanning, setScanning] = useState<boolean>(false);
  const [scan, setScan] = useState<any>(undefined);
  const [scanError, setScanError] = useState<string>("");
  const [scanLoading, setScanLoading] = useState<boolean>(false);

  // Report filters
  const [startDate, setStartDate] = useState<any>(startDateParam != null ? Number(startDateParam) * 1000 : moment().subtract(7, "days").valueOf());
  const [endDate, setEndDate] = useState<any>(endDateParam != null ? Number(endDateParam) * 1000 : moment().valueOf());
  const [trackers, setTrackers] = useState<any>([]);
  const [appliedTemperatures, setAppliedTemperatures] = useState<any>([]);

  const [appliedFilters, setAppliedFilters] = useState<number>(0);
  const [filterErrors, setFilterErrors] = useState<any>({});

  const [autoCompleteTrackers, setAutoCompleteTrackers] = useState<any>(undefined);

  const [filterOptions, setFilterOptions] = useState<any>({
    trackerIds: [],
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Fetch all autoComplete fields to use for matching params
  useEffect(() => {
    const trackersParam = getParameterByName("trackers", location.search) != null ? getParameterByName("trackers", location.search)?.split(",") : [];

    fetchAutoComplete("alltrackers", "").then((response) => {
      if (trackersParam != null) {
        setTrackers(
          response.filter((tracker: any) => {
            if (trackersParam.includes(tracker.value)) {
              return true;
            } else {
              return false;
            }
          })
        );
      }
      setAutoCompleteTrackers(response);
    });
  }, []);

  // On location change (e.g. url parameter changes because filters changed), update filters and fetch report
  useEffect(() => {
    let selectedStartDate = undefined;
    let selectedEndDate = undefined;
    let selectedTrackers = [];
    let selectedAppliedTemperatures: { date: string; temp: string }[] = [];

    const startDateParam = getParameterByName("start-date", location.search) != null ? getParameterByName("start-date", location.search) : null;
    if (startDateParam != null) {
      selectedStartDate = Number(startDateParam) * 1000;
      setStartDate(selectedStartDate);
    }

    const endDateParam = getParameterByName("end-date", location.search) != null ? getParameterByName("end-date", location.search) : null;
    if (endDateParam != null) {
      selectedEndDate = Number(endDateParam) * 1000;
      setEndDate(selectedEndDate);
    }

    if (autoCompleteTrackers != null) {
      const trackersParam = getParameterByName("trackers", location.search) != null ? getParameterByName("trackers", location.search)?.split(",") : [];
      selectedTrackers = autoCompleteTrackers?.filter((tracker: any) => trackersParam?.includes(tracker.value)) || [];
      setTrackers(selectedTrackers);
    }

    // Takes applied-temperatures query param and transforms it from date1:temp1,date2:temp2 to [{date: date1, temp: temp1}, {date: date2, temp: temp2}]
    const appliedTemperaturesParam =
      getParameterByName("applied-temperatures", location.search) != null ? getParameterByName("applied-temperatures", location.search)?.split(",") : [];
    if (appliedTemperaturesParam && appliedTemperaturesParam.length > 0) {
      selectedAppliedTemperatures = appliedTemperaturesParam.map((pair: string) => {
        return { date: pair.split(":")[0], temp: pair.split(":")[1] };
      });
      setAppliedTemperatures(selectedAppliedTemperatures);
    }

    if (!filtersLoading) {
      fetchReport(selectedStartDate, selectedEndDate, selectedTrackers, selectedAppliedTemperatures);
    }
  }, [location]);

  // After autoCompletes are initially set on mount, update filtersLoading to false
  useEffect(() => {
    if (autoCompleteTrackers != null) {
      setFiltersLoading(false);
    }
  }, [autoCompleteTrackers]);

  // If appliedTemperatures changes, remove error
  useEffect(() => {
    if (appliedTemperatures.length > 0) {
      setFilterErrors((prev: any) => ({ ...prev, appliedTemperatures: undefined }));
    }
  }, [appliedTemperatures]);

  const updateQueryParams = () => {
    const parsed = queryString.parse(location.search);
    const newQuery = { ...parsed };

    if (startDate != null) {
      newQuery["start-date"] = Math.round(startDate.valueOf() / 1000).toString();
    } else {
      delete newQuery["start-date"];
    }

    if (endDate != null) {
      newQuery["end-date"] = Math.round(endDate.valueOf() / 1000).toString();
    } else {
      delete newQuery["end-date"];
    }

    if (trackers && trackers.length > 0) {
      newQuery["trackers"] = trackers.map((tracker: any) => tracker.value).join(",");
    } else if (autoCompleteTrackers != null) {
      delete newQuery["trackers"];
    }

    if (appliedTemperatures && appliedTemperatures.length > 0) {
      newQuery["applied-temperatures"] = appliedTemperatures.map((pair: any) => `${pair.date}:${pair.temp}`).join(",");
    } else {
      delete newQuery["applied-temperatures"];
    }

    const stringified = queryString.stringify(newQuery);
    navigate({ ...location, search: stringified });
  };

  // Initialise codeReader and stop/destroy codeReader and stop camera access on clean up
  useEffect(() => {
    let codeReader: any = undefined;
    if (videoRef) {
      codeReader = new QrScanner(videoRef, (result) => {
        if (result) {
          let code = result;
          if (code.includes("10b.in/")) {
            code = code.split("10b.in/")[1];
          }
          setScan(code);
        }
      });
      codeReader.setInversionMode("both");
      setCodeReader(codeReader);
    }

    return () => {
      if (codeReader) {
        codeReader.stop();
        codeReader.destroy();
      }
      if (videoRef && videoRef.srcObject) {
        videoRef.srcObject.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
    };
  }, [videoRef]);

  // Stop code reader when scanning is disabled or filters menu is closed
  useEffect(() => {
    if (codeReader) {
      if (scanning && filtersOpen) {
        codeReader.start();
      } else {
        codeReader.stop();
        if (videoRef && videoRef.srcObject) {
          videoRef.srcObject.getTracks().forEach((track: any) => {
            track.stop();
          });
        }
      }
    }
  }, [codeReader, scanning, videoRef, filtersOpen]);

  // On QR scan, fetch all trackers from autoComplete to find matching binaryId and set it in the form or show error
  useEffect(() => {
    if (scan) {
      setScanLoading(true);
      fetchAutoComplete("alltrackers", "")
        .then((response) => {
          const test = response.filter((tracker: any) => tracker.value == scan);
          // if a tracker is found with the scanned ID and it is not already in the list of trackers, add it to the list
          if (test.length > 0) {
            if (!trackers.find((tracker: any) => tracker.value == scan)) {
              setTrackers((prev: any) => [...prev, test[0]]);
              setScanError("");
              setScan(undefined);
            } else {
              setScanError("Tracker already in list");
            }
          } else {
            setScanError("No tracker found with ID: " + scan);
          }
          setScanLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setScanError(errToStr(err));
            setScanLoading(false);
          }
        });
    }
  }, [scan]);

  const fetchReport = (startDateParam?: any, endDateParam?: any, trackersParam?: any, appliedTemperaturesParam?: any) => {
    if (!startDate) setDataErr("Start date is required");
    else if (!endDate) setDataErr("End date is required");
    else {
      setDataLoading(true);
      setDataErr("");

      const startDateArg = startDateParam !== undefined ? startDateParam : startDate;
      const endDateArg = endDateParam !== undefined ? endDateParam : endDate;
      const trackersArg = trackersParam?.length > 0 ? trackersParam : trackers;
      const appliedTemperaturesArg = appliedTemperaturesParam?.length > 0 ? appliedTemperaturesParam : appliedTemperatures;

      fetchTrackerCalibrationReport(
        source,
        trackersArg?.length > 0 ? trackersArg.map((tracker: any) => tracker.value, []) : [],
        appliedTemperaturesArg,
        startDateArg ? String(Math.round(startDateArg / 1000)) : undefined,
        endDateArg ? String(Math.round(endDateArg / 1000)) : undefined
      )
        .then((response) => {
          // get distinct strings for use in the select input filters
          if (response && response.length > 0) {
            const trackerIds = new Set();

            for (let i = 0; i < response.length; i++) {
              if (response[i].trackerId) trackerIds.add(response[i].trackerId);
            }

            setFilterOptions({
              trackerIds: Array.from(trackerIds).sort(),
            });
          }

          setData(response);
          setDataLoading(false);
          setFiltersOpen(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setData([]);
            setDataErr(errToStr(err));
            setDataLoading(false);
          }
        });

      let filters = 0;

      if (startDateArg != null) filters++;
      if (endDateArg != null) filters++;
      if (trackersArg?.length) filters += trackersArg.length;
      if (appliedTemperaturesArg?.length) filters += appliedTemperaturesArg.length;

      setAppliedFilters(filters);
    }
  };

  const columns = [
    {
      id: "trackerId",
      Header: "Tracker",
      accessor: "trackerId",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") {
          return true;
        }
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          {filterOptions.trackerIds.map((label: any) => {
            return (
              <option key={label} value={label}>
                {label}
              </option>
            );
          }, [])}
        </select>
      ),
      minWidth: 100,
    },
    {
      id: "averageCorrection",
      Header: "Average Correction",
      accessor: "averageCorrection",
      Cell: (props: any) => <PlainTemperatureSpan temp={props.value} />,
      minWidth: 140,
    },
    {
      id: "results",
      Header: "Results",
      accessor: "results",
      Cell: (props: any) => (
        <PrimaryBtn
          onClick={() => {
            setSelectedTracker(props.original);
            setResultsModalOpen(true);
          }}
        >
          Results
        </PrimaryBtn>
      ),
      minWidth: 140,
    },
  ];

  const defaultSorted = [
    {
      id: "startDate",
      desc: true,
    },
  ];

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  const isValidFilters = () => {
    let valid = true;

    if (startDate == null || endDate == null) {
      if (startDate == null) {
        setFilterErrors((prev: any) => ({ ...prev, startDate: "Required" }));
        valid = false;
      } else {
        setFilterErrors((prev: any) => ({ ...prev, startDate: undefined }));
      }

      if (endDate == null) {
        setFilterErrors((prev: any) => ({ ...prev, endDate: "Required" }));
        valid = false;
      } else {
        setFilterErrors((prev: any) => ({ ...prev, endDate: undefined }));
      }
    } else if (startDate > endDate) {
      setFilterErrors((prev: any) => ({ ...prev, startDate: "End date must be after start date", endDate: "End date must be after start date" }));
      valid = false;
    } else {
      setFilterErrors((prev: any) => ({ ...prev, startDate: undefined, endDate: undefined }));
    }

    if (trackers.length == 0) {
      setFilterErrors((prev: any) => ({ ...prev, trackers: "Required" }));
      valid = false;
    } else {
      setFilterErrors((prev: any) => ({ ...prev, trackers: undefined }));
    }

    if (appliedTemperatures.length == 0) {
      setFilterErrors((prev: any) => ({ ...prev, appliedTemperatures: "Required" }));
      valid = false;
    } else {
      setFilterErrors((prev: any) => ({ ...prev, appliedTemperatures: undefined }));
    }

    return valid;
  };

  const handleCloseFilters = () => {
    if (!filtersLoading && isValidFilters()) {
      setFiltersOpen(false);
      updateQueryParams();
    }
  };

  const toggleScan = (e: any) => {
    e.persist();
    e.preventDefault();

    if (scanning) {
      setScan(undefined);
      setScanning(false);
      setScanError("");
      setScanLoading(false);
    } else {
      setScanning(true);
      setScanError("");
      setScanLoading(false);
    }
  };

  return (
    <>
      <Drawer
        placement="right"
        level={null}
        open={filtersOpen}
        onClose={handleCloseFilters}
        onHandleClick={() => {
          if (!filtersLoading) setFiltersOpen(!filtersOpen);
        }}
        handler={false}
      >
        <DrawContent>
          <DrawHeader>
            <div
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                fontSize: "0",
                marginRight: "6px",
              }}
            >
              <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                <HiOutlineAdjustmentsHorizontal />
              </IconContext.Provider>
            </div>
            Filters
            <DrawCloseButton
              onClick={() => {
                if (!filtersLoading) setFiltersOpen(false);
              }}
              aria-label={`Close filters`}
            >
              <Cross />
            </DrawCloseButton>
          </DrawHeader>
          <DrawBody>
            <LoadingContainer loading={filtersLoading}>
              <form noValidate onSubmit={(e) => e.preventDefault()}>
                <label>Start Date</label>
                <FilterInputContainer>
                  <FlatpickrPicker
                    name="startDate"
                    enableTime={true}
                    error={filterErrors.startDate}
                    value={startDate}
                    clearable={false}
                    onChange={(d: any) => setStartDate(d[0])}
                    onClose={(d: any) => setStartDate(d[0])}
                    options={{ maxDate: Date.now() }}
                  />
                  <FormError error={filterErrors.startDate}>{filterErrors.startDate}</FormError>
                </FilterInputContainer>
                <label>End Date</label>
                <FilterInputContainer>
                  <FlatpickrPicker
                    name="endDate"
                    enableTime={true}
                    error={filterErrors.endDate}
                    value={endDate}
                    clearable={false}
                    onChange={(d: any) => setEndDate(d[0])}
                    onClose={(d: any) => setEndDate(d[0])}
                    options={{ maxDate: Date.now() }}
                  />
                  <FormError error={filterErrors.endDate}>{filterErrors.endDate}</FormError>
                </FilterInputContainer>
                <label>Trackers</label>
                <FilterInputContainer>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "100%" }}>
                      <AsyncSelect
                        name="trackers"
                        defaultOptions={true}
                        isClearable={true}
                        isSearchable={true}
                        isError={filterErrors.trackers}
                        value={trackers}
                        isMulti={true}
                        loadOptions={(inputValue: any, callback: any) => loadOptions("alltrackers", inputValue, callback)}
                        onChange={(selected: any) => {
                          if (selected) {
                            setTrackers(selected);
                          } else {
                            setTrackers([]);
                          }
                          setFilterErrors((prev: any) => ({ ...prev, trackers: undefined }));
                        }}
                        placeholder="Select..."
                      />
                    </div>
                    <div style={{ width: "100%", maxWidth: "34px", marginLeft: "6px" }}>
                      <Tooltip content="Toggle QR Scanner" trigger="mouseenter">
                        <OutlineBtn onClick={toggleScan} style={{ height: "34px", minWidth: "unset" }} padding="0" width="34px">
                          <div
                            style={{
                              width: "34px",
                              height: "34px",
                              lineHeight: "0",
                              fill: "none",
                            }}
                          >
                            <QrScanButton />
                          </div>
                        </OutlineBtn>
                      </Tooltip>
                    </div>
                  </div>
                  <FormError error={filterErrors.trackers}>{filterErrors.trackers}</FormError>
                  <div
                    style={{
                      display: scanning ? "flex" : "none",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LoadingContainer loading={scanLoading}>
                      <video
                        id="video"
                        ref={videoRefCallback}
                        style={{
                          display: "inline-block",
                          border: `1px solid ${color_30.panel_fg[0]}`,
                          borderRadius: "3px",
                          maxHeight: "50vh",
                          maxWidth: "100%",
                        }}
                      />
                    </LoadingContainer>
                  </div>
                  {scanError && (
                    <DangerAlert
                      style={{
                        margin: "20px 0 0 0",
                        float: "left",
                        width: "100%",
                      }}
                    >
                      {scanError}
                    </DangerAlert>
                  )}
                </FilterInputContainer>
                <label>Applied Temperatures</label>
                <FilterInputContainer>
                  <DateTemperaturePairManager
                    pairs={appliedTemperatures}
                    setPairs={setAppliedTemperatures}
                    minDate={startDate}
                    maxDate={endDate}
                    error={filterErrors.appliedTemperatures}
                  />
                </FilterInputContainer>
                {dataErr && <DangerAlert>{dataErr}</DangerAlert>}
              </form>
            </LoadingContainer>
          </DrawBody>
          <DrawFooter>
            <PrimaryBtn onClick={handleCloseFilters}>Apply Filters</PrimaryBtn>
          </DrawFooter>
        </DrawContent>
      </Drawer>
      <PageBreadcrumbs prevRoutes={[{ slug: "/admin-reports", title: "Admin Reports" }]} currRoute="Tracker Calibration" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading}>
            <ClearPanel style={{ textAlign: "center" }}>
              <Title style={{ marginBottom: "6px" }}>Tracker Calibration</Title>
              <Subtitle>Tracker Calibration</Subtitle>
            </ClearPanel>
            <ClearPanel style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <PrimaryBtn style={{ height: "40px", minWidth: "unset", marginRight: "12px" }} padding="0 6px" onClick={() => setFiltersOpen(!filtersOpen)}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div
                      style={{
                        display: "inline-block",
                        width: "20px",
                        height: "20px",
                        fontSize: "0",
                        marginRight: "6px",
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiOutlineAdjustmentsHorizontal />
                      </IconContext.Provider>
                    </div>
                    <span>Filters</span>
                  </div>
                </PrimaryBtn>
                <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                  {appliedFilters} filter{appliedFilters === 1 ? "" : "s"} applied
                </span>
              </div>
            </ClearPanel>
            <Panel>
              <Table
                loading={dataLoading}
                style={{ clear: "both" }}
                data={data}
                filterable={true}
                columns={columns}
                defaultSorted={defaultSorted}
                ref={tableRef}
              />
            </Panel>
            {dataErr && <DangerAlert>{dataErr}</DangerAlert>}
          </LoadingContainer>
        </div>
      </PageContainer>
      {resultsModalOpen && <CalibrationResultsModal data={selectedTracker} modalOpen={resultsModalOpen} setModalOpen={setResultsModalOpen} />}
    </>
  );
};

export default TrackerCalibration;
