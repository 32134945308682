import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const fetchReport10 = async (
  source: CancelTokenSource,
  minPostcode?: string,
  maxPostcode?: string,
  minKegs?: string,
  pickupIndicator?: string,
  lat?: number,
  lng?: number,
  radius?: number
) => {
  const params = {
    organisationId: getAccount().organisationId,
    minPostcode: minPostcode ? minPostcode : undefined,
    maxPostcode: maxPostcode ? maxPostcode : undefined,
    minKegs: minKegs ? minKegs : undefined,
    pickupIndicator: pickupIndicator ? pickupIndicator : undefined,
    lat,
    lng,
    radius,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/report10",
    params,
  });

  return data;
};
