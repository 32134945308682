import axios, { CancelTokenSource } from "axios";
import { getAccount, getToken } from "../localStorage";

export const fetchAlerts = async (source: CancelTokenSource, id?: string) => {
  const params = {
    organisationId: getAccount().organisationId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + `/api/editeventalerts/${id ? id : ""}`,
    params,
  });

  return data;
};

export const fetchPlaceAlerts = async (source: CancelTokenSource, placeId?: number) => {
  const params = {
    organisationId: getAccount().organisationId,
    placeId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/editeventalerts/place",
    params,
  });

  return data;
};

export const postAlerts = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/api/editeventalerts",
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};

export const putAlerts = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + "/api/editeventalerts",
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};

export const deleteAlerts = async (source: CancelTokenSource, id: string) => {
  const params = {
    id,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "delete",
    url: process.env.REACT_APP_API_URL + "/api/editeventalerts",
    params,
  });

  return data;
};
