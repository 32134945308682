import React, { FC, useContext, useMemo } from "react";
import Modal from "react-modal";
import { ModalHeading, ModalExit, ModalBody, ModalFooter, ModalError, ModalSuccess, ModalIconContainer } from "./styles";
import Cross from "../../svgs/Cross";
import { ThemeContext } from "styled-components";
import useWindowSize from "../../util/useWindowSize";
import ErrIcon from "../../svgs/ErrIcon";
import { DangerBtn, GhostBtn, OutlineBtn, PrimaryBtn, WarningBtn } from "../Buttons";
import SuccessIcon from "../../svgs/SuccessIcon";

export const getStyling = (theme: any, size: string, width: number) => ({
  overlay: {
    position: "absolute",
    height: "100vh",
    width: "100vw",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 1010000,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: 0,
    position: "absolute",
    top: "10vh",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, 0)",
    fontSize: "14px",
    border: `1px solid ${theme.color.modal_border[2]}`,
    background: theme.color.modal_background[2],
    overflow: "visible",
    borderRadius: "4px",
    maxHeight: "80%",
    maxWidth: size === "xl" ? (width > 1440 ? "1380px" : "95%") : size === "lg" ? (width > 850 ? "800px" : "95%") : width > 768 ? "600px" : "95%",
    minWidth: size === "xl" ? (width > 1440 ? "1380px" : "95%") : size === "lg" ? (width > 850 ? "800px" : "95%") : width > 768 ? "600px" : "95%",
    margin: "auto",
  },
});

export const getFlatpickrStyling = (theme: any, size: string, width: number) => ({
  overlay: {
    position: "absolute",
    height: "100vh",
    width: "100vw",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 1010000,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: 0,
    position: "absolute",
    top: "10vh",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, 0)",
    fontSize: "14px",
    border: `1px solid ${theme.color.modal_border[2]}`,
    background: theme.color.modal_background[2],
    overflow: "visible",
    borderRadius: "4px",
    maxHeight: "80%",
    margin: "auto",
  },
});

export const SubmitModal: FC<any> = ({
  isOpen,
  loading,
  size,
  onClose,
  onSubmit,
  headerVisible = true,
  title,
  body,
  footer,
  success,
  error,
  submitBtn = "primary",
  submitBtnText = "Submit",
  cancelBtnText = "Cancel",
  cancelBtnProps,
  okayBtnText = "Okay",
  showSuccessIcon = true,
  showErrorIcon = true,
  padding,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const { width } = useWindowSize();

  const style = useMemo(() => getStyling(theme, size, width), [theme, size, width]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={style} {...props}>
      {headerVisible && title && (
        <>
          <ModalHeading>{title}</ModalHeading>
          <ModalExit onClick={onClose}>
            <Cross />
          </ModalExit>
        </>
      )}
      {success ? (
        <ModalSuccess>
          <div>
            {showSuccessIcon && (
              <ModalIconContainer>
                <SuccessIcon fill={theme.color.success[2]} />
              </ModalIconContainer>
            )}
            {success}
          </div>
        </ModalSuccess>
      ) : error ? (
        <ModalError>
          <div>
            {showErrorIcon && (
              <ModalIconContainer>
                <ErrIcon fill={theme.color.error[2]} />
              </ModalIconContainer>
            )}
            {error}
          </div>
        </ModalError>
      ) : (
        <ModalBody padding={padding}>{body}</ModalBody>
      )}
      <ModalFooter>
        {footer ? (
          footer
        ) : success ? (
          <OutlineBtn onClick={onClose}>{okayBtnText}</OutlineBtn>
        ) : error ? (
          <OutlineBtn onClick={onClose}>{okayBtnText}</OutlineBtn>
        ) : (
          <>
            <GhostBtn onClick={onClose} {...cancelBtnProps}>
              {cancelBtnText}
            </GhostBtn>
            {!loading && (
              <>
                {submitBtn === "primary" ? (
                  <PrimaryBtn onClick={onSubmit}>{submitBtnText}</PrimaryBtn>
                ) : submitBtn === "danger" ? (
                  <DangerBtn onClick={onSubmit}>{submitBtnText}</DangerBtn>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export const InfoModal: FC<any> = ({
  isOpen,
  size,
  onClose,
  headerVisible = true,
  title,
  body,
  footer,
  okayBtn = "default",
  okayBtnText = "Okay",
  okayBtnProps,
  padding,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const { width } = useWindowSize();

  const style = useMemo(() => getStyling(theme, size, width), [theme, size, width]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={style} {...props}>
      {headerVisible && title && (
        <>
          <ModalHeading>{title}</ModalHeading>
          <ModalExit onClick={onClose}>
            <Cross />
          </ModalExit>
        </>
      )}
      <ModalBody padding={padding}>{body}</ModalBody>
      <ModalFooter>
        {footer ? (
          footer
        ) : okayBtn === "default" ? (
          <OutlineBtn onClick={onClose} {...okayBtnProps}>
            {okayBtnText}
          </OutlineBtn>
        ) : okayBtn === "danger" ? (
          <DangerBtn onClick={onClose} {...okayBtnProps}>
            {okayBtnText}
          </DangerBtn>
        ) : okayBtn === "primary" ? (
          <PrimaryBtn onClick={onClose} {...okayBtnProps}>
            {okayBtnText}
          </PrimaryBtn>
        ) : okayBtn === "success" ? (
          <OutlineBtn onClick={onClose} {...okayBtnProps}>
            {okayBtnText}
          </OutlineBtn>
        ) : okayBtn === "warning" ? (
          <WarningBtn onClick={onClose} {...okayBtnProps}>
            {okayBtnText}
          </WarningBtn>
        ) : (
          <></>
        )}
      </ModalFooter>
    </Modal>
  );
};

export const ErrorModal: FC<any> = ({
  isOpen,
  size = "sm",
  onClose,
  headerVisible = true,
  title = "Error",
  error,
  showErrorIcon = true,
  okayBtnText = "Okay",
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const { width } = useWindowSize();

  const style = useMemo(() => getStyling(theme, size, width), [theme, size, width]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={style} {...props}>
      {headerVisible && title && (
        <>
          <ModalHeading>{title}</ModalHeading>
          <ModalExit onClick={onClose}>
            <Cross />
          </ModalExit>
        </>
      )}
      <ModalError>
        <div>
          {showErrorIcon && (
            <ModalIconContainer>
              <ErrIcon fill={theme.color.error[2]} />
            </ModalIconContainer>
          )}
          {error}
        </div>
      </ModalError>
      <ModalFooter>
        <OutlineBtn onClick={onClose}>{okayBtnText}</OutlineBtn>
      </ModalFooter>
    </Modal>
  );
};

export const FlatpickrModal: FC<any> = ({ isOpen, size, onClose, onUpdate, updateDisabled = false, onClear, body, ...props }) => {
  const theme = useContext(ThemeContext);
  const { width } = useWindowSize();

  const style = useMemo(() => getFlatpickrStyling(theme, size, width), [theme, size, width]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={style} {...props}>
      <ModalBody padding="12px">{body}</ModalBody>
      <ModalFooter style={{ justifyContent: "space-between" }}>
        <GhostBtn onClick={onClear}>Clear</GhostBtn>
        <PrimaryBtn disabled={updateDisabled} onClick={onUpdate}>
          Update
        </PrimaryBtn>
      </ModalFooter>
    </Modal>
  );
};

export const ColourPickerModal: FC<any> = ({ isOpen, size, onClose, body, ...props }) => {
  const theme = useContext(ThemeContext);
  const { width } = useWindowSize();

  const style = useMemo(() => getFlatpickrStyling(theme, size, width), [theme, size, width]);

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={style} {...props}>
      <ModalBody padding="0">{body}</ModalBody>
    </Modal>
  );
};
