import react from "react";
import styled, { keyframes } from "styled-components";

const Screen = styled<any>(`div`)`
  display: ${({ isLoading }) => (isLoading ? "block" : "none")};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(2px);
`;

const DualRingAnimation = keyframes`
0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const DualRing = styled<any>(`div`)`
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &::after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: ${({ theme }) => theme.color.primary[2]} transparent ${({ theme }) => theme.color.primary[2]} transparent;
    animation: ${DualRingAnimation} 1s linear infinite;
  }
`;

const LoadingScreen = ({ loading }: any) => {
  return (
    <Screen isLoading={loading}>
      <DualRing />
    </Screen>
  );
};

export default LoadingScreen;
