import axios, { CancelTokenSource } from "axios";
import QrScanner from "qr-scanner";
import { FC, useCallback, useEffect, useState } from "react";
import { fetchAutoComplete } from "../../services/autoComplete";
import { getAccount } from "../../services/localStorage";
import { createTrackerServiceLog, fetchTrackerServiceActions } from "../../services/trackerServiceLogs";
import errToStr from "../../util/errToStr";
import { printTempUnit, toCelsius } from "../../util/formatUnits";
import { exists, maxLength, validNumber } from "../../util/formValidations";
import { QrScanButton } from "../ActivateTrackerScreen/styles";
import { DangerAlert } from "../Alerts";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import Checkbox from "../Checkbox";
import FlatpickrPicker from "../FlatpickrPicker";
import { FormError, FormInput, FormTextareaInput, InputUnitWrapper } from "../FormComponents";
import { HorizontalLine } from "../HorizontalLine";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { AsyncSelect } from "../Select";
import Tooltip from "../Tooltip";

const defaultForm = {
  tracker: null,
  serviceDate: new Date(),
  roomTemperature: "24",
  actions: "",
  notes: "",
};

const initForm = (trackerId: string | null) => {
  if (trackerId == null) return defaultForm;
  return { ...defaultForm, tracker: { value: trackerId, label: trackerId } };
};

const CreateTrackerServiceLogModal: FC<any> = ({ trackerId, onSuccess, modalOpen, setModalOpen }) => {
  const videoRefCallback = useCallback((node: any) => {
    if (node) {
      setVideoRef(node);
    }
  }, []);

  const [videoRef, setVideoRef] = useState<any>(undefined);

  const [formData, setFormData] = useState<any>(initForm(trackerId));
  const [checkedActions, setCheckedActions] = useState<Record<string, boolean>>({});
  const [formErrors, setFormErrors] = useState<any>({});

  const [codeReader, setCodeReader] = useState<any>(undefined);
  const [scanning, setScanning] = useState<boolean>(false);
  const [scan, setScan] = useState<any>(undefined);
  const [scanError, setScanError] = useState<string>("");
  const [scanLoading, setScanLoading] = useState<boolean>(false);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [actions, setActions] = useState<any>([]);
  const [actionsError, setActionsError] = useState<string>("");
  const [actionsLoading, setActionsLoading] = useState<boolean>(true);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Initialise codeReader and stop/destroy codeReader and stop camera access on clean up
  useEffect(() => {
    let codeReader: any = undefined;
    if (videoRef) {
      codeReader = new QrScanner(videoRef, (result) => {
        if (result) {
          let code = result;
          if (code.includes("10b.in/")) {
            code = code.split("10b.in/")[1];
          }
          setScan(code);
        }
      });
      codeReader.setInversionMode("both");
      setCodeReader(codeReader);
    }

    return () => {
      if (codeReader) {
        codeReader.stop();
        codeReader.destroy();
      }
      if (videoRef && videoRef.srcObject) {
        videoRef.srcObject.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
    };
  }, [videoRef]);

  // Stop code reader when tracker info is loading or the tracker info modal is open
  useEffect(() => {
    if (codeReader) {
      if (scanning) {
        codeReader.start();
      } else {
        codeReader.stop();
        if (videoRef && videoRef.srcObject) {
          videoRef.srcObject.getTracks().forEach((track: any) => {
            track.stop();
          });
        }
      }
    }
  }, [codeReader, scanning, videoRef]);

  // On QR scan, fetch all trackers from autoComplete to find matching binaryId and set it in the form or show error
  useEffect(() => {
    if (scan) {
      setScanLoading(true);
      fetchAutoComplete("alltrackers", "")
        .then((response) => {
          const test = response.filter((tracker: any) => tracker.value == scan);
          if (test.length > 0) {
            setFormData((prev: any) => ({ ...prev, tracker: test[0] }));
            setScan(undefined);
            setScanning(false);
            setScanError("");
          } else {
            setScanError("No tracker found with ID: " + scan);
          }
          setScanLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setScanError(errToStr(err));
            setScanLoading(false);
          }
        });
    }
  }, [scan]);

  useEffect(() => {
    fetchTrackerServiceActions(source)
      .then((response) => {
        setActions(response);
        // Initialize each action as unchecked
        setCheckedActions(response.reduce((acc: any, current: any) => ({ ...acc, [current.name]: false }), {}));
        setActionsLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setActionsError(errToStr(err));
          setActionsLoading(false);
        }
      });
  }, [source]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "tracker":
          currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        case "roomTemperature":
          currValid = exists(name, value, setFormErrors) && validNumber(name, value, setFormErrors);
          break;

        case "notes":
          currValid = maxLength(name, value, 4096, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const formattedData: any = {
      trackerId: formData.tracker ? formData.tracker.value : null,
      serviceDate: formData.serviceDate ? formData.serviceDate.toISOString() : null,
      roomTemperature: toCelsius(formData.roomTemperature),
      actions: formData.actions.trim(),
      notes: formData.notes.trim(),
    };

    return formattedData;
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      createTrackerServiceLog(source, body)
        .then((response) => {
          if (onSuccess) onSuccess(response);
          setSubmittedMsg("Service Log Created");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const { name, checked } = e.target;

    // Update the checked state for individual checkboxes
    setCheckedActions((prev: any) => ({
      ...prev,
      [name]: checked,
    }));

    // Update the actions list in the form data
    setFormData((prev: any) => {
      const currentActions = prev.actions ? prev.actions.split(",").map((action: string) => action.trim()) : [];

      if (checked) {
        // Add the action name if checked and it's not already in the array
        if (!currentActions.includes(name)) {
          currentActions.push(name);
        }
      } else {
        // Remove the action name if unchecked
        const filteredActions = currentActions.filter((action: string) => action !== name);
        return {
          ...prev,
          actions: filteredActions.join(","),
        };
      }
      return {
        ...prev,
        actions: currentActions.join(","),
      };
    });
  };

  const handleEnableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setSubmittedMsg("");
    setSubmittingErr("");
    setScanning(true);
    setScanError("");
    setScanLoading(false);
  };

  const handleDisableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setScan(undefined);
    setScanning(false);
    setScanError("");
    setScanLoading(false);
  };

  const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const newCheckedActions: Record<string, boolean> = {};

    actions.forEach((action: any) => {
      newCheckedActions[action.name] = checked;
    });

    setCheckedActions(newCheckedActions);
    setFormData((prev: any) => ({
      ...prev,
      actions: checked ? actions.map((action: any) => action.name).join(",") : "",
    }));
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
  };

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const handleDateChange = (date: any) => {
    setFormData((prev: any) => ({ ...prev, serviceDate: date[0] }));
    setFormErrors((prev: any) => ({ ...prev, serviceDate: undefined }));
  };

  return (
    <>
      <SubmitModal
        isOpen={modalOpen}
        onSubmit={() => handleSubmit()}
        onClose={handleClose}
        size={!submittedMsg && !submittingErr ? "lg" : "sm"}
        title="Create Service Log"
        success={submittedMsg}
        error={submittingErr}
        submitBtnText="Create"
        body={
          <LoadingContainer loading={actionsLoading || submitting}>
            <div
              style={{
                display: scanning ? "flex" : "none",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingContainer loading={scanLoading}>
                <video
                  id="video"
                  ref={videoRefCallback}
                  style={{
                    display: "inline-block",
                    border: "1px solid gray",
                    borderRadius: "3px",
                    maxHeight: "50vh",
                    maxWidth: "100%",
                  }}
                />
              </LoadingContainer>
            </div>
            {scanError && (
              <DangerAlert
                style={{
                  margin: "20px 0 0 0",
                  float: "left",
                  width: "100%",
                }}
              >
                {scanError}
              </DangerAlert>
            )}
            <div
              style={{
                display: scanning ? "none" : "block",
              }}
            >
              <form noValidate onSubmit={(e) => e.preventDefault()} style={{ display: "flex", flexWrap: "wrap", columnGap: "10px" }}>
                <ModalFormContainer halfWidth={true}>
                  <label>User</label>
                  <FormInput autoComplete="no" type="text" name="fullName" value={getAccount().fullName} disabled={true} />
                </ModalFormContainer>
                <ModalFormContainer halfWidth={true}>
                  <label>Service Date</label>
                  <FlatpickrPicker
                    enableTime={false}
                    name="serviceDate"
                    value={formData.serviceDate}
                    onChange={handleDateChange}
                    onClose={handleDateChange}
                    onClear={() => handleDateChange([undefined])}
                  />
                  <FormError error={formErrors.serviceDate}>{formErrors.serviceDate}</FormError>
                </ModalFormContainer>
                {trackerId == null && (
                  <>
                    <ModalFormContainer>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <label style={{ float: "left" }}>Tracker</label>
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "100%" }}>
                            <AsyncSelect
                              name="tracker"
                              defaultOptions={true}
                              isClearable={true}
                              isError={formErrors.tracker}
                              value={formData.tracker}
                              loadOptions={(inputValue: any, callback: any) => loadOptions("alltrackers", inputValue, callback)}
                              onChange={handleSelectChange}
                              placeholder="Select..."
                            />
                          </div>
                          <div style={{ width: "100%", maxWidth: "34px", marginLeft: "6px" }}>
                            <Tooltip content="Scan QR Code" trigger="mouseenter">
                              <OutlineBtn onClick={handleEnableScan} style={{ height: "34px", minWidth: "unset" }} padding="0" width="34px">
                                <div
                                  style={{
                                    width: "34px",
                                    height: "34px",
                                    lineHeight: "0",
                                    fill: "none",
                                  }}
                                >
                                  <QrScanButton />
                                </div>
                              </OutlineBtn>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <FormError error={formErrors.tracker}>{formErrors.tracker}</FormError>
                    </ModalFormContainer>
                  </>
                )}
                <ModalFormContainer halfWidth={true}>
                  <label>Room Temperature</label>
                  <InputUnitWrapper style={{ clear: "both" }} unit={printTempUnit()}>
                    <FormInput
                      type="number"
                      name="roomTemperature"
                      value={formData.roomTemperature}
                      error={formErrors.roomTemperature}
                      onChange={handleChange}
                      step={0.1}
                    />
                    <FormError error={formErrors.roomTemperature}>{formErrors.roomTemperature}</FormError>
                  </InputUnitWrapper>
                </ModalFormContainer>
                <ModalFormContainer halfWidth={true} style={{ alignSelf: "center" }}>
                  <Checkbox
                    name="Check All"
                    label="Check All"
                    checked={Object.keys(checkedActions).length > 0 && Object.values(checkedActions).every(Boolean)}
                    onChange={handleCheckAll}
                    indeterminate={Object.values(checkedActions).some((checked) => checked) && !Object.values(checkedActions).every(Boolean)}
                  />
                </ModalFormContainer>
                <HorizontalLine style={{ margin: "12px 0", width: "100%" }} />
                {actions.length > 0 &&
                  actions.map((action: any) => {
                    return (
                      <ModalFormContainer key={action.id}>
                        <Checkbox
                          style={{ display: "inline-block" }}
                          name={action.name}
                          label={action.name}
                          checked={!!checkedActions[action.name]}
                          onChange={handleCheckboxChange}
                        />
                      </ModalFormContainer>
                    );
                  })}
                <ModalFormContainer>
                  <label>Notes</label>
                  <FormTextareaInput name="notes" rows={6} maxLength={4096} value={formData.notes} error={formErrors.notes} onChange={handleChange} />
                  <FormError error={formErrors.notes}>{formErrors.notes}</FormError>
                </ModalFormContainer>
              </form>
            </div>
          </LoadingContainer>
        }
        footer={
          scanning ? (
            <OutlineBtn onClick={handleDisableScan} width="100%">
              Back
            </OutlineBtn>
          ) : submittedMsg ? (
            <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
          ) : submittingErr ? (
            <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
          ) : (
            <>
              <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
              <PrimaryBtn onClick={handleSubmit}>Create</PrimaryBtn>
            </>
          )
        }
      />
    </>
  );
};

export default CreateTrackerServiceLogModal;
