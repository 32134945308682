import React, { FC, useState, useEffect, useContext } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { FormError, FormInput } from "../FormComponents";
import { exists, validName, maxLength } from "../../util/formValidations";
import { ModalFormContainer } from "../Modal/styles";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import ColorPicker from "../ColorPicker";
import { addTrackerTag, editTrackerTag } from "../../services/editTrackerTags";
import { ThemeContext } from "styled-components";
import { kegOrTracker, kegsOrTrackers } from "../../util/kegOrTracker";
import matchSorter from "match-sorter";
import Bold from "../Bold";
import Checkbox from "../Checkbox";
import { Link } from "react-router-dom";
import Table from "../Table";
import Tag from "../Tag";
import sortTags from "../../util/sortTags";
import { getTrackers } from "../../services/trackers";
import { getAccount } from "../../services/localStorage";

const initTag = (tag: any, echarts_palette: any) => {
  return {
    id: null,
    name: "",
    colour: echarts_palette[Math.floor(Math.random() * echarts_palette.length)],
    description: "",
    trackerCount: 0,
    organisationId: "",
    organisationName: "",
    ...tag,
  };
};

const EditTrackerTagModel: FC<any> = ({ tag, fetch, modalOpen, setModalOpen }) => {
  const { echarts_palette } = useContext(ThemeContext);

  const [formData, setFormData] = useState<any>(initTag(tag, echarts_palette));
  const [formErrors, setFormErrors] = useState<any>({});

  const [trackers, setTrackers] = useState<any>([]);
  const [trackersErr, setTrackersErr] = useState<string>("");
  const [trackersLoading, setTrackersLoading] = useState<boolean>(false);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [trackerChanges, setTrackerChanges] = useState<any>({});

  const [filterOptions, setFilterOptions] = useState<any>({
    trackerTags: [],
    trackerTypeNames: [],
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setTrackersLoading(true);
    getTrackers(source, { filters: `organisationId eq ${getAccount().organisationId}` })
      .then(({ data }) => {
        // Sets selected and initSelected property to indicate if trackers have the current tag or not and to sort the rows
        // by initSelected to prevent the rows bouncing around when editing
        setTrackers(
          data.map((tracker: any) => {
            const selected = tracker.trackerTags.some((tag: any) => formData.id === tag.id);
            return {
              ...tracker,
              selected: selected,
              initSelected: selected,
            };
          })
        );
        setTrackersLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setTrackersErr(errToStr(err));
          setTrackersLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [formData.id, source]);

  // Get distinct strings for use in the select input filters when trackers changes
  useEffect(() => {
    if (trackers.length > 0) {
      const trackerTags = new Set();
      const trackerTypeNames = new Set();

      for (let i = 0; i < trackers.length; i++) {
        if (trackers[i].trackerTags) trackers[i].trackerTags.forEach((tag: any) => trackerTags.add(tag.name));
        if (trackers[i].trackerTypeName) trackerTypeNames.add(trackers[i].trackerTypeName);
      }

      setFilterOptions({
        trackerTags: Array.from(trackerTags).sort(),
        trackerTypeNames: Array.from(trackerTypeNames).sort(),
      });
    }
  }, [trackers]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "name":
          currValid = exists(name, value, setFormErrors) && maxLength(name, value, 50, setFormErrors) && validName(name, value, setFormErrors);
          break;

        case "description":
          currValid = maxLength(name, value, 500, setFormErrors);
          break;

        case "colour":
          currValid = exists(name, value, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const trackerChangesArr = Object.values(trackerChanges);

    const addTrackerIds: any = [];
    const removeTrackerIds: any = [];

    trackerChangesArr.forEach((tracker: any) => {
      // If the tracker is selected it will be updated with the current tag
      if (tracker.selected) addTrackerIds.push(tracker.id);

      // If the tracker is not selected and we are editing an existing tag and we have
      // de-selected a tracker within the current tag, remove tag from the tracker
      if (!tracker.selected && formData.id && tracker.trackerTags.some((tag: any) => formData.id === tag.id)) removeTrackerIds.push(tracker.id);
    });

    // If the tag has an id then we are editing it therefore include the removeTrackerIds array
    const formattedData: any = {
      id: formData.id,
      name: formData.name,
      colour: formData.colour,
      description: formData.description,
      addTrackerIds,
    };

    if (formData.id) {
      formattedData.removeTrackerIds = removeTrackerIds;
    }

    return formattedData;
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      // If the tag has an id then we are editing it
      if (body.id) {
        setSubmitting(true);
        editTrackerTag(source, body)
          .then(() => {
            if (fetch) fetch();
            setSubmittedMsg(kegsOrTrackers("Keg Tag Updated", "Tracker Tag Updated"));
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      } else {
        setSubmitting(true);
        addTrackerTag(source, body)
          .then(() => {
            if (fetch) fetch();
            setSubmittedMsg(kegsOrTrackers("Keg Tag Created", "Tracker Tag Created"));
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      }
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleCheckboxChange = (e: any) => {
    e.persist();
    const index = e.target.name;
    const tracker = trackers[index];

    setTrackerChanges((prev: any) => {
      const curr = JSON.parse(JSON.stringify(prev));
      curr[tracker.id] = tracker;
      return curr;
    });
    setTrackers((prev: any) => {
      const curr = [...prev];
      curr[index].selected = e.target.checked;
      return curr;
    });
  };

  const columns: any = [];

  columns.push({
    id: "selected",
    Header: "Selected",
    accessor: "selected",
    minWidth: 120,
    maxWidth: 120,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "true") {
        return row[filter.id] === true;
      }
      if (filter.value === "false") {
        return row[filter.id] === false;
      }
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
    ),
    Cell: (props: any) => <Checkbox onChange={handleCheckboxChange} name={props.index} checked={props.value} />,
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  columns.push({
    id: "id",
    Header: "Tracker ID",
    accessor: "id",
    minWidth: 140,
    maxWidth: 140,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["id"],
      }),
    filterAll: true,
    Cell: (props: any) => {
      const url = kegOrTracker("kegs", "trackers");
      return (
        <Link title={props.value} to={`/${url}/${props.original.id}`}>
          {props.value}
        </Link>
      );
    },
  });

  columns.push({
    id: "trackerTypeName",
    Header: "Tracker Type",
    accessor: "trackerTypeName",
    minWidth: 160,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "none") {
        return row[filter.id] === undefined;
      }
      return row[filter.id] === filter.value;
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="none">None</option>
        {filterOptions.trackerTypeNames.map((label: any) => {
          return (
            <option key={label} value={label}>
              {label}
            </option>
          );
        }, [])}
      </select>
    ),
  });

  columns.push({
    id: "trackerTags",
    Header: kegsOrTrackers("Keg Tags", "Tracker Tags"),
    accessor: "trackerTags",
    minWidth: 140,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "none") {
        return row[filter.id] === undefined;
      }
      return row[filter.id] === filter.value;
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="none">None</option>
        {filterOptions.trackerTags.map((tag: any) => {
          return (
            <option key={tag} value={tag}>
              {tag}
            </option>
          );
        }, [])}
      </select>
    ),
    Cell: (props: any) =>
      props.original.trackerTags ? (
        props.original.trackerTags.sort(sortTags).map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
      ) : (
        <></>
      ),
  });

  // exists here to sort the rows by selected and prevent them bouncing around when editing
  columns.push({
    id: "initSelected",
    accessor: "initSelected",
    show: false,
  });

  const defaultSorted = [
    {
      id: "initSelected",
      desc: true,
    },
    {
      id: "id",
      desc: false,
    },
  ];

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      size={!submittedMsg && !submittingErr ? "lg" : "sm"}
      title={formData.id ? kegsOrTrackers("Edit Keg Tag", "Edit Tracker Tag") : kegsOrTrackers("Create Keg Tag", "Create Tracker Tag")}
      success={submittedMsg}
      error={submittingErr}
      submitBtnText={formData.id ? "Update" : "Create"}
      body={
        <LoadingContainer loading={trackersLoading || submitting} err={trackersErr}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer>
              <label>Tag Name</label>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <FormInput type="text" name="name" value={formData.name} error={formErrors.name} onChange={handleChange} />
                </div>
                <div style={{ width: "100%", maxWidth: "34px" }}>
                  <ColorPicker color={formData.colour} setColor={(color: any) => setFormData((prev: any) => ({ ...prev, colour: color.hex }))} />
                </div>
              </div>
              <FormError error={formErrors.name || formErrors.colour}>
                <span>{formErrors.name}</span>
                <span style={{ position: "absolute", right: "20px" }}>{formErrors.colour}</span>
              </FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <label>Description</label>
              <FormInput type="text" name="description" value={formData.description} error={formErrors.description} onChange={handleChange} />
              <FormError error={formErrors.description}>{formErrors.description}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <Table
                loading={trackersLoading}
                filterable={true}
                style={{ clear: "both" }}
                data={trackers}
                columns={columns}
                defaultSorted={defaultSorted}
                defaultPageSize={10}
              />
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default EditTrackerTagModel;
