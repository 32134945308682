import { FC } from "react";
import { DangerAlert } from "../Alerts";
import moment from "moment";

const TrackerMaintenanceAlert: FC<any> = ({ batteryPercentage, missedUploadCount, sleepCount, sleepDuration, latestSampleDate }) => {
  if (batteryPercentage == null && missedUploadCount == null && sleepCount == null && sleepDuration == null && latestSampleDate == null) {
    return <></>;
  }

  if (missedUploadCount == null && sleepCount != null && sleepDuration != null && latestSampleDate != null) {
    missedUploadCount = Math.floor((moment().unix() - latestSampleDate) / (sleepCount * sleepDuration * 60));
  }

  if (missedUploadCount > 2 || (batteryPercentage != null && batteryPercentage <= 10)) {
    return (
      <DangerAlert style={{ margin: 0, width: "100%" }}>
        Tracker Maintenance Suggested:{" "}
        {[...(missedUploadCount > 2 ? ["No Recent Uploads"] : []), ...(batteryPercentage != null && batteryPercentage <= 10 ? ["Poor Battery"] : [])].join(
          " and "
        )}
      </DangerAlert>
    );
  } else {
    return <></>;
  }
};

export default TrackerMaintenanceAlert;
