import axios from "axios";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { getReadyToFill } from "../../services/readyToFill";
import FillsKegsIcon from "../../svgs/FillsKegsIcon";
import InfoIcon from "../../svgs/Legend";
import errToStr from "../../util/errToStr";
import LoadingContainer from "../LoadingContainer";
import InfoTooltip from "../Tooltip";
import { Container, DateText, Header, Icon, IconContainer, InfoIconContainer, Value, ValueContainer } from "./styles";

const ReadyToFill: FC<any> = ({ filterDates }) => {
  const { color, color_15, short_date, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>(undefined);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    // If end date is undefined set to current date
    const dates = {
      end: filterDates && filterDates.end !== undefined ? filterDates.end.unix() : moment().unix(),
    };

    getReadyToFill(source, dates)
      .then((response: any) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData([]);
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [filterDates]);

  return (
    <LoadingContainer loading={dataLoading} err={dataErr}>
      {!dataLoading && (
        <Container>
          <IconContainer>
            <Icon>
              <svg viewBox="0 0 140 140" preserveAspectRatio="xMinYMin meet" style={{ maxHeight: "100%" }}>
                <g>
                  <circle r="50%" cx="50%" cy="50%" style={{ fill: color_15.primary[2] }}></circle>
                  <g style={{ transformOrigin: "center", transform: "scale(0.66)" }}>
                    <FillsKegsIcon width="100%" height="100%" fill={color.primary[2]} />
                  </g>
                </g>
              </svg>
            </Icon>
          </IconContainer>
          <ValueContainer>
            <Header>Kegs Ready to Fill</Header>
            {data !== undefined && <Value>{data}</Value>}
            {data !== undefined && (
              <DateText title={moment(filterDates.end).format(long_datetime)}>
                {filterDates.isNow ? "Now" : `On ${moment(filterDates.end).format(short_date)}`}
              </DateText>
            )}
          </ValueContainer>
          <InfoTooltip content={`Most recent number of kegs empty at a brewery`} touch={true}>
            <InfoIconContainer>
              <InfoIcon fill={color.font[2]} />
            </InfoIconContainer>
          </InfoTooltip>
        </Container>
      )}
    </LoadingContainer>
  );
};

export default ReadyToFill;
