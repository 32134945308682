import React, { FC, useState, useRef, useEffect } from "react";
import { SubmitModal } from "../Modal";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import AvatarEditor from "react-avatar-editor";
import axios, { CancelTokenSource } from "axios";
import { putPhoto } from "../../services/photo";
import LoadingContainer from "../LoadingContainer";
import errToStr from "../../util/errToStr";
import Slider from "../Slider";

const UploadPhotoModal: FC<any> = ({ modalOpen, setModalOpen, photo, trackerId, organisationId, placeId, userId, onSuccess, onError }) => {
  const editorRef = useRef<any>(undefined);

  const [scale, setScale] = useState<number>(1);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleSubmit = () => {
    if (editorRef.current) {
      setSubmitting(true);
      const canvas = editorRef.current.getImageScaledToCanvas();

      canvas.toBlob(
        (blob: any) => {
          const formData = new FormData();

          formData.append("file", blob);
          formData.append("name", photo.name);

          putPhoto(source, formData, trackerId, organisationId, placeId, userId)
            .then(() => {
              setSubmittedMsg("Photo Saved");
              onSuccess("Photo Saved");
              setSubmitting(false);
            })
            .catch((err) => {
              if (!axios.isCancel(err)) {
                setSubmittingErr(errToStr(err));
                onError(errToStr(err));
                setSubmitting(false);
              }
            });
        },
        "image/webp",
        1
      );
    }
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onClose={handleClose}
      title={submittedMsg ? "Photo Saved" : "Crop Photo"}
      success={submittedMsg}
      error={submittingErr}
      body={
        <LoadingContainer loading={submitting}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "column", alignSelf: "center" }}>
              <AvatarEditor
                style={{ marginBottom: "12px", width: "250px", height: "250px" }}
                ref={editorRef}
                image={photo}
                width={500}
                height={500}
                scale={scale}
                border={25}
                color={[122, 122, 122, 0.6]}
                borderRadius={trackerId || organisationId || placeId || userId ? 3 : 500}
                rotate={0}
              />
              <Slider
                hideNumberInput={true}
                name="scale"
                minRange={0.1}
                maxRange={10}
                step={0.1}
                value={scale}
                setValue={(value: number) => setScale(value)}
                onChange={(value: number) => setScale(value)}
              />
            </div>
          </div>
        </LoadingContainer>
      }
      footer={
        submittedMsg ? (
          <OutlineBtn onClick={handleClose}>Done</OutlineBtn>
        ) : submittingErr ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : (
          <>
            <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
            <PrimaryBtn onClick={() => handleSubmit()}>Upload</PrimaryBtn>
          </>
        )
      }
    />
  );
};

export default UploadPhotoModal;
