import React, { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { OutlineBtn, PrimaryBtn } from "../Buttons";
import SensorsAtPlace from "./sensorsAtPlace";
import PlaceGroups from "./placeGroups";
import DaysInPlace from "./daysInPlace";
import DaysSinceSeen from "./daysSinceSeen";
import Temperature from "./temperature";
import ProductTypes from "./productTypes";
import HideMarkers from "./hideMarkers";
import ReadyForCollection from "./readyForCollection";
import KegStatus from "./kegStatus";
import { InfoModal } from "../Modal";
import { isBinaryBeer } from "../../util/checkDomain";
import PlaceTags from "./placeTags";
import AssetTypes from "./assetTypes";
import TrackerTags from "./trackerTags";
import Beers from "./beers";
import Freshness from "./freshness";
import { Badge, BadgeNumber } from "./styles";
import { Bar } from "./styles";

const MapFilters: FC<any> = ({ sensorsRanges, placeRanges, sensorFilters, placeFilters }) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Controls if the modal is open or not
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  // URL query parameters from the filters in the filters modal
  const [modalQuery, setModalQuery] = useState<any>({});
  // Previous query parameters, used to check if filters should be updated
  const [prevModalQuery, setPrevModalQuery] = useState<any>(modalQuery);
  // Number of filters applied in the filters modal
  const [activeFiltersCount, setActiveFiltersCount] = useState<number>(0);

  const getButton = () => {
    if (activeFiltersCount > 0) {
      const label = `Filters`;
      return (
        <OutlineBtn
          title={label}
          onClick={() => setModalOpen(!modalOpen)}
          style={{ margin: "0px 6px", display: "flex", justifyContent: "center", alignItems: "center" }}
        >
          <Badge>
            <BadgeNumber>{activeFiltersCount}</BadgeNumber>
          </Badge>
          <span style={{ marginLeft: "6px" }}>{label}</span>
        </OutlineBtn>
      );
    }

    const label = "Filters";
    return (
      <OutlineBtn title={label} onClick={() => setModalOpen(!modalOpen)} style={{ margin: "0px 6px" }}>
        {label}
      </OutlineBtn>
    );
  };

  const updateQueryString = (queries?: any) => {
    const parsed = queryString.parse(location.search);

    const newQuery = {
      ...parsed,
      ...queries,
    };

    const stringified = queryString.stringify(newQuery);
    navigate({ ...location, search: stringified });
  };

  const resetFilters = () => {
    navigate({ ...location, search: "" });
  };

  const handleModalSave = () => {
    setModalOpen(false);
    if (JSON.stringify(modalQuery) !== JSON.stringify(prevModalQuery)) {
      updateQueryString(modalQuery);
    }
    setPrevModalQuery(modalQuery);
  };

  const isFiltersResettable = () => {
    const activeSensorFilters = Object.keys(sensorFilters);
    const activePlaceFilters = Object.keys(placeFilters);

    // if there are any active sensor filters the filters are resettable
    if (activeSensorFilters.length > 0) return true;

    // if there are more than 1 active place filters the filters are resettable
    if (activePlaceFilters.length > 1) return true;

    // if there is 1 active place filters and it is minSensors = 1 the filters
    // are NOT resettable, otherwise they are resettable
    if (activePlaceFilters.length === 1) {
      if (activePlaceFilters.includes("minSensors")) {
        if (placeFilters.minSensors === "1") {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    }

    // if there is 0 active place filters the filters are resettable
    // this is because the default sensors minSensors filter is 1 so
    // it is only added to the place filters when it isn't 1
    if (activePlaceFilters.length === 0) {
      return true;
    }

    return false;
  };

  const renderFilters = () => {
    const filters = [
      ...(isBinaryBeer() ? [<Beers key="Beers-Modal" sensorFilters={sensorFilters} setModalQuery={setModalQuery} modalView={true} />] : []),
      ...(isBinaryBeer() ? [<KegStatus key="KegStatus-Modal" sensorFilters={sensorFilters} setModalQuery={setModalQuery} modalView={true} />] : []),
      ...(isBinaryBeer()
        ? [<ReadyForCollection key="ReadyForCollection-Modal" sensorFilters={sensorFilters} setModalQuery={setModalQuery} modalView={true} />]
        : []),
      <SensorsAtPlace key="SensorsAtPlace-Modal" placeRanges={placeRanges} placeFilters={placeFilters} setModalQuery={setModalQuery} />,
      <HideMarkers key="HideMarkers" sensorFilters={sensorFilters} placeFilters={placeFilters} setModalQuery={setModalQuery} modalView={true} />,
      <AssetTypes key="AssetTypes-Modal" sensorFilters={sensorFilters} setModalQuery={setModalQuery} modalView={true} />,
      <TrackerTags key="TrackerTags-Modal" sensorFilters={sensorFilters} setModalQuery={setModalQuery} modalView={true} />,
      <PlaceGroups key="PlaceGroups-Modal" placeFilters={placeFilters} setModalQuery={setModalQuery} modalView={true} />,
      <PlaceTags key="PlaceTags-Modal" placeFilters={placeFilters} setModalQuery={setModalQuery} modalView={true} />,
      <Temperature key="Temperature-Modal" sensorsRanges={sensorsRanges} sensorFilters={sensorFilters} setModalQuery={setModalQuery} modalView={true} />,
      ...(isBinaryBeer()
        ? [<Freshness key="Freshness-Modal" sensorsRanges={sensorsRanges} sensorFilters={sensorFilters} setModalQuery={setModalQuery} modalView={true} />]
        : []),
      <DaysInPlace key="DaysInPlace-Modal" sensorsRanges={sensorsRanges} sensorFilters={sensorFilters} setModalQuery={setModalQuery} modalView={true} />,
      <DaysSinceSeen key="DaysSinceSeen-Modal" sensorsRanges={sensorsRanges} sensorFilters={sensorFilters} setModalQuery={setModalQuery} modalView={true} />,
      <ProductTypes key="ProductTypes-Modal" sensorFilters={sensorFilters} setModalQuery={setModalQuery} modalView={true} />,
    ];

    let activeFilters = 0;

    const queryParams = Object.keys(queryString.parse(location.search));

    if (queryParams.includes("hideAtPlaces")) activeFilters++;
    if (queryParams.includes("hideInTransit")) activeFilters++;
    if (queryParams.includes("minSensors") || !queryParams.includes("maxSensors")) activeFilters++;
    if (queryParams.includes("hideSensors") || queryParams.includes("hidePlaces")) activeFilters++;
    if (queryParams.includes("assetTypes")) activeFilters++;
    if (queryParams.includes("trackerTags")) activeFilters++;
    if (queryParams.includes("placeGroups")) activeFilters++;
    if (queryParams.includes("placeTags")) activeFilters++;
    if (queryParams.includes("minTemperature") || queryParams.includes("maxTemperature")) activeFilters++;
    if ((isBinaryBeer() && queryParams.includes("minFreshness")) || queryParams.includes("maxFreshness")) activeFilters++;
    if (queryParams.includes("minDaysInPlace") || queryParams.includes("maxDaysInPlace")) activeFilters++;
    if (queryParams.includes("minDaysSinceSeen") || queryParams.includes("maxDaysSinceSeen")) activeFilters++;
    if (queryParams.includes("productIds")) activeFilters++;
    if (isBinaryBeer() && queryParams.includes("beers")) activeFilters++;
    if (isBinaryBeer() && queryParams.includes("kegStatus")) activeFilters++;
    if (isBinaryBeer() && queryParams.includes("readyForCollection")) activeFilters++;

    // update the number of active filters if changed
    if (activeFilters !== activeFiltersCount) {
      setActiveFiltersCount(activeFilters);
    }

    return filters;
  };

  return (
    <Bar>
      <div style={{ float: "left", whiteSpace: "nowrap" }}>
        {getButton()}
        <InfoModal
          isOpen={modalOpen}
          onClose={() => handleModalSave()}
          title="Filters"
          body={renderFilters()}
          footer={
            <>
              {isFiltersResettable() ? (
                <p
                  style={{
                    marginRight: "auto",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    resetFilters();
                    setModalQuery({});
                    setPrevModalQuery({});
                    setModalOpen(false);
                  }}
                >
                  Reset All
                </p>
              ) : (
                <p
                  style={{
                    marginRight: "auto",
                    textDecoration: "underline",
                    cursor: "not-allowed",
                    opacity: 0.2,
                  }}
                >
                  Reset All
                </p>
              )}
              <PrimaryBtn onClick={handleModalSave}>Apply Filters</PrimaryBtn>
            </>
          }
        />
      </div>
    </Bar>
  );
};

export default MapFilters;
