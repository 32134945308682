const hexToRgba = (hex: string, opacity: number) => {
  if (hex) {
    const hexNum = hex.replace("#", "");
    const r = parseInt(hexNum.substring(0, 2), 16);
    const g = parseInt(hexNum.substring(2, 4), 16);
    const b = parseInt(hexNum.substring(4, 6), 16);

    return `rgba(${r},${g},${b}, ${opacity / 100})`;
  }

  return hex;
};

export default hexToRgba;
