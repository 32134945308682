import React, { FC, useState, useEffect, useContext } from "react";
import LoadingContainer from "../LoadingContainer";
import { InfoModal } from "../Modal";
import { DangerAlert } from "../Alerts";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import Avatar from "react-avatar";
import { InviteContainer, InviteCard, InviteBody, InviteImg, InviteDate, InviteFooter } from "./styles";
import { postInvite } from "../../services/invite";
import moment from "moment";
import ReactTimeago from "react-timeago";
import Bold from "../Bold";
import { PrimaryBtn, OutlineBtn } from "../Buttons";
import { ThemeContext } from "styled-components";

const PendingInvitesModal: FC<any> = ({ invites, setInvites, onSuccess, modalOpen, setModalOpen }) => {
  const { long_datetime } = useContext(ThemeContext);

  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleAcceptInvite = (inviteId: string) => {
    const body = {
      action: "accept",
      inviteId,
    };

    setDataLoading(true);
    setDataErr("");

    postInvite(source, body)
      .then(() => {
        setInvites((prev: any) => prev.filter((row: any) => row.inviteId !== inviteId));
        onSuccess();
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const handleDeclineInvite = (inviteId: string) => {
    const body = {
      action: "cancel",
      inviteId,
    };

    setDataLoading(true);
    setDataErr("");

    postInvite(source, body)
      .then(() => {
        setInvites((prev: any) => prev.filter((row: any) => row.inviteId !== inviteId));
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(null));
          setDataLoading(false);
        }
      });
  };

  return (
    <InfoModal
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      title="Pending Invites"
      okayBtnText="Close"
      okayBtnProps={{ width: "100%" }}
      padding={0}
      body={
        <>
          <div style={{ overflowX: "hidden", overflowY: "auto" }}>
            <LoadingContainer loading={dataLoading}>
              {dataErr && <DangerAlert style={{ margin: "0" }}>Error: {dataErr}</DangerAlert>}
              <div
                style={{
                  position: "relative",
                  height: "100%",
                  width: "100%",
                }}
              >
                <InviteContainer>
                  {invites.map((invite: any) => (
                    <InviteCard key={invite.inviteId}>
                      <InviteBody>
                        <InviteImg>
                          <Avatar name={invite.organisationName} src={invite.orgPhotoUrl} size="50px" round={true} />
                        </InviteImg>
                        <div>
                          <Bold title={invite.invitedByEmail}>{invite.invitedByName}</Bold> invited you to join <Bold>{invite.organisationName}</Bold>
                          <InviteDate>
                            <ReactTimeago live={false} date={invite.invitedDate * 1000} title={moment.unix(invite.invitedDate).format(long_datetime)} />
                          </InviteDate>
                        </div>
                      </InviteBody>
                      <InviteFooter>
                        <OutlineBtn style={{ marginTop: "12px" }} onClick={() => handleDeclineInvite(invite.inviteId)}>
                          Decline
                        </OutlineBtn>
                        <PrimaryBtn style={{ marginLeft: "12px", marginTop: "12px" }} onClick={() => handleAcceptInvite(invite.inviteId)}>
                          Accept
                        </PrimaryBtn>
                      </InviteFooter>
                    </InviteCard>
                  ))}
                </InviteContainer>
              </div>
            </LoadingContainer>
          </div>
        </>
      }
    />
  );
};

export default PendingInvitesModal;
