import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 297 297" {...props}>
      <path d="M46.169 61.873a7.484 7.484 0 0010.488-1.408c22.071-28.917 55.546-45.5 91.843-45.5s69.772 16.584 91.843 45.5a7.471 7.471 0 005.954 2.942 7.485 7.485 0 005.942-12.022C227.312 18.729 189.501 0 148.5 0S69.688 18.729 44.762 51.385a7.484 7.484 0 001.407 10.488zM228.037 101.786c0 12.885 10.484 23.369 23.369 23.369s23.369-10.484 23.369-23.369-10.484-23.369-23.369-23.369-23.369 10.483-23.369 23.369zM275.322 132.91c-.054-.018-5.942-1.825-5.942-1.825a6.993 6.993 0 00-6.233 1.086l-11.741 8.791-11.741-8.791a6.989 6.989 0 00-6.233-1.086s-5.888 1.807-5.942 1.825a23.136 23.136 0 00-15.839 21.976v35.329c0 1.363.398 2.696 1.148 3.834l11.848 18.031v47.352a6.982 6.982 0 006.983 6.983h39.553a6.982 6.982 0 006.983-6.983V212.08l11.848-18.031a6.979 6.979 0 001.148-3.834v-35.329a23.137 23.137 0 00-15.84-21.976zM45.594 125.155c12.885 0 23.369-10.484 23.369-23.369S58.479 78.417 45.594 78.417s-23.37 10.483-23.37 23.369 10.484 23.369 23.37 23.369zM69.51 132.91c-.054-.018-5.942-1.825-5.942-1.825a6.993 6.993 0 00-6.233 1.086l-11.741 8.791-11.741-8.791a6.988 6.988 0 00-6.233-1.086s-5.888 1.807-5.942 1.825a23.136 23.136 0 00-15.839 21.976v35.329c0 1.363.398 2.696 1.148 3.834l11.848 18.031v47.352a6.982 6.982 0 006.983 6.983H65.37a6.982 6.982 0 006.983-6.983V212.08l11.848-18.031a6.979 6.979 0 001.148-3.834v-35.329A23.138 23.138 0 0069.51 132.91z" />
      <path d="M178.642 68.851c0-16.62-13.522-30.142-30.142-30.142s-30.142 13.522-30.142 30.142c0 16.621 13.522 30.142 30.142 30.142s30.142-13.521 30.142-30.142zM181.607 113.601l-.05-.016-8.603-2.64a2.672 2.672 0 00-3.295 1.64l-18.09 49.635c-1.044 2.863-5.093 2.863-6.137 0l-18.09-49.635a2.672 2.672 0 00-2.508-1.759c-.259 0-9.38 2.755-9.38 2.755-10.99 3.662-18.332 13.85-18.332 25.37v52.506c0 .521.153 1.031.439 1.467l18.876 28.724v72.68a2.672 2.672 0 002.672 2.672h58.783a2.672 2.672 0 002.672-2.672v-72.68l18.876-28.724c.286-.436.439-.946.439-1.467v-52.644a26.533 26.533 0 00-18.272-25.212z" />
      <path d="M155.568 112.556c-.747-.814-1.84-1.224-2.946-1.224h-8.245c-1.105 0-2.198.41-2.946 1.224a3.825 3.825 0 00-.504 4.505l4.407 6.644-2.063 17.405 4.063 10.808c.396 1.087 1.933 1.087 2.33 0l4.063-10.808-2.063-17.405 4.407-6.644a3.823 3.823 0 00-.503-4.505z" />
    </svg>
  );
}

export default SvgComponent;
