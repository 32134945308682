import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { postEditBeer, putEditBeer } from "../../services/editBeer";
import { ModalFormContainer } from "../Modal/styles";
import { FormInput, FormError, InputUnitWrapper } from "../FormComponents";
import { exists, max, maxLength, min } from "../../util/formValidations";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import Checkbox from "../Checkbox";
import { convertRoundedTemp, toCelsius, printTempUnit } from "../../util/formatUnits";
import addOrUpdate from "../../util/addOrUpdate";

const defaultBeer = {
  id: null,
  active: true,
  name: "",
  targetAbv: 4.5,
  lifeExpectancy: 90,
  temperature: 4,
  upsideDown: false,
};

const initBeerForm = (beer: any) => {
  const formData = {
    ...defaultBeer,
    ...beer,
  };

  formData.temperature = convertRoundedTemp(formData.temperature);

  return formData;
};

const EditBeerModal: FC<any> = ({ beer, setData, modalOpen, setModalOpen }) => {
  const [formData, setFormData] = useState<any>(initBeerForm(beer));
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "name":
          currValid = exists(name, value, setFormErrors) && maxLength(name, value, 200, setFormErrors);
          break;

        case "targetAbv":
          currValid = exists(name, value, setFormErrors) && min(name, value, 0, setFormErrors) && max(name, value, 100, setFormErrors);
          break;

        case "lifeExpectancy":
          currValid = exists(name, value, setFormErrors) && min(name, value, 1, setFormErrors) && max(name, value, 2147483647, setFormErrors);
          break;

        case "temperature":
          currValid =
            exists(name, value, setFormErrors) &&
            min(name, value, convertRoundedTemp(-100), setFormErrors) &&
            max(name, value, convertRoundedTemp(100), setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    return {
      id: formData.id,
      active: formData.active,
      name: formData.name.trim(),
      targetAbv: formData.targetAbv,
      lifeExpectancy: formData.lifeExpectancy,
      temperature: toCelsius(formData.temperature),
      upsideDown: formData.upsideDown,
    };
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);

      if (formData.id) {
        putEditBeer(source, body)
          .then((response) => {
            setData((prev: any) => addOrUpdate(prev, response, "id"));
            setSubmittedMsg("Beer Updated");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      } else {
        postEditBeer(source, body)
          .then((response) => {
            setData((prev: any) => addOrUpdate(prev, response, "id"));
            setSubmittedMsg("Beer Created");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      }
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleCheckboxChange = (e: any) => {
    e.persist();
    setFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      title={formData.id ? "Edit Beer" : "Create Beer"}
      success={submittedMsg}
      error={submittingErr}
      submitBtnText={formData.id ? "Update Beer" : "Create Beer"}
      body={
        <LoadingContainer loading={submitting}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer style={{ padding: "5px 0 19px" }}>
              <Checkbox style={{ display: "inline-block" }} name="active" label="Active" checked={formData.active} onChange={handleCheckboxChange} />
            </ModalFormContainer>
            <ModalFormContainer>
              <label>Beer Name</label>
              <FormInput
                type="text"
                name="name"
                placeholder="Beer Name"
                value={formData.name}
                error={formErrors.name}
                onChange={handleChange}
                required={true}
              />
              <FormError error={formErrors.name}>{formErrors.name}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <label>Target ABV</label>
              <InputUnitWrapper unit="%">
                <FormInput
                  type="number"
                  name="targetAbv"
                  value={formData.targetAbv}
                  error={formErrors.targetAbv}
                  onChange={handleChange}
                  required={true}
                  step={0.1}
                  min={0}
                  max={100}
                />
              </InputUnitWrapper>
              <FormError error={formErrors.targetAbv}>{formErrors.targetAbv}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <label>Life Expectancy</label>
              <InputUnitWrapper unit="days">
                <FormInput
                  type="number"
                  name="lifeExpectancy"
                  value={formData.lifeExpectancy}
                  error={formErrors.lifeExpectancy}
                  onChange={handleChange}
                  required={true}
                  step={1}
                  min={0}
                  max={1000}
                />
              </InputUnitWrapper>
              <FormError error={formErrors.lifeExpectancy}>{formErrors.lifeExpectancy}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <label>Storage Temperature</label>
              <InputUnitWrapper unit={printTempUnit()}>
                <FormInput
                  type="number"
                  name="temperature"
                  value={formData.temperature}
                  error={formErrors.temperature}
                  onChange={handleChange}
                  required={true}
                  step={1}
                  min={convertRoundedTemp(-100)}
                  max={convertRoundedTemp(100)}
                />
              </InputUnitWrapper>
              <FormError error={formErrors.temperature}>{formErrors.temperature}</FormError>
            </ModalFormContainer>
            <ModalFormContainer style={{ padding: "5px 0 19px" }}>
              <Checkbox
                style={{ display: "inline-block" }}
                name="upsideDown"
                label="Kegs will be turned upside down when empty"
                checked={formData.upsideDown}
                onChange={handleCheckboxChange}
              />
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default EditBeerModal;
