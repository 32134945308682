import React, { FC, useContext } from "react";
import { InfoModal } from "../Modal";
import Table from "../Table";
import matchSorter from "match-sorter";
import { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { kegsOrTrackers, kegOrTracker } from "../../util/kegOrTracker";
import moment from "moment";
import FlatpickrRangePicker from "../FlatpickrRangePicker";

const BeerBatchKegsListModal: FC<any> = ({ modalOpen, setModalOpen, data, title }) => {
  const { short_datetime, short_date } = useContext(ThemeContext);

  const columns = [];

  columns.push({
    id: "sensorId",
    Header: "Tracker ID",
    accessor: "sensorId",
    minWidth: 160,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["sensorId"],
      }),
    filterAll: true,
    Cell: (props: any) => {
      const url = kegOrTracker("kegs", "trackers");
      return (
        <Link onClick={() => setModalOpen(false)} title={props.value} to={`/${url}/${props.original.sensorId}`}>
          {props.value}
        </Link>
      );
    },
  });

  columns.push({
    id: "dateFilled",
    Header: "Date Filled",
    accessor: "dateFilled",
    minWidth: 180,
    maxWidth: 180,
    filterMethod: (filter: any, row: any) => {
      if (filter.value.length === 2) {
        if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
        else return false;
      } else return true;
    },
    Filter: ({ onChange }: any) => (
      <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
    ),
    Cell: (props: any) => (props.value ? moment.unix(props.value).format(short_datetime) : ""),
  });

  columns.push({
    id: "dateEmptied",
    Header: "Date Emptied",
    accessor: "dateEmptied",
    minWidth: 180,
    maxWidth: 180,
    filterMethod: (filter: any, row: any) => {
      if (filter.value.length === 2) {
        if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
        else return false;
      } else return true;
    },
    Filter: ({ onChange }: any) => (
      <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
    ),
    Cell: (props: any) => (props.value ? moment.unix(props.value).format(short_datetime) : "-"),
  });

  const defaultSorted = [
    {
      id: "dateFilled",
      desc: true,
    },
  ];

  return (
    <InfoModal
      isOpen={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      title={title}
      okayBtnText="Close"
      okayBtnProps={{ width: "100%" }}
      body={<Table filterable={true} style={{ clear: "both" }} data={data} columns={columns} defaultSorted={defaultSorted} defaultPageSize={15} />}
    />
  );
};

export default BeerBatchKegsListModal;
