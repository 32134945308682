import axios, { CancelTokenSource } from "axios";
import { getToken } from "../localStorage";

export const postMarkAsEmpty = async (source: CancelTokenSource, sensorId: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/api/markasempty",
    data: JSON.stringify({ sensorId }),
  });

  return data;
};
