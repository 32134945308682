import styled from "../Themes";

export const UpdateImageButton = styled(`div`)`
  position: absolute;
  bottom: -12px;
  right: -12px;
  background: ${({ theme }) => theme.color.main_bg[2]};
  fill: ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 50%;
  padding: 5px;
`;

export const UpdateImageButtonContainer = styled(`div`)`
  border: 1px solid ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;

  &:hover {
    fill: ${({ theme }) => theme.color.primary[2]};
    border: 1px solid ${({ theme }) => theme.color.primary[2]};
  }
`;

export const CameraIconContainer = styled(`div`)`
  width: 16px;
  height: 16px;
  margin-top: -1px;
`;

export const BoxesContainer = styled(`div`)`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  @media (max-width: 768px) {
    flex-direction: column;
    flex-wrap: initial;
  }
`;

export const BoxElement = styled(`div`)`
  padding: 7px 7px;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  box-shadow: ${({ theme }) => theme.boxShadow.s};
  min-height: 69px;
  flex-basis: 49%;
  flex-grow: 1;

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);
`;

export const ContentContainer = styled(`div`)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

export const ValueContainer = styled(`div`)`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 10px;
  overflow: hidden;
  height: 100%;
  justify-content: center;
`;

export const IconContainer = styled(`div`)`
  align-items: center;
  flex: 0 0 32px;
  justify-content: center;
  position: relative;
  height: 100%;
`;

export const Icon = styled(`div`)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

export const Header = styled(`span`)`
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.font[2]};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Footer = styled(`span`)`
  font-size: 12px;
  line-height: 17px;
  color: ${({ theme }) => theme.color.font[2]};
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  width: 100%;
`;

export const Value = styled(`span`)`
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.font[2]};
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  width: 100%;
`;
