import axios from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import moment from "moment";
import React, { FC, useContext, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { fetchReport12 } from "../../../services/report12";
import downloadFile from "../../../util/downloadFile";
import errToStr from "../../../util/errToStr";
import LoadingContainer from "../../LoadingContainer";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import Table from "../../Table";
import Tooltip from "../../Tooltip";
import { ClearPanel, Panel, StatPanel } from "../../Panel";
import { MenuButton, MenuList } from "../../Tooltip/styles";
import { isAdminOrUser } from "../../../util/checkRole";
import MoreIcon from "../../../svgs/MoreIcon";
import { MoreIconContainer, MoreIconSize } from "../../UsersScreen/styles";
import MarkAsFilledModal from "../../MarkAsFilledModal";
import { v4 as uuidv4 } from "uuid";
import { PrimaryBtn } from "../../Buttons";
import { Title, Subtitle } from "../../PageTitle/styles";
import { StatBlock, StatLabel, StatValue } from "../styles";
import FlatpickrRangePicker from "../../FlatpickrRangePicker";
import { IconContext } from "react-icons";
import { TbDownload } from "react-icons/tb";

const formatDataToCsv = (tableRef: any) => {
  const headers = ["Tracker ID", "Place", "Date Left"];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [row.sensorId, row.placeName, moment.unix(row.dateLeft).format()];
    }, []),
  ];
};

const LeftBreweryWithoutFill: FC<any> = () => {
  const { color, short_datetime, short_date } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<any>(undefined);

  const [markAsFilledModalOpen, setMarkAsFilledModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchReport12(source)
      .then((response) => {
        // Add unique ids so a row can be found and removed after filling kegs
        setData(response.map((e: any) => ({ ...e, id: uuidv4() })));
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData([]);
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  const columns = [
    {
      Header: "Actions",
      minWidth: 110,
      maxWidth: 110,
      filterable: false,
      sortable: false,
      Cell: ({ original }: any) => {
        return (
          <Tooltip
            maxWidth="none"
            theme="binary-no-padding"
            content={
              <MenuList>
                {isAdminOrUser() ? (
                  <MenuButton
                    onClick={() => {
                      setSelectedRow(original);
                      setMarkAsFilledModalOpen(true);
                    }}
                  >
                    Create Fill Event
                  </MenuButton>
                ) : (
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <MenuButton disabled={true}>Create Fill Event</MenuButton>
                    </div>
                  </Tooltip>
                )}
              </MenuList>
            }
            interactive={true}
            touch={true}
            appendTo={document.body}
            trigger="click"
            placement="bottom-start"
          >
            <MoreIconContainer>
              <MoreIconSize>
                <MoreIcon fill={color.font[2]} />
              </MoreIconSize>
            </MoreIconContainer>
          </Tooltip>
        );
      },
    },
    {
      id: "sensorId",
      Header: "Tracker ID",
      accessor: "sensorId",
      Cell: (props: any) => <Link to={`/kegs/${props.value}`}>{props.value}</Link>,
      minWidth: 130,
    },
    {
      id: "placeName",
      Header: "Place",
      accessor: "placeName",
      Cell: (props: any) => <Link to={`/places/${props.original.placeId}`}>{props.value}</Link>,
      minWidth: 180,
    },
    {
      id: "dateLeft",
      Header: "Date Left",
      accessor: "dateLeft",
      filterMethod: (filter: any, row: any) => {
        if (filter.value.length === 2) {
          if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
          else return false;
        } else return true;
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
      ),
      Cell: (props: any) => moment.unix(props.value).format(short_datetime),
      minWidth: 160,
    },
  ];

  const defaultSorted = [
    {
      id: "dateLeft",
      desc: true,
    },
  ];

  return (
    <>
      <PageBreadcrumbs prevRoutes={[{ slug: "/reports", title: "Reports" }]} currRoute="Left Brewery Empty" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading} err={dataErr}>
            <ClearPanel style={{ textAlign: "center" }}>
              <Title style={{ marginBottom: "6px" }}>Left Brewery Empty</Title>
              <Subtitle>Kegs which departed a brewery without a fill event from a scanner or user</Subtitle>
            </ClearPanel>
            <ClearPanel style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div></div>
              <PrimaryBtn
                style={{ height: "40px", minWidth: "unset", marginRight: "12px" }}
                padding="0 6px"
                onClick={() =>
                  downloadFile(
                    stringify(formatDataToCsv(tableRef), {
                      quoted: true,
                      quoted_string: true,
                    }),
                    "text/csv;charset=utf-8",
                    "Left Brewery Empty.csv"
                  )
                }
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "inline-block",
                      width: "20px",
                      height: "20px",
                      fontSize: "0",
                      marginRight: "6px",
                    }}
                  >
                    <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                      <TbDownload />
                    </IconContext.Provider>
                  </div>
                  <span
                    style={{
                      marginRight: "6px",
                    }}
                  >
                    CSV
                  </span>
                </div>
              </PrimaryBtn>
            </ClearPanel>
            <StatPanel>
              <StatBlock>
                <StatLabel>Missing Fill Events</StatLabel>
                <StatValue>{data.length}</StatValue>
              </StatBlock>
            </StatPanel>
            <Panel>
              <Table
                loading={dataLoading}
                filterable={true}
                style={{ clear: "both" }}
                data={data}
                columns={columns}
                defaultSorted={defaultSorted}
                ref={tableRef}
              />
            </Panel>
          </LoadingContainer>
        </div>
      </PageContainer>
      {markAsFilledModalOpen && (
        <MarkAsFilledModal
          sensor={{ id: selectedRow.id, sensorId: selectedRow.sensorId }}
          place={{ placeId: selectedRow.placeId, placeName: selectedRow.placeName }}
          onSuccess={(e: any) => setData((prev: any) => prev.filter((row: any) => row.id !== e.id))}
          noDate={false}
          modalOpen={markAsFilledModalOpen}
          setModalOpen={setMarkAsFilledModalOpen}
        />
      )}
    </>
  );
};

export default LeftBreweryWithoutFill;
