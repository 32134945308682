import ReactEcharts from "echarts-for-react";
import moment from "moment";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import useWindowSize from "../../util/useWindowSize";
import { freshSeries, freshTooltip, freshYAxis, publicTempSeries, publicTempTooltip, publicTempYAxis } from "../Charts";

const tooltips: any = {
  temp: publicTempTooltip,
  fresh: freshTooltip,
};

// Calculates the eCharts grids for the sensor log charts
// Depending on the number of charts, this function generates the grids and container
// height in order to make everything fit nicely.
const calculateGrid = (dimensions: any, setHeight: any, width: number) => {
  const len = dimensions.length;
  const grid = [];
  const chartHeight = len === 1 ? 200 : len === 2 ? 124 : 100;

  // Adjust padding based on width
  const chartPadding = width < 500 ? 20 : 30;
  const xAxisLabelPadding = width < 500 ? 20 : 40;
  const leftPadding = width < 500 ? 40 : 80;
  const rightPadding = width < 500 ? 10 : 40;

  setHeight(len * (chartHeight + chartPadding) + xAxisLabelPadding);

  for (let i = 0; i < len; i++) {
    grid.push({
      right: rightPadding,
      left: leftPadding,
      top: i === 0 ? chartPadding : chartPadding + i * chartPadding + i * chartHeight, // Keep top unchanged
      height: chartHeight,
    });
  }
  return grid;
};

const PhoneScanLogsChart: FC<any> = ({ meta, logs, seriesNames, country }) => {
  const { echart_colors, short_datetime, echarts_theme, echarts_opacity } = useContext(ThemeContext);
  const { width } = useWindowSize();

  const options = useRef<any>({});
  const echartsContainerRef = useRef(null);

  const [echartsInstance, setEchartsInstance] = useState<any>(undefined);
  const [height, setHeight] = useState<number>(550);
  const [genericData, setGenericData] = useState<any>([]);

  // Removes freshness from logs if not a keg
  useEffect(() => {
    let newData = logs;

    if (!meta.hasBeer) {
      newData = logs.map(({ fresh, ...keep }: any) => keep);
    }

    setGenericData(newData);
  }, [logs, meta]);

  useEffect(() => {
    if (echartsInstance) {
      const dimensions = seriesNames;

      const index = dimensions.indexOf("ts");
      if (index > -1) {
        dimensions.splice(index, 1);
      }

      // if there are more than 5000 rows of logs, downsample the logs using the max values
      // overwise don't do any downsampling
      const sampling = "none";

      let containerWidth = width;

      if (echartsContainerRef.current) {
        // @ts-ignore
        containerWidth = echartsContainerRef.current.offsetWidth;
      }

      const grid = calculateGrid(dimensions, setHeight, containerWidth);
      const xAxis = [];
      const yAxis = [];
      const series = [];
      const dataZoom =
        dimensions.length > 0
          ? [
              {
                type: "inside",
                xAxisIndex: Array.from(Array(dimensions.length).keys()),
              },
            ]
          : [];

      for (let i = 0; i < dimensions.length; i++) {
        xAxis.push(
          i !== dimensions.length - 1
            ? {
                gridIndex: i,
                type: "time",
                min: "dataMin",
                max: "dataMax",
                axisLabel: {
                  hideOverlap: true,
                  show: false,
                },
                axisPointer: {
                  label: {
                    show: false,
                  },
                },
              }
            : {
                gridIndex: i,
                type: "time",
                min: "dataMin",
                max: "dataMax",
                axisLabel: {
                  hideOverlap: true,
                  formatter: (value: any): string => moment.unix(value).format(short_datetime),
                },
                axisPointer: {
                  label: {
                    show: false,
                  },
                },
              }
        );

        switch (dimensions[i]) {
          case "temp":
            yAxis.push(publicTempYAxis(i, country));
            series.push(publicTempSeries(i, sampling, echarts_opacity, echart_colors));
            break;
          case "fresh":
            if (meta.hasBeer) {
              yAxis.push(freshYAxis(i));
              series.push(freshSeries(i, sampling, echarts_opacity, echart_colors));
            }
            break;
        }
      }

      const tooltipFormatter = (params: any) => {
        if (params.length > 0) {
          const ts = params[0].data.ts || params[0].data[0];
          let tooltip = `<b>${moment.unix(ts).format(short_datetime)}</b><br />`;

          for (let i = 0; i < params.length; i++) {
            if (params[i].seriesId !== "events") {
              const tooltipFunction = tooltips[params[i].seriesId];
              if (tooltipFunction) {
                tooltip = tooltipFunction(params[i], tooltip, {});
              }
            }
          }

          tooltip = `<span style="word-break: keep-all;white-space: normal;">` + tooltip + "</span>";
          return tooltip;
        }
      };

      options.current = {
        grid: grid,
        dataset: {
          dimensions: ["ts", ...dimensions],
          source: genericData,
        },
        tooltip: {
          trigger: "axis",
          transitionDuration: 0,
          axisPointer: {
            type: "cross",
            snap: true,
          },
          formatter: tooltipFormatter,
        },
        xAxis: xAxis,
        yAxis: yAxis,
        axisPointer: {
          link: { xAxisIndex: "all" },
          snap: true,
        },
        dataZoom: dataZoom,
        series: series,
        animation: false,
      };

      // Updates the options of the chart each render
      // This is mainly to update the splitNumber on the bottom time axis
      if (options && options.current) {
        echartsInstance.setOption(options.current);
      }
    }
  }, [echartsInstance, genericData, short_datetime, echarts_opacity, country, echart_colors, meta, seriesNames]);

  // Checks width of chart when screen width changes and sets splitNumber for x-axis to 1 if width is less than 500px
  // This is to prevent the x-axis datetime labels from overlapping on mobile
  useEffect(() => {
    if (echartsContainerRef.current) {
      // @ts-ignore
      const width = echartsContainerRef.current.offsetWidth;

      // // Hide y-axis labels if width is less than 500px
      // if (options && options.current && options.current.yAxis && options.current.yAxis.length > 0) {
      //   options.current.yAxis.forEach((axis: any) => {
      //     axis.axisLabel.show = width >= 500;
      //   });
      // }

      // // Hide x-axis labels and tick marks if width is less than 500px
      // if (options && options.current && options.current.xAxis && options.current.xAxis.length > 0) {
      //   options.current.xAxis.forEach((axis: any) => {
      //     axis.axisLabel.show = width >= 500;

      //     if (axis.axisTick) axis.axisTick.show = width >= 500;
      //     else {
      //       axis.axisTick = {
      //         show: width >= 500,
      //       };
      //     }
      //   });
      // }

      // Recalculate and update grid if width changes
      if (options && options.current && options.current.grid) {
        options.current.grid = calculateGrid(seriesNames, setHeight, width);
      }

      // Update the chart with the new options
      if (echartsInstance && options && options.current) {
        echartsInstance.setOption(options.current);
      }
    }
  }, [echartsInstance, width]);

  // listens for window resize events and triggers a chart resize so it fits
  // properly within it's container, fixes issue on iOS tablet when screen rotates
  const handleResizeEvent = useCallback(() => {
    echartsInstance.resize();
  }, [echartsInstance]);

  useEffect(() => {
    window.removeEventListener("resize", handleResizeEvent);

    if (echartsInstance) {
      window.addEventListener("resize", handleResizeEvent);
    }

    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, [echartsInstance, handleResizeEvent]);

  return (
    <>
      <div style={{ height: "100%", width: "100%" }} ref={echartsContainerRef}>
        {options && (
          <ReactEcharts
            key="sensor-logs-chart"
            onChartReady={(chart: any) => setEchartsInstance(chart)}
            style={{ height: height, width: "100%" }}
            option={options.current}
            notMerge={true}
            lazyUpdate={true}
            theme={echarts_theme}
            opts={{ renderer: "svg" }}
          />
        )}
      </div>
    </>
  );
};

export default PhoneScanLogsChart;
