import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce";
import { getAllContents } from "../../services/contents";
import { contentsTableDefaults, getContentsTableSettings, saveContentsTableSettings } from "../../services/localStorage";
import MoreIcon from "../../svgs/MoreIcon";
import { isAdminOrUser } from "../../util/checkRole";
import copyToClipboard from "../../util/copyToClipboard";
import downloadFile from "../../util/downloadFile";
import errToStr from "../../util/errToStr";
import sortTags from "../../util/sortTags";
import { getTableFilters } from "../../util/urlParamFilters";
import AddContentsModal from "../AddContentsModal";
import Badge from "../Badge";
import { OutlineBtn, PrimaryBtn } from "../Buttons";
import EditContentsModal from "../EditContentsModal";
import { OutlineIconBtn, PrimaryIconBtn } from "../IconButtons";
import NewTable from "../NewTable";
import { DesktopDiv, MobileDiv } from "../NewTable/styles";
import RemoveAllContentsModal from "../RemoveAllContentsModal";
import RemoveContentsModal from "../RemoveContentsModal";
import ScanRemoveContentsModal from "../ScanRemoveContentsModal";
import Tag from "../Tag";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

type Contents = {
  id: string;
  identifier: string;
  manifestId: string;
  manifestName: string;
  organisationId: string;
  organisationName: string;
  name: string;
  description: string;
  dateAddedUnix: number;
  dateAdded: string;
  dateRemovedUnix: number;
  dateRemoved: string;
  userAdded: string;
  userRemoved: string;
  placeAddedId: number;
  placeAddedName: string;
  placeRemovedId: string;
  placeRemovedName: string;
  contentTags: string;
  removed: boolean;
};

const ManifestContentsTable: FC<any> = ({ manifestId = "", placeId, placeName }) => {
  const { color, short_datetime, short_date, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>([{ id: "dateAddedUnix", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [selectedItem, setSelectedItem] = useState<any>(undefined);

  const [addModalOpen, setAddModalOpen] = useState<any>(false);
  const [editModalOpen, setEditModalOpen] = useState<any>(false);
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
  const [scanRemoveModalOpen, setScanRemoveModalOpen] = useState<boolean>(false);
  const [removeAllModalOpen, setRemoveAllModalOpen] = useState<boolean>(false);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getContentsTableSettings() ? getContentsTableSettings().pageSize : 20,
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // if manifestId is not empty, add a filter to the column filters to filter out only contents for that manifest (for the manifest page table)
  const manifestIdFilter = (filters: any) => {
    if (manifestId === "") return filters;

    const filtersCopy = JSON.parse(JSON.stringify(filters));
    const filter = filtersCopy.find((x: any) => x.id === "manifestId");
    if (filter) filter.value = { type: "number", value: manifestId };
    else filtersCopy.push({ id: "manifestId", value: { type: "number", value: manifestId } });
    return filtersCopy;
  };

  const fetchContents = () => {
    setDataLoading(true);
    setDataErr("");

    getAllContents(source, true, { pageIndex, pageSize, orderBy: sorting, filters: getTableFilters(manifestIdFilter(columnFiltersDebounced)) })
      .then((response) => {
        setData(response.data);
        setCount(response.count);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const fetchCsv = (download: boolean) => {
    setDataLoading(true);
    setDataErr("");

    getAllContents(source, true, { orderBy: sorting, filters: getTableFilters(manifestIdFilter(columnFiltersDebounced)) })
      .then((response) => {
        if (download) {
          downloadFile(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            }),
            "text/csv;charset=utf-8",
            "Contents List.csv"
          );
        } else {
          copyToClipboard(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            })
          );
          toast.info("Copied to Clipboard");
        }
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const formatDataToCsv = (data: any) => {
    const headers = [
      "Status",
      "Contents ID",
      "Name",
      "Description",
      "Content Tags",
      "Date Added",
      "Date Removed",
      "Place Added",
      "Place Removed",
      "User Added",
      "User Removed",
    ];

    return [
      headers,
      ...data.map((row: any) => {
        const rows = [
          row.removed ? "Removed" : "Current",
          row.identifier,
          row.name,
          row.description,
          row.contentTags
            .sort(sortTags)
            .map((tag: any) => tag.name)
            .join(", "),
          row.dateAddedUnix !== undefined ? moment.unix(row.dateAddedUnix).format(long_datetime) : "",
          row.dateRemovedUnix !== undefined ? moment.unix(row.dateRemovedUnix).format(long_datetime) : "",
          row.placeAddedName,
          row.placeRemovedName,
          row.userAdded,
          row.userRemoved,
        ];

        return rows;
      }, []),
    ];
  };

  const columns = React.useMemo<ColumnDef<Contents>[]>(
    () => [
      {
        id: "actions",
        header: "",
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          if (row.original.removed) return <></>;
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  {isAdminOrUser() ? (
                    <>
                      <MenuButton
                        onClick={() => {
                          setSelectedItem(row.original);
                          setEditModalOpen(true);
                        }}
                      >
                        Edit Item
                      </MenuButton>
                      <DangerMenuButton
                        onClick={() => {
                          setSelectedItem(row.original);
                          setRemoveModalOpen(true);
                        }}
                      >
                        Remove Item
                      </DangerMenuButton>
                    </>
                  ) : (
                    <>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Edit Item</MenuButton>
                        </div>
                      </Tooltip>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <DangerMenuButton disabled={true}>Remove Item</DangerMenuButton>
                        </div>
                      </Tooltip>
                    </>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        size: 65,
        minSize: 65,
      },
      {
        header: "Status",
        accessorKey: "removed",
        cell: (props: any) => {
          return (
            <div style={{ textAlign: "center" }}>
              <Badge title={props.getValue() ? "Removed" : "Current"} background={props.getValue() ? color.danger_dark[2] : color.success_dark[2]}>
                {props.getValue() ? "Removed" : "Current"}
              </Badge>
            </div>
          );
        },
        meta: {
          filterType: "select",
          loadOptionsKey: "removed",
          selectKey: "value",
          selectOptions: [
            { value: true, label: "Removed", colour: color.danger_dark[2] },
            { value: false, label: "Current", colour: color.success_dark[2] },
          ],
        },
        filterFn: undefined,
        size: 160,
      },
      {
        header: "Contents ID",
        accessorKey: "identifier",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 200,
      },
      {
        header: "Name",
        accessorKey: "name",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 200,
      },
      {
        header: "Description",
        accessorKey: "description",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
      {
        accessorKey: "contentTags",
        cell: (props: any) =>
          props.row.original.contentTags ? (
            props.row.original.contentTags
              .sort(sortTags)
              .map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
          ) : (
            <></>
          ),
        header: "Content Tags",
        enableSorting: false,
        meta: {
          filterType: "autoComplete",
          filterKey: "contentTags.name",
          loadOptionsKey: "contentTags",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Date Added",
        accessorKey: "dateAddedUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRangeUnix",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Date Removed",
        accessorKey: "dateRemovedUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRangeUnix",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Place Added",
        accessorKey: "placeAddedName",
        cell: (props: any) => (
          <Link title={props.getValue()} to={`/places/${props.row.original.placeAddedId}`}>
            {props.getValue()}
          </Link>
        ),
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "Place Removed",
        accessorKey: "placeRemovedName",
        cell: (props: any) => (
          <Link title={props.getValue()} to={`/places/${props.row.original.placeRemovedId}`}>
            {props.getValue()}
          </Link>
        ),
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 180,
      },
      {
        header: "User Added",
        accessorKey: "userAdded",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
      {
        header: "User Removed",
        accessorKey: "userRemoved",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 250,
      },
    ],
    [color]
  );

  return (
    <div style={{ position: "relative" }}>
      <NewTable
        data={data}
        count={count}
        dataErr={dataErr}
        dataLoading={dataLoading}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        columnFiltersDebounced={columnFiltersDebounced}
        setColumnFilters={setColumnFilters}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        fetchData={fetchContents}
        fetchCsv={fetchCsv}
        defaultTableSettings={contentsTableDefaults}
        getTableSettings={getContentsTableSettings}
        saveTableSettings={saveContentsTableSettings}
        dataTypeName="Contents"
        emptyDataMsg="Add contents to manifest to get started"
        TableButtons={
          <div style={{ display: "flex" }}>
            {isAdminOrUser() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setSelectedItem(undefined);
                      setAddModalOpen(true);
                    }}
                  >
                    Add
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Add">
                    <PrimaryIconBtn
                      onClick={() => {
                        setSelectedItem(undefined);
                        setAddModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
                <DesktopDiv>
                  <OutlineBtn
                    style={{ marginLeft: "12px" }}
                    onClick={() => {
                      setSelectedItem(undefined);
                      setScanRemoveModalOpen(true);
                    }}
                  >
                    Remove
                  </OutlineBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Remove">
                    <OutlineIconBtn
                      onClick={() => {
                        setSelectedItem(undefined);
                        setScanRemoveModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                        <IoClose />
                      </IconContext.Provider>
                    </OutlineIconBtn>
                  </Tooltip>
                </MobileDiv>
                <DesktopDiv>
                  <OutlineBtn
                    style={{ marginLeft: "12px" }}
                    onClick={() => {
                      setSelectedItem(undefined);
                      setRemoveAllModalOpen(true);
                    }}
                  >
                    Remove All
                  </OutlineBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Remove All">
                    <OutlineIconBtn
                      onClick={() => {
                        setSelectedItem(undefined);
                        setRemoveAllModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                        <IoClose />
                      </IconContext.Provider>
                    </OutlineIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div style={{ cursor: "not-allowed", userSelect: "none" }}>
                      <PrimaryBtn disabled={true}>Add</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div style={{ cursor: "not-allowed", userSelect: "none" }}>
                      <PrimaryIconBtn disabled={true}>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div style={{ cursor: "not-allowed", userSelect: "none" }}>
                      <OutlineBtn style={{ marginLeft: "12px" }} disabled={true}>
                        Remove
                      </OutlineBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div style={{ cursor: "not-allowed", userSelect: "none" }}>
                      <OutlineIconBtn disabled={true}>
                        <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                          <IoClose />
                        </IconContext.Provider>
                      </OutlineIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div style={{ cursor: "not-allowed", userSelect: "none" }}>
                      <OutlineBtn style={{ marginLeft: "12px" }} disabled={true}>
                        Remove All
                      </OutlineBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div style={{ cursor: "not-allowed", userSelect: "none" }}>
                      <OutlineIconBtn disabled={true}>
                        <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                          <IoClose />
                        </IconContext.Provider>
                      </OutlineIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
        }
      />
      {addModalOpen && (
        <AddContentsModal
          manifestId={manifestId}
          placeId={placeId}
          placeName={placeName}
          onSuccess={fetchContents}
          modalOpen={addModalOpen}
          setModalOpen={setAddModalOpen}
        />
      )}
      {editModalOpen && (
        <EditContentsModal manifestId={manifestId} item={selectedItem} onSuccess={fetchContents} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />
      )}
      {removeModalOpen && (
        <RemoveContentsModal contentsId={selectedItem?.id} onSuccess={fetchContents} modalOpen={removeModalOpen} setModalOpen={setRemoveModalOpen} />
      )}
      {scanRemoveModalOpen && (
        <ScanRemoveContentsModal manifestId={manifestId} onSuccess={fetchContents} modalOpen={scanRemoveModalOpen} setModalOpen={setScanRemoveModalOpen} />
      )}
      {removeAllModalOpen && (
        <RemoveAllContentsModal manifestId={manifestId} onSuccess={fetchContents} modalOpen={removeAllModalOpen} setModalOpen={setRemoveAllModalOpen} />
      )}
    </div>
  );
};

export default ManifestContentsTable;
