import styled from "../Themes";

export const Section = styled("section")`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: radial-gradient(circle at top center, rgba(0, 0, 0, 0) 50%, ${({ theme }) => theme.color_50.sign_in_bg[2]} 75%);
`;
