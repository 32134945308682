import axios from "axios";
import { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { fetchReport5 } from "../../../services/report5";
import errToStr from "../../../util/errToStr";
import { printFixedTemp, printTempUnit } from "../../../util/formatUnits";
import { humaniseHours } from "../../../util/humaniseDurations";
import LoadingContainer from "../../LoadingContainer";
import { TableHeaderButtons } from "../../NewTable/styles";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import { Panel } from "../../Panel";
import { DurationSpan, TemperatureSpan } from "../../ReportsScreen/styles";
import Table, { CsvButtonsComponent } from "../../Table";

const formatDataToCsv = (tableRef: any) => {
  const headers = [
    "Place",
    "Litres Consumed (L)",
    `Average Temperature Before Fridge (${printTempUnit()})`,
    `Minimum Temperature Before Fridge (${printTempUnit()})`,
    `Maximum Temperature Before Fridge (${printTempUnit()})`,
    "Average Time Before Fridge",
    "Minimum Time Before Fridge",
    "Maximum Time Before Fridge",
    `Average Temperature In Fridge (${printTempUnit()})`,
    `Minimum Temperature In Fridge (${printTempUnit()})`,
    `Maximum Temperature In Fridge (${printTempUnit()})`,
    "Average Time In Fridge",
    "Minimum Time In Fridge",
    "Maximum Time In Fridge",
  ];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [
        row.place,
        row.litresConsumed,
        printFixedTemp(row.tempsBeforeFridgeAvg),
        printFixedTemp(row.tempsBeforeFridgeMin),
        printFixedTemp(row.tempsBeforeFridgeMax),
        humaniseHours(row.durationsBeforeFridgeAvg),
        humaniseHours(row.durationsBeforeFridgeMin),
        humaniseHours(row.durationsBeforeFridgeMax),
        printFixedTemp(row.tempsInFridgeAvg),
        printFixedTemp(row.tempsInFridgeMin),
        printFixedTemp(row.tempsInFridgeMax),
        humaniseHours(row.durationsInFridgeAvg),
        humaniseHours(row.durationsInFridgeMin),
        humaniseHours(row.durationsInFridgeMax),
      ];
    }, []),
  ];
};

const RefrigerationPerformance: FC<any> = () => {
  const tableRef = useRef<any>(null);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchReport5(source)
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData([]);
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  const columns = [
    {
      Header: "Place",
      columns: [
        {
          id: "place",
          Header: "Name",
          accessor: "placeName",
          Cell: (props: any) => <Link to={`/places/${props.original.placeId}`}>{props.value}</Link>,
          minWidth: 310,
        },
      ],
    },
    {
      Header: "Litres Consumed",
      columns: [
        {
          id: "litresConsumed",
          Header: "Total",
          accessor: "litresConsumed",
          Cell: (props: any) => (props.value !== null ? `${props.value.toFixed(0)} L` : "0 L"),
          minWidth: 140,
        },
      ],
    },
    {
      Header: "Temperature before fridge",
      columns: [
        {
          id: "tempsBeforeFridgeAvg",
          Header: "Avg",
          accessor: "tempsBeforeFridge.avg",
          Cell: (props: any) => <TemperatureSpan temp={props.value} />,
          minWidth: 85,
        },
        {
          id: "tempsBeforeFridgeMin",
          Header: "Min",
          accessor: "tempsBeforeFridge.min",
          Cell: (props: any) => <TemperatureSpan temp={props.value} />,
          minWidth: 85,
        },
        {
          id: "tempsBeforeFridgeMax",
          Header: "Max",
          accessor: "tempsBeforeFridge.max",
          Cell: (props: any) => <TemperatureSpan temp={props.value} />,
          minWidth: 85,
        },
      ],
    },
    {
      Header: "Time before fridge",
      columns: [
        {
          id: "durationsBeforeFridgeAvg",
          Header: "Avg",
          accessor: "durationsBeforeFridge.avg",
          Cell: (props: any) => <DurationSpan hours={props.value} />,
          minWidth: 160,
        },
        {
          id: "durationsBeforeFridgeMin",
          Header: "Min",
          accessor: "durationsBeforeFridge.min",
          Cell: (props: any) => <DurationSpan hours={props.value} />,
          minWidth: 160,
        },
        {
          id: "durationsBeforeFridgeMax",
          Header: "Max",
          accessor: "durationsBeforeFridge.max",
          Cell: (props: any) => <DurationSpan hours={props.value} />,
          minWidth: 160,
        },
      ],
    },
    {
      Header: "Temperature in fridge",
      columns: [
        {
          id: "tempsInFridgeAvg",
          Header: "Avg",
          accessor: "tempsInFridge.avg",
          Cell: (props: any) => <TemperatureSpan temp={props.value} />,
          minWidth: 85,
        },
        {
          id: "tempsInFridgeMin",
          Header: "Min",
          accessor: "tempsInFridge.min",
          Cell: (props: any) => <TemperatureSpan temp={props.value} />,
          minWidth: 85,
        },
        {
          id: "tempsInFridgeMax",
          Header: "Max",
          accessor: "tempsInFridge.max",
          Cell: (props: any) => <TemperatureSpan temp={props.value} />,
          minWidth: 85,
        },
      ],
    },
    {
      Header: "Time in fridge",
      columns: [
        {
          id: "durationsInFridgeAvg",
          Header: "Avg",
          accessor: "durationsInFridge.avg",
          Cell: (props: any) => <DurationSpan hours={props.value} />,
          minWidth: 160,
        },
        {
          id: "durationsInFridgeMin",
          Header: "Min",
          accessor: "durationsInFridge.min",
          Cell: (props: any) => <DurationSpan hours={props.value} />,
          minWidth: 160,
        },
        {
          id: "durationsInFridgeMax",
          Header: "Max",
          accessor: "durationsInFridge.max",
          Cell: (props: any) => <DurationSpan hours={props.value} />,
          minWidth: 160,
        },
      ],
    },
  ];

  const defaultSorted = [
    {
      id: "durationsBeforeFridgeAvg",
      desc: true,
    },
  ];

  return (
    <>
      <PageBreadcrumbs prevRoutes={[{ slug: "/reports", title: "Reports" }]} currRoute="Refrigeration Performance" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading} err={dataErr}>
            <Panel>
              <TableHeaderButtons>
                <div style={{ display: "flex" }}></div>
                <CsvButtonsComponent data={data} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName="Refrigeration Performance.csv" />
              </TableHeaderButtons>
              <Table loading={dataLoading} style={{ clear: "both" }} data={data} columns={columns} defaultSorted={defaultSorted} ref={tableRef} />
            </Panel>
          </LoadingContainer>
        </div>
      </PageContainer>
    </>
  );
};

export default RefrigerationPerformance;
