import { createGlobalStyle } from "../Themes";

export default createGlobalStyle<any>`
.rc-menu {
    border: unset !important;
    box-shadow: unset !important;
    border-radius: unset !important;
}

.rc-menu-submenu-title {
    cursor: pointer;
    display: flex !important;
    align-items: center;
    color: ${({ theme }) => theme.color.font[2]};
    fill: transparent;
}

.rc-menu-item {
    cursor: pointer;
    display: flex !important;
    align-items: center;
    color: ${({ theme }) => theme.color.font[2]};
    fill: ${({ theme }) => theme.color.font[2]};
}

.rc-menu-vertical > .rc-menu-item, .rc-menu-vertical-left > .rc-menu-item, .rc-menu-vertical-right > .rc-menu-item, .rc-menu-inline > .rc-menu-item, .rc-menu-vertical > .rc-menu-submenu > .rc-menu-submenu-title, .rc-menu-vertical-left > .rc-menu-submenu > .rc-menu-submenu-title, .rc-menu-vertical-right > .rc-menu-submenu > .rc-menu-submenu-title, .rc-menu-inline > .rc-menu-submenu > .rc-menu-submenu-title {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.rc-menu-vertical, .rc-menu-vertical-left, .rc-menu-vertical-right, .rc-menu-inline {
    padding: 0 !important;
}

.rc-menu-inline .rc-menu-submenu-arrow {
    display: ${({ open }) => (open ? "unset" : "none")} !important;
}

.rc-menu-item-selected {
    background-color: unset !important;
    color: ${({ theme }) => theme.color.font_bold[2]};
    fill: ${({ theme }) => theme.color.font_bold[2]};
}

.rc-menu-submenu-selected {
    background-color: unset !important;
}

.rc-menu-item-active, .rc-menu-submenu-active > .rc-menu-submenu-title {
    background-color: unset !important;
    color: ${({ theme }) => theme.color.font_bold[2]};
}

.rc-menu-item-active {
    color: ${({ theme }) => theme.color.font_bold[2]};
    fill: ${({ theme }) => theme.color.font_bold[2]};
  }

.rc-menu-submenu > .rc-menu {
    background-color: ${({ theme }) => theme.color.panel_bg[0]} !important; 
}
`;
