import React, { FC, useState } from "react";
import { SubmitModal } from "../Modal";
import LoadingContainer from "../LoadingContainer";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { exists, minLength, maxLength } from "../../util/formValidations";
import { FormError, FormTextareaInput } from "../FormComponents";
import { ModalFormContainer } from "../Modal/styles";
import { getAccount } from "../../services/localStorage";
import isTouch from "../../util/isTouch";
import { postFeedbackForm } from "../../services/feedbackForm";

// deviceMemory is current experimental so the type definition isn't added yet
declare global {
  interface Navigator {
    deviceMemory: any;
  }
}

const getGPU = () => {
  const canvas = document.createElement("canvas");
  let gl: any = undefined;
  let debugInfo: any = undefined;
  let renderer: any = undefined;

  try {
    gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
  } catch (e) {
    console.error(e);
  }

  if (gl) {
    debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
    renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
  }

  return renderer;
};

const FeedbackModal: FC<any> = ({ modalOpen, setModalOpen }) => {
  const [accountInfo] = useState<any>(getAccount());

  const [formData, setFormData] = useState<any>({ message: "" });
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "message":
          currValid = exists(name, value, setFormErrors) && minLength(name, value, 1, setFormErrors) && maxLength(name, value, 4096, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const handleSubmit = () => {
    const valid = validateForm();
    const body = {
      message: formData.message,
      email: accountInfo.email,
      account: accountInfo,
      url: window.location.href,
      userAgent: navigator.userAgent,
      logicalCores: navigator.hardwareConcurrency,
      deviceMemory: navigator.deviceMemory,
      gpu: getGPU(),
      touchScreen: isTouch(),
      screen: {
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        colorDepth: window.screen.colorDepth,
        pixelDepth: window.screen.pixelDepth,
      },
      date: new Date().toString(),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      navigationPerformance: performance.getEntriesByType("navigation")[0],
    };

    if (valid) {
      setSubmitting(true);
      postFeedbackForm(source, body)
        .then(() => {
          setSubmittedMsg("Message submitted. We will be in contact shortly");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      title="Feedback & Support"
      size="lg"
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Submit Message"
      body={
        <LoadingContainer loading={submitting}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer>
              <label>Message</label>
              <FormTextareaInput name="message" rows={6} maxLength={4096} value={formData.message} error={formErrors.message} onChange={handleChange} />
              <FormError error={formErrors.message}>{formErrors.message}</FormError>
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default FeedbackModal;
