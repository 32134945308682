import styled from "../Themes";

export const DateItem = styled(`div`)`
  padding: 7px 9px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.color.tooltip_hover[2]};
  }
`;

export const CalendarIconContainer = styled(`div`)`
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  top: 10px;
  right: 13px;
`;

export const DatePickerInputContainer = styled<any>(`div`)`
  position: relative;
  cursor: pointer;
  margin: ${({ margin }) => (margin !== undefined ? margin : "12px 6px")};
  fill: ${({ theme }) => theme.color_80.font_bold[2]};
  transition: fill 0.25s ease-in-out;

  &:hover {
    fill: ${({ theme }) => theme.color.font_bold[2]};
  }
`;
