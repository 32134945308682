import { FC, useContext, useMemo } from "react";
import { IconContext } from "react-icons";
import { FaBatteryEmpty, FaBatteryFull, FaBatteryHalf, FaBatteryQuarter, FaBatteryThreeQuarters } from "react-icons/fa";
import { ThemeContext } from "styled-components";
import roundBatteryPercent from "../../util/roundBatteryPercent";
import Tooltip from "../Tooltip";

const BatteryIcon: FC<any> = ({ batteryPercentage }) => {
  const { color } = useContext(ThemeContext);

  const percent = useMemo(() => roundBatteryPercent(batteryPercentage), [batteryPercentage]);

  const battery = useMemo(() => {
    if (percent == null) {
      return <></>;
    } else if (percent <= 10) {
      return (
        <IconContext.Provider value={{ color: color.worse[2], size: "32px" }}>
          <FaBatteryEmpty />
        </IconContext.Provider>
      );
    } else if (percent > 10 && percent <= 25) {
      return (
        <IconContext.Provider value={{ color: percent <= 10 ? color.worse[2] : color.bad[2], size: "32px" }}>
          <FaBatteryQuarter />
        </IconContext.Provider>
      );
    } else if (percent > 25 && percent <= 50) {
      return (
        <IconContext.Provider value={{ color: percent <= 35 ? color.good[2] : color.great[2], size: "32px" }}>
          <FaBatteryHalf />
        </IconContext.Provider>
      );
    } else if (percent > 50 && percent <= 75) {
      return (
        <IconContext.Provider value={{ color: color.great[2], size: "32px" }}>
          <FaBatteryThreeQuarters />
        </IconContext.Provider>
      );
    } else if (percent > 75 && percent <= 100) {
      return (
        <IconContext.Provider value={{ color: color.great[2], size: "32px" }}>
          <FaBatteryFull />
        </IconContext.Provider>
      );
    }
  }, [percent, color]);

  return (
    <Tooltip placement="bottom" content={`${percent}%`}>
      {<div>{battery}</div>}
    </Tooltip>
  );
};

export default BatteryIcon;
