import styled from "../Themes";

export const PageContainer = styled("div")`
  padding: 24px;
  margin: auto;
  max-width: 1200px;
  text-align: center;
`;

export const TermsContainer = styled(`div`)`
  max-height: 60vh;
  overflow: scroll;
  text-align: left;
  border: 1px solid ${({ theme }) => theme.color.font_bold[2]};
`;

export const Terms = styled("div")`
  padding-right: 12px;
`;

export const ButtonContainer = styled("div")`
  float: left;
  width: 100%;
  margin: 14px 0;
`;
