import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import QrScanner from "qr-scanner";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { deletePickupRequest } from "../../../services/pickupRequests";
import SuccessIcon from "../../../svgs/SuccessIcon";
import errToStr from "../../../util/errToStr";
import { exists } from "../../../util/formValidations";
import { kegsOrTrackers } from "../../../util/kegOrTracker";
import Bold from "../../Bold";
import { PrimaryBtn } from "../../Buttons";
import { FormContainer, FormError, FormInput, FormInputContainer } from "../../FormComponents";
import LoadingContainer from "../../LoadingContainer";
import { InfoModal, SubmitModal } from "../../Modal";
import { ModalIconContainer, ModalSuccess } from "../../Modal/styles";
import { TableHeaderButtons } from "../../NewTable/styles";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import Table, { CsvButtonsComponent } from "../../Table";

const formatDataToCsv = (tableRef: any, short_datetime: string) => {
  const headers = ["Date", "Tracker ID"];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [moment.unix(row.date).format(short_datetime), row.sensorId];
    }, []),
  ];
};

const DeletePickupRequest: FC<any> = () => {
  const { color, short_datetime } = useContext(ThemeContext);

  const tableRef = useRef<any>(undefined);

  const videoRefCallback = useCallback((node: any) => {
    if (node) {
      setVideoRef(node);
    }
  }, []);

  const [videoRef, setVideoRef] = useState<any>(undefined);

  const [formData, setFormData] = useState<any>({ sensorId: "" });
  const [formErrors, setFormErrors] = useState<any>({});

  const [requestedSensors, setRequestedSensors] = useState<any>([]);

  const [scanResponseMsg, setResponseMsg] = useState<string>("");
  const [scanResponseErr, setResponseErr] = useState<string>("");
  const [scanResponseLoading, setResponseLoading] = useState<boolean>(false);

  const [requestModalOpen, setRequestModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Initialise codeReader and stop/destroy codeReader and stop camera access on clean up
  useEffect(() => {
    let codeReader: any = undefined;
    if (videoRef) {
      codeReader = new QrScanner(videoRef, (result) => {
        if (result) {
          let code = result;
          if (code.includes("10b.in/")) {
            code = code.split("10b.in/")[1];
          }
          setFormData((prev: any) => ({ ...prev, sensorId: code }));
          setFormErrors((prev: any) => ({
            ...prev,
            sensorId: undefined,
          }));
          setResponseMsg("");
          setResponseErr("");
          setRequestModalOpen(true);
        }
      });
      codeReader.setInversionMode("both");
      codeReader.start();
    }

    return () => {
      if (codeReader) {
        codeReader.stop();
        codeReader.destroy();
      }
      if (videoRef && videoRef.srcObject) {
        videoRef.srcObject.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
    };
  }, [videoRef]);

  const handleSubmit = () => {
    setResponseLoading(true);
    deletePickupRequest(source, formData.sensorId)
      .then((response) => {
        // On successful post, add scan to local table (or modify scan
        // if already present)
        setResponseMsg(response);
        setResponseErr("");
        setRequestedSensors((prev: any) => [
          ...prev.filter((prevScan: any) => {
            if (prevScan.sensorId === formData.sensorId) {
              return false;
            }
            return true;
          }, []),
          { date: Math.floor(Date.now() / 1000), sensorId: formData.sensorId },
        ]);
        setResponseLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setResponseErr(errToStr(err));
          setResponseLoading(false);
        }
      });
  };

  const handleManualSubmit = () => {
    const valid = exists("sensorId", formData.sensorId, setFormErrors);

    if (valid) {
      setResponseMsg("");
      setResponseErr("");
      setRequestModalOpen(true);
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setFormErrors((prev: any) => ({
      ...prev,
      [e.target.name]: undefined,
    }));
  };

  const columns = [
    {
      id: "date",
      Header: "Date",
      accessor: "date",
      Cell: (props: any) => moment.unix(props.value).format(short_datetime),
      minWidth: 160,
    },
    {
      id: "sensorId",
      Header: "Tracker ID",
      accessor: "sensorId",
      minWidth: 120,
      Cell: (props: any) => {
        return (
          <Link title={props.value} to={`/${kegsOrTrackers("kegs", "trackers")}/${props.value}`}>
            {props.value}
          </Link>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      id: "date",
      desc: true,
    },
  ];

  return (
    <>
      <PageBreadcrumbs prevRoutes={[{ slug: "/scanner", title: "Scanner" }]} currRoute="Delete Pickup Request" />
      <PageContainer top="40px">
        <FormContainer>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <div>
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <label>Scan {kegsOrTrackers("Kegs", "Trackers")}</label>
                <div>
                  <video
                    id="video"
                    ref={videoRefCallback}
                    style={{
                      display: "inline-block",
                      width: "100%",
                      border: "1px solid gray",
                      borderRadius: "3px",
                    }}
                  />
                </div>
              </FormInputContainer>
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <label>Tracker ID</label>
                <FormInput
                  type="text"
                  name="sensorId"
                  value={formData.sensorId}
                  error={formErrors.sensorId}
                  onChange={handleChange}
                  style={{ height: "40px" }}
                />
                <FormError error={formErrors.sensorId}>{formErrors.sensorId}</FormError>
              </FormInputContainer>
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <PrimaryBtn width="100%" onClick={handleManualSubmit}>
                  Delete Pickup Request
                </PrimaryBtn>
              </FormInputContainer>
              <div
                style={{
                  paddingTop: "16px",
                  width: "100%",
                  float: "left",
                  display: requestedSensors.length > 0 ? "block" : "none",
                }}
              >
                <TableHeaderButtons>
                  <div style={{ display: "flex" }}></div>
                  <CsvButtonsComponent
                    data={requestedSensors}
                    formatCsv={formatDataToCsv}
                    formatCsvParams={[tableRef, short_datetime]}
                    fileName="Deleted Pickup Requests.csv"
                  />
                </TableHeaderButtons>
                <Table style={{ clear: "both" }} data={requestedSensors} columns={columns} defaultSorted={defaultSorted} ref={tableRef} />
              </div>
            </div>
            {scanResponseMsg ? (
              <InfoModal
                isOpen={requestModalOpen}
                onClose={() => {
                  setResponseMsg("");
                  setResponseErr("");
                  setRequestModalOpen(false);
                }}
                title="Delete Pickup Request"
                okayBtnText="Okay"
                body={
                  <ModalSuccess>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <ModalIconContainer>
                        <SuccessIcon fill={color.success[2]} />
                      </ModalIconContainer>
                      <div>{scanResponseMsg}</div>
                    </div>
                  </ModalSuccess>
                }
              />
            ) : (
              <SubmitModal
                isOpen={requestModalOpen}
                setRequestModalOpen
                onSubmit={() => handleSubmit()}
                onClose={() => {
                  setResponseMsg("");
                  setResponseErr("");
                  setRequestModalOpen(false);
                }}
                title="Delete Pickup Request"
                success={scanResponseMsg}
                error={scanResponseErr}
                body={
                  <LoadingContainer loading={scanResponseLoading}>
                    <p>
                      Tracker ID: <Bold>{formData.sensorId}</Bold>
                    </p>
                  </LoadingContainer>
                }
              />
            )}
          </form>
        </FormContainer>
      </PageContainer>
    </>
  );
};

export default DeletePickupRequest;
