import axios from "axios";
import matchSorter from "match-sorter";
import { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { fetchPlaceTagList } from "../../services/placeTagList";
import errToStr from "../../util/errToStr";
import sortTags from "../../util/sortTags";
import Bold from "../Bold";
import LoadingContainer from "../LoadingContainer";
import { InfoModal } from "../Modal";
import { TableHeaderButtons } from "../NewTable/styles";
import Table, { CsvButtonsComponent } from "../Table";
import Tag from "../Tag";

const PlaceTagsPlaceList: FC<any> = ({ tag, modalOpen, setModalOpen }) => {
  const [places, setPlaces] = useState<any>([]);
  const [placesErr, setPlacesErr] = useState<string>("");
  const [placesLoading, setPlacesLoading] = useState<boolean>(false);

  const tableRef = useRef<any>(null);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (tag) {
      setPlacesLoading(true);
      fetchPlaceTagList(source, tag.name)
        .then((response) => {
          setPlaces(response);
          setPlacesLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setPlacesErr(errToStr(err));
            setPlacesLoading(false);
          }
        });
    }

    return () => {
      source.cancel();
    };
  }, [tag]);

  const formatDataToCsv = (tableRef: any) => {
    const sortedData = tableRef.current.getResolvedState().sortedData;
    let headers = [];

    headers = ["Name", "Place Tags"];

    return [
      headers,
      ...sortedData.map((row: any) => {
        return [
          row.placeName,
          row.tags
            ? row.tags
                .sort(sortTags)
                .map((tag: any) => tag.name)
                .join(", ")
            : "",
        ];
      }, []),
    ];
  };

  const columns: any = [];

  columns.push({
    id: "placeName",
    Header: "Name",
    accessor: "placeName",
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["placeName"],
      }),
    filterAll: true,
    Cell: (props: any) => (
      <Link title={props.value} to={`/places/${props.original.placeId}`}>
        {props.value}
      </Link>
    ),
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  columns.push({
    id: "tags",
    Header: "Tags",
    accessor: "tags",
    style: { textOverflow: "unset", whiteSpace: "normal" },
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["tags"],
      }),
    filterAll: true,
    Cell: (props: any) =>
      props.value.sort(sortTags).map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />),
  });

  const defaultSorted = [
    {
      id: "placeName",
      desc: false,
    },
  ];

  return (
    <InfoModal
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      title="Places"
      okayBtnText="Close"
      okayBtnProps={{ width: "100%" }}
      body={
        <LoadingContainer loading={placesLoading} err={placesErr}>
          <TableHeaderButtons>
            <div style={{ display: "flex" }}></div>
            <CsvButtonsComponent data={places} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName="Place List.csv" />
          </TableHeaderButtons>
          <Table
            loading={placesLoading}
            filterable={true}
            style={{ clear: "both" }}
            data={places}
            columns={columns}
            defaultSorted={defaultSorted}
            ref={tableRef}
            defaultPageSize={15}
          />
        </LoadingContainer>
      }
    />
  );
};

export default PlaceTagsPlaceList;
