import { AxiosError } from "axios";

const errToStr = (error: AxiosError | null) => {
  if (error) {
    if (error.response && error.response.status === 500 && error.response.statusText) {
      return error.response.statusText;
    } else if (error.response && error.response.data && typeof error.response.data === "string") {
      return error.response.data;
    } else if (error.response && error.response.data && error.response.data.message) {
      return error.response.data.message;
    } else if (error.response && error.response.statusText) {
      return error.response.statusText;
    } else {
      return error.message || "An error occurred";
    }
  } else {
    return "An error occurred";
  }
};

export default errToStr;
