import axios, { CancelTokenSource } from "axios";
import matchSorter from "match-sorter";
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { ThemeContext } from "styled-components";
import { deleteAssetTypes, fetchAssetTypes } from "../../services/editAssetTypes";
import { getAccount } from "../../services/localStorage";
import MoreIcon from "../../svgs/MoreIcon";
import { isAdminOrUser } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import Bold from "../Bold";
import { PrimaryBtn } from "../Buttons";
import DeleteModal from "../DeleteModal";
import EditAssetTypeModel from "../EditAssetTypeModel";
import { PrimaryIconBtn } from "../IconButtons";
import LoadingContainer from "../LoadingContainer";
import { DesktopDiv, MobileDiv, TableHeaderButtons } from "../NewTable/styles";
import ReactIcon from "../ReactIcon";
import Table, { CsvButtonsComponent } from "../Table";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import TrackerListModal from "../TrackerListModal";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

const AssetTypesTable: FC<any> = () => {
  const { color } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);

  const [types, setTypes] = useState<any>([]);
  const [typesErr, setTypesErr] = useState<string>("");
  const [typesLoading, setTypesLoading] = useState<boolean>(false);

  const [selectedType, setSelectedType] = useState<any>(null);
  const [editModalOpen, setEditModalOpen] = useState<any>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [trackerListModalOpen, setTrackerListModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  const fetchTypes = useCallback(() => {
    setTypesLoading(true);
    fetchAssetTypes(source)
      .then((response) => {
        setTypes(response);
        setTypesLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setTypesErr(errToStr(err));
          setTypesLoading(false);
        }
      });
  }, [source]);

  useEffect(() => {
    if (source) {
      fetchTypes();
    }

    return () => {
      source.cancel();
    };
  }, [source, fetchTypes]);

  const formatDataToCsv = (tableRef: any) => {
    const sortedData = tableRef.current.getResolvedState().sortedData;
    let headers = [];

    headers = ["Name", "Description", kegsOrTrackers("Keg Count", "Tracker Count"), "Icon", "Colour"];

    return [
      headers,
      ...sortedData.map((row: any) => {
        return [row.name, row.description, row.trackerCount, row.icon, row.colour];
      }, []),
    ];
  };

  const columns = React.useMemo<any>(
    () => [
      {
        Header: "",
        minWidth: 65,
        maxWidth: 65,
        filterable: false,
        sortable: false,
        Cell: ({ original }: any) => {
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  {isAdminOrUser() ? (
                    <>
                      <MenuButton
                        onClick={() => {
                          setSelectedType(original);
                          setEditModalOpen(true);
                        }}
                      >
                        Edit Asset Type
                      </MenuButton>
                      <DangerMenuButton
                        onClick={() => {
                          setSelectedType(original);
                          setDeleteModalOpen(true);
                        }}
                      >
                        Delete Asset Type
                      </DangerMenuButton>
                    </>
                  ) : (
                    <>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Edit Asset Type</MenuButton>
                        </div>
                      </Tooltip>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Delete Asset Type</MenuButton>
                        </div>
                      </Tooltip>
                    </>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
      },
      {
        id: "name",
        Header: "Name",
        accessor: "name",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["name"],
          }),
        filterAll: true,
      },
      {
        id: "description",
        Header: "Description",
        accessor: "description",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["description"],
          }),
        filterAll: true,
      },
      {
        id: "trackerCount",
        Header: kegsOrTrackers("Keg Count", "Tracker Count"),
        accessor: "trackerCount",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["trackerCount"],
          }),
        filterAll: true,
        Cell: (props: any) => (
          <div
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => {
              setSelectedType(props.original);
              setTrackerListModalOpen(true);
            }}
          >
            {props.value}
          </div>
        ),
      },
      {
        id: "icon",
        minWidth: 160,
        maxWidth: 160,
        Header: "Icon",
        accessor: "icon",
        Cell: (props: any) => (
          <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
            <ReactIcon defaultColour={color.font[2]} name={props.value} colour={props.original.colour} style={{ margin: "auto" }} size="1.1rem" />
          </div>
        ),
      },
      {
        id: "colour",
        accessor: "colour",
        show: false,
      },
    ],
    [color]
  );

  const defaultSorted = [
    {
      id: "trackerCount",
      desc: true,
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <LoadingContainer loading={typesLoading} err={typesErr}>
        <TableHeaderButtons>
          <div style={{ display: "flex" }}>
            {isAdminOrUser() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setSelectedType(null);
                      setEditModalOpen(true);
                    }}
                  >
                    Create
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <PrimaryIconBtn
                      onClick={() => {
                        setSelectedType(null);
                        setEditModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryBtn disabled={true}>Create</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryIconBtn>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
          <CsvButtonsComponent data={types} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName="Asset Type List.csv" />
        </TableHeaderButtons>
        <Table
          loading={typesLoading}
          filterable={true}
          style={{ clear: "both" }}
          data={types}
          columns={columns}
          defaultSorted={defaultSorted}
          ref={tableRef}
          defaultPageSize={15}
        />
      </LoadingContainer>
      {editModalOpen && <EditAssetTypeModel assetType={selectedType} fetch={fetchTypes} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
      {deleteModalOpen && (
        <DeleteModal
          title="Delete Asset Type"
          body={
            <>
              <span>Are you sure you want to delete this asset type?</span>
              <br />
              <span>This will also remove the asset type from any {kegsOrTrackers("kegs", "trackers")} it was assigned to.</span>
            </>
          }
          successMsg="Asset Type Deleted"
          onDelete={() => setTypes((prev: any) => prev.filter((row: any) => row.id !== selectedType.id))}
          onClose={() => setSelectedType(null)}
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          deleteService={deleteAssetTypes}
          serviceParams={[selectedType.id]}
        />
      )}
      {trackerListModalOpen && (
        <TrackerListModal
          filters={`organisationId eq ${getAccount().organisationId} and assetTypeId eq ${selectedType.id}`}
          columns={["id"]}
          modalOpen={trackerListModalOpen}
          setModalOpen={setTrackerListModalOpen}
        />
      )}
    </div>
  );
};

export default AssetTypesTable;
