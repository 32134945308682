import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { FormInput, FormError, InputUnitWrapper } from "../FormComponents";
import { exists, max, maxLength, min } from "../../util/formValidations";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { AsyncSelect } from "../Select";
import { fetchAutoComplete } from "../../services/autoComplete";
import { putPlaceAllocation, postPlaceAllocation } from "../../services/placeAllocations";
import addOrUpdate from "../../util/addOrUpdate";

const defaultAllocation = {
  id: undefined,
  place: null,
  name: "",
  assetType: null,
  min: "",
  max: "",
};

const initForm = (allocation?: any) => {
  if (allocation) {
    const formData = {
      ...defaultAllocation,
      ...allocation,
      place: allocation.placeId
        ? {
            value: allocation.placeId,
            label: allocation.placeName,
          }
        : null,
      assetType: allocation.assetTypeId
        ? {
            value: allocation.assetTypeId,
            label: allocation.assetTypeName,
            colour: allocation.assetTypeColour,
            icon: allocation.assetTypeIcon,
          }
        : null,
    };

    return formData;
  } else {
    return defaultAllocation;
  }
};

const EditPlaceAllocationModal: FC<any> = ({ allocation, placeId, setData, modalOpen, setModalOpen }) => {
  const [formData, setFormData] = useState<any>(initForm(allocation));
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "name":
          currValid = value ? maxLength(name, value, 255, setFormErrors) : true;
          break;

        case "place":
          if (placeId === undefined) {
            currValid = exists(name, value ? value.value : null, setFormErrors);
          }
          break;

        case "min":
          currValid =
            value !== "" ? min(name, value, 0, setFormErrors) && max(name, value, formData.max !== "" ? formData.max : undefined, setFormErrors) : true;
          break;

        case "max":
          currValid = value !== "" ? min(name, value, formData.min !== "" ? formData.min : 0, setFormErrors) : true;
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    return {
      id: formData.id,
      placeId: formData.place && formData.place.value ? formData.place.value : placeId,
      name: formData.name.trim(),
      assetTypeId: formData.assetType && formData.assetType.value ? formData.assetType.value : null,
      min: formData.min !== "" ? formData.min : null,
      max: formData.max !== "" ? formData.max : null,
    };
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);

      if (formData.id) {
        putPlaceAllocation(source, formData.id, body)
          .then((response) => {
            setData((prev: any) => addOrUpdate(prev, response, "id"));
            setSubmittedMsg("Place Allocation Updated");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      } else {
        postPlaceAllocation(source, body)
          .then((response) => {
            setData((prev: any) => addOrUpdate(prev, response, "id"));
            setSubmittedMsg("Place Allocation Created");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      }
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      title={formData.id ? "Edit Place Allocation" : "Create Place Allocation"}
      success={submittedMsg}
      error={submittingErr}
      submitBtnText={formData.id ? "Update Place Allocation" : "Create Place Allocation"}
      body={
        <LoadingContainer loading={submitting}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            {placeId == null && (
              <ModalFormContainer>
                <label>Place</label>
                <AsyncSelect
                  name="place"
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.place}
                  value={formData.place}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                />
                <FormError error={formErrors.place}>{formErrors.place}</FormError>
              </ModalFormContainer>
            )}
            <ModalFormContainer>
              <label>Name</label>
              <FormInput type="text" name="name" value={formData.name} error={formErrors.name} onChange={handleChange} required={true} />
              <FormError error={formErrors.name}>{formErrors.name}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <label>Asset Type</label>
              <AsyncSelect
                name="assetType"
                defaultOptions={true}
                isClearable={true}
                isError={formErrors.assetType}
                value={formData.assetType}
                loadOptions={(inputValue: any, callback: any) => loadOptions("assetTypes", inputValue, callback)}
                onChange={handleSelectChange}
                placeholder="Select..."
              />
              <FormError error={formErrors.assetType}>{formErrors.assetType}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <label>Min</label>
              <InputUnitWrapper>
                <FormInput
                  type="number"
                  name="min"
                  value={formData.min}
                  error={formErrors.min}
                  onChange={handleChange}
                  required={true}
                  step={1}
                  min={0}
                  max={formData.max !== "" ? formData.max : undefined}
                />
              </InputUnitWrapper>
              <FormError error={formErrors.min}>{formErrors.min}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <label>Max</label>
              <InputUnitWrapper>
                <FormInput
                  type="number"
                  name="max"
                  value={formData.max}
                  error={formErrors.max}
                  onChange={handleChange}
                  required={true}
                  step={1}
                  min={formData.max !== "" ? formData.max : 0}
                />
              </InputUnitWrapper>
              <FormError error={formErrors.max}>{formErrors.max}</FormError>
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default EditPlaceAllocationModal;
