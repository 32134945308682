/* https://stackoverflow.com/a/53751866/8629665
true: undefined, null, "", [], {}
false: true, false, 1, 0, -1, "foo", [1, 2, 3], { foo: 1 }
*/
const isEmpty = (value: any) => {
  return (
    // null or undefined
    value == null ||
    // has length and it's zero
    (Object.prototype.hasOwnProperty.call(value, "length") && value.length === 0) ||
    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  );
};

export default isEmpty;
