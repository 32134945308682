import styled from "../Themes";

export const FlexRow = styled<any>(`div`)`
  display: flex;
  justify-content: center;

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const FlexRowColumn = styled(`div`)`
  min-height: 300px;
  margin: 12px;
  flex: 1;
  max-width: calc(33.33% - 24px);

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);

  @media (max-width: 1200px) {
    min-height: auto;
    max-width: calc(100% - 24px);
  }

  @media (max-width: 768px) {
    margin: 6px;
    max-width: calc(100% - 12px);
  }
`;

export const ChartWrapper = styled(`div`)`
  height: 550px;
  min-width: 500px;
  padding: 24px;
  margin: 12px;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  box-shadow: ${({ theme }) => theme.boxShadow.s};
  flex-grow: 1;

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);

  @media (max-width: 900px) {
    min-width: fill-available;
  }

  @media (max-width: 768px) {
    padding: 12px;
    margin: 6px;
    min-width: fill-available;
  }
`;

export const KegDetailsWrapper = styled(`div`)`
  min-width: 400px;
  overflow: auto;
  padding: 24px;
  margin: 12px;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  box-shadow: ${({ theme }) => theme.boxShadow.s};
  flex-grow: 1;

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);

  @media (max-width: 900px) {
    min-width: fill-available;
  }

  @media (max-width: 768px) {
    padding: 12px;
    margin: 6px;
    min-width: fill-available;
  }
`;
