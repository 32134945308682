import { useContext, useMemo } from "react";
import { ThemeContext } from "styled-components";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import ReactIcon from "../ReactIcon";

const AssetTypeLabel = ({ name, icon, colour, count, size = "1.1rem" }: any) => {
  const theme = useContext(ThemeContext);

  const formattedName = useMemo(() => (count !== undefined ? `${count} ${name}` : name), [name, count]);

  if (!colour && !icon) {
    return <span title={name}>{formattedName}</span>;
  }

  if (!icon) {
    return (
      <>
        <ColoredDot color={colour} />
        <span title={name}>{formattedName}</span>
      </>
    );
  }

  return (
    <>
      <ReactIcon name={icon} defaultColour={theme.color.font[2]} colour={colour} title={name} style={{ marginRight: "6px" }} size={size} />
      <span title={name}>{formattedName}</span>
    </>
  );
};

export default AssetTypeLabel;
