import * as styledComponents from "styled-components";
import { ThemedStyledComponentsModule } from "styled-components";
import { Theme } from "./types";

export type ITheme = Readonly<Theme>;

const { default: styled, css, createGlobalStyle, keyframes, ThemeProvider } = styledComponents as ThemedStyledComponentsModule<ITheme>;

export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled;
