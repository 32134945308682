import styled from "../Themes";

export const FlexRow = styled<any>(`div`)`
  display: flex;
  justify-content: center;

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

export const MetaWrapper = styled(`div`)`
  display: flex;
  max-width: 550px;
  overflow: visible;
  margin: 12px;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);

  @media (max-width: 900px) {
    min-width: fill-available;
  }

  @media (max-width: 768px) {
    margin: 6px;
    min-width: fill-available;
  }
`;

export const MapWrapper = styled(`div`)`
  min-height: 400px;
  margin: 12px;
  box-shadow: ${({ theme }) => theme.boxShadow.s};
  flex-grow: 1;

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);

  @media (max-width: 900px) {
    min-width: fill-available;
  }

  @media (max-width: 768px) {
    margin: 6px;
    min-width: fill-available;
  }
`;

export const ChartWrapper = styled(`div`)`
  min-width: 500px;
  min-height: 150px;
  padding: 24px;
  margin: 12px;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  box-shadow: ${({ theme }) => theme.boxShadow.s};
  flex-grow: 1;

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);

  @media (max-width: 900px) {
    min-width: fill-available;
  }

  @media (max-width: 768px) {
    padding: 12px;
    margin: 6px;
    min-width: fill-available;
  }
`;

export const HistoryWrapper = styled(`div`)`
  min-width: 400px;
  overflow: auto;
  padding: 24px;
  margin: 12px;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  box-shadow: ${({ theme }) => theme.boxShadow.s};
  flex-grow: 1;

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);

  @media (max-width: 900px) {
    min-width: fill-available;
  }

  @media (max-width: 768px) {
    padding: 12px;
    margin: 6px;
    min-width: fill-available;
  }
`;

export const AlertWrapper = styled(`div`)`
  min-width: 400px;
  overflow: auto;
  padding: 24px;
  margin: 12px;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  box-shadow: ${({ theme }) => theme.boxShadow.s};
  flex-grow: 1;

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);

  @media (max-width: 900px) {
    min-width: fill-available;
  }

  @media (max-width: 768px) {
    padding: 12px;
    margin: 6px;
    min-width: fill-available;
  }
`;
