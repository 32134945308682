import axios, { CancelTokenSource } from "axios";
import { getToken } from "../localStorage";

export const postEditPassword = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/api/editpassword",
    data: JSON.stringify(body),
  });

  return data;
};
