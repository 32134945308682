import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill={props.fill}
        d="M90.5 0h-30v362h-30v90h30v60h30v-60h30v-90h-30zM451.5 362V0h-30v362h-30v90h30v60h30v-60h30v-90zM331.5 0h-30v222h-30v90h30v200h30V312h30v-90h-30zM210.5 0h-30v53.652h-30v90h30V512h30V143.652h30v-90h-30z"
      />
    </svg>
  );
}

export default SvgComponent;
