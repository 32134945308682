import React, { FC, useEffect, useState } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { fetchUnsubscribeAlert } from "../../services/unsubscribeAlert";

const UnsubscribeAlertModal: FC<any> = ({ token, modalOpen, setModalOpen }) => {
  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleUnsubscribe = () => {
    setSubmitting(true);
    fetchUnsubscribeAlert(source, token)
      .then((response) => {
        setSubmittedMsg(response);
        setSubmittingErr("");
        setSubmitting(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleUnsubscribe()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      title="Unsubscribe From Alert"
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Unsubscribe"
      body={
        <LoadingContainer loading={submitting}>
          <span>Are you sure you want to unsubscribe from this alert?</span>
        </LoadingContainer>
      }
    />
  );
};

export default UnsubscribeAlertModal;
