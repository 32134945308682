import React, { FC, useRef, useEffect, useState, useContext } from "react";
import { ThemeContext } from "styled-components";
import { convertRoundedLength, toMeters } from "../../util/formatUnits";
import { MapContainer } from "./styles";

declare global {
  interface Window {
    google: any;
  }
}

window.google = window.google || {};

const MapFilter: FC<any> = ({ place, setPlace, error }) => {
  const { color, map_style } = useContext(ThemeContext);

  const googleMapRef = useRef(null);

  const [googleMap, setGoogleMap] = useState<any>(undefined);
  const [location, setLocation] = useState<any>(undefined);
  const [circle, setCircle] = useState<any>(undefined);

  useEffect(() => {
    setLocation({
      lat: place.latitude,
      lng: place.longitude,
      zoom: 18,
    });
  }, []);

  useEffect(() => {
    if (location) {
      const newMap = new window.google.maps.Map(googleMapRef.current, {
        zoom: location.zoom,
        center: { lat: location.lat, lng: location.lng },
        disableDefaultUI: true,
        keyboardShortcuts: false,
        controlSize: 26,
        zoomControl: true,
        mapTypeControl: true,
        fullscreenControl: true,
        styles: map_style,
      });
      setGoogleMap(newMap);
    }
  }, [location]);

  useEffect(() => {
    if (googleMap && !circle) {
      const newCircle = new window.google.maps.Circle({
        strokeColor: color.primary[2],
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: color.primary[2],
        fillOpacity: 0.25,
        map: googleMap,
        editable: true,
        center: { lat: location.lat, lng: location.lng },
        radius: toMeters(place.radius),
      });

      newCircle.addListener("center_changed", () => {
        const lat = newCircle.getCenter().lat();
        const lng = newCircle.getCenter().lng();
        setPlace((prev: any) => ({ ...prev, latitude: lat, longitude: lng }));
      });
      newCircle.addListener("radius_changed", () => {
        const radius = convertRoundedLength(Math.round(newCircle.getRadius()));
        setPlace((prev: any) => ({ ...prev, radius }));
      });

      setCircle(newCircle);

      // If editing a place, set the map bounds to the size of the radius (circle)
      if (place.latitude && place.longitude) {
        googleMap.fitBounds(newCircle.getBounds());
      }
    }
  }, [googleMap]);

  useEffect(() => {
    if (circle) {
      googleMap.addListener("click", (e: any) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        circle.setOptions({ center: { lat, lng } });
        setPlace((prev: any) => ({ ...prev, latitude: lat, longitude: lng }));
      });
    }
  }, [circle]);

  useEffect(() => {
    if (circle) {
      circle.setRadius(toMeters(place.radius));
      circle.setOptions({ center: { lat: +place.latitude, lng: +place.longitude } });
      googleMap.fitBounds(circle.getBounds());
    }
  }, [place.blur]);

  useEffect(() => {
    if (googleMap) {
      googleMap.setOptions({ styles: map_style });
    }
  }, [map_style]);

  return <MapContainer id="map-filter-google-map" ref={googleMapRef} error={error} />;
};

export default MapFilter;
