import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const getFleetSize = async (source: CancelTokenSource, dates: any) => {
  const params = {
    organisationId: getAccount().organisationId,
    end: dates ? dates.end : undefined,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/fleetsize",
    params,
  });

  return data;
};
