import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const fetchPublicBeerMap = async (source: CancelTokenSource, organisationId: string) => {
  const params = {
    organisationId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/publicbeermap",
    params,
  });

  return data;
};
