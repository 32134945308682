import React, { FC, useState, useContext } from "react";
import { SketchPicker } from "react-color";
import { ThemeContext } from "styled-components";
import DisableUserSelect from "../GlobalStyles/disableUserSelect";
import { Swatch, Color, Popover, Cover } from "./styles";
import { ColourPickerModal } from "../Modal";

const ColorPicker: FC<any> = ({ color, setColor }) => {
  const { echarts_palette } = useContext(ThemeContext);

  const [colorPickerVisible, setColorPickerVisible] = useState<boolean>(false);

  return (
    <>
      {colorPickerVisible && <DisableUserSelect />}
      <Swatch onClick={() => setColorPickerVisible(!colorPickerVisible)}>
        <Color color={color} />
      </Swatch>
      {colorPickerVisible ? (
        <ColourPickerModal
          isOpen={colorPickerVisible}
          onClose={() => {
            setColorPickerVisible(false);
          }}
          body={
            <>
              <Cover onClick={() => setColorPickerVisible(false)} />
              <SketchPicker disableAlpha={true} presetColors={echarts_palette} color={color} onChange={setColor} />
            </>
          }
        />
      ) : null}
    </>
  );
};

export default ColorPicker;
