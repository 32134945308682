import styled from "../Themes";

export const DrawHandle = styled(`div`)`
  position: absolute;
  top: 90px;
  width: 51px;
  height: 50px;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.color.panel_bg[2]};
  left: -50px;
  box-shadow: -2px 0 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
  fill: ${({ theme }) => theme.color.font[2]};

  &:hover {
    fill: ${({ theme }) => theme.color.font_bold[2]};
  }
`;

export const DrawContent = styled(`div`)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  min-width: 440px;

  @media (max-width: 500px) {
    width: 100vw;
    min-width: unset;
  }
`;

export const DrawHeader = styled(`div`)`
  width: 100%;
  min-height: 50px;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  font-size: 16px;
  font-weight: 500;
  display: flex;
  place-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.color.border[2]};
  position: relative;
`;

export const DrawCloseButton = styled(`div`)`
  width: 20px;
  cursor: pointer;
  position: absolute;
  left: 15px;
  top: 15px;
  display: block;
  fill: ${({ theme }) => theme.color.font[2]};

  &:hover {
    fill: ${({ theme }) => theme.color.font_bold[2]};
  }
`;

export const DrawBody = styled(`div`)`
  overflow: auto;
  margin-bottom: auto;
  padding: 12px;
  height: 100%;
`;

export const FilterInputContainer = styled(`div`)`
  display: flex;
  flex-direction: column;
  padding-bottom: 12px;
`;

export const DrawFooter = styled(`div`)`
  background: ${({ theme }) => theme.color.main_bg[2]};
  border-top: 1px solid ${({ theme }) => theme.color.border[2]};
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
`;
