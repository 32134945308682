import axios from "axios";
import matchSorter from "match-sorter";
import moment from "moment";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { fetchPlaces } from "../../services/editPlace";
import errToStr from "../../util/errToStr";
import { humaniseHours } from "../../util/humaniseDurations";
import sortTags from "../../util/sortTags";
import FlatpickrRangePicker from "../FlatpickrRangePicker";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import LoadingContainer from "../LoadingContainer";
import { InfoModal } from "../Modal";
import Table, { CsvButtonsComponent } from "../Table";
import Tag from "../Tag";
import { TableHeaderButtons } from "./../NewTable/styles";

const PlaceListModal: FC<any> = ({ size = "sm", data, filters, title = "Place List", columns, modalOpen, setModalOpen, onClose }) => {
  const { color, short_date, short_datetime } = useContext(ThemeContext);

  const [places, setPlaces] = useState<any>(data !== undefined ? data : []);
  const [placesErr, setPlacesErr] = useState<string>("");
  const [placesLoading, setPlacesLoading] = useState<boolean>(false);

  const [selectedColumns, setSelectedColumns] = useState<any>([]);

  const tableRef = useRef<any>(null);

  useEffect(() => {
    if (data !== undefined) setPlaces(data);
  }, [data]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (data === undefined && filters) {
      setPlacesLoading(true);
      fetchPlaces(source, {
        filters,
      })
        .then(({ data }) => {
          setPlaces(data);
          setPlacesLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setPlacesErr(errToStr(err));
            setPlacesLoading(false);
          }
        });
    }

    return () => {
      source.cancel();
    };
  }, [filters]);

  useEffect(() => {
    setSelectedColumns(columnDefinitions.filter((columnDef: any) => columns.some((column: any) => column === columnDef.id)));
  }, [columns]);

  const formatDataToCsv = (tableRef: any) => {
    const sortedData = tableRef.current.getResolvedState().sortedData;
    const headers = [];

    if (columns.includes("id")) headers.push("Place ID");
    if (columns.includes("name")) headers.push("Name");
    if (columns.includes("placeGroup")) headers.push("Place Type");
    if (columns.includes("placeTags")) headers.push("Place Tags");
    if (columns.includes("address")) headers.push("Address");
    if (columns.includes("city")) headers.push("City");
    if (columns.includes("state")) headers.push("State");
    if (columns.includes("postcode")) headers.push("Postcode");
    if (columns.includes("country")) headers.push("Country");
    if (columns.includes("startDate")) headers.push("Start Date");
    if (columns.includes("endDate")) headers.push("End Date");
    if (columns.includes("duration")) headers.push("Duration");

    return [
      headers,
      ...sortedData.map((row: any) => {
        const rows = [];

        if (columns.includes("id")) rows.push(`${row.id}${row.name ? " (" + row.name + ")" : ""}`);
        if (columns.includes("placeGroup")) rows.push(row.placeGroup);
        if (columns.includes("placeTags"))
          rows.push(
            row.placeTags
              ? row.placeTags
                  .sort(sortTags)
                  .map((tag: any) => tag.name)
                  .join(", ")
              : ""
          );
        if (columns.includes("address")) rows.push(row.address);
        if (columns.includes("city")) rows.push(row.city);
        if (columns.includes("state")) rows.push(row.state);
        if (columns.includes("postcode")) rows.push(row.postcode);
        if (columns.includes("country")) rows.push(row.country);
        if (columns.includes("startDate")) rows.push(moment.unix(row.startDate).format(short_datetime));
        if (columns.includes("endDate")) rows.push(moment.unix(row.endDate).format(short_datetime));
        if (columns.includes("duration")) rows.push(humaniseHours(row.duration));

        return rows;
      }, []),
    ];
  };

  const columnDefinitions = React.useMemo<any>(
    () => [
      {
        id: "id",
        Header: "Place",
        accessor: "id",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["id", "name"],
          }),
        filterAll: true,
        Cell: (props: any) => {
          let link = `/places/${props.value}`;
          if (props.original.startDate && props.original.endDate) link += `?start=${props.original.startDate}&end=${props.original.endDate}`;
          else if (props.original.startDate) link += `?start=${props.original.startDate}`;
          else if (props.original.endDate) link += `?end=${props.original.endDate}`;

          return (
            <Link to={link} title={props.original.name}>
              {props.original.name}
            </Link>
          );
        },
        minWidth: 210,
        maxWidth: 210,
      },
      {
        id: "placeGroup",
        Header: "Place Type",
        accessor: "placeGroup",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["placeGroup"],
          }),
        filterAll: true,
        Cell: (props: any) => (
          <>
            <ColoredDot color={props.original.placeGroupColour} />
            <span title={props.value}>{props.value}</span>
          </>
        ),
        minWidth: 130,
        maxWidth: 130,
      },
      {
        id: "placeTags",
        Header: "Place Tags",
        accessor: "placeTags",
        style: { textOverflow: "unset", whiteSpace: "normal" },
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["placeTags"],
          }),
        filterAll: true,
        Cell: (props: any) =>
          props.value ? (
            props.value.sort(sortTags).map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
          ) : (
            <></>
          ),
        minWidth: 130,
        maxWidth: 130,
      },
      {
        id: "address",
        Header: "Address",
        accessor: "address",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["address"],
          }),
        filterAll: true,
        minWidth: 150,
        maxWidth: 150,
      },
      {
        id: "city",
        Header: "City",
        accessor: "city",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["city"],
          }),
        filterAll: true,
        minWidth: 150,
        maxWidth: 150,
      },
      {
        id: "state",
        Header: "State",
        accessor: "state",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["state"],
          }),
        filterAll: true,
        minWidth: 150,
        maxWidth: 150,
      },
      {
        id: "postcode",
        Header: "Postcode",
        accessor: "postcode",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["postcode"],
          }),
        filterAll: true,
        minWidth: 120,
        maxWidth: 120,
      },
      {
        id: "country",
        Header: "Country",
        accessor: "country",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["country"],
          }),
        filterAll: true,
        minWidth: 110,
        maxWidth: 110,
      },
      {
        id: "startDate",
        Header: "Start Date",
        accessor: "startDate",
        filterMethod: (filter: any, row: any) => {
          if (filter.value.length === 2) {
            if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
            else return false;
          } else return true;
        },
        Filter: ({ onChange }: any) => (
          <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
        ),
        Cell: (props: any) => moment.unix(props.value).format(short_datetime),
        minWidth: 160,
        maxWidth: 160,
      },
      {
        id: "endDate",
        Header: "End Date",
        accessor: "endDate",
        filterMethod: (filter: any, row: any) => {
          if (filter.value.length === 2) {
            if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
            else return false;
          } else return true;
        },
        Filter: ({ onChange }: any) => (
          <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
        ),
        Cell: (props: any) => moment.unix(props.value).format(short_datetime),
        minWidth: 160,
        maxWidth: 160,
      },
      {
        id: "duration",
        Header: "Duration",
        accessor: "duration",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: [(item: any) => humaniseHours(item.duration)],
          }),
        filterAll: true,
        Cell: (props: any) => (props.value !== undefined ? <span title={humaniseHours(props.value)}>{humaniseHours(props.value)}</span> : "-"),
        minWidth: 160,
        maxWidth: 160,
      },
    ],
    []
  );

  const defaultSorted = [
    {
      id: "startDate",
      desc: false,
    },
  ];

  return (
    <InfoModal
      size={size}
      isOpen={modalOpen}
      onClose={() => {
        if (onClose) onClose();
        setModalOpen(false);
      }}
      title={title}
      okayBtnText="Close"
      okayBtnProps={{ width: "100%" }}
      body={
        <LoadingContainer loading={placesLoading} err={placesErr}>
          <TableHeaderButtons>
            <div style={{ display: "flex" }}></div>
            <CsvButtonsComponent data={places} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName={`${title}.csv`} />
          </TableHeaderButtons>
          <Table
            loading={placesLoading}
            filterable={true}
            style={{ clear: "both" }}
            data={places}
            columns={selectedColumns}
            defaultSorted={defaultSorted}
            ref={tableRef}
            defaultPageSize={15}
          />
        </LoadingContainer>
      }
    />
  );
};

export default PlaceListModal;
