import React, { FC } from "react";

const PlaceIcon: FC<any> = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 403.2">
    <path
      fill={fill}
      d="M472 387.2h-48v-264a8 8 0 00-8-8H304V8a8 8 0 00-8-8H88a8 8 0 00-8 8v379.2H8a8 8 0 000 16h464a8 8 0 000-16zm-304 0h-32v-72a8 8 0 018-8h16a8 8 0 018 8zm32-264v264h-16v-72a24 24 0 00-24-24h-16a24 24 0 00-24 24v72H96V16h192v99.2h-80a8 8 0 00-8 8zm208 264H216v-256h192z"
    />
    <path
      fill={fill}
      d="M256 35.2h-48a8 8 0 00-8 8v48a8 8 0 008 8h48a8 8 0 008-8v-48a8 8 0 00-8-8zm-8 48h-32v-32h32zM176 35.2h-48a8 8 0 00-8 8v48a8 8 0 008 8h48a8 8 0 008-8v-48a8 8 0 00-8-8zm-8 48h-32v-32h32zM176 115.2h-48a8 8 0 00-8 8v48a8 8 0 008 8h48a8 8 0 008-8v-48a8 8 0 00-8-8zm-8 48h-32v-32h32zM176 195.2h-48a8 8 0 00-8 8v48a8 8 0 008 8h48a8 8 0 008-8v-48a8 8 0 00-8-8zm-8 48h-32v-32h32zM328 291.2h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32zM328 211.2h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32zM328 371.2h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32zM248 291.2h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32zM248 211.2h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32zM248 371.2h48a8 8 0 008-8v-48a8 8 0 00-8-8h-48a8 8 0 00-8 8v48a8 8 0 008 8zm8-48h32v32h-32z"
    />
  </svg>
);

export default PlaceIcon;
