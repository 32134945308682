import React, { FC, useState, useEffect, useContext } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { FormError, FormInput } from "../FormComponents";
import { exists, validName, maxLength } from "../../util/formValidations";
import { ModalFormContainer } from "../Modal/styles";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import ColorPicker from "../ColorPicker";
import { ThemeContext } from "styled-components";
import matchSorter from "match-sorter";
import Bold from "../Bold";
import Checkbox from "../Checkbox";
import Table from "../Table";
import Tag from "../Tag";
import sortTags from "../../util/sortTags";
import { getAllContents } from "../../services/contents";
import { getAccount } from "../../services/localStorage";
import { addContentTag, editContentTag } from "../../services/editContentTags";

const initTag = (tag: any, echarts_palette: any) => {
  return {
    id: null,
    name: "",
    colour: echarts_palette[Math.floor(Math.random() * echarts_palette.length)],
    description: "",
    contentCount: 0,
    organisationId: "",
    organisationName: "",
    ...tag,
  };
};

const EditContentTagModel: FC<any> = ({ tag, fetch, modalOpen, setModalOpen }) => {
  const { echarts_palette } = useContext(ThemeContext);

  const [formData, setFormData] = useState<any>(initTag(tag, echarts_palette));
  const [formErrors, setFormErrors] = useState<any>({});

  const [contents, setContents] = useState<any>([]);
  const [contentsErr, setContentsErr] = useState<string>("");
  const [contentsLoading, setContentsLoading] = useState<boolean>(false);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [contentChanges, setContentChanges] = useState<any>({});

  const [filterOptions, setFilterOptions] = useState<any>({
    contentTags: [],
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setContentsLoading(true);
    getAllContents(source, false, { filters: `organisationId eq ${getAccount().organisationId}` })
      .then(({ data }) => {
        // Sets selected and initSelected property to indicate if contents have the current tag or not and to sort the rows
        // by initSelected to prevent the rows bouncing around when editing
        setContents(
          data.map((item: any) => {
            const selected = item.contentTags.some((tag: any) => formData.id === tag.id);
            return {
              ...item,
              selected: selected,
              initSelected: selected,
            };
          })
        );
        setContentsLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setContentsErr(errToStr(err));
          setContentsLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [formData.id, source]);

  // Get distinct strings for use in the select input filters when contents changes
  useEffect(() => {
    if (contents.length > 0) {
      const contentTags = new Set();

      for (let i = 0; i < contents.length; i++) {
        if (contents[i].contentTags) contents[i].contentTags.forEach((tag: any) => contentTags.add(tag.name));
      }

      setFilterOptions({
        contentTags: Array.from(contentTags).sort(),
      });
    }
  }, [contents]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "name":
          currValid = exists(name, value, setFormErrors) && maxLength(name, value, 50, setFormErrors) && validName(name, value, setFormErrors);
          break;

        case "description":
          currValid = maxLength(name, value, 500, setFormErrors);
          break;

        case "colour":
          currValid = exists(name, value, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const contentChangesArr = Object.values(contentChanges);

    const addContentIds: any = [];
    const removeContentIds: any = [];

    contentChangesArr.forEach((item: any) => {
      // If an item is selected it will be updated with the current tag
      if (item.selected) addContentIds.push(item.id);

      // If an item is not selected and we are editing an existing tag and we have
      // de-selected an item within the current tag, remove tag from the item
      if (!item.selected && formData.id && item.contentTags.some((tag: any) => formData.id === tag.id)) removeContentIds.push(item.id);
    });

    // If the tag has an id then we are editing it therefore include the removeContentIds array
    const formattedData: any = {
      id: formData.id,
      name: formData.name,
      colour: formData.colour,
      description: formData.description,
      addContentIds,
    };

    if (formData.id) {
      formattedData.removeContentIds = removeContentIds;
    }

    return formattedData;
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      // If the tag has an id then we are editing it
      if (body.id) {
        setSubmitting(true);
        editContentTag(source, body)
          .then(() => {
            if (fetch) fetch();
            setSubmittedMsg("Content Tag Updated");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      } else {
        setSubmitting(true);
        addContentTag(source, body)
          .then(() => {
            if (fetch) fetch();
            setSubmittedMsg("Content Tag Created");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      }
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleCheckboxChange = (e: any) => {
    e.persist();
    const index = e.target.name;
    const item = contents[index];

    setContentChanges((prev: any) => {
      const curr = JSON.parse(JSON.stringify(prev));
      curr[item.id] = item;
      return curr;
    });
    setContents((prev: any) => {
      const curr = [...prev];
      curr[index].selected = e.target.checked;
      return curr;
    });
  };

  const columns: any = [];

  columns.push({
    id: "selected",
    Header: "Selected",
    accessor: "selected",
    minWidth: 120,
    maxWidth: 120,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "true") {
        return row[filter.id] === true;
      }
      if (filter.value === "false") {
        return row[filter.id] === false;
      }
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
    ),
    Cell: (props: any) => <Checkbox onChange={handleCheckboxChange} name={props.index} checked={props.value} />,
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  columns.push({
    id: "identifier",
    Header: "ID",
    accessor: "identifier",
    minWidth: 140,
    maxWidth: 140,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["identifier"],
      }),
    filterAll: true,
  });

  columns.push({
    id: "name",
    Header: "Name",
    accessor: "name",
    minWidth: 140,
    maxWidth: 140,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["name"],
      }),
    filterAll: true,
  });

  columns.push({
    id: "contentTags",
    Header: "Content Tags",
    accessor: "contentTags",
    minWidth: 140,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "none") {
        return row[filter.id] === undefined;
      }
      return row[filter.id] === filter.value;
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="none">None</option>
        {filterOptions.contentTags.map((tag: any) => {
          return (
            <option key={tag} value={tag}>
              {tag}
            </option>
          );
        }, [])}
      </select>
    ),
    Cell: (props: any) =>
      props.original.contentTags ? (
        props.original.contentTags.sort(sortTags).map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
      ) : (
        <></>
      ),
  });

  // exists here to sort the rows by selected and prevent them bouncing around when editing
  columns.push({
    id: "initSelected",
    accessor: "initSelected",
    show: false,
  });

  const defaultSorted = [
    {
      id: "initSelected",
      desc: true,
    },
    {
      id: "id",
      desc: false,
    },
  ];

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      size={!submittedMsg && !submittingErr ? "lg" : "sm"}
      title={formData.id ? "Edit Content Tag" : "Create Content Tag"}
      success={submittedMsg}
      error={submittingErr}
      submitBtnText={formData.id ? "Update" : "Create"}
      body={
        <LoadingContainer loading={contentsLoading || submitting} err={contentsErr}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer>
              <label>Tag Name</label>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <FormInput type="text" name="name" value={formData.name} error={formErrors.name} onChange={handleChange} />
                </div>
                <div style={{ width: "100%", maxWidth: "34px" }}>
                  <ColorPicker color={formData.colour} setColor={(color: any) => setFormData((prev: any) => ({ ...prev, colour: color.hex }))} />
                </div>
              </div>
              <FormError error={formErrors.name || formErrors.colour}>
                <span>{formErrors.name}</span>
                <span style={{ position: "absolute", right: "20px" }}>{formErrors.colour}</span>
              </FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <label>Description</label>
              <FormInput type="text" name="description" value={formData.description} error={formErrors.description} onChange={handleChange} />
              <FormError error={formErrors.description}>{formErrors.description}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <Table
                loading={contentsLoading}
                filterable={true}
                style={{ clear: "both" }}
                data={contents}
                columns={columns}
                defaultSorted={defaultSorted}
                defaultPageSize={10}
              />
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default EditContentTagModel;
