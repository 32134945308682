// Function to calculate the distance in km between two points given their latitude and longitude using the Haversine formula
const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number | null => {
  // Function to convert degrees to radians
  const toRadians = (degrees: number): number => degrees * (Math.PI / 180);

  // Earth's radius in kilometers
  const earthRadiusKm = 6371;

  // Validate input coordinates
  const validateCoordinates = (lat: number, lon: number): boolean => {
    return lat >= -90 && lat <= 90 && lon >= -180 && lon <= 180;
  };

  try {
    // Check that all provided coordinates are valid
    if (!validateCoordinates(lat1, lon1) || !validateCoordinates(lat2, lon2)) {
      console.error("Invalid coordinates provided. Latitude must be between -90 and 90 and longitude must be between -180 and 180.");
      return null;
    }

    // Calculate the differences in latitude and longitude
    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);

    // Calculate the intermediate value 'a' using the Haversine formula
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

    // Calculate the angular distance 'c'
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    // Calculate and return the distance in kilometers
    return earthRadiusKm * c;
  } catch (error) {
    console.error("An error occurred while calculating the distance:", error);
    return null;
  }
};

export default calculateDistance;
