import axios from "axios";
import matchSorter from "match-sorter";
import moment from "moment";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { getTrackers } from "../../services/trackers";
import errToStr from "../../util/errToStr";
import { humaniseHours } from "../../util/humaniseDurations";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import sortTags from "../../util/sortTags";
import AssetTypeLabel from "../AssetTypeLabel";
import FlatpickrRangePicker from "../FlatpickrRangePicker";
import LoadingContainer from "../LoadingContainer";
import { InfoModal } from "../Modal";
import { TableHeaderButtons } from "../NewTable/styles";
import Table, { CsvButtonsComponent } from "../Table";
import Tag from "../Tag";

const TrackerListModal: FC<any> = ({
  size = "sm",
  data,
  filters,
  title = kegsOrTrackers("Keg List", "Tracker List"),
  columns,
  modalOpen,
  setModalOpen,
  onClose,
}) => {
  const { short_date, short_datetime } = useContext(ThemeContext);

  const [trackers, setTrackers] = useState<any>(data !== undefined ? data : []);
  const [trackersErr, setTrackersErr] = useState<string>("");
  const [trackersLoading, setTrackersLoading] = useState<boolean>(false);

  const [selectedColumns, setSelectedColumns] = useState<any>([]);

  const tableRef = useRef<any>(null);

  useEffect(() => {
    if (data !== undefined) setTrackers(data);
  }, [data]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (data === undefined && filters) {
      setTrackersLoading(true);
      getTrackers(source, {
        filters,
      })
        .then(({ data }) => {
          setTrackers(data);
          setTrackersLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setTrackersErr(errToStr(err));
            setTrackersLoading(false);
          }
        });
    }

    return () => {
      source.cancel();
    };
  }, [filters]);

  useEffect(() => {
    setSelectedColumns(columnDefinitions.filter((columnDef: any) => columns.some((column: any) => column === columnDef.id)));
  }, [columns]);

  const formatDataToCsv = (tableRef: any) => {
    const sortedData = tableRef.current.getResolvedState().sortedData;
    const headers = [];

    if (columns.includes("id")) headers.push(kegsOrTrackers("Keg", "Tracker"));
    if (columns.includes("assetTypeName")) headers.push("Asset Type");
    if (columns.includes("trackerTags")) headers.push(kegsOrTrackers("Keg Tags", "Tracker Tags"));
    if (columns.includes("startDate")) headers.push("Start Date");
    if (columns.includes("endDate")) headers.push("End Date");
    if (columns.includes("duration")) headers.push("Duration");

    return [
      headers,
      ...sortedData.map((row: any) => {
        const rows = [];

        if (columns.includes("id")) rows.push(`${row.id}${row.name ? " (" + row.name + ")" : ""}`);
        if (columns.includes("assetTypeName")) rows.push(row.assetTypeName);
        if (columns.includes("trackerTags"))
          rows.push(
            row.trackerTags
              ? row.trackerTags
                  .sort(sortTags)
                  .map((tag: any) => tag.name)
                  .join(", ")
              : ""
          );
        if (columns.includes("startDate")) rows.push(moment.unix(row.startDate).format(short_datetime));
        if (columns.includes("endDate")) rows.push(moment.unix(row.endDate).format(short_datetime));
        if (columns.includes("duration")) rows.push(humaniseHours(row.duration));

        return rows;
      }, []),
    ];
  };

  const columnDefinitions = React.useMemo<any>(
    () => [
      {
        id: "id",
        Header: kegsOrTrackers("Keg", "Tracker"),
        accessor: "id",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["id", "name"],
          }),
        filterAll: true,
        Cell: (props: any) => {
          let link = `/${kegsOrTrackers("kegs", "trackers")}/${props.value}`;
          if (props.original.startDate && props.original.endDate) link += `?start=${props.original.startDate}&end=${props.original.endDate}`;
          else if (props.original.startDate) link += `?start=${props.original.startDate}`;
          else if (props.original.endDate) link += `?end=${props.original.endDate}`;

          let title = props.value ? props.value : "";
          title += `${props.original.name ? " (" + props.original.name + ")" : ""}`;

          return (
            <Link to={link} title={title}>
              {title}
            </Link>
          );
        },
      },
      {
        id: "assetTypeName",
        Header: "Asset Type",
        accessor: "assetTypeName",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["assetTypeName"],
          }),
        filterAll: true,
        Cell: (props: any) => (
          <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
            <AssetTypeLabel name={props.value} colour={props.original.assetTypeColour} icon={props.original.assetTypeIcon} />
          </div>
        ),
      },
      {
        id: "trackerTags",
        Header: kegsOrTrackers("Keg Tags", "Tracker Tags"),
        accessor: "trackerTags",
        style: { textOverflow: "unset", whiteSpace: "normal" },
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["trackerTags"],
          }),
        filterAll: true,
        Cell: (props: any) =>
          props.value ? (
            props.value.sort(sortTags).map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
          ) : (
            <></>
          ),
      },
      {
        id: "startDate",
        Header: "Start Date",
        accessor: "startDate",
        filterMethod: (filter: any, row: any) => {
          if (filter.value.length === 2) {
            if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
            else return false;
          } else return true;
        },
        Filter: ({ onChange }: any) => (
          <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
        ),
        Cell: (props: any) => moment.unix(props.value).format(short_datetime),
        minWidth: 160,
      },
      {
        id: "endDate",
        Header: "End Date",
        accessor: "endDate",
        filterMethod: (filter: any, row: any) => {
          if (filter.value.length === 2) {
            if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
            else return false;
          } else return true;
        },
        Filter: ({ onChange }: any) => (
          <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
        ),
        Cell: (props: any) => moment.unix(props.value).format(short_datetime),
        minWidth: 160,
      },
      {
        id: "duration",
        Header: "Duration",
        accessor: "duration",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: [(item: any) => humaniseHours(item.duration)],
          }),
        filterAll: true,
        Cell: (props: any) => (props.value !== undefined ? <span title={humaniseHours(props.value)}>{humaniseHours(props.value)}</span> : "-"),
        minWidth: 160,
      },
    ],
    []
  );

  const defaultSorted = [
    {
      id: "id",
      desc: false,
    },
  ];

  return (
    <InfoModal
      size={size}
      isOpen={modalOpen}
      onClose={() => {
        if (onClose) onClose();
        setModalOpen(false);
      }}
      title={title}
      okayBtnText="Close"
      okayBtnProps={{ width: "100%" }}
      body={
        <LoadingContainer loading={trackersLoading} err={trackersErr}>
          <TableHeaderButtons>
            <div style={{ display: "flex" }}></div>
            <CsvButtonsComponent data={trackers} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName={`${title}.csv`} />
          </TableHeaderButtons>
          <Table
            loading={trackersLoading}
            filterable={true}
            style={{ clear: "both" }}
            data={trackers}
            columns={selectedColumns}
            defaultSorted={defaultSorted}
            ref={tableRef}
            defaultPageSize={15}
          />
        </LoadingContainer>
      }
    />
  );
};

export default TrackerListModal;
