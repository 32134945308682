import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const fetchInTransitDuration = async (source: CancelTokenSource, trackerTags: string, assetType: string) => {
  const params = {
    organisationId: getAccount().organisationId,
    trackerTags,
    assetType,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/intransitduration",
    params,
  });

  return data;
};
