import React, { FC } from "react";
import PhoneInput from "react-phone-number-input";
import { PhoneNumberInputStyles } from "./styles";

const PhoneNumberInput: FC<any> = (props) => {
  const { error, ...restProps } = props;

  return (
    <>
      <PhoneNumberInputStyles error={error} />
      <PhoneInput {...restProps} />
    </>
  );
};

export default PhoneNumberInput;
