import React, { FC } from "react";

const ErrIcon: FC<any> = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.024 25.023">
    <path fill={fill} fillOpacity={0.6} d="M12.024 0a12 12 0 1 0 12 12 12 12 0 0 0-12-12zm1.2 18h-2.4v-2.4h2.4zm0-4.8h-2.4V6h2.4z" />
    <path
      fill={fill}
      fillOpacity={0.9}
      d="M12.023 0C1.332 0-4.02 12.925 3.54 20.484c7.56 7.56 20.484 2.208 20.484-8.484 0-6.627-5.372-12-12-12zm-.002 1.818c5.625 0 10.184 4.56 10.184 10.184 0 9.073-10.968 13.616-17.383 7.201-6.415-6.415-1.874-17.385 7.2-17.385z"
    />
  </svg>
);

export default ErrIcon;
