import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const postTestAlert = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/api/testalert",
    data: JSON.stringify({
      ...body,
      organisationId: getAccount().organisationId,
    }),
  });

  return data;
};
