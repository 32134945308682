import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

type Props = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: any;
  filters?: any;
};

export const fetchBatch = async (source: CancelTokenSource, { pageIndex, pageSize, orderBy, filters }: Props, placeId?: number, batchId?: string) => {
  const params: any = {
    organisationId: getAccount().organisationId,
    placeId,
    count: true,
  };

  if (pageIndex !== undefined && pageSize !== undefined) params.skip = pageIndex * pageSize;

  if (pageSize !== undefined) params.top = pageSize;

  if (orderBy && orderBy.length > 0) params.orderby = orderBy.map((order: any) => `${order.id} ${order.desc ? "desc" : "asc"}`).join(", ");

  if (filters) params.filter = filters;

  const { data, headers } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + `/v1/batch/${batchId ? batchId : ""}`,
    params,
  });

  return { count: headers?.count, data };
};

export const postBatch = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/api/batch",
    data: JSON.stringify({
      ...body,
      organisationId: getAccount().organisationId,
    }),
  });

  return data;
};

export const putBatch = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + "/api/batch",
    data: JSON.stringify({
      ...body,
      organisationId: getAccount().organisationId,
    }),
  });

  return data;
};

export const deleteBatch = async (source: CancelTokenSource, batchId: string) => {
  const params = {
    batchId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "delete",
    url: process.env.REACT_APP_API_URL + "/api/batch",
    params,
  });

  return data;
};
