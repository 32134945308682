import ReactEcharts from "echarts-for-react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import hkCalibrationsLogo from "../../../imgs/hkCalibrationsLogo.jpg";
import calculateGrid from "../../../util/calculateGrid";
import { OutlineBtn, PrimaryBtn } from "../../Buttons";
import { appliedTempSeries, appliedTempTooltip, measuredTempSeries, tempTooltip, tempYAxis } from "../../Charts";
import { InfoModal } from "../../Modal";
import { Subtitle, Title } from "../../PageTitle/styles";
import { ClearPanel, Panel } from "../../Panel";
import { PlainTemperatureSpan } from "../../ReportsScreen/styles";
import Table, { NoDataComponent } from "../../Table";
import { getAccount } from "../../../services/localStorage";

const CalibrationResultsModal: FC<any> = ({ data, modalOpen, setModalOpen, onClose }) => {
  const { echarts_palette, short_datetime, echarts_theme, echarts_opacity } = useContext(ThemeContext);

  const accountInfo = getAccount();

  const tableRef = useRef<any>(null);
  const bodyRef = useRef<any>(null);
  const options = useRef<any>({ dataset: { dimensions: [] } });
  const echartsContainerRef = useRef(null);

  const [echartsInstance, setEchartsInstance] = useState<any>(undefined);
  const [height, setHeight] = useState<number>(550);

  useEffect(() => {
    if (echartsInstance) {
      let tsMin = 0;
      let tsMax = 0;

      const { logs, results } = data;

      // Create a new array of logs with applied temperature
      const newLogs = results.reduce((acc: any[], result: { appliedDate: any; appliedTemp: any }) => {
        const { appliedDate, appliedTemp } = result;
        const insertIndex = acc.findIndex((log) => log.ts >= appliedDate);

        if (insertIndex === -1) {
          return [...acc, { ts: appliedDate, appliedTemp }];
        } else {
          return [...acc.slice(0, insertIndex), { ts: appliedDate, appliedTemp }, ...acc.slice(insertIndex)];
        }
      }, logs.slice());

      if (newLogs && newLogs.length > 0) {
        tsMin = newLogs[0].ts;
        tsMax = newLogs[newLogs.length - 1].ts > tsMax ? newLogs[newLogs.length - 1].ts : tsMax;
      }

      const grid = calculateGrid(["temp"], setHeight);
      const xAxis = [
        {
          gridIndex: 0,
          type: "time",
          min: tsMin,
          max: tsMax,
          minInterval: 3600 * 24,
          axisLabel: {
            hideOverlap: true,
            formatter: (value: any): string => moment.unix(value).format(short_datetime),
          },
          axisPointer: {
            label: {
              show: false,
            },
          },
        },
      ];
      const yAxis = [tempYAxis(0)];
      const series = [measuredTempSeries(0, "none", echarts_opacity, echarts_palette), appliedTempSeries(0, "none", echarts_opacity, echarts_palette)];
      const dataZoom = [
        {
          type: "inside",
          xAxisIndex: 0,
        },
      ];

      const tooltipFormatter = (params: any) => {
        if (params.length > 0) {
          const ts = params[0].data.ts || params[0].data[0];
          let tooltip = `<b>${moment.unix(ts).format(short_datetime)}</b><br />`;

          for (let i = 0; i < params.length; i++) {
            if (params[i].seriesId == "temp") {
              tooltip = tempTooltip(params[i], tooltip, undefined);
            } else if (params[i].seriesId === "appliedTemp") {
              tooltip = appliedTempTooltip(params[i], tooltip, undefined);
            }
          }

          tooltip = `<span style="word-break: keep-all;white-space: normal;">` + tooltip + "</span>";
          return tooltip;
        }
      };

      options.current = {
        grid: grid,
        dataset: {
          dimensions: ["ts", "temp", "appliedTemp"],
          source: newLogs,
        },
        tooltip: {
          confine: true,
          trigger: "axis",
          transitionDuration: 0,
          axisPointer: {
            type: "cross",
            snap: true,
          },
          formatter: tooltipFormatter,
        },
        xAxis: xAxis,
        yAxis: yAxis,
        axisPointer: {
          link: { xAxisIndex: "all" },
          snap: true,
        },
        series: series,
        dataZoom,
        animation: false,
      };

      // Updates the options of the chart each render
      if (options && options.current) {
        echartsInstance.clear();
        echartsInstance.setOption(options.current);
      }
    }
  }, [echartsInstance, data, short_datetime, echarts_opacity]);

  // listens for window resize events and triggers a chart resize so it fits
  // properly within it's container, fixes issue on iOS tablet when screen rotates
  const handleResizeEvent = useCallback(() => {
    echartsInstance.resize();
  }, [echartsInstance]);

  useEffect(() => {
    window.removeEventListener("resize", handleResizeEvent);

    if (echartsInstance) {
      window.addEventListener("resize", handleResizeEvent);
    }

    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, [echartsInstance]);

  const columns = [
    {
      id: "appliedDate",
      Header: () => <div style={{ padding: "6px 0" }}>Applied Date</div>,
      accessor: "appliedDate",
      Cell: (props: any) => moment.unix(props.value).format(short_datetime),
      minWidth: 160,
    },
    {
      id: "logDate",
      Header: () => <div style={{ padding: "6px 0" }}>Measured Date</div>,
      accessor: "logDate",
      Cell: (props: any) => moment.unix(props.value).format(short_datetime),
      minWidth: 160,
    },
    {
      id: "appliedTemp",
      Header: () => <div style={{ padding: "6px 0" }}>Applied Temperature</div>,
      accessor: "appliedTemp",
      Cell: (props: any) => <PlainTemperatureSpan temp={props.value} />,
      minWidth: 140,
    },
    {
      id: "measuredTemp",
      Header: () => <div style={{ padding: "6px 0" }}>Measured Temperature</div>,
      accessor: "measuredTemp",
      Cell: (props: any) => <PlainTemperatureSpan temp={props.value} />,
      minWidth: 140,
    },
    {
      id: "correction",
      Header: () => <div style={{ padding: "6px 0" }}>Correction</div>,
      accessor: "correction",
      Cell: (props: any) => <PlainTemperatureSpan temp={props.value} />,
      minWidth: 140,
    },
  ];

  const defaultSorted = [
    {
      id: "appliedDate",
      desc: true,
    },
  ];

  const exportAsPDF = () => {
    const input = bodyRef.current;
    const scale = 2; // You can adjust the scale if needed

    html2canvas(input, { scale }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 0.75); // Adjust quality to reduce file size

      // Create a jsPDF instance with landscape orientation
      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
      });

      const margin = 10; // 10mm margin
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = pageWidth - 2 * margin;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeight;
      let position = margin;

      pdf.addImage(imgData, "JPEG", margin, position, imgWidth, imgHeight);
      heightLeft -= pageHeight - 2 * margin;

      while (heightLeft > 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "JPEG", margin, position, imgWidth, imgHeight);
        heightLeft -= pageHeight - 2 * margin;
      }

      pdf.save(`Calibration Results - ${data.trackerId}.pdf`);
    });
  };

  const handleClose = () => {
    if (onClose) onClose();
    setModalOpen(false);
  };

  return (
    <>
      <InfoModal
        isOpen={modalOpen}
        onClose={handleClose}
        size="xl"
        title="Calibration Results"
        body={
          <div ref={bodyRef}>
            <ClearPanel style={{ textAlign: "center" }}>
              <Title style={{ marginBottom: "6px" }}>Tracker Calibration</Title>
              <Subtitle style={{ padding: "6px 0" }}>Temperature Measurement for {data.trackerId}</Subtitle>
            </ClearPanel>
            <Table
              style={{ clear: "both" }}
              data={data.results}
              filterable={false}
              columns={columns}
              defaultPageSize={Number.MAX_SAFE_INTEGER}
              defaultSorted={defaultSorted}
              ref={tableRef}
            />
            <Panel style={{ marginTop: "24px" }}>
              <div style={{ position: "relative" }}>
                {data.logs && data.logs.length > 0 ? (
                  <div style={{ height: "100%", width: "100%" }} ref={echartsContainerRef}>
                    {options && (
                      <ReactEcharts
                        key="calibration-logs-chart"
                        onChartReady={(chart: any) => setEchartsInstance(chart)}
                        style={{ height: height, width: "100%" }}
                        option={options.current}
                        notMerge={true}
                        lazyUpdate={true}
                        theme={echarts_theme}
                        opts={{ renderer: "svg" }}
                      />
                    )}
                  </div>
                ) : (
                  <NoDataComponent style={{ top: "65%" }}>No logs found</NoDataComponent>
                )}
              </div>
            </Panel>
            {accountInfo.organisationName == "HK Calibrations" && (
              <img src={hkCalibrationsLogo} alt="HK Calibration Logo" style={{ maxWidth: "256px", margin: "24px auto 0" }} />
            )}
          </div>
        }
        footer={
          <>
            <PrimaryBtn onClick={() => exportAsPDF()}>Save PDF</PrimaryBtn>
            <OutlineBtn onClick={() => handleClose()}>Close</OutlineBtn>
          </>
        }
      />
    </>
  );
};

export default CalibrationResultsModal;
