import axios, { CancelTokenSource } from "axios";
import { getAccount, getToken } from "../localStorage";

export const fetchPhoto = async (source: CancelTokenSource, id?: string) => {
  const params = {
    organisationId: getAccount().organisationId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + `/v1/photos/${id ? id : ""}`,
    params,
  });

  return data;
};

export const putPhoto = async (source: CancelTokenSource, file: any, trackerId?: string, organisationId?: string, placeId?: string, userId?: string) => {
  const params = {
    trackerId,
    organisationId,
    placeId,
    userId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + `/v1/photos/`,
    params,
    data: file,
  });

  return data;
};

export const deletePhoto = async (source: CancelTokenSource, trackerId?: string, organisationId?: string, placeId?: string, userId?: string) => {
  const params = {
    trackerId,
    organisationId,
    placeId,
    userId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "delete",
    url: process.env.REACT_APP_API_URL + `/v1/photos/`,
    params,
  });

  return data;
};
