import React, { FC, useEffect, useState } from "react";
import { PageContainer } from "../PageStyles";
import { Panel } from "../Panel";
import AlertsTable from "../AlertsTable";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import AlertLogsTable from "../AlertLogsTable";
import moment from "moment";
import getParameterByName from "../../util/getParamByName";
import DateRangeList from "../DateRangeList";
import { useLocation } from "react-router-dom";
import { TableHeaderButtons } from "../NewTable/styles";

const AlertsScreen: FC<any> = () => {
  const location = useLocation();

  // Gets details from URL query parameters if available to set date range
  const startDateParam = getParameterByName("start", location.search);
  const endDateParam = getParameterByName("end", location.search);
  const dateLabelParam = getParameterByName("label", location.search);

  const [filterDates, setFilterDates] = useState<any>({
    start: startDateParam !== null ? moment.unix(+startDateParam) : moment().subtract(7, "days"),
    end: endDateParam !== null ? moment.unix(+endDateParam) : moment(),
    dateLabel: startDateParam !== null || endDateParam !== null ? dateLabelParam : "Last 7 days",
  });

  useEffect(() => {
    const startDateParam = getParameterByName("start", location.search);
    const endDateParam = getParameterByName("end", location.search);
    const dateLabelParam = getParameterByName("label", location.search);
    setFilterDates({
      start: startDateParam !== null ? moment.unix(+startDateParam) : moment().subtract(7, "days"),
      end: endDateParam !== null ? moment.unix(+endDateParam) : moment(),
      dateLabel: startDateParam !== null || endDateParam !== null ? dateLabelParam : "Last 7 days",
    });
  }, [location]);

  return (
    <>
      <Tabs>
        <TabList className="react-tabs__tab-list__background_no-margin">
          <Tab>Alerts</Tab>
          <Tab>Alert Notifications</Tab>
        </TabList>
        <TabPanel>
          <PageContainer>
            <Panel>
              <AlertsTable />
            </Panel>
          </PageContainer>
        </TabPanel>
        <TabPanel>
          <PageContainer>
            <Panel>
              <TableHeaderButtons>
                <div style={{ display: "flex", marginLeft: "-6px" }}>
                  <DateRangeList filterDates={filterDates} setFilterDates={setFilterDates} maxDate={moment()} enableAllTime={true} />
                </div>
              </TableHeaderButtons>
              <AlertLogsTable filterDates={filterDates} />
            </Panel>
          </PageContainer>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default AlertsScreen;
