import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { postEditPassword } from "../../services/editPassword";
import { FormInput, FormError } from "../FormComponents";
import { ModalFormContainer } from "../Modal/styles";
import { validPassword, passwordsMatch } from "../../util/formValidations";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";

const ChangePasswordModal: FC<any> = ({ user, modalOpen, setModalOpen }) => {
  const [formErrors, setFormErrors] = useState<any>({});
  const [formData, setFormData] = useState<any>({
    id: user.userId,
    newPassword: "",
    confirmPassword: "",
  });

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const newPassword = formData.newPassword || "";
      const confirmPassword = formData.confirmPassword || "";

      switch (name) {
        case "newPassword":
          currValid = validPassword(name, newPassword, setFormErrors) && passwordsMatch("confirmPassword", newPassword, confirmPassword, setFormErrors);
          break;

        case "confirmPassword":
          currValid = passwordsMatch(name, newPassword, confirmPassword, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const handleSubmit = () => {
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      postEditPassword(source, formData)
        .then(() => {
          setSubmittedMsg("Password Changed");
          setSubmittingErr("");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) {
          setModalOpen(false);
        }
      }}
      title="Change Password"
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Change Password"
      body={
        <LoadingContainer loading={submitting}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer>
              <label>Password</label>
              <FormInput
                type="password"
                name="newPassword"
                value={formData.newPassword}
                error={formErrors.newPassword}
                onChange={handleChange}
                autoComplete="new-password"
              />
              <FormError error={formErrors.newPassword}>{formErrors.newPassword}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <label>Confirm Password</label>
              <FormInput
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                error={formErrors.confirmPassword}
                onChange={handleChange}
                autoComplete="new-password"
              />
              <FormError error={formErrors.confirmPassword}>{formErrors.confirmPassword}</FormError>
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default ChangePasswordModal;
