import axios, { CancelTokenSource } from "axios";

export const fetchBinaryAnalyticsDashboard = async (source: CancelTokenSource) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/binaryanalyticsdashboard",
  });

  return data;
};
