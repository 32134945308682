import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

type GetContentsProps = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: any;
  filters?: any;
};

export const getAllContents = async (source: CancelTokenSource, showAll: boolean, { pageIndex, pageSize, orderBy, filters }: GetContentsProps) => {
  const params: any = {
    organisationId: getAccount().organisationId,
    showAll,
    count: true,
  };

  if (pageIndex !== undefined && pageSize !== undefined) params.skip = pageIndex * pageSize;

  if (pageSize !== undefined) params.top = pageSize;

  if (orderBy && orderBy.length > 0) params.orderby = orderBy.map((order: any) => `${order.id} ${order.desc ? "desc" : "asc"}`).join(", ");

  if (filters) params.filter = filters;

  const { data, headers } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/contents",
    params,
  });

  return { count: headers?.count, data };
};

export const getContentDetails = async (source: CancelTokenSource, id: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + `/v1/contents/${id ? id : ""}`,
  });

  return data;
};

export const addContents = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + "/v1/contents/addContents",
    data: JSON.stringify(body),
  });

  return data;
};

export const editContents = async (source: CancelTokenSource, id: string, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + `/v1/contents/${id ? id : ""}`,
    data: JSON.stringify(body),
  });

  return data;
};

export const removeContents = async (source: CancelTokenSource, contents: any[]) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + `/v1/contents/remove`,
    data: JSON.stringify(contents),
  });

  return data;
};

export const removeAllContents = async (source: CancelTokenSource, manifestId: string, placeRemovedId: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + `/v1/contents/removeall/?manifestId=${manifestId}&placeRemovedId=${placeRemovedId}`,
  });

  return data;
};
