import axios, { CancelTokenSource } from "axios";
import { getAccount, getToken } from "../localStorage";

export const fetchEditOrganisations = async (source: CancelTokenSource) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/editorganisation",
  });

  return data;
};

export const fetchEditOrganisation = async (source: CancelTokenSource, id?: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/editorganisation/" + id,
  });

  return data;
};

export const postEditOrganisation = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/api/editorganisation",
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};

export const putEditOrganisation = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + "/api/editorganisation",
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};
