import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const getAverageTemperatureOnTap = async (source: CancelTokenSource, dates: any) => {
  const params = {
    organisationId: getAccount().organisationId,
    start: dates ? dates.start : undefined,
    end: dates ? dates.end : undefined,
  };

  const { data, status } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/averagetemperatureontap",
    params,
  });

  // if 204 (No Content) return null to display appropriate message
  if (status === 204) {
    return null;
  } else {
    return data;
  }
};
