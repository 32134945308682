import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" {...props}>
      <path
        d="M145.981 21.329c-6.235 2.139-10.578 5.032-15.094 10.055-3.384 3.764-4.454 5.762-15.93 29.749-5.06 10.575-6.655 13.236-8.316 13.867-1.251.475-14.762.781-34.509.781-26.957 0-33.255.208-37.186 1.227-16.27 4.216-29.504 17.449-33.72 33.719-1.862 7.187-1.862 227.609 0 234.796 4.216 16.27 17.45 29.503 33.72 33.719 7.211 1.869 322.897 1.869 330.108 0 16.27-4.216 29.504-17.449 33.72-33.719 1.862-7.187 1.862-227.609 0-234.796-4.216-16.27-17.45-29.503-33.72-33.719-3.921-1.016-10.207-1.229-36.651-1.238-23.737-.009-32.419-.259-33.872-.977-1.997-.987-4.405-5.416-15.94-29.317-6.46-13.387-10.518-18.013-19.844-22.623l-5.93-2.931-50.822-.187-50.823-.186-5.191 1.78m98.16 30.234c1.997.759 4.481 5.451 18.094 34.183 4.124 8.705 11.314 15.646 19.796 19.112 4.177 1.707 5.279 1.767 39.453 2.16l35.157.404 3.69 2.169a16.674 16.674 0 015.859 5.859l2.169 3.691v217.968l-2.169 3.691a16.674 16.674 0 01-5.859 5.859l-3.69 2.169H43.359l-3.69-2.169a16.674 16.674 0 01-5.859-5.859l-2.169-3.691V119.141l2.169-3.691a16.674 16.674 0 015.859-5.859l3.69-2.169 35.981-.391 35.981-.39 5.816-2.892c10.442-5.19 12.76-8.213 24.247-31.621 8.218-16.745 10.359-20.512 11.818-20.792 3.889-.746 84.937-.534 86.939.227m-61.145 72.263c-72.94 13.222-105.409 98.323-59.914 157.033 44.811 57.828 137.632 46.075 166.597-21.093 30.915-71.693-30.362-149.775-106.683-135.94m123.254 30.08v15.625h31.25v-31.25h-31.25v15.625m-87.917 3.112c45.324 14.517 62.186 67.974 32.914 104.347-26.42 32.83-76.074 32.83-102.494 0-25.901-32.184-15.246-81.774 21.41-99.648 15.061-7.344 34.112-9.202 48.17-4.699"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
