import React, { FC } from "react";
import { Section } from "./styles";
import ForgotPasswordForm from "../ForgotPasswordForm";
import { useLocation } from "react-router-dom";

const ForgotPasswordScreen: FC<any> = () => {
  const location = useLocation();

  return (
    <Section>
      <ForgotPasswordForm location={location} />
    </Section>
  );
};

export default ForgotPasswordScreen;
