import axios, { CancelTokenSource } from "axios";
import QrScanner from "qr-scanner";
import queryString from "query-string";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { postActivateSensor } from "../../services/activateSensors";
import { fetchEditAccount } from "../../services/editAccount";
import { getAccount, saveAccount } from "../../services/localStorage";
import { isAdmin } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import { exists } from "../../util/formValidations";
import getParameterByName from "../../util/getParamByName";
import isMobileDevice from "../../util/isMobileDevice";
import isTouch from "../../util/isTouch";
import { DangerAlert, SuccessAlert } from "../Alerts";
import { OutlineBtn, PrimaryBtn } from "../Buttons";
import ConfirmJoinDemoModal from "../ConfirmJoinDemoModal";
import { FormError, FormInput, FormInputContainer } from "../FormComponents";
import { HorizontalLineWithText } from "../HorizontalLine";
import LoadingContainer from "../LoadingContainer";
import ScreenHeading from "../ScreenHeading";
import StateContext from "../StateContext";
import { QrScanButton } from "./styles";

const ActivateTrackerScreen: FC<any> = () => {
  const { forceUpdate } = useContext(StateContext);
  const location = useLocation();
  const navigate = useNavigate();

  const [accountInfo] = useState<any>(getAccount());

  const sensorId = getParameterByName("sensorId", location.search) || "";
  const activated = getParameterByName("activated", location.search) || "";

  const videoRefCallback = useCallback((node: any) => {
    if (node) {
      setVideoRef(node);
    }
  }, []);

  const [videoRef, setVideoRef] = useState<any>(undefined);

  const [joinDemoModalOpen, setJoinDemoModalOpen] = useState<boolean>(false);

  const [formData, setFormData] = useState<any>({
    sensorId,
    phoneLatitude: undefined,
    phoneLongitude: undefined,
    phoneAccuracy: undefined,
  });

  const [formErrors, setFormErrors] = useState<any>({});

  const [codeReader, setCodeReader] = useState<any>(undefined);
  const [scanning, setScanning] = useState<boolean>(false);
  const [scan, setScan] = useState<any>(undefined);

  const [submittedMsg, setSubmittedMsg] = useState<string>(activated ? "Tracker Activated" : "");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  // Only show join demo button if not activating a sensor
  const [showJoinDemo] = useState<any>(() => {
    const params = queryString.parse(location.search);
    if (params && params.sensorId && params.activateSensor === "true") return false;
    else return true;
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  const showGetStarted = accountInfo.sensors > 0 || accountInfo.stickers > 0;

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // If user is on a mobile device, get their location to update the tracker's location
  useEffect(() => {
    if (isMobileDevice()) {
      navigator.geolocation.getCurrentPosition((pos: any) => {
        const { coords } = pos;

        setFormData((prev: any) => ({
          ...prev,
          phoneLatitude: coords.latitude,
          phoneLongitude: coords.longitude,
          phoneAccuracy: coords.accuracy,
        }));
      });
    }
  }, []);

  // Initialise codeReader and stop/destroy codeReader and stop camera access on clean up
  useEffect(() => {
    let codeReader: any = undefined;
    if (videoRef) {
      codeReader = new QrScanner(videoRef, (result) => {
        if (result) {
          let code = result;
          if (code.includes("10b.in/")) {
            code = code.split("10b.in/")[1];
          }
          setScan(code);
        }
      });
      codeReader.setInversionMode("both");
      setCodeReader(codeReader);
    }

    return () => {
      if (codeReader) {
        codeReader.stop();
        codeReader.destroy();
      }
      if (videoRef && videoRef.srcObject) {
        videoRef.srcObject.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
    };
  }, [videoRef]);

  // Stop code reader when tracker info is loading or the tracker info modal is open
  useEffect(() => {
    if (codeReader) {
      if (scanning) {
        codeReader.start();
      } else {
        codeReader.stop();
        if (videoRef && videoRef.srcObject) {
          videoRef.srcObject.getTracks().forEach((track: any) => {
            track.stop();
          });
        }
      }
    }
  }, [codeReader, scanning, videoRef]);

  // On QR scan, add string to sensor ID input
  useEffect(() => {
    if (scan) {
      setFormData((prev: any) => ({
        ...prev,
        sensorId: scan,
      }));
      setScan(undefined);
      setScanning(false);
    }
  }, [scan]);

  const validateActivateSensorForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "sensorId":
          currValid = exists(name, value, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatActivateSensorForm = () => {
    return {
      sensorId: formData.sensorId,
      phoneLatitude: formData.phoneLatitude,
      phoneLongitude: formData.phoneLongitude,
      phoneAccuracy: Math.round(formData.phoneAccuracy) || null,
    };
  };

  const handleActivateSensorSubmit = () => {
    setSubmittedMsg("");
    setSubmittingErr("");

    const formattedData = formatActivateSensorForm();
    const valid = validateActivateSensorForm();

    if (valid) {
      setSubmitting(true);
      postActivateSensor(source, formattedData)
        .then((response) => {
          setTimeout(() => {
            updateAccountInfo();
            setSubmittedMsg("Tracker Activated");
            setSubmitting(false);
          }, 1000);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const updateAccountInfo = () => {
    fetchEditAccount(source).then((response) => {
      saveAccount(response);
      // When user has no active trackers the home page shows the activate tracker form.
      // If the user activates a tracker here take them to the activate-tracker page as
      // the home page will change to the dashboard screen.
      if (location.pathname === "/") {
        navigate(`/activate-tracker?activated=1&sensorId=${formData.sensorId}`);
      }
      const accountInfoUpdatedEvent = new Event("accountinfoupdated");
      document.dispatchEvent(accountInfoUpdatedEvent);
      forceUpdate();
    });
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setFormErrors((prev: any) => ({
      ...prev,
      [e.target.name]: undefined,
    }));
  };

  const handleEnableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setSubmittedMsg("");
    setSubmittingErr("");
    setScanning(true);
  };

  const handleDisableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setScan(undefined);
    setScanning(false);
  };

  return (
    <>
      <div style={{ padding: "24px", textAlign: "center" }}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            maxWidth: "544px",
            margin: "auto",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {isAdmin() ? (
            <LoadingContainer loading={submitting}>
              {scanning ? (
                <>
                  <ScreenHeading>Scan QR Code</ScreenHeading>
                  <div
                    style={{
                      margin: "10px 0 4px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <video
                      id="video"
                      ref={videoRefCallback}
                      style={{
                        display: "inline-block",
                        border: "1px solid gray",
                        borderRadius: "3px",
                        maxHeight: "50vh",
                        maxWidth: "100%",
                      }}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <OutlineBtn width="100%" onClick={handleDisableScan}>
                      Back
                    </OutlineBtn>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <ScreenHeading>Activate Tracker</ScreenHeading>
                  <form noValidate onSubmit={(e) => e.preventDefault()}>
                    <FormInputContainer>
                      <label>Tracker ID</label>
                      <QrScanButton title={`Scan Tracker`} onClick={handleEnableScan} />
                      <FormInput
                        type="text"
                        name="sensorId"
                        value={formData.sensorId}
                        error={formErrors.sensorId}
                        onChange={handleChange}
                        autoFocus={!isTouch()}
                      />
                      <FormError error={formErrors.sensorId}>{formErrors.sensorId}</FormError>
                    </FormInputContainer>
                  </form>
                  {showGetStarted ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        width: "100%",
                        margin: "14px 0",
                      }}
                    >
                      <PrimaryBtn width="45%" onClick={() => handleActivateSensorSubmit()}>
                        Activate
                      </PrimaryBtn>
                      <PrimaryBtn width="45%" onClick={() => navigate("/map")} autoFocus={true}>
                        Get Started
                      </PrimaryBtn>
                    </div>
                  ) : (
                    <div
                      style={{
                        float: "left",
                        width: "100%",
                        margin: "14px 0",
                      }}
                    >
                      <PrimaryBtn width="100%" onClick={() => handleActivateSensorSubmit()}>
                        Activate
                      </PrimaryBtn>
                    </div>
                  )}
                  {submittingErr && (
                    <DangerAlert
                      style={{
                        marginBottom: "-2px",
                        float: "left",
                        width: "100%",
                      }}
                    >
                      {submittingErr}
                    </DangerAlert>
                  )}
                  {submittedMsg && (
                    <SuccessAlert
                      style={{
                        marginBottom: "-2px",
                        float: "left",
                        width: "100%",
                      }}
                    >
                      {submittedMsg}
                    </SuccessAlert>
                  )}
                </div>
              )}
            </LoadingContainer>
          ) : (
            <>
              <span style={{ fontSize: "1.2em" }}>Please contact your organisation administrator to activate trackers.</span>
            </>
          )}
          {!accountInfo.joinedDemo && showJoinDemo && (
            <>
              <HorizontalLineWithText style={{ margin: "18px 0 32px 0" }}>Or</HorizontalLineWithText>
              <PrimaryBtn onClick={() => setJoinDemoModalOpen(true)}>Join the demo organisation</PrimaryBtn>
            </>
          )}
        </div>
      </div>
      {joinDemoModalOpen && <ConfirmJoinDemoModal modalOpen={joinDemoModalOpen} setModalOpen={setJoinDemoModalOpen} />}
    </>
  );
};

export default ActivateTrackerScreen;
