import React, { FC, useState, useEffect } from "react";
import { ModalFilterContainer, ModalFilterHeader } from "./styles";
import { Select } from "../Select";
import { fetchAutoComplete } from "../../services/autoComplete";

const kegStatusOptions = [
  { value: "empty", label: "Empty" },
  { value: "onTap", label: "On Tap" },
  { value: "inFridge", label: "In Fridge" },
];

// Sets the initial values of the selected tracker types if there are URL query parameters.
// If there are none we use the default initial values []
const getValues = (sensorFilters: any) => {
  const { kegStatus } = sensorFilters;

  const kegStatusArray = kegStatus ? kegStatus.split(",") : [];

  if (kegStatusArray.length > 0) {
    return kegStatusArray.map((label: string) => ({ value: label, label }));
  }

  return [];
};

const KegStatus: FC<any> = ({ sensorFilters, setModalQuery }) => {
  const [values, setValues] = useState<any>(getValues(sensorFilters));
  const [formattedValues, setFormattedValues] = useState<any>([]);

  // Since we are filtering tracker types by id we must fetch the names and match them
  // in order to list the selected tracker types in the select input by name
  // We do this whenever values change
  useEffect(() => {
    setFormattedValues(() =>
      values.map((val: any) => {
        // Must use == to check for equality since the previous values are strings retrieved from the URL query parameters
        const trackerType = kegStatusOptions.find((status: any) => status.value == val.value);
        if (trackerType) {
          return {
            label: trackerType.label,
            value: trackerType.value,
          };
        }
      })
    );
  }, [values]);

  // This is to update the values and button state when a user changes the query
  // parameters (e.g. when a user navigates using the back/forward buttons).
  // Each time the sensorFilters change (whenever the sensor query parameters change),
  // if the new values don't match the current values, update the values and button state
  useEffect(() => {
    const newValues = getValues(sensorFilters);
    if (JSON.stringify(values) !== JSON.stringify(newValues)) {
      setValues(newValues);
    }
  }, [sensorFilters]);

  // On initial render, sets the modal query string state in
  // the parent component to the initial values
  useEffect(() => {
    updateModalQuery(values);
  }, []);

  const updateModalQuery = (values: any) => {
    if (values.length > 0) {
      const kegStatus = values.map((label: any) => label.value, []);
      setModalQuery((prev: any) => ({
        ...prev,
        kegStatus: kegStatus.join(","),
      }));
    } else {
      setModalQuery((prev: any) => ({
        ...prev,
        kegStatus: undefined,
      }));
    }
  };

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  return (
    <ModalFilterContainer>
      <ModalFilterHeader>Keg Status</ModalFilterHeader>
      <Select
        name="kegStatus"
        closeMenuOnSelect={false}
        isClearable={true}
        isMulti={true}
        isSearchable={true}
        value={formattedValues}
        options={kegStatusOptions}
        onChange={(selected: any) => {
          if (selected) {
            setValues(selected);
            updateModalQuery(selected);
          } else {
            setValues([]);
            updateModalQuery([]);
          }
        }}
        placeholder="Select..."
      />
    </ModalFilterContainer>
  );
};

export default KegStatus;
