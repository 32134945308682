import React, { FC, useState, useEffect } from "react";
import RangeSlider from "../RangeSlider";
import { ModalFilterContainer, ModalFilterHeader } from "./styles";
import { kegsOrTrackers } from "../../util/kegOrTracker";

// Sets the initial values of the range slider if there are URL query parameters.
// If there are none we use the default initial values of 1 - Infinity
const getValues = (placeFilters: any) => {
  const { minSensors, maxSensors } = placeFilters;
  const values = [1, Infinity];

  if (minSensors) {
    values[0] = +minSensors;
  }
  if (maxSensors) {
    values[1] = +maxSensors;
  }

  return values;
};

const SensorsAtPlace: FC<any> = ({ placeRanges, placeFilters, setModalQuery }) => {
  const [minMaxRange, setMinMaxRange] = useState<any>([0, 9999]);
  const [values, setValues] = useState<any>(getValues(placeFilters));
  const [initialValuesSet, toggleInitialValuesSet] = useState<any>([values[0] !== 1, values[1] !== Infinity]);

  // When the placeRanges change update the minMaxRange state and, if the values
  // have not been set yet, set minVal to 1 or 0 and maxVal to maxRange
  useEffect(() => {
    if (placeRanges.sensors && placeRanges.sensors.length === 2) {
      const min = Math.floor(placeRanges.sensors[0]);
      const max = Math.ceil(placeRanges.sensors[1]);
      setMinMaxRange([min, max]);

      if (!initialValuesSet[0]) {
        setValues((prev: any) => [max == 0 ? 0 : 1, prev[1]]);
        toggleInitialValuesSet((prev: any) => [true, prev[1]]);
      }

      if (!initialValuesSet[1]) {
        setValues((prev: any) => [prev[0], max]);
        toggleInitialValuesSet((prev: any) => [prev[0], true]);
      }
    }
  }, [placeRanges]);

  // This is to update the values and button state when a user changes the query
  // parameters (e.g. when a user navigates using the back/forward buttons).
  // Each time the placeFilters change (whenever the place query parameters change),
  // if the new values don't match the current values, update the values and button state
  useEffect(() => {
    const newValues = getValues(placeFilters);
    if (JSON.stringify(values) !== JSON.stringify(newValues)) {
      setValues(newValues);
    }
  }, [placeFilters]);

  // On initial render, sets the modal query string state in
  // the parent component to the initial values
  useEffect(() => {
    updateModalQuery(values);
  }, []);

  // Updated the parent's modal query state after the slider value has been changed
  const updateModalQuery = (values: any) => {
    const minVal = values[0];
    const maxVal = values[1] === minMaxRange[1] ? Infinity : values[1];

    if (minVal !== 1 && maxVal !== Infinity) {
      setModalQuery((prev: any) => ({
        ...prev,
        minSensors: minVal,
        maxSensors: maxVal,
      }));
    } else if (minVal !== 1) {
      setModalQuery((prev: any) => ({
        ...prev,
        minSensors: minVal,
        maxSensors: undefined,
      }));
    } else if (maxVal !== Infinity) {
      setModalQuery((prev: any) => ({
        ...prev,
        minSensors: undefined,
        maxSensors: maxVal,
      }));
    } else {
      setModalQuery((prev: any) => ({
        ...prev,
        minSensors: undefined,
        maxSensors: undefined,
      }));
    }
  };

  return (
    <ModalFilterContainer>
      <ModalFilterHeader>Number of {kegsOrTrackers("Kegs", "Trackers")} at a Place</ModalFilterHeader>
      <RangeSlider
        value={values}
        minRange={minMaxRange[0]}
        maxRange={minMaxRange[1]}
        exceedsMax={true}
        setMinVal={(val: any) => {
          setValues((prev: any) => [val, prev[1]]);
          updateModalQuery([val, values[1]]);
        }}
        setMaxVal={(val: any) => {
          setValues((prev: any) => [prev[0], val]);
          updateModalQuery([values[0], val]);
        }}
        onChange={(props: any) => {
          setValues(props);
        }}
        onAfterChange={(props: any) => {
          updateModalQuery(props);
        }}
      />
    </ModalFilterContainer>
  );
};

export default SensorsAtPlace;
