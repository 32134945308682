import axios, { CancelTokenSource } from "axios";
import { FC, useEffect, useState } from "react";
import { postActivateSensor } from "../../services/activateSensors";
import { fetchAutoComplete } from "../../services/autoComplete";
import errToStr from "../../util/errToStr";
import { exists } from "../../util/formValidations";
import isMobileDevice from "../../util/isMobileDevice";
import { kegOrTracker } from "../../util/kegOrTracker";
import { FormError } from "../FormComponents";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { AsyncSelect } from "../Select";

const ActivateSelectedSensorModal: FC<any> = ({ id, onSuccess, modalOpen, setModalOpen }) => {
  const [formData, setFormData] = useState<any>({
    organisation: undefined,
    phoneLatitude: undefined,
    phoneLongitude: undefined,
    phoneAccuracy: undefined,
  });
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // If user is on a mobile device, get their location to update the tracker's location
  useEffect(() => {
    if (isMobileDevice()) {
      navigator.geolocation.getCurrentPosition((pos: any) => {
        const { coords } = pos;

        setFormData((prev: any) => ({
          ...prev,
          phoneLatitude: coords.latitude,
          phoneLongitude: coords.longitude,
          phoneAccuracy: coords.accuracy,
        }));
      });
    }
  }, []);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "organisation":
          currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => ({
    sensorId: id,
    organisationId: formData.organisation ? formData.organisation.value : null,
    phoneLatitude: formData.phoneLatitude,
    phoneLongitude: formData.phoneLongitude,
    phoneAccuracy: Math.round(formData.phoneAccuracy) || null,
  });

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      postActivateSensor(source, body)
        .then(() => {
          onSuccess();
          setSubmittedMsg(kegOrTracker("Keg Activated", "Tracker Activated"));
          setSubmittingErr("");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  // Auto-populates select input on search.
  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) {
          setSubmittingErr("");
          setModalOpen(false);
        }
      }}
      title="Activate Selected Tracker"
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Activate Tracker"
      body={
        <LoadingContainer loading={submitting}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer>
              <label>Organisation</label>
              <AsyncSelect
                name="organisation"
                defaultOptions={true}
                isClearable={true}
                isError={formErrors.organisation}
                value={formData.organisation}
                loadOptions={(inputValue: any, callback: any) => loadOptions("organisations", inputValue, callback)}
                onChange={handleSelectChange}
                placeholder="Select..."
              />
              <FormError error={formErrors.organisation}>{formErrors.organisation}</FormError>
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default ActivateSelectedSensorModal;
