import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 357.99 464.41" {...props}>
      <defs>
        <style>{`.prefix__cls-1{fill:#aaa;stroke:#000;stroke-miterlimit:10;stroke-width:10px}`}</style>
      </defs>
      <rect className="prefix__cls-1" x={5} y={106.41} width={240} height={60.89} rx={12} />
      <path className="prefix__cls-1" d="M17 167.3h216a12 12 0 0112 12v113.9H5V179.3a12 12 0 0112-12z" />
      <path className="prefix__cls-1" d="M5 293.2h240v113.9a12 12 0 01-12 12H17a12 12 0 01-12-12V293.2z" />
      <rect className="prefix__cls-1" x={5} y={419.09} width={240} height={40.32} rx={12} />
      <path
        className="prefix__cls-1"
        d="M410 97.06c32-.89 46.27 29.36 47.16 48.94M410 58.06c57.54-1.6 83.11 52.75 84.71 87.91M410 22.14c80.15-2.23 115.77 73.47 118 122.45"
        transform="translate(-175 -17.09)"
      />
      <rect className="prefix__cls-1" x={88} y={122.91} width={74} height={27} rx={12} />
    </svg>
  );
}

export default SvgComponent;
