import React, { FC, useContext, useEffect, useState } from "react";
import LoadingContainer from "../LoadingContainer";
import { humaniseHours } from "../../util/humaniseDurations";
import axios from "axios";
import errToStr from "../../util/errToStr";
import { getAverageTimeToTap } from "../../services/averageTimeToTap";
import { IconContext } from "react-icons";
import { HiClock } from "react-icons/hi";
import { ThemeContext } from "styled-components";
import moment from "moment";
import InfoIcon from "../../svgs/Legend";
import InfoTooltip from "../Tooltip";
import { Container, InfoIconContainer, IconContainer, ValueContainer, Icon, Header, Value, DateText } from "./styles";

const AverageTimeToTap: FC<any> = ({ filterDates }) => {
  const { color, color_15, short_date, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>(undefined);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    // If end date is undefined set to current date
    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined ? filterDates.end.unix() : moment().unix(),
    };

    getAverageTimeToTap(source, dates)
      .then((response: any) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData([]);
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [filterDates]);

  return (
    <LoadingContainer loading={dataLoading} err={dataErr}>
      {!dataLoading && (
        <Container>
          <IconContainer>
            <Icon>
              <svg viewBox="0 0 140 140" preserveAspectRatio="xMinYMin meet" style={{ maxHeight: "100%" }}>
                <g>
                  <circle r="50%" cx="50%" cy="50%" style={{ fill: color_15.primary[2] }}></circle>
                  <g style={{ transformOrigin: "center", transform: "scale(0.66)" }}>
                    <IconContext.Provider value={{ color: color.primary[2], size: "100%" }}>
                      <HiClock />
                    </IconContext.Provider>
                  </g>
                </g>
              </svg>
            </Icon>
          </IconContainer>
          <ValueContainer>
            <Header title="Average Time to Tap">Average Time to Tap</Header>
            {data !== null ? (
              <Value title={humaniseHours(data, ["d"])}>{humaniseHours(data, ["d"])}</Value>
            ) : (
              <Value title="No kegs tapped">No kegs tapped</Value>
            )}
            {data !== undefined && (
              <DateText title={`${moment(filterDates.start).format(long_datetime)} - ${moment(filterDates.end).format(long_datetime)}`}>
                {filterDates.isNow
                  ? filterDates.dateLabel
                  : `From ${moment(filterDates.start).format(short_date)} to ${moment(filterDates.end).format(short_date)}`}
              </DateText>
            )}
          </ValueContainer>
          <InfoTooltip content={`For the selected date range, the average time taken for kegs to reach a known venue and go on tap`} touch={true}>
            <InfoIconContainer>
              <InfoIcon fill={color.font[2]} />
            </InfoIconContainer>
          </InfoTooltip>
        </Container>
      )}
    </LoadingContainer>
  );
};

export default AverageTimeToTap;
