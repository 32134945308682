import React, { FC } from "react";
import PlacesTable from "../PlacesTable";
import { PageContainer } from "../PageStyles";
import { Panel } from "../Panel";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import PlaceTypesTable from "../PlaceTypesTable";
import PlaceTagsTable from "../PlaceTagsTable";
import SuggestedPlacesTable from "../SuggestedPlacesTable";
import PlaceAllocationsTable from "../PlaceAllocationsTable";

const PlacesScreen: FC<any> = () => {
  return (
    <>
      <Tabs>
        <TabList className="react-tabs__tab-list__background_no-margin">
          <Tab>Places</Tab>
          <Tab>Place Types</Tab>
          <Tab>Place Tags</Tab>
          <Tab>Place Allocations</Tab>
          <Tab>Suggested Places</Tab>
        </TabList>
        <TabPanel>
          <PageContainer>
            <Panel>
              <PlacesTable />
            </Panel>
          </PageContainer>
        </TabPanel>
        <TabPanel>
          <PageContainer>
            <Panel>
              <PlaceTypesTable />
            </Panel>
          </PageContainer>
        </TabPanel>
        <TabPanel>
          <PageContainer>
            <Panel>
              <PlaceTagsTable />
            </Panel>
          </PageContainer>
        </TabPanel>
        <TabPanel>
          <PageContainer>
            <Panel>
              <PlaceAllocationsTable />
            </Panel>
          </PageContainer>
        </TabPanel>
        <TabPanel>
          <PageContainer>
            <Panel>
              <SuggestedPlacesTable />
            </Panel>
          </PageContainer>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default PlacesScreen;
