import axios, { CancelTokenSource } from "axios";
import { FC, useCallback, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Redirect } from "react-router6-redirect";
import { toast } from "react-toastify";
import AccountScreen from "./components/AccountScreen";
import ActivateTrackerScreen from "./components/ActivateTrackerScreen";
import AdminReportsScreen from "./components/AdminReportsScreen";
import AlertScreen from "./components/AlertScreen";
import AlertsScreen from "./components/AlertsScreen";
import BeersScreen from "./components/BeersScreen";
import Dashboard from "./components/Dashboard";
import ForgotPasswordScreen from "./components/ForgotPasswordScreen";
import MainMapScreen from "./components/MainMapScreen";
import MaintenanceScreen from "./components/MaintenanceScreen";
import ManifestScreen from "./components/ManifestScreen";
import ManifestsScreen from "./components/ManifestsScreen";
import NotFoundScreen from "./components/NotFoundScreen";
import OrganisationScreen from "./components/OrganisationScreen";
import OrganisationsScreen from "./components/OrganisationsScreen";
import PhoneScanScreen from "./components/PhoneScanScreen";
import PickupRequestsScreen from "./components/PickupRequestsScreen";
import PlaceScreen from "./components/PlaceScreen";
import PlacesScreen from "./components/PlacesScreen";
import PublicBeerMapScreen from "./components/PublicBeerMapScreen";
import RegisterScreen from "./components/RegisterScreen";
import ConsumptionAudit from "./components/Reports/ConsumptionAudit";
import FreshnessReport from "./components/Reports/FreshnessReport";
import InTransitDuration from "./components/Reports/InTransitDuration";
import KegFleetReport from "./components/Reports/KegFleetReport";
import KegsReadyForCollection from "./components/Reports/KegsReadyForCollection";
import LastSeenOver7DaysAgo from "./components/Reports/LastSeenOver7DaysAgo";
import LeftBreweryWithoutFill from "./components/Reports/LeftBreweryWithoutFill";
import LocationAudit from "./components/Reports/LocationAudit";
import LongGapsInUpdates from "./components/Reports/LongGapsInUpdates";
import LowBatteries from "./components/Reports/LowBatteries";
import MarkedForPickup from "./components/Reports/MarkedForPickup";
import PhoneScans from "./components/Reports/PhoneScans";
import PlacesDuration from "./components/Reports/PlacesDuration";
import RecentKegTurns from "./components/Reports/RecentKegTurns";
import RefrigerationPerformance from "./components/Reports/RefrigerationPerformance";
import RouteAudit from "./components/Reports/RouteAudit";
import StagnantKegs from "./components/Reports/StagnantKegs";
import StationaryNotAtPlace from "./components/Reports/StationaryNotAtPlace";
import TemperatureEvents from "./components/Reports/TemperatureEvents";
import TemperatureRange from "./components/Reports/TemperatureRange";
import TrackerCalibration from "./components/Reports/TrackerCalibration";
import TrackerEvents from "./components/Reports/TrackerEvents";
import WaywardKegs from "./components/Reports/WaywardKegs";
import ReportsScreen from "./components/ReportsScreen";
import ResetPasswordScreen from "./components/ResetPasswordScreen";
import { Container } from "./components/RootContainer";
import Activate from "./components/ScannerActions/Activate";
import AssignAssetId from "./components/ScannerActions/AssignAssetId";
import CreatePickupRequest from "./components/ScannerActions/CreatePickupRequest";
import DeletePickupRequest from "./components/ScannerActions/DeletePickupRequest";
import MarkAsEmptied from "./components/ScannerActions/MarkAsEmptied";
import MarkAsFilled from "./components/ScannerActions/MarkAsFilled";
import UpdateLocation from "./components/ScannerActions/UpdateLocation";
import ScannerScreen from "./components/ScannerScreen";
import SelectOrganisationScreen from "./components/SelectOrganisationScreen";
import SignInScreen from "./components/SignInScreen";
import TermsAndConditionsScreen from "./components/TermsAndConditionsScreen";
import TrackerScreen from "./components/TrackerScreen";
import TrackersScreen from "./components/TrackersScreen";
import UnsubscribeScreen from "./components/UnsubscribeScreen";
import UsersScreen from "./components/UsersScreen";
import VerifyEmailScreen from "./components/VerifyEmailScreen";
import VersionScreen from "./components/VersionScreen";
import BasicLayout from "./layouts/BasicLayout";
import NoSidebarLayout from "./layouts/NoSidebarLayout";
import { fetchEditAccount } from "./services/editAccount";
import { isOnline } from "./services/isOnline";
import { getAccount, getToken, getVersion, removeAccount, removeToken, saveAccount } from "./services/localStorage";
import { isAdmin, isSuper } from "./util/checkRole";
import getParameterByName from "./util/getParamByName";
import getPathParams from "./util/getPathParams";
import useInterval from "./util/useInterval";
import TrackerServicesReport from "./components/Reports/TrackerServicesReport";

const ACCOUNT_REFRESH_INTERVAL = 30000;

// Sends page visits to Google Analytics on location change.
const usePageViews = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);
};

const hasAnyTrackers = (accountInfo: any) => {
  return accountInfo.sensors > 0 || accountInfo.stickers > 0;
};

/*************************************************************/
/************************ User Route *************************/
/*************************************************************/

const UserRoute = ({ children }: { children: JSX.Element }) => {
  const token = getToken();
  const accountInfo = getAccount();
  const version = getVersion();
  const location = useLocation();
  const tokenParam = getParameterByName("Token", location.search) != null ? getParameterByName("Token", location.search) : null;

  // If the request includes a token parameter, render the route and use the token in any subsequent back-end requests
  // Allows the back-end to generate a pdf of the front-end for scheduled reports
  if (tokenParam) {
    return (
      <Container>
        <BasicLayout>{children}</BasicLayout>
      </Container>
    );
  }

  if (!token || !accountInfo.id) {
    return <Navigate to={`/sign-in${getPathParams(location)}`} replace />;
  }

  if (version !== process.env.REACT_APP_VERSION) {
    removeToken();
    removeAccount();
    return <Navigate to={`/sign-in${getPathParams(location)}`} replace />;
  }

  // If the back-end is in maintenance mode redirect to the maintenance screen
  if (accountInfo.maintenance) {
    return <Navigate to={`/maintenance${getPathParams(location)}`} replace />;
  }

  // If the user hasn't accepted the latest terms and conditions they will
  // be redirected to the terms and conditions page.
  if (!accountInfo.legalAccepted) {
    return <Navigate to={`/terms-and-conditions${getPathParams(location)}`} replace />;
  }

  // If the user hasn't selected an organisation
  if (!accountInfo.organisationId) {
    return <Navigate to={`/select-organisation${getPathParams(location)}`} replace />;
  }

  return (
    <Container>
      <BasicLayout>{children}</BasicLayout>
    </Container>
  );
};

/*************************************************************/
/************************ Admin Route ************************/
/*************************************************************/

const AdminRoute = ({ children }: { children: JSX.Element }) => {
  const token = getToken();
  const accountInfo = getAccount();
  const version = getVersion();
  const location = useLocation();
  const tokenParam = getParameterByName("Token", location.search) != null ? getParameterByName("Token", location.search) : null;

  // If the request includes a token parameter, render the route and use the token in any subsequent back-end requests
  // Allows the back-end to generate a pdf of the front-end for scheduled reports
  if (tokenParam) {
    return (
      <Container>
        <BasicLayout>{children}</BasicLayout>
      </Container>
    );
  }

  if (!token || !accountInfo.id) {
    return <Navigate to={`/sign-in${getPathParams(location)}`} replace />;
  }

  if (version !== process.env.REACT_APP_VERSION) {
    removeToken();
    removeAccount();
    return <Navigate to={`/sign-in${getPathParams(location)}`} replace />;
  }

  // If the back-end is in maintenance mode redirect to the maintenance screen
  if (accountInfo.maintenance) {
    return <Navigate to={`/maintenance${getPathParams(location)}`} replace />;
  }

  // If the user hasn't accepted the latest terms and conditions they will
  // be redirected to the terms and conditions page.
  if (!accountInfo.legalAccepted) {
    return <Navigate to={`/terms-and-conditions${getPathParams(location)}`} replace />;
  }

  // If the user hasn't selected an organisation
  if (!accountInfo.organisationId) {
    return <Navigate to={`/select-organisation${getPathParams(location)}`} replace />;
  }

  if (isAdmin()) {
    return (
      <Container>
        <BasicLayout>{children}</BasicLayout>
      </Container>
    );
  } else {
    return <Navigate to={`/not-found${getPathParams(location)}`} replace />;
  }
};

/*************************************************************/
/************************ Super Route ************************/
/*************************************************************/

const SuperRoute = ({ children }: { children: JSX.Element }) => {
  const token = getToken();
  const accountInfo = getAccount();
  const version = getVersion();
  const location = useLocation();
  const tokenParam = getParameterByName("Token", location.search) != null ? getParameterByName("Token", location.search) : null;

  // If the request includes a token parameter, render the route and use the token in any subsequent back-end requests
  // Allows the back-end to generate a pdf of the front-end for scheduled reports
  if (tokenParam) {
    return (
      <Container>
        <BasicLayout>{children}</BasicLayout>
      </Container>
    );
  }

  if (!token || !accountInfo.id) {
    return <Navigate to={`/sign-in${getPathParams(location)}`} replace />;
  }

  if (version !== process.env.REACT_APP_VERSION) {
    removeToken();
    removeAccount();
    return <Navigate to={`/sign-in${getPathParams(location)}`} replace />;
  }

  // If the back-end is in maintenance mode redirect to the maintenance screen
  if (accountInfo.maintenance) {
    return <Navigate to={`/maintenance${getPathParams(location)}`} replace />;
  }

  // If the user hasn't accepted the latest terms and conditions they will
  // be redirected to the terms and conditions page.
  if (!accountInfo.legalAccepted) {
    return <Navigate to={`/terms-and-conditions${getPathParams(location)}`} replace />;
  }

  // If the user hasn't selected an organisation
  if (!accountInfo.organisationId) {
    return <Navigate to={`/select-organisation${getPathParams(location)}`} replace />;
  }

  if (isSuper()) {
    return (
      <Container>
        <BasicLayout>{children}</BasicLayout>
      </Container>
    );
  } else {
    return <Navigate to={`/not-found${getPathParams(location)}`} replace />;
  }
};

/*************************************************************/
/******************* Activate Tracker Route *******************/
/*************************************************************/

const ActivateTrackerRoute = ({ children }: { children: JSX.Element }) => {
  const token = getToken();
  const accountInfo = getAccount();
  const version = getVersion();
  const tokenParam = getParameterByName("Token", location.search) != null ? getParameterByName("Token", location.search) : null;

  // If the request includes a token parameter, render the route and use the token in any subsequent back-end requests
  // Allows the back-end to generate a pdf of the front-end for scheduled reports
  if (tokenParam) {
    return (
      <Container>
        <BasicLayout>{children}</BasicLayout>
      </Container>
    );
  }

  if (!token || !accountInfo.id) {
    return <Navigate to={`/sign-in${getPathParams(location)}`} replace />;
  }

  if (version !== process.env.REACT_APP_VERSION) {
    removeToken();
    removeAccount();
    return <Navigate to={`/sign-in${getPathParams(location)}`} replace />;
  }

  // If the back-end is in maintenance mode redirect to the maintenance screen
  if (accountInfo.maintenance) {
    return <Navigate to={`/maintenance${getPathParams(location)}`} replace />;
  }

  // If the user hasn't accepted the latest terms and conditions they will
  // be redirected to the terms and conditions page.
  if (!accountInfo.legalAccepted) {
    return <Navigate to={`/terms-and-conditions${getPathParams(location)}`} replace />;
  }

  // If the user hasn't selected an organisation
  if (!accountInfo.organisationId) {
    return <Navigate to={`/select-organisation${getPathParams(location)}`} replace />;
  }

  return (
    <Container>
      <BasicLayout>{children}</BasicLayout>
    </Container>
  );
};

/************************************************************/
/******************** Verify Email Route ********************/
/************************************************************/

const VerifyEmailRoute = ({ children }: { children: JSX.Element }) => {
  const token = getToken();
  const accountInfo = getAccount();
  const version = getVersion();
  const tokenParam = getParameterByName("Token", location.search) != null ? getParameterByName("Token", location.search) : null;

  // If the request includes a token parameter, render the route and use the token in any subsequent back-end requests
  // Allows the back-end to generate a pdf of the front-end for scheduled reports
  if (tokenParam) {
    return (
      <Container>
        <BasicLayout>{children}</BasicLayout>
      </Container>
    );
  }

  if (!token || !accountInfo.id || !accountInfo.organisationId) {
    return children;
  }

  if (version !== process.env.REACT_APP_VERSION) {
    removeToken();
    removeAccount();
    return <Navigate to={`/sign-in${getPathParams(location)}`} replace />;
  }

  // If the back-end is in maintenance mode redirect to the maintenance screen
  if (accountInfo.maintenance) {
    return <Navigate to={`/maintenance${getPathParams(location)}`} replace />;
  }

  // If the user hasn't accepted the latest terms and conditions they will
  // be redirected to the terms and conditions page.
  if (!accountInfo.legalAccepted) {
    return <Navigate to={`/terms-and-conditions${getPathParams(location)}`} replace />;
  }

  return (
    <Container>
      <BasicLayout>{children}</BasicLayout>
    </Container>
  );
};

/************************************************************/
/***************** Terms & Conditions Route *****************/
/************************************************************/

const TermsAndConditionsRoute = ({ children }: { children: JSX.Element }) => {
  const token = getToken();
  const accountInfo = getAccount();
  const version = getVersion();
  const tokenParam = getParameterByName("Token", location.search) != null ? getParameterByName("Token", location.search) : null;

  // If the request includes a token parameter, render the route and use the token in any subsequent back-end requests
  // Allows the back-end to generate a pdf of the front-end for scheduled reports
  if (tokenParam) {
    return (
      <Container>
        <NoSidebarLayout>{children}</NoSidebarLayout>
      </Container>
    );
  }

  if (!token || !accountInfo.id) {
    return <Navigate to={`/sign-in${getPathParams(location)}`} replace />;
  }

  if (version !== process.env.REACT_APP_VERSION) {
    removeToken();
    removeAccount();
    return <Navigate to={`/sign-in${getPathParams(location)}`} replace />;
  }

  // If the back-end is in maintenance mode redirect to the maintenance screen
  if (accountInfo.maintenance) {
    return <Navigate to={`/maintenance${getPathParams(location)}`} replace />;
  }

  if (!accountInfo.legalAccepted) {
    return (
      <Container>
        <NoSidebarLayout>{children}</NoSidebarLayout>
      </Container>
    );
  }

  return <Navigate to={`/not-found${getPathParams(location)}`} replace />;
};

/*************************************************************/
/***************** Select Organisation Route *****************/
/*************************************************************/

const SelectOrganisationRoute = ({ children }: { children: JSX.Element }) => {
  const token = getToken();
  const accountInfo = getAccount();
  const version = getVersion();
  const tokenParam = getParameterByName("Token", location.search) != null ? getParameterByName("Token", location.search) : null;

  // If the request includes a token parameter, render the route and use the token in any subsequent back-end requests
  // Allows the back-end to generate a pdf of the front-end for scheduled reports
  if (tokenParam) {
    return (
      <Container>
        <BasicLayout>{children}</BasicLayout>
      </Container>
    );
  }

  if (!token || !accountInfo.id) {
    return <Navigate to={`/sign-in${getPathParams(location)}`} replace />;
  }

  if (version !== process.env.REACT_APP_VERSION) {
    removeToken();
    removeAccount();
    return <Navigate to={`/sign-in${getPathParams(location)}`} replace />;
  }

  // If the back-end is in maintenance mode redirect to the maintenance screen
  if (accountInfo.maintenance) {
    return <Navigate to={`/maintenance${getPathParams(location)}`} replace />;
  }

  // If the user hasn't accepted the latest terms and conditions they will
  // be redirected to the terms and conditions page.
  if (!accountInfo.legalAccepted) {
    return <Navigate to={`/terms-and-conditions${getPathParams(location)}`} replace />;
  }

  if (!accountInfo.organisationId) {
    return (
      <Container>
        <NoSidebarLayout>{children}</NoSidebarLayout>
      </Container>
    );
  }

  return (
    <Container>
      <BasicLayout>{children}</BasicLayout>
    </Container>
  );
};

/*************************************************************/
/********************* Maintenance Route *********************/
/*************************************************************/

const MaintenanceRoute = ({ children }: { children: JSX.Element }) => {
  const token = getToken();
  const accountInfo = getAccount();
  const version = getVersion();
  const tokenParam = getParameterByName("Token", location.search) != null ? getParameterByName("Token", location.search) : null;

  // If the request includes a token parameter, render the route and use the token in any subsequent back-end requests
  // Allows the back-end to generate a pdf of the front-end for scheduled reports
  if (tokenParam) {
    return (
      <Container>
        <NoSidebarLayout>{children}</NoSidebarLayout>
      </Container>
    );
  }

  if (!token || !accountInfo.id) {
    return <Navigate to={`/sign-in${getPathParams(location)}`} replace />;
  }

  if (version !== process.env.REACT_APP_VERSION) {
    removeToken();
    removeAccount();
    return <Navigate to={`/sign-in${getPathParams(location)}`} replace />;
  }

  return (
    <Container>
      <NoSidebarLayout>{children}</NoSidebarLayout>
    </Container>
  );
};

/************************************************************/
/************************ Root Route ************************/
/************************************************************/

const getRootRoutes = () => {
  const accountInfo = getAccount();

  if (!hasAnyTrackers(accountInfo))
    return (
      <Route
        key="/"
        path="/"
        element={
          <UserRoute>
            <ActivateTrackerScreen />
          </UserRoute>
        }
      />
    );

  return (
    <Route
      key="/"
      path="/"
      element={
        <UserRoute>
          <Dashboard />
        </UserRoute>
      }
    />
  );
};

/********************************************************************************/
/******************************* Routes Component *******************************/
/********************************************************************************/

const AppRoutes: FC = (props: any) => {
  const navigate = useNavigate();

  const tokenParam = getParameterByName("Token", location.search) != null ? getParameterByName("Token", location.search) : null;
  const orgIdParam = getParameterByName("organisationId", location.search) != null ? getParameterByName("organisationId", location.search) : null;

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  usePageViews();

  // fetch and update the user account info if logged in and the tab is visible
  const updateAccountInfo = useCallback(async () => {
    if (document.visibilityState === "visible" && getToken()) {
      const response = await fetchEditAccount(source);
      saveAccount(response);
      const accountInfoUpdatedEvent = new Event("accountinfoupdated");
      document.dispatchEvent(accountInfoUpdatedEvent);
    }
  }, [source]);

  // setup event listener to fetch and update account info when tab visibility changes to true (page focus)
  useEffect(() => {
    updateAccountInfo();
    document.addEventListener("visibilitychange", updateAccountInfo);

    return () => {
      source.cancel();
      document.removeEventListener("visibilitychange", updateAccountInfo);
    };
  }, [source, updateAccountInfo]);

  // fetch and update the user's account information at a regular interval (30 seconds)
  useInterval(updateAccountInfo, ACCOUNT_REFRESH_INTERVAL);

  // Add the token and organisationId to the headers of all axios requests
  axios.interceptors.request.use(function (config) {
    config.headers["Content-Type"] = "application/json";

    if (tokenParam) {
      config.headers.Authorization = tokenParam;
    } else {
      config.headers.Authorization = getToken();
    }

    if (orgIdParam) {
      config.headers.OrganisationId = orgIdParam;
      config.params = {
        ...config.params,
        organisationId: orgIdParam,
      };
    } else {
      config.headers.OrganisationId = getAccount().organisationId;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        // if a request to the back-end returns 401 Unauthorised logout the user as this only happens with the auth token is invalid
        if (error.response.status === 401) {
          removeToken();
          removeAccount();
          navigate(`/sign-in${getPathParams(props)}`);
          // else if a request to the back-end returns 403 set message to Insufficient Permissions
        } else if (error.response.status === 403) {
          error.response.data = {
            message: "Insufficient Permissions",
          };
          return Promise.reject(error);
        }
        // if no response, error likely a network error
      } else {
        // don't check if online if it's the isOnline request that fails, this will result in an infinite loop
        if (error.config && error.config.params && error.config.params.id !== "checkifonline") {
          isOnline().then((response) => {
            if (!response) {
              toast.error(
                () => (
                  <span style={{ textAlign: "center" }}>
                    It appears you are offline.{" "}
                    <a href="" onClick={() => window.location.reload()}>
                      Refresh
                    </a>
                  </span>
                ),
                {
                  toastId: "offline-toast",
                  autoClose: false,
                }
              );
            }
            // Removed this because when the app is left opened for too long CORS errors can happen for some reason causing this to be triggered.
            // else {
            //   toast.error(
            //     () => (
            //       <span style={{ textAlign: "center" }}>
            //         Something went wrong.{" "}
            //         <a href="" onClick={() => window.location.reload()}>
            //           Refresh
            //         </a>
            //       </span>
            //     ),
            //     {
            //       toastId: "offline-toast",
            //       autoClose: false,
            //     }
            //   );
            // }
          });
        }
      }

      return Promise.reject(error);
    }
  );

  return (
    <Routes>
      <Route path="/sign-in" element={<SignInScreen />} />
      <Route
        path="/register"
        element={
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY || ""}>
            <RegisterScreen />
          </GoogleReCaptchaProvider>
        }
      />
      <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
      <Route path="/reset-password" element={<ResetPasswordScreen />} />
      <Route path="/phonescan/:scanCode" element={<PhoneScanScreen />} />
      <Route path="/alert/:id" element={<AlertScreen />} />
      <Route path="/unsubscribe/:id" element={<UnsubscribeScreen />} />
      <Route path="/beer-map/:organisationId" element={<PublicBeerMapScreen />} />
      {getRootRoutes()}
      <Route
        key="/map"
        path="/map"
        element={
          <UserRoute>
            <MainMapScreen />
          </UserRoute>
        }
      />
      <Route
        path="/trackers"
        element={
          <UserRoute>
            <TrackersScreen />
          </UserRoute>
        }
      />
      <Route
        path="/trackers/:trackerId"
        element={
          <UserRoute>
            <TrackerScreen />
          </UserRoute>
        }
      />
      <Route
        path="/kegs"
        element={
          <UserRoute>
            <TrackersScreen />
          </UserRoute>
        }
      />
      <Route
        path="/kegs/:trackerId"
        element={
          <UserRoute>
            <TrackerScreen />
          </UserRoute>
        }
      />
      <Route
        path="/manifests"
        element={
          <UserRoute>
            <ManifestsScreen />
          </UserRoute>
        }
      />
      <Route
        path="/manifests/:manifestId"
        element={
          <UserRoute>
            <ManifestScreen />
          </UserRoute>
        }
      />
      <Route
        path="/places"
        element={
          <UserRoute>
            <PlacesScreen />
          </UserRoute>
        }
      />
      <Route
        path="/places/:id"
        element={
          <UserRoute>
            <PlaceScreen />
          </UserRoute>
        }
      />
      <Route
        path="/pickup-requests"
        element={
          <UserRoute>
            <PickupRequestsScreen />
          </UserRoute>
        }
      />
      <Route
        path="/alerts"
        element={
          <UserRoute>
            <AlertsScreen />
          </UserRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <UserRoute>
            <AccountScreen />
          </UserRoute>
        }
      />
      <Route
        path="/organisation"
        element={
          <UserRoute>
            <OrganisationScreen />
          </UserRoute>
        }
      />
      <Route
        path="/organisations"
        element={
          <UserRoute>
            <OrganisationsScreen />
          </UserRoute>
        }
      />
      <Route
        path="/users"
        element={
          <UserRoute>
            <UsersScreen />
          </UserRoute>
        }
      />
      <Route
        path="/beers"
        element={
          <UserRoute>
            <BeersScreen />
          </UserRoute>
        }
      />
      <Route
        path="/version"
        element={
          <SuperRoute>
            <VersionScreen />
          </SuperRoute>
        }
      />
      <Route
        path="/activate-tracker"
        element={
          <ActivateTrackerRoute>
            <ActivateTrackerScreen />
          </ActivateTrackerRoute>
        }
      />
      <Route
        path="/terms-and-conditions"
        element={
          <TermsAndConditionsRoute>
            <TermsAndConditionsScreen />
          </TermsAndConditionsRoute>
        }
      />
      <Route
        path="/verify-email"
        element={
          <VerifyEmailRoute>
            <VerifyEmailScreen />
          </VerifyEmailRoute>
        }
      />
      <Route
        path="/select-organisation"
        element={
          <SelectOrganisationRoute>
            <SelectOrganisationScreen />
          </SelectOrganisationRoute>
        }
      />
      <Route
        path="/maintenance"
        element={
          <MaintenanceRoute>
            <MaintenanceScreen />
          </MaintenanceRoute>
        }
      />
      {/* ################################################################## */}
      {/* ############################ SCANNERS ############################ */}
      {/* ################################################################## */}
      <Route
        path="/scanner"
        element={
          <UserRoute>
            <ScannerScreen />
          </UserRoute>
        }
      />
      <Route
        path="/scanner/create-pickup-request"
        element={
          <UserRoute>
            <CreatePickupRequest />
          </UserRoute>
        }
      />
      <Route
        path="/scanner/delete-pickup-request"
        element={
          <UserRoute>
            <DeletePickupRequest />
          </UserRoute>
        }
      />
      <Route
        path="/scanner/fill-keg"
        element={
          <UserRoute>
            <MarkAsFilled />
          </UserRoute>
        }
      />
      <Route
        path="/scanner/mark-as-filled"
        element={
          <UserRoute>
            <MarkAsFilled />
          </UserRoute>
        }
      />
      <Route
        path="/scanner/mark-as-emptied"
        element={
          <UserRoute>
            <MarkAsEmptied />
          </UserRoute>
        }
      />
      <Route
        path="/scanner/update-location"
        element={
          <UserRoute>
            <UpdateLocation />
          </UserRoute>
        }
      />
      <Route
        path="/scanner/activate"
        element={
          <UserRoute>
            <Activate />
          </UserRoute>
        }
      />
      <Route
        path="/scanner/assign-asset-id"
        element={
          <UserRoute>
            <AssignAssetId />
          </UserRoute>
        }
      />
      {/* ################################################################### */}
      {/* ########################## ADMIN REPORTS ########################## */}
      {/* ################################################################### */}
      <Route
        path="/admin-reports"
        element={
          <UserRoute>
            <AdminReportsScreen />
          </UserRoute>
        }
      />
      <Route
        path="/admin-reports/long-gaps-in-updates"
        element={
          <UserRoute>
            <LongGapsInUpdates />
          </UserRoute>
        }
      />
      <Route
        path="/admin-reports/last-seen-over-7-days-ago"
        element={
          <UserRoute>
            <LastSeenOver7DaysAgo />
          </UserRoute>
        }
      />
      <Route
        path="/admin-reports/low-batteries"
        element={
          <UserRoute>
            <LowBatteries />
          </UserRoute>
        }
      />
      <Route
        path="/admin-reports/tracker-events"
        element={
          <UserRoute>
            <TrackerEvents />
          </UserRoute>
        }
      />
      <Route
        path="/admin-reports/phone-scans"
        element={
          <UserRoute>
            <PhoneScans />
          </UserRoute>
        }
      />
      <Route
        path="/admin-reports/tracker-calibration"
        element={
          <UserRoute>
            <TrackerCalibration />
          </UserRoute>
        }
      />
      <Route
        path="/admin-reports/tracker-services-report"
        element={
          <UserRoute>
            <TrackerServicesReport />
          </UserRoute>
        }
      />
      {/* ################################################################### */}
      {/* ############################# REPORTS ############################# */}
      {/* ################################################################### */}
      <Route
        path="/reports"
        element={
          <UserRoute>
            <ReportsScreen />
          </UserRoute>
        }
      />

      <Route
        path="/reports/keg-collections"
        element={
          <UserRoute>
            <KegsReadyForCollection />
          </UserRoute>
        }
      />
      <Route
        path="/reports/wayward-kegs"
        element={
          <UserRoute>
            <WaywardKegs />
          </UserRoute>
        }
      />
      <Route
        path="/reports/stagnant-kegs"
        element={
          <UserRoute>
            <StagnantKegs />
          </UserRoute>
        }
      />
      <Route
        path="/reports/freshness-report"
        element={
          <UserRoute>
            <FreshnessReport />
          </UserRoute>
        }
      />
      <Route
        path="/reports/keg-fleet-report"
        element={
          <UserRoute>
            <KegFleetReport />
          </UserRoute>
        }
      />
      <Route
        path="/reports/left-brewery-empty"
        element={
          <UserRoute>
            <LeftBreweryWithoutFill />
          </UserRoute>
        }
      />
      <Route
        path="/reports/marked-for-pickup"
        element={
          <UserRoute>
            <MarkedForPickup />
          </UserRoute>
        }
      />
      <Route
        path="/reports/keg-turn-audit"
        element={
          <UserRoute>
            <RecentKegTurns />
          </UserRoute>
        }
      />
      <Route
        path="/reports/current-places-duration"
        element={
          <UserRoute>
            <PlacesDuration />
          </UserRoute>
        }
      />
      <Route
        path="/reports/current-in-transit-duration"
        element={
          <UserRoute>
            <InTransitDuration />
          </UserRoute>
        }
      />
      <Route
        path="/reports/stationary-not-at-a-place"
        element={
          <UserRoute>
            <StationaryNotAtPlace />
          </UserRoute>
        }
      />
      <Route
        path="/reports/location-audit"
        element={
          <UserRoute>
            <LocationAudit />
          </UserRoute>
        }
      />
      <Route
        path="/reports/refrigeration-performance"
        element={
          <UserRoute>
            <RefrigerationPerformance />
          </UserRoute>
        }
      />
      <Route
        path="/reports/consumption-audit"
        element={
          <UserRoute>
            <ConsumptionAudit />
          </UserRoute>
        }
      />
      <Route
        path="/reports/route-audit"
        element={
          <UserRoute>
            <RouteAudit />
          </UserRoute>
        }
      />
      <Route
        path="/reports/temperature-events-audit"
        element={
          <UserRoute>
            <TemperatureEvents />
          </UserRoute>
        }
      />
      <Route
        path="/reports/temperature-range-audit"
        element={
          <UserRoute>
            <TemperatureRange />
          </UserRoute>
        }
      />

      {/* ##################################################################### */}
      {/* ############################# REDIRECTS ############################# */}
      {/* ##################################################################### */}
      <Route path="/join-organisation" element={<Redirect to="/" />} />
      <Route path="/reports/location-audit-report" element={<Redirect to="/reports/location-audit" />} />
      <Route path="/scan" element={<Redirect to="/scanner" />} />
      <Route path="/reports/venue-performance" element={<Redirect to="/reports/refrigeration-performance" />} />
      <Route path="/reports/kegs-marked-for-pickup" element={<Redirect to="/reports/marked-for-pickup" />} />
      <Route path="/reports/sensor-events" element={<Redirect to="/reports/tracker-events" />} />

      <Route path="/reports/trip-report" element={<Redirect to="/reports/route-audit" />} />
      <Route path="/reports/sensors-at-addresses" element={<Redirect to="/reports/current-in-transit-duration" />} />
      <Route path="/reports/in-transit-duration" element={<Redirect to="/reports/current-in-transit-duration" />} />
      <Route path="/reports/sensors-at-places" element={<Redirect to="/reports/current-places-duration" />} />
      <Route path="/reports/places-duration" element={<Redirect to="/reports/current-places-duration" />} />
      <Route path="/reports/temperature-range" element={<Redirect to="/reports/temperature-range-audit" />} />
      <Route path="/reports/temperature-events" element={<Redirect to="/reports/temperature-events-audit" />} />
      <Route path="/reports/venue-report" element={<Redirect to="/reports/consumption-audit" />} />
      <Route path="/reports/recent-keg-turns" element={<Redirect to="/reports/keg-turn-audit" />} />
      <Route path="/reports/left-brewery-without-fill" element={<Redirect to="/reports/left-brewery-empty" />} />
      <Route path="/reports/long-gaps-in-updates" element={<Redirect to="/admin-reports/long-gaps-in-updates" />} />
      <Route path="/reports/last-seen-over-7-days-ago" element={<Redirect to="/admin-reports/last-seen-over-7-days-ago" />} />
      <Route path="/reports/low-batteries" element={<Redirect to="/admin-reports/low-batteries" />} />
      <Route path="/reports/tracker-events" element={<Redirect to="/admin-reports/tracker-events" />} />
      <Route path="/reports/phone-scans" element={<Redirect to="/admin-reports/phone-scans" />} />

      <Route path="/sensors" element={<Redirect to="/trackers" />} />
      <Route path="/sensors/:id" element={<Redirect to="/trackers/:id" />} />
      <Route path="/assets" element={<Redirect to="/trackers" />} />
      <Route path="/assets/:id" element={<Redirect to="/trackers/:id" />} />
      <Route path="/activate-sensor" element={<Redirect to="/activate-tracker" />} />
      <Route path="/contents" element={<Redirect to="/manifests" />} />
      {/* Not Found Screen */}
      <Route path="/not-found" element={<NotFoundScreen />} />
    </Routes>
  );
};

export default AppRoutes;
