import { createGlobalStyle } from "../Themes";
import showAllIcon from "../../imgs/showAllIcon.svg";

export default createGlobalStyle<any>`
    .leaflet-container {
        background: ${({ theme }) => theme.map_bg_grid} !important;
        background-color: ${({ theme }) => theme.color.map_bg[2]} !important;
    }

    .leaflet-top .leaflet-control {
        border: 1px solid ${({ theme }) => theme.color_30.font_bold[2]} !important;
    }

    .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
        border: 1px solid ${({ theme }) => theme.color_30.font_bold[2]} !important;
    }

    .leaflet-bar a, .leaflet-bar a:hover {
        background-color: ${({ theme }) => theme.color.panel_bg[2]} !important;
        color: ${({ theme }) => theme.color.font[2]} !important;
        border-bottom: 1px solid ${({ theme }) => theme.color_30.font_bold[2]} !important;
    }

    .leaflet-bar a:last-child {
        border-bottom: none !important;
    }

    .leaflet-control-layers {
        background: ${({ theme }) => theme.color.panel_bg[2]} !important;
        color: ${({ theme }) => theme.color.font[2]} !important;
    }

    .leaflet-bar button, .leaflet-bar button:hover {
        background: ${({ theme }) => theme.color.panel_bg[2]} !important;
        color: ${({ theme }) => theme.color.font[2]} !important;
        font-size: 26px !important;
        line-height: 26px !important;
    }

    .filter-span {
        line-height: 30px;
        font-size: 18px;
    }

    .show-all-span {
        line-height: 31px;
        font-size: 22px;
    }

    .leaflet-popup-content-wrapper, .leaflet-popup-tip {
        background: ${({ theme }) => theme.color.main_bg[2]} !important;
        color: ${({ theme }) => theme.color.font[2]} !important;
        box-shadow: 0 3px 14px rgba(0,0,0,0.4);
        border: 1px solid ${({ theme }) => theme.color_30.font_bold[2]};
    }

    .leaflet-container a.leaflet-popup-close-button {
        top: 2px !important;
        right: 2px !important;
    }

    .leaflet-popup-content {
        line-height: 1.4;
    }

    .leaflet-popup-tip-container {
        margin-top: -1px;
    }

    .popup-title-link {
        display: block;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 4px;
        cursor: pointer;
        color: ${({ theme }) => theme.color.font_bold[2]};
        text-decoration: underline;
    }

    .popup-title {
        display: block;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 4px;
        color: ${({ theme }) => theme.color.font_bold[2]};
    }

    .popup-bold {
        display: block;
        font-weight: bold;
    }

    .popup-text {
        display: block;
    }

    .leaflet-bar {
        box-shadow: 0 2px 4px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02) !important;
    }
    
    .leaflet-control-layers {
        box-shadow: 0 2px 4px rgba(0,0,0,0.2), 0 -1px 0px rgba(0,0,0,0.02) !important;
    }

    /******* Leaflet Buttons Styling ********/

    .leaflet-control-zoom-fullscreen, .fullscreen-icon {
        background-size: 18px 18px !important;
        background-image: url(${({ theme, fullscreen }) => (fullscreen ? theme.leaflet_fullscreen_contract : theme.leaflet_fullscreen_expand)}) !important;
    }
    
    .leaflet-control .show-all-button {
        width: 100%;
        height: 100%;
        background-size: 19px 19px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(${showAllIcon}) !important;
        filter: ${({ theme }) => theme.filter_theme} !important;
    }
`;
