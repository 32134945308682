import styled from "../Themes";
import qrIcon from "../../imgs/qrIcon.svg";

export const Section = styled("section")`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: radial-gradient(circle at top center, rgba(0, 0, 0, 0) 50%, ${({ theme }) => theme.color_50.sign_in_bg[2]} 75%);
`;

export const FormContainer = styled(`div`)`
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
`;

export const LogoContainer = styled("div")`
  width: 100%;
  max-width: 300px;
  margin-bottom: 48px;
`;

export const AlertIconContainer = styled<any>(`div`)`
  fill: ${({ theme }) => theme.color.font_bold[2]};
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 6px;
`;

export const AlertSpan = styled<any>(`span`)`
  color: ${({ theme }) => theme.color.font_bold[2]};
  line-height: 24px;
`;

export const QrScanButton = styled<any>(`div`)`
  display: block;
  position: relative;

  &::after {
    content: "";
    background-size: 20px 20px;
    background-image: url(${qrIcon});
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 0.5em;
    filter: ${({ theme }) => theme.filter_theme} !important;
  }
`;
