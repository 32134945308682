import L from "leaflet";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import ClusterMarker from "../svgs/ClusterMarker";
import Marker from "../svgs/Marker";
import PlaceMarker from "../svgs/PlaceMarker";
import SensorMarker from "../svgs/SensorMarker";

const getMarkerIcon = (color: any, count?: number, type?: string, fill?: string) => {
  if (type === "sensor") {
    return L.divIcon({
      className: "leaflet-data-marker",
      html: L.Util.template(renderToStaticMarkup(<SensorMarker fill={color.primary[2]} />), {}), //.replace('#','%23'),
      iconSize: [24, 32],
      iconAnchor: [12, 32],
      popupAnchor: [0, -32],
    });
  } else if (type === "sensorCluster") {
    return L.divIcon({
      className: "leaflet-data-marker",
      html: L.Util.template(renderToStaticMarkup(<ClusterMarker count={count} fill={color.primary[2]} />), {}), //.replace('#','%23'),
      iconSize: [40, 40],
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
    });
  } else if (type === "place") {
    return L.divIcon({
      className: "leaflet-data-marker",
      html: L.Util.template(renderToStaticMarkup(<PlaceMarker fill={color.secondary[2]} />), {}), //.replace('#','%23'),
      iconSize: [24, 32],
      iconAnchor: [12, 32],
      popupAnchor: [0, -32],
    });
  } else if (type === "placeCluster") {
    return L.divIcon({
      className: "leaflet-data-marker",
      html: L.Util.template(renderToStaticMarkup(<ClusterMarker count={count} fill={color.secondary[2]} />), {}), //.replace('#','%23'),
      iconSize: [40, 40],
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
    });
  } else if (type === "nearbyPlace") {
    return L.divIcon({
      className: "leaflet-data-marker",
      html: L.Util.template(renderToStaticMarkup(<PlaceMarker fill={color.nearbyPlace[2]} />), {}), //.replace('#','%23'),
      iconSize: [24, 32],
      iconAnchor: [12, 32],
      popupAnchor: [0, -32],
    });
  } else if (type === "nearbyPlaceCluster") {
    return L.divIcon({
      className: "leaflet-data-marker",
      html: L.Util.template(renderToStaticMarkup(<ClusterMarker count={count} fill={color.nearbyPlace[2]} />), {}), //.replace('#','%23'),
      iconSize: [40, 40],
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
    });
  } else {
    return L.divIcon({
      className: "leaflet-data-marker",
      html: L.Util.template(renderToStaticMarkup(<Marker fill={fill} />), {}), //.replace('#','%23'),
      iconSize: [48, 48],
      iconAnchor: [24, 48],
      popupAnchor: [0, -48],
    });
  }
};

export default getMarkerIcon;
