import React, { FC } from "react";
import { useLocation } from "react-router-dom";
import SignInForm from "../SignInForm";
import { Section } from "./styles";

const SignInScreen: FC<any> = () => {
  const location = useLocation();

  return (
    <Section>
      <SignInForm location={location} />
    </Section>
  );
};

export default SignInScreen;
