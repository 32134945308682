import React from "react";
import { Label, HiddenRadioButton, StyledRadioButton, Svg, Circle, LabelText } from "./styles";

const RadioButton = (props: any) => {
  const { label, checked, disabled = false, loading = false, style, ...rest } = props;

  return (
    <Label style={style} disabled={disabled} loading={loading ? 1 : 0}>
      <HiddenRadioButton checked={checked} disabled={disabled || loading ? true : false} {...rest} />
      <StyledRadioButton checked={checked} disabled={disabled} loading={loading ? 1 : 0}>
        <Svg width="20px" height="20px" viewBox="0 0 20 20">
          <Circle cx="10" cy="10" checked={checked} disabled={disabled} loading={loading ? 1 : 0} />
        </Svg>
      </StyledRadioButton>
      {label && (
        <LabelText disabled={disabled} loading={loading ? 1 : 0}>
          {label}
        </LabelText>
      )}
    </Label>
  );
};

export default RadioButton;
