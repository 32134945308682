import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 512.65 512.65" {...props}>
      <path d="M409.925 205.45h-230.4v-74.24c0-38.4 25.6-71.68 61.44-79.36 48.64-7.68 92.16 28.16 92.16 76.8 0 15.36 12.8 25.6 25.6 25.6s25.6-10.24 25.6-25.6c0-74.24-64-135.68-140.8-128-66.56 7.68-115.2 66.56-115.2 133.12v71.68h-25.6c-12.8 0-25.6 10.24-25.6 25.6v256c0 12.8 12.8 25.6 25.6 25.6h307.2c15.36 0 25.6-12.8 25.6-25.6v-256c0-15.36-10.24-25.6-25.6-25.6zm-128 171.52v33.28c0 15.36-10.24 25.6-25.6 25.6s-25.6-10.24-25.6-25.6v-33.28c-15.36-10.24-25.6-25.6-25.6-43.52 0-33.28 30.72-56.32 64-48.64 17.92 5.12 33.28 20.48 38.4 38.4 5.12 23.04-7.68 43.52-25.6 53.76z" />
    </svg>
  );
}

export default SvgComponent;
