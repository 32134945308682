import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const fetchFreshnessReport = async (source: CancelTokenSource, includeEmpty: boolean, placeTypes: string) => {
  const params = {
    organisationId: getAccount().organisationId,
    includeEmpty,
    placeTypes,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/freshnessreport",
    params,
  });

  return data;
};
