import styled from "styled-components";

export const DroppableContainer = styled<any>(`div`)`
  width: 100%;
  padding: grid;
  height: 100%;
`;

export const DraggableContainer = styled<any>(`div`)`
  user-select: none;
  width: 100%;
  padding: 6px;
  margin-bottom: 6px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.dnd_list_item_bg[2]};
  display: flex;
  align-items: center;
`;

export const DragHandle = styled<any>(`div`)`
  width: 14px;
  fill: ${({ theme }) => theme.color.font[2]};
  line-height: 0;
  margin-right: 6px;
`;
