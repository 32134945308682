import axios, { CancelTokenSource } from "axios";
import { getToken } from "../localStorage";

export const fetchVerifyEmailGenerate = async (source: CancelTokenSource) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/verifyemail",
  });

  return data;
};

export const getVerifyEmailVerify = async (source: CancelTokenSource, id: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/verifyemail/" + id,
  });

  return data;
};
