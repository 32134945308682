import React, { FC, useState, useEffect, useContext } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { FormInput, FormError } from "../FormComponents";
import { exists } from "../../util/formValidations";
import { ModalFormContainer } from "../Modal/styles";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { ThemeContext } from "styled-components";
import { putManifest } from "../../services/manifests";
import { getAccount } from "../../services/localStorage";
import { AsyncSelect } from "../Select";
import { fetchAutoComplete } from "../../services/autoComplete";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import moment from "moment";
import FlatpickrPicker from "../FlatpickrPicker";

const defaultManifest = {
  id: null,
  navifyTransportId: null,
  startPlace: null,
  targetDestination: null,
  deadline: "",
  startTime: moment().unix() * 1000,
};

const initManifestForm = (manifest: any, startTime: number) => {
  const formData = {
    ...defaultManifest,
    ...manifest,
    startTime,
    tracker: manifest.sensorId
      ? {
          value: manifest.sensorId,
          label: manifest.sensorId,
        }
      : null,
    startPlace: manifest.startPlaceId
      ? {
          value: manifest.startPlaceId,
          label: manifest.startPlaceName,
        }
      : null,
    targetDestination: manifest.targetPlaceId
      ? {
          value: manifest.targetPlaceId,
          label: manifest.targetPlaceName,
        }
      : null,
    deadline: manifest.deadlineTimeUnix ? manifest.deadlineTimeUnix * 1000 : "",
  };

  return formData;
};

const StartManifestModel: FC<any> = ({ manifest, onSuccess, modalOpen, setModalOpen }) => {
  const { short_datetime } = useContext(ThemeContext);

  const [formData, setFormData] = useState<any>(initManifestForm(manifest, moment().unix() * 1000));
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "startPlace":
          currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const formattedData: any = {
      id: formData.id,
      binaryId: formData.tracker?.value,
      name: formData.name,
      description: formData.description,
      startTime: Math.round(formData.startTime / 1000).toString(),
      startPlaceId: formData.startPlace?.value,
      targetPlaceId: formData.targetDestination?.value,
      deadlineTime: formData.deadline ? Math.round(formData.deadline / 1000).toString() : undefined,
      startingUserId: getAccount().id,
    };

    return formattedData;
  };

  const handleStart = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      putManifest(source, body)
        .then(() => {
          if (onSuccess) onSuccess();
          setSubmittedMsg("Manifest Started");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const handleDateChange = (date: any) => {
    setFormData((prev: any) => ({ ...prev, deadline: date[0] }));
    setFormErrors((prev: any) => ({ ...prev, deadline: undefined }));
  };

  // Auto-populates select input on search.
  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={handleStart}
      onClose={handleClose}
      size={!submittedMsg && !submittingErr ? "lg" : "sm"}
      title="Start Manifest"
      success={submittedMsg}
      error={submittingErr}
      body={
        <LoadingContainer loading={submitting}>
          <div>
            <form noValidate onSubmit={(e) => e.preventDefault()} style={{ display: "flex", flexWrap: "wrap", columnGap: "10px" }}>
              <ModalFormContainer halfWidth={true}>
                <label>Start Place</label>
                <AsyncSelect
                  name="startPlace"
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.startPlace}
                  value={formData.startPlace}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                />
                <FormError error={formErrors.startPlace}>{formErrors.startPlace}</FormError>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true}>
                <label>Start Time</label>
                <FormInput
                  autoComplete="no"
                  type="text"
                  name="startTime"
                  value={formData.startTime ? moment(formData.startTime).format(short_datetime) : ""}
                  disabled={true}
                />
                <FormError error={formErrors.startTime}>{formErrors.startTime}</FormError>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true}>
                <label>Target Destination</label>
                <AsyncSelect
                  name="targetDestination"
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.targetDestination}
                  value={formData.targetDestination}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                />
                <FormError error={formErrors.targetDestination}>{formErrors.targetDestination}</FormError>
              </ModalFormContainer>
              <ModalFormContainer halfWidth={true}>
                <label>Deadline</label>
                <FlatpickrPicker
                  enableTime={true}
                  name="deadline"
                  value={formData.deadline}
                  onChange={handleDateChange}
                  onClose={handleDateChange}
                  onClear={() => handleDateChange([undefined])}
                />
                <FormError error={formErrors.deadline}>{formErrors.deadline}</FormError>
              </ModalFormContainer>
            </form>
          </div>
        </LoadingContainer>
      }
      footer={
        submittedMsg ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : submittingErr ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : (
          <>
            <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
            <PrimaryBtn onClick={handleStart}>Start</PrimaryBtn>
          </>
        )
      }
    />
  );
};

export default StartManifestModel;
