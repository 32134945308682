import React, { FC, useState, useEffect, useContext } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { FormError } from "../FormComponents";
import { exists } from "../../util/formValidations";
import { ModalFormContainer } from "../Modal/styles";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { ThemeContext } from "styled-components";
import { endManifest } from "../../services/manifests";
import { AsyncSelect } from "../Select";
import { fetchAutoComplete } from "../../services/autoComplete";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";

const defaultManifest = {
  endPlace: null,
};

const EndManifestModel: FC<any> = ({ manifest, onSuccess, modalOpen, setModalOpen }) => {
  const { color, short_datetime } = useContext(ThemeContext);

  const [formData, setFormData] = useState<any>(defaultManifest);
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "endPlace":
          currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const handleEnd = () => {
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      endManifest(source, manifest.id, formData.endPlace.value)
        .then(() => {
          if (onSuccess) onSuccess();
          setSubmittedMsg("Manifest Ended");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  // Auto-populates select input on search.
  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={handleEnd}
      onClose={handleClose}
      size="sm"
      title="End Manifest"
      success={submittedMsg}
      error={submittingErr}
      body={
        <LoadingContainer loading={submitting}>
          <div>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <ModalFormContainer>
                <label>End Place</label>
                <AsyncSelect
                  name="endPlace"
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.endPlace}
                  value={formData.endPlace}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                />
                <FormError error={formErrors.endPlace}>{formErrors.endPlace}</FormError>
              </ModalFormContainer>
            </form>
          </div>
        </LoadingContainer>
      }
      footer={
        submittedMsg ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : submittingErr ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : (
          <>
            <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
            <PrimaryBtn onClick={handleEnd}>End</PrimaryBtn>
          </>
        )
      }
    />
  );
};

export default EndManifestModel;
