import React, { FC, useState, useRef, useContext } from "react";
import { FlatpickrModal } from "../Modal";
import Flatpickr from "react-flatpickr";
import { ThemeContext } from "styled-components";
import moment from "moment";

const DateRangeCalendarModal: FC<any> = ({ start, end, minDate, maxDate, handleDatesChange, modalOpen, setModalOpen, enableTime = true }) => {
  const { short_date, short_datetime } = useContext(ThemeContext);

  const flatpickrRef = useRef<any>(null);

  // sets initial dates if within the min and max date ranges
  const initDates = () => {
    if (start && end) {
      if (start >= minDate && end <= maxDate) return [start.toDate(), end.toDate()];
    }
    return [];
  };

  const [dates, setDates] = useState<any>(initDates());

  // handles the change event from the flatpickr calendar and updates the dates state accordingly
  // if time is enabled, the dates are set as is, otherwise the start and end dates are set to the start and end of the day
  const handleChange = (dates: any) => {
    if (enableTime) setDates(dates);
    else {
      if (dates.length === 1) {
        const start = moment(dates[0]).startOf("day");
        setDates([start.toDate()]);
      } else if (dates.length === 2) {
        const start = moment(dates[0]).startOf("day");
        const end = moment(dates[1]).endOf("day");
        setDates([start.toDate(), end.toDate()]);
      } else {
        setDates([]);
      }
    }
  };

  return (
    <>
      <FlatpickrModal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        updateDisabled={dates.length < 2}
        onUpdate={() => {
          handleDatesChange(dates);
          setModalOpen(false);
        }}
        onClear={() => {
          if (flatpickrRef.current && flatpickrRef.current.flatpickr) {
            flatpickrRef.current.flatpickr.clear();
          }
        }}
        body={
          <>
            <Flatpickr
              data-enable-time={enableTime}
              ref={flatpickrRef}
              value={dates}
              onChange={(dates: any) => handleChange(dates)}
              className="hide-flatpickr-input"
              options={{
                inline: true,
                mode: "range",
                minDate: minDate ? minDate.startOf("day").toDate() : undefined,
                maxDate: maxDate ? maxDate.endOf("day").toDate() : undefined,
              }}
            />
            {dates.length > 0 && (
              <span
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  paddingTop: "12px",
                }}
              >
                {dates[0] ? moment(dates[0]).format(enableTime ? short_datetime : short_date) : "..."} to{" "}
                {dates[1] ? moment(dates[1]).format(enableTime ? short_datetime : short_date) : "..."}
              </span>
            )}
          </>
        }
      />
    </>
  );
};

export default DateRangeCalendarModal;
