import React, { FC, useState, useEffect } from "react";
import MainMap from "../MainMap";
import { fetchPlaces } from "../../services/places";
import queryString from "query-string";
import axios from "axios";
import errToStr from "../../util/errToStr";
import { toCelsius } from "../../util/formatUnits";
import { useLocation } from "react-router-dom";
import { getAccount } from "../../services/localStorage";
import { getMapTrackers } from "../../services/mapTrackers";

// creates an object containing only the sensor filters from the query params
// https://stackoverflow.com/q/11704267
const getTrackerFilters = (filters: any) => {
  const {
    hideAtPlaces,
    hideInTransit,
    productIds,
    minDaysInPlace,
    maxDaysInPlace,
    minDaysSinceSeen,
    maxDaysSinceSeen,
    minTemperature,
    maxTemperature,
    organisations,
    assetTypes,
    trackerTags,
    hideSensors,
    readyForCollection,
    kegStatus,
    beers,
    minFreshness,
    maxFreshness,
  } = filters;
  return {
    ...(hideAtPlaces && { hideAtPlaces }),
    ...(hideInTransit && { hideInTransit }),
    ...(productIds && { productIds }),
    ...(minDaysInPlace && { minDaysInPlace }),
    ...(maxDaysInPlace && { maxDaysInPlace }),
    ...(minDaysSinceSeen && { minDaysSinceSeen }),
    ...(maxDaysSinceSeen && { maxDaysSinceSeen }),
    ...(minTemperature && { minTemperature }),
    ...(maxTemperature && { maxTemperature }),
    ...(organisations && { organisations }),
    ...(assetTypes && { assetTypes }),
    ...(trackerTags && { trackerTags }),
    ...(hideSensors && { hideSensors }),
    ...(readyForCollection && { readyForCollection }),
    ...(kegStatus && { kegStatus }),
    ...(beers && { beers }),
    ...(minFreshness && { minFreshness }),
    ...(maxFreshness && { maxFreshness }),
  };
};

// creates an object containing only the place filters from the query params
// https://stackoverflow.com/q/11704267
const getPlaceFilters = (filters: any, accountInfo: any) => {
  const { minSensors, maxSensors, organisations, placeGroups, placeTags, hidePlaces } = filters;
  return {
    // If minSensors doesn't exist, set minSensors filter to '1' if the user has sensors or stickers else set to '0'
    ...(minSensors ? { minSensors } : { minSensors: accountInfo.sensors > 0 || accountInfo.stickers > 0 ? "1" : "0" }),
    ...(maxSensors && { maxSensors }),
    ...(organisations && { organisations }),
    ...(placeGroups && { placeGroups }),
    ...(placeTags && { placeTags }),
    ...(hidePlaces && { hidePlaces }),
  };
};

const MainMapScreen: FC<any> = () => {
  const location = useLocation();

  const [accountInfo] = useState<any>(getAccount());

  const [filters] = useState<any>(queryString.parse(location.search));
  const [sensorFilters, setSensorFilters] = useState<any>(getTrackerFilters(filters));
  const [placeFilters, setPlaceFilters] = useState<any>(getPlaceFilters(filters, accountInfo));

  const [sensors, setSensors] = useState<any>([]);
  const [sensorsRanges, setSensorsRanges] = useState<any>({});
  const [sensorsErr, setSensorsErr] = useState<string>("");
  const [sensorsLoading, setSensorsLoading] = useState<any>(true);
  const [sensorsLoaded, setSensorsLoaded] = useState<boolean>(false);

  const [places, setPlaces] = useState<any>([]);
  const [placeRanges, setPlaceRanges] = useState<any>({});
  const [placesErr, setPlacesErr] = useState<string>("");
  const [placesLoading, setPlacesLoading] = useState<any>(true);
  const [placesLoaded, setPlacesLoaded] = useState<boolean>(false);

  useEffect(() => {
    const filters = queryString.parse(location.search);
    const newSensorFilters = getTrackerFilters(filters);
    const newPlaceFilters = getPlaceFilters(filters, accountInfo);

    if (JSON.stringify(newSensorFilters) !== JSON.stringify(sensorFilters)) setSensorFilters(newSensorFilters);

    if (JSON.stringify(newPlaceFilters) !== JSON.stringify(placeFilters)) setPlaceFilters(newPlaceFilters);
  }, [location]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    const convertedSensorFilters = {
      ...sensorFilters,
      minTemperature: toCelsius(sensorFilters.minTemperature),
      maxTemperature: toCelsius(sensorFilters.maxTemperature),
    };

    setSensorsLoading(true);
    getMapTrackers(source, convertedSensorFilters)
      .then((response) => {
        setSensors(response.data);
        setSensorsRanges(response.ranges);
        setSensorsErr("");
        setSensorsLoading(false);
        setSensorsLoaded(true);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSensorsErr(errToStr(err));
          setSensorsLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [sensorFilters]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setPlacesLoading(true);
    fetchPlaces(source, placeFilters)
      .then((response) => {
        setPlaces(response.places);
        setPlaceRanges(response.ranges);
        setPlacesErr("");
        setPlacesLoading(false);
        setPlacesLoaded(true);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setPlacesErr(errToStr(err));
          setPlacesLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [placeFilters]);

  return (
    <MainMap
      sensors={sensors}
      places={places}
      sensorsRanges={sensorsRanges}
      placeRanges={placeRanges}
      sensorsErr={sensorsErr}
      placesErr={placesErr}
      sensorsLoading={sensorsLoading}
      placesLoading={placesLoading}
      sensorsLoaded={sensorsLoaded}
      placesLoaded={placesLoaded}
      sensorFilters={sensorFilters}
      placeFilters={placeFilters}
    />
  );
};

export default MainMapScreen;
