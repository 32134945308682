import { createGlobalStyle } from "../Themes";

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Montserrat:400,500');

  * {
    box-sizing: border-box;

    margin: 0;
    padding: 0;
    
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    scrollbar-color: #3a3a3a transparent;
    scrollbar-width: thin;
  }

  *:focus {
    outline: none;
  }

  html {
    overflow: hidden;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    font-size: 14px;
  }

  #root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    overflow: hidden;
  }

  body {
    color: ${({ theme }) => theme.color.font[2]};
    line-height: ${({ theme }) => theme.lineHeight.m};
    font-family: ${({ theme }) => theme.fontFamily.body}; 
    background-color: ${({ theme }) => theme.color.main_bg[2]};
  }

  a {
    color: ${({ theme }) => theme.color.font[2]};
    font-weight: 500;
  }

  ul { list-style: none; }
  b { font-weight: bold; }

  video,
  iframe {
    /* remove pixels below display: inline */
    display: block;
    /* so imgs will resize based on wrapper constraints */
    width: 100%;
    height: auto;
    /* prevent image from stretching due to 'align-self: default' value is stretch (in flexbox context) */
    align-self: flex-start;
  }

  /* remove pixels below display: inline */
  img { display: block; }

  button { 
    background: transparent; 
    border: none;
    cursor: pointer;
  }

  [disabled] { pointer-events: none; }
 
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    cursor:pointer;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
   
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.scroll_bar[2]};
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.color.scroll_bar_hover[2]};
  }
  
  /********* Hide arrows on number inputs *********/
  
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export default GlobalStyles;
