import { getAccount } from "../services/localStorage";
import { isBinaryBeer } from "./checkDomain";

export const kegsOrTrackers = (kegs: any, trackers: any) => {
  if (isBinaryBeer()) return kegs;
  else return trackers;
};

export const kegOrTracker = (keg: any, tracker: any) => {
  if (isBinaryBeer()) return keg;
  else return tracker;
};

export const onlyStickers = () => {
  const stickerCount = getAccount().stickers;
  const sensorsCount = getAccount().sensors;

  if (stickerCount > 0 && sensorsCount === 0) return true;
  else return false;
};

export const onlySensors = () => {
  const stickerCount = getAccount().stickers;
  const sensorsCount = getAccount().sensors;

  if (stickerCount === 0 && sensorsCount > 0) return true;
  else return false;
};
