import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400" {...props}>
      <path
        d="M182.031 1.107C125.09 11.188 84.348 62.554 86.94 120.993c1.18 26.616 7.093 41.315 39.791 98.929l12.859 22.656-41.344.391-41.344.39-2.118 2.372C51.682 249.203-.028 387.301-.012 392.069c.01 2.786.449 3.806 2.438 5.664L4.853 400h390.294l2.427-2.267c1.989-1.858 2.428-2.878 2.438-5.664.016-4.768-51.694-142.866-54.796-146.338l-2.118-2.372-41.304-.39-41.304-.391 13.524-23.828c21.651-38.147 29.112-52.955 33.424-66.338 21.198-65.797-18.086-135.469-84.84-150.469-7.841-1.762-32.471-2.27-40.567-.836m33.08 18.075c57.562 10.069 93.003 70.219 75.008 127.302-3.953 12.539-87.212 164.454-90.131 164.454-1.024 0-75.844-132.736-81.864-145.233-6.69-13.887-8.999-20.206-11.161-30.549C93.3 69.783 150.952 7.959 215.111 19.182M192.188 52.36c-49.184 6.247-70.617 65.772-36.869 102.39 33.369 36.205 94.281 19.185 104.575-29.22 8.653-40.692-26.27-78.433-67.706-73.17m20.821 19.921c30.731 10.315 39.986 48.071 17.36 70.815-26.167 26.302-70.79 9.531-73.091-27.471-1.857-29.865 27.611-52.784 55.731-43.344m-41.815 226.157c22.823 40.31 23.045 40.625 28.806 40.625 5.753 0 5.96-.291 28.824-40.625l21.037-37.11 41.375-.202 41.374-.202 22.637 60.358 22.637 60.359-88.942.198c-48.918.109-128.966.109-177.884 0l-88.942-.198 22.637-60.359 22.637-60.358 41.397.202 41.398.202 21.009 37.11"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
