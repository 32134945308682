import styled from "../../components/Themes";

export const Content = styled<any>(`div`)`
  position: absolute;
  top: 50px;
  left: ${({ open }) => (open ? "50px" : "0px")};
  bottom: 0;
  right: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;
`;
