import styled from "../Themes";
import qrIcon from "../../imgs/qrIcon.svg";

export const Content = styled<any>(`div`)`
  width: 90%;
  margin: 0 auto;
  padding: 40px;

  @media (max-width: 900px) {
    width: 100%;
    padding: 0;
  }
`;

export const QrScanButton = styled<any>(`div`)`
  display: block;
  position: relative;

  &::after {
    content: "";
    background-size: 20px 20px;
    background-image: url(${qrIcon});
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 0.5em;
    filter: ${({ theme }) => theme.filter_theme} !important;
  }
`;
