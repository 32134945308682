import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import React, { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill, BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce";
import { deletePlace, fetchPlaces } from "../../services/editPlace";
import { getPlaceTableSettings, placeTableDefaults, savePlaceTableSettings } from "../../services/localStorage";
import MoreIcon from "../../svgs/MoreIcon";
import addOrUpdate from "../../util/addOrUpdate";
import { isBinaryBeer } from "../../util/checkDomain";
import { isAdminOrUser } from "../../util/checkRole";
import copyToClipboard from "../../util/copyToClipboard";
import downloadFile from "../../util/downloadFile";
import errToStr from "../../util/errToStr";
import { printFixedLength, printLength, printLengthUnit } from "../../util/formatUnits";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import sortTags from "../../util/sortTags";
import { getTableFilters } from "../../util/urlParamFilters";
import { PrimaryBtn } from "../Buttons";
import CreatePlaceModal from "../CreatePlaceModal";
import DeleteModal from "../DeleteModal";
import EditPlaceModal from "../EditPlaceModal";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import { PrimaryIconBtn } from "../IconButtons";
import NewTable from "../NewTable";
import { DesktopDiv, MobileDiv } from "../NewTable/styles";
import PlaceAllocationsListModal from "../PlaceAllocationsListModal";
import Tag from "../Tag";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

type Place = {
  id: number;
  name: string;
  placeType: string;
  placeTypeColour: string;
  trackerCount: number;
  address: string;
  city: string;
  state: string;
  postcode: string;
  country: string;
  radius: number;
  allocations: number;
  allocationStatus: number;
};

const PlacesTable: FC<any> = () => {
  const { color } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>([{ id: "trackerCount", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [selectedId, setSelectedId] = useState<any>(undefined);
  const [createModalOpen, setCreateModalOpen] = useState<any>(false);
  const [editModalOpen, setEditModalOpen] = useState<any>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [allocationListModalOpen, setAllocationListModalOpen] = useState<boolean>(false);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getPlaceTableSettings() ? getPlaceTableSettings().pageSize : 20,
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const getPlaces = () => {
    setDataLoading(true);
    fetchPlaces(source, { pageIndex, pageSize, orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        setData(response.data);
        setCount(response.count);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const fetchCsv = (download: boolean) => {
    setDataLoading(true);
    fetchPlaces(source, { orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        if (download) {
          downloadFile(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            }),
            "text/csv;charset=utf-8",
            "Place List.csv"
          );
        } else {
          copyToClipboard(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            })
          );
          toast.info("Copied to Clipboard");
        }
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const formatDataToCsv = (data: any) => {
    const headers = [
      "Name",
      "Place Type",
      "Place Tags",
      kegsOrTrackers("Keg Count", "Tracker Count"),
      "Address",
      "City",
      "State",
      "Postcode",
      "Country",
      `Place Radius (${printLengthUnit()})`,
      "Allocations",
      "Allocation Status",
    ];

    return [
      headers,
      ...data.map((row: any) => {
        const rows = [
          row.name,
          row.placeType,
          row.placeTagsList.sort().join(", "),
          row.trackerCount,
          row.address,
          row.city,
          row.state,
          row.postcode,
          row.country,
          printFixedLength(row.radius),
          row.allocations,
          row.allocationStatus === 0
            ? "Correctly Allocated"
            : row.allocationStatus === 1
            ? "Under Allocated"
            : row.allocationStatus === 2
            ? "Over Allocated"
            : "Under/Over Allocated",
        ];

        return rows;
      }, []),
    ];
  };

  const updateTable = (newData: any) => {
    setData((prev: any) => addOrUpdate(prev, newData, "id"));
  };

  const columns = React.useMemo<ColumnDef<Place>[]>(
    () => [
      {
        id: "actions",
        header: "",
        enableColumnFilter: false,
        enableSorting: false,
        enableHiding: false,
        cell: ({ row }) => {
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  {isAdminOrUser() ? (
                    <>
                      <MenuButton
                        onClick={() => {
                          setSelectedId(row.original.id);
                          setEditModalOpen(true);
                        }}
                      >
                        Edit Place
                      </MenuButton>
                      <DangerMenuButton
                        onClick={() => {
                          setSelectedId(row.original.id);
                          setDeleteModalOpen(true);
                        }}
                      >
                        Delete Place
                      </DangerMenuButton>
                    </>
                  ) : (
                    <>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Edit Place</MenuButton>
                        </div>
                      </Tooltip>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Delete Place</MenuButton>
                        </div>
                      </Tooltip>
                    </>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        size: 65,
        minSize: 65,
      },
      {
        accessorKey: "name",
        cell: (props: any) => (
          <Link title={props.getValue()} to={`/places/${props.row.original.id}`}>
            {props.getValue()}
          </Link>
        ),
        header: "Name",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 290,
      },
      {
        accessorKey: "placeType",
        cell: (props: any) => (
          <>
            <ColoredDot color={props.row.original.placeTypeColour} />
            <span title={props.getValue()}>{props.getValue()}</span>
          </>
        ),
        header: "Place Type",
        meta: {
          filterType: "autoComplete",
          filterKey: "PlaceType",
          loadOptionsKey: "placetypes",
        },
        filterFn: undefined,
        size: 170,
      },
      {
        accessorKey: "PlaceTagsList",
        cell: (props: any) =>
          props.row.original.placeTags ? (
            props.row.original.placeTags
              .sort(sortTags)
              .map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
          ) : (
            <></>
          ),
        header: "Place Tags",
        enableSorting: false,
        meta: {
          filterType: "autoCompleteInList",
          filterKey: "placeTags",
          loadOptionsKey: "placetags",
        },
        filterFn: undefined,
        size: 170,
      },
      {
        accessorKey: "trackerCount",
        header: kegsOrTrackers("Keg Count", "Tracker Count"),
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 160,
      },
      ...(isBinaryBeer()
        ? [
            {
              accessorKey: "beerName",
              header: "Default Beer",
              cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
              meta: {
                filterType: "autoComplete",
                filterKey: "beerName",
                selectKey: "label",
                loadOptionsKey: "beers",
              },
              filterFn: undefined,
              size: 160,
            },
          ]
        : []),
      {
        accessorKey: "address",
        header: "Address",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 350,
      },
      {
        accessorKey: "city",
        header: "City",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 150,
      },
      {
        accessorKey: "state",
        header: "State",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 225,
      },
      {
        accessorKey: "postcode",
        header: "Postcode",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 130,
      },
      {
        accessorKey: "country",
        header: "Country",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 160,
      },
      {
        accessorKey: "radius",
        cell: (props: any) => <span title={printLength(props.getValue())}>{printLength(props.getValue())}</span>,
        header: "Radius",
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 160,
      },
      {
        accessorKey: "allocations",
        cell: (props: any) =>
          props.getValue() > 0 ? (
            <div
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                setSelectedId(props.row.original.id);
                setAllocationListModalOpen(true);
              }}
            >
              {props.getValue()}
            </div>
          ) : (
            <span title={props.getValue()}>{props.getValue()}</span>
          ),
        header: "Allocations",
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 150,
      },
      {
        accessorKey: "allocationStatus",
        cell: (props: any) =>
          props.getValue() === undefined ? (
            <></>
          ) : props.getValue() === 0 ? (
            <Tooltip trigger="mouseenter" content="Correctly Allocated">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <IconContext.Provider value={{ color: color.success[2], size: "23px" }}>
                  <BsFillCheckCircleFill />
                </IconContext.Provider>
              </div>
            </Tooltip>
          ) : props.getValue() === 1 ? (
            <Tooltip trigger="mouseenter" content="Under Allocated">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <IconContext.Provider value={{ color: color.danger[2], size: "23px" }}>
                  <BsFillArrowDownCircleFill />
                </IconContext.Provider>
              </div>
            </Tooltip>
          ) : props.getValue() === 2 ? (
            <Tooltip trigger="mouseenter" content="Over Allocated">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <IconContext.Provider value={{ color: color.danger[2], size: "23px" }}>
                  <BsFillArrowUpCircleFill />
                </IconContext.Provider>
              </div>
            </Tooltip>
          ) : props.getValue() === 3 ? (
            <Tooltip trigger="mouseenter" content="Under/Over Allocated">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <IconContext.Provider value={{ color: color.danger[2], size: "23px" }}>
                  <BsFillXCircleFill />
                </IconContext.Provider>
              </div>
            </Tooltip>
          ) : (
            <></>
          ),
        header: "Allocation Status",
        meta: {
          filterType: "select",
          loadOptionsKey: "removed",
          selectKey: "value",
          selectOptions: [
            { value: 0, label: "Correct", colour: color.success[2], comparisonOperator: "eq" },
            { value: 0.9, label: "Incorrect", colour: color.danger[2], comparisonOperator: "ge" },
            { value: 1, label: "Under", colour: color.danger[2], comparisonOperator: "eq" },
            { value: 2, label: "Over", colour: color.danger[2], comparisonOperator: "eq" },
            { value: 3, label: "Under/Over", colour: color.danger[2], comparisonOperator: "eq" },
          ],
        },
        filterFn: undefined,
        size: 180,
      },
    ],
    [color]
  );

  return (
    <>
      <NewTable
        data={data}
        count={count}
        dataErr={dataErr}
        dataLoading={dataLoading}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        columnFiltersDebounced={columnFiltersDebounced}
        setColumnFilters={setColumnFilters}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        fetchData={getPlaces}
        fetchCsv={fetchCsv}
        defaultTableSettings={placeTableDefaults}
        getTableSettings={getPlaceTableSettings}
        saveTableSettings={savePlaceTableSettings}
        dataTypeName="Places"
        emptyDataMsg="Create a place above to get started"
        TableButtons={
          <div>
            {isAdminOrUser() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setSelectedId(undefined);
                      setCreateModalOpen(true);
                    }}
                  >
                    Create
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <PrimaryIconBtn
                      onClick={() => {
                        setSelectedId(undefined);
                        setCreateModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryBtn disabled={true}>Create</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryIconBtn>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
        }
      />
      {createModalOpen && <CreatePlaceModal updateData={updateTable} modalOpen={createModalOpen} setModalOpen={setCreateModalOpen} />}
      {editModalOpen && <EditPlaceModal id={selectedId} updateData={updateTable} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
      {deleteModalOpen && (
        <DeleteModal
          title="Delete Place"
          body={
            <>
              <span>Are you sure you want to delete this place?</span>
              <br />
              <span>Any alerts associated with this place will be disabled</span>
            </>
          }
          successMsg="Place Deleted"
          onDelete={() => setData((prev: any) => prev.filter((row: any) => row.id !== selectedId))}
          onClose={() => setSelectedId(undefined)}
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          deleteService={deletePlace}
          serviceParams={[selectedId]}
        />
      )}
      {allocationListModalOpen && (
        <PlaceAllocationsListModal
          placeId={selectedId}
          modalOpen={allocationListModalOpen}
          setModalOpen={setAllocationListModalOpen}
          onClose={() => setSelectedId(undefined)}
        />
      )}
    </>
  );
};

export default PlacesTable;
