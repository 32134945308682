import React, { FC } from "react";
import ExternalLink from "../../util/externalLink";

const UnsupportedBrowser: FC<any> = () => {
  return (
    <div style={{ textAlign: "center", margin: "10px 0" }}>
      <h2>It appears you're using an unsupported browser</h2>
      <p style={{ margin: "6px 0 12px 0" }}>For the best experience we recommend using one of the following browsers</p>
      <ExternalLink url="https://www.google.com/intl/en_au/chrome/" label="Chrome" style={{ margin: "0 6px" }} />
      <ExternalLink url="https://www.mozilla.org/en-US/firefox/new/" label="Firefox" style={{ margin: "0 6px" }} />
      <ExternalLink url="https://www.microsoft.com/en-us/edge" label="Edge" style={{ margin: "0 6px" }} />
    </div>
  );
};

export default UnsupportedBrowser;
