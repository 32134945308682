import React, { FC, useState, useEffect } from "react";
import { getAccount, saveAccount } from "../../services/localStorage";
import { fetchLegal, postLegal } from "../../services/legal";
import { OutlineBtn, PrimaryBtn } from "../Buttons";
import LoadingContainer from "../LoadingContainer";
import TermsStyling from "../GlobalStyles/terms-and-conditions";
import { PageContainer, TermsContainer, Terms, ButtonContainer } from "./styles";
import { useNavigate, useLocation } from "react-router-dom";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import queryString from "query-string";

const TermsAndConditionsScreen: FC<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [accountInfo] = useState<any>(getAccount());

  const [data, setData] = useState<any>(undefined);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [accepted, setAccepted] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const getNextRoute = () => {
    const queryParams = queryString.parse(location.search);
    let nextRoute: any = "/";

    if (queryParams.path) {
      const { path, ...rest } = queryParams;

      nextRoute = path;

      const stringified = queryString.stringify(rest);

      if (stringified) nextRoute += `?${stringified}`;

      return nextRoute;
    } else {
      return nextRoute;
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchLegal(source)
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [source]);

  const acceptLegal = () => {
    setSubmittingErr("");
    setSubmitting(true);

    const body = {
      version: data.version,
      accepted: true,
    };

    postLegal(source, body)
      .then(() => {
        saveAccount({
          ...accountInfo,
          legalAccepted: true,
        });
        setSubmitting(false);
        setAccepted(true);
        const accountInfoUpdatedEvent = new Event("accountinfoupdated");
        document.dispatchEvent(accountInfoUpdatedEvent);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  const saveTerms = () => {
    const element = document.createElement("a");
    const file = new Blob([data.text], { type: "html" });
    element.href = URL.createObjectURL(file);
    element.download = `Terms and Conditions - v${data.version}.html`;
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  if (accepted) navigate(getNextRoute() || "/");

  return (
    <>
      <TermsStyling />
      <LoadingContainer loading={dataLoading || submitting} err={dataErr || submittingErr}>
        <PageContainer>
          <TermsContainer>
            {data && (
              <Terms
                dangerouslySetInnerHTML={{
                  __html: data.text,
                }}
              />
            )}
          </TermsContainer>
          <ButtonContainer>
            <OutlineBtn onClick={() => saveTerms()} width="30%" float="left">
              Save...
            </OutlineBtn>
            <PrimaryBtn onClick={() => acceptLegal()} width="30%" float="right">
              Accept
            </PrimaryBtn>
          </ButtonContainer>
        </PageContainer>
      </LoadingContainer>
    </>
  );
};

export default TermsAndConditionsScreen;
