import React, { FC, useState, useEffect, useContext } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import Bold from "../Bold";
import { FormError, FormInput, InfoIconContainer } from "../FormComponents";
import { exists, validName, maxLength } from "../../util/formValidations";
import { ModalFormContainer } from "../Modal/styles";
import { fetchPlaceGroupList } from "../../services/placeGroupList";
import matchSorter from "match-sorter";
import { Link } from "react-router-dom";
import Table from "../Table";
import Checkbox from "../Checkbox";
import { postEditPlaceGroup } from "../../services/editPlaceGroup";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import ColorPicker from "../ColorPicker";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import { ThemeContext } from "styled-components";
import { isBinaryBeer } from "../../util/checkDomain";
import Tooltip from "../Tooltip";
import InfoIcon from "../../svgs/Legend";

const initGroup = (group: any, echarts_palette: any) => {
  return {
    groupId: null,
    groupName: "",
    groupColour: echarts_palette[Math.floor(Math.random() * echarts_palette.length)],
    features: [],
    sensors: [],
    fillsBeerKegs: false,
    emptiesBeerKegs: false,
    ...group,
  };
};

const EditPlaceTypeModal: FC<any> = ({ group, fetch, modalOpen, setModalOpen }) => {
  const { color, echarts_palette } = useContext(ThemeContext);

  const [formData, setFormData] = useState<any>(initGroup(group, echarts_palette));
  const [formErrors, setFormErrors] = useState<any>({});

  const [places, setPlaces] = useState<any>([]);
  const [placesErr, setPlacesErr] = useState<string>("");
  const [placesLoading, setPlacesLoading] = useState<boolean>(false);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [placeChanges, setPlaceChanges] = useState<any>({});

  const [filterOptions, setFilterOptions] = useState<any>({
    groupName: [],
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setPlacesLoading(true);
    fetchPlaceGroupList(source)
      .then((response) => {
        // Sets selected and initSelected property to indicate if places are included in the group or not and to sort the rows
        // by initSelected to prevent the rows bouncing around when editing
        setPlaces(
          response.map((place: any) => ({
            ...place,
            selected: place.groupId === formData.groupId,
            initSelected: place.groupId === formData.groupId,
          }))
        );
        setPlacesLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setPlacesErr(errToStr(err));
          setPlacesLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [formData.groupId, source]);

  // Get distinct strings for use in the select input filters when places changes
  useEffect(() => {
    if (Array.isArray(places)) {
      const groupName = new Set();

      for (let i = 0; i < places.length; i++) {
        if (places[i].groupName) groupName.add(places[i].groupName);
      }

      setFilterOptions({
        groupName: Array.from(groupName).sort(),
      });
    }
  }, [places]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "groupName":
          currValid = exists(name, value, setFormErrors) && maxLength(name, value, 200, setFormErrors) && validName(name, value, setFormErrors);
          break;

        case "groupColour":
          currValid = exists(name, value, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const placeChangesArr = Object.values(placeChanges);

    const updatePlaces: any = [];
    const removePlaces: any = [];

    placeChangesArr.forEach((place: any) => {
      // If the place is selected it's place type will be updated
      if (place.selected) updatePlaces.push(place.placeId);

      // If the place is not selected and we are editing an existing type and we have
      // de-selected a place within the current type, remove place from the type
      if (!place.selected && formData.groupId && place.groupId === formData.groupId) removePlaces.push(place.placeId);
    });

    const formattedData: any = {
      groupId: formData.groupId,
      groupName: formData.groupName,
      groupColour: formData.groupColour,
      updatePlaces,
      removePlaces,
      fillsBeerKegs: formData.fillsBeerKegs,
      emptiesBeerKegs: formData.emptiesBeerKegs,
    };

    return formattedData;
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      postEditPlaceGroup(source, body)
        .then(() => {
          if (fetch) fetch();
          setSubmittedMsg(formData.groupId ? "Place Type Updated" : "Place Type Created");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleCheckboxChange = (e: any) => {
    e.persist();
    const index = e.target.name;
    const place = places[index];

    if (place) {
      setPlaceChanges((prev: any) => {
        const curr = JSON.parse(JSON.stringify(prev));
        curr[place.placeId] = place;
        return curr;
      });
      setPlaces((prev: any) => {
        const curr = [...prev];
        curr[index].selected = e.target.checked;
        return curr;
      });
    }
  };

  const handleFeatureCheckboxChange = (e: any) => {
    e.persist();
    setFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const columns: any = [];

  columns.push({
    id: "selected",
    Header: "Selected",
    accessor: "selected",
    minWidth: 120,
    maxWidth: 120,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "true") {
        return row[filter.id] === true;
      }
      if (filter.value === "false") {
        return row[filter.id] === false;
      }
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
    ),
    Cell: (props: any) => <Checkbox onChange={handleCheckboxChange} name={props.index} checked={props.value} />,
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  columns.push({
    id: "placeName",
    Header: "Name",
    accessor: "placeName",
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["placeName"],
      }),
    filterAll: true,
    Cell: (props: any) => (
      <Link title={props.value} to={`/places/${props.original.placeId}`}>
        {props.value}
      </Link>
    ),
  });

  columns.push({
    id: "groupName",
    Header: "Place Type",
    accessor: "groupName",
    minWidth: 140,
    maxWidth: 140,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "none") {
        return row[filter.id] === undefined;
      }
      return row[filter.id] === filter.value;
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="none">None</option>
        {filterOptions.groupName.map((label: any) => {
          return (
            <option key={label} value={label}>
              {label}
            </option>
          );
        }, [])}
      </select>
    ),
    Cell: (props: any) => (
      <>
        <ColoredDot color={props.original.groupColour} />
        <span title={props.value}>{props.value}</span>
      </>
    ),
  });

  // exists here to sort the rows by selected and prevent them bouncing around when editing
  columns.push({
    id: "initSelected",
    accessor: "initSelected",
    show: false,
  });

  const defaultSorted = [
    {
      id: "initSelected",
      desc: true,
    },
    {
      id: "placeName",
      desc: false,
    },
  ];

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      size={!submittedMsg && !submittingErr ? "lg" : "sm"}
      title={formData.groupId ? "Edit Place Type" : "Create Place Type"}
      success={submittedMsg}
      error={submittingErr}
      submitBtnText={formData.groupId ? "Update Type" : "Create Type"}
      body={
        <LoadingContainer loading={placesLoading || submitting} err={placesErr}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer>
              <label>Type Name</label>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <FormInput type="text" name="groupName" value={formData.groupName} error={formErrors.groupName} onChange={handleChange} />
                </div>
                <div style={{ width: "100%", maxWidth: "34px" }}>
                  <ColorPicker color={formData.groupColour} setColor={(color: any) => setFormData((prev: any) => ({ ...prev, groupColour: color.hex }))} />
                </div>
              </div>
              <FormError error={formErrors.groupName}>{formErrors.groupName}</FormError>
            </ModalFormContainer>
            {isBinaryBeer() && (
              <>
                <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                  <Checkbox
                    style={{ display: "inline-block" }}
                    name="fillsBeerKegs"
                    label="Fills Beer Kegs"
                    checked={formData.fillsBeerKegs}
                    onChange={handleFeatureCheckboxChange}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginBottom: "-3px",
                    }}
                  >
                    <Tooltip
                      content="Enable if this place type fills beer kegs (e.g. brewery) This will help determine freshness and emptiness"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </Tooltip>
                  </div>
                </ModalFormContainer>
                <ModalFormContainer style={{ padding: "5px 0 19px" }}>
                  <Checkbox
                    style={{ display: "inline-block" }}
                    name="emptiesBeerKegs"
                    label="Empties Beer Kegs"
                    checked={formData.emptiesBeerKegs}
                    onChange={handleFeatureCheckboxChange}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      marginBottom: "-3px",
                    }}
                  >
                    <Tooltip
                      content="Enable if this place type empties beer kegs (e.g. venue) This will help determine freshness and emptiness"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </Tooltip>
                  </div>
                </ModalFormContainer>
              </>
            )}
            <ModalFormContainer>
              <Table
                loading={placesLoading}
                filterable={true}
                style={{ clear: "both" }}
                data={places}
                columns={columns}
                defaultSorted={defaultSorted}
                defaultPageSize={10}
              />
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default EditPlaceTypeModal;
