import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const fetchPickupRequestTypes = async (source: CancelTokenSource) => {
  const params = {
    organisationId: getAccount().organisationId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/pickuprequesttypes",
    params,
  });

  return data;
};

export const fetchPickupRequestType = async (source: CancelTokenSource, id: number) => {
  const params = {
    organisationId: getAccount().organisationId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/pickuprequesttypes/" + id,
    params,
  });

  return data;
};

export const createPickupRequestType = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/v1/pickuprequesttypes",
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};

export const editPickupRequestType = async (source: CancelTokenSource, id: string, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + `/v1/pickuprequesttypes/${id ? id : ""}`,
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};

export const deletePickupRequestType = async (source: CancelTokenSource, id: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "delete",
    url: process.env.REACT_APP_API_URL + `/v1/pickuprequesttypes/${id ? id : ""}`,
  });

  return data;
};
