import { createGlobalStyle } from "../Themes";

export default createGlobalStyle`
  .ReactTable {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.color_30.panel_fg[0]};
  }

    .ReactTableError {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border: 1px solid ${({ theme }) => theme.color.error[2]};
  }

  .ReactTable * {
    box-sizing: border-box
  }

  .ReactTable .rt-table {
    -webkit-box-flex: 1;
    -ms-flex: auto 1;
    flex: auto 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .ReactTable .rt-thead {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .ReactTable .rt-thead.-headerGroups {
    background: ${({ theme }) => theme.color.table_group_header[2]};
    border-bottom: 1px solid ${({ theme }) => theme.color_30.panel_fg[0]};
  }

  .ReactTable .rt-thead.-header {
    background: ${({ theme }) => theme.color.table_header[2]};
  }

  .ReactTable .rt-thead.-filters {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .ReactTable .rt-thead.-filters input,
  .ReactTable .rt-thead.-filters select {
    border: 1px solid ${({ theme }) => theme.color_30.panel_fg[0]};
    background: ${({ theme }) => theme.color.input_bg[2]};
    color: ${({ theme }) => theme.color.font_bold[2]};
    padding: 7px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    font-family: inherit;
    outline: none
  }

  .ReactTable .rt-thead.-filters .rt-th {
    border-right: 1px solid ${({ theme }) => theme.color_30.panel_fg[0]};
  }

  .ReactTable .rt-thead.-header {
    border-bottom: 1px solid ${({ theme }) => theme.color_30.panel_fg[0]};
  }

  .ReactTable .rt-thead .rt-tr {
    text-align: center
  }

  .ReactTable .rt-thead .rt-th,
  .ReactTable .rt-thead .rt-td {
    padding: 5px 5px;
    line-height: normal;
    position: relative;
    border-right: 1px solid ${({ theme }) => theme.color_30.panel_fg[0]};
    transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
    box-shadow: inset 0 0 0 0 transparent;
  }

  .ReactTable .rt-thead .rt-th.-sort-asc,
  .ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: inset 0 3px 0 0 ${({ theme }) => theme.color_30.panel_fg[0]};
  }

  .ReactTable .rt-thead .rt-th.-sort-desc,
  .ReactTable .rt-thead .rt-td.-sort-desc {
    box-shadow: inset 0 -3px 0 0 ${({ theme }) => theme.color_30.panel_fg[0]};
  }

  .ReactTable .rt-thead .rt-th.-cursor-pointer,
  .ReactTable .rt-thead .rt-td.-cursor-pointer {
    cursor: pointer
  }

  .ReactTable .rt-thead .rt-th:last-child,
  .ReactTable .rt-thead .rt-td:last-child {
    border-right: 0
  }

  .ReactTable .rt-thead .rt-th:focus {
    outline: none
  }

  .ReactTable .rt-thead .rt-resizable-header {
    overflow: visible;
  }

  .ReactTable .rt-thead .rt-resizable-header:last-child {
    overflow: hidden
  }

  .ReactTable .rt-thead .rt-resizable-header-content {
    overflow: hidden;
    text-overflow: ellipsis
  }

  .ReactTable .rt-resizable-header {
    padding: 6px 24px !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .ReactTable .rt-resizable-header-content:after {
    position: absolute;
    right: 8px;
    top: 3px;
    height: 18px;
    width: 18px;
    z-index: 120;
    color: transparent;
    content: ".";
  }

  .ReactTable .rt-thead .rt-header-pivot {
    border-right-color: ${({ theme }) => theme.color_30.panel_fg[0]};
  }

  .ReactTable .rt-thead .rt-header-pivot:after,
  .ReactTable .rt-thead .rt-header-pivot:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
  }

  .ReactTable .rt-thead .rt-header-pivot:after {
    border-color: ${({ theme }) => theme.color_30.panel_fg[0]};
    border-left-color: ${({ theme }) => theme.color_30.panel_fg[0]};
    border-width: 8px;
    margin-top: -8px
  }

  .ReactTable .rt-thead .rt-header-pivot:before {
    border-color: ${({ theme }) => theme.color_30.panel_fg[0]};
    border-left-color: ${({ theme }) => theme.color_30.panel_fg[0]};
    border-width: 10px;
    margin-top: -10px
  }

  .ReactTable .rt-tbody {
    -webkit-box-flex: 99999;
    -ms-flex: 99999 1 auto;
    flex: 99999 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    text-align: center;
    background: ${({ theme }) => theme.color.table_body[2]};
  }

  .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: solid 1px ${({ theme }) => theme.color_30.panel_fg[0]};
  }

  .ReactTable .rt-tbody .rt-tr-group:last-child {
    border-bottom: 0
  }

  .ReactTable .rt-tbody .rt-td {
    border-right: 1px solid ${({ theme }) => theme.color_30.panel_fg[0]};
    align-content: center;
  }

  .ReactTable .rt-tbody .rt-td:last-child {
    border-right: 0
  }

  .ReactTable .rt-tbody .rt-expandable {
    cursor: pointer;
    text-overflow: clip
  }

  .ReactTable .rt-tr-group {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
  }

  .ReactTable .rt-tr {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
  }

  .ReactTable .rt-th,
  .ReactTable .rt-td {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 0px;
    flex: 1 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 7px 5px;
    overflow: hidden;
    transition: .3s ease;
    transition-property: width, min-width, padding, opacity;
  }

  .ReactTable .rt-th.-hidden,
  .ReactTable .rt-td.-hidden {
    width: 0 !important;
    min-width: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    opacity: 0 !important
  }

  .ReactTable .rt-expander {
    display: inline-block;
    position: relative;
    margin: 0;
    color: transparent;
    margin: 0 10px;
  }

  .ReactTable .rt-expander:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%) rotate(-90deg);
    transform: translate(-50%, -50%) rotate(-90deg);
    border-left: 5.04px solid transparent;
    border-right: 5.04px solid transparent;
    border-top: 7px solid ${({ theme }) => theme.color_30.panel_fg[0]};
    transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
    cursor: pointer
  }

  .ReactTable .rt-expander.-open:after {
    -webkit-transform: translate(-50%, -50%) rotate(0);
    transform: translate(-50%, -50%) rotate(0)
  }

  .ReactTable .rt-resizer {
    display: inline-block;
    position: absolute;
    width: 36px;
    top: 0;
    bottom: 0;
    right: -18px;
    cursor: col-resize;
    z-index: 10
  }

  .ReactTable .rt-resizer:before {
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 7px;
    height: 18px;
    width: 18px;
    color: transparent;
    content: ".";
    background-size: 18px 18px;
    background-repeat: no-repeat;
    opacity: 0.87;
  }
    
  .ReactTable .rt-th.-sort-asc .rt-resizer:before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301 301"><path fill="${({ theme }) =>
      encodeURIComponent(
        theme.color.font[2]
      )}" d="M160 283v-10c0-6-4-10-10-10H11c-6 0-10 4-10 10v10c0 5 4 10 10 10h139c6 0 10-5 10-10zm-28-84H11c-6 0-10 4-10 10v10c0 5 4 10 10 10h121c5 0 10-5 10-10v-10c0-6-5-10-10-10zm-19-69H11c-6 0-10 4-10 10v10c0 5 4 10 10 10h102c5 0 10-5 10-10v-10c0-6-5-10-10-10zM94 67H11C5 67 1 72 1 77v10c0 6 4 10 10 10h83c6 0 10-4 10-10V77c0-5-4-10-10-10zM75 7H11C5 7 1 12 1 17v10c0 6 4 10 10 10h64c6 0 10-4 10-10V17c0-5-4-10-10-10zm83 82l7 7a10 10 0 0014 0l35-34v225c0 6 4 10 10 10h10c5 0 10-4 10-10V62l34 34a10 10 0 0014 0l7-7c4-4 4-10 0-14l-63-63a10 10 0 00-7-3 10 10 0 00-7 3l-64 63c-4 4-4 10 0 14z"/></svg>');
  }
  
  .ReactTable .rt-th.-sort-desc .rt-resizer:before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301 301"><path fill="${({ theme }) =>
      encodeURIComponent(
        theme.color.font[2]
      )}" d="M159 24V14c0-6-4-10-10-10H10C4 4 0 8 0 14v10c0 5 4 10 10 10h139c6 0 10-5 10-10zM131 67H10C4 67 0 71 0 77v10c0 5 4 10 10 10h121c5 0 10-5 10-10V77c0-6-5-10-10-10zM112 130H10c-6 0-10 4-10 10v10c0 5 4 10 10 10h102c5 0 10-5 10-10v-10c0-6-5-10-10-10zM93 200H10c-6 0-10 4-10 10v10c0 5 4 10 10 10h83c6 0 10-5 10-10v-10c0-6-4-10-10-10zM74 263H10c-6 0-10 4-10 10v10c0 5 4 10 10 10h64c6 0 10-5 10-10v-10c0-6-4-10-10-10zM298 217l-7-7a10 10 0 0 0-14 0l-34 34V19c0-6-5-10-10-10h-10c-6 0-10 4-10 10v225l-35-34a10 10 0 0 0-14 0l-7 7c-4 4-4 10 0 14l64 64a10 10 0 0 0 14 0l63-64c4-4 4-10 0-14z"/></svg>');
  }

  .ReactTable .rt-tfoot {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    border-top: 1px solid ${({ theme }) => theme.color_30.panel_fg[0]};
    background: ${({ theme }) => theme.color.table_header[2]};
  }

  .ReactTable .rt-tfoot .rt-td {
    border-right: 1px solid ${({ theme }) => theme.color_30.panel_fg[0]};
  }

  .ReactTable .rt-tfoot .rt-td:last-child {
    border-right: 0
  }

  .ReactTable.-striped .rt-tr.-odd {
    background: ${({ theme }) => theme.color_30.panel_fg[0]};
  }

  .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
    background: ${({ theme }) => theme.color_30.panel_fg[0]};
  }

  .ReactTable .-pagination {
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 3px;
    border-top: 1px solid ${({ theme }) => theme.color_30.panel_fg[0]};
    background: ${({ theme }) => theme.color.table_header[2]};
  }

  .ReactTable .-pagination input,
  .ReactTable .-pagination select {
    border: 1px solid ${({ theme }) => theme.color_30.panel_fg[0]};
    background: ${({ theme }) => theme.color_30.panel_fg[1]};
    padding: 7px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none
  }

  .ReactTable .-pagination .-btn {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 3px;
    padding: 6px;
    font-size: 1em;
    color: ${({ theme }) => theme.color.font_bold[2]};
    background: ${({ theme }) => theme.color_30.panel_fg[1]};
    transition: all .1s ease;
    cursor: pointer;
    outline: none;
  }

  .ReactTable .-pagination .-btn[disabled] {
    opacity: .5;
    cursor: default
  }

  .ReactTable .-pagination .-btn:not([disabled]):hover {
    background: ${({ theme }) => theme.color_30.panel_fg[4]};
    color: ${({ theme }) => theme.color.font_bold[2]};
  }

  .ReactTable .-pagination .-previous,
  .ReactTable .-pagination .-next {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center
  }

  .ReactTable .-pagination .-center {
    -webkit-box-flex: 1.5;
    -ms-flex: 1.5;
    flex: 1.5;
    text-align: center;
    margin-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around
  }

  .ReactTable .-pagination .-pageInfo {
    display: inline-block;
    margin: 3px 10px;
    white-space: nowrap
  }

  .ReactTable .-pagination .-pageJump {
    display: inline-block;
  }

  .ReactTable .-pagination .-pageJump input {
    width: 70px;
    text-align: center
  }

  .ReactTable .-pagination .-pageSizeOptions {
    margin: 3px 10px
  }

  .ReactTable .rt-noData {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: ${({ theme }) => theme.color_30.panel_fg[0]};
    transition: all .3s ease;
    z-index: 1;
    pointer-events: none;
    padding: 20px;
    color: ${({ theme }) => theme.color_30.panel_fg[0]};
  }

  .ReactTable .-loading {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: ${({ theme }) => theme.color_30.panel_fg[0]};
    transition: all .3s ease;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }

  .ReactTable .-loading > div {
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 0;
    font-size: 15px;
    color: ${({ theme }) => theme.color_30.panel_fg[0]};
    -webkit-transform: translateY(-52%);
    transform: translateY(-52%);
    transition: all .3s cubic-bezier(.25, .46, .45, .94)
  }

  .ReactTable .-loading.-active {
    opacity: 1;
    z-index: 2;
    pointer-events: all;
  }

  .ReactTable .-loading.-active > div {
    -webkit-transform: translateY(50%);
    transform: translateY(50%)
  }

  .ReactTable .rt-resizing .rt-th,
  .ReactTable .rt-resizing .rt-td {
    transition: none !important;
    cursor: col-resize;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
  }
`;
