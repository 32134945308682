import { FC, useContext } from "react";
import { IconContext } from "react-icons";
import { FaTools } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import Bold from "../Bold";
import { PageContainer } from "../PageStyles";
import PageTitle from "../PageTitle";
import { Panel } from "../Panel";
import { CardBody, CardContainer, CardHeading, CardIcon, CardText, ReportCard, ReportPanelText } from "./styles";
import { isBinaryMed } from "../../util/checkDomain";

const AdminReportsScreen: FC<any> = () => {
  const { color } = useContext(ThemeContext);

  const adminReports = [
    {
      slug: "/admin-reports/long-gaps-in-updates",
      title: `Long Gaps in Updates`,
      meta: `Long gaps in updates.`,
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <FaTools />
        </IconContext.Provider>
      ),
    },
    {
      slug: "/admin-reports/last-seen-over-7-days-ago",
      title: `Last Seen Over 7 Days Ago`,
      meta: "Trackers that have gone missing. No valid sample in the last 7 days.",
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <FaTools />
        </IconContext.Provider>
      ),
    },
    {
      slug: "/admin-reports/low-batteries",
      title: `Low Batteries`,
      meta: `Trackers with low batteries that may need replacing.`,
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <FaTools />
        </IconContext.Provider>
      ),
    },
    {
      slug: "/admin-reports/tracker-events",
      title: `Switch Events and Reboots`,
      meta: `Tracker events such as the enclosure opening, magnet wakeups, or reboots.`,
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <FaTools />
        </IconContext.Provider>
      ),
    },
    {
      slug: "/admin-reports/phone-scans",
      title: `Phone Scans`,
      meta: `Phone Scans.`,
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <FaTools />
        </IconContext.Provider>
      ),
    },
    {
      slug: "/admin-reports/tracker-calibration",
      title: `Tracker Calibration`,
      meta: `Tracker Calibration.`,
      icon: (
        <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
          <FaTools />
        </IconContext.Provider>
      ),
    },
    ...(isBinaryMed()
      ? [
          {
            slug: "/admin-reports/tracker-services-report",
            title: `Tracker Services Report`,
            meta: `Tracker Services Report.`,
            icon: (
              <IconContext.Provider value={{ color: color.primary[2], size: "40px" }}>
                <FaTools />
              </IconContext.Provider>
            ),
          },
        ]
      : []),
  ];

  const generateReportCard = (reports: any) => {
    return reports.map((report: any, index: number) => (
      <ReportCard key={index}>
        <Link key={index} to={report.slug} style={{ textDecoration: "none" }}>
          <CardContainer>
            <CardIcon>{report.icon}</CardIcon>
            <CardText>
              <CardHeading>{report.title}</CardHeading>
              <CardBody>{report.meta}</CardBody>
            </CardText>
          </CardContainer>
        </Link>
      </ReportCard>
    ));
  };

  return (
    <>
      <PageTitle title="Admin Reports" />
      <PageContainer>
        <Panel>
          <ReportPanelText>
            <Bold>Admin Reports:</Bold> Additional reports for tracker operations
          </ReportPanelText>
          <div style={{ display: "flex", flexFlow: "wrap" }}>{generateReportCard(adminReports)}</div>
        </Panel>
      </PageContainer>
    </>
  );
};

export default AdminReportsScreen;
