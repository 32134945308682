import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { postForgotPassword } from "../../services/forgotPassword";

const ResetPasswordModal: FC<any> = ({ user, modalOpen, setModalOpen }) => {
  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleSubmit = () => {
    setSubmitting(true);

    const body = {
      email: user.email,
    };

    postForgotPassword(source, body)
      .then(() => {
        setSubmittedMsg("Reset Password Link Sent");
        setSubmittingErr("");
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmittingErr(errToStr(err));
        setSubmitting(false);
      });
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) {
          setSubmittingErr("");
          setModalOpen(false);
        }
      }}
      title="Send Password Reset Link"
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Send Link"
      body={
        <LoadingContainer loading={submitting}>
          <span>Are you sure you want to send a password reset link to {user.email}?</span>
        </LoadingContainer>
      }
    />
  );
};

export default ResetPasswordModal;
