import React, { FC, useState, useEffect } from "react";
import RangeSlider from "../RangeSlider";
import { ModalFilterContainer, ModalFilterHeader } from "./styles";
import { kegsOrTrackers } from "../../util/kegOrTracker";

// Sets the initial values of the range slider if there are URL query parameters.
// If there are none we use the default initial values of 0 - Infinity
const getValues = (sensorFilters: any) => {
  const { minDaysInPlace, maxDaysInPlace } = sensorFilters;
  const values = [-Infinity, Infinity];

  if (minDaysInPlace) {
    values[0] = +minDaysInPlace;
  }
  if (maxDaysInPlace) {
    values[1] = +maxDaysInPlace;
  }

  return values;
};

const DaysinPlace: FC<any> = ({ sensorsRanges, sensorFilters, setModalQuery }) => {
  const [minMaxRange, setMinMaxRange] = useState<any>([0, 9999]);
  const [values, setValues] = useState<any>(getValues(sensorFilters));
  const [initialValuesSet, toggleInitialValuesSet] = useState<any>([values[0] !== -Infinity, values[1] !== Infinity]);

  useEffect(() => {
    if (sensorsRanges.daysInPlace && sensorsRanges.daysInPlace.length === 2) {
      const min = Math.floor(sensorsRanges.daysInPlace[0]);
      const max = Math.ceil(sensorsRanges.daysInPlace[1]);
      setMinMaxRange([min, max]);

      if (!initialValuesSet[0]) {
        setValues((prev: any) => [min, prev[1]]);
        toggleInitialValuesSet((prev: any) => [true, prev[1]]);
      }

      if (!initialValuesSet[1]) {
        setValues((prev: any) => [prev[0], max]);
        toggleInitialValuesSet((prev: any) => [prev[0], true]);
      }
    }
  }, [sensorsRanges, initialValuesSet]);

  // This is to update the values and button state when a user changes the query
  // parameters (e.g. when a user navigates using the back/forward buttons).
  // Each time the sensorFilters change (whenever the place query parameters change),
  // if the new values don't match the current values, update the values and button state
  useEffect(() => {
    const newValues = getValues(sensorFilters);
    if (JSON.stringify(values) !== JSON.stringify(newValues)) {
      setValues(newValues);
    }
  }, [sensorFilters]);

  // On initial render, sets the modal query string state in
  // the parent component to the initial values
  useEffect(() => {
    updateModalQuery(values);
  }, []);

  const updateModalQuery = (values: any) => {
    const minVal = values[0] === minMaxRange[0] ? -Infinity : values[0];
    const maxVal = values[1] === minMaxRange[1] ? Infinity : values[1];

    if (minVal !== -Infinity && maxVal !== Infinity) {
      setModalQuery((prev: any) => ({
        ...prev,
        minDaysInPlace: minVal,
        maxDaysInPlace: maxVal,
      }));
    } else if (minVal !== -Infinity) {
      setModalQuery((prev: any) => ({
        ...prev,
        minDaysInPlace: minVal,
        maxDaysInPlace: undefined,
      }));
    } else if (maxVal !== Infinity) {
      setModalQuery((prev: any) => ({
        ...prev,
        minDaysInPlace: undefined,
        maxDaysInPlace: maxVal,
      }));
    } else {
      setModalQuery((prev: any) => ({
        ...prev,
        minDaysInPlace: undefined,
        maxDaysInPlace: undefined,
      }));
    }
  };

  return (
    <ModalFilterContainer>
      <ModalFilterHeader>Days {kegsOrTrackers("a keg", "a tracker")} has been at a place</ModalFilterHeader>
      <RangeSlider
        unit="days"
        value={values}
        minRange={minMaxRange[0]}
        maxRange={minMaxRange[1]}
        exceedsMax={true}
        setMinVal={(val: any) => {
          setValues((prev: any) => [val, prev[1]]);
          updateModalQuery([val, values[1]]);
        }}
        setMaxVal={(val: any) => {
          setValues((prev: any) => [prev[0], val]);
          updateModalQuery([values[0], val]);
        }}
        onChange={(props: any) => {
          setValues(props);
        }}
        onAfterChange={(props: any) => {
          updateModalQuery(props);
        }}
      />
    </ModalFilterContainer>
  );
};

export default DaysinPlace;
