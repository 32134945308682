import styled from "../Themes";

export const Color = styled<any>(`div`)`
  width: 24px;
  height: 24px;
  border-radius: 3px;
  background: ${({ color }) => color};
`;

export const Swatch = styled<any>(`div`)`
  padding: 4px;
  background: ${({ theme }) => theme.color.input_bg[2]};
  border-radius: 3px;
  border: 1px solid ${({ theme, error }) => (error ? theme.color.error[2] : theme.color_30.panel_fg[0])};
  display: inline-block;
  cursor: pointer;
  outline: none;

  ::placeholder {
    opacity: 0.5;
  }

  &:hover {
    border: 1px solid ${({ theme, error }) => (error ? theme.color.error[2] : theme.color_80.panel_fg[0])};
  }

  :focus {
    box-shadow: 0 0 0 1px ${({ theme }) => theme.color.primary[2]};
  }

  :disabled {
    opacity: 0.33;
  }
`;

export const Popover = styled<any>(`div`)`
  position: absolute;
  z-index: 200;
  right: 20px;
`;

export const Cover = styled<any>(`div`)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
