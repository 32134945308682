import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const fetchTrackerCalibrationReport = async (
  source: CancelTokenSource,
  trackerIds: string,
  appliedTemperatures: any,
  startDate?: string,
  endDate?: string
) => {
  const body = {
    organisationId: getAccount().organisationId,
    startDate,
    endDate,
    trackerIds,
    appliedTemperatures,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/v1/calibrationReport",
    data: JSON.stringify({
      ...body,
    }),
  });

  return data;
};
