import React, { FC, useState, useEffect, useCallback, useRef } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { FormInput, FormError } from "../FormComponents";
import { exists, maxLength } from "../../util/formValidations";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { editContents } from "../../services/contents";
import { OutlineBtn, PrimaryBtn } from "../Buttons";
import { QrScanButton } from "./styles";
import Quagga, { QuaggaJSCodeReader, QuaggaJSResultObject } from "@ericblade/quagga2";
// @ts-ignore
import QrCodeReader from "@ericblade/quagga2-reader-qr";
import sortTags from "../../util/sortTags";
import { AsyncCreatableSelect } from "../Select";
import { fetchAutoComplete } from "../../services/autoComplete";

Quagga.registerReader("qr_code", QrCodeReader);

function getMedian(arr: any[]) {
  arr.sort((a: number, b: number) => a - b);
  const half = Math.floor(arr.length / 2);
  if (arr.length % 2 === 1) {
    return arr[half];
  }
  return (arr[half - 1] + arr[half]) / 2;
}

function getMedianOfCodeErrors(decodedCodes: any[]) {
  const errors = decodedCodes.filter((x) => x.error !== undefined).map((x) => x.error);
  const medianOfErrors = getMedian(errors);
  return medianOfErrors;
}

const defaultConstraints = {
  width: 640,
  height: 480,
};

const defaultLocatorSettings = {
  patchSize: "medium",
  halfSample: true,
  willReadFrequently: true,
};

const defaultDecoders: (QuaggaJSCodeReader | "qr_code")[] = [
  "qr_code",
  "code_128_reader",
  "ean_reader",
  "ean_8_reader",
  "code_39_reader",
  "code_39_vin_reader",
  "codabar_reader",
  "upc_reader",
  "upc_e_reader",
  "i2of5_reader",
  "2of5_reader",
  "code_93_reader",
];

const defaultItem = {
  id: null,
  identifier: "",
  name: "",
  description: "",
  placeAdded: null,
  contentTags: [],
};

const initItemForm = (item: any) => {
  const formData = {
    ...defaultItem,
    ...item,
    placeAdded: item.placeAddedId
      ? {
          value: item.placeAddedId,
          label: item.placeAddedName,
        }
      : null,
    contentTags:
      item.contentTags && item.contentTags.length > 0
        ? item.contentTags.sort(sortTags).map((tag: any) => ({ value: tag.id, label: tag.name, colour: tag.colour, description: tag.description }))
        : [],
  };

  return formData;
};

const EditContentsModal: FC<any> = ({ manifestId, item, onSuccess, modalOpen, setModalOpen }) => {
  const [formData, setFormData] = useState<any>(initItemForm(item));
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [scanning, setScanning] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  const scannerRef = useRef<any>(undefined);

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // On barcode detect, add result to ID input and stop scanning
  const onDetected = (result: any) => {
    setFormData((prev: any) => ({ ...prev, identifier: result }));
    setScanning(false);
  };

  const errorCheck = useCallback(
    (result: { codeResult: { decodedCodes: any[]; code: any } }) => {
      if (!onDetected) {
        return;
      }
      // @ts-ignore
      if (result.codeResult.format !== "qr_code") {
        const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
        // if Quagga is at least 92% certain that it read correctly, then accept the code.
        if (err < 0.08) {
          onDetected(result.codeResult.code);
        }
      } else {
        onDetected(result.codeResult.code);
      }
    },
    [onDetected]
  );

  const handleProcessed = (result: QuaggaJSResultObject) => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;
    drawingCtx.font = "24px Arial";
    drawingCtx.fillStyle = "green";

    if (result) {
      if (result.boxes) {
        // @ts-ignore
        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
        result.boxes
          .filter((box: any) => box !== result.box)
          .forEach((box: any[]) => {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "purple", lineWidth: 2 });
          });
      }
      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "blue", lineWidth: 2 });
      }
    }
  };

  useEffect(() => {
    if (scanning === false) {
      Quagga.offDetected(errorCheck);
      Quagga.offProcessed(handleProcessed);
      Quagga.stop();
    } else {
      Quagga.init(
        {
          inputStream: {
            type: "LiveStream",
            constraints: defaultConstraints,
            target: scannerRef.current,
            willReadFrequently: true,
          },
          locator: defaultLocatorSettings,
          numOfWorkers: navigator.hardwareConcurrency || 0,
          // @ts-ignore
          decoder: { readers: defaultDecoders },
          locate: true,
          debug: false,
        },
        (err) => {
          Quagga.onProcessed(handleProcessed);

          if (err) {
            return console.log("Error starting Quagga:", err);
          }
          if (scannerRef && scannerRef.current) {
            Quagga.start();
          }
        }
      );
      Quagga.onDetected(errorCheck);
      return () => {
        Quagga.offDetected(errorCheck);
        Quagga.offProcessed(handleProcessed);
        Quagga.stop();
      };
    }
  }, [scanning, onDetected, scannerRef, errorCheck]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "identifier":
          currValid = maxLength(name, value, 256, setFormErrors);
          break;

        case "name":
          currValid = maxLength(name, value, 256, setFormErrors);
          break;

        case "description":
          currValid = maxLength(name, value, 1024, setFormErrors);
          break;

        case "placeAdded":
          currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    return {
      manifestId,
      identifier: formData.identifier.trim(),
      name: formData.name.trim(),
      description: formData.description.trim(),
      placeAdded: formData.placeAdded && formData.placeAdded.value !== undefined ? formData.placeAdded.value : null,
      contentTags: formData.contentTags ? formData.contentTags.map((tag: any) => tag.label) : [],
    };
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      editContents(source, formData.id, body)
        .then((response) => {
          onSuccess(response);
          setSubmittedMsg("Contents Updated");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  const handleEnableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setSubmittedMsg("");
    setSubmittingErr("");
    setScanning(true);
  };

  const handleDisableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setScanning(false);
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={handleSubmit}
      onClose={handleClose}
      title="Edit Contents"
      success={submittedMsg}
      error={submittingErr}
      body={
        <LoadingContainer loading={submitting}>
          <div
            style={{
              display: scanning ? "flex" : "none",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div ref={scannerRef} style={{ position: "relative" }}>
              <canvas
                className="drawingBuffer"
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: "0",
                }}
              />
            </div>
          </div>
          <div style={{ display: scanning ? "none" : "flex" }}>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <ModalFormContainer>
                <label>ID</label>
                <QrScanButton title="Scan QR Code" onClick={handleEnableScan} />
                <FormInput
                  type="text"
                  name="identifier"
                  placeholder="XXXXXX"
                  value={formData.identifier}
                  error={formErrors.identifier}
                  onChange={handleChange}
                  required={true}
                />
                <FormError error={formErrors.identifier}>{formErrors.identifier}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label>Name</label>
                <FormInput type="text" name="name" placeholder="Name" value={formData.name} error={formErrors.name} onChange={handleChange} required={true} />
                <FormError error={formErrors.name}>{formErrors.name}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label>Description</label>
                <FormInput
                  type="text"
                  name="description"
                  placeholder="Description"
                  value={formData.description}
                  error={formErrors.description}
                  onChange={handleChange}
                />
                <FormError error={formErrors.description}>{formErrors.description}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label>Content Tags</label>
                <AsyncCreatableSelect
                  name="contentTags"
                  defaultOptions={true}
                  isMulti={true}
                  isClearable={true}
                  isError={formErrors.contentTags}
                  value={formData.contentTags}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("contentTags", inputValue, callback)}
                  onChange={handleSelectChange}
                  placeholder="Select..."
                />
                <FormError error={formErrors.contentTags}>{formErrors.contentTags}</FormError>
              </ModalFormContainer>
            </form>
          </div>
        </LoadingContainer>
      }
      footer={
        submittedMsg ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : submittingErr ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : (
          <>
            {scanning ? (
              <OutlineBtn onClick={handleDisableScan} width="100%">
                Back
              </OutlineBtn>
            ) : (
              <>
                <OutlineBtn
                  onClick={() => {
                    if (!submitting) setModalOpen(false);
                  }}
                >
                  Cancel
                </OutlineBtn>
                <PrimaryBtn onClick={() => handleSubmit()}>Update</PrimaryBtn>
              </>
            )}
          </>
        )
      }
    />
  );
};

export default EditContentsModal;
