import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import ReactTimeago from "react-timeago";
import { ThemeContext } from "styled-components";
import { getTrackerInfo } from "../../services/trackerInfo";
import roundBatteryPercent from "../../util/roundBatteryPercent";
import BatteryIcon from "../BatteryIcon";
import SignalIcon from "../SignalIcon";
import TrackerMaintenanceAlert from "../TrackerMaintenanceAlert";
import { ContentContainer, Header, Panel, Value, ValueContainer } from "./styles";

const TrackerInfoPanel: FC<any> = ({ trackerId }) => {
  const { color, long_datetime } = useContext(ThemeContext);

  const [trackerInfo, setTrackerInfo] = useState<any>(undefined);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    if (trackerId) {
      getTrackerInfo(source, trackerId)
        .then((x) => {
          setTrackerInfo({
            ...x,
            lateUpload:
              x.sleepCount == null || x.sleepDuration == null ? false : moment().unix() > x.latestSampleDateUnix + x.sleepCount * x.sleepDuration * 60,
            missedUploadCount:
              x.sleepCount == null || x.sleepDuration == null
                ? 0
                : Math.floor((moment().unix() - x.latestSampleDateUnix) / (x.sleepCount * x.sleepDuration * 60)),
            batteryPercentage: roundBatteryPercent(x.batteryPercentage),
          });
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setTrackerInfo(undefined);
          }
        });
    } else {
      setTrackerInfo(undefined);
    }
  }, [trackerId]);

  const lastSeenColour = useMemo(() => {
    if (trackerInfo) {
      if (trackerInfo.missedUploadCount === 0) {
        return color.great[2];
      } else if (trackerInfo.missedUploadCount === 1) {
        return color.good[2];
      } else if (trackerInfo.missedUploadCount === 2) {
        return color.bad[2];
      } else if (trackerInfo.missedUploadCount > 2) {
        return color.worse[2];
      } else {
        return color.worse[2];
      }
    } else {
      return color.worse[2];
    }
  }, [trackerInfo, color]);

  if (trackerInfo != null) {
    return (
      <>
        <Panel>
          {trackerInfo.batteryPercentage != null && (
            <ContentContainer style={{ height: "unset", marginTop: "6px" }}>
              <ValueContainer style={{ margin: 0, padding: "0 20px", alignItems: "center", justifyContent: "start", height: "60px" }}>
                <Header>Battery</Header>
                <Value style={{ alignSelf: "center", textAlign: "center" }}>
                  <BatteryIcon batteryPercentage={trackerInfo.batteryPercentage} />
                </Value>
              </ValueContainer>
            </ContentContainer>
          )}
          <ContentContainer style={{ height: "unset", marginTop: "6px" }}>
            <ValueContainer style={{ margin: 0, padding: "0 20px", alignItems: "center", justifyContent: "start", height: "60px" }}>
              <Header style={{ marginBottom: "6px" }}>Last Seen</Header>
              <Value style={{ alignSelf: "center", textAlign: "center", color: lastSeenColour }}>
                {trackerInfo.latestSampleDateUnix != null ? (
                  <ReactTimeago
                    live={false}
                    date={trackerInfo.latestSampleDateUnix * 1000}
                    title={moment.unix(trackerInfo.latestSampleDateUnix).format(long_datetime)}
                  />
                ) : (
                  "Never"
                )}
              </Value>
            </ValueContainer>
          </ContentContainer>
          {trackerInfo.csq != null && (
            <ContentContainer style={{ height: "unset", marginTop: "6px" }}>
              <ValueContainer style={{ margin: 0, padding: "0 20px", alignItems: "center", justifyContent: "start", height: "60px" }}>
                <Header>Signal</Header>
                <Value style={{ alignSelf: "center", textAlign: "center" }}>
                  <SignalIcon csq={trackerInfo.csq} />
                </Value>
              </ValueContainer>
            </ContentContainer>
          )}
        </Panel>
        <TrackerMaintenanceAlert missedUploadCount={trackerInfo.missedUploadCount} batteryPercentage={trackerInfo.batteryPercentage} />
      </>
    );
  } else {
    return <></>;
  }
};

export default TrackerInfoPanel;
