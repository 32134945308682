import React, { FC } from "react";
import { PageContainer } from "../PageStyles";
import { Panel } from "../Panel";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import TrackersTable from "../TrackersTable";
import TrackerTagsTable from "../TrackerTagsTable";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import AssetTypesTable from "../AssetTypesTable";

const TrackersScreen: FC<any> = () => {
  return (
    <>
      <Tabs>
        <TabList className="react-tabs__tab-list__background_no-margin">
          <Tab>{kegsOrTrackers("Kegs", "Trackers")}</Tab>
          <Tab>Asset Types</Tab>
          <Tab>{kegsOrTrackers("Keg Tags", "Tracker Tags")}</Tab>
        </TabList>
        <TabPanel>
          <PageContainer>
            <Panel>
              <TrackersTable />
            </Panel>
          </PageContainer>
        </TabPanel>
        <TabPanel>
          <PageContainer>
            <Panel>
              <AssetTypesTable />
            </Panel>
          </PageContainer>
        </TabPanel>
        <TabPanel>
          <PageContainer>
            <Panel>
              <TrackerTagsTable />
            </Panel>
          </PageContainer>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default TrackersScreen;
