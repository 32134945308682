import axios, { CancelTokenSource } from "axios";

type GetManifestsProps = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: any;
  filters?: any;
};

export const getManifests = async (source: CancelTokenSource, { pageIndex, pageSize, orderBy, filters }: GetManifestsProps) => {
  const params: any = {
    count: true,
  };

  if (pageIndex !== undefined && pageSize !== undefined) params.skip = pageIndex * pageSize;

  if (pageSize !== undefined) params.top = pageSize;

  if (orderBy && orderBy.length > 0) params.orderby = orderBy.map((order: any) => `${order.id} ${order.desc ? "desc" : "asc"}`).join(", ");

  if (filters) params.filter = filters;

  const { data, headers } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/manifests",
    params,
  });

  return { count: headers?.count, data };
};

export const getManifest = async (source: CancelTokenSource, id: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + `/v1/manifests/${id ? id : ""}`,
  });

  return data;
};

// create manifest
export const postManifest = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + `/v1/manifests`,
    data: JSON.stringify(body),
  });

  return data;
};

// update manifest
export const putManifest = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + `/v1/manifests/UpdateManifest`,
    data: JSON.stringify(body),
  });

  return data;
};

// end manifest
export const endManifest = async (source: CancelTokenSource, manifestId: string, endPlaceId: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + `/v1/manifests/CloseManifest/?manifestId=${manifestId}&endPlaceId=${endPlaceId}`,
  });

  return data;
};

// mark manifest for pickup
export const markManifestForPickup = async (source: CancelTokenSource, manifestId: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + `/v1/manifests/MarkForPickup/?manifestId=${manifestId}`,
  });

  return data;
};
