import axios, { CancelTokenSource } from "axios";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import Avatar from "react-avatar";
import { IconContext } from "react-icons";
import { BsQrCode } from "react-icons/bs";
import { FaMapMarkerAlt, FaTools, FaUsers } from "react-icons/fa";
import { IoIosBeer } from "react-icons/io";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { hideAll } from "tippy.js";
import { fetchAutoComplete } from "../../services/autoComplete";
import { fetchEditAccount } from "../../services/editAccount";
import { fetchEditOrganisation, putEditOrganisation } from "../../services/editOrganisation";
import { getAccount, saveAccount, updateOrganisationName } from "../../services/localStorage";
import { deletePhoto } from "../../services/photo";
import BinaryBeerBigLogo from "../../svgs/BinaryBeerBigLogo";
import CameraIcon from "../../svgs/CameraIcon";
import CubLogo from "../../svgs/CubLogo";
import FiveBarrelLogo from "../../svgs/FiveBarrelLogo";
import InfoIcon from "../../svgs/Legend";
import SensorIcon from "../../svgs/SensorIcon";
import StoneWoodLogo from "../../svgs/StoneWoodLogo";
import { isBinaryBeer, isBinaryMed } from "../../util/checkDomain";
import { isAdmin } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import { exists, maxLength, min } from "../../util/formValidations";
import { DangerAlert, SuccessAlert } from "../Alerts";
import { PrimaryBtn } from "../Buttons";
import Checkbox from "../Checkbox";
import DeleteModal from "../DeleteModal";
import { FormContainer, FormError, FormInput, FormInputContainer, InfoIconContainer, InputUnitWrapper } from "../FormComponents";
import { HorizontalLine } from "../HorizontalLine";
import LoadingContainer from "../LoadingContainer";
import PhotoModal from "../PhotoModal";
import ScreenHeading from "../ScreenHeading";
import { AsyncSelect, Select } from "../Select";
import StateContext from "../StateContext";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import UploadPhotoModal from "../UploadPhotoModal";
import { Block, BlockIconContainer, BlockSpan, CameraIconContainer, UpdateImageButton, UpdateImageButtonContainer } from "./styles";

const defaultOrgData = {
  allowPublicButtons: false,
  allowPublicScans: false,
  orgDomain: null,
  beerCount: 0,
  enabled: true,
  name: "",
  placeCount: 0,
  sensorCount: 0,
  userCount: 0,
  defaultKegType: null,
  enableTrackerServiceFrequency: false,
  trackerServiceFrequencyMonths: 12,
};

const initOrgForm = (org?: any) => {
  if (org) {
    // e.g. app.binarybeer.io -> ["app", "binarybeer", "io"]
    const orgDomainArray = org.orgDomain !== undefined ? org.orgDomain.split(".") : [];
    return {
      ...defaultOrgData,
      ...org,
      orgDomain: org.orgDomain
        ? {
            value: orgDomainArray[1] + "." + orgDomainArray[2],
            label: orgDomainArray[1] + "." + orgDomainArray[2],
          }
        : null,
      defaultKegType:
        org.defaultKegTypeId && org.defaultKegTypeName
          ? {
              value: org.defaultKegTypeId,
              label: org.defaultKegTypeName,
            }
          : null,
      enableTrackerServiceFrequency: org.trackerServiceFrequencyMonths > 0,
      trackerServiceFrequencyMonths: org.trackerServiceFrequencyMonths === 0 ? 12 : org.trackerServiceFrequencyMonths,
    };
  } else {
    return defaultOrgData;
  }
};

const orgDomainOptions = [
  { label: "binarytech.io", value: "binarytech.io" },
  { label: "binarybeer.io", value: "binarybeer.io" },
  { label: "binaryloop.io", value: "binaryloop.io" },
  { label: "binarymed.io", value: "binarymed.io" },
];

const OrganisationScreen: FC<any> = () => {
  const { color } = useContext(ThemeContext);
  const { forceUpdate } = useContext(StateContext);

  const photoMenuRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const [accountInfo, setAccountInfo] = useState<any>(getAccount());

  const [inputPhoto, setInputPhoto] = useState<any>(undefined);
  const [photoModalOpen, setPhotoModalOpen] = useState<boolean>(false);

  const [uploadPhotoModalOpen, setUploadPhotoModalOpen] = useState<boolean>(false);
  const [deletePhotoModalOpen, setDeletePhotoModalOpen] = useState<boolean>(false);

  const [Logo, setLogo] = useState<any>(<></>);

  const [data, setData] = useState<any>(initOrgForm());
  const [formErrors, setFormErrors] = useState<any>({});
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [orgSubmitted, setOrgSubmitted] = useState<boolean>(false);
  const [orgSubmittingErr, setOrgSubmittingErr] = useState<string>("");
  const [orgSubmitting, setOrgSubmitting] = useState<boolean>(false);

  const [orgPhotoLoading, setOrgPhotoLoading] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const getLogo = useCallback(
    (org: string) => {
      switch (org) {
        case "CUB":
          return <CubLogo fill={color.font[4]} />;

        case "Five Barrels":
          return <FiveBarrelLogo fill={color.font[4]} />;

        case "Stone & Wood":
          return <StoneWoodLogo fill={color.font[4]} />;

        case "Developers":
          return <BinaryBeerBigLogo fill={color.font[4]} />;

        default:
          return org;
      }
    },
    [color.font]
  );

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchEditOrganisation(source, accountInfo.organisationId)
      .then((response) => {
        setLogo(getLogo(response.name));
        setData(initOrgForm(response));
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [accountInfo.organisationId, getLogo, source]);

  const validateForm = () => {
    const names = Object.keys(data);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = data[names[i]];

      switch (name) {
        case "name":
          currValid = exists(name, value, setFormErrors) && maxLength(name, value, 200, setFormErrors);
          break;

        case "orgDomain":
          currValid = exists(name, value ? value.value : undefined, setFormErrors);
          break;

        case "trackerServiceFrequencyMonths":
          currValid = min(name, value, 1, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const handleOrgSubmit = (e: any) => {
    e.persist();
    e.preventDefault();

    const valid = validateForm();

    if (valid) {
      const subdomain = window.location.hostname.split(".").length === 3 ? window.location.hostname.split(".")[0] : "staging";

      const body = {
        id: data.id,
        name: data.name,
        allowPublicButtons: data.allowPublicButtons,
        allowPublicScans: data.allowPublicScans,
        orgDomain: subdomain + "." + data.orgDomain.value,
        defaultKegTypeId: null,
        trackerServiceFrequencyMonths: data.enableTrackerServiceFrequency === true ? data.trackerServiceFrequencyMonths : 0,
      };

      if (isBinaryBeer() && data.defaultKegType && data.defaultKegType.value) {
        body.defaultKegTypeId = data.defaultKegType.value;
      }

      setOrgSubmitting(true);
      putEditOrganisation(source, body)
        .then((response) => {
          setLogo(getLogo(response.name));
          updateOrganisationName(response.name);
          setOrgSubmitted(true);
          setOrgSubmittingErr("");
          setOrgSubmitting(false);
          updateAccountInfo();
          forceUpdate();
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setOrgSubmitted(false);
            setOrgSubmittingErr(errToStr(err));
            setOrgSubmitting(false);
          }
        });
    }
  };

  const updateAccountInfo = () => {
    setOrgPhotoLoading(true);
    fetchEditAccount(source)
      .then((response) => {
        saveAccount(response);
        setAccountInfo(response);
        setOrgPhotoLoading(false);
        const accountInfoUpdatedEvent = new Event("accountinfoupdated");
        document.dispatchEvent(accountInfoUpdatedEvent);
        forceUpdate();
      })
      .catch((err) => {
        setOrgPhotoLoading(false);
      });
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleCheckboxChange = (e: any) => {
    e.persist();
    setData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleFileChange = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setInputPhoto(e.target.files[0]);
    if (e.target.files[0]) setUploadPhotoModalOpen(true);
    e.target.value = "";
  };

  const handleSelectChange = (selected: any, action: any) => {
    setData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  return (
    <FormContainer>
      <LoadingContainer loading={dataLoading} err={dataErr}>
        <div style={{ paddingTop: "24px", textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              maxWidth: "544px",
              margin: "auto",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "100%", paddingBottom: "24px" }}>{Logo}</div>

            {isAdmin() && (
              <Link to="/users" style={{ textDecoration: "none" }}>
                <Block>
                  <BlockIconContainer>
                    <IconContext.Provider value={{ size: "40px" }}>
                      <FaUsers />
                    </IconContext.Provider>
                  </BlockIconContainer>
                  <BlockSpan>
                    {data.userCount} {data.userCount === 1 ? "User" : "Users"}
                  </BlockSpan>
                </Block>
              </Link>
            )}

            <Link to="/places" style={{ textDecoration: "none" }}>
              <Block>
                <BlockIconContainer>
                  <IconContext.Provider value={{ size: "40px" }}>
                    <FaMapMarkerAlt />
                  </IconContext.Provider>
                </BlockIconContainer>
                <BlockSpan>
                  {data.placeCount} {data.placeCount === 1 ? "Place" : "Places"}
                </BlockSpan>
              </Block>
            </Link>

            {isBinaryBeer() && data.beerCount > 0 && (
              <Link to="/beers" style={{ textDecoration: "none" }}>
                <Block>
                  <BlockIconContainer>
                    <IconContext.Provider value={{ size: "40px" }}>
                      <IoIosBeer />
                    </IconContext.Provider>
                  </BlockIconContainer>
                  <BlockSpan>
                    {data.beerCount} {data.beerCount === 1 ? "Beer" : "Beers"}
                  </BlockSpan>
                </Block>
              </Link>
            )}

            {accountInfo.sensors > 0 && (
              <Link to="/trackers" style={{ textDecoration: "none" }}>
                <Block>
                  <BlockIconContainer>
                    <SensorIcon width={40} height={40} />
                  </BlockIconContainer>
                  <BlockSpan>
                    {accountInfo.sensors} {accountInfo.sensors === 1 ? "Tracker" : "Trackers"}
                  </BlockSpan>
                </Block>
              </Link>
            )}

            {accountInfo.stickers > 0 && (
              <Link to="/trackers" style={{ textDecoration: "none" }}>
                <Block>
                  <BlockIconContainer>
                    <IconContext.Provider value={{ size: "40px" }}>
                      <BsQrCode />
                    </IconContext.Provider>
                  </BlockIconContainer>
                  <BlockSpan>
                    {accountInfo.stickers} {accountInfo.stickers === 1 ? "Sticker" : "Stickers"}
                  </BlockSpan>
                </Block>
              </Link>
            )}

            <Link to="/admin-reports" style={{ textDecoration: "none" }}>
              <Block>
                <BlockIconContainer>
                  <IconContext.Provider value={{ size: "40px" }}>
                    <FaTools />
                  </IconContext.Provider>
                </BlockIconContainer>
                <BlockSpan>Admin Reports</BlockSpan>
              </Block>
            </Link>
          </div>
        </div>
      </LoadingContainer>

      {isAdmin() && (
        <LoadingContainer loading={orgSubmitting}>
          <div style={{ textAlign: "center" }}>
            <HorizontalLine />

            <form onSubmit={handleOrgSubmit} noValidate>
              <ScreenHeading style={{ paddingTop: 24 }}>Organisation Settings</ScreenHeading>
              <div
                style={{
                  display: "block",
                  textAlign: "left",
                }}
              >
                <FormInputContainer>
                  <label>Organisation Photo</label>
                  <div style={{ width: "125px", marginBottom: "16px", position: "relative" }}>
                    <LoadingContainer loading={orgPhotoLoading}>
                      {accountInfo.orgPhotoUrl ? (
                        <div style={{ cursor: "pointer" }} onClick={() => setPhotoModalOpen(true)}>
                          <Avatar style={{ display: "block", margin: "12px 0" }} name={data.name} src={accountInfo.orgPhotoUrl} size="125px" round={true} />
                        </div>
                      ) : (
                        <Avatar style={{ display: "block", margin: "12px 0" }} name={data.name} size="125px" round={true} />
                      )}
                      <Tooltip
                        onCreate={(instance) => (photoMenuRef.current = instance)}
                        interactive={true}
                        touch={true}
                        appendTo={document.body}
                        trigger="click"
                        placement="bottom-start"
                        maxWidth="none"
                        theme="binary-no-padding"
                        content={
                          <MenuList>
                            <MenuButton
                              onClick={() => {
                                inputRef.current.click();
                                hideAll();
                              }}
                            >
                              {accountInfo.orgPhotoUrl ? "Change" : "Upload"}
                            </MenuButton>
                            {accountInfo.orgPhotoUrl && (
                              <DangerMenuButton
                                onClick={() => {
                                  setDeletePhotoModalOpen(true);
                                  hideAll();
                                }}
                              >
                                Delete
                              </DangerMenuButton>
                            )}
                          </MenuList>
                        }
                      >
                        <UpdateImageButton>
                          <UpdateImageButtonContainer>
                            <CameraIconContainer>
                              <CameraIcon />
                            </CameraIconContainer>
                          </UpdateImageButtonContainer>
                        </UpdateImageButton>
                      </Tooltip>
                    </LoadingContainer>
                  </div>
                </FormInputContainer>
                <FormInputContainer style={{ float: "unset", paddingBottom: "14px" }}>
                  <label>Organisation Name</label>
                  <FormInput type="text" name="name" value={data.name} error={formErrors.name} onChange={handleChange} autoComplete="name" />
                  <FormError error={formErrors.name}>{formErrors.name}</FormError>
                </FormInputContainer>
                <FormInputContainer style={{ float: "unset", paddingBottom: "14px" }}>
                  <label>Default Domain</label>
                  <Select
                    name="orgDomain"
                    isError={formErrors.orgDomain}
                    value={data.orgDomain}
                    onChange={handleSelectChange}
                    placeholder="Select"
                    options={orgDomainOptions}
                  />
                  <FormError error={formErrors.name}>{formErrors.name}</FormError>
                </FormInputContainer>
                {isBinaryBeer() && (
                  <FormInputContainer style={{ float: "unset", paddingBottom: "14px" }}>
                    <label>Default Keg Type</label>
                    <AsyncSelect
                      name="defaultKegType"
                      defaultOptions={true}
                      isClearable={true}
                      isError={formErrors.defaultKegType}
                      value={data.defaultKegType}
                      loadOptions={(inputValue: any, callback: any) => loadOptions("kegTypes", inputValue, callback)}
                      onChange={handleSelectChange}
                      placeholder="Select"
                    />
                    <FormError error={formErrors.name}>{formErrors.name}</FormError>
                  </FormInputContainer>
                )}
                {isBinaryMed() && (
                  <>
                    <div style={{ padding: "8px 0" }}>
                      <Checkbox
                        style={{ display: "inline-block" }}
                        name="enableTrackerServiceFrequency"
                        label="Tracker Service Frequency"
                        checked={data.enableTrackerServiceFrequency}
                        onChange={handleCheckboxChange}
                      />
                      <div
                        style={{
                          cursor: "pointer",
                          display: "inline-block",
                          margin: "0 0 -3px 4px",
                        }}
                      >
                        <Tooltip
                          content="This enables notifications to alert you if a tracker is overdue for a service"
                          interactive={true}
                          touch={true}
                          appendTo={document.body}
                        >
                          <InfoIconContainer>
                            <InfoIcon fill={color.font[2]} />
                          </InfoIconContainer>
                        </Tooltip>
                      </div>
                    </div>
                    {data.enableTrackerServiceFrequency === true && (
                      <FormInputContainer>
                        <InputUnitWrapper unit="months">
                          <FormInput
                            type="number"
                            name="trackerServiceFrequencyMonths"
                            value={data.trackerServiceFrequencyMonths}
                            error={formErrors.trackerServiceFrequencyMonths}
                            onChange={handleChange}
                            min={1}
                          />
                        </InputUnitWrapper>
                        <FormError error={formErrors.trackerServiceFrequencyMonths}>{formErrors.trackerServiceFrequencyMonths}</FormError>
                      </FormInputContainer>
                    )}
                  </>
                )}
                <div style={{ padding: "8px 0" }}>
                  <Checkbox
                    style={{ display: "inline-block" }}
                    name="allowPublicScans"
                    label="Allow Public Scans"
                    checked={data.allowPublicScans}
                    onChange={handleCheckboxChange}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      margin: "0 0 -3px 4px",
                    }}
                  >
                    <Tooltip
                      content="This enables the public scan page that appears when a sensor's QR code is scanned"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </Tooltip>
                  </div>
                </div>
                <div style={{ padding: "8px 0" }}>
                  <Checkbox
                    style={{ display: "inline-block" }}
                    name="allowPublicButtons"
                    label="Allow Public Buttons"
                    checked={data.allowPublicScans ? data.allowPublicButtons : false}
                    onChange={handleCheckboxChange}
                    disabled={!data.allowPublicScans}
                  />
                  <div
                    style={{
                      cursor: "pointer",
                      display: "inline-block",
                      margin: "0 0 -3px 4px",
                    }}
                  >
                    <Tooltip
                      content="This shows the request pickup and feedback buttons on the public scan page"
                      interactive={true}
                      touch={true}
                      appendTo={document.body}
                    >
                      <InfoIconContainer>
                        <InfoIcon fill={color.font[2]} />
                      </InfoIconContainer>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", margin: "16px 0px" }}>
                <PrimaryBtn type="submit">Save Changes</PrimaryBtn>
              </div>
              {orgSubmitted && (
                <SuccessAlert
                  style={{
                    marginBottom: "16px",
                    float: "left",
                    width: "100%",
                  }}
                >
                  Organisation Settings Updated
                </SuccessAlert>
              )}
              {orgSubmittingErr && (
                <DangerAlert
                  style={{
                    marginBottom: "16px",
                    float: "left",
                    width: "100%",
                  }}
                >
                  {orgSubmittingErr}
                </DangerAlert>
              )}
            </form>
          </div>
        </LoadingContainer>
      )}
      <input type="file" ref={inputRef} onChange={handleFileChange} style={{ display: "none" }} accept="image/png, image/jpeg" />
      {uploadPhotoModalOpen && (
        <UploadPhotoModal
          modalOpen={uploadPhotoModalOpen}
          setModalOpen={setUploadPhotoModalOpen}
          photo={inputPhoto}
          trackerId={undefined}
          organisationId={accountInfo.organisationId}
          placeId={undefined}
          userId={undefined}
          onSuccess={updateAccountInfo}
        />
      )}
      {deletePhotoModalOpen && (
        <DeleteModal
          title="Delete Organisation Photo"
          body={<span>Are you sure you want to delete your organisation photo?</span>}
          successMsg="Organisation Photo Deleted"
          modalOpen={deletePhotoModalOpen}
          setModalOpen={setDeletePhotoModalOpen}
          deleteService={deletePhoto}
          serviceParams={[undefined, accountInfo.organisationId, undefined, undefined]}
          onDelete={() => {
            updateAccountInfo();
          }}
        />
      )}
      {photoModalOpen && <PhotoModal modalOpen={photoModalOpen} setModalOpen={setPhotoModalOpen} name="Organisation Photo" src={accountInfo.orgPhotoUrl} />}
    </FormContainer>
  );
};

export default OrganisationScreen;
