import React, { FC, useState, useEffect, useContext } from "react";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { humaniseHours } from "../../util/humaniseDurations";
import moment from "moment";
import { SortingState, ColumnFiltersState, PaginationState, ColumnDef } from "@tanstack/react-table";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce/lib";
import { getPlaceProductsTableSettings, placeProductsTableDefaults, savePlaceProductsTableSettings } from "../../services/localStorage";
import NewTable from "../NewTable";
import { getTableFilters } from "../../util/urlParamFilters";
import { fetchPlaceProductsTable } from "../../services/placeProductsTable";
import { printTemp } from "../../util/formatUnits";

type Consumption = {
  beerName: string;
  full: number;
  filledHere: number;
  empty: number;
  timeUntilDepleted: number;
  tappedTemperature: number;
  tappedFreshness: number;
  timeToTap: number;
  consumptionTime: number;
  litresConsumed: number;
};

const PlaceProductsTable: FC<any> = ({ id, filterDates, meta }) => {
  const { color } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>([
    { id: "full", desc: true },
    { id: "timeUntilDepleted", desc: false },
  ]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getPlaceProductsTableSettings() ? getPlaceProductsTableSettings().pageSize : 10,
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const getData = () => {
    // If end date is undefined set to current date
    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined ? filterDates.end.unix() : moment().unix(),
    };

    setDataLoading(true);
    setDataErr("");

    fetchPlaceProductsTable(source, id, dates, { pageIndex, pageSize, orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        setData(response.data);
        setCount(response.count);
        setDataErr("");
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const columns = React.useMemo<ColumnDef<Consumption>[]>(
    () => [
      {
        accessorKey: "beerName",
        header: "Beer",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "autoComplete",
          filterKey: "beerName",
          selectKey: "label",
          loadOptionsKey: "beers",
        },
        filterFn: undefined,
        size: 160,
      },
      {
        header: "Full Kegs",
        accessorKey: "full",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 190,
      },
      ...(meta.fillsBeerKegs
        ? [
            {
              header: "Kegs Filled Here",
              accessorKey: "filledHere",
              cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
              meta: {
                filterType: "number",
              },
              filterFn: undefined,
              size: 190,
            },
          ]
        : []),
      ...(meta.emptiesBeerKegs
        ? [
            {
              header: "Emptied Kegs",
              accessorKey: "empty",
              cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
              meta: {
                filterType: "number",
              },
              filterFn: undefined,
              size: 190,
            },
          ]
        : []),
      ...(meta.emptiesBeerKegs
        ? [
            {
              accessorKey: "timeUntilDepleted",
              header: "Time Until Depleted",
              cell: (props: any) =>
                props.getValue() !== undefined ? <span title={humaniseHours(props.getValue())}>{humaniseHours(props.getValue())}</span> : "-",
              meta: {
                filterType: "number",
              },
              filterFn: undefined,
              size: 150,
            },
          ]
        : []),
      ...(meta.emptiesBeerKegs
        ? [
            {
              accessorKey: "tappedTemperature",
              header: "Average Tapped Temperature",
              cell: (props: any) => {
                const temp = printTemp(props.getValue(), 1);
                return <span title={temp}>{temp}</span>;
              },
              meta: {
                filterType: "number",
              },
              filterFn: undefined,
              size: 150,
            },
          ]
        : []),
      ...(meta.emptiesBeerKegs
        ? [
            {
              accessorKey: "tappedFreshness",
              header: "Average Tapped Freshness",
              cell: (props: any) => {
                const freshness = props.getValue() !== undefined ? `${props.getValue().toFixed(1)}%` : "";
                return <span title={freshness}>{freshness}</span>;
              },
              meta: {
                filterType: "number",
              },
              filterFn: undefined,
              size: 190,
            },
          ]
        : []),
      ...(meta.emptiesBeerKegs
        ? [
            {
              accessorKey: "timeToTap",
              cell: (props: any) =>
                props.getValue() !== undefined ? <span title={humaniseHours(props.getValue())}>{humaniseHours(props.getValue())}</span> : "-",
              header: "Average Time To Tap",
              meta: {
                filterType: "number",
              },
              filterFn: undefined,
              size: 160,
            },
          ]
        : []),
      ...(meta.emptiesBeerKegs
        ? [
            {
              accessorKey: "consumptionTime",
              cell: (props: any) =>
                props.getValue() !== undefined ? <span title={humaniseHours(props.getValue())}>{humaniseHours(props.getValue())}</span> : "-",
              header: "Average Consumption Time",
              meta: {
                filterType: "number",
              },
              filterFn: undefined,
              size: 160,
            },
          ]
        : []),
      ...(meta.emptiesBeerKegs
        ? [
            {
              accessorKey: "litresConsumed",
              cell: (props: any) => <span title={`${props.getValue()} L`}>{`${props.getValue()} L`}</span>,
              header: "Total Litres Consumed Here",
              meta: {
                filterType: "number",
              },
              filterFn: undefined,
              size: 160,
            },
          ]
        : []),
    ],
    [color, meta]
  );

  return (
    <>
      <NewTable
        data={data}
        count={count}
        dataErr={dataErr}
        dataLoading={dataLoading}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        columnFiltersDebounced={columnFiltersDebounced}
        setColumnFilters={setColumnFilters}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        fetchData={getData}
        fetchDataDependencies={[filterDates]}
        // fetchCsv={fetchCsv}
        defaultTableSettings={placeProductsTableDefaults}
        getTableSettings={getPlaceProductsTableSettings}
        saveTableSettings={savePlaceProductsTableSettings}
        dataTypeName="Beer Consumption"
        emptyDataMsg="Adjust date range to see beer consumption metrics"
      />
    </>
  );
};

export default PlaceProductsTable;
