import { createGlobalStyle } from "../Themes";

export default createGlobalStyle<any>`
.drawer {
  z-index: 1010001 !important;
}
.drawer-content{
  overflow: unset !important;
  max-width: 500px;
}
.drawer-content-wrapper {
  background: ${({ theme }) => theme.color.panel_bg[2]} !important;
}
`;
