import React, { FC } from "react";
import { InfoModal } from "../Modal";
import Table from "../Table";
import matchSorter from "match-sorter";
import copyToClipboard from "../../util/copyToClipboard";
import { toast } from "react-toastify";
import { CopyCell } from "./styles";

const AdditionalSensorInfoModal: FC<any> = ({ modalOpen, setModalOpen, data }) => {
  const parsedData = Object.entries(data).map((row: any) => ({ key: row[0], value: row[1] }), []);

  const columns = [
    {
      id: "key",
      Header: "Key",
      accessor: "key",
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["key"],
        }),
      filterAll: true,
      Cell: (props: any) => {
        return (
          <CopyCell
            onClick={() => {
              copyToClipboard(props.value);
              toast.info("Copied to Clipboard");
            }}
            title={props.value}
          >
            {props.value}
          </CopyCell>
        );
      },
    },
    {
      id: "value",
      Header: "Value",
      accessor: "value",
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["value"],
        }),
      filterAll: true,
      Cell: (props: any) => {
        return (
          <CopyCell
            onClick={() => {
              copyToClipboard(props.value);
              toast.info("Copied to Clipboard");
            }}
            title={props.value}
          >
            {props.value}
          </CopyCell>
        );
      },
    },
  ];

  const defaultSorted = [
    {
      id: "key",
    },
  ];

  return (
    <InfoModal
      isOpen={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      title="Additional Meta Data"
      size="xl"
      okayBtnText="Close"
      okayBtnProps={{ width: "100%" }}
      body={<Table filterable={true} defaultPageSize={Number.MAX_SAFE_INTEGER} data={parsedData} columns={columns} defaultSorted={defaultSorted} />}
    />
  );
};

export default AdditionalSensorInfoModal;
