import L from "leaflet";
import "leaflet-easybutton";
import "leaflet-easybutton/src/easy-button.css";

const leafletShowAllButton = (buttonRef: any, points: any, map: any, setBounds: any) => {
  if (map) {
    // Remove the show all button if it exists
    if (buttonRef.current) {
      map.removeControl(buttonRef.current);
    }

    // create button and add to map
    const newShowAllBtn = L.easyButton({
      position: "topleft",
      leafletClasses: true,
      states: [
        {
          stateName: "showAll",
          onClick: () => {
            // push all create all markers for points, add to group, then fit map bounds to show all
            if (points.length > 0) {
              const markerList: any = [];
              for (let i = 0; i < points.length; i++) {
                const p = points[i];
                const circle = L.circle([p.lat, p.lng], {
                  radius: p.acc >= 0 ? p.acc : 0,
                });
                markerList.push(circle);
              }

              const group = L.featureGroup(markerList);
              group.addTo(map);

              map.fitBounds(group.getBounds(), {
                padding: [50, 50],
                maxZoom: 18,
              });

              group.clearLayers();
              setBounds(group.getBounds());
            }
          },
          title: "Show all",
          icon: `<div class="show-all-button"></div>`,
        },
      ],
    }).addTo(map);

    // store reference to button so it can be removed/updated
    buttonRef.current = newShowAllBtn;
  }
};

export default leafletShowAllButton;
