import L, { LatLngTuple } from "leaflet";
import moment from "moment";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Bold from "../Bold";
import { Content, Header, ListItem, Menu, SelectContainer, Toggle, ToggleText } from "./styles";
//@ts-ignore
import { ButtonGroup } from "@zendeskgarden/react-buttons";
import "@zendeskgarden/react-buttons/dist/styles.css";
import fastSort from "fast-sort";
import matchSorter from "match-sorter";
import { renderToStaticMarkup } from "react-dom/server";
import { IconContext } from "react-icons";
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill, BsFillCheckCircleFill, BsFillXCircleFill } from "react-icons/bs";
import ReactTimeago from "react-timeago";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce";
import { isBinaryBeer } from "../../util/checkDomain";
import { printLength, printTemp } from "../../util/formatUnits";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import sortTags from "../../util/sortTags";
import AssetTypeLabel from "../AssetTypeLabel";
import { PrimaryToggleBtn, SecondaryToggleBtn } from "../Buttons";
import { FormInput } from "../FormComponents";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import { Select } from "../Select";
import Tag from "../Tag";

const MapList: FC<any> = ({ map, sensors, places, bounds, toggleList, listOpen }) => {
  const { color, long_datetime } = useContext(ThemeContext);

  const hoverRef = useRef<any>({});

  const [sensorList, setSensorList] = useState<any>([]);
  const [boundedSensors, setBoundedSensors] = useState<any>([]);
  const [filteredSensors, setFilteredSensors] = useState<any>([]);
  const [sortedSensors, setSortedSensors] = useState<any>([]);
  const [sensorFilter, setSensorFilter] = useState<string>("");
  const [sensorCards, setSensorCards] = useState<any>([]);
  const [sortSensorsBy, setSortSensorsBy] = useState<string>("lastSampleDate-desc");
  const [placeList, setPlaceList] = useState<any>([]);
  const [boundedPlaces, setBoundedPlaces] = useState<any>([]);
  const [filteredPlaces, setFilteredPlaces] = useState<any>([]);
  const [sortedPlaces, setSortedPlaces] = useState<any>([]);
  const [placeFilter, setPlaceFilter] = useState<string>("");
  const [placeCards, setPlaceCards] = useState<any>([]);
  const [sortPlacesBy, setSortPlacesBy] = useState<string>("sensors-desc");
  const [activeTab, setActiveTab] = useState<string>("sensors");

  const [sensorFilterValue] = useDebounce(sensorFilter, 250);
  const [placeFilterValue] = useDebounce(placeFilter, 250);

  useEffect(() => {
    setSensorList(sensors);
  }, [sensors]);

  useEffect(() => {
    setPlaceList(places);
  }, [places]);

  useEffect(() => {
    if (listOpen) {
      setActiveTab(listOpen);
    }
  }, [listOpen]);

  // Filter out the sensors that are only visible on the map
  useEffect(() => {
    if (listOpen && activeTab === "sensors" && bounds) {
      if (sensorList.length > 0) {
        const bounded: any = [];

        for (let i = 0; i < sensorList.length; i++) {
          const { latitude, longitude } = sensorList[i].coordinates;
          if (latitude !== undefined && longitude !== undefined) {
            if (bounds.contains(L.latLng(latitude, longitude))) {
              bounded.push(sensorList[i]);
            }
          }
        }

        setBoundedSensors(bounded);
      }
    }
  }, [listOpen, activeTab, sensorList, bounds]);

  // Filter out the sensors that don't match the filtered text
  useEffect(() => {
    if (listOpen && activeTab === "sensors") {
      const filtered = matchSorter(boundedSensors, sensorFilterValue, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["id", "sensorName", "assetTypeName", "assetId", (item: any) => item.trackerTags.map((i: any) => i.name)],
      });
      setFilteredSensors(filtered);
    }
  }, [listOpen, activeTab, boundedSensors, sensorFilterValue]);

  // Sort sensors when "Sort by" dropdown or filteredSensors changes
  useEffect(() => {
    if (listOpen && activeTab === "sensors") {
      const sort = sortSensorsBy.split("-")[0];
      const order = sortSensorsBy.split("-")[1];

      const sorted = order === "asc" ? fastSort(filteredSensors).asc((el: any) => el[sort]) : fastSort(filteredSensors).desc((el: any) => el[sort]);

      setSortedSensors(sorted);
    }
  }, [listOpen, activeTab, sortSensorsBy, filteredSensors]);

  // Filter out the places that are only visible on the map
  useEffect(() => {
    if (listOpen && activeTab === "places" && bounds) {
      if (placeList.length > 0) {
        const bounded: any = [];

        for (let i = 0; i < placeList.length; i++) {
          const { latitude, longitude } = placeList[i].coordinates;
          if (latitude !== undefined && longitude !== undefined) {
            if (bounds.contains(L.latLng(latitude, longitude))) {
              bounded.push(placeList[i]);
            }
          }
        }

        setBoundedPlaces(bounded);
      }
    }
  }, [listOpen, activeTab, placeList, bounds]);

  // Filter out the places that don't match the filtered text
  useEffect(() => {
    if (listOpen && activeTab === "places") {
      const filtered = matchSorter(boundedPlaces, placeFilterValue, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["name"],
      });
      setFilteredPlaces(filtered);
    }
  }, [listOpen, activeTab, boundedPlaces, placeFilterValue]);

  // Sort places when "Sort by" dropdown or filteredPlaces changes
  useEffect(() => {
    if (listOpen && activeTab === "places") {
      const sort = sortPlacesBy.split("-")[0];
      const order = sortPlacesBy.split("-")[1];

      const sorted = order === "asc" ? fastSort(filteredPlaces).asc((el: any) => el[sort]) : fastSort(filteredPlaces).desc((el: any) => el[sort]);

      setSortedPlaces(sorted);
    }
  }, [listOpen, activeTab, sortPlacesBy, filteredPlaces]);

  useEffect(() => {
    if (listOpen && activeTab === "sensors") {
      const sort = sortSensorsBy.split("-")[0];
      const url = kegsOrTrackers("kegs", "trackers");

      if (sensorList.length > 0) {
        setSensorCards(
          sortedSensors.map((sensor: any, i: number) => {
            return (
              <ListItem
                style={{ cursor: "pointer" }}
                onClick={() => handleClick(sensor.coordinates, sensor.locationAccuracy)}
                onMouseEnter={() => handleMouseEnterSensor(sensor)}
                onMouseLeave={removeHoverElements}
                key={i}
              >
                {sensor.sensorName && (
                  <p>
                    <span>Name</span>: <Bold>{sensor.sensorName}</Bold>
                  </p>
                )}
                <p>
                  <span>Tracker ID</span>:{" "}
                  <Link to={`/${url}/${sensor.id}`}>
                    <Bold>{sensor.id}</Bold>
                  </Link>
                </p>
                {sensor.assetId && (
                  <p>
                    <span>Asset ID</span>: <Bold>{sensor.assetId}</Bold>
                  </p>
                )}
                {sensor.assetTypeName && (
                  <>
                    <p style={{ display: "inline-flex", alignItems: "center" }}>
                      <span style={{ marginRight: "6px" }}>
                        <span>Asset Type</span>:{" "}
                      </span>
                      <Bold style={{ display: "inline-flex", alignItems: "center" }}>
                        <AssetTypeLabel name={sensor.assetTypeName} colour={sensor.assetTypeColour} icon={sensor.assetTypeIcon} />
                      </Bold>
                    </p>
                  </>
                )}
                {sensor.trackerTags.length > 0 && (
                  <p>
                    <span>{kegsOrTrackers("Keg Tags", "Tracker Tags")}</span>:{" "}
                    {sensor.trackerTags.sort(sortTags).map((tag: any) => (
                      <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />
                    ))}
                  </p>
                )}
                <p>
                  Last Seen:{" "}
                  <Bold>
                    <ReactTimeago live={false} date={sensor.lastSampleDate * 1000} title={moment.unix(sensor.lastSampleDate).format(long_datetime)} />
                  </Bold>
                </p>
                {sensor.lastSampleDate && sensor.lastGoodLocationDate && sensor.lastSampleDate !== sensor.lastGoodLocationDate && (
                  <p>
                    Last Located:{" "}
                    <Bold>
                      <ReactTimeago
                        live={false}
                        date={sensor.lastGoodLocationDate * 1000}
                        title={moment.unix(sensor.lastGoodLocationDate).format(long_datetime)}
                      />
                    </Bold>
                  </p>
                )}
                {sort === "beer" && sensor.beer && (
                  <p>
                    Beer: <Bold>{sensor.beer}</Bold>
                  </p>
                )}
                {sort === "temperature" && (
                  <p>
                    Temperature: <Bold>{printTemp(sensor.temperature)}</Bold>
                  </p>
                )}
                {sort === "locationAccuracy" && (
                  <p>
                    Accuracy: <Bold>{printLength(sensor.locationAccuracy)}</Bold>
                  </p>
                )}
                {sort === "freshness" && sensor.freshness !== undefined && (
                  <p>
                    Freshness: <Bold>{sensor.freshness}%</Bold>
                  </p>
                )}
              </ListItem>
            );
          })
        );
      } else {
        setSensorCards([]);
      }
    }
  }, [listOpen, activeTab, sortedSensors, sortSensorsBy, sensorList]);

  useEffect(() => {
    if (listOpen && activeTab === "places") {
      const sort = sortPlacesBy.split("-")[0];

      if (placeList.length > 0) {
        setPlaceCards(
          sortedPlaces.map((place: any, i: number) => {
            return (
              <ListItem
                style={{ cursor: "pointer" }}
                onClick={() => handleClick(place.coordinates, place.radius)}
                onMouseEnter={() => handleMouseEnterPlace(place)}
                onMouseLeave={removeHoverElements}
                key={i}
              >
                <span>Place</span>:{" "}
                <Link to={`/places/${place.id}`}>
                  <Bold>{place.name}</Bold>
                </Link>
                {place.placeGroup && (
                  <p>
                    <span>Place Type</span>: <ColoredDot color={place.placeGroupColour} />
                    <Bold>{place.placeGroup}</Bold>
                  </p>
                )}
                {place.placeTags.length > 0 && (
                  <p>
                    <span>Place Tags</span>:{" "}
                    {place.placeTags.sort(sortTags).map((tag: any) => (
                      <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />
                    ))}
                  </p>
                )}
                <p>
                  {kegsOrTrackers("Keg", "Tracker")} Count: <Bold>{place.sensors}</Bold>
                </p>
                {place.allocations > 0 && (
                  <p>
                    Allocation Status:{" "}
                    {place.allocationStatus === 0 ? (
                      <div style={{ display: "inline-flex", alignItems: "center" }}>
                        <Bold>Correctly Allocated</Bold>
                        <span style={{ display: "flex", marginLeft: "6px" }}>
                          <IconContext.Provider value={{ color: color.success[2], size: "21px" }}>
                            <BsFillCheckCircleFill />
                          </IconContext.Provider>
                        </span>
                      </div>
                    ) : place.allocationStatus === 1 ? (
                      <div style={{ display: "inline-flex", alignItems: "center" }}>
                        <Bold>Under Allocated</Bold>
                        <span style={{ display: "flex", marginLeft: "6px" }}>
                          <IconContext.Provider value={{ color: color.danger[2], size: "21px" }}>
                            <BsFillArrowDownCircleFill />
                          </IconContext.Provider>
                        </span>
                      </div>
                    ) : place.allocationStatus === 2 ? (
                      <div style={{ display: "inline-flex", alignItems: "center" }}>
                        <Bold>Over Allocated</Bold>
                        <span style={{ display: "flex", marginLeft: "6px" }}>
                          <IconContext.Provider value={{ color: color.danger[2], size: "21px" }}>
                            <BsFillArrowUpCircleFill />
                          </IconContext.Provider>
                        </span>
                      </div>
                    ) : (
                      <div style={{ display: "inline-flex", alignItems: "center" }}>
                        <Bold>Under/Over Allocated</Bold>
                        <span style={{ display: "flex", marginLeft: "6px" }}>
                          <IconContext.Provider value={{ color: color.danger[2], size: "21px" }}>
                            <BsFillXCircleFill />
                          </IconContext.Provider>
                        </span>
                      </div>
                    )}
                  </p>
                )}
                {sort === "radius" && (
                  <p>
                    Radius: <Bold>{printLength(place.radius)}</Bold>
                  </p>
                )}
              </ListItem>
            );
          })
        );
      } else {
        setPlaceCards([]);
      }
    }
  }, [listOpen, activeTab, sortedPlaces, sortPlacesBy, placeList]);

  const handleClick = (coordinates: any, radius: number) => {
    const circle = L.circle([coordinates.latitude, coordinates.longitude], {
      radius,
    }).addTo(map);
    map.fitBounds(circle.getBounds());
    map.removeLayer(circle);
  };

  const handleMouseEnterSensor = (sensor: any) => {
    const latlng: LatLngTuple = [sensor.coordinates.latitude, sensor.coordinates.longitude];

    const container = L.DomUtil.create("div");
    const trackerId = L.DomUtil.create("span", "popup-title");
    const assetId = L.DomUtil.create("span", "popup-text");
    const assetType = L.DomUtil.create("span", "popup-text");
    const trackerTags = L.DomUtil.create("span", "popup-text");
    const lastSeen = L.DomUtil.create("span", "popup-text");
    const temperature = L.DomUtil.create("span", "popup-text");
    const beer = L.DomUtil.create("span", "popup-text");
    const freshness = L.DomUtil.create("span", "popup-text");

    trackerId.innerHTML = sensor.sensorName ? `${sensor.sensorName} (${sensor.id})` : sensor.id;
    assetId.innerHTML = sensor.assetId ? `Asset ID: ${sensor.assetId}` : "";
    assetType.innerHTML = sensor.assetTypeName ? `Asset Type: ${sensor.assetTypeName}` : "";
    trackerTags.innerHTML =
      sensor.trackerTags && sensor.trackerTags.length > 0
        ? `${kegsOrTrackers("Kegs Tags", "Tracker Tags")}: ${sensor.trackerTags.map((tag: any) => tag.name).join(", ")}`
        : "";
    lastSeen.innerHTML = `Last Seen: ${renderToStaticMarkup(
      <ReactTimeago live={false} date={sensor.lastSampleDate * 1000} title={moment.unix(sensor.lastSampleDate).format(long_datetime)} />
    )}`;
    temperature.innerHTML = sensor.temperature !== undefined ? `Temperature: ${printTemp(sensor.temperature)}` : ``;

    if (isBinaryBeer()) {
      beer.innerHTML = sensor.beer ? `Beer: ${sensor.beer}` : "";
      freshness.innerHTML = sensor.freshness !== undefined ? `Freshness: ${sensor.freshness}%` : ``;
    }

    container.appendChild(trackerId);
    container.appendChild(assetId);
    container.appendChild(assetType);
    container.appendChild(trackerTags);
    container.appendChild(lastSeen);
    container.appendChild(temperature);

    if (isBinaryBeer()) {
      container.appendChild(beer);
      container.appendChild(freshness);
    }

    removeHoverElements();

    hoverRef.current.circle = L.circle(latlng, {
      radius: sensor.locationAccuracy,
      color: color.primary[2],
    }).addTo(map);

    hoverRef.current.popup = L.popup().setLatLng(latlng).setContent(container).openOn(map);
  };

  const handleMouseEnterPlace = (place: any) => {
    const latlng: LatLngTuple = [place.coordinates.latitude, place.coordinates.longitude];

    const container = L.DomUtil.create("div");
    const name = L.DomUtil.create("span", "popup-title");
    const placeGroup = L.DomUtil.create("span", "popup-text");
    const sensors = L.DomUtil.create("span", "popup-text");

    name.innerHTML = place.name;
    placeGroup.innerHTML = place.placeGroup ? `Place Type: ${place.placeGroup}` : "";
    sensors.innerHTML = `${kegsOrTrackers("Kegs", "Trackers")}: ${place.sensors}`;

    container.appendChild(name);
    container.appendChild(placeGroup);
    container.appendChild(sensors);

    removeHoverElements();

    hoverRef.current.circle = L.circle(latlng, {
      radius: place.radius,
      color: color.secondary[2],
    }).addTo(map);

    hoverRef.current.popup = L.popup().setLatLng(latlng).setContent(container).openOn(map);
  };

  const removeHoverElements = () => {
    hoverRef.current.circle && map.removeLayer(hoverRef.current.circle);
    hoverRef.current.popup && map.removeLayer(hoverRef.current.popup);
    hoverRef.current = {};
  };

  // when the mouse enters the map this removes the map elements created when the mouse
  // is enters a list item. This is needed because when a user clicks a list item
  // and the map pans sometimes it automatically moves the list item away from the mouse due
  // to less map markers being displayed causing the mouse leave event to be missed
  useEffect(() => {
    map.on("mouseover", removeHoverElements);
    return () => {
      map.off("mouseover", removeHoverElements);
    };
  }, [map, removeHoverElements]);

  const filterWording = kegsOrTrackers("Keg", "Tracker");

  const sensorFilterOptions = [
    { value: "id-asc", label: `Tracker Id: Asc` },
    { value: "id-desc", label: `Tracker Id: Desc` },
    { value: "sensorName-asc", label: `Name: Asc` },
    { value: "sensorName-desc", label: `Name: Desc` },
    { value: "assetId-asc", label: `Asset Id: Asc` },
    { value: "assetId-desc", label: `Asset Id: Desc` },
    { value: "assetTypeName-asc", label: `Asset Type: Asc` },
    { value: "assetTypeName-desc", label: `Asset Type: Desc` },
    { value: "lastSampleDate-desc", label: "Seen: Newest" },
    { value: "lastSampleDate-asc", label: "Seen: Oldest" },
    { value: "temperature-desc", label: "Temp: High" },
    { value: "temperature-asc", label: "Temp: Low" },
    { value: "locationAccuracy-asc", label: "Accuracy: Good" },
    { value: "locationAccuracy-desc", label: "Accuracy: Bad" },
    ...(isBinaryBeer() ? [{ value: "beer-asc", label: `Beer: Asc` }] : []),
    ...(isBinaryBeer() ? [{ value: "beer-desc", label: `Beer: Desc` }] : []),
    ...(isBinaryBeer() ? [{ value: "freshness-desc", label: "Freshness: High" }] : []),
    ...(isBinaryBeer() ? [{ value: "freshness-asc", label: "Freshness: Low" }] : []),
  ];

  const placeFilterOptions = [
    { value: "sensors-desc", label: `${filterWording} Count: High` },
    { value: "sensors-asc", label: `${filterWording} Count: Low` },
    { value: "placeGroup-asc", label: "Place Type: Asc" },
    { value: "placeGroup-desc", label: "Place Type: Desc" },
    { value: "radius-desc", label: "Place Radius: High" },
    { value: "radius-asc", label: "Place Radius: Low" },
    { value: "name-desc", label: "Place Name: Desc" },
    { value: "name-asc", label: "Place Name: Asc" },
    { value: "allocationStatus-desc", label: "Allocation Status: Desc" },
    { value: "allocationStatus-asc", label: "Allocation Status: Asc" },
  ];

  return (
    <Menu open={!!listOpen}>
      <Toggle onClick={() => toggleList(!listOpen ? activeTab : false)}>
        <ToggleText>List</ToggleText>
      </Toggle>

      <ButtonGroup style={{ paddingTop: "20px", width: "66%" }} selectedKey={activeTab}>
        <PrimaryToggleBtn
          key="sensors"
          onClick={() => {
            setActiveTab("sensors");
            toggleList("sensors");
          }}
          width="50%"
        >
          {kegsOrTrackers("Kegs", "Trackers")}
        </PrimaryToggleBtn>
        <SecondaryToggleBtn
          key="places"
          onClick={() => {
            setActiveTab("places");
            toggleList("places");
          }}
          width="50%"
        >
          Places
        </SecondaryToggleBtn>
      </ButtonGroup>

      <div
        style={{
          position: "absolute",
          top: "70px",
          bottom: "0px",
          width: "100%",
          display: activeTab === "sensors" ? "flex" : "none",
        }}
      >
        <Header>
          <p style={{ padding: "4px" }}>
            <Bold>{sensorCards.length}</Bold> out of <Bold>{sensorList.length}</Bold>
          </p>

          <p style={{ padding: "4px" }}>{kegsOrTrackers("Kegs", "Trackers")} in the Current View</p>

          <div style={{ padding: "4px" }}>
            <span style={{ padding: "4px 10px 10px 10px" }}>Sort by</span>
            <SelectContainer>
              <Select
                defaultValue={{
                  value: "lastSampleDate-desc",
                  label: "Seen: Newest",
                }}
                isClearable={false}
                isSearchable={false}
                onChange={(selected: any) => {
                  setSortSensorsBy(selected.value);
                }}
                options={sensorFilterOptions}
              />
            </SelectContainer>
          </div>

          <div style={{ padding: "4px", width: "80%", margin: "auto" }}>
            <FormInput
              placeholder={kegsOrTrackers("Filter Kegs...", "Filter Trackers...")}
              type="text"
              onChange={(e: any) => setSensorFilter(e.target.value)}
              value={sensorFilter}
            />
          </div>
        </Header>
        <Content>{sensorCards}</Content>
      </div>
      <div
        style={{
          position: "absolute",
          top: "70px",
          bottom: "0px",
          width: "100%",
          display: activeTab === "places" ? "flex" : "none",
        }}
      >
        <Header>
          <p style={{ padding: "4px" }}>
            <Bold>{placeCards.length}</Bold> out of <Bold>{placeList.length}</Bold>
          </p>

          <p style={{ padding: "4px" }}>{placeList.length === 1 ? "Place" : "Places"} in the Current View</p>

          <div style={{ padding: "4px" }}>
            <span style={{ padding: "0 10px 10px 10px" }}>Sort by</span>
            <SelectContainer>
              <Select
                defaultValue={{
                  value: "sensors-desc",
                  label: `${kegsOrTrackers("Keg", "Tracker")} Count: High`,
                }}
                isClearable={false}
                isSearchable={false}
                onChange={(selected: any) => {
                  setSortPlacesBy(selected.value);
                }}
                options={placeFilterOptions}
              />
            </SelectContainer>
          </div>

          <div style={{ padding: "4px", width: "80%", margin: "auto" }}>
            <FormInput placeholder="Filter Place Name..." type="text" onChange={(e: any) => setPlaceFilter(e.target.value)} value={placeFilter} />
          </div>
        </Header>
        <Content>{placeCards}</Content>
      </div>
    </Menu>
  );
};

export default MapList;
