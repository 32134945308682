import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import QrScanner from "qr-scanner";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { ThemeContext } from "styled-components";
import { fetchAutoComplete, fetchAutoCompleteBatches } from "../../../services/autoComplete";
import { postFillKeg } from "../../../services/fillKeg";
import { getDefaults, saveDefaults } from "../../../services/localStorage";
import DangerIcon from "../../../svgs/DangerIcon";
import SuccessIcon from "../../../svgs/SuccessIcon";
import WarningIcon from "../../../svgs/WarningIcon";
import errToStr from "../../../util/errToStr";
import { exists } from "../../../util/formValidations";
import Bold from "../../Bold";
import { OutlineBtn, PrimaryBtn } from "../../Buttons";
import EditBeerBatchModal from "../../EditBeerBatchModal";
import { FormContainer, FormError, FormInput, FormInputContainer } from "../../FormComponents";
import LoadingContainer from "../../LoadingContainer";
import { InfoModal, SubmitModal } from "../../Modal";
import { ModalIconContainer, ModalSuccess } from "../../Modal/styles";
import { TableHeaderButtons } from "../../NewTable/styles";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import { AsyncSelect, Select } from "../../Select";
import Table, { CsvButtonsComponent } from "../../Table";
import Tooltip from "../../Tooltip";

const formatDataToCsv = (tableRef: any, short_datetime: string) => {
  const headers = ["Date", "Tracker ID", "Brewery", "Beer", "Batch"];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [moment.unix(row.date).format(short_datetime), row.sensorId, row.placeName, row.beerName, row.batchName];
    }, []),
  ];
};

const MarkAsFilled: FC<any> = () => {
  const { color, short_datetime, long_datetime } = useContext(ThemeContext);

  const tableRef = useRef<any>(undefined);

  const videoRefCallback = useCallback((node: any) => {
    if (node) {
      setVideoRef(node);
    }
  }, []);

  const [videoRef, setVideoRef] = useState<any>(undefined);

  const [formData, setFormData] = useState<any>({
    sensorId: "",
    brewery: undefined,
    beer: undefined,
    batch: undefined,
  });
  const [formErrors, setFormErrors] = useState<any>({});

  const [codeReader, setCodeReader] = useState<any>(undefined);
  const [filledKegs, setFilledKegs] = useState<any>([]);

  const [scanResponseMsg, setResponseMsg] = useState<string>("");
  const [scanResponseBattery, setResponseBattery] = useState<string>("");
  const [scanResponseErr, setResponseErr] = useState<string>("");
  const [scanResponseLoading, setResponseLoading] = useState<boolean>(false);

  const [batches, setBatches] = useState<any>([]);
  const [batchesLoading, setBatchesLoading] = useState<boolean>(false);

  const [fillModalOpen, setFillModalOpen] = useState<boolean>(false);
  const [batchModalOpen, setBatchModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const formatBatchOption = useCallback(
    (id: string, date: number, notes: string) => ({
      label: `${moment.unix(date).format(short_datetime)} ${notes ? "- " + notes : ""}`,
      value: id,
      date: date,
    }),
    [short_datetime]
  );

  useEffect(() => {
    // Loads the batches options for the batches select input
    // Once loaded, checks if it contains the default batch.
    // If it does, update the selected batch to the default.
    const placeId = formData.brewery ? formData.brewery.value : undefined;
    const beerId = formData.beer ? formData.beer.value : undefined;

    if (placeId !== undefined && beerId !== undefined) {
      setBatchesLoading(true);
      fetchAutoCompleteBatches("batches", placeId, beerId)
        .then((response) => {
          const formattedBatches = response.map((batch: any) => formatBatchOption(batch.value, batch.label, batch.notes));
          setBatches(formattedBatches);
          setBatchesLoading(false);
          if (getDefaults().batch && getDefaults().batch.value) {
            if (formattedBatches.some((batch: any) => batch.value === getDefaults().batch.value)) {
              setFormData((prev: any) => ({
                ...prev,
                batch: getDefaults().batch,
              }));
            }
          }
        })
        .catch(() => {
          setBatchesLoading(false);
        });
    } else {
      setBatches([]);
      setFormData((prev: any) => ({
        ...prev,
        batch: undefined,
      }));
    }
  }, [formData.brewery, formData.beer, formatBatchOption]);

  // Initialise codeReader and stop/destroy codeReader and stop camera access on clean up
  useEffect(() => {
    let codeReader: any = undefined;

    if (videoRef) {
      codeReader = new QrScanner(videoRef, (result) => {
        if (result) {
          let code = result;
          if (code.includes("10b.in/")) {
            code = code.split("10b.in/")[1];
          }
          setFormData((prev: any) => ({ ...prev, sensorId: code }));
          setFormErrors((prev: any) => ({
            ...prev,
            sensorId: undefined,
          }));
          setResponseMsg("");
          setResponseBattery("");
          setResponseErr("");
          setFillModalOpen(true);
        }
      });
      codeReader.setInversionMode("both");
      setCodeReader(codeReader);
    }

    return () => {
      if (codeReader) {
        codeReader.stop();
        codeReader.destroy();
      }
      if (videoRef && videoRef.srcObject) {
        videoRef.srcObject.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
    };
  }, [videoRef]);

  // Stop code reader if brewery, beer, and batch not selected
  useEffect(() => {
    if (codeReader) {
      if (formData.brewery && formData.beer && formData.batch) {
        codeReader.start();
      } else {
        codeReader.stop();
        if (videoRef && videoRef.srcObject) {
          videoRef.srcObject.getTracks().forEach((track: any) => {
            track.stop();
          });
        }
      }
    }
  }, [codeReader, formData.brewery, formData.beer, formData.batch, videoRef]);

  const handleSubmit = () => {
    const body = {
      date: Math.floor(Date.now() / 1000),
      sensorId: formData.sensorId,
      placeId: formData.brewery.value,
      beerName: formData.beer.label,
      batchId: formData.batch.value,
    };

    setResponseLoading(true);
    postFillKeg(source, body)
      .then((response) => {
        // On successful post, add scan to local table (or modify scan
        // if already present)
        setResponseMsg(response.message);
        setResponseBattery(response.battery);
        setResponseErr("");
        setFilledKegs((prev: any) => [
          ...prev.filter((prevScan: any) => {
            if (prevScan.sensorId === formData.sensorId) {
              return false;
            }
            return true;
          }, []),
          {
            ...body,
            placeId: formData.brewery.value,
            placeName: formData.brewery.label,
            batchName: formData.batch.label,
          },
        ]);
        setResponseLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setResponseErr(errToStr(err));
          setResponseLoading(false);
        }
      });
  };

  const handleManualSubmit = () => {
    const valid = exists("sensorId", formData.sensorId, setFormErrors);

    if (valid) {
      setResponseMsg("");
      setResponseBattery("");
      setResponseErr("");
      setFillModalOpen(true);
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setFormErrors((prev: any) => ({
      ...prev,
      [e.target.name]: undefined,
    }));
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({
      ...prev,
      batch: undefined,
      [action.name]: selected,
    }));
    setFormErrors((prev: any) => ({
      ...prev,
      batch: undefined,
      [action.name]: undefined,
    }));
    saveDefaults({ ...getDefaults(), [action.name]: selected });
  };

  const handleBatchSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({
      ...prev,
      [action.name]: selected,
    }));
    setFormErrors((prev: any) => ({
      ...prev,
      [action.name]: undefined,
    }));
    saveDefaults({ ...getDefaults(), [action.name]: selected });
  };

  // Loads the brewery options for the brewery select input
  // Once loaded, checks if it contains the default brewery.
  // If it does, update the selected brewery to the default.
  const loadBreweries = (inputValue: string, callback: any) => {
    fetchAutoComplete("breweries", inputValue).then((response) => {
      callback(response);
      if (response.length === 1) {
        setFormData((prev: any) => ({
          ...prev,
          brewery: response[0],
        }));
      } else {
        if (getDefaults().brewery && getDefaults().brewery.value) {
          if (response.some((brewery: any) => brewery.value === getDefaults().brewery.value)) {
            setFormData((prev: any) => ({
              ...prev,
              brewery: getDefaults().brewery,
            }));
          }
        }
      }
    });
  };

  // Loads the beer options for the beer select input
  // Once loaded, checks if it contains the default beer.
  // If it does, update the selected beer to the default.
  const loadBeers = (inputValue: string, callback: any) => {
    fetchAutoComplete("beers", inputValue).then((response) => {
      callback(response);
      if (getDefaults().beer && getDefaults().beer.value) {
        if (response.some((beer: any) => beer.value === getDefaults().beer.value)) {
          setFormData((prev: any) => ({
            ...prev,
            beer: getDefaults().beer,
          }));
        }
      }
    });
  };

  const columns = [
    {
      id: "date",
      Header: "Date",
      accessor: "date",
      Cell: (props: any) => moment.unix(props.value).format(short_datetime),
      minWidth: 160,
    },
    {
      id: "sensorId",
      Header: "Tracker ID",
      accessor: "sensorId",
      minWidth: 120,
      Cell: (props: any) => {
        return (
          <Link title={props.value} to={`/kegs/${props.value}`}>
            {props.value}
          </Link>
        );
      },
    },
    {
      id: "placeName",
      Header: "Brewery",
      accessor: "placeName",
      minWidth: 140,
      Cell: (props: any) => (
        <Link title={props.value} to={`/places/${props.original.placeId}`}>
          {props.value}
        </Link>
      ),
    },
    {
      id: "beerName",
      Header: "Beer",
      accessor: "beerName",
      minWidth: 140,
    },
    {
      id: "batchName",
      Header: "Batch",
      accessor: "batchName",
      minWidth: 140,
    },
  ];

  const defaultSorted = [
    {
      id: "date",
      desc: true,
    },
  ];

  return (
    <>
      <PageBreadcrumbs prevRoutes={[{ slug: "/scanner", title: "Scanner" }]} currRoute="Mark as Filled" />
      <PageContainer top="40px">
        <FormContainer>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <FormInputContainer style={{ marginBottom: "14px" }}>
              <label>Brewery</label>
              <AsyncSelect
                name="brewery"
                defaultOptions={true}
                isClearable={true}
                isSearchable={true}
                isError={formErrors.brewery}
                value={formData.brewery || ""}
                loadOptions={(inputValue: any, callback: any) => loadBreweries(inputValue, callback)}
                onChange={handleSelectChange}
                placeholder="Select..."
              />
            </FormInputContainer>
            <FormInputContainer style={{ marginBottom: "14px" }}>
              <label>Beer</label>
              <AsyncSelect
                name="beer"
                defaultOptions={true}
                isClearable={true}
                isSearchable={true}
                isError={formErrors.beer}
                value={formData.beer || ""}
                loadOptions={(inputValue: any, callback: any) => loadBeers(inputValue, callback)}
                onChange={handleSelectChange}
                placeholder="Select..."
              />
            </FormInputContainer>
            <FormInputContainer style={{ marginBottom: "14px" }}>
              <label>Batch</label>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%" }}>
                  <Select
                    name="batch"
                    options={batches}
                    isClearable={true}
                    isSearchable={true}
                    isError={formErrors.batch}
                    value={formData.batch || ""}
                    onChange={handleBatchSelectChange}
                    placeholder="Select..."
                    isLoading={batchesLoading}
                  />
                </div>
                <div>
                  <Tooltip content="Create Batch">
                    <OutlineBtn
                      onClick={() => {
                        setBatchModalOpen(true);
                      }}
                      style={{
                        height: "34px",
                        margin: "0 0 0 12px",
                        minWidth: "unset",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      padding="0 8px"
                    >
                      <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </OutlineBtn>
                  </Tooltip>
                </div>
              </div>
            </FormInputContainer>
            <div
              style={{
                display: !!formData.brewery && !!formData.beer && !!formData.batch ? "block" : "none",
              }}
            >
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <label>Scan Kegs</label>
                <div>
                  <video
                    id="video"
                    ref={videoRefCallback}
                    style={{
                      display: "inline-block",
                      width: "100%",
                      border: "1px solid gray",
                      borderRadius: "3px",
                    }}
                  />
                </div>
              </FormInputContainer>
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <label>Tracker ID</label>
                <FormInput
                  type="text"
                  name="sensorId"
                  value={formData.sensorId}
                  error={formErrors.sensorId}
                  onChange={handleChange}
                  style={{ height: "40px" }}
                />
                <FormError error={formErrors.sensorId}>{formErrors.sensorId}</FormError>
              </FormInputContainer>
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <PrimaryBtn width="100%" onClick={handleManualSubmit}>
                  Mark as Filled
                </PrimaryBtn>
              </FormInputContainer>
              <div
                style={{
                  paddingTop: "16px",
                  width: "100%",
                  float: "left",
                  display: filledKegs.length > 0 ? "block" : "none",
                }}
              >
                <TableHeaderButtons>
                  <div style={{ display: "flex" }}></div>
                  <CsvButtonsComponent data={filledKegs} formatCsv={formatDataToCsv} formatCsvParams={[tableRef, short_datetime]} fileName="Fill Report.csv" />
                </TableHeaderButtons>
                <Table style={{ clear: "both" }} data={filledKegs} columns={columns} defaultSorted={defaultSorted} ref={tableRef} />
              </div>
            </div>
            {formData.brewery &&
              formData.beer &&
              formData.batch &&
              (scanResponseMsg ? (
                <InfoModal
                  isOpen={fillModalOpen}
                  onClose={() => {
                    setResponseMsg("");
                    setResponseBattery("");
                    setResponseErr("");
                    setFillModalOpen(false);
                  }}
                  title="Mark as Filled"
                  okayBtnText="Okay"
                  body={
                    <ModalSuccess>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <ModalIconContainer>
                          {scanResponseBattery === "LOW" ? (
                            <DangerIcon fill={color.danger[2]} />
                          ) : scanResponseBattery === "MEDIUM" ? (
                            <WarningIcon fill={color.warning[2]} />
                          ) : (
                            <SuccessIcon fill={color.success[2]} />
                          )}
                        </ModalIconContainer>
                        <div>
                          {scanResponseMsg}
                          <br />
                          {scanResponseBattery === "LOW"
                            ? "Battery Level Low"
                            : scanResponseBattery === "MEDIUM"
                            ? "Battery Level Medium"
                            : scanResponseBattery === "HIGH"
                            ? "Battery Level High"
                            : ""}
                        </div>
                      </div>
                    </ModalSuccess>
                  }
                />
              ) : (
                <SubmitModal
                  isOpen={fillModalOpen}
                  setFillModalOpen
                  onSubmit={() => handleSubmit()}
                  onClose={() => {
                    setResponseMsg("");
                    setResponseBattery("");
                    setResponseErr("");
                    setFillModalOpen(false);
                  }}
                  title="Mark as Filled"
                  success={scanResponseMsg}
                  error={scanResponseErr}
                  body={
                    <LoadingContainer loading={scanResponseLoading}>
                      {formData.batch && formData.batch.date && moment().diff(moment.unix(formData.batch.date), "hours") >= 24 && (
                        <>
                          <p>
                            <Bold>
                              Warning: This is an older batch that was created{" "}
                              <ReactTimeago live={false} date={formData.batch.date * 1000} title={moment.unix(formData.batch.date).format(long_datetime)} />
                            </Bold>
                          </p>
                          <br />
                        </>
                      )}
                      <p>
                        Tracker ID: <Bold>{formData.sensorId}</Bold>
                      </p>
                      <p>
                        Brewery: <Bold>{formData.brewery.label}</Bold>
                      </p>
                      <p>
                        Beer: <Bold>{formData.beer.label}</Bold>
                      </p>
                      <p>
                        Batch: <Bold>{formData.batch.label}</Bold>
                      </p>
                    </LoadingContainer>
                  }
                />
              ))}
          </form>
        </FormContainer>
        {batchModalOpen && (
          <EditBeerBatchModal
            modalOpen={batchModalOpen}
            setModalOpen={setBatchModalOpen}
            placeId={formData.brewery ? formData.brewery.value : undefined}
            beerId={formData.beer ? formData.beer.value : undefined}
            onCreate={(response: any) => {
              if (response) {
                const newBatchOption = formatBatchOption(response.id, response.dateCreated, response.notes);
                setBatches((prev: any) => [newBatchOption, ...prev]);
                setFormData((prev: any) => ({
                  ...prev,
                  brewery: { value: response.placeId, label: response.placeName },
                  beer: { value: response.beerId, label: response.beerName },
                  batch: newBatchOption,
                }));
                setFormErrors((prev: any) => ({
                  ...prev,
                  brewery: undefined,
                  beer: undefined,
                  batch: undefined,
                }));
              }
            }}
          />
        )}
      </PageContainer>
    </>
  );
};

export default MarkAsFilled;
