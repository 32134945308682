import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { BsChatRightTextFill, BsThermometerHalf } from "react-icons/bs";
import { GiBackwardTime } from "react-icons/gi";
import { HiArrowCircleDown, HiArrowCircleRight, HiArrowCircleUp, HiExclamationCircle } from "react-icons/hi";
import { IoIosBeer } from "react-icons/io";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { ThemeContext } from "styled-components";
import { getToken } from "../../services/localStorage";
import { postPhoneScan } from "../../services/phoneScan";
import IoIosBeerEmpty from "../../svgs/IoIosBeerEmpty";
import TapIcon from "../../svgs/TapIcon";
import { isBinaryBeer, isBinaryLoop, isBinaryTech } from "../../util/checkDomain";
import { printCountryTemp } from "../../util/formatUnits";
import getSensorIcon from "../../util/getSensorIcon";
import isMobileDevice from "../../util/isMobileDevice";
import { kegOrTracker } from "../../util/kegOrTracker";
import { OutlineBtn, PrimaryBtn } from "../Buttons";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import PhoneScanFeedbackModal from "../PhoneScanFeedbackModal";
import PhoneScanLogsChart from "../PhoneScanLogsChart";
import PublicEditPickupRequestModal from "../PublicEditPickupRequestModal";
import PublicMarkAsCollectedModel from "../PublicMarkAsCollectedModel";
import ReactIcon from "../ReactIcon";
import { BoxElement, BoxesContainer, Footer, Icon, IconContainer } from "../SensorMeta/styles";
import { ContentContainer, Header, Value, ValueContainer } from "../TrackerInfoPanel/styles";
import TrackerMaintenanceAlert from "../TrackerMaintenanceAlert";
import { Section } from "./styles";

const PhoneScanMeta: FC<any> = ({ scanCode, meta, logs, series, fetchData, country, ownsTracker }) => {
  const { color, color_50, short_date, short_datetime, long_datetime } = useContext(ThemeContext);

  const [requestPickupModalOpen, setRequestPickupModalOpen] = useState<any>(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState<boolean>(false);
  const [markAsCollectedOpen, setMarkAsCollectedOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // If user is on a mobile device, get their location to update the tracker's location
  useEffect(() => {
    if (isMobileDevice()) {
      navigator.geolocation.getCurrentPosition((pos: any) => {
        const { coords } = pos;

        const body = {
          scanCode: scanCode,
          PhoneLatitude: coords.latitude,
          PhoneLongitude: coords.longitude,
          PhoneAccuracy: Math.round(coords.accuracy) || null,
        };

        postPhoneScan(source, body);
      });
    }
  }, [scanCode, source]);

  const {
    sensorId = null,
    latestSampleDate = null,
    productName = null,
    productId = null,
    temp = null,
    orientation = null,
    notes = null,
    beerName = null,
    dateFilled = null,
    freshness = null,
    empty = null,
    emptyDate = null,
    activated = null,
    showButtons = null,
    hasTemperature = null,
    hasOrientation = null,
    hasBeer = null,
    dateMarkedForPickup = null,
    pickupRequestTypeName = null,
    pickupRequestTypeColour = null,
    pickupRequestNotes = null,
    onTap = null,
    onTapDate = null,
    timesEmptied = null,
    batteryPercentage = null,
    sleepCount = null,
    sleepDuration = null,
    assetId = null,
    assetTypeName = null,
    assetTypeColour = null,
    assetTypeIcon = null,
    csq = null,
  } = meta ? meta : {};

  return (
    <>
      <Section style={{ maxWidth: "576px" }}>
        <div style={{ marginBottom: "10px", width: "100%" }}>
          <TrackerMaintenanceAlert
            sleepCount={sleepCount}
            sleepDuration={sleepDuration}
            latestSampleDate={latestSampleDate}
            batteryPercentage={batteryPercentage}
          />
        </div>
        {(sensorId != null || assetId != null || assetTypeName != null) && (
          <BoxesContainer>
            {sensorId != null && (
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>{getSensorIcon(productId, productName, color)}</Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>Tracker</Header>
                    <Value>{sensorId}</Value>
                    {productName != null && <Footer>{productName}</Footer>}
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            )}
            {(assetId != null || assetTypeName != null) && (
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <ReactIcon
                        name={assetTypeIcon != null ? assetTypeIcon : "GiCube"}
                        defaultColour={color_50.font_bold[2]}
                        colour={assetTypeColour ? assetTypeColour : color_50.font_bold[2]}
                        title={assetTypeName}
                        size="32px"
                      />
                    </Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>{assetId != null && assetTypeName != null ? "Asset" : assetId != null ? "Asset ID" : "Asset Type"}</Header>
                    <Value>{assetId != null ? assetId : assetTypeName}</Value>
                    {assetTypeName && assetId != null && <Footer>{assetTypeName}</Footer>}
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            )}
          </BoxesContainer>
        )}
        {latestSampleDate != null && (
          <BoxesContainer>
            <BoxElement>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                      <GiBackwardTime />
                    </IconContext.Provider>
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Last Seen</Header>
                  <Value>
                    <ReactTimeago live={false} date={latestSampleDate * 1000} title={moment.unix(latestSampleDate).format(long_datetime)} />
                  </Value>
                  <Footer>{moment.unix(latestSampleDate).format(short_datetime)}</Footer>
                </ValueContainer>
              </ContentContainer>
            </BoxElement>
          </BoxesContainer>
        )}
        {!isBinaryLoop() && (hasOrientation === true || hasTemperature === true) && (orientation != null || temp != null) && (
          <BoxesContainer>
            {temp != null && (
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                        <BsThermometerHalf />
                      </IconContext.Provider>
                    </Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>Temperature</Header>
                    <Value>{printCountryTemp(temp, 2, country)}</Value>
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            )}
            {orientation != null && (
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                        {orientation === "S" ? <HiArrowCircleRight /> : orientation === "D" ? <HiArrowCircleDown /> : <HiArrowCircleUp />}
                      </IconContext.Provider>
                    </Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>Orientation</Header>
                    <Value>{orientation === "S" ? "Sideways " : orientation === "D" ? "Upside Down " : "Upright "}</Value>
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            )}
          </BoxesContainer>
        )}
        {dateMarkedForPickup != null && (
          <BoxesContainer>
            <BoxElement style={{ display: "flex", flexDirection: "column" }}>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                      <HiExclamationCircle />
                    </IconContext.Provider>
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Marked for Pickup</Header>
                  <Value>
                    <ReactTimeago live={false} date={dateMarkedForPickup * 1000} title={moment.unix(dateMarkedForPickup).format(long_datetime)} />
                  </Value>
                  <Footer>{moment.unix(dateMarkedForPickup).format(short_datetime)}</Footer>
                </ValueContainer>
              </ContentContainer>
              <ContentContainer
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: "3px",
                }}
              >
                {pickupRequestTypeName != null && (
                  <Footer>
                    {isBinaryLoop() ? "Contents" : "Pickup Request Type"}:{" "}
                    {pickupRequestTypeColour != null && <ColoredDot color={pickupRequestTypeColour} margin="0 4px" />}
                    {pickupRequestTypeName}
                  </Footer>
                )}
                {pickupRequestNotes !== null && <Footer title={`Notes: ${pickupRequestNotes}`}>Notes: {pickupRequestNotes}</Footer>}
              </ContentContainer>
            </BoxElement>
          </BoxesContainer>
        )}
        {isBinaryBeer() && hasBeer === true && (
          <BoxesContainer>
            <BoxElement style={{ display: "flex", flexDirection: "column" }}>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    {onTap === true ? (
                      <TapIcon width="32" height="32" fill={color_50.font_bold[2]} />
                    ) : (
                      <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                        {empty === false ? <IoIosBeer /> : <IoIosBeerEmpty width="32px" height="32px" fill={color_50.font_bold[2]} />}
                      </IconContext.Provider>
                    )}
                  </Icon>
                </IconContainer>
                <ValueContainer style={{ justifyContent: "center" }}>
                  <Header>Keg Status</Header>
                  {onTap === true ? (
                    <>
                      <Value>On tap {beerName != null && <>with {beerName}</>}</Value>
                      {freshness != null && <Footer>{freshness}% fresh</Footer>}
                    </>
                  ) : empty === false ? (
                    <>
                      <Value>
                        Filled{" "}
                        {beerName && (
                          <>
                            with <span title={beerName}>{beerName}</span>
                          </>
                        )}
                      </Value>
                      {freshness != null && <Footer>{freshness}% fresh</Footer>}
                    </>
                  ) : empty === true && timesEmptied > 0 ? (
                    <>
                      <Value>
                        Emptied{" "}
                        {beerName && (
                          <>
                            with <span title={beerName}>{beerName}</span>
                          </>
                        )}
                      </Value>
                      {freshness != null && <Footer>{freshness}% fresh when emptied</Footer>}
                    </>
                  ) : empty !== false && !(timesEmptied > 0) ? (
                    <Value>Never filled</Value>
                  ) : (
                    <></>
                  )}
                </ValueContainer>
              </ContentContainer>
              <ContentContainer
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: "3px",
                }}
              >
                {emptyDate && (
                  <Footer>
                    Emptied <ReactTimeago live={false} date={emptyDate * 1000} title={moment.unix(emptyDate).format(long_datetime)} /> (
                    {moment.unix(emptyDate).format(short_date)}){" "}
                  </Footer>
                )}
                {onTap === true && onTapDate != null && (
                  <Footer>
                    Tapped <ReactTimeago live={false} date={onTapDate * 1000} title={moment.unix(onTapDate).format(long_datetime)} /> (
                    {moment.unix(onTapDate).format(short_date)})
                  </Footer>
                )}
                {dateFilled != null && (
                  <Footer>
                    Filled <ReactTimeago live={false} date={dateFilled * 1000} title={moment.unix(dateFilled).format(long_datetime)} /> (
                    {moment.unix(dateFilled).format(short_date)}){" "}
                  </Footer>
                )}
              </ContentContainer>
            </BoxElement>
          </BoxesContainer>
        )}
        {notes && (
          <BoxesContainer>
            <BoxElement>
              <ContentContainer>
                <IconContainer>
                  <Icon>
                    <IconContext.Provider value={{ color: color_50.font_bold[2], size: "28px" }}>
                      <BsChatRightTextFill />
                    </IconContext.Provider>
                  </Icon>
                </IconContainer>
                <ValueContainer>
                  <Header>Notes</Header>
                  <Value title={notes} style={{ fontSize: "14px", lineHeight: "17px" }}>
                    {notes}
                  </Value>
                </ValueContainer>
              </ContentContainer>
            </BoxElement>
          </BoxesContainer>
        )}
        {(isBinaryBeer() || isBinaryTech()) && logs.length > 1 && hasTemperature && (
          <BoxesContainer>
            <BoxElement>
              <PhoneScanLogsChart meta={meta} logs={logs} seriesNames={series} country={country} />
            </BoxElement>
          </BoxesContainer>
        )}
        {(showButtons || ownsTracker) && activated && dateMarkedForPickup == null && (
          <PrimaryBtn
            width="100%"
            style={{ height: "80px", marginBottom: "10px", fontSize: "16px", lineHeight: "21px", fontWeight: 500 }}
            onClick={() => setRequestPickupModalOpen(true)}
          >
            Request Pickup
          </PrimaryBtn>
        )}
        {(showButtons || ownsTracker) && activated && dateMarkedForPickup != null && (
          <PrimaryBtn
            width="100%"
            style={{ height: "80px", marginBottom: "10px", fontSize: "16px", lineHeight: "21px", fontWeight: 500 }}
            onClick={() => setMarkAsCollectedOpen(true)}
          >
            Mark as Collected
          </PrimaryBtn>
        )}
        {activated ? (
          <Link style={{ textDecoration: "none", width: "100%" }} to={`/${kegOrTracker("kegs", "trackers")}/${sensorId}`}>
            <PrimaryBtn width="100%" style={{ height: "80px", marginBottom: "10px", fontSize: "16px", lineHeight: "21px", fontWeight: 500 }}>
              View {isBinaryLoop() ? "Asset" : "Tracker"}
            </PrimaryBtn>
          </Link>
        ) : (
          <Link style={{ textDecoration: "none", width: "100%" }} to={`/activate-tracker?sensorId=${sensorId}&activateSensor=true`}>
            <OutlineBtn width="100%" style={{ marginBottom: "10px" }}>
              Activate Tracker
            </OutlineBtn>
          </Link>
        )}
        {activated && showButtons && (
          <OutlineBtn width="100%" style={{ marginBottom: "10px" }} onClick={() => setFeedbackModalOpen(true)}>
            Feedback
          </OutlineBtn>
        )}
        {getToken() == null && (
          <Link style={{ textDecoration: "none", width: "100%" }} to={`/sign-in`}>
            <OutlineBtn width="100%" style={{ marginBottom: "10px" }}>
              Sign In
            </OutlineBtn>
          </Link>
        )}
      </Section>
      {requestPickupModalOpen && (
        <PublicEditPickupRequestModal
          scanCode={scanCode}
          trackerId={sensorId}
          onSuccess={fetchData}
          modalOpen={requestPickupModalOpen}
          setModalOpen={setRequestPickupModalOpen}
        />
      )}
      {feedbackModalOpen && <PhoneScanFeedbackModal scanCode={scanCode} modalOpen={feedbackModalOpen} setModalOpen={setFeedbackModalOpen} />}
      {markAsCollectedOpen && (
        <PublicMarkAsCollectedModel scanCode={scanCode} modalOpen={markAsCollectedOpen} setModalOpen={setMarkAsCollectedOpen} onSuccess={fetchData} />
      )}
    </>
  );
};

export default PhoneScanMeta;
