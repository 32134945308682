import React, { FC, useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { FormInput, FormError, InfoIconContainer } from "../FormComponents";
import { exists, maxLength } from "../../util/formValidations";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import Checkbox from "../Checkbox";
import InfoIcon from "../../svgs/Legend";
import InfoTooltip from "../Tooltip";
import { putEditOrganisation } from "../../services/editOrganisation";
import addOrUpdate from "../../util/addOrUpdate";

const defaultOrg = {
  id: "",
  name: "",
  allowPublicScans: false,
  allowPublicButtons: false,
  enabled: false,
};

const initOrgForm = (org: any) => {
  return {
    ...defaultOrg,
    ...org,
  };
};

const EditOrganisationModal: FC<any> = ({ org, setData, modalOpen, setModalOpen }) => {
  const { color } = useContext(ThemeContext);

  const [formData, setFormData] = useState<any>(initOrgForm(org));
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "name":
          currValid = exists(name, value, setFormErrors) && maxLength(name, value, 200, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const handleSubmit = () => {
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      putEditOrganisation(source, formData)
        .then((response) => {
          setData((prev: any) => addOrUpdate(prev, response, "id"));
          setSubmittedMsg("Organisation Updated");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleCheckboxChange = (e: any) => {
    e.persist();
    setFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      title="Edit Organisation"
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Update Organisation"
      body={
        <LoadingContainer loading={submitting}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer>
              <label>Name</label>
              <FormInput type="text" name="name" value={formData.name} error={formErrors.name} onChange={handleChange} />
              <FormError error={formErrors.name}>{formErrors.name}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <Checkbox
                style={{ display: "inline-block" }}
                name="allowPublicScans"
                label="Public Scans"
                checked={formData.allowPublicScans}
                onChange={handleCheckboxChange}
              />
              <div
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  marginBottom: "-3px",
                }}
              >
                <InfoTooltip
                  content="This enables the public scan page that appears when a sensor's QR code is scanned"
                  interactive={true}
                  touch={true}
                  appendTo={document.body}
                >
                  <InfoIconContainer>
                    <InfoIcon fill={color.font[2]} />
                  </InfoIconContainer>
                </InfoTooltip>
              </div>
            </ModalFormContainer>
            <ModalFormContainer>
              <Checkbox
                style={{ display: "inline-block" }}
                name="allowPublicButtons"
                label="Public Buttons"
                checked={formData.allowPublicScans ? formData.allowPublicButtons : false}
                onChange={handleCheckboxChange}
                disabled={!formData.allowPublicScans}
              />
              <div
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  marginBottom: "-3px",
                }}
              >
                <InfoTooltip
                  content="This shows the request pickup and feedback buttons on the public scan page"
                  interactive={true}
                  touch={true}
                  appendTo={document.body}
                >
                  <InfoIconContainer>
                    <InfoIcon fill={color.font[2]} />
                  </InfoIconContainer>
                </InfoTooltip>
              </div>
            </ModalFormContainer>
            <ModalFormContainer>
              <Checkbox style={{ display: "inline-block" }} name="enabled" label="Enabled" checked={formData.enabled} onChange={handleCheckboxChange} />
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default EditOrganisationModal;
