import React, { FC, useContext, useRef } from "react";
import { ThemeContext } from "styled-components";
import Table, { CsvButtonsComponent } from "../Table";
import moment from "moment";
import matchSorter from "match-sorter";
import { TableHeaderButtons } from "./../NewTable/styles";
import FlatpickrRangePicker from "../FlatpickrRangePicker";
import { kegsOrTrackers } from "../../util/kegOrTracker";

const formatDataToCsv = (tableRef: any) => {
  const headers = ["Tracker ID", "Date Created"];

  const sortedData = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...sortedData.map((row: any) => {
      return [row.sensorId, moment.unix(row.dateCreated).format()];
    }, []),
  ];
};

const CreateSensorsTable: FC<any> = ({ data }) => {
  const { short_datetime, short_date } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);

  const columns = [
    {
      id: "sensorId",
      Header: "Tracker ID",
      accessor: "sensorId",
      minWidth: 180,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["sensorId"],
        }),
      filterAll: true,
    },
    {
      id: "dateCreated",
      Header: "Date Created",
      accessor: "dateCreated",
      minWidth: 180,
      filterMethod: (filter: any, row: any) => {
        if (filter.value.length === 2) {
          if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
          else return false;
        } else return true;
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
      ),
      Cell: (props: any) => (props.value ? moment.unix(props.value).format(short_datetime) : ""),
    },
  ];

  const defaultSorted = [
    {
      id: "dateCreated",
      desc: true,
    },
  ];

  return (
    <>
      <TableHeaderButtons>
        <div style={{ display: "flex" }}></div>
        <CsvButtonsComponent
          data={data}
          formatCsv={formatDataToCsv}
          formatCsvParams={[tableRef]}
          fileName={kegsOrTrackers("New Kegs.csv", "New Trackers.csv")}
        />
      </TableHeaderButtons>
      <Table filterable={true} style={{ clear: "both" }} data={data} columns={columns} defaultSorted={defaultSorted} ref={tableRef} defaultPageSize={10} />
    </>
  );
};

export default CreateSensorsTable;
