import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import moment from "moment";
import queryString from "query-string";
import Drawer from "rc-drawer";
import { FC, useContext, useEffect, useRef, useState } from "react";
import Flatpickr from "react-flatpickr";
import { IconContext } from "react-icons";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { TbDownload } from "react-icons/tb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { fetchAutoComplete } from "../../../services/autoComplete";
import { fetchLocationAudit } from "../../../services/locationAudit";
import Cross from "../../../svgs/Cross";
import downloadFile from "../../../util/downloadFile";
import errToStr from "../../../util/errToStr";
import getParameterByName from "../../../util/getParamByName";
import { kegsOrTrackers } from "../../../util/kegOrTracker";
import { PrimaryBtn } from "../../Buttons";
import { DrawBody, DrawCloseButton, DrawContent, DrawFooter, DrawHeader, FilterInputContainer } from "../../Drawer/styles";
import LoadingContainer from "../../LoadingContainer";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import { Subtitle, Title } from "../../PageTitle/styles";
import { ClearPanel, Panel, StatPanel } from "../../Panel";
import { AsyncSelect } from "../../Select";
import Table from "../../Table";
import TrackerListModal from "../../TrackerListModal";
import { StatBlock, StatLabel, StatValue } from "../styles";

const formatDataToCsv = (tableRef: any) => {
  const headers = ["Place Name", "Place Type", kegsOrTrackers("Keg Count", "Tracker Count")];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [row.placeName, row.placeGroup, row.trackers ? row.trackers.length : 0];
    }, []),
  ];
};

const LocationAudit: FC<any> = () => {
  const { color, short_datetime, long_datetime } = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const tableRef = useRef<any>(null);

  const [data, setData] = useState<any>({
    places: [],
    totalPlaces: 0,
    trackersAtPlaces: [],
    trackersInTransit: [],
  });
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [trackerList, setTrackerList] = useState<any>([]);
  const [trackerListModalOpen, setTrackerListModalOpen] = useState<boolean>(false);

  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  // Report filters
  const [date, setDate] = useState<any>(new Date());
  const [filteredDate, setFilteredDate] = useState<any>(date);

  const [trackerTags, setTrackerTags] = useState<any>([]);
  const [assetType, setAssetType] = useState<any>(null);

  const [appliedFilters, setAppliedFilters] = useState<number>(0);

  const [autoCompleteTrackerTags, setAutoCompleteTrackerTags] = useState<any>(undefined);
  const [autoCompleteAssetTypes, setAutoCompleteAssetTypes] = useState<any>(undefined);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Fetch all tracker tags and asset types from autoComplete to use for matching params
  // e.g. match "?asset-types=Keg" to {colour: "#e6b600", icon: "BbKeg", label: "Keg", value: "b08d3cf1-cb6e-4026-998b-5702ca4a4829"}
  useEffect(() => {
    const tagsParam = getParameterByName("tags", location.search) !== null ? getParameterByName("tags", location.search)?.split(",") : [];
    const assetTypeParam = getParameterByName("asset-type", location.search) !== null ? getParameterByName("asset-type", location.search) : null;

    fetchAutoComplete("trackertags", "").then((response) => {
      if (tagsParam && tagsParam.length > 0) {
        setTrackerTags(response.filter((tag: any) => tagsParam.includes(tag.label)));
      }
      setAutoCompleteTrackerTags(response);
    });

    fetchAutoComplete("assettypes", "").then((response) => {
      if (assetTypeParam !== null) {
        setAssetType(response.find((type: any) => assetTypeParam === type.label));
      }
      setAutoCompleteAssetTypes(response);
    });
  }, []);

  // On location change (e.g. url parameter changes because filters changed), update filters and fetch report
  useEffect(() => {
    let filteredTrackerTags = [];
    let filteredAssetType = undefined;
    let selectedDate = undefined;

    if (autoCompleteTrackerTags !== undefined) {
      const tagsParam = getParameterByName("tags", location.search) !== null ? getParameterByName("tags", location.search)?.split(",") : [];
      filteredTrackerTags = autoCompleteTrackerTags.filter((type: any) => tagsParam?.includes(type.label));
      setTrackerTags(filteredTrackerTags);
    }

    if (autoCompleteAssetTypes !== undefined) {
      const assetTypeParam = getParameterByName("asset-type", location.search) !== null ? getParameterByName("asset-type", location.search) : null;
      filteredAssetType = autoCompleteAssetTypes.find((type: any) => assetTypeParam === type.label);
      setAssetType(filteredAssetType);
    }

    const dateParam = getParameterByName("date", location.search) !== null ? getParameterByName("date", location.search) : null;
    if (dateParam !== null) {
      selectedDate = new Date(Number(dateParam) * 1000);
      setDate(selectedDate);
    }

    fetchReport(filteredTrackerTags, filteredAssetType, selectedDate);
  }, [location]);

  // After autoCompleteTrackerTags & autoCompleteAssetTypes is initially set on mount, fetch report
  useEffect(() => {
    if (autoCompleteTrackerTags !== undefined && autoCompleteAssetTypes !== undefined) {
      fetchReport();
    }
  }, [autoCompleteTrackerTags, autoCompleteAssetTypes]);

  const updateQueryParams = () => {
    const parsed = queryString.parse(location.search);
    const newQuery = { ...parsed };

    if (trackerTags && trackerTags.length > 0) {
      newQuery["tags"] = trackerTags.map((tag: any) => tag.label).join(",");
    } else if (autoCompleteTrackerTags !== undefined) {
      delete newQuery["tags"];
    }

    if (assetType !== null) {
      newQuery["asset-type"] = assetType?.label;
    } else if (autoCompleteAssetTypes !== undefined) {
      delete newQuery["asset-type"];
    }

    if (date !== null) {
      newQuery["date"] = Math.round(date.getTime() / 1000).toString();
    } else {
      delete newQuery["date"];
    }

    const stringified = queryString.stringify(newQuery);
    navigate({ ...location, search: stringified });
  };

  const fetchReport = (tags?: any, type?: any, selectedDate?: any) => {
    setDataLoading(true);
    setDataErr("");

    const tagsArg = tags?.length > 0 ? tags : trackerTags;
    const assetTypeArg = type !== undefined ? type : assetType;
    const dateArg = selectedDate !== undefined ? selectedDate : date;

    fetchLocationAudit(source, tagsArg.map((tag: any) => tag.label).join(","), assetTypeArg?.label, dateArg ? Math.round(dateArg.getTime() / 1000) : undefined)
      .then((response) => {
        setFilteredDate(dateArg);
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData({
            places: [],
            totalPlaces: 0,
            trackersAtPlaces: [],
            trackersInTransit: [],
          });
          const resetDate = new Date();
          setFilteredDate(resetDate);
          setDate(resetDate);
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    let filters = 0;

    if (tagsArg.length) filters += tagsArg.length;
    if (assetTypeArg) filters++;
    if (dateArg) filters++;

    setAppliedFilters(filters);
  };

  const columns = [
    {
      Header: "Place",
      columns: [
        {
          id: "placeName",
          Header: "Name",
          accessor: "placeName",
          Cell: (props: any) => (props.original.placeId ? <Link to={`/places/${props.original.placeId}`}>{props.value}</Link> : props.value),
          minWidth: 310,
        },
        {
          id: "placeGroup",
          Header: "Place Type",
          accessor: "placeGroup",
          minWidth: 130,
        },
        {
          id: "trackers",
          Header: kegsOrTrackers("Keg Count", "Tracker Count"),
          accessor: "trackers",
          Cell: (props: any) =>
            !props.value ? (
              0
            ) : props.value.length === 0 ? (
              props.value.length
            ) : (
              <div
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => {
                  setTrackerList(props.value);
                  setTrackerListModalOpen(true);
                }}
              >
                {props.value.length}
              </div>
            ),
          minWidth: 130,
        },
      ],
    },
  ];

  const defaultSorted = [
    {
      id: "trackers",
      desc: true,
    },
    {
      id: "placeName",
      desc: false,
    },
  ];

  const handleDateChange = (d: any) => {
    setDate(d[0]);
  };

  const TrackerListStat = (trackers: any) =>
    !trackers ? (
      0
    ) : trackers.length === 0 ? (
      trackers.length
    ) : (
      <span
        style={{ cursor: "pointer", textDecoration: "underline" }}
        onClick={() => {
          setTrackerList(trackers);
          setTrackerListModalOpen(true);
        }}
      >
        {trackers.length}
      </span>
    );

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  return (
    <>
      <Drawer
        placement="right"
        level={null}
        open={filtersOpen}
        onClose={() => {
          setFiltersOpen(false);
          updateQueryParams();
        }}
        onHandleClick={() => setFiltersOpen(!filtersOpen)}
        handler={false}
      >
        <DrawContent>
          <DrawHeader>
            <div
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                fontSize: "0",
                marginRight: "6px",
              }}
            >
              <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                <HiOutlineAdjustmentsHorizontal />
              </IconContext.Provider>
            </div>
            Filters
            <DrawCloseButton
              onClick={() => {
                setFiltersOpen(false);
              }}
              aria-label={`Close filters`}
            >
              <Cross />
            </DrawCloseButton>
          </DrawHeader>
          <DrawBody>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <label>Filtered Date</label>
              <FilterInputContainer>
                <Flatpickr data-enable-time value={date} onChange={handleDateChange} options={{ maxDate: Date.now() }} />
              </FilterInputContainer>
              <label>Asset Type</label>
              <FilterInputContainer>
                <AsyncSelect
                  name="assetType"
                  defaultOptions={true}
                  isClearable={true}
                  isSearchable={true}
                  value={assetType}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("assetTypes", inputValue, callback)}
                  onChange={(selected: any) => {
                    if (selected) {
                      setAssetType(selected);
                    } else {
                      setAssetType(null);
                    }
                  }}
                  placeholder="Select..."
                />
              </FilterInputContainer>
              <label>{kegsOrTrackers("Keg Tags", "Tracker Tags")}</label>
              <FilterInputContainer>
                <AsyncSelect
                  name="trackerTags"
                  defaultOptions={true}
                  closeMenuOnSelect={false}
                  isClearable={true}
                  isMulti={true}
                  isSearchable={true}
                  value={trackerTags}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("trackerTags", inputValue, callback)}
                  onChange={(selected: any) => {
                    if (selected) {
                      setTrackerTags(selected);
                    } else {
                      setTrackerTags([]);
                    }
                  }}
                  placeholder="Select..."
                />
              </FilterInputContainer>
            </form>
          </DrawBody>
          <DrawFooter>
            <PrimaryBtn
              onClick={() => {
                setFiltersOpen(false);
                updateQueryParams();
              }}
            >
              Apply Filters
            </PrimaryBtn>
          </DrawFooter>
        </DrawContent>
      </Drawer>
      <PageBreadcrumbs prevRoutes={[{ slug: "/reports", title: "Reports" }]} currRoute="Location Audit" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading} err={dataErr}>
            <ClearPanel style={{ textAlign: "center" }}>
              <Title style={{ marginBottom: "6px" }}>Location Audit</Title>
              <Subtitle>Review your fleet distribution at a specified date/time</Subtitle>
            </ClearPanel>
            <ClearPanel style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <PrimaryBtn style={{ height: "40px", minWidth: "unset", marginRight: "12px" }} padding="0 6px" onClick={() => setFiltersOpen(!filtersOpen)}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div
                      style={{
                        display: "inline-block",
                        width: "20px",
                        height: "20px",
                        fontSize: "0",
                        marginRight: "6px",
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiOutlineAdjustmentsHorizontal />
                      </IconContext.Provider>
                    </div>
                    <span>Filters</span>
                  </div>
                </PrimaryBtn>
                <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                  {appliedFilters} filter{appliedFilters === 1 ? "" : "s"} applied
                </span>
              </div>
              <PrimaryBtn
                style={{ height: "40px", minWidth: "unset", marginRight: "12px" }}
                padding="0 6px"
                onClick={() =>
                  downloadFile(
                    stringify(formatDataToCsv(tableRef), {
                      quoted: true,
                      quoted_string: true,
                    }),
                    "text/csv;charset=utf-8",
                    "Location Audit.csv"
                  )
                }
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "inline-block",
                      width: "20px",
                      height: "20px",
                      fontSize: "0",
                      marginRight: "6px",
                    }}
                  >
                    <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                      <TbDownload />
                    </IconContext.Provider>
                  </div>
                  <span
                    style={{
                      marginRight: "6px",
                    }}
                  >
                    CSV
                  </span>
                </div>
              </PrimaryBtn>
            </ClearPanel>
            <StatPanel>
              <StatBlock>
                <StatLabel>Filtered Date</StatLabel>
                <StatValue title={moment.unix(filteredDate / 1000).format(long_datetime)}>{moment.unix(filteredDate / 1000).format(short_datetime)}</StatValue>
              </StatBlock>
            </StatPanel>
            <StatPanel>
              <StatBlock style={{ width: "260px" }}>
                <StatLabel>Total Places</StatLabel>
                <StatValue>{data.totalPlaces}</StatValue>
              </StatBlock>
              <StatBlock style={{ width: "260px" }}>
                <StatLabel>Total {kegsOrTrackers("Kegs", "Trackers")}</StatLabel>
                <StatValue>{TrackerListStat([...data.trackersAtPlaces, ...data.trackersInTransit])}</StatValue>
              </StatBlock>
              <StatBlock style={{ width: "260px" }}>
                <StatLabel>{kegsOrTrackers("Kegs", "Trackers")} At Places</StatLabel>
                <StatValue>{TrackerListStat(data.trackersAtPlaces)}</StatValue>
              </StatBlock>
              <StatBlock style={{ width: "260px" }}>
                <StatLabel>{kegsOrTrackers("Kegs", "Trackers")} In Transit</StatLabel>
                <StatValue>{TrackerListStat(data.trackersInTransit)}</StatValue>
              </StatBlock>
            </StatPanel>
            <Panel>
              <Table loading={dataLoading} style={{ clear: "both" }} data={data.places} columns={columns} defaultSorted={defaultSorted} ref={tableRef} />
            </Panel>
          </LoadingContainer>
        </div>
      </PageContainer>
      {trackerList?.length > 0 && (
        <TrackerListModal
          data={trackerList}
          columns={["id", "assetTypeName", "trackerTags"]}
          modalOpen={trackerListModalOpen}
          setModalOpen={setTrackerListModalOpen}
          onClose={() => setTrackerList([])}
        />
      )}
    </>
  );
};

export default LocationAudit;
