import styled from "../Themes";

export const Div = styled(`div`)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: flex-start;
`;

export const Img = styled(`img`)`
  width: ${({ theme }) => theme.space[5]};
  margin-right: ${({ theme }) => theme.space[2]};
`;

export const P = styled(`p`)`
  line-height: ${({ theme }) => theme.space[5]};
  color: ${({ theme }) => theme.color.error[1]};

  span {
    color: ${({ theme }) => theme.color.font_bold[2]};
  }
`;
