import { Link } from "react-router-dom";
import styled from "../Themes";

export const ButtonsContainer = styled(`div`)`
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
`;

export const BigButton = styled(Link)`
  display: flex;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
  margin: 12px;
  padding: 26px 12px;
  height: 100px;
  width: 240px;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
  }

  &:hover {
    background: ${({ theme }) => theme.color.tooltip_hover[2]};
  }
`;

export const ButtonContent = styled(`div`)`
  display: flex;
  align-items: center;
`;

export const IconContainer = styled(`div`)`
  fill: ${({ theme }) => theme.color.font_bold[2]};
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  margin-right: 16px;
`;

export const ButtonText = styled(`p`)`
  font-weight: 500;
`;

export const VideoContainer = styled(`div`)`
  margin: 12px;
  display: flex;
  justify-content: center;
`;
