import axios, { CancelTokenSource } from "axios";
import { getToken } from "../localStorage";

type Props = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: any;
  filters?: any;
};

export const fetchPlaceProductsTable = async (source: CancelTokenSource, id: string, dates: any, { pageIndex, pageSize, orderBy, filters }: Props) => {
  const params: any = {
    start: dates ? dates.start : undefined,
    end: dates ? dates.end : undefined,
    count: true,
  };

  if (pageIndex !== undefined && pageSize !== undefined) params.skip = pageIndex * pageSize;

  if (pageSize !== undefined) params.top = pageSize;

  if (orderBy && orderBy.length > 0) params.orderby = orderBy.map((order: any) => `${order.id} ${order.desc ? "desc" : "asc"}`).join(", ");

  if (filters) params.filter = filters;

  const { data, headers } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/placeproductstable/" + id,
    params,
  });

  return { count: headers?.count, data };
};
