import { createGlobalStyle } from "../Themes";

export default createGlobalStyle`
  .rc-collapse {
    background-color:  ${({ theme }) => theme.color.panel_bg[2]};
    border-radius: 3px;
    border: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
  }
  .rc-collapse-anim-active {
    transition: height 0.2s ease-out;
  }
  .rc-collapse > .rc-collapse-item {
    position: relative;
    border-top: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
  }
  .rc-collapse > .rc-collapse-item:first-child {
    border-top: none;
  }
  .rc-collapse > .rc-collapse-item > .rc-collapse-header {
    display: flex;
    align-items: center;
    line-height: 22px;
    padding: 10px 16px;
    color: ${({ theme }) => theme.color.font_bold[2]};
    cursor: pointer;
    font-weight: 500;
  }
  .rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
    display: inline-block;
    position: absolute;
    top: 19px;
    right: 12px;
    content: "";
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 4px solid ${({ theme }) => theme.color.font_bold[2]};
    vertical-align: middle;
    margin-right: 8px;
  }
  .rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra {
    margin: 0 16px 0 auto;
  }
  .rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
    cursor: not-allowed;
    color: ${({ theme }) => theme.color.disabled[4]};
    background-color: ${({ theme }) => theme.color.disabled[2]};
  }
  .rc-collapse-content {
    overflow: hidden;
    color: ${({ theme }) => theme.color.font[2]};
    padding: 0;
    background-color: ${({ theme }) => theme.color.main_bg[2]};
  }
  .rc-collapse-content > .rc-collapse-content-box {
    margin: 0;
    min-height: 48px;
  }
  .rc-collapse-content-inactive {
    display: none;
  }
  .rc-collapse-item:last-child > .rc-collapse-content {
    border-radius: 0 0 3px 3px;
  }
  .rc-collapse > .rc-collapse-item-active > .rc-collapse-header {
    border-bottom: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
  }
  .rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow {    
    position: absolute;
    top: 20px;
    right: 12px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid ${({ theme }) => theme.color.font_bold[2]};
    margin-right: 6px;
  }
`;
