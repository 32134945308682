import React, { FC } from "react";
import { BadgeContainer, BadgeText } from "./styles";

const Badge: FC<any> = ({ children, title, background }) => {
  return (
    <BadgeContainer title={title}>
      <BadgeText background={background}>{children}</BadgeText>
    </BadgeContainer>
  );
};

export default Badge;
