import React, { FC, useState, useEffect } from "react";
// @ts-ignore
import Slider from "rc-slider";
import { FormInput } from "../FormComponents";

const Range = Slider.Range;

const RangeSlider: FC<any> = ({ value, onChange, onAfterChange, setMinVal, setMaxVal, minRange = 0, maxRange = 9999, exceedsMax = false, unit }) => {
  const [min, setMin] = useState<any>(value[0]);
  const [max, setMax] = useState<any>(value[1]);

  useEffect(() => {
    setMin(value[0]);
    setMax(value[1]);
  }, [value]);

  const handleMinKeyDown = (e: any) => {
    // 13 = ENTER
    if (e.keyCode === 13) {
      handleMinBlur({ target: { value: min } });
    }
  };

  const handleMaxKeyDown = (e: any) => {
    // 13 = ENTER
    if (e.keyCode === 13) {
      handleMaxBlur({ target: { value: max } });
    }
  };

  const handleMinBlur = (e: any) => {
    const val = e.target.value;
    const regex = /^[-]?[0-9\b]+$/;

    // set value if number else don't change
    if (val === "" || regex.test(val)) {
      const num = +val;
      if (num < minRange) setMinVal(minRange);
      else if (num > value[1]) setMinVal(value[1]);
      else setMinVal(num);
    } else {
      setMinVal(value[0]);
    }
  };

  const handleMaxBlur = (e: any) => {
    const val = e.target.value;
    const regex = /^[-]?[0-9\b]+[+]?$/;
    const regexWithPlus = /^[-]?[0-9\b]+[+]$/;

    // set value if number or number followed by a '+' else don't change
    if (val === "" || regex.test(val)) {
      // removes last character if '+'
      if (regexWithPlus.test(val)) {
        const num = +val.slice(0, -1);
        if (num > maxRange) setMaxVal(maxRange);
        else if (num < value[0]) setMaxVal(value[0]);
        else setMaxVal(num);
      } else {
        const num = +val;
        if (num > maxRange) setMaxVal(maxRange);
        else if (num < value[0]) setMaxVal(value[0]);
        else setMaxVal(num);
      }
    } else {
      setMax(value[1]);
    }
  };

  return (
    <>
      <div style={{ display: "flex", flexFlow: "column" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: "16px",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "left" }}>
            {unit && <label style={{ display: "block", margin: "auto", fontSize: "0.8rem" }}>min {unit}</label>}
            <FormInput
              style={{
                width: "75%",
                textAlign: "center",
              }}
              type="text"
              value={min}
              onChange={(e: any) => {
                setMin(e.target.value);
              }}
              onKeyDown={handleMinKeyDown}
              onBlur={handleMinBlur}
            />
          </div>
          <div style={{ textAlign: "right" }}>
            {unit && <label style={{ display: "block", margin: "auto", fontSize: "0.8rem" }}>max {unit}</label>}
            <FormInput
              style={{
                width: "75%",
                textAlign: "center",
              }}
              type="text"
              value={max === maxRange && exceedsMax ? `${max}+` : max}
              onChange={(e: any) => {
                setMax(e.target.value);
              }}
              onKeyDown={handleMaxKeyDown}
              onBlur={handleMaxBlur}
            />
          </div>
        </div>
        <Range allowCross={false} min={minRange} max={maxRange} value={value} onChange={onChange} onAfterChange={onAfterChange} />
      </div>
    </>
  );
};

export default RangeSlider;
