import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import QrScanner from "qr-scanner";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { fetchAutoComplete } from "../../../services/autoComplete";
import { postUpdateLocation } from "../../../services/updateLocation";
import DangerIcon from "../../../svgs/DangerIcon";
import SuccessIcon from "../../../svgs/SuccessIcon";
import WarningIcon from "../../../svgs/WarningIcon";
import errToStr from "../../../util/errToStr";
import { exists } from "../../../util/formValidations";
import { kegsOrTrackers } from "../../../util/kegOrTracker";
import { INITIAL_VIEW_STATE } from "../../../util/mapUtils";
import Bold from "../../Bold";
import { PrimaryBtn } from "../../Buttons";
import { FormContainer, FormError, FormInput, FormInputContainer } from "../../FormComponents";
import LoadingContainer from "../../LoadingContainer";
import { InfoModal, SubmitModal } from "../../Modal";
import { ModalIconContainer, ModalSuccess } from "../../Modal/styles";
import { TableHeaderButtons } from "../../NewTable/styles";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import { AsyncSelect } from "../../Select";
import Table, { CsvButtonsComponent } from "../../Table";
import { LocationLoading, MapContainer } from "./styles";

const formatDataToCsv = (tableRef: any, short_datetime: string) => {
  const headers = ["Date", "Tracker ID", "Place", "Latitude", "Longitude"];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [moment.unix(row.date).format(short_datetime), row.sensorId, row.placeName, row.latitude, row.longitude];
    }, []),
  ];
};

const UpdateLocation: FC<any> = () => {
  const { map_style, color, short_datetime } = useContext(ThemeContext);

  const googleMapRef = useRef<any>(null);
  const tableRef = useRef<any>(undefined);

  const videoRefCallback = useCallback((node: any) => {
    if (node) {
      setVideoRef(node);
    }
  }, []);

  const [videoRef, setVideoRef] = useState<any>(undefined);

  const [googleMap, setGoogleMap] = useState<any>(undefined);
  const [marker, setMarker] = useState<any>(undefined);

  const [location, setLocation] = useState<any>(undefined);
  const [locationLoading, setLocationLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState<any>({
    sensorId: "",
    place: undefined,
  });

  const [formErrors, setFormErrors] = useState<any>({});

  const [scanResponseMsg, setResponseMsg] = useState<string>("");
  const [scanResponseBattery, setResponseBattery] = useState<string>("");
  const [scanResponseErr, setResponseErr] = useState<string>("");
  const [scanResponseLoading, setResponseLoading] = useState<boolean>(false);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [updatedSensors, setUpdatedSensors] = useState<any>([]);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    setLocationLoading(true);
    navigator.geolocation.getCurrentPosition(
      (pos: any) => {
        const { coords } = pos;
        setLocation({
          lat: coords.latitude,
          lng: coords.longitude,
          zoom: 16,
        });
        setLocationLoading(false);
      },
      () => {
        setLocation({
          lat: INITIAL_VIEW_STATE.latitude,
          lng: INITIAL_VIEW_STATE.longitude,
          zoom: INITIAL_VIEW_STATE.zoom,
        });
        setLocationLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    if (location && !googleMap) {
      createGoogleMap();
    }
  }, [location]);

  useEffect(() => {
    if (googleMap && location && !marker) {
      createMarker();
    }
  }, [googleMap, location]);

  useEffect(() => {
    if (googleMap) {
      googleMap.setOptions({
        styles: map_style,
      });
    }
  }, [map_style]);

  // Initialise codeReader and stop/destroy codeReader and stop camera access on clean up
  useEffect(() => {
    let codeReader: any = undefined;
    if (videoRef) {
      codeReader = new QrScanner(videoRef, (result) => {
        if (result) {
          let code = result;
          if (code.includes("10b.in/")) {
            code = code.split("10b.in/")[1];
          }
          setFormData((prev: any) => ({ ...prev, sensorId: code }));
          setFormErrors((prev: any) => ({
            ...prev,
            sensorId: undefined,
          }));
          setResponseMsg("");
          setResponseBattery("");
          setResponseErr("");
          setModalOpen(true);
        }
      });
      codeReader.setInversionMode("both");
      codeReader.start();
    }

    return () => {
      if (codeReader) {
        codeReader.stop();
        codeReader.destroy();
      }
      if (videoRef && videoRef.srcObject) {
        videoRef.srcObject.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
    };
  }, [videoRef]);

  const createGoogleMap = () => {
    if (googleMapRef.current) {
      const newMap = new window.google.maps.Map(googleMapRef.current, {
        disableDefaultUI: true,
        controlSize: 26,
        zoomControl: true,
        mapTypeControl: true,
        fullscreenControl: true,
        styles: map_style,
        center: { lat: location.lat, lng: location.lng },
        zoom: location.zoom,
      });
      setGoogleMap(newMap);
    }
  };

  const createMarker = () => {
    const newMarker = new window.google.maps.Marker({
      position: { lat: location.lat, lng: location.lng },
      map: googleMap,
      draggable: true,
    });

    // deselect place when marker is moved
    newMarker.addListener("dragend", (e: any) => {
      setFormData((prev: any) => ({ ...prev, place: undefined }));
      setFormErrors((prev: any) => ({ ...prev, place: undefined }));
    });

    // deselect place when marker is moved
    googleMap.addListener("click", (e: any) => {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      newMarker.setPosition({ lat, lng });
      setFormData((prev: any) => ({ ...prev, place: undefined }));
      setFormErrors((prev: any) => ({ ...prev, place: undefined }));
    });

    setMarker(newMarker);
  };

  const handleSubmit = () => {
    const body = {
      sensorId: formData.sensorId,
      placeId: formData.place ? formData.place.value : undefined,
      latitude: marker.position.lat(),
      longitude: marker.position.lng(),
    };

    setResponseLoading(true);
    postUpdateLocation(source, body)
      .then((response) => {
        setResponseMsg(response.message);
        setResponseBattery(response.battery);
        setResponseErr("");
        setUpdatedSensors((prev: any) => [
          ...prev.filter((prevScan: any) => {
            if (prevScan.sensorId === formData.sensorId) {
              return false;
            }
            return true;
          }, []),
          {
            date: Math.floor(Date.now() / 1000),
            sensorId: formData.sensorId,
            placeId: formData.place ? formData.place.value : "",
            placeName: formData.place ? formData.place.label : "",
            latitude: marker.position.lat(),
            longitude: marker.position.lng(),
          },
        ]);
        setResponseLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setResponseErr(errToStr(err));
          setResponseLoading(false);
        }
      });
  };

  const handleManualSubmit = () => {
    const valid = exists("sensorId", formData.sensorId, setFormErrors);

    if (valid) {
      setResponseMsg("");
      setResponseBattery("");
      setResponseErr("");
      setModalOpen(true);
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setFormErrors((prev: any) => ({
      ...prev,
      [e.target.name]: undefined,
    }));
  };

  const handleSelectChange = (selected: any) => {
    setFormData((prev: any) => ({ ...prev, place: selected }));
    setFormErrors((prev: any) => ({ ...prev, place: undefined }));
    if (selected && googleMap && marker) {
      googleMap.panTo({ lat: selected.latitude, lng: selected.longitude });
      marker.setPosition({ lat: selected.latitude, lng: selected.longitude });
    }
  };

  // Loads the place options for the place select input
  const loadPlaces = (inputValue: string, callback: any) => {
    fetchAutoComplete("places", inputValue).then((response) => {
      callback(response);
    });
  };

  const columns = [
    {
      id: "date",
      Header: "Date",
      accessor: "date",
      Cell: (props: any) => moment.unix(props.value).format(short_datetime),
      minWidth: 160,
    },
    {
      id: "sensorId",
      Header: "Tracker ID",
      accessor: "sensorId",
      minWidth: 120,
      Cell: (props: any) => {
        const url = kegsOrTrackers("kegs", "trackers");
        return (
          <Link title={props.value} to={`/${url}/${props.value}`}>
            {props.value}
          </Link>
        );
      },
    },
    {
      id: "placeName",
      Header: "Place",
      accessor: "placeName",
      minWidth: 120,
      Cell: (props: any) =>
        props.original.placeId ? (
          <Link title={props.value} to={`/places/${props.original.placeId}`}>
            {props.value}
          </Link>
        ) : (
          props.value
        ),
    },
    {
      id: "latitude",
      Header: "Latitude",
      accessor: "latitude",
      minWidth: 120,
    },
    {
      id: "longitude",
      Header: "Longitude",
      accessor: "longitude",
      minWidth: 120,
    },
  ];

  const defaultSorted = [
    {
      id: "date",
      desc: true,
    },
  ];

  return (
    <>
      <PageBreadcrumbs prevRoutes={[{ slug: "/scanner", title: "Scanner" }]} currRoute="Update Location" />
      <PageContainer top="40px">
        <FormContainer>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <FormInputContainer style={{ marginBottom: "14px" }}>
              <label>Place</label>
              <AsyncSelect
                name="place"
                defaultOptions={true}
                isClearable={true}
                isSearchable={true}
                isError={formErrors.place}
                value={formData.place || ""}
                loadOptions={(inputValue: any, callback: any) => loadPlaces(inputValue, callback)}
                onChange={handleSelectChange}
                placeholder="Select..."
              />
            </FormInputContainer>
            <FormInputContainer
              style={{
                position: "relative",
              }}
            >
              <MapContainer id="update-location-google-map" ref={googleMapRef} />
              {locationLoading && <LocationLoading>Loading Location...</LocationLoading>}
            </FormInputContainer>
            <div style={{ display: formData.place || marker ? "block" : "none" }}>
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <label>Scan Tracker</label>
                <div>
                  <video
                    id="video"
                    ref={videoRefCallback}
                    style={{
                      display: "inline-block",
                      width: "100%",
                      border: "1px solid gray",
                      borderRadius: "3px",
                    }}
                  />
                </div>
              </FormInputContainer>
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <label>Tracker ID</label>
                <FormInput
                  type="text"
                  name="sensorId"
                  value={formData.sensorId}
                  error={formErrors.sensorId}
                  onChange={handleChange}
                  style={{ height: "40px" }}
                />
                <FormError error={formErrors.sensorId}>{formErrors.sensorId}</FormError>
              </FormInputContainer>
              <PrimaryBtn width="100%" onClick={handleManualSubmit}>
                Update Location
              </PrimaryBtn>
              <div
                style={{
                  paddingTop: "16px",
                  width: "100%",
                  float: "left",
                  display: updatedSensors.length > 0 ? "block" : "none",
                }}
              >
                <TableHeaderButtons>
                  <div style={{ display: "flex" }}></div>
                  <CsvButtonsComponent
                    data={updatedSensors}
                    formatCsv={formatDataToCsv}
                    formatCsvParams={[tableRef, short_datetime]}
                    fileName={kegsOrTrackers("Updated Kegs.csv", "Updated Trackers.csv")}
                  />
                </TableHeaderButtons>
                <Table style={{ clear: "both", marginBottom: "24px" }} data={updatedSensors} columns={columns} defaultSorted={defaultSorted} ref={tableRef} />
              </div>
            </div>
            {modalOpen &&
              (scanResponseMsg ? (
                <InfoModal
                  isOpen={modalOpen}
                  onClose={() => {
                    setResponseMsg("");
                    setResponseBattery("");
                    setResponseErr("");
                    setModalOpen(false);
                  }}
                  title="Update Location"
                  okayBtnText="Okay"
                  body={
                    <ModalSuccess>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <ModalIconContainer>
                          {scanResponseBattery === "LOW" ? (
                            <DangerIcon fill={color.danger[2]} />
                          ) : scanResponseBattery === "MEDIUM" ? (
                            <WarningIcon fill={color.warning[2]} />
                          ) : (
                            <SuccessIcon fill={color.success[2]} />
                          )}
                        </ModalIconContainer>
                        <div>
                          {scanResponseMsg}
                          <br />
                          {scanResponseBattery === "LOW"
                            ? "Battery Level Low"
                            : scanResponseBattery === "MEDIUM"
                            ? "Battery Level Medium"
                            : scanResponseBattery === "HIGH"
                            ? "Battery Level High"
                            : ""}
                        </div>
                      </div>
                    </ModalSuccess>
                  }
                />
              ) : (
                <SubmitModal
                  isOpen={modalOpen}
                  onSubmit={() => handleSubmit()}
                  onClose={() => {
                    setResponseMsg("");
                    setResponseBattery("");
                    setResponseErr("");
                    setModalOpen(false);
                  }}
                  title="Update Location"
                  success={scanResponseMsg}
                  error={scanResponseErr}
                  body={
                    <LoadingContainer loading={scanResponseLoading}>
                      <p>
                        Tracker ID: <Bold>{formData.sensorId}</Bold>
                      </p>
                      {formData.place && formData.place.label && (
                        <p>
                          Place: <Bold>{formData.place.label}</Bold>
                        </p>
                      )}
                      {marker && (
                        <>
                          <p>
                            Latitude: <Bold>{marker.position.lat()}</Bold>
                          </p>
                          <p>
                            Longitude: <Bold>{marker.position.lng()}</Bold>
                          </p>
                        </>
                      )}
                    </LoadingContainer>
                  }
                />
              ))}
          </form>
        </FormContainer>
      </PageContainer>
    </>
  );
};

export default UpdateLocation;
