// Method to add or update an object or an array of objects, specifying the key
const addOrUpdate = (prevState: any[], newItemOrItems: any | any[], key: keyof any): any[] => {
  try {
    // Return the previous state if newItemOrItems is null or undefined
    if (newItemOrItems == null) {
      return prevState;
    }

    // Convert input to an array if it's a single object
    const newItems = Array.isArray(newItemOrItems) ? newItemOrItems : [newItemOrItems];

    // Create a copy of the previous state
    const updatedState = [...prevState];

    // Iterate through each new item
    newItems.forEach((newItem) => {
      // Check if the key exists on the new item
      if (!(key in newItem)) {
        throw new Error(`The key '${String(key)}' does not exist on the item.`);
      }

      // Find the index of the item in the collection with the same key
      const index = updatedState.findIndex((item) => item[key] === newItem[key]);

      if (index !== -1) {
        // If an item with the same key exists, update it
        updatedState[index] = newItem;
      } else {
        // Otherwise, add the new item to the collection
        updatedState.push(newItem);
      }
    });

    // Return the updated state
    return updatedState;
  } catch (error: any) {
    // Log the error message or take any other desired action
    console.error(`Error in addOrUpdate function: ${error.message}`);
    // Return the previous state if an error occurs
    return prevState;
  }
};

export default addOrUpdate;
