import React, { FC, useState, useEffect, useRef, useContext } from "react";
import moment from "moment";
import { DateItem, DatePickerInputContainer } from "./styles";
import Tooltip from "../Tooltip";
import { PrimaryBtn } from "../Buttons";
import { ThemeContext } from "styled-components";
import DateRangeCalendarModal from "../DateRangeCalendarModal";
import { IoCalendarOutline } from "react-icons/io5";
import { IconContext } from "react-icons";

const formatDateLabel = (start: any, end: any, dateFormat: string) => {
  return `${start ? start.format(dateFormat) : "..."} to ${end ? end.format(dateFormat) : "..."}`;
};

const DateSelect: FC<any> = ({ filterDates, setFilterDates, minDate, maxDate }) => {
  const { color, short_datetime } = useContext(ThemeContext);

  const [dateChanged, setDateChanged] = useState<boolean>(false);
  const [start, setStart] = useState<any>(filterDates.start);
  const [end, setEnd] = useState<any>(filterDates.end);
  const [dateLabel, setDateLabel] = useState<string>(filterDates.dateLabel ? filterDates.dateLabel : formatDateLabel(start, end, short_datetime));
  const [flatpickrModalOpen, setFlatpickrModalOpen] = useState<boolean>(false);

  const tooltipRef = useRef<any>(null);

  useEffect(() => {
    if (dateChanged) {
      setFilterDates({ start, end, changed: true });
      setDateChanged(false);
    }
  }, [start, end, dateChanged, setFilterDates]);

  const handleDatesChange = (dates: any) => {
    const startMoment = dates.length > 0 ? moment(dates[0]) : filterDates.start;
    const endMoment = dates.length > 1 ? moment(dates[1]) : filterDates.end;
    const label = formatDateLabel(startMoment, endMoment, short_datetime);
    setStart(startMoment);
    setEnd(endMoment);
    setDateLabel(label);
    setDateChanged(true);
  };

  const handleDateItemClick = (start: any, end: any, label: string) => {
    setStart(start);
    setEnd(end);
    setDateLabel(label);
    setDateChanged(true);
    if (tooltipRef.current) {
      tooltipRef.current.hide();
    }
  };

  return (
    <div style={{ float: "left" }}>
      <Tooltip
        onCreate={(instance) => (tooltipRef.current = instance)}
        content={
          <div
            style={{
              textAlign: "left",
              width: "200px",
              maxHeight: "250px",
              overflow: "auto",
            }}
          >
            <DateItem
              onClick={() => {
                setFlatpickrModalOpen(true);
                if (tooltipRef.current) {
                  tooltipRef.current.hide();
                }
              }}
            >
              <span>Custom</span>
            </DateItem>
            <DateItem onClick={() => handleDateItemClick(moment().subtract(24, "hours"), moment(), "Last 24 hours")}>
              <span>Last 24 hours</span>
            </DateItem>
            <DateItem onClick={() => handleDateItemClick(moment().subtract(2, "days"), moment(), "Last 2 days")}>
              <span>Last 2 days</span>
            </DateItem>
            <DateItem onClick={() => handleDateItemClick(moment().subtract(3, "days"), moment(), "Last 3 days")}>
              <span>Last 3 days</span>
            </DateItem>
            <DateItem onClick={() => handleDateItemClick(moment().subtract(7, "days"), moment(), "Last 7 days")}>
              <span>Last 7 days</span>
            </DateItem>
            <DateItem onClick={() => handleDateItemClick(moment().subtract(2, "weeks"), moment(), "Last 2 weeks")}>
              <span>Last 2 weeks</span>
            </DateItem>
            <DateItem onClick={() => handleDateItemClick(moment().subtract(1, "months"), moment(), "Last 1 month")}>
              <span>Last 1 month</span>
            </DateItem>
            <DateItem onClick={() => handleDateItemClick(moment().subtract(3, "months"), moment(), "Last 3 months")}>
              <span>Last 3 months</span>
            </DateItem>
            <DateItem onClick={() => handleDateItemClick(moment().subtract(6, "months"), moment(), "Last 6 months")}>
              <span>Last 6 months</span>
            </DateItem>
            <DateItem onClick={() => handleDateItemClick(moment().subtract(12, "months"), moment(), "Last 12 months")}>
              <span>Last 12 months</span>
            </DateItem>
            <DateItem onClick={() => handleDateItemClick(minDate ? minDate : moment(0), moment(), "All time")}>
              <span>All time</span>
            </DateItem>
          </div>
        }
        interactive={true}
        appendTo={document.body}
        trigger="click"
        touch={true}
        placement="bottom"
        theme="binary-no-padding"
      >
        <DatePickerInputContainer>
          <PrimaryBtn style={{ height: "40px", minWidth: "unset", display: "flex", alignItems: "center" }} padding="0px 12px">
            <span style={{ marginRight: "12px" }}>{dateLabel}</span>
            <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
              <IoCalendarOutline />
            </IconContext.Provider>
          </PrimaryBtn>
        </DatePickerInputContainer>
      </Tooltip>
      {flatpickrModalOpen && (
        <DateRangeCalendarModal
          start={start}
          end={end}
          minDate={minDate}
          maxDate={maxDate}
          handleDatesChange={handleDatesChange}
          modalOpen={flatpickrModalOpen}
          setModalOpen={setFlatpickrModalOpen}
        />
      )}
    </div>
  );
};

export default DateSelect;
