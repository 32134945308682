import React, { FC, useState } from "react";
import { Content } from "./styles";
import TopNav from "../../components/TopNav";
import SideNav from "../../components/SideNav";
import { saveMenuOpen, getMenuOpen } from "../../services/localStorage";

const BasicLayout: FC<any> = ({ children }) => {
  // The side nav menu state is stored in local storage so it is persisted on refreshes.
  const [open, toggleOpen] = useState<boolean>(getMenuOpen());

  const handleOpen = (isOpen: boolean) => {
    toggleOpen(isOpen);
    saveMenuOpen(isOpen);
  };

  return (
    <>
      <TopNav open={open} toggleOpen={handleOpen} enableNav={true} />
      <SideNav open={open} toggleOpen={handleOpen} />
      <Content open={open}>{children}</Content>
    </>
  );
};

export default BasicLayout;
