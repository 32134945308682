import styled from "../Themes";

export const ClearButton = styled(`div`)`
  position: absolute;
  fill: ${({ theme }) => theme.color_30.font_bold[2]};
  right: 0px;
  top: 0px;
  padding: 7px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
  display: flex;

  &:hover {
    fill: ${({ theme }) => theme.color.font_bold[2]};
  }
`;
