import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const fetchTemperatureRangeReport = async (
  source: CancelTokenSource,
  startDate?: number,
  endDate?: number,
  trackerId?: string,
  assetType?: string,
  trackerTags?: string,
  placeId?: number,
  placeType?: string,
  placeTags?: string,
  inTransit?: string
) => {
  const params = {
    organisationId: getAccount().organisationId,
    startDate,
    endDate,
    trackerId,
    assetType,
    trackerTags,
    placeId,
    placeType,
    placeTags,
    inTransit,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/temperaturerangereport",
    params,
  });

  return data;
};
