import axios, { CancelTokenSource } from "axios";
import React, { FC, useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { getAccount } from "../../services/localStorage";
import ExternalLink from "../../util/externalLink";
import { getLogo } from "../../util/getLogo";
import getNextRoute from "../../util/getNextRoute";
import StateContext from "../StateContext";
import { Section, LogoContainer, Heading, SubHeading } from "./styles";

const MaintenanceScreen: FC<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { theme } = useContext(StateContext);
  const { color } = useContext(ThemeContext);

  const [nextRoute, setNextRoute] = useState<any>(undefined);
  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  const updateNextRoute = () => {
    if (!getAccount().maintenance) {
      getNextRoute(source, location, setNextRoute);
    }
  };

  useEffect(() => {
    document.addEventListener("accountinfoupdated", updateNextRoute);

    return () => {
      source.cancel();
      document.removeEventListener("accountinfoupdated", updateNextRoute);
    };
  }, [source, updateNextRoute]);

  if (nextRoute) {
    navigate(nextRoute);
  }

  return (
    <Section>
      <LogoContainer>{getLogo(color, theme)}</LogoContainer>
      <Heading>Down for Maintenance</Heading>
      <SubHeading>We apologise for any inconveniences caused.</SubHeading>
      <SubHeading>We'll be back up shortly.</SubHeading>
      <SubHeading>
        <a href="" onClick={() => window.location.reload}>
          Reload.
        </a>
      </SubHeading>
      <SubHeading style={{ marginTop: "24px" }}>
        Check <ExternalLink url="https://twitter.com/binaryappstatus" label="@binaryappstatus" /> for further updates.
      </SubHeading>
    </Section>
  );
};

export default MaintenanceScreen;
