import React, { FC, useState, useEffect, useContext, useRef } from "react";
import Bold from "../Bold";
import {
  CameraIconContainer,
  UpdateImageButton,
  UpdateImageButtonContainer,
  Capitalize,
  ScreenIcon,
  BoxElement,
  ContentContainer,
  IconContainer,
  Icon,
  ValueContainer,
  Value,
  Header,
  Footer,
  HeaderContentContainer,
  HeaderValueContainer,
} from "./styles";
import LoadingContainer from "../LoadingContainer";
//@ts-ignore
import "@zendeskgarden/react-buttons/dist/styles.css";
import { ThemeContext } from "styled-components";
import axios, { CancelTokenSource } from "axios";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import PlaceIcon from "../../svgs/PlaceIcon";
import PhotoModal from "../PhotoModal";
import Tag from "../Tag";
import sortTags from "../../util/sortTags";
import { IconContext } from "react-icons";
import { FaMapMarkerAlt, FaPhoneAlt } from "react-icons/fa";
import { googleMapsAddressLink, googleMapsLink } from "../../util/googleMapsLink";
import { MdEmail } from "react-icons/md";
import { BsChatRightTextFill, BsGlobe2 } from "react-icons/bs";
import ExternalLink from "../../util/externalLink";
import { HiClock } from "react-icons/hi";
import { InfoModal } from "../Modal";
import { FormTextareaInput } from "../FormComponents";
import { deletePhoto } from "../../services/photo";
import UploadPhotoModal from "../UploadPhotoModal";
import Tooltip from "../Tooltip";
import { MenuList, MenuButton, DangerMenuButton } from "../Tooltip/styles";
import { isAdmin } from "../../util/checkRole";
import CameraIcon from "../../svgs/CameraIcon";
import { hideAll } from "tippy.js";
import DeleteModal from "../DeleteModal";
import FillsKegsIcon from "../../svgs/FillsKegsIcon";
import TapIcon from "../../svgs/TapIcon";
import { TbWorldSearch } from "react-icons/tb";
import IoIosBeerDefault from "../../svgs/IoIosBeerDefault";

const PlaceMeta: FC<any> = ({ id, fetchMeta, data, dataLoading, dataErr }) => {
  const { color, color_50 } = useContext(ThemeContext);

  const photoMenuRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const [inputPhoto, setInputPhoto] = useState<any>(undefined);
  const [photoModalOpen, setPhotoModalOpen] = useState<boolean>(false);
  const [uploadPhotoModalOpen, setUploadPhotoModalOpen] = useState<boolean>(false);
  const [deletePhotoModalOpen, setDeletePhotoModalOpen] = useState<boolean>(false);

  const [hoursModalOpen, setHoursModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const {
    dateCreated = null,
    placeName = null,
    contactName = null,
    email = null,
    phoneNumber = null,
    notes = null,
    address = null,
    sensorCount = null,
    beerName = null,
    avgDays = null,
    avgTemp = null,
    hoursAccrued = null,
    placeGroup = null,
    placeGroupColour = null,
    placeTags = null,
    avgHoursTillEmpty = null,
    kegsFilled = null,
    litresFilled = null,
    kegsEmptied = null,
    litresConsumed = null,
    placePhotoUrl = null,
    fillsBeerKegs = null,
    emptiesBeerKegs = null,
    lat = null,
    lng = null,
    website = null,
    openHours = null,
    fuzzy = null,
  } = data ? data : {};

  const handleFileChange = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setInputPhoto(e.target.files[0]);
    if (e.target.files[0]) setUploadPhotoModalOpen(true);
    e.target.value = "";
  };

  return (
    <LoadingContainer loading={dataLoading} err={dataErr}>
      <BoxElement style={{ display: "flex", flexDirection: "column" }}>
        <HeaderContentContainer>
          <IconContainer>
            {placePhotoUrl ? (
              <div style={{ cursor: "pointer", position: "relative" }} onClick={() => setPhotoModalOpen(true)}>
                <img width="150px" height="150px" style={{ borderRadius: "3px" }} src={placePhotoUrl} />
                {isAdmin() && (
                  <Tooltip
                    onCreate={(instance) => (photoMenuRef.current = instance)}
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                    trigger="click"
                    placement="bottom-start"
                    maxWidth="none"
                    theme="binary-no-padding"
                    content={
                      <MenuList>
                        <MenuButton
                          onClick={() => {
                            inputRef.current.click();
                            hideAll();
                          }}
                        >
                          {placePhotoUrl ? "Change" : "Upload"}
                        </MenuButton>
                        {placePhotoUrl && (
                          <DangerMenuButton
                            onClick={() => {
                              setDeletePhotoModalOpen(true);
                              hideAll();
                            }}
                          >
                            Delete
                          </DangerMenuButton>
                        )}
                      </MenuList>
                    }
                  >
                    <UpdateImageButton style={{ background: color.panel_bg[2], bottom: "-8px", right: "-8px" }}>
                      <UpdateImageButtonContainer>
                        <CameraIconContainer>
                          <CameraIcon />
                        </CameraIconContainer>
                      </UpdateImageButtonContainer>
                    </UpdateImageButton>
                  </Tooltip>
                )}
              </div>
            ) : (
              <ScreenIcon>
                <PlaceIcon fill={color.font_bold[2]} />
              </ScreenIcon>
            )}
          </IconContainer>
          <HeaderValueContainer>
            <Header>Place</Header>
            {placeName !== null && (
              <Value title={placeName}>
                <Capitalize>
                  <Bold>{placeName}</Bold>
                </Capitalize>
              </Value>
            )}
            {placeGroup !== null && (
              <Footer>
                {placeGroupColour !== null && <ColoredDot color={placeGroupColour} margin="0 4px" />}
                {placeGroup}
              </Footer>
            )}
            {placeTags !== null && placeTags.length > 0 && (
              <Footer style={{ whiteSpace: "normal" }}>
                {placeTags.sort(sortTags).map((tag: any) => (
                  <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />
                ))}
              </Footer>
            )}
          </HeaderValueContainer>
        </HeaderContentContainer>
        <ContentContainer
          style={{
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: "10px",
          }}
        >
          {address !== null && address !== "" && (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", minHeight: "28px", margin: "5px 0", width: "100%" }}>
              <IconContainer>
                <Icon>
                  <IconContext.Provider value={{ color: color_50.font_bold[2], size: "28px" }}>
                    <FaMapMarkerAlt />
                  </IconContext.Provider>
                </Icon>
              </IconContainer>
              <ValueContainer>
                <Value title={address} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "17px", whiteSpace: "normal" }}>
                  {lat !== null && lng !== null ? googleMapsLink(lat, lng, address) : googleMapsAddressLink(address)}
                </Value>
              </ValueContainer>
            </div>
          )}
          {openHours !== null && openHours !== "" && (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", minHeight: "28px", margin: "5px 0", width: "100%" }}>
              <IconContainer>
                <Icon>
                  <IconContext.Provider value={{ color: color_50.font_bold[2], size: "28px" }}>
                    <HiClock />
                  </IconContext.Provider>
                </Icon>
              </IconContainer>
              <ValueContainer>
                <Value
                  onClick={() => setHoursModalOpen(true)}
                  style={{ fontWeight: 400, fontSize: "14px", lineHeight: "17px", textDecoration: "underline", cursor: "pointer" }}
                >
                  Opening Hours
                </Value>
              </ValueContainer>
            </div>
          )}
          {website !== null && website !== "" && (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", minHeight: "28px", margin: "5px 0", width: "100%" }}>
              <IconContainer>
                <Icon>
                  <IconContext.Provider value={{ color: color_50.font_bold[2], size: "28px" }}>
                    <BsGlobe2 />
                  </IconContext.Provider>
                </Icon>
              </IconContainer>
              <ValueContainer>
                <Value title={website} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "17px" }}>
                  <ExternalLink url={website} />
                </Value>
              </ValueContainer>
            </div>
          )}
          {phoneNumber !== null && phoneNumber !== "" && (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", minHeight: "28px", margin: "5px 0", width: "100%" }}>
              <IconContainer>
                <Icon>
                  <IconContext.Provider value={{ color: color_50.font_bold[2], size: "28px" }}>
                    <FaPhoneAlt />
                  </IconContext.Provider>
                </Icon>
              </IconContainer>
              <ValueContainer>
                <Value title={phoneNumber} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "17px" }}>
                  <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                </Value>
              </ValueContainer>
            </div>
          )}
          {email !== null && email !== "" && (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", minHeight: "28px", margin: "5px 0", width: "100%" }}>
              <IconContainer>
                <Icon>
                  <IconContext.Provider value={{ color: color_50.font_bold[2], size: "28px" }}>
                    <MdEmail />
                  </IconContext.Provider>
                </Icon>
              </IconContainer>
              <ValueContainer>
                <Value title={email} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "17px" }}>
                  <a href={`mailto:${email}`}>{email}</a>
                </Value>
              </ValueContainer>
            </div>
          )}
          {notes !== null && notes !== "" && (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", minHeight: "28px", margin: "5px 0", width: "100%" }}>
              <IconContainer>
                <Icon>
                  <IconContext.Provider value={{ color: color_50.font_bold[2], size: "28px" }}>
                    <BsChatRightTextFill />
                  </IconContext.Provider>
                </Icon>
              </IconContainer>
              <ValueContainer>
                <Value title={notes} style={{ fontWeight: 400, fontSize: "14px", lineHeight: "17px", whiteSpace: "normal" }}>
                  {notes}
                </Value>
              </ValueContainer>
            </div>
          )}
          {(fillsBeerKegs || emptiesBeerKegs || fuzzy || beerName) && (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", minHeight: "32px", marginTop: "10px", width: "100%" }}>
              {fillsBeerKegs && (
                <Tooltip content={`Fills Beer Kegs`} touch={true}>
                  <IconContainer style={{ marginRight: "10px" }}>
                    <Icon>
                      <FillsKegsIcon width="32" height="32" fill={placeGroupColour !== null ? placeGroupColour : color_50.font_bold[2]} />
                    </Icon>
                  </IconContainer>
                </Tooltip>
              )}
              {emptiesBeerKegs && (
                <Tooltip content={`Empties Beer Kegs`} touch={true}>
                  <IconContainer style={{ marginRight: "10px" }}>
                    <Icon>
                      <TapIcon width="32" height="32" fill={placeGroupColour !== null ? placeGroupColour : color_50.font_bold[2]} />
                    </Icon>
                  </IconContainer>
                </Tooltip>
              )}
              {fuzzy && (
                <Tooltip content={`Fuzzy Location Matching`} touch={true}>
                  <IconContainer style={{ marginRight: "10px" }}>
                    <Icon>
                      <IconContext.Provider value={{ color: placeGroupColour !== null ? placeGroupColour : color_50.font_bold[2], size: "32px" }}>
                        <TbWorldSearch />
                      </IconContext.Provider>
                    </Icon>
                  </IconContainer>
                </Tooltip>
              )}
              {beerName !== null && (
                <Tooltip content={`Default Beer`} touch={true}>
                  <IconContainer style={{ marginRight: "10px" }}>
                    <Icon>
                      <IoIosBeerDefault width="32" height="32" fill={placeGroupColour !== null ? placeGroupColour : color_50.font_bold[2]} />
                    </Icon>
                  </IconContainer>
                </Tooltip>
              )}
            </div>
          )}
        </ContentContainer>
      </BoxElement>
      {hoursModalOpen && (
        <InfoModal
          isOpen={hoursModalOpen}
          onClose={() => {
            setHoursModalOpen(false);
          }}
          title="Opening Hours"
          okayBtnText="Close"
          okayBtnProps={{ width: "100%" }}
          body={<FormTextareaInput name="openHours" rows={7} maxLength={512} value={openHours} />}
        />
      )}
      <input type="file" ref={inputRef} onChange={handleFileChange} style={{ display: "none" }} accept="image/png, image/jpeg" />
      {uploadPhotoModalOpen && (
        <UploadPhotoModal
          modalOpen={uploadPhotoModalOpen}
          setModalOpen={setUploadPhotoModalOpen}
          photo={inputPhoto}
          trackerId={undefined}
          organisationId={undefined}
          placeId={id}
          userId={undefined}
          onSuccess={fetchMeta}
        />
      )}
      {deletePhotoModalOpen && (
        <DeleteModal
          title="Delete Place Photo"
          body={<span>Are you sure you want to delete this photo?</span>}
          successMsg="Place Photo Deleted"
          modalOpen={deletePhotoModalOpen}
          setModalOpen={setDeletePhotoModalOpen}
          deleteService={deletePhoto}
          serviceParams={[undefined, undefined, id, undefined]}
          onDelete={fetchMeta}
        />
      )}
      {photoModalOpen && <PhotoModal modalOpen={photoModalOpen} setModalOpen={setPhotoModalOpen} name="Place Photo" src={placePhotoUrl} />}
    </LoadingContainer>
  );
};

export default PlaceMeta;
