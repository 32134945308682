import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { fetchVerifyEmailGenerate } from "../../services/verifyEmail";
import { getAccount } from "../../services/localStorage";

const VerifyEmailModal: FC<any> = ({ modalOpen, setModalOpen }) => {
  const [accountInfo] = useState<any>(getAccount());

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, []);

  const handleSubmit = () => {
    setSubmitting(true);
    fetchVerifyEmailGenerate(source)
      .then(() => {
        setSubmittedMsg("Verify Email Sent");
        setSubmitting(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) {
          setModalOpen(false);
        }
      }}
      title="Verify Email Address"
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Send"
      body={
        <LoadingContainer loading={submitting}>
          <span>Are you sure you want to send a verification email to {accountInfo.email}</span>
        </LoadingContainer>
      }
    />
  );
};

export default VerifyEmailModal;
