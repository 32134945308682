import tryParseJSON from "../../util/tryParseJSON";
import ReactGA from "react-ga";
import createNestedObj from "../../util/createNestedObj";
import { isBinaryBeer } from "../../util/checkDomain";
import isValidArray from "../../util/isValidArray";

export const saveToken = (data: any, noExpire: boolean) => {
  // When a user logs in assign their ID to GA for analytics
  ReactGA.set({ userId: data.id });

  const jsonToken = JSON.stringify({
    token: `Bearer ${data.access_token}`,
    // If noExpire is ticked, token expires in 999 days, otherwise 1 day
    exp: new Date().setDate(new Date().getDate() + (noExpire ? 999 : 1)),
    version: process.env.REACT_APP_VERSION,
  });

  localStorage.setItem("bb-token", jsonToken);
};

export const saveAccount = (data: any) => {
  const { access_token, token_type, expires_in, integrations, ...account } = data;
  localStorage.setItem("bb-account", JSON.stringify(account));
  if (isValidArray(integrations)) {
    localStorage.setItem(
      "bb-integrations",
      JSON.stringify(
        integrations.reduce((acc: any, val: unknown) => {
          acc[val as string] = true;
          return acc;
        }, {})
      )
    );
  }
};

export const updateOrganisationId = (id: string | undefined) => {
  const account = tryParseJSON(String(localStorage.getItem("bb-account")));

  if (account) {
    const updatedAccount = {
      ...account,
      organisationId: id,
    };
    localStorage.setItem("bb-account", JSON.stringify(updatedAccount));
  } else {
    localStorage.removeItem("bb-token");
    localStorage.removeItem("bb-account");
    return undefined;
  }
};

export const updateOrganisationName = (name: string) => {
  const account = tryParseJSON(String(localStorage.getItem("bb-account")));

  if (account) {
    const updatedAccount = {
      ...account,
      organisationName: name,
    };
    localStorage.setItem("bb-account", JSON.stringify(updatedAccount));
  } else {
    localStorage.removeItem("bb-token");
    localStorage.removeItem("bb-account");
    return undefined;
  }
};

export const setEmailVerified = (verified: boolean) => {
  const account = tryParseJSON(String(localStorage.getItem("bb-account")));

  if (account) {
    account.emailVerified = verified;
  }

  localStorage.setItem("bb-account", JSON.stringify(account));
};

export const getToken = (): string | null => {
  const data = tryParseJSON(String(localStorage.getItem("bb-token")));

  if (!data || Date.now() > data.exp) {
    localStorage.removeItem("bb-token");
    return null;
  } else {
    return data.token;
  }
};

export const removeToken = () => {
  // When a user logs out unassign their ID from GA
  ReactGA.set({ userId: null });
  localStorage.removeItem("bb-token");
};

export const getVersion = () => {
  const data = tryParseJSON(String(localStorage.getItem("bb-token")));

  if (!data || !data.version) {
    localStorage.removeItem("bb-token");
    return "";
  } else {
    return data.version;
  }
};

export const getAccount = (): any => {
  const account = tryParseJSON(String(localStorage.getItem("bb-account")));

  if (account) {
    return account;
  } else {
    localStorage.removeItem("bb-token");
    localStorage.removeItem("bb-account");
    return {};
  }
};

export const removeAccount = (): any => {
  localStorage.removeItem("bb-account");
};

export const saveTheme = (theme: string) => {
  const data = tryParseJSON(String(localStorage.getItem("bb-account")));
  data.lightMode = theme === "light";
  localStorage.setItem("bb-account", JSON.stringify(data));
};

export const getTheme = (): string => {
  const data = tryParseJSON(String(localStorage.getItem("bb-account")));
  if (data && data.lightMode === true) return "light";
  else if (data && data.lightMode === false) return "dark";
  else return "light";
};

export const saveMenuOpen = (open: boolean) => {
  localStorage.setItem("bb-menu-open", JSON.stringify({ open }));
};

export const getMenuOpen = (): any => {
  const data = tryParseJSON(String(localStorage.getItem("bb-menu-open")));
  return data ? data.open : false;
};

export const saveLastFillEvent = (event: any) => {
  localStorage.setItem("bb-last-fill-event", JSON.stringify({ event }));
};

export const getLastFillEvent = (): any => {
  const data = tryParseJSON(String(localStorage.getItem("bb-last-fill-event")));
  return data ? data.event : undefined;
};

export const saveDefaults = (data: any) => {
  localStorage.setItem("bb-defaults", JSON.stringify(data));
};

export const getDefaults = (): any => {
  const data = tryParseJSON(String(localStorage.getItem("bb-defaults")));
  return data || {};
};

export const saveCountry = (country: any) => {
  localStorage.setItem("bb-country", JSON.stringify(country));
};

export const getCountry = (): any => {
  const country = tryParseJSON(String(localStorage.getItem("bb-country")));
  return country || null;
};

export const getIntegrations = (): any => {
  const data: any = tryParseJSON(String(localStorage.getItem("bb-integrations")));
  if (data) return data;
  else return {};
};

////////////////////////////////////
////////// Table Settings //////////
////////////////////////////////////

////// Tracker Table //////
export const trackerTableDefaults = {
  density: 0,
  order: [],
  pageSize: 20,
  visibility: {
    active: false,
    dateActivatedUnix: false,
    dateCreatedUnix: false,
    dateProvisionedUnix: false,
    lastServiceDateUnix: false,
    nextSampleDueUnix: false,
    organisationName: false,
    pickup: false,
  },
};

export const getTrackerTableSettings = (): any => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings")));
  if (settings?.trackers !== undefined) return settings?.trackers;
  else return trackerTableDefaults;
};

export const saveTrackerTableSettings = (data: any) => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings"))) || {};
  createNestedObj(settings, ["trackers"], data);
  localStorage.setItem("bb-table-settings", JSON.stringify(settings));
};

////// Place Table //////
export const placeTableDefaults = {
  density: 0,
  order: [],
  pageSize: 20,
  visibility: {},
};

export const getPlaceTableSettings = (): any => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings")));
  if (settings?.places !== undefined) return settings?.places;
  else return placeTableDefaults;
};

export const savePlaceTableSettings = (data: any) => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings"))) || {};
  createNestedObj(settings, ["places"], data);
  localStorage.setItem("bb-table-settings", JSON.stringify(settings));
};

////// Place Allocations Table //////
export const placeAllocationsTableDefaults = {
  density: 0,
  order: [],
  pageSize: 20,
  visibility: {},
};

export const getPlaceAllocationTableSettings = (): any => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings")));
  if (settings?.placeAllocations !== undefined) return settings?.placeAllocations;
  else return placeAllocationsTableDefaults;
};

export const savePlaceAllocationTableSettings = (data: any) => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings"))) || {};
  createNestedObj(settings, ["placeAllocations"], data);
  localStorage.setItem("bb-table-settings", JSON.stringify(settings));
};

////// Suggested Place Table //////
export const suggestedPlaceTableDefaults = {
  density: 0,
  order: [],
  pageSize: 20,
  visibility: {},
};

export const getSuggestedPlaceTableSettings = (): any => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings")));
  if (settings?.suggestedPlace !== undefined) return settings?.suggestedPlace;
  else return suggestedPlaceTableDefaults;
};

export const saveSuggestedPlaceTableSettings = (data: any) => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings"))) || {};
  createNestedObj(settings, ["suggestedPlaces"], data);
  localStorage.setItem("bb-table-settings", JSON.stringify(settings));
};

////// Contents Table //////
export const contentsTableDefaults = {
  density: 0,
  order: [],
  pageSize: 20,
  visibility: {},
};

export const getContentsTableSettings = (): any => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings")));
  if (settings?.contents !== undefined) return settings?.contents;
  else return contentsTableDefaults;
};

export const saveContentsTableSettings = (data: any) => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings"))) || {};
  createNestedObj(settings, ["contents"], data);
  localStorage.setItem("bb-table-settings", JSON.stringify(settings));
};

////// Place Trackers Here Table //////
export const placeTrackersHereTableDefaults = {
  density: 0,
  order: [],
  pageSize: 10,
  visibility: isBinaryBeer()
    ? {
        assetTypeName: false,
        assetId: false,
        trackerName: false,
        trackerTags: false,
        inFridge: false,
        orientation: false,
      }
    : {
        orientation: false,
        assetTypeName: false,
      },
};

export const getPlaceTrackersHereTableSettings = (): any => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings")));
  if (settings?.placeTrackersHere !== undefined) return settings?.placeTrackersHere;
  else return placeTrackersHereTableDefaults;
};

export const savePlaceTrackersHereTableSettings = (data: any) => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings"))) || {};
  createNestedObj(settings, ["placeTrackersHere"], data);
  localStorage.setItem("bb-table-settings", JSON.stringify(settings));
};

////// Place Trackers Arrived Table //////
export const placeTrackersArrivedTableDefaults = {
  density: 0,
  order: [],
  pageSize: 10,
  visibility: isBinaryBeer()
    ? {
        assetTypeName: false,
        assetId: false,
        trackerName: false,
        trackerTags: false,
        inFridge: false,
        orientation: false,
      }
    : {
        orientation: false,
        assetTypeName: false,
      },
};

export const getPlaceTrackersArrivedTableSettings = (): any => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings")));
  if (settings?.placeTrackersArrived !== undefined) return settings?.placeTrackersArrived;
  else return placeTrackersArrivedTableDefaults;
};

export const savePlaceTrackersArrivedTableSettings = (data: any) => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings"))) || {};
  createNestedObj(settings, ["placeTrackersArrived"], data);
  localStorage.setItem("bb-table-settings", JSON.stringify(settings));
};

////// Place Trackers Departed Table //////
export const placeTrackersDepartedTableDefaults = {
  density: 0,
  order: [],
  pageSize: 10,
  visibility: isBinaryBeer()
    ? {
        assetTypeName: false,
        assetId: false,
        trackerName: false,
        trackerTags: false,
        inFridge: false,
        orientation: false,
      }
    : {
        orientation: false,
        assetTypeName: false,
      },
};

export const getPlaceTrackersDepartedTableSettings = (): any => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings")));
  if (settings?.placeTrackersDeparted !== undefined) return settings?.placeTrackersDeparted;
  else return placeTrackersDepartedTableDefaults;
};

export const savePlaceTrackersDepartedTableSettings = (data: any) => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings"))) || {};
  createNestedObj(settings, ["placeTrackersDeparted"], data);
  localStorage.setItem("bb-table-settings", JSON.stringify(settings));
};

////// Place Products Table //////
export const placeProductsTableDefaults = {
  density: 0,
  order: [],
  pageSize: 10,
  visibility: {},
};

export const getPlaceProductsTableSettings = (): any => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings")));
  if (settings?.placeProduct !== undefined) return settings?.placeProducts;
  else return placeProductsTableDefaults;
};

export const savePlaceProductsTableSettings = (data: any) => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings"))) || {};
  createNestedObj(settings, ["placeProducts"], data);
  localStorage.setItem("bb-table-settings", JSON.stringify(settings));
};

////// Place Batches Table //////
export const placeBatchesTableDefaults = {
  density: 0,
  order: [],
  pageSize: 10,
  visibility: {},
};

export const getPlaceBatchesTableSettings = (): any => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings")));
  if (settings?.placeBatches !== undefined) return settings?.placeBatches;
  else return placeBatchesTableDefaults;
};

export const savePlaceBatchesTableSettings = (data: any) => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings"))) || {};
  createNestedObj(settings, ["placeBatches"], data);
  localStorage.setItem("bb-table-settings", JSON.stringify(settings));
};

////// Manifests Table //////
export const manifestsTableDefaults = {
  density: 0,
  order: [],
  pageSize: 10,
  visibility: {
    endPlaceName: false,
    targetPlaceName: false,
    startPlaceName: false,
    startTimeUnix: false,
    endTimeUnix: false,
    deadlineTimeUnix: false,
    startingUserName: false,
    endUserName: false,
  },
};

export const getManifestsTableSettings = (): any => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings")));
  if (settings?.manifests !== undefined) return settings?.manifests;
  else return manifestsTableDefaults;
};

export const saveManifestsTableSettings = (data: any) => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings"))) || {};
  createNestedObj(settings, ["manifests"], data);
  localStorage.setItem("bb-table-settings", JSON.stringify(settings));
};

////// Pickup Requests Table //////
export const pickupRequestsTableDefaults = {
  density: 0,
  order: [],
  pageSize: 10,
  visibility: {},
};

export const getPickupRequestsTableSettings = (): any => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings")));
  if (settings?.pickupRequests !== undefined) return settings?.pickupRequests;
  else return pickupRequestsTableDefaults;
};

export const savePickupRequestsTableSettings = (data: any) => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings"))) || {};
  createNestedObj(settings, ["pickupRequests"], data);
  localStorage.setItem("bb-table-settings", JSON.stringify(settings));
};

////// Tracker Services Report Table //////
export const trackerServicesReportTableDefaults = {
  density: 0,
  order: [],
  pageSize: 10,
  visibility: {
    dateProvisioned: false,
    serviceFrequencyMonths: false,
    lastServicedBy: false,
    roomTemperature: false,
    actionsList: false,
    notes: false,
  },
};

export const getTrackerServicesReportTableSettings = (): any => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings")));
  if (settings?.trackerServicesReport !== undefined) return settings?.trackerServicesReport;
  else return trackerServicesReportTableDefaults;
};

export const saveTrackerServicesReportTableSettings = (data: any) => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings"))) || {};
  createNestedObj(settings, ["trackerServicesReport"], data);
  localStorage.setItem("bb-table-settings", JSON.stringify(settings));
};

////// Tracker Service Logs Table //////
export const trackerServiceLogsTableDefaults = {
  density: 0,
  order: [],
  pageSize: 10,
  visibility: {},
};

export const getTrackerServiceLogsTableSettings = (): any => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings")));
  if (settings?.trackerServiceLogs !== undefined) return settings?.trackerServiceLogs;
  else return trackerServiceLogsTableDefaults;
};

export const saveTrackerServiceLogsTableSettings = (data: any) => {
  const settings = tryParseJSON(String(localStorage.getItem("bb-table-settings"))) || {};
  createNestedObj(settings, ["trackerServiceLogs"], data);
  localStorage.setItem("bb-table-settings", JSON.stringify(settings));
};
