import styled from "../../components/Themes";

export const Content = styled<any>(`div`)`
  position: absolute;
  top: 50px;
  left: ${({ open }) => (open ? "160px" : "50px")};
  bottom: 0;
  right: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 576px) {
    left: 0;
  }
`;
