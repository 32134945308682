import axios from "axios";
import ReactEcharts from "echarts-for-react";
import moment from "moment";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import { getTrackerDurations } from "../../services/trackerDurations";
import InfoIcon from "../../svgs/Legend";
import { isBinaryBeer } from "../../util/checkDomain";
import errToStr from "../../util/errToStr";
import hexToRgba from "../../util/hexToRgba";
import { humaniseHours } from "../../util/humaniseDurations";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import ChartHeading from "../ChartHeading";
import LoadingContainer from "../LoadingContainer";
import InfoTooltip from "../Tooltip";
import TrackerListModal from "../TrackerListModal";
import { ChartContainer, DateText, InfoIconContainer } from "./styles";

const TrackerDurations: FC<any> = ({ filterDates }) => {
  const { color, echarts_theme, echarts_opacity, short_date, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [trackerList, setTrackerList] = useState<any>([]);
  const [trackerListTitle, setTrackerListTitle] = useState<any>("");
  const [trackerListModalOpen, setTrackerListModalOpen] = useState<boolean>(false);

  const options = useRef<any>({});

  const [echartsInstance, setEchartsInstance] = useState<any>(undefined);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    // If end date is undefined set to current date
    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined ? filterDates.end.unix() : moment().unix(),
    };

    getTrackerDurations(source, dates)
      .then((response: any) => {
        let series: any = [];

        if (response && response.length > 0) {
          // if not binarybeer combine series with the same legendName
          if (isBinaryBeer()) {
            series = response;
          } else {
            response.forEach((el: any) => {
              const index = series.findIndex((_el: any) => _el.legendName === el.legendName);

              if (index > -1) {
                series[index] = {
                  ...series[index],
                  name: el.legendName,
                  value: (series[index].value + el.value) / 2,
                  trackers: [...series[index].trackers, ...el.trackers],
                  empty: false,
                };
              } else {
                series.push({
                  ...el,
                  name: el.legendName,
                  empty: false,
                });
              }
            }, []);
          }
        }

        setData(series);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData([]);
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [filterDates]);

  const tooltipFormatter = (param: any) => {
    if (param) {
      let tooltip = `<span>${param.name}<br />`;
      tooltip += `${param.marker} ${humaniseHours(+param.value, ["d", "h"])}`;
      tooltip += "</span>";
      return tooltip;
    }
  };

  const formatSeries = () => {
    if (data.length > 0) {
      return data.map(
        (el: any) => ({
          type: "bar",
          name: el.legendName,
          color: hexToRgba(el.colour, 80),
          data: [
            {
              name: el.name,
              value: el.value,
              trackers: el.trackers,
              itemStyle: {
                color: hexToRgba(el.colour, el.empty ? 30 : 80),
                borderWidth: 1.5,
                borderColor: el.colour,
              },
            },
          ],
        }),
        []
      );
    }
  };

  const formatLegend = () => {
    if (data.length > 0) {
      return data.reduce((filtered: any, el: any) => {
        filtered.push({ name: el.legendName });
        return filtered;
      }, []);
    }
  };

  useEffect(() => {
    if (echartsInstance) {
      options.current = {
        tooltip: {
          trigger: "item",
          axisPointer: {
            type: "shadow",
          },
          transitionDuration: 0,
          formatter: tooltipFormatter,
        },
        legend: {
          data: formatLegend(),
          type: "scroll",
          bottom: 0,
        },
        grid: {
          top: 10,
          right: 10,
          bottom: 30,
          left: 10,
          containLabel: true,
        },
        xAxis: {
          type: "category",
          axisLabel: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: (value: any): string => humaniseHours(value, ["d"]),
          },
          axisLine: {
            show: true,
          },
        },
        series: formatSeries(),
      };

      // Updates the options of the chart each render
      // This is mainly to update the splitNumber on the bottom time axis
      if (options && options.current) {
        echartsInstance.setOption(options.current);
      }
    }
  }, [echartsInstance, data, echarts_opacity]);

  // listens for window resize events and triggers a chart resize so it fits
  // properly within it's container, fixes issue on iOS tablet when screen rotates
  const handleResizeEvent = useCallback(() => {
    echartsInstance.resize();
  }, [echartsInstance]);

  useEffect(() => {
    window.removeEventListener("resize", handleResizeEvent);

    if (echartsInstance) {
      window.addEventListener("resize", handleResizeEvent);
    }

    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, [echartsInstance]);

  const onClick = useCallback((props: any) => {
    if (props && props.data && props.data.trackers) {
      setTrackerList(props.data.trackers);
      setTrackerListTitle(props.data.name);
      setTrackerListModalOpen(true);
    }
  }, []);

  return (
    <>
      <LoadingContainer loading={dataLoading} err={dataErr}>
        {!dataLoading && (
          <ChartContainer>
            <ChartHeading>{kegsOrTrackers("Keg", "Tracker")} Durations</ChartHeading>
            {options && (
              <ReactEcharts
                key="current-durations-chart"
                onChartReady={(chart: any) => setEchartsInstance(chart)}
                style={{ height: "100%", width: "100%" }}
                option={options.current}
                notMerge={true}
                lazyUpdate={true}
                theme={echarts_theme}
                onEvents={{ click: onClick }}
                opts={{ renderer: "svg" }}
              />
            )}
            <DateText
              style={{ textAlign: "left", marginTop: "6px" }}
              title={`${moment(filterDates.start).format(long_datetime)} - ${moment(filterDates.end).format(long_datetime)}`}
            >
              {filterDates.isNow
                ? filterDates.dateLabel
                : `From ${moment(filterDates.start).format(short_date)} to ${moment(filterDates.end).format(short_date)}`}
            </DateText>
            <InfoTooltip
              content={`For the selected date range, the average duration ${kegsOrTrackers("kegs", "trackers")} spend at different place types`}
              touch={true}
            >
              <InfoIconContainer>
                <InfoIcon fill={color.font[2]} />
              </InfoIconContainer>
            </InfoTooltip>
          </ChartContainer>
        )}
      </LoadingContainer>
      {trackerListModalOpen && trackerList.length > 0 && (
        <TrackerListModal
          title={trackerListTitle + " List"}
          data={trackerList}
          columns={["id", "startDate", "endDate", "duration"]}
          size="lg"
          modalOpen={trackerListModalOpen}
          setModalOpen={setTrackerListModalOpen}
          onClose={() => {
            setTrackerList([]);
            setTrackerListTitle("");
          }}
        />
      )}
    </>
  );
};

export default TrackerDurations;
