import axios, { CancelTokenSource } from "axios";
import matchSorter from "match-sorter";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { ThemeContext } from "styled-components";
import { fetchEditOrganisations } from "../../services/editOrganisation";
import MoreIcon from "../../svgs/MoreIcon";
import { isBinaryBeer } from "../../util/checkDomain";
import { getRole, isAdminOrUser, isSuper } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import Badge from "../Badge";
import Bold from "../Bold";
import { PrimaryBtn } from "../Buttons";
import CreateOrganisationModal from "../CreateOrganisationModal";
import EditOrganisationModal from "../EditOrganisationModal";
import { PrimaryIconBtn } from "../IconButtons";
import LeaveOrganisationModal from "../LeaveOrganisationModal";
import LoadingContainer from "../LoadingContainer";
import Table, { CsvButtonsComponent } from "../Table";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";
import { DesktopDiv, MobileDiv, TableHeaderButtons } from "./../NewTable/styles";

const OrganisationsTable: FC<any> = () => {
  const { color } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [selectedOrg, setSelectedOrg] = useState<any>(null);

  const [editModalOpen, setEditModalOpen] = useState<any>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [leaveOrgModalOpen, setLeaveOrgModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchEditOrganisations(source)
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [source]);

  const formatDataToCsv = (tableRef: any) => {
    const headers = ["Organisation", "Users", "Places", kegsOrTrackers("Kegs", "Trackers"), "Allow Public Scans", "Allow Public Buttons", "Enabled", "Role"];

    if (isBinaryBeer()) {
      headers.splice(4, 0, "Beers");
    }

    const data = tableRef.current.getResolvedState().sortedData;

    return [
      headers,
      ...data.map((row: any) => {
        const rows = [
          row.name,
          row.userCount,
          row.placeCount,
          row.sensorCount,
          row.allowPublicScans ? "True" : "False",
          row.allowPublicButtons ? "True" : "False",
          row.enabled ? "True" : "False",
          row.role,
        ];

        if (isBinaryBeer()) {
          rows.splice(4, 0, row.beerCount);
        }

        return rows;
      }, []),
    ];
  };

  const columns: any = [];

  columns.push({
    Header: "Actions",
    minWidth: 110,
    maxWidth: 110,
    filterable: false,
    sortable: false,
    Cell: ({ original }: any) => {
      return (
        <Tooltip
          maxWidth="none"
          theme="binary-no-padding"
          content={
            <MenuList>
              {original.role === "Admin" ? (
                <MenuButton
                  onClick={() => {
                    setSelectedOrg(original);
                    setEditModalOpen(true);
                  }}
                >
                  Edit Organisation
                </MenuButton>
              ) : (
                <Tooltip trigger="mouseenter" content="Only admins can edit organisations">
                  <div
                    style={{
                      cursor: "not-allowed",
                      userSelect: "none",
                    }}
                  >
                    <MenuButton disabled={true}>Edit Organisation</MenuButton>
                  </div>
                </Tooltip>
              )}
              {!isSuper() && (
                <DangerMenuButton
                  onClick={() => {
                    setSelectedOrg(original);
                    setLeaveOrgModalOpen(true);
                  }}
                >
                  Leave Organisation
                </DangerMenuButton>
              )}
            </MenuList>
          }
          interactive={true}
          touch={true}
          appendTo={document.body}
          trigger="click"
          placement="bottom-start"
        >
          <MoreIconContainer>
            <MoreIconSize>
              <MoreIcon fill={color.font[2]} />
            </MoreIconSize>
          </MoreIconContainer>
        </Tooltip>
      );
    },
  });

  columns.push({
    id: "name",
    Header: "Name",
    accessor: "name",
    minWidth: 180,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["name"],
      }),
    filterAll: true,
    Cell: (props: any) => <span title={props.value}>{props.value}</span>,
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  columns.push({
    id: "userCount",
    Header: "Users",
    accessor: "userCount",
    minWidth: 110,
    maxWidth: 110,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["userCount"],
      }),
    filterAll: true,
    Cell: (props: any) => <span title={props.value}>{props.value}</span>,
    Footer: ({ data }: any) => <Bold>Total: {data.reduce((a: any, d: any) => a + d.userCount, 0)}</Bold>,
  });

  columns.push({
    id: "placeCount",
    Header: "Places",
    accessor: "placeCount",
    minWidth: 110,
    maxWidth: 110,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["placeCount"],
      }),
    filterAll: true,
    Cell: (props: any) => <span title={props.value}>{props.value}</span>,
    Footer: ({ data }: any) => <Bold>Total: {data.reduce((a: any, d: any) => a + d.placeCount, 0)}</Bold>,
  });

  columns.push({
    id: "sensorCount",
    Header: kegsOrTrackers("Kegs", "Trackers"),
    accessor: "sensorCount",
    minWidth: 110,
    maxWidth: 110,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["sensorCount"],
      }),
    filterAll: true,
    Cell: (props: any) => <span title={props.value}>{props.value}</span>,
    Footer: ({ data }: any) => <Bold>Total: {data.reduce((a: any, d: any) => a + d.sensorCount, 0)}</Bold>,
  });

  if (isBinaryBeer()) {
    columns.push({
      id: "beerCount",
      Header: "Beers",
      accessor: "beerCount",
      minWidth: 110,
      maxWidth: 110,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["beerCount"],
        }),
      filterAll: true,
      Cell: (props: any) => <span title={props.value}>{props.value}</span>,
      Footer: ({ data }: any) => <Bold>Total: {data.reduce((a: any, d: any) => a + d.beerCount, 0)}</Bold>,
    });
  }

  columns.push({
    id: "allowPublicScans",
    Header: "Public Scans",
    accessor: "allowPublicScans",
    minWidth: 150,
    maxWidth: 150,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "true") {
        return row[filter.id] === true;
      }
      if (filter.value === "false") {
        return row[filter.id] === false;
      }
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
    ),
    Cell: (props: any) => (
      <Badge title={props.value ? "True" : "False"} background={props.value ? color.success_dark[2] : color.danger_dark[2]}>
        {props.value ? "True" : "False"}
      </Badge>
    ),
  });

  columns.push({
    id: "allowPublicButtons",
    Header: "Public Buttons",
    accessor: "allowPublicButtons",
    minWidth: 160,
    maxWidth: 160,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "true") {
        return row[filter.id] === true;
      }
      if (filter.value === "false") {
        return row[filter.id] === false;
      }
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
    ),
    Cell: (props: any) => (
      <Badge title={props.value ? "True" : "False"} background={props.value ? color.success_dark[2] : color.danger_dark[2]}>
        {props.value ? "True" : "False"}
      </Badge>
    ),
  });

  columns.push({
    id: "enabled",
    Header: "Enabled",
    accessor: "enabled",
    minWidth: 130,
    maxWidth: 130,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "true") {
        return row[filter.id] === true;
      }
      if (filter.value === "false") {
        return row[filter.id] === false;
      }
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
    ),
    Cell: (props: any) => (
      <Badge title={props.value ? "True" : "False"} background={props.value ? color.success_dark[2] : color.danger_dark[2]}>
        {props.value ? "True" : "False"}
      </Badge>
    ),
  });

  columns.push({
    id: "role",
    Header: "Role",
    accessor: "role",
    minWidth: 120,
    maxWidth: 120,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["role"],
      }),
    filterAll: true,
    Cell: (props: any) => {
      const role = getRole(props.value);
      return <span title={role}>{role}</span>;
    },
  });

  const defaultSorted = [
    {
      id: "name",
      desc: false,
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <LoadingContainer loading={dataLoading} err={dataErr}>
        <TableHeaderButtons>
          <div style={{ display: "flex" }}>
            {isAdminOrUser() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setCreateModalOpen(true);
                    }}
                  >
                    Create
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <PrimaryIconBtn
                      onClick={() => {
                        setCreateModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryBtn disabled={true}>Create</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryIconBtn>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
          <CsvButtonsComponent data={data} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName="Organisation List.csv" />
        </TableHeaderButtons>
        <Table
          loading={dataLoading}
          filterable={true}
          style={{ clear: "both" }}
          data={data}
          columns={columns}
          defaultSorted={defaultSorted}
          ref={tableRef}
          defaultPageSize={15}
        />
      </LoadingContainer>
      {createModalOpen && <CreateOrganisationModal setData={setData} modalOpen={createModalOpen} setModalOpen={setCreateModalOpen} />}
      {editModalOpen && <EditOrganisationModal org={selectedOrg} setData={setData} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
      {leaveOrgModalOpen && <LeaveOrganisationModal org={selectedOrg} setData={setData} modalOpen={leaveOrgModalOpen} setModalOpen={setLeaveOrgModalOpen} />}
    </div>
  );
};

export default OrganisationsTable;
