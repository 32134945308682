import Menu, { Item as MenuItem, SubMenu } from "rc-menu";
import "rc-menu/assets/index.css";
import React, { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { FaBell, FaChartPie, FaMapMarkerAlt, FaTruck, FaUsers } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa6";
import { IoEarth } from "react-icons/io5";
import { PiClipboardTextFill } from "react-icons/pi";
import { TiHome } from "react-icons/ti";
import { useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { getAccount } from "../../services/localStorage";
import SensorIcon from "../../svgs/SensorIcon";
import { isAdmin } from "../../util/checkRole";
import { isBinaryBeer, isBinaryMed } from "../../util/checkDomain";
import useWindowSize from "../../util/useWindowSize";
import RcMenuStyling from "../GlobalStyles/rc-menu";
import Tooltip from "../Tooltip";
import { Bar, CloseMenuSection, IconContainer, MenuLabel, TooltipTrigger } from "./styles";
import { IoIosBeer } from "react-icons/io";
import BbKeg from "../../svgs/BbKeg";

const SideNav: FC<any> = ({ open, toggleOpen }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { width } = useWindowSize();

  const { color } = useContext(ThemeContext);

  const [accountInfo] = useState<any>(getAccount());

  // splits url into array of paths to figure out which sub-menu is open
  const [openKeys, setOpenKeys] = useState<any>(
    pathname
      .split("/")
      .map((path: string, i: number) => (i > 0 ? `/${path}` : null), [])
      .filter((path: any) => path !== null)
      .map((path: string | null, i: number, arr: any) => {
        let newPath = "";
        for (let j = 0; j <= i; j++) {
          newPath += arr[j];
        }
        return newPath;
      }, [])
      .reverse()
  );
  // gets pathname to figure out which page/menu item is active
  const [selectedKeys, setSelectedKeys] = useState<any>([pathname]);
  const [nav, setNav] = useState<any>([]);

  // on mount work out which menu items to display
  useEffect(() => {
    const navItems = [];

    // changes home and map menu depending if the user has beer sensors
    if (accountInfo.hasBeer) {
      navItems.push({
        title: "Home",
        key: "/",
        icon: (
          <IconContext.Provider value={{ size: "20px" }}>
            <TiHome />
          </IconContext.Provider>
        ),
        subs: [],
      });
      navItems.push({
        title: "Map",
        key: "/map",
        search: "?sensorsAtPlace=kegs%20ge%201",
        icon: (
          <IconContext.Provider value={{ size: "20px" }}>
            <IoEarth />
          </IconContext.Provider>
        ),
        subs: [],
      });
    } else {
      navItems.push({
        title: "Dashboard",
        key: "/",
        icon: (
          <IconContext.Provider value={{ size: "20px" }}>
            <TiHome />
          </IconContext.Provider>
        ),
        subs: [],
      });
      navItems.push({
        title: "Map",
        key: "/map",
        search: "?sensorsAtPlace=kegs%20ge%201",
        icon: (
          <IconContext.Provider value={{ size: "20px" }}>
            <IoEarth />
          </IconContext.Provider>
        ),
        subs: [],
      });
    }

    navItems.push({
      title: "Reports",
      key: "/reports",
      icon: (
        <IconContext.Provider value={{ size: "20px" }}>
          <FaChartPie />
        </IconContext.Provider>
      ),
      subs: [],
    });

    navItems.push({
      title: "Alerts",
      key: "/alerts",
      icon: (
        <IconContext.Provider value={{ size: "20px" }}>
          <FaBell />
        </IconContext.Provider>
      ),
      subs: [],
    });

    if (isBinaryMed()) {
      navItems.push({
        title: "Manifests",
        key: "/manifests",
        icon: (
          <IconContext.Provider value={{ size: "20px" }}>
            <PiClipboardTextFill />
          </IconContext.Provider>
        ),
        subs: [],
      });
    }

    navItems.push({
      title: "Places",
      key: "/places",
      icon: (
        <IconContext.Provider value={{ size: "20px" }}>
          <FaMapMarkerAlt />
        </IconContext.Provider>
      ),
      subs: [],
    });

    // changes which icon to use for the trackers page
    let title = "Trackers";
    let key = "/trackers";
    let icon = (
      <div style={{ marginTop: "-3px" }}>
        <SensorIcon width={20} height={24} />
      </div>
    );

    if (isBinaryBeer()) {
      title = "Kegs";
      key = "/kegs";
      icon = (
        <IconContext.Provider value={{ size: "20px" }}>
          <BbKeg size="100%" />
        </IconContext.Provider>
      );
    }

    navItems.push({
      title,
      key,
      icon,
      subs: [],
    });

    if (isBinaryBeer()) {
      navItems.push({
        title: "Beers",
        key: "/beers",
        icon: (
          <IconContext.Provider value={{ size: "20px" }}>
            <IoIosBeer />
          </IconContext.Provider>
        ),
        subs: [],
      });
    }

    navItems.push({
      title: "Pickup Requests",
      key: "/pickup-requests",
      icon: (
        <IconContext.Provider value={{ size: "20px" }}>
          <FaTruck />
        </IconContext.Provider>
      ),
      subs: [],
    });

    if (isAdmin()) {
      navItems.push({
        title: "Users",
        key: "/users",
        icon: (
          <IconContext.Provider value={{ size: "20px" }}>
            <FaUsers />
          </IconContext.Provider>
        ),
        subs: [],
      });
    }

    navItems.push({
      title: "Organisations",
      key: "/organisations",
      icon: (
        <IconContext.Provider value={{ size: "20px" }}>
          <FaBuilding />
        </IconContext.Provider>
      ),
      subs: [],
    });

    setNav(navItems);
  }, [color]);

  const iterateMenuItems: any = (route: any) => {
    // if the menu item has subs it must be a sub menu otherwise it is a normal menu item
    if (route.subs.length > 0) {
      return (
        <SubMenu
          key={route.key}
          title={
            <>
              {!open ? (
                <Tooltip key={route.key} content={route.title} placement="right" trigger="mouseenter">
                  <TooltipTrigger active={route.key === pathname} />
                </Tooltip>
              ) : (
                <TooltipTrigger active={route.key === pathname} />
              )}
              <IconContainer open={open}>{route.icon}</IconContainer>
              {open && <MenuLabel>{route.title}</MenuLabel>}
            </>
          }
        >
          {route.subs.map((route: any) => iterateMenuItems(route), [])}
        </SubMenu>
      );
    } else {
      return (
        <MenuItem key={route.key}>
          {!open ? (
            <Tooltip key={route.key} content={route.title} placement="right" trigger="mouseenter">
              <TooltipTrigger active={route.key === pathname} />
            </Tooltip>
          ) : (
            <TooltipTrigger active={route.key === pathname} />
          )}
          <IconContainer open={open}>{route.icon}</IconContainer>
          {open && <MenuLabel>{route.title}</MenuLabel>}
        </MenuItem>
      );
    }
  };

  const generateMenu = (nav: any) => {
    return React.createElement(
      Menu as any,
      {
        mode: "inline",
        onClick: (props: any) => {
          if (width < 576) toggleOpen(false);
          setSelectedKeys([props.key]);
          setOpenKeys(props.keyPath);
          navigate({ pathname: props.key, search: props.search });
        },
        onOpenChange: (openKeys: any) => setOpenKeys(openKeys),
        selectedKeys: selectedKeys,
        openKeys: openKeys,
      },
      nav.map((route: any) => iterateMenuItems(route), [])
    );
  };

  return (
    <>
      <RcMenuStyling open={open} />
      <Bar open={open}>
        {generateMenu(nav)}
        <CloseMenuSection open={open} onClick={() => toggleOpen(!open)} />
      </Bar>
    </>
  );
};

export default SideNav;
