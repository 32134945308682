import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250" {...props}>
      <path
        d="M11.853 42.304c-10.57 6.063-10.353 4.42-10.348 78.03.005 67.397-.038 66.7 4.325 70.185l2.697 2.153 16.092.39 16.091.39 1.044 3.042c4.914 14.305 26.42 18.344 37.2 6.987 3.016-3.18 5.36-7.203 5.36-9.208 0-1.081 5.622-1.21 52.673-1.21h52.674l.873 2.647c6.273 19.007 38.414 18.787 42.605-.292.495-2.257.69-2.355 4.646-2.355 4.813 0 7.805-1.876 9.513-5.966 1.693-4.052 1.55-46.323-.168-49.645-1.518-2.935-42.085-48.303-44.383-49.635-1.163-.674-7.354-.957-21.364-.977l-19.726-.027v-14.33c0-19.758-1.915-25.34-10.353-30.18l-4.1-2.351H15.954l-4.1 2.352m134.522 7.91c1.138.519 2.816 2.579 3.943 4.842l1.963 3.939v88.755H97.36c-59.509 0-74.994-23.125-74.994-18.506 0 5.18 15.721 27.88 75.793 27.88h54.123v27.345H85.38l-1.308-4.148c-6.27-19.894-36.216-19.894-42.487 0l-1.307 4.148H27.005c-11.253 0-13.489-.197-14.7-1.293-1.362-1.232-1.429-4.203-1.429-62.737V58.995l1.966-3.947c1.082-2.17 2.751-4.325 3.711-4.788 2.56-1.235 127.117-1.278 129.823-.045m54.668 48.902l2.69 2.93-10.159.39-10.159.39-3.114 3.342-3.115 3.34.243 14.81.242 14.81 2.554 2.553 2.553 2.553 28.111.223 28.111.224v19.085c0 18.069-.08 19.128-1.512 19.893-2.35 1.258-3.105.982-3.66-1.34-3.99-16.635-26.49-22.777-38.074-10.392-3.535 3.779-5.973 8.3-5.973 11.074 0 1.38-.826 1.467-14.063 1.467h-14.062V96.188h36.697l2.69 2.929m18.915 21.485c4.312 4.834 8.991 10.107 10.397 11.719l2.556 2.929H186.657v-11.198c0-6.16.234-11.432.521-11.718.286-.287 6.015-.521 12.73-.521h12.21l7.841 8.789M69.664 176.885c7.566 4.188 8.074 15.896.926 21.348-6.242 4.76-16.62 2.183-19.563-4.858-5.075-12.146 7.07-22.892 18.637-16.49m148.245-.588c8.863 4.445 9.118 17.55.44 22.636-6.194 3.63-13.61 1.903-17.526-4.082-7.299-11.157 5.064-24.582 17.086-18.554"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgComponent;
