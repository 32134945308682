import styled from "../Themes";

export const MapContainer = styled<any>(`div`)`
  float: left;
  width: 100%;
  clear: both;
  height: 500px;
  border: 1px solid ${({ theme, error }) => (error ? theme.color.error[2] : theme.color.font_bold[2])};
  border-radius: 3px;

  @media (max-width: 768px) {
    height: 400px;
  }
`;

export const LocationLoading = styled(`div`)`
  position: absolute;
  width: 160px;
  padding: 4px;
  background: ${({ theme }) => theme.color.main_bg[2]};
  border: 1px solid ${({ theme }) => theme.color.main_fg[2]};
  border-radius: 3px;
  top: 32px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 1000;
  text-align: center;
`;
