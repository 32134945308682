import React, { FC, useState, useEffect } from "react";
import { ModalFilterContainer, ModalFilterHeader } from "./styles";
import { AsyncSelect } from "../Select";
import { fetchAutoComplete } from "../../services/autoComplete";

// Sets the initial values of the selected asset types if there are URL query parameters.
// If there are none we use the default initial values []
const getValues = (sensorFilters: any) => {
  const { assetTypes } = sensorFilters;

  const assetTypesArray = assetTypes ? assetTypes.split(",") : [];

  if (assetTypesArray.length > 0) {
    return assetTypesArray.map((label: string) => ({ value: label, label }));
  }

  return [];
};

const AssetTypes: FC<any> = ({ sensorFilters, setModalQuery }) => {
  const [values, setValues] = useState<any>(getValues(sensorFilters));

  // This is to update the values and button state when a user changes the query
  // parameters (e.g. when a user navigates using the back/forward buttons).
  // Each time the sensorFilters change (whenever the sensor query parameters change),
  // if the new values don't match the current values, update the values and button state
  useEffect(() => {
    const newValues = getValues(sensorFilters);
    if (JSON.stringify(values) !== JSON.stringify(newValues)) {
      setValues(newValues);
    }
  }, [sensorFilters]);

  // On initial render, sets the modal query string state in
  // the parent component to the initial values
  useEffect(() => {
    updateModalQuery(values);
  }, []);

  const updateModalQuery = (values: any) => {
    if (values.length > 0) {
      const assetTypes = values.map((label: any) => label.value, []);
      setModalQuery((prev: any) => ({
        ...prev,
        assetTypes: assetTypes.join(","),
      }));
    } else {
      setModalQuery((prev: any) => ({
        ...prev,
        assetTypes: undefined,
      }));
    }
  };

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      // Set value the same to label (since label is unique too) so we can save the filter in the url params
      const assetTypes = response.map((asset: any) => ({ ...asset, value: asset.label }));
      callback(assetTypes);
    });
  };

  return (
    <ModalFilterContainer>
      <ModalFilterHeader>Asset Types</ModalFilterHeader>
      <AsyncSelect
        name="assetType"
        defaultOptions={true}
        closeMenuOnSelect={false}
        isClearable={true}
        isMulti={true}
        isSearchable={true}
        value={values}
        loadOptions={(inputValue: any, callback: any) => loadOptions("assettypes", inputValue, callback)}
        onChange={(selected: any) => {
          if (selected) {
            setValues(selected);
            updateModalQuery(selected);
          } else {
            setValues([]);
            updateModalQuery([]);
          }
        }}
        placeholder="Select..."
      />
    </ModalFilterContainer>
  );
};

export default AssetTypes;
