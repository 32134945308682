import L, { LatLngTuple } from "leaflet";
import React, { FC, useState, useEffect, useContext, useRef } from "react";
import Bold from "../Bold";
import { Menu, Header, Content, SelectContainer, Toggle, ToggleText, ListItem } from "./styles";
import "@zendeskgarden/react-buttons/dist/styles.css";
import { ThemeContext } from "styled-components";
import { Select } from "../Select";
import fastSort from "fast-sort";
import { FormInput } from "../FormComponents";
import matchSorter from "match-sorter";
import { useDebounce } from "use-debounce";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import { printLength } from "../../util/formatUnits";

const PublicMapList: FC<any> = ({ map, places, bounds, toggleList, listOpen }) => {
  const { color } = useContext(ThemeContext);

  const hoverRef = useRef<any>({});

  const [placeList, setPlaceList] = useState<any>([]);
  const [boundedPlaces, setBoundedPlaces] = useState<any>([]);
  const [filteredPlaces, setFilteredPlaces] = useState<any>([]);
  const [sortedPlaces, setSortedPlaces] = useState<any>([]);
  const [placeFilter, setPlaceFilter] = useState<string>("");
  const [placeCards, setPlaceCards] = useState<any>([]);
  const [sortPlacesBy, setSortPlacesBy] = useState<string>("kegs-desc");

  const [placeFilterValue] = useDebounce(placeFilter, 250);

  useEffect(() => {
    setPlaceList(places);
  }, [places]);

  // Filter out the places that are only visible on the map
  useEffect(() => {
    if (listOpen && bounds) {
      if (placeList.length > 0) {
        const bounded: any = [];

        for (let i = 0; i < placeList.length; i++) {
          const { latitude, longitude } = placeList[i].coordinates;
          if (latitude !== undefined && longitude !== undefined) {
            if (bounds.contains(L.latLng(latitude, longitude))) {
              bounded.push(placeList[i]);
            }
          }
        }

        setBoundedPlaces(bounded);
      }
    }
  }, [listOpen, placeList, bounds]);

  // Filter out the places that don't match the filtered text
  useEffect(() => {
    if (listOpen) {
      const filtered = matchSorter(boundedPlaces, placeFilterValue, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["name", "beerString"],
      });
      setFilteredPlaces(filtered);
    }
  }, [listOpen, boundedPlaces, placeFilterValue]);

  // Sort places when "Sort by" dropdown or filteredPlaces changes
  useEffect(() => {
    if (listOpen) {
      const sort = sortPlacesBy.split("-")[0];
      const order = sortPlacesBy.split("-")[1];

      const sorted = order === "asc" ? fastSort(filteredPlaces).asc((el: any) => el[sort]) : fastSort(filteredPlaces).desc((el: any) => el[sort]);

      setSortedPlaces(sorted);
    }
  }, [listOpen, sortPlacesBy, filteredPlaces]);

  useEffect(() => {
    if (listOpen) {
      const sort = sortPlacesBy.split("-")[0];

      if (placeList.length > 0) {
        setPlaceCards(
          sortedPlaces.map((place: any, i: number) => {
            return (
              <ListItem
                style={{ cursor: "pointer" }}
                onClick={() => handleClick(place.coordinates, place.radius)}
                onMouseEnter={() => handleMouseEnterPlace(place)}
                onMouseLeave={removeHoverElements}
                key={i}
              >
                <span>Venue</span>: <Bold>{place.name}</Bold>
                {/* {place.placeGroup && (
                  <p>
                    <span>Place Type</span>: <ColoredDot color={place.placeGroupColour} />
                    <Bold>{place.placeGroup}</Bold>
                  </p>
                )} */}
                {place.beerString && (
                  <p>
                    Beers: <Bold>{place.beerString}</Bold>
                  </p>
                )}
                <p>
                  Keg Count: <Bold>{place.beers ? place.beers.reduce((acc: number, beer: any) => acc + beer.count, 0) : 0}</Bold>
                </p>
                {sort === "radius" && (
                  <p>
                    Radius: <Bold>{printLength(place.radius)}</Bold>
                  </p>
                )}
              </ListItem>
            );
          })
        );
      } else {
        setPlaceCards([]);
      }
    }
  }, [listOpen, sortedPlaces, sortPlacesBy, placeList]);

  const handleClick = (coordinates: any, radius: number) => {
    const circle = L.circle([coordinates.latitude, coordinates.longitude], {
      radius,
    }).addTo(map);
    map.fitBounds(circle.getBounds());
    map.removeLayer(circle);
  };

  const handleMouseEnterPlace = (place: any) => {
    const latlng: LatLngTuple = [place.coordinates.latitude, place.coordinates.longitude];

    const container = L.DomUtil.create("div");
    const name = L.DomUtil.create("span", "popup-title");
    const beerTitle = L.DomUtil.create("span", "popup-bold");
    // const placeGroup = L.DomUtil.create("span", "popup-text");
    const beers: any = [];

    if (place.beers && place.beers.length > 0) {
      place.beers.forEach((beer: any) => {
        const beerText = L.DomUtil.create("span", "popup-text");
        beerText.innerHTML = `${beer.name}: ${beer.count} keg${beer.count == 1 ? "" : "s"} (${beer.temperature}°C)`;
        beers.push(beerText);
      });
    }

    name.innerHTML = place.name;
    beerTitle.innerHTML = "Beers Here";
    // placeGroup.innerHTML = place.placeGroup ? `Place Type: ${place.placeGroup}` : "";

    container.appendChild(name);
    // container.appendChild(placeGroup);
    if (beers.length > 0) {
      container.appendChild(beerTitle);
      beers.forEach((beer: any) => container.appendChild(beer));
    }

    removeHoverElements();

    hoverRef.current.circle = L.circle(latlng, {
      radius: place.radius,
      color: color.secondary[2],
    }).addTo(map);

    hoverRef.current.popup = L.popup().setLatLng(latlng).setContent(container).openOn(map);
  };

  const removeHoverElements = () => {
    hoverRef.current.circle && map.removeLayer(hoverRef.current.circle);
    hoverRef.current.popup && map.removeLayer(hoverRef.current.popup);
    hoverRef.current = {};
  };

  // when the mouse enters the map this removes the map elements created when the mouse
  // is enters a list item. This is needed because when a user clicks a list item
  // and the map pans sometimes it automatically moves the list item away from the mouse due
  // to less map markers being displayed causing the mouse leave event to be missed
  useEffect(() => {
    map.on("mouseover", removeHoverElements);
    return () => {
      map.off("mouseover", removeHoverElements);
    };
  }, [map, removeHoverElements]);

  const placeFilterOptions = [
    { value: "kegs-desc", label: `Keg Count: High` },
    { value: "kegs-asc", label: `Keg Count: Low` },
    { value: "radius-desc", label: "Venue Radius: High" },
    { value: "radius-asc", label: "Venue Radius: Low" },
    { value: "name-desc", label: "Venue Name: Desc" },
    { value: "name-asc", label: "Venue Name: Asc" },
  ];

  return (
    <Menu open={listOpen}>
      <Toggle onClick={() => toggleList(!listOpen)}>
        <ToggleText>List</ToggleText>
      </Toggle>

      <div
        style={{
          position: "absolute",
          top: "12px",
          bottom: 0,
          width: "100%",
          display: "flex",
        }}
      >
        <Header>
          <p style={{ padding: "4px" }}>
            <Bold>Venues</Bold>
          </p>

          <p style={{ padding: "4px" }}>
            <Bold>{placeCards.length}</Bold> out of <Bold>{placeList.length}</Bold>
          </p>

          <div style={{ padding: "4px" }}>
            <span style={{ padding: "0 10px 10px 10px" }}>Sort by</span>
            <SelectContainer>
              <Select
                defaultValue={{
                  value: "kegs-desc",
                  label: "Keg Count: High",
                }}
                isClearable={false}
                isSearchable={false}
                onChange={(selected: any) => {
                  setSortPlacesBy(selected.value);
                }}
                options={placeFilterOptions}
              />
            </SelectContainer>
          </div>

          <div style={{ padding: "4px", width: "80%", margin: "auto" }}>
            <FormInput placeholder="Filter Venue..." type="text" onChange={(e: any) => setPlaceFilter(e.target.value)} value={placeFilter} />
          </div>
        </Header>
        <Content>{placeCards}</Content>
      </div>
    </Menu>
  );
};

export default PublicMapList;
