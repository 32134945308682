export const MAPBOX_ACCESS_TOKEN = "pk.eyJ1IjoiYnJhZHdvb2RzaW8iLCJhIjoiY2pxdnB0ajI3MHh0OTQ5b2M0Z3R2ZHBoeSJ9.xLGRPusEbOriBf4HSFwBeg";

export const INITIAL_VIEW_STATE = {
  longitude: 150.8972753,
  latitude: -34.4017924,
  zoom: 8,
  minZoom: 2,
  maxZoom: 20,
  pitch: 0,
  bearing: 0,
};

export const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 128, height: 128, anchorY: 128, mask: true },
};
