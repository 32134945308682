import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.gridlayer.googlemutant/dist/Leaflet.GoogleMutant";
import "leaflet.markercluster/dist/leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet-easybutton";
import "leaflet-easybutton/src/easy-button.css";
import React, { useEffect, FC, useState, useContext, useRef } from "react";
import { Attribution, MapContainer, MapLoading } from "./styles";
import { INITIAL_VIEW_STATE } from "../../util/mapUtils";
import { ThemeContext } from "styled-components";
import LeafletStyling from "../GlobalStyles/leaflet";
import useWindowSize from "../../util/useWindowSize";
import { useLocation, useParams } from "react-router-dom";
import LoadingContainer from "../LoadingContainer";
import queryString from "query-string";
import { useDebounce } from "use-debounce";
import getMarkerIcon from "../../util/getMarkerIcon";
import axios from "axios";
import errToStr from "../../util/errToStr";
import { fetchPublicBeerMap } from "../../services/publicBeerMap";
import PublicMapList from "../PublicMapList";
import BinaryBeerLogo from "../../svgs/BinaryBeerLogo";

const initActiveLayer = (layer: any) => {
  const validLayers = ["Map", "Terrain", "Satellite", "Hybrid"];
  if (layer && validLayers.includes(layer)) return layer;
  else return "Map";
};

const initListOpen = (width: any, listOpen: any): boolean => {
  if (listOpen !== "false") {
    if (width >= 1150) {
      if (listOpen === "true") return true;
      else return false;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const PublicBeerMapScreen: FC<any> = () => {
  const location = useLocation();
  const { organisationId = "no-org" } = useParams();

  const { color, map_style } = useContext(ThemeContext);

  const { width } = useWindowSize();

  const mapRef = useRef<HTMLDivElement>(null);

  const [places, setPlaces] = useState<any>([]);
  const [placesErr, setPlacesErr] = useState<string>("");
  const [placesLoading, setPlacesLoading] = useState<any>(true);
  const [placesLoaded, setPlacesLoaded] = useState<boolean>(false);

  const [queryParams] = useState<any>(queryString.parse(location.search));
  const [map, setMap] = useState<any>(undefined);
  const [bounds, setBounds] = useState<any>(undefined);
  const [mapControl, setMapControl] = useState<any>(undefined);
  const [mapLayer, setMapLayer] = useState<any>(undefined);
  const [activeLayer, setActiveLayer] = useState<string>(initActiveLayer(queryParams.layer));
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [placeCluster, setPlaceCluster] = useState<any>(undefined);
  const [markers, setMarkers] = useState<any>({ places: [] });
  const [listOpen, toggleList] = useState<boolean>(initListOpen(width, queryParams.list_open));

  // used to update the query parameters of the map bounds if they haven't changed in 500 milliseconds
  const [boundsValue] = useDebounce(bounds, 500);

  const showAllBtn = useRef<any>(null);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setPlacesLoading(true);
    fetchPublicBeerMap(source, organisationId)
      .then((response) => {
        const places = response.map((place: any) => ({ ...place, beerString: place.beers ? place.beers.map((beer: any) => beer.name).join(", ") : "" }));
        setPlaces(places);
        setPlacesErr("");
        setPlacesLoading(false);
        setPlacesLoaded(true);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setPlacesErr(errToStr(err));
          setPlacesLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  // On places loaded, initialise leaflet map
  useEffect(() => {
    if (placesLoaded) {
      const newMap = L.map("map", {
        // @ts-ignore
        fullscreenControl: true,
        doubleClickZoom: true,
        worldCopyJump: true,
        attributionControl: false,
        maxBoundsViscosity: 1,
        maxBounds: new L.LatLngBounds(new L.LatLng(-90, -Infinity), new L.LatLng(90, Infinity)),
        center: [INITIAL_VIEW_STATE.latitude, INITIAL_VIEW_STATE.longitude],
        zoomControl: false,
        zoom: INITIAL_VIEW_STATE.zoom,
        minZoom: 1,
      });

      L.control
        .zoom({
          position: "topleft",
        })
        .addTo(newMap);

      setBounds(newMap.getBounds());

      createMapControl(newMap);

      newMap.on("baselayerchange", (event: any) => {
        // If the user changes the baselayer, update the state with new layer and name
        setActiveLayer(event.name);
        setMapLayer(event.layer);
      });

      newMap.on("dragend", (e: any) => {
        setBounds(newMap.getBounds());
      });

      newMap.on("zoomend", (e: any) => {
        setBounds(newMap.getBounds());
      });

      newMap.on("enterFullscreen", () => {
        setFullscreen(true);
      });

      newMap.on("exitFullscreen", () => {
        setFullscreen(false);
      });

      if (queryParams.ne_lat && queryParams.ne_lng && queryParams.sw_lat && queryParams.sw_lng) {
        newMap.fitBounds(L.latLngBounds([+queryParams.ne_lat, +queryParams.ne_lng], [+queryParams.sw_lat, +queryParams.sw_lng]));
      } else {
        if (places.length > 0) {
          const bounds = new L.LatLngBounds(
            places.map((place: any) => {
              if (place.coordinates.latitude !== undefined && place.coordinates.longitude !== undefined) {
                return [place.coordinates.latitude, place.coordinates.longitude];
              }
            }, [])
          );
          newMap.fitBounds(bounds, {
            padding: [50, 50],
            maxZoom: 18,
          });
        }
      }

      setMap(newMap);
    }
  }, [placesLoaded]);

  // Updates the query string when the boundsValue changes (which is a debounce value)
  useEffect(() => {
    if (boundsValue) updateQueryString(boundsValue, activeLayer, listOpen);
  }, [boundsValue, activeLayer, listOpen]);

  useEffect(() => {
    if (map) {
      updatePlaces(map, places);
    }
  }, [map, places]);

  // On theme change, update the map's base layers and layer controls
  useEffect(() => {
    if (map) {
      createMapControl(map);
    }
  }, [map_style]);

  // Checks if the map container size changed and updates the map if so
  useEffect(() => {
    const mapDiv = document.getElementById("map");

    if (!mapDiv || !map) return;

    const observer = new ResizeObserver(() => {
      if (map) {
        map.invalidateSize();
      }
    });

    observer.observe(mapDiv);

    return () => {
      observer.unobserve(mapDiv);
      observer.disconnect();
    };
  }, [map]);

  useEffect(() => {
    if (map) {
      // Remove the show all button if it exists
      if (showAllBtn.current) {
        map.removeControl(showAllBtn.current);
      }

      if (markers.places.length > 0) {
        // create button and add to map
        const newShowAllBtn = L.easyButton({
          position: "topleft",
          leafletClasses: true,
          states: [
            {
              stateName: "showAll",
              onClick: () => {
                // push all place markers into list, add to group, then fit map bounds to show all
                const markerList = [];

                for (let i = 0; i < markers.places.length; i++) {
                  const row = markers.places[i];
                  if (row.lat !== undefined && row.lng !== undefined) {
                    const marker = L.marker(new L.LatLng(row.lat, row.lng));
                    markerList.push(marker);
                  }
                }

                // @ts-ignore
                const group = new L.featureGroup(markerList);
                map.fitBounds(group.getBounds(), {
                  padding: [50, 50],
                  maxZoom: 18,
                });
                setBounds(group.getBounds());
              },
              title: "Show all",
              icon: `<div class="show-all-button"></div>`,
            },
          ],
        }).addTo(map);

        // store reference to button so it can be removed/updated
        showAllBtn.current = newShowAllBtn;
      } else {
        // remove reference to button to show it doesn't exist
        showAllBtn.current = null;
      }
    }
  }, [map, markers]);

  // Triggered on moveend and zoomend events to update the query params and replace the history object
  const updateQueryString = (newBounds: any, newActiveLayer: any, newListOpen: any) => {
    const parsed = queryString.parse(location.search);

    const query = {
      sw_lat: newBounds._southWest.lat,
      sw_lng: newBounds._southWest.lng,
      ne_lat: newBounds._northEast.lat,
      ne_lng: newBounds._northEast.lng,
      layer: newActiveLayer,
      list_open: newListOpen,
    };

    const newQuery = {
      ...parsed,
      ...query,
    };

    const stringified = queryString.stringify(newQuery);

    window.history.replaceState(null, "", `${location.pathname}?${stringified}`);
  };

  const updatePlaces = (map: any, places: any) => {
    // Remove the place cluster layer from the map if it exists
    if (map && placeCluster) {
      map.removeLayer(placeCluster);
    }

    const markers: any = [];

    if (places.length > 0) {
      const placeRadiusGroup = L.layerGroup().addTo(map);

      const newPlaceCluster = L.markerClusterGroup({
        spiderfyDistanceMultiplier: 1.5,
        spiderLegPolylineOptions: {
          weight: 1,
          color: color.font[2],
          opacity: 0.5,
        },
        polygonOptions: {
          color: color.secondary[2],
          fillColor: color.secondary[2],
        },
        iconCreateFunction: (cluster: any) => {
          return getMarkerIcon(color, cluster.getChildCount(), "placeCluster");
        },
      });

      for (let i = 0; i < places.length; i++) {
        const row = places[i];
        if (row.coordinates.latitude !== undefined && row.coordinates.longitude !== undefined) {
          const marker = L.marker(new L.LatLng(row.coordinates.latitude, row.coordinates.longitude), {
            title: row.name,
            icon: getMarkerIcon(color, 1, "place"),
          });

          const placeRadius = L.circle([row.coordinates.latitude, row.coordinates.longitude], {
            radius: row.radius,
            color: color.secondary[2],
          });
          marker.on("click", () => {
            placeRadiusGroup.clearLayers();
            placeRadiusGroup.addLayer(placeRadius);
          });
          marker.on("dblclick", () => {
            map.fitBounds(placeRadius.getBounds());
          });

          marker.bindPopup(createPlacePopup(row));
          newPlaceCluster.addLayer(marker);
          markers.push({
            lat: row.coordinates.latitude,
            lng: row.coordinates.longitude,
          });
        }
      }

      map.on("click", () => {
        placeRadiusGroup.clearLayers();
      });

      map.addLayer(newPlaceCluster);
      setPlaceCluster(newPlaceCluster);
    }

    setMarkers((prev: any) => ({ ...prev, places: markers }));
  };

  const createPlacePopup = (place: any) => {
    const container = L.DomUtil.create("div");
    const name = L.DomUtil.create("span", "popup-title");
    const beerTitle = L.DomUtil.create("span", "popup-bold");
    // const placeGroup = L.DomUtil.create("span", "popup-text");
    const beers: any = [];

    if (place.beers && place.beers.length > 0) {
      place.beers.forEach((beer: any) => {
        const beerText = L.DomUtil.create("span", "popup-text");
        beerText.innerHTML = `${beer.name}: ${beer.count} keg${beer.count == 1 ? "" : "s"} (${beer.temperature}°C)`;
        beers.push(beerText);
      });
    }

    name.innerHTML = place.name;
    beerTitle.innerHTML = "Beers Here";
    // placeGroup.innerHTML = place.placeGroup ? `Place Type: ${place.placeGroup}` : "";

    container.appendChild(name);
    // container.appendChild(placeGroup);
    if (beers.length > 0) {
      container.appendChild(beerTitle);
      beers.forEach((beer: any) => container.appendChild(beer));
    }

    return L.popup().setContent(container);
  };

  const createMapControl = (currentMap: any) => {
    const roadLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "roadmap",
      styles: map_style,
    });

    const terrainLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "terrain",
      styles: map_style,
    });

    const satelliteLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "satellite",
    });

    const hybridLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "hybrid",
    });

    const layers: any = {
      Map: roadLayer,
      Terrain: terrainLayer,
      Satellite: satelliteLayer,
      Hybrid: hybridLayer,
    };

    const control = L.control.layers(
      layers,
      {},
      // @ts-ignore
      { sortLayers: true, position: "topright" }
    );

    // Remove old baselayer/control from map if set in state
    if (mapControl) currentMap.removeControl(mapControl);
    if (mapLayer) currentMap.removeLayer(mapLayer);

    // Add new baselayer/control to map
    layers[activeLayer].addTo(currentMap);
    control.addTo(currentMap);

    // Store new baselayer/control in state
    setMapLayer(layers[activeLayer]);
    setMapControl(control);
  };

  if (placesErr) {
    return <LoadingContainer err={placesErr}></LoadingContainer>;
  }

  return (
    <LoadingContainer err={placesErr}>
      <LeafletStyling fullscreen={fullscreen} />
      <MapContainer id="map" ref={mapRef} listOpen={listOpen}></MapContainer>
      {placesLoading && <MapLoading>Loading...</MapLoading>}
      {map && <PublicMapList map={map} places={places} bounds={boundsValue} toggleList={toggleList} listOpen={listOpen} />}
      <Attribution>
        Powered By <BinaryBeerLogo color={color} />
      </Attribution>
    </LoadingContainer>
  );
};

export default PublicBeerMapScreen;
