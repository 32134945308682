import axios, { CancelTokenSource } from "axios";
import { getToken } from "../localStorage";

export const fetchPlaceTrackerChart = async (source: CancelTokenSource, id: string, dates: any) => {
  const params = {
    start: dates ? dates.start : undefined,
    end: dates ? dates.end : undefined,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/placetrackerchart/" + id,
    params,
  });

  return data;
};
