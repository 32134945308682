import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { postInvite } from "../../services/invite";
import { FormInput, FormError } from "../FormComponents";
import { exists, validEmail } from "../../util/formValidations";
import { Select } from "../Select";
import { ModalFormContainer } from "../Modal/styles";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";

const initialInvite = {
  role: { label: "User", value: "User" },
  email: "",
};

const CreateInviteModal: FC<any> = ({ setData, modalOpen, setModalOpen }) => {
  const [formData, setFormData] = useState<any>(initialInvite);
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "email":
          currValid = exists(name, value, setFormErrors) && validEmail(name, value, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    return {
      action: "new",
      role: formData.role && formData.role.value ? formData.role.value : null,
      email: formData.email,
    };
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      postInvite(source, body)
        .then((response) => {
          setData((prev: any) => {
            // Insert returned data into existing list
            return [...prev, response];
          });
          setSubmittedMsg("Invite Sent - user may have to check their spam folders");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      title="Create Invite"
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Create Invite"
      body={
        <LoadingContainer loading={submitting}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer>
              <label>Role</label>
              <Select
                name="role"
                isError={formErrors.role}
                value={formData.role}
                onChange={handleSelectChange}
                placeholder="Select"
                options={[
                  { label: "User", value: "User" },
                  { label: "Admin", value: "Admin" },
                  { label: "Alerts Only", value: "AlertsOnly" },
                  { label: "Read Only", value: "ReadOnly" },
                ]}
              />
              <FormError error={formErrors.role}>{formErrors.role}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <label>Email</label>
              <FormInput type="email" name="email" value={formData.email} error={formErrors.email} onChange={handleChange} autoComplete="email" />
              <FormError error={formErrors.email}>{formErrors.email}</FormError>
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default CreateInviteModal;
