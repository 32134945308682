import styled from "../Themes";

export const DefaultAlert = styled<any>(`div`)`
  background-color: ${({ theme }) => theme.color_15.default[0]};
  border: 1px solid ${({ theme }) => theme.color_30.default[2]};
  color: ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 3px;
  text-align: center;
  margin-bottom: 32px;
  padding: 12px 12px;
`;

export const PrimaryAlert = styled<any>(`div`)`
  background-color: ${({ theme }) => theme.color_15.primary[2]};
  border: 1px solid ${({ theme }) => theme.color_30.primary[2]};
  color: ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 3px;
  text-align: center;
  margin-bottom: 32px;
  padding: 12px 12px;
`;

export const SecondaryAlert = styled<any>(`div`)`
  background-color: ${({ theme }) => theme.color_15.secondary[0]};
  border: 1px solid ${({ theme }) => theme.color_30.secondary[2]};
  color: ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 3px;
  text-align: center;
  margin-bottom: 32px;
  padding: 12px 12px;
`;

export const SuccessAlert = styled<any>(`div`)`
  background-color: ${({ theme }) => theme.color_15.success[0]};
  border: 1px solid ${({ theme }) => theme.color_30.success[2]};
  color: ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 3px;
  text-align: center;
  margin-bottom: 32px;
  padding: 12px 12px;
`;

export const InfoAlert = styled<any>(`div`)`
  background-color: ${({ theme }) => theme.color_15.info[0]};
  border: 1px solid ${({ theme }) => theme.color_30.info[2]};
  color: ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 3px;
  text-align: center;
  margin-bottom: 32px;
  padding: 12px 12px;
`;

export const WarningAlert = styled<any>(`div`)`
  background-color: ${({ theme }) => theme.color_15.warning[0]};
  border: 1px solid ${({ theme }) => theme.color_30.warning[2]};
  color: ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 3px;
  text-align: center;
  margin-bottom: 32px;
  padding: 12px 12px;
`;

export const DangerAlert = styled<any>(`div`)`
  background-color: ${({ theme }) => theme.color_15.danger[0]};
  border: 1px solid ${({ theme }) => theme.color_30.danger[2]};
  color: ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 3px;
  text-align: center;
  margin-bottom: 32px;
  padding: 12px 12px;
`;
