import styled from "../Themes";

export const PageContainer = styled<any>(`div`)`
  width: 100%;
  margin: auto;
  overflow: auto;
  padding: ${({ slim }) => (slim ? "12px" : "24px")};
  -webkit-overflow-scrolling: touch;
  position: ${({ top }) => (top !== undefined ? "absolute" : "unset")};
  top: ${({ top }) => (top ? top : "0")};
  bottom: 0;

  @media (max-width: 768px) {
    padding: ${({ slim }) => (slim ? "6px" : "12px")};
  }
`;
