/**
 * Copyright Zendesk, Inc.
 *
 * Use of this source code is governed under the Apache License, Version 2.0
 * found at http://www.apache.org/licenses/LICENSE-2.0.
 */

/** @component */
export default function isRtl({ theme } = {}) {
  return Boolean(theme && theme.rtl);
}
