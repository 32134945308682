import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import loadGoogleMapsApi from "load-google-maps-api";
import ReactGA from "react-ga";

ReactGA.initialize("UA-150316485-4");
ReactGA.set({ anonymizeIp: true });

// Try's to load the Google Maps JavaScript API then renders the react app
loadGoogleMapsApi({ key: process.env.REACT_APP_GOOGLE_API_KEY, libraries: ["places"] })
  .then(() => {
    const container = document.getElementById("root");
    const root = createRoot(container!);
    // root element is removed if unsupported browser
    if (root) root.render(<App />);
  })
  .catch((error: any) => {
    console.error(error);
    const container = document.getElementById("root");
    const root = createRoot(container!);
    // root element is removed if unsupported browser
    if (root) root.render(<App />);
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
