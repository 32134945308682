import axios, { CancelTokenSource } from "axios";
import matchSorter from "match-sorter";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { ThemeContext } from "styled-components";
import { deleteContentTag, fetchContentTags } from "../../services/editContentTags";
import { getAccount } from "../../services/localStorage";
import MoreIcon from "../../svgs/MoreIcon";
import { isAdminOrUser } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import Bold from "../Bold";
import { PrimaryBtn } from "../Buttons";
import ContentListModal from "../ContentListModal";
import DeleteModal from "../DeleteModal";
import EditContentTagModel from "../EditContentTagModel";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import { PrimaryIconBtn } from "../IconButtons";
import LoadingContainer from "../LoadingContainer";
import { DesktopDiv, MobileDiv, TableHeaderButtons } from "../NewTable/styles";
import Table, { CsvButtonsComponent } from "../Table";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

const ContentTagsTable: FC<any> = () => {
  const { color } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);

  const [tags, setTags] = useState<any>([]);
  const [tagsErr, setTagsErr] = useState<string>("");
  const [tagsLoading, setTagsLoading] = useState<boolean>(true);

  const [selectedTag, setSelectedTag] = useState<any>(null);
  const [editModalOpen, setEditModalOpen] = useState<any>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [contentListModalOpen, setContentListModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  const fetchTags = useCallback(() => {
    setTagsLoading(true);
    fetchContentTags(source)
      .then((response) => {
        setTags(response);
        setTagsLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setTagsErr(errToStr(err));
          setTagsLoading(false);
        }
      });
  }, [source]);

  useEffect(() => {
    if (source) {
      fetchTags();
    }

    return () => {
      source.cancel();
    };
  }, [source, fetchTags]);

  const formatDataToCsv = (tableRef: any) => {
    const sortedData = tableRef.current.getResolvedState().sortedData;
    let headers = [];

    headers = ["Name", "Description", "Contents Count", "Colour"];

    return [
      headers,
      ...sortedData.map((row: any) => {
        return [row.name, row.description, row.contentCount, row.colour];
      }, []),
    ];
  };

  const columns: any = [];

  columns.push({
    Header: "Actions",
    minWidth: 110,
    maxWidth: 110,
    filterable: false,
    sortable: false,
    Cell: ({ original }: any) => {
      return (
        <Tooltip
          maxWidth="none"
          theme="binary-no-padding"
          content={
            <MenuList>
              {isAdminOrUser() ? (
                <>
                  <MenuButton
                    onClick={() => {
                      setSelectedTag(original);
                      setEditModalOpen(true);
                    }}
                  >
                    Edit Tag
                  </MenuButton>
                  <DangerMenuButton
                    onClick={() => {
                      setSelectedTag(original);
                      setDeleteModalOpen(true);
                    }}
                  >
                    Delete Tag
                  </DangerMenuButton>
                </>
              ) : (
                <>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <MenuButton disabled={true}>Edit Tag</MenuButton>
                    </div>
                  </Tooltip>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <MenuButton disabled={true}>Delete Tag</MenuButton>
                    </div>
                  </Tooltip>
                </>
              )}
            </MenuList>
          }
          interactive={true}
          touch={true}
          appendTo={document.body}
          trigger="click"
          placement="bottom-start"
        >
          <MoreIconContainer>
            <MoreIconSize>
              <MoreIcon fill={color.font[2]} />
            </MoreIconSize>
          </MoreIconContainer>
        </Tooltip>
      );
    },
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  columns.push({
    id: "name",
    Header: "Name",
    accessor: "name",
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["name"],
      }),
    filterAll: true,
  });

  columns.push({
    id: "description",
    Header: "Description",
    accessor: "description",
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["description"],
      }),
    filterAll: true,
  });

  columns.push({
    id: "contentCount",
    Header: "Content Count",
    accessor: "contentCount",
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["contentCount"],
      }),
    filterAll: true,
    Cell: (props: any) => (
      <div
        style={{ cursor: "pointer", textDecoration: "underline" }}
        onClick={() => {
          setSelectedTag(props.original);
          setContentListModalOpen(true);
        }}
      >
        {props.value}
      </div>
    ),
  });

  columns.push({
    id: "colour",
    minWidth: 160,
    maxWidth: 160,
    Header: "Colour",
    accessor: "colour",
    Cell: (props: any) => <ColoredDot color={props.value} />,
  });

  const defaultSorted = [
    {
      id: "name",
      desc: true,
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <LoadingContainer loading={tagsLoading} err={tagsErr}>
        <TableHeaderButtons>
          <div style={{ display: "flex" }}>
            {isAdminOrUser() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setSelectedTag(null);
                      setEditModalOpen(true);
                    }}
                  >
                    Create
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <PrimaryIconBtn
                      onClick={() => {
                        setSelectedTag(null);
                        setEditModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryBtn disabled={true}>Create</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryIconBtn>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
          <CsvButtonsComponent data={tags} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName="Contents Tag List.csv" />
        </TableHeaderButtons>
        <Table
          loading={tagsLoading}
          filterable={true}
          style={{ clear: "both" }}
          data={tags}
          columns={columns}
          defaultSorted={defaultSorted}
          ref={tableRef}
          defaultPageSize={15}
        />
      </LoadingContainer>
      {editModalOpen && <EditContentTagModel tag={selectedTag} fetch={fetchTags} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
      {deleteModalOpen && (
        <DeleteModal
          title="Delete Contents Tag"
          body={
            <>
              <span>Are you sure you want to delete this contents tag?</span>
              <br />
              <span>This will remove the tag from any contents it has been assigned</span>
            </>
          }
          successMsg="Contents Tag Deleted"
          onDelete={() => setTags((prev: any) => prev.filter((row: any) => row.name !== selectedTag.name))}
          onClose={() => setSelectedTag(null)}
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          deleteService={deleteContentTag}
          serviceParams={[selectedTag.id]}
        />
      )}
      {contentListModalOpen && (
        <ContentListModal
          filters={`organisationId eq ${getAccount().organisationId} and contentTags/any(t: t/id eq ${selectedTag.id})`}
          columns={["identifier", "name", "contentTags"]}
          modalOpen={contentListModalOpen}
          setModalOpen={setContentListModalOpen}
        />
      )}
    </div>
  );
};

export default ContentTagsTable;
