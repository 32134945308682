import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { postEditUser } from "../../services/editUser";

const RemoveUserModal: FC<any> = ({ user, setData, modalOpen, setModalOpen }) => {
  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleRemove = () => {
    setSubmitting(true);

    const body = {
      userId: user.userId,
      role: "None",
    };

    setSubmitting(true);
    postEditUser(source, body)
      .then(() => {
        // On success, remove the user from the date
        setData((prev: any) => prev.filter((row: any) => row.userId !== user.userId));
        setSubmittedMsg(`${user.fullName} was successfully removed from your organisation`);
        setSubmitting(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleRemove()}
      onClose={() => {
        if (!submitting) {
          setSubmittingErr("");
          setModalOpen(false);
        }
      }}
      title="Remove User"
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Remove User"
      submitBtn="danger"
      body={
        <LoadingContainer loading={submitting}>
          <span>{`Are you sure you want to remove ${user.fullName} from your organisation?`}</span>
        </LoadingContainer>
      }
    />
  );
};

export default RemoveUserModal;
