import axios, { CancelTokenSource } from "axios";
import QrScanner from "qr-scanner";
import { FC, useCallback, useEffect, useState } from "react";
import { fetchAutoComplete } from "../../services/autoComplete";
import { createPickupRequest, editPickupRequest } from "../../services/pickupRequests";
import { isBinaryLoop } from "../../util/checkDomain";
import errToStr from "../../util/errToStr";
import { exists, maxLength } from "../../util/formValidations";
import { QrScanButton } from "../ActivateTrackerScreen/styles";
import { DangerAlert } from "../Alerts";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import { FormError, FormTextareaInput } from "../FormComponents";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { AsyncCreatableSelect, AsyncSelect } from "../Select";
import Tooltip from "../Tooltip";
import TrackerInfoPanel from "../TrackerInfoPanel";

const defaultRequest = {
  id: null,
  tracker: null,
  pickupRequestType: null,
  notes: "",
};

const initRequest = (request?: any, trackerId?: any) => {
  if (request != null) {
    return {
      ...defaultRequest,
      ...request,
      tracker: request.trackerId
        ? {
            value: request.trackerId,
            label: request.trackerId,
          }
        : null,
      pickupRequestType: request.pickupRequestTypeId
        ? {
            value: request.pickupRequestTypeId,
            label: request.pickupRequestTypeName,
            colour: request.pickupRequestTypeColour,
          }
        : null,
    };
  } else if (trackerId != null) {
    return {
      ...defaultRequest,
      tracker: {
        value: trackerId,
        label: trackerId,
      },
    };
  } else {
    return defaultRequest;
  }
};

const EditPickupRequestModal: FC<any> = ({ request, trackerId, onSuccess, modalOpen, setModalOpen }) => {
  const videoRefCallback = useCallback((node: any) => {
    if (node) {
      setVideoRef(node);
    }
  }, []);

  const [videoRef, setVideoRef] = useState<any>(undefined);

  const [formData, setFormData] = useState<any>(initRequest(request, trackerId));
  const [formErrors, setFormErrors] = useState<any>({});

  const [codeReader, setCodeReader] = useState<any>(undefined);
  const [scanning, setScanning] = useState<boolean>(false);
  const [scan, setScan] = useState<any>(undefined);
  const [scanError, setScanError] = useState<string>("");
  const [scanLoading, setScanLoading] = useState<boolean>(false);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Initialise codeReader and stop/destroy codeReader and stop camera access on clean up
  useEffect(() => {
    let codeReader: any = undefined;
    if (videoRef) {
      codeReader = new QrScanner(videoRef, (result) => {
        if (result) {
          let code = result;
          if (code.includes("10b.in/")) {
            code = code.split("10b.in/")[1];
          }
          setScan(code);
        }
      });
      codeReader.setInversionMode("both");
      setCodeReader(codeReader);
    }

    return () => {
      if (codeReader) {
        codeReader.stop();
        codeReader.destroy();
      }
      if (videoRef && videoRef.srcObject) {
        videoRef.srcObject.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
    };
  }, [videoRef]);

  // Stop code reader when tracker info is loading or the tracker info modal is open
  useEffect(() => {
    if (codeReader) {
      if (scanning) {
        codeReader.start();
      } else {
        codeReader.stop();
        if (videoRef && videoRef.srcObject) {
          videoRef.srcObject.getTracks().forEach((track: any) => {
            track.stop();
          });
        }
      }
    }
  }, [codeReader, scanning, videoRef]);

  // On QR scan, fetch all trackers from autoComplete to find matching binaryId and set it in the form or show error
  useEffect(() => {
    if (scan) {
      setScanLoading(true);
      fetchAutoComplete("trackers", "")
        .then((response) => {
          const test = response.filter((tracker: any) => tracker.value == scan);
          if (test.length > 0) {
            setFormData((prev: any) => ({ ...prev, tracker: test[0] }));
            setScan(undefined);
            setScanning(false);
            setScanError("");
          } else {
            setScanError("No tracker found with ID: " + scan);
          }
          setScanLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setScanError(errToStr(err));
            setScanLoading(false);
          }
        });
    }
  }, [scan]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "tracker":
          if (formData.id == null) currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        case "notes":
          currValid = maxLength(name, value, 500, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const formattedData: any = {
      id: formData.id,
      trackerId: formData.tracker?.value,
      pickupRequestTypeName: formData.pickupRequestType?.label,
      notes: formData.notes.trim(),
    };

    return formattedData;
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);

      if (formData.id) {
        editPickupRequest(source, formData.id, body)
          .then((response) => {
            if (onSuccess) onSuccess(response);
            setSubmittedMsg("Pickup Request Updated");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      } else {
        createPickupRequest(source, body)
          .then((response) => {
            if (onSuccess) onSuccess(response);
            setSubmittedMsg("Pickup Request Created");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      }
    }
  };

  const handleEnableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setSubmittedMsg("");
    setSubmittingErr("");
    setScanning(true);
    setScanError("");
    setScanLoading(false);
  };

  const handleDisableScan = (e: any) => {
    e.persist();
    e.preventDefault();
    setScan(undefined);
    setScanning(false);
    setScanError("");
    setScanLoading(false);
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  // Auto-populates select input on search.
  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
  };

  return (
    <>
      <SubmitModal
        isOpen={modalOpen}
        onSubmit={() => handleSubmit()}
        onClose={handleClose}
        size={!submittedMsg && !submittingErr ? "lg" : "sm"}
        title={formData.id ? "Edit Pickup Request" : "Create Pickup Request"}
        success={submittedMsg}
        error={submittingErr}
        submitBtnText={formData.id ? "Update" : "Create"}
        body={
          <LoadingContainer loading={submitting}>
            <div
              style={{
                display: scanning ? "flex" : "none",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoadingContainer loading={scanLoading}>
                <video
                  id="video"
                  ref={videoRefCallback}
                  style={{
                    display: "inline-block",
                    border: "1px solid gray",
                    borderRadius: "3px",
                    maxHeight: "50vh",
                    maxWidth: "100%",
                  }}
                />
              </LoadingContainer>
            </div>
            {scanError && (
              <DangerAlert
                style={{
                  margin: "20px 0 0 0",
                  float: "left",
                  width: "100%",
                }}
              >
                {scanError}
              </DangerAlert>
            )}
            <div
              style={{
                display: scanning ? "none" : "block",
              }}
            >
              <form noValidate onSubmit={(e) => e.preventDefault()} style={{ display: "flex", flexWrap: "wrap", columnGap: "10px" }}>
                <ModalFormContainer>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>
                      <label style={{ float: "left" }}>Tracker</label>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "100%" }}>
                        <AsyncSelect
                          name="tracker"
                          defaultOptions={true}
                          isClearable={true}
                          isError={formErrors.tracker}
                          value={formData.tracker}
                          loadOptions={(inputValue: any, callback: any) => loadOptions("trackers", inputValue, callback)}
                          onChange={handleSelectChange}
                          placeholder="Select..."
                          isDisabled={formData.id != null || trackerId != null}
                        />
                      </div>
                      <div style={{ width: "100%", maxWidth: "34px", marginLeft: "6px" }}>
                        <Tooltip content="Scan QR Code" trigger="mouseenter">
                          <OutlineBtn
                            disabled={formData.id != null || trackerId != null}
                            onClick={handleEnableScan}
                            style={{ height: "34px", minWidth: "unset" }}
                            padding="0"
                            width="34px"
                          >
                            <div
                              style={{
                                width: "34px",
                                height: "34px",
                                lineHeight: "0",
                                fill: "none",
                              }}
                            >
                              <QrScanButton disabled={formData.id != null || trackerId != null} />
                            </div>
                          </OutlineBtn>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                  <FormError error={formErrors.tracker}>{formErrors.tracker}</FormError>
                  <TrackerInfoPanel trackerId={formData.tracker?.value} />
                </ModalFormContainer>
                <ModalFormContainer halfWidth={true}>
                  <label>{isBinaryLoop() ? "Contents Type" : "Pickup Request Type"}</label>
                  <AsyncCreatableSelect
                    name="pickupRequestType"
                    defaultOptions={true}
                    isClearable={true}
                    isError={formErrors.pickupRequestType}
                    value={formData.pickupRequestType}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("pickuprequesttypes", inputValue, callback)}
                    onChange={handleSelectChange}
                    placeholder="Select..."
                  />
                  <FormError error={formErrors.pickupRequestType}>{formErrors.pickupRequestType}</FormError>
                </ModalFormContainer>
                <ModalFormContainer>
                  <label>Notes</label>
                  <FormTextareaInput name="notes" rows={6} maxLength={4096} value={formData.notes} error={formErrors.notes} onChange={handleChange} />
                  <FormError error={formErrors.notes}>{formErrors.notes}</FormError>
                </ModalFormContainer>
              </form>
            </div>
          </LoadingContainer>
        }
        footer={
          scanning ? (
            <OutlineBtn onClick={handleDisableScan} width="100%">
              Back
            </OutlineBtn>
          ) : submittedMsg ? (
            <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
          ) : submittingErr ? (
            <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
          ) : (
            <>
              <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
              <PrimaryBtn onClick={handleSubmit}>{formData.id ? "Update" : "Create"}</PrimaryBtn>
            </>
          )
        }
      />
    </>
  );
};

export default EditPickupRequestModal;
