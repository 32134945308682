import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { fetchSensorCsv } from "../../services/sensorCsv";
import downloadFile from "../../util/downloadFile";
import errToStr from "../../util/errToStr";
import { printFixedLength, printFixedTemp, printLengthUnit, printTempUnit } from "../../util/formatUnits";
import { kegOrTracker } from "../../util/kegOrTracker";
import DateSelect from "../DateSelect";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";

const formatDataToCsv = ({ logs, series, meta }: any, short_date: any, time: any) => {
  if (logs.length > 0) {
    const keys = [];
    const headings: any = [];

    if (series.date === true) keys.push("date");
    if (series.moved === true) keys.push("moved");
    if (series.place === true) keys.push("place");
    if (series.placeGroup === true) keys.push("placeGroup");
    if (series.temperature === true) keys.push("temperature");
    if (series.voltage === true) keys.push("voltage");
    if (series.loadVoltage === true) keys.push("loadVoltage");
    if (series.supplyVoltage === true) keys.push("supplyVoltage");
    if (series.orientation === true) keys.push("orientation");
    if (series.freshness === true) keys.push("freshness");
    if (series.light === true) keys.push("light");
    if (series.lightInterrupt === true) keys.push("lightInterrupt");
    if (series.engineRunning === true) keys.push("engineRunning");
    if (series.network === true) keys.push("network");
    if (series.input1 === true) keys.push("input1");
    if (series.input2 === true) keys.push("input2");
    if (series.input3 === true) keys.push("input3");
    if (series.input4 === true) keys.push("input4");
    if (series.input5 === true) keys.push("input5");
    if (series.input6 === true) keys.push("input6");
    if (series.input7 === true) keys.push("input7");
    if (series.timeOfFlight === true) keys.push("timeOfFlight");
    if (series.coupled === true) keys.push("coupled");
    if (series.address === true) keys.push("address");
    if (series.city === true) keys.push("city");
    if (series.state === true) keys.push("state");
    if (series.postcode === true) keys.push("postcode");
    if (series.country === true) keys.push("country");
    if (series.latitude === true) keys.push("latitude");
    if (series.longitude === true) keys.push("longitude");
    if (series.locationAccuracy === true) keys.push("locationAccuracy");
    if (series.locationType === true) keys.push("locationType");

    // check series in keys array and push heading for those that exist
    if (keys.includes("date")) {
      headings.push("Date");
      headings.push("Local Date");
      headings.push("Local Time");
    }
    if (keys.includes("moved")) headings.push("Moved");
    if (keys.includes("place")) headings.push("Place");
    if (keys.includes("placeGroup")) headings.push("Place Type");
    if (keys.includes("temperature")) headings.push(`Temperature (${printTempUnit()})`);
    if (keys.includes("voltage")) headings.push("Voltage (V)");
    if (keys.includes("loadVoltage")) headings.push("Load Voltage (V)");
    if (keys.includes("supplyVoltage")) headings.push("Supply Voltage (V)");
    if (keys.includes("orientation")) headings.push("Orientation");
    if (keys.includes("freshness")) headings.push("Freshness (%)");
    if (keys.includes("light")) headings.push("Light");
    if (keys.includes("lightInterrupt")) headings.push("Light Interrupt");
    if (keys.includes("engineRunning")) headings.push("Engine Running");
    if (keys.includes("network")) headings.push("Network");
    if (keys.includes("input1")) headings.push(`${meta.input1Label ? meta.input1Label : "Input 1"}${meta.input1Unit ? ` (${meta.input1Unit})` : ""}`);
    if (keys.includes("input2")) headings.push(`${meta.input2Label ? meta.input2Label : "Input 2"}${meta.input2Unit ? ` (${meta.input2Unit})` : ""}`);
    if (keys.includes("input3")) headings.push(`${meta.input3Label ? meta.input3Label : "Input 3"}${meta.input3Unit ? ` (${meta.input3Unit})` : ""}`);
    if (keys.includes("input4")) headings.push(`${meta.input4Label ? meta.input4Label : "Input 4"}${meta.input4Unit ? ` (${meta.input4Unit})` : ""}`);
    if (keys.includes("input5")) headings.push(`${meta.input5Label ? meta.input5Label : "Input 5"}${meta.input5Unit ? ` (${meta.input5Unit})` : ""}`);
    if (keys.includes("input6")) headings.push(`${meta.input6Label ? meta.input6Label : "Input 6"}${meta.input6Unit ? ` (${meta.input6Unit})` : ""}`);
    if (keys.includes("input7")) headings.push(`${meta.input7Label ? meta.input7Label : "Input 7"}${meta.input7Unit ? ` (${meta.input7Unit})` : ""}`);
    if (keys.includes("timeOfFlight")) headings.push("Time Of Flight (mm)");
    if (keys.includes("coupled")) headings.push("on Tap");
    if (keys.includes("address")) headings.push("Address");
    if (keys.includes("city")) headings.push("City");
    if (keys.includes("state")) headings.push("State");
    if (keys.includes("postcode")) headings.push("Postcode");
    if (keys.includes("country")) headings.push("Country");
    if (keys.includes("latitude")) headings.push("Latitude");
    if (keys.includes("longitude")) headings.push("Longitude");
    if (keys.includes("locationAccuracy")) headings.push(`Location Accuracy (${printLengthUnit()})`);
    if (keys.includes("locationType")) headings.push("Location Type");

    const rows = [headings];

    for (let i = 0; i < logs.length; i++) {
      const row = [];

      // for each series check if exists in row and format accordingly
      for (let j = 0; j < keys.length; j++) {
        const key = keys[j];

        if (logs[i][key] !== undefined) {
          if (key === "date") {
            row.push(moment.unix(logs[i][key]).format());
            row.push(moment.unix(logs[i][key]).format(short_date));
            row.push(moment.unix(logs[i][key]).format(time));
          } else if (key === "moved") row.push(logs[i][key] ? "True" : "False");
          else if (key === "temperature") row.push(printFixedTemp(logs[i][key]));
          else if (key === "orientation") row.push(logs[i][key] === "U" ? "Up" : "Down");
          else if (key === "freshness") row.push(`${+logs[i][key].toFixed(1)}`);
          else if (key === "lightInterrupt") row.push(logs[i][key] ? "True" : "False");
          else if (key === "engineRunning") row.push(logs[i][key] ? "True" : "False");
          else if (key === "coupled") row.push(logs[i][key] ? "True" : "False");
          else if (key === "locationAccuracy") row.push(printFixedLength(logs[i][key]));
          else row.push(logs[i][key]);
        } else {
          row.push("");
        }
      }
      rows.push(row);
    }

    return rows;
  } else {
    return [];
  }
};

const TrackerCsvDownloadModal: FC<any> = ({ modalOpen, setModalOpen, id, tracker }) => {
  const { short_date, short_datetime, time } = useContext(ThemeContext);

  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [filterDates, setFilterDates] = useState<any>({
    start: moment().subtract(7, "days"),
    end: moment(),
    dateLabel: "Last 7 days",
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const downloadCsv = () => {
    // If end date is undefined set to current date
    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined ? filterDates.end.unix() : moment().unix(),
    };

    const fileName = `${kegOrTracker("Keg", "Tracker")} ${id} - (${moment.unix(dates.start).format(short_datetime)} - ${moment
      .unix(dates.end)
      .format(short_datetime)}).csv`;

    setDataLoading(true);
    setDataErr("");

    fetchSensorCsv(source, id, dates)
      .then((response) => {
        downloadFile(
          stringify(formatDataToCsv(response, short_date, time), {
            quoted: true,
            quoted_string: true,
          }),
          "text/csv;charset=utf-8",
          fileName
        );
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => downloadCsv()}
      onClose={() => {
        setModalOpen(false);
      }}
      submitBtnText="Download"
      cancelBtnText="Close"
      title="Download CSV"
      error={dataErr}
      body={
        <LoadingContainer loading={dataLoading}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer>
              <label>Date Range</label>
              <div>
                <DateSelect
                  filterDates={filterDates}
                  setFilterDates={setFilterDates}
                  minDate={moment.unix(tracker.dateActivated)}
                  maxDate={moment.unix(tracker.latestSampleDate)}
                />
              </div>
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default TrackerCsvDownloadModal;
