import React, { FC, useState, useEffect, useContext, useRef } from "react";
import LoadingContainer from "../LoadingContainer";
import { PrimaryBtn } from "../Buttons";
import { FormContainer, FormInputContainer, FormError, FormInput } from "../FormComponents";
import ScreenHeading from "../ScreenHeading";
import { SuccessAlert, DangerAlert } from "../Alerts";
import { postEditPassword } from "../../services/editPassword";
import { fetchEditAccount, postEditAccount } from "../../services/editAccount";
import { getAccount, getCountry, saveAccount, saveCountry } from "../../services/localStorage";
import { exists, validEmail, passwordsMatch, validPassword, validPhoneNumber } from "../../util/formValidations";
import PhoneNumberInput from "../PhoneNumberInput";
import { HorizontalLine } from "../HorizontalLine";
import { AccountContainer, CameraIconContainer, UpdateImageButton, UpdateImageButtonContainer } from "./styles";
import StateContext from "../StateContext";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import RadioButton from "../RadioButton";
import Checkbox from "../Checkbox";
import Avatar from "react-avatar";
import CameraIcon from "../../svgs/CameraIcon";
import Tooltip from "../Tooltip";
import { MenuList, MenuButton, DangerMenuButton } from "../Tooltip/styles";
import { hideAll } from "tippy.js";
import UploadPhotoModal from "../UploadPhotoModal";
import DeleteModal from "../DeleteModal";
import { deletePhoto } from "../../services/photo";
import PhotoModal from "../PhotoModal";
import moment from "moment";
import { fetchCountry } from "../../services/fetchCountry";

const initAccount = (accountInfo: any) => {
  const initialAccount = {
    userPhotoUrl: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    organisationName: "",
    units: "",
    twoFactor: false,
  };

  return { ...initialAccount, ...accountInfo };
};

const initialPasswords = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

const AccountScreen: FC<any> = () => {
  const { forceUpdate } = useContext(StateContext);

  const photoMenuRef = useRef<any>(null);
  const inputRef = useRef<any>(null);

  const [accountInfo] = useState<any>(getAccount());

  const [inputPhoto, setInputPhoto] = useState<any>(undefined);
  const [photoModalOpen, setPhotoModalOpen] = useState<boolean>(false);

  const [uploadPhotoModalOpen, setUploadPhotoModalOpen] = useState<boolean>(false);
  const [deletePhotoModalOpen, setDeletePhotoModalOpen] = useState<boolean>(false);

  const [accountErrors, setAccountErrors] = useState<any>({});
  const [account, setAccount] = useState<any>(initAccount(accountInfo));
  const [accountErr, setAccountErr] = useState<string>("");
  const [accountLoading, setAccountLoading] = useState<boolean>(false);

  const [passwordsErrors, setPasswordsErrors] = useState<any>({});
  const [passwords, setPasswords] = useState<any>(initialPasswords);

  const [accountSubmitted, setAccountSubmitted] = useState<boolean>(false);
  const [accountSubmittingErr, setAccountSubmittingErr] = useState<string>("");

  const [passSubmitted, setPassSubmitted] = useState<boolean>(false);
  const [passSubmittingErr, setPassSubmittingErr] = useState<string>("");

  const [submitting, setSubmitting] = useState<boolean>(false);

  const [country, setCountry] = useState<string>("AU");

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    fetchAccount();

    return () => {
      source.cancel();
    };
  }, []);

  useEffect(() => {
    const currCountry = getCountry();

    // If no country stored or older than 24 hours, fetch country code again
    if (currCountry && moment().diff(moment(currCountry.date), "hours") < 24) {
      setCountry(currCountry.code);
    } else {
      fetchCountry(source)
        .then((response) => {
          if (response) {
            setCountry(response);
            saveCountry({ date: moment(), code: response });
          } else {
            setCountry("AU");
            saveCountry({ date: moment(), code: "AU" });
          }
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            saveCountry({ date: moment(), code: "AU" });
            setCountry("AU");
          }
        });
    }
  }, [source]);

  const fetchAccount = () => {
    setAccountLoading(true);
    fetchEditAccount(source)
      .then((response) => {
        saveAccount(response);
        setAccount({
          ...response,
          units: response.imperial ? "imperial" : "metric",
        });
        setAccountErr("");
        setAccountLoading(false);
        const accountInfoUpdatedEvent = new Event("accountinfoupdated");
        document.dispatchEvent(accountInfoUpdatedEvent);
        forceUpdate();
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setAccountErr(errToStr(err));
          setAccountLoading(false);
        }
      });
  };

  const validateAccount = () => {
    const names = Object.keys(account);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = account[names[i]];

      switch (name) {
        case "fullName":
          currValid = exists(name, value, setAccountErrors);
          break;

        case "email":
          currValid = exists(name, value, setAccountErrors) && validEmail(name, value, setAccountErrors);
          break;

        case "phoneNumber":
          currValid = value ? validPhoneNumber(name, value, setAccountErrors) : true;
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const validatePassword = () => {
    const names = Object.keys(passwords);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const oldPassword = passwords.oldPassword || "";
      const newPassword = passwords.newPassword || "";
      const confirmPassword = passwords.confirmPassword || "";

      switch (name) {
        case "oldPassword":
          currValid = validPassword(name, oldPassword, setPasswordsErrors);
          break;

        case "newPassword":
          currValid =
            validPassword(name, newPassword, setPasswordsErrors) && passwordsMatch("confirmPassword", newPassword, confirmPassword, setPasswordsErrors);
          break;

        case "confirmPassword":
          currValid = passwordsMatch(name, newPassword, confirmPassword, setPasswordsErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const handleAccountSubmit = (e: any) => {
    e.persist();
    e.preventDefault();

    const valid = validateAccount();
    const body = {
      ...account,
      imperial: account.units === "imperial",
    };

    if (valid) {
      setSubmitting(true);
      postEditAccount(source, body)
        .then((response) => {
          saveAccount(response);
          setAccountSubmitted(true);
          setAccountSubmittingErr("");
          setSubmitting(false);
          const accountInfoUpdatedEvent = new Event("accountinfoupdated");
          document.dispatchEvent(accountInfoUpdatedEvent);
          forceUpdate();
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setAccountSubmitted(false);
            setAccountSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handlePasswordSubmit = (e: any) => {
    e.persist();
    e.preventDefault();

    const valid = validatePassword();

    if (valid) {
      setSubmitting(true);
      postEditPassword(source, passwords)
        .then(() => {
          setPassSubmitted(true);
          setPassSubmittingErr("");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setPassSubmitted(false);
            setPassSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleAccountChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setAccount((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setAccountErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handlePhoneChange = (value?: string) => {
    setAccount((prev: any) => ({ ...prev, phoneNumber: value }));
    setAccountErrors((prev: any) => ({ ...prev, phoneNumber: undefined }));
  };

  const handlePasswordChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setPasswords((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setPasswordsErrors((prev: any) => ({
      ...prev,
      [e.target.name]: undefined,
    }));
  };

  const handleRadioButtonChange = (e: any) => {
    e.persist();
    setAccount((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCheckboxChange = (e: any) => {
    e.persist();
    setAccount((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleFileChange = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setInputPhoto(e.target.files[0]);
    if (e.target.files[0]) setUploadPhotoModalOpen(true);
    e.target.value = "";
  };

  return (
    <FormContainer>
      <LoadingContainer loading={accountLoading || submitting} err={accountErr}>
        <AccountContainer>
          <ScreenHeading>Account Settings</ScreenHeading>
          <form style={{ display: "flex", flexDirection: "column" }} onSubmit={handleAccountSubmit} noValidate>
            <FormInputContainer>
              <label>Profile Photo</label>
              <div style={{ width: "125px", marginBottom: "16px", position: "relative" }}>
                {account.userPhotoUrl ? (
                  <div style={{ cursor: "pointer" }} onClick={() => setPhotoModalOpen(true)}>
                    <Avatar style={{ display: "block", margin: "12px 0" }} name={account.fullName} src={account.userPhotoUrl} size="125px" round={true} />
                  </div>
                ) : (
                  <Avatar style={{ display: "block", margin: "12px 0" }} name={account.fullName} size="125px" round={true} />
                )}
                <Tooltip
                  onCreate={(instance) => (photoMenuRef.current = instance)}
                  interactive={true}
                  touch={true}
                  appendTo={document.body}
                  trigger="click"
                  placement="bottom-start"
                  maxWidth="none"
                  theme="binary-no-padding"
                  content={
                    <MenuList>
                      <MenuButton
                        onClick={() => {
                          inputRef.current.click();
                          hideAll();
                        }}
                      >
                        {account.userPhotoUrl ? "Change" : "Upload"}
                      </MenuButton>
                      {account.userPhotoUrl && (
                        <DangerMenuButton
                          onClick={() => {
                            setDeletePhotoModalOpen(true);
                            hideAll();
                          }}
                        >
                          Delete
                        </DangerMenuButton>
                      )}
                    </MenuList>
                  }
                >
                  <UpdateImageButton>
                    <UpdateImageButtonContainer>
                      <CameraIconContainer>
                        <CameraIcon />
                      </CameraIconContainer>
                    </UpdateImageButtonContainer>
                  </UpdateImageButton>
                </Tooltip>
              </div>
            </FormInputContainer>
            <FormInputContainer>
              <label>Full Name</label>
              <FormInput
                type="text"
                name="fullName"
                value={account.fullName}
                error={accountErrors.fullName}
                onChange={handleAccountChange}
                autoComplete="name"
              />
              <FormError error={accountErrors.fullName}>{accountErrors.fullName}</FormError>
            </FormInputContainer>
            <FormInputContainer>
              <label>Email</label>
              <FormInput type="email" name="email" value={account.email} error={accountErrors.email} onChange={handleAccountChange} autoComplete="email" />
              <FormError error={accountErrors.email}>{accountErrors.email}</FormError>
            </FormInputContainer>
            <FormInputContainer>
              <label>Phone Number</label>
              <PhoneNumberInput
                international={true}
                defaultCountry={country}
                value={account.phoneNumber}
                error={accountErrors.phoneNumber}
                onChange={handlePhoneChange}
                autoComplete="tel"
              />
              <FormError error={accountErrors.phoneNumber}>{accountErrors.phoneNumber}</FormError>
            </FormInputContainer>
            <FormInputContainer style={{ marginBottom: "14px" }}>
              <label>Organisation</label>
              <FormInput type="text" name="organisation" value={account.organisationName} disabled={true} />
            </FormInputContainer>
            <FormInputContainer style={{ marginBottom: "14px" }}>
              <label>Units</label>
              <div style={{ padding: "5px 0" }}>
                <RadioButton
                  style={{ display: "block" }}
                  name="units"
                  label="Metric"
                  value="metric"
                  checked={account.units === "metric"}
                  onChange={handleRadioButtonChange}
                />
              </div>
              <div style={{ padding: "5px 0" }}>
                <RadioButton
                  style={{ display: "block" }}
                  name="units"
                  label="Imperial"
                  value="imperial"
                  checked={account.units === "imperial"}
                  onChange={handleRadioButtonChange}
                />
              </div>
            </FormInputContainer>
            <FormInputContainer style={{ marginBottom: "14px" }}>
              <label>Two Factor Authentication</label>
              <div style={{ padding: "5px 0" }}>
                <Checkbox style={{ display: "inline-block" }} name="twoFactor" label="Enabled" checked={account.twoFactor} onChange={handleCheckboxChange} />
              </div>
            </FormInputContainer>
            <div style={{ float: "left", width: "100%", margin: "14px 0px" }}>
              <PrimaryBtn type="submit">Update Account</PrimaryBtn>
            </div>
            {accountSubmitted && <SuccessAlert style={{ marginBottom: "16px", float: "left", width: "100%" }}>Account updated</SuccessAlert>}
            {accountSubmittingErr && <DangerAlert style={{ marginBottom: "16px", float: "left", width: "100%" }}>{accountSubmittingErr}</DangerAlert>}
          </form>

          <HorizontalLine />

          <form onSubmit={handlePasswordSubmit} noValidate>
            <ScreenHeading style={{ paddingTop: 24 }}>Change Password</ScreenHeading>
            <FormInputContainer>
              <label>Old password</label>
              <FormInput
                type="password"
                name="oldPassword"
                value={passwords.oldPassword}
                error={passwordsErrors.oldPassword}
                onChange={handlePasswordChange}
                required={true}
                autoComplete="current-password"
              />
              <FormError error={passwordsErrors.oldPassword}>{passwordsErrors.oldPassword}</FormError>
            </FormInputContainer>
            <FormInputContainer>
              <label>Password</label>
              <FormInput
                type="password"
                name="newPassword"
                value={passwords.newPassword}
                error={passwordsErrors.newPassword}
                onChange={handlePasswordChange}
                autoComplete="new-password"
              />
              <FormError error={passwordsErrors.newPassword}>{passwordsErrors.newPassword}</FormError>
            </FormInputContainer>
            <FormInputContainer>
              <label>Confirm Password</label>
              <FormInput
                type="password"
                name="confirmPassword"
                value={passwords.confirmPassword}
                error={passwordsErrors.confirmPassword}
                onChange={handlePasswordChange}
                autoComplete="new-password"
              />
              <FormError error={passwordsErrors.confirmPassword}>{passwordsErrors.confirmPassword}</FormError>
            </FormInputContainer>
            <div style={{ float: "left", width: "100%", marginTop: "14px" }}>
              <PrimaryBtn type="submit">Change Password</PrimaryBtn>
            </div>
            {passSubmitted && <SuccessAlert style={{ margin: "16px 0 0 0", float: "left", width: "100%" }}>Password updated</SuccessAlert>}
            {passSubmittingErr && <DangerAlert style={{ margin: "16px 0 0 0", float: "left", width: "100%" }}>{passSubmittingErr}</DangerAlert>}
          </form>
        </AccountContainer>
      </LoadingContainer>
      <input type="file" ref={inputRef} onChange={handleFileChange} style={{ display: "none" }} accept="image/png, image/jpeg" />
      {uploadPhotoModalOpen && (
        <UploadPhotoModal
          modalOpen={uploadPhotoModalOpen}
          setModalOpen={setUploadPhotoModalOpen}
          photo={inputPhoto}
          trackerId={undefined}
          organisationId={undefined}
          placeId={undefined}
          userId={accountInfo.id}
          onSuccess={fetchAccount}
        />
      )}
      {deletePhotoModalOpen && (
        <DeleteModal
          title="Delete Profile Photo"
          body={<span>Are you sure you want to delete your profile photo?</span>}
          successMsg="Profile Photo Deleted"
          modalOpen={deletePhotoModalOpen}
          setModalOpen={setDeletePhotoModalOpen}
          deleteService={deletePhoto}
          serviceParams={[undefined, undefined, undefined, accountInfo.id]}
          onDelete={fetchAccount}
        />
      )}
      {photoModalOpen && <PhotoModal modalOpen={photoModalOpen} setModalOpen={setPhotoModalOpen} name="Profile Photo" src={account.userPhotoUrl} />}
    </FormContainer>
  );
};

export default AccountScreen;
