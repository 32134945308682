import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import queryString from "query-string";
import Drawer from "rc-drawer";
import "rc-drawer/assets/index.css";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { TbDownload } from "react-icons/tb";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { fetchReport10 } from "../../../services/report10";
import Cross from "../../../svgs/Cross";
import downloadFile from "../../../util/downloadFile";
import errToStr from "../../../util/errToStr";
import getParameterByName from "../../../util/getParamByName";
import { googleMapsLink } from "../../../util/googleMapsLink";
import { INITIAL_VIEW_STATE } from "../../../util/mapUtils";
import { PrimaryBtn } from "../../Buttons";
import Checkbox from "../../Checkbox";
import { DrawBody, DrawCloseButton, DrawContent, DrawFooter, DrawHeader, FilterInputContainer } from "../../Drawer/styles";
import { FormInput } from "../../FormComponents";
import LoadingContainer from "../../LoadingContainer";
import MapFilter from "../../MapFilter";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import { Subtitle, Title } from "../../PageTitle/styles";
import { ClearPanel, Panel, StatPanel } from "../../Panel";
import { Select } from "../../Select";
import SensorListModal from "../../SensorListModal";
import Table from "../../Table";
import { StatBlock, StatLabel, StatValue } from "../styles";

const pickupIndicatorOptions = [
  { label: "Empty", value: "Empty" },
  { label: "Marked for Pickup", value: "Marked for Pickup" },
  { label: "Upside Down", value: "Upside Down" },
];

const formatDataToCsv = (tableRef: any) => {
  const headers = ["Place Name", "Address", "Postcode", "Number of Kegs"];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [
        row.placeName ? row.placeName : row.location ? row.location : `${row.latitude}, ${row.longitude}`,
        row.address,
        row.postcode,
        row.kegsReady.length,
      ];
    }, []),
  ];
};

const KegsReadyForCollection: FC<any> = () => {
  const { color } = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const tableRef = useRef<any>(null);

  const [data, setData] = useState<any>({ locations: [], totalKegs: [] });
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [sensorList, setSensorList] = useState<any>([]);
  const [sensorListModalOpen, setSensorListModalOpen] = useState<boolean>(false);

  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  // Report filters
  const [mapEnabled, setMapEnabled] = useState<boolean>(false);
  const [place, setPlace] = useState<any>({ latitude: INITIAL_VIEW_STATE.latitude, longitude: INITIAL_VIEW_STATE.longitude, radius: 10000 });
  const [minPostcode, setMinPostcode] = useState<string>("");
  const [maxPostcode, setMaxPostcode] = useState<string>("");
  const [minKegs, setMinKegs] = useState<string>("");
  const [pickupIndicators, setPickupIndicators] = useState<any>([
    { label: "Empty", value: "Empty" },
    { label: "Marked for Pickup", value: "Marked for Pickup" },
  ]);

  const [appliedFilters, setAppliedFilters] = useState<number>(0);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // On location change (e.g. url parameter changes because filters changed), update filters and fetch report
  useEffect(() => {
    let map = undefined;
    let lat = undefined;
    let lng = undefined;
    let radius = undefined;
    let minPost = undefined;
    let maxPost = undefined;
    let kegs = undefined;
    let pickup: any = [];

    const latitudeParam = getParameterByName("lat", location.search) !== null ? getParameterByName("lat", location.search) : null;
    const longitudeParam = getParameterByName("lng", location.search) !== null ? getParameterByName("lng", location.search) : null;
    const radiusParam = getParameterByName("radius", location.search) !== null ? getParameterByName("radius", location.search) : null;
    if (latitudeParam !== null && longitudeParam !== null && radiusParam !== null) {
      map = true;
      lat = parseFloat(latitudeParam);
      lng = parseFloat(longitudeParam);
      radius = parseFloat(radiusParam);

      setMapEnabled(map);
      setPlace({ latitude: lat, longitude: lng, radius: radius });
    } else {
      navigator.geolocation.getCurrentPosition(
        (pos: any) => {
          const { coords } = pos;
          setPlace({
            latitude: coords.latitude,
            longitude: coords.longitude,
            radius: 10000,
          });
        },
        () => {
          setPlace({
            latitude: INITIAL_VIEW_STATE.latitude,
            longitude: INITIAL_VIEW_STATE.longitude,
            radius: 10000,
          });
        }
      );
    }

    const minPostcodeParam = getParameterByName("min-postcode", location.search) !== null ? getParameterByName("min-postcode", location.search) : null;
    if (minPostcodeParam !== null) {
      minPost = minPostcodeParam;
      setMinPostcode(minPost);
    }

    const maxPostcodeParam = getParameterByName("max-postcode", location.search) !== null ? getParameterByName("max-postcode", location.search) : null;
    if (maxPostcodeParam !== null) {
      maxPost = maxPostcodeParam;
      setMaxPostcode(maxPost);
    }

    const minKegsParam = getParameterByName("min-kegs", location.search) !== null ? getParameterByName("min-kegs", location.search) : null;
    if (minKegsParam !== null) {
      kegs = minKegsParam;
      setMinKegs(kegs);
    }

    const pickupIndicatorsParam =
      getParameterByName("pickup-indicators", location.search) !== null ? getParameterByName("pickup-indicators", location.search) : null;
    if (pickupIndicatorsParam !== null) {
      pickup = pickupIndicatorOptions.filter((el: any) => pickupIndicatorsParam.includes(el.value));
      setPickupIndicators(pickup);
    }

    fetchReport(map, lat, lng, radius, minPost, maxPost, kegs, pickup);
  }, [location]);

  const updateQueryParams = () => {
    const parsed = queryString.parse(location.search);
    const newQuery = { ...parsed };

    if (mapEnabled) {
      if (place?.latitude) newQuery["lat"] = place.latitude;
      if (place?.longitude) newQuery["lng"] = place.longitude;
      if (place?.radius) newQuery["radius"] = place.radius;
    } else {
      delete newQuery["lat"];
      delete newQuery["lng"];
      delete newQuery["radius"];
    }

    if (minPostcode !== "") newQuery["min-postcode"] = minPostcode;
    else delete newQuery["min-postcode"];

    if (maxPostcode !== "") newQuery["max-postcode"] = maxPostcode;
    else delete newQuery["max-postcode"];

    if (minKegs !== "") newQuery["min-kegs"] = minKegs;
    else delete newQuery["min-kegs"];

    if (pickupIndicators.length) newQuery["pickup-indicators"] = pickupIndicators.map((indicator: any) => indicator.value).join(",");
    else delete newQuery["pickup-indicators"];

    const stringified = queryString.stringify(newQuery);
    navigate({ ...location, search: stringified });
  };

  const fetchReport = (map?: any, lat?: any, lng?: any, radius?: any, minPost?: any, maxPost?: any, kegs?: any, pickup?: any) => {
    setDataLoading(true);
    setDataErr("");

    const latArg = map || mapEnabled ? (lat !== undefined ? lat : place.latitude) : undefined;
    const lngArg = map || mapEnabled ? (lng !== undefined ? lng : place.longitude) : undefined;
    const radiusArg = map || mapEnabled ? (radius !== undefined ? radius : place.radius) : undefined;
    const minPostArg = minPost !== undefined ? minPost : minPostcode;
    const maxPostArg = maxPost !== undefined ? maxPost : maxPostcode;
    const kegsArg = kegs !== undefined ? kegs : minKegs;
    const pickupArg = pickup?.length > 0 ? pickup : pickupIndicators;

    fetchReport10(source, minPostArg, maxPostArg, kegsArg, pickupArg.map((indicator: any) => indicator.value).join(","), latArg, lngArg, radiusArg)
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData({ locations: [], totalKegs: [] });
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    let filters = 0;

    if (map || mapEnabled) filters++;
    if (minPostArg) filters++;
    if (maxPostArg) filters++;
    if (kegsArg) filters++;
    if (pickupArg.length) filters += pickupArg.length;

    setAppliedFilters(filters);
  };

  const SensorListCell = (props: any) =>
    !props.value ? (
      0
    ) : props.value.length === 0 ? (
      props.value.length
    ) : (
      <div
        style={{ cursor: "pointer", textDecoration: "underline" }}
        onClick={() => {
          setSensorList(props.value);
          setSensorListModalOpen(true);
        }}
      >
        {props.value.length}
      </div>
    );

  const SensorListStat = (sensors: any) =>
    !sensors ? (
      0
    ) : sensors.length === 0 ? (
      sensors.length
    ) : (
      <span
        style={{ cursor: "pointer", textDecoration: "underline" }}
        onClick={() => {
          setSensorList(sensors);
          setSensorListModalOpen(true);
        }}
      >
        {sensors.length}
      </span>
    );

  const columns = [
    {
      id: "placeName",
      Header: "Place Name",
      accessor: "placeName",
      Cell: (props: any) =>
        props.original.placeId ? (
          <Link to={`/places/${props.original.placeId}`}>{props.value}</Link>
        ) : props.original.placeName ? (
          googleMapsLink(props.original.latitude, props.original.longitude, props.original.placeName)
        ) : (
          googleMapsLink(props.original.latitude, props.original.longitude)
        ),
      minWidth: 260,
    },
    {
      id: "address",
      Header: "Address",
      accessor: "address",
      minWidth: 400,
    },
    {
      id: "postcode",
      Header: "Postcode",
      accessor: "postcode",
      minWidth: 80,
    },
    {
      id: "kegsReady",
      Header: "# Kegs",
      accessor: "kegsReady",
      Cell: SensorListCell,
      minWidth: 80,
    },
  ];

  const defaultSorted = [
    {
      id: "kegsReady",
      desc: true,
    },
    {
      id: "postcode",
    },
  ];

  return (
    <>
      <Drawer
        placement="right"
        level={null}
        open={filtersOpen}
        onClose={() => {
          setFiltersOpen(false);
          updateQueryParams();
        }}
        onHandleClick={() => setFiltersOpen(!filtersOpen)}
        handler={false}
      >
        <DrawContent>
          <DrawHeader>
            <div
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                fontSize: "0",
                marginRight: "6px",
              }}
            >
              <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                <HiOutlineAdjustmentsHorizontal />
              </IconContext.Provider>
            </div>
            Filters
            <DrawCloseButton
              onClick={() => {
                setFiltersOpen(false);
              }}
              aria-label={`Close filters`}
            >
              <Cross />
            </DrawCloseButton>
          </DrawHeader>
          <DrawBody>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <Checkbox name="mapEnabled" label="Map" checked={mapEnabled} onChange={(e: any) => setMapEnabled(e.target.checked)} />
              <FilterInputContainer>
                {mapEnabled && (
                  <div style={{ width: "100%", paddingTop: "12px" }}>
                    <MapFilter place={place} setPlace={setPlace} />
                  </div>
                )}
              </FilterInputContainer>
              <label>Postcode Range</label>
              <FilterInputContainer>
                <FormInput
                  style={{
                    textAlign: "center",
                  }}
                  placeholder="2500"
                  value={minPostcode}
                  onChange={(e: any) => {
                    setMinPostcode(e.target.value);
                  }}
                />
                <span style={{ paddingTop: "8px", margin: "0 12px" }}>-</span>
                <FormInput
                  style={{
                    textAlign: "center",
                  }}
                  placeholder="2600"
                  value={maxPostcode}
                  onChange={(e: any) => {
                    setMaxPostcode(e.target.value);
                  }}
                />
              </FilterInputContainer>
              <label>Minimum Kegs</label>
              <FilterInputContainer>
                <FormInput
                  type="number"
                  value={minKegs}
                  onChange={(e: any) => {
                    setMinKegs(e.target.value);
                  }}
                />
              </FilterInputContainer>
              <label>Pickup Indicators</label>
              <FilterInputContainer>
                <Select
                  name="pickupIndicators"
                  options={pickupIndicatorOptions}
                  closeMenuOnSelect={false}
                  isClearable={true}
                  isMulti={true}
                  isSearchable={true}
                  value={pickupIndicators}
                  onChange={(selected: any) => {
                    if (selected) {
                      setPickupIndicators(selected);
                    } else {
                      setPickupIndicators([]);
                    }
                  }}
                  placeholder="Select..."
                />
              </FilterInputContainer>
            </form>
          </DrawBody>
          <DrawFooter>
            <PrimaryBtn
              onClick={() => {
                setFiltersOpen(false);
                updateQueryParams();
              }}
            >
              Apply Filters
            </PrimaryBtn>
          </DrawFooter>
        </DrawContent>
      </Drawer>
      <PageBreadcrumbs prevRoutes={[{ slug: "/reports", title: "Reports" }]} currRoute="Kegs Ready for Collection" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading} err={dataErr}>
            <ClearPanel style={{ textAlign: "center" }}>
              <Title style={{ marginBottom: "6px" }}>Kegs Ready for Collection</Title>
              <Subtitle>Kegs which have been identified as empty and not already at a brewery</Subtitle>
            </ClearPanel>
            <ClearPanel style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <PrimaryBtn style={{ height: "40px", minWidth: "unset", marginRight: "12px" }} padding="0 6px" onClick={() => setFiltersOpen(!filtersOpen)}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        display: "inline-block",
                        width: "20px",
                        height: "20px",
                        fontSize: "0",
                        marginRight: "6px",
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiOutlineAdjustmentsHorizontal />
                      </IconContext.Provider>
                    </div>
                    <span>Filters</span>
                  </div>
                </PrimaryBtn>
                <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                  {appliedFilters} filter{appliedFilters === 1 ? "" : "s"} applied
                </span>
              </div>
              <PrimaryBtn
                style={{ height: "40px", minWidth: "unset", marginRight: "12px" }}
                padding="0 6px"
                onClick={() =>
                  downloadFile(
                    stringify(formatDataToCsv(tableRef), {
                      quoted: true,
                      quoted_string: true,
                    }),
                    "text/csv;charset=utf-8",
                    "Kegs Ready for Collection.csv"
                  )
                }
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "inline-block",
                      width: "20px",
                      height: "20px",
                      fontSize: "0",
                      marginRight: "6px",
                    }}
                  >
                    <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                      <TbDownload />
                    </IconContext.Provider>
                  </div>
                  <span
                    style={{
                      marginRight: "6px",
                    }}
                  >
                    CSV
                  </span>
                </div>
              </PrimaryBtn>
            </ClearPanel>
            <StatPanel>
              <StatBlock>
                <StatLabel>Total Pickup Locations </StatLabel>
                <StatValue>{data.locations.length}</StatValue>
              </StatBlock>
              <StatBlock>
                <StatLabel>Total Kegs to Collect </StatLabel>
                <StatValue>{SensorListStat(data.totalKegs)}</StatValue>
              </StatBlock>
            </StatPanel>
            <Panel>
              <Table loading={dataLoading} style={{ clear: "both" }} data={data.locations} columns={columns} defaultSorted={defaultSorted} ref={tableRef} />
            </Panel>
          </LoadingContainer>
        </div>
      </PageContainer>
      <SensorListModal
        sensors={sensorList}
        sensorColumns={["pickupIndicators"]}
        loading={dataLoading}
        err={dataErr}
        modalOpen={sensorListModalOpen}
        setModalOpen={setSensorListModalOpen}
      />
    </>
  );
};

export default KegsReadyForCollection;
