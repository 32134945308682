import hexToRgba from "../../util/hexToRgba";
import hexToRgb from "../../util/hexToRgb";
import hexLum from "../../util/hexLum";
import { Theme } from "./types";
import { lightMap } from "../GlobalStyles/google-maps";
import * as echarts from "echarts";
import fullscreenExpand from "../../imgs/fullscreen-expand-light-theme.svg";
import fullscreenContract from "../../imgs/fullscreen-contract-light-theme.svg";
import { checkDomain } from "../../util/checkDomain";

const DURATION = 250;

const theme: Theme = {
  space: [
    "4px",
    "8px",
    "12px",
    "16px",
    "24px",
    "32px",
    "48px",
    "64px",
    "96px",
    "128px",
    "192px",
    "256px",
    "384px",
    "512px",
    "640px",
    "768px",
    "896px",
    "1024px",
  ],
  color: {
    default: ["#ababab"],
    primary: [checkDomain("#b8292f", "#1b75bb", "#814998", "#00b26b")],
    secondary: [checkDomain("#d5ae30", "#ee6c4d", "#deb81e", "#b27400")],
    success: ["#4bb543"],
    info: ["#d738ff"],
    warning: ["#e87900"],
    danger: ["#c90000"],

    success_dark: ["#0b9300"],
    danger_dark: ["#d70000"],

    freshness: ["#eac100"],
    placeholder: ["#757575"],
    side_nav_inactive: ["#6b6b6b"],
    side_nav_active: ["#000000"],
    map_bg: ["#ede9dd"],

    tooltip_bg: ["#ffffff"],
    tooltip_hover: ["#f1f1f1"],
    tooltip_success_hover: ["#4bb543"],
    tooltip_warning_hover: ["#e87900"],
    tooltip_danger_hover: ["#ff6161"],
    tooltip_shadow: ["#0000001a"],

    scroll_bar: ["#c3c3c3"],
    scroll_bar_hover: ["#a8a8a8"],

    border: ["#d0d0d0"],
    subtle_border: ["#e3e3e3"],

    more_hover: ["#f1f1f1"],

    modal_border: ["#eceef1"],
    modal_background: ["#fafafa"],
    modal_header_background: ["#f3f5f8"],

    dnd_list_item_bg: ["#ffffff"],
    react_icon_background: ["#ffffff"],

    other: ["#8D8E8F"],

    error: ["#ff0000"],
    font: ["#3a3a3a"],
    font_bold: ["#000000"],
    font_faint: ["#aaaaaa"],
    inverse_font: ["#a9a9a9"],
    inverse_font_bold: ["#ffffff"],
    inverse_font_faint: ["#6e6e6e"],
    button_font: ["#dddddd"],
    button_font_bold: ["#ffffff"],
    loading_bg: ["#cbcbcb"],
    loading_icon: ["#757575"],
    main_bg: ["#ffffff"],
    main_fg: ["#424242"],
    panel_bg: ["#eff2f6"],
    panel_fg: ["#616161"],
    nearbyPlace: ["#7f00ff"],
    disabled: ["#cecece"],
    table_group_header: ["#c4c6c8"],
    table_header: ["#fafafa"],
    table_border: ["#dddfe1"],
    table_body: ["#ffffff"],
    shadow: ["#646464"],
    sign_in_bg: ["#d8d8d8"],
    cold: ["#0090ff"],
    warm: ["#ffb800"],
    hot: ["#ff0000"],
    great: ["#0b9300"],
    good: ["#8bb900"],
    average: ["#b9b300"],
    bad: ["#ffb800"],
    worse: ["#ff0000"],
    input_bg: ["#fcfcfc"],
    logo_beer_blue: ["#1B75BB"],
    logo_beer_gray: ["#2B2B2B"],
    logo_tech_red: ["#b8292f"],
    logo_tech_gray: ["#2B2B2B"],
    logo_safari_green: ["#34b44c"],
    logo_safari_red: ["#ec1c24"],
    logo_uas_blue: ["#1d91c0"],
    logo_uas_gray: ["#3a3a3a"],
    logo_med_gray: ["#3e403f"],
    logo_med_plum: ["#814998"],
    logo_loop_gray: ["#3e403f"],
    logo_loop_green: ["#00b26b"],
  },
  color_80: {},
  color_50: {},
  color_30: {},
  color_15: {},
  color_rgb: {},
  fill_opacity: [0.4, 0.9, 1.0],
  stroke_width: [3, 2, 1],
  map_tile: "light-v10",
  map_style: lightMap,
  filter_theme: "invert(22%) sepia(0%) saturate(0%) hue-rotate(159deg) brightness(91%) contrast(91%)",
  leaflet_fullscreen_expand: fullscreenExpand,
  leaflet_fullscreen_contract: fullscreenContract,
  sample_type_colors: {
    sample: "#3098e8",
    historic: "#6e38eb",
    userPrivate: "#ff6427",
    userPublic: "#f92662",
  },
  echarts_theme: "light_theme",
  echarts_opacity: 0.4,
  echarts_palette: ["#0098d9", "#2b821d", "#c12e34", "#e6b600", "#005eaa", "#339ca8", "#cda819", "#32a487"],
  map_bg_grid:
    "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACAAgMAAAC+UIlYAAAACVBMVEX///////////+OSuX+AAAAA3RSTlNmADMJ/BJaAAAAQklEQVR4AWMgCERDQ0On4sYhw0rBqAKtVXjBitGAGk0Po+lhND2MpodRBaPpYVTBaHrAgkcVjKaHUQWj6WFUASEAAIdLM/YhmklvAAAAAElFTkSuQmCC)",
  short_datetime: "DD MMM Y HH:mm",
  long_datetime: "DD MMM Y HH:mm Z",
  short_date: "DD MMM Y",
  long_date: "ddd, Do MMM Y",
  time: "hh:mm a",
  maxWidth: {
    form: "25rem",
  },
  height: {
    toolbar: "48px",
  },
  fontFamily: {
    heading: "Montserrat, sans-serif",
    body: "Montserrat, sans-serif",
  },
  fontSize: {
    l: "1.25rem",
    m: "1rem",
    s: "0.85rem",
  },
  fontWeight: {
    bold: 500,
    medium: 400,
  },
  lineHeight: {
    l: 1.8,
    m: 1.5,
    s: 1,
  },
  letterSpacing: {
    s: "0.1em",
  },
  zIndex: {
    toolbar: 1,
  },
  opacity: {
    disabled: 0.5,
    placeholder: 0.5,
    fade: 0.5,
  },
  borderRadius: {
    m: "0.25rem",
  },
  /* https://codepen.io/protomorph/pen/VejmVE */
  dropShadow: {
    s: `
      drop-shadow(0 2px 2px rgba(0, 0, 0, 0.12))
      drop-shadow(0 3px 1px rgba(0, 0, 0, 0.14))
      drop-shadow(0 1px 5px rgba(0, 0, 0, 0.12))
      drop-shadow(0 -1px 2px rgba(0, 0, 0, 0.1))`,
    m: `
      drop-shadow(0 3px 4px rgba(0, 0, 0, 0.12))
      drop-shadow(0 3px 3px rgba(0, 0, 0, 0.14))
      drop-shadow(0 1px 8px rgba(0, 0, 0, 0.12))
      drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.1))`,
  },
  boxShadow: {
    s: "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em",
    m: "",
  },
  scale: {
    m: 1.2,
  },
  duration: DURATION,
  transition: {
    m: `all ${DURATION}ms ease-out`,
  },
};

// Converts each hex color above to an rgb array (e.g. [r, g, b]) and
// then adds them to the theme object in the color_rgb property.
Object.keys(theme.color).forEach((c: string) => {
  const currcolor = theme.color[c][0];
  theme.color[c] = [];
  theme.color_80[c] = [];
  theme.color_50[c] = [];
  theme.color_30[c] = [];
  theme.color_15[c] = [];
  theme.color_rgb[c] = [];
  theme.color[c].push(hexLum(currcolor, 0.3));
  theme.color[c].push(hexLum(currcolor, 0.15));
  theme.color[c].push(currcolor);
  theme.color[c].push(hexLum(currcolor, -0.15));
  theme.color[c].push(hexLum(currcolor, -0.3));
  theme.color[c].forEach((hex: string) => {
    theme.color_rgb[c].push(hexToRgb(hex));
    theme.color_80[c].push(hexToRgba(hex, 80)); // 80% Opacity
    theme.color_50[c].push(hexToRgba(hex, 50)); // 50% Opacity
    theme.color_30[c].push(hexToRgba(hex, 30)); // 30% Opacity
    theme.color_15[c].push(hexToRgba(hex, 15)); // 15% Opacity
  });
});

// ECharts theming
const contrastColor = theme.color.font[2];
const axisCommon = function () {
  return {
    axisLine: {
      lineStyle: {
        color: contrastColor,
      },
    },
    axisTick: {
      lineStyle: {
        color: contrastColor,
      },
    },
    axisPointer: {
      label: {
        backgroundColor: theme.color_80.main_bg[2],
        textStyle: {
          color: contrastColor,
        },
      },
    },
    axisLabel: {
      textStyle: {
        color: contrastColor,
      },
    },
    splitLine: {
      lineStyle: {
        color: theme.color_15.font[2],
      },
    },
    splitArea: {
      areaStyle: {
        color: contrastColor,
      },
    },
  };
};

echarts.registerTheme("light_theme", {
  color: theme.echarts_palette,
  backgroundColor: theme.color.panel_bg[2],
  tooltip: {
    backgroundColor: theme.color.main_bg[2],
    textStyle: {
      color: contrastColor,
    },
    axisPointer: {
      lineStyle: {
        color: contrastColor,
      },
      crossStyle: {
        color: contrastColor,
      },
    },
  },
  legend: {
    textStyle: {
      color: contrastColor,
    },
  },
  textStyle: {
    color: contrastColor,
  },
  title: {
    textStyle: {
      color: contrastColor,
    },
  },
  toolbox: {
    iconStyle: {
      normal: {
        borderColor: contrastColor,
      },
    },
  },
  dataZoom: {
    dataBackgroundColor: contrastColor,
    fillerColor: theme.color_15.primary[2],
    handleColor: contrastColor,
    textStyle: {
      color: contrastColor,
    },
  },
  timeline: {
    lineStyle: {
      color: contrastColor,
    },
    itemStyle: {
      normal: {
        color: theme.echarts_palette[1],
      },
    },
    label: {
      normal: {
        textStyle: {
          color: contrastColor,
        },
      },
    },
    controlStyle: {
      normal: {
        color: contrastColor,
        borderColor: contrastColor,
      },
    },
  },
  timeAxis: axisCommon(),
  logAxis: axisCommon(),
  valueAxis: axisCommon(),
  categoryAxis: axisCommon(),

  line: {
    symbol: "circle",
  },
  graph: {
    color: theme.echarts_palette,
  },
  gauge: {
    title: {
      textStyle: {
        color: contrastColor,
      },
    },
  },
  candlestick: {
    itemStyle: {
      normal: {
        color: "#FD1050",
        color0: "#0CF49B",
        borderColor: "#FD1050",
        borderColor0: "#0CF49B",
      },
    },
  },
});

export default theme;
