import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill, BsFillCheckCircleFill } from "react-icons/bs";
import { HiPlus } from "react-icons/hi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce";
import { getPlaceAllocationTableSettings, placeAllocationsTableDefaults, savePlaceAllocationTableSettings } from "../../services/localStorage";
import { deletePlaceAllocation, fetchPlaceAllocations } from "../../services/placeAllocations";
import MoreIcon from "../../svgs/MoreIcon";
import { isAdminOrUser } from "../../util/checkRole";
import copyToClipboard from "../../util/copyToClipboard";
import downloadFile from "../../util/downloadFile";
import errToStr from "../../util/errToStr";
import { getTableFilters } from "../../util/urlParamFilters";
import AssetTypeLabel from "../AssetTypeLabel";
import { PrimaryBtn } from "../Buttons";
import DeleteModal from "../DeleteModal";
import EditPlaceAllocationModal from "../EditPlaceAllocationModal";
import { PrimaryIconBtn } from "../IconButtons";
import NewTable from "../NewTable";
import { DesktopDiv, MobileDiv } from "../NewTable/styles";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

type PlaceAllocation = {
  id: string;
  placeId: number;
  placeName: string;
  name: string;
  assetTypeId: string;
  assetTypeName: string;
  assetTypeColour: string;
  assetTypeIcon: string;
  min: number;
  max: number;
  dateCreatedUnix: number;
  dateCreated: string;
  userCreated: string;
  count: number;
  status: number;
};

const PlaceAllocationsTable: FC<any> = ({ placeId }) => {
  const { color, short_datetime, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [count, setCount] = useState<any>(0);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [sorting, setSorting] = useState<SortingState>([{ id: "dateCreatedUnix", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnFiltersDebounced] = useDebounce(columnFilters, 200);

  const [selectedAllocation, setSelectedAllocation] = useState<any>(undefined);
  const [editModalOpen, setEditModalOpen] = useState<any>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: getPlaceAllocationTableSettings() ? getPlaceAllocationTableSettings().pageSize : 20,
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const getPlaceAllocations = () => {
    setDataLoading(true);
    setDataErr("");

    fetchPlaceAllocations(source, placeId, { pageIndex, pageSize, orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        setData(response.data);
        setCount(response.count);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const fetchCsv = (download: boolean) => {
    setDataLoading(true);
    setDataErr("");

    fetchPlaceAllocations(source, placeId, { orderBy: sorting, filters: getTableFilters(columnFiltersDebounced) })
      .then((response) => {
        if (download) {
          downloadFile(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            }),
            "text/csv;charset=utf-8",
            "Place Allocation List.csv"
          );
        } else {
          copyToClipboard(
            stringify(formatDataToCsv(response.data), {
              quoted: true,
              quoted_string: true,
            })
          );
          toast.info("Copied to Clipboard");
        }
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });
  };

  const formatDataToCsv = (data: any) => {
    const headers = ["Name", "Asset Type", "Current Count", "Min", "Max", "Created By", "Date Created", "Allocation Status"];

    if (placeId == null) {
      headers.splice(1, 0, "Place");
    }

    return [
      headers,
      ...data.map((row: any) => {
        const data = [
          row.name,
          row.assetTypeName,
          row.count,
          row.min,
          row.max,
          row.userCreated,
          row.dateCreatedUnix !== undefined ? moment.unix(row.dateCreatedUnix).format(long_datetime) : "",
          row.status === 0 ? "Correctly Allocated" : row.status === 1 ? "Under Allocated" : "Over Allocated",
        ];

        if (placeId == null) {
          data.splice(1, 0, row.placeName);
        }

        return data;
      }, []),
    ];
  };

  const columns = React.useMemo<ColumnDef<PlaceAllocation>[]>(
    () => [
      {
        id: "actions",
        header: "",
        enableColumnFilter: false,
        enableSorting: false,
        enableHiding: false,
        cell: ({ row }) => {
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  {isAdminOrUser() ? (
                    <>
                      <MenuButton
                        onClick={() => {
                          setSelectedAllocation(row.original);
                          setEditModalOpen(true);
                        }}
                      >
                        Edit Place Allocation
                      </MenuButton>
                      <DangerMenuButton
                        onClick={() => {
                          setSelectedAllocation(row.original);
                          setDeleteModalOpen(true);
                        }}
                      >
                        Delete Place Allocation
                      </DangerMenuButton>
                    </>
                  ) : (
                    <>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Edit Place Allocation</MenuButton>
                        </div>
                      </Tooltip>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Delete Place Allocation</MenuButton>
                        </div>
                      </Tooltip>
                    </>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        size: 65,
        minSize: 65,
      },
      {
        accessorKey: "name",
        header: "Name",
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 110,
      },
      {
        accessorKey: "status",
        cell: (props: any) =>
          props.getValue() === undefined ? (
            <></>
          ) : props.getValue() === 0 ? (
            <Tooltip trigger="mouseenter" content="Correctly Allocated">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <IconContext.Provider value={{ color: color.success[2], size: "23px" }}>
                  <BsFillCheckCircleFill />
                </IconContext.Provider>
              </div>
            </Tooltip>
          ) : props.getValue() === 1 ? (
            <Tooltip trigger="mouseenter" content="Under Allocated">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <IconContext.Provider value={{ color: color.danger[2], size: "23px" }}>
                  <BsFillArrowDownCircleFill />
                </IconContext.Provider>
              </div>
            </Tooltip>
          ) : props.getValue() === 2 ? (
            <Tooltip trigger="mouseenter" content="Over Allocated">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <IconContext.Provider value={{ color: color.danger[2], size: "23px" }}>
                  <BsFillArrowUpCircleFill />
                </IconContext.Provider>
              </div>
            </Tooltip>
          ) : (
            <></>
          ),
        header: "Allocation Status",
        meta: {
          filterType: "select",
          loadOptionsKey: "removed",
          selectKey: "value",
          selectOptions: [
            { value: 0, label: "Correct", colour: color.success[2], comparisonOperator: "eq" },
            { value: 0.9, label: "Incorrect", colour: color.danger[2], comparisonOperator: "ge" },
            { value: 1, label: "Under", colour: color.danger[2], comparisonOperator: "eq" },
            { value: 2, label: "Over", colour: color.danger[2], comparisonOperator: "eq" },
          ],
        },
        filterFn: undefined,
        size: 180,
      },
      ...(placeId == null
        ? [
            {
              header: "Place",
              accessorKey: "placeName",
              cell: (props: any) => (props.getValue() ? <Link to={`/places/${props.row.original.placeId}`}>{props.getValue()}</Link> : ""),
              meta: {
                filterType: "string",
              },
              filterFn: undefined,
              size: 300,
            },
          ]
        : []),
      {
        accessorKey: "assetTypeName",
        cell: (props: any) =>
          props.getValue() ? (
            <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
              <AssetTypeLabel name={props.getValue()} colour={props.row.original.assetTypeColour} icon={props.row.original.assetTypeIcon} />
            </div>
          ) : (
            <></>
          ),
        header: "Asset Type",
        enableSorting: false,
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 170,
      },
      {
        accessorKey: "count",
        header: "Current Count",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 150,
      },
      {
        accessorKey: "min",
        header: "Min",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 90,
      },
      {
        accessorKey: "max",
        header: "Max",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "number",
        },
        filterFn: undefined,
        size: 90,
      },
      {
        header: "Created By",
        accessorKey: "userCreated",
        cell: (props: any) => <span title={props.getValue()}>{props.getValue()}</span>,
        meta: {
          filterType: "string",
        },
        filterFn: undefined,
        size: 170,
      },
      {
        header: "Date Created",
        accessorKey: "dateCreatedUnix",
        cell: (props: any) => (props.getValue() ? moment.unix(props.getValue()).format(short_datetime) : ""),
        meta: {
          filterType: "dateRangeUnix",
        },
        filterFn: undefined,
        size: 170,
      },
    ],
    [color]
  );

  return (
    <>
      <NewTable
        data={data}
        count={count}
        dataErr={dataErr}
        dataLoading={dataLoading}
        columns={columns}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        columnFiltersDebounced={columnFiltersDebounced}
        setColumnFilters={setColumnFilters}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
        fetchData={getPlaceAllocations}
        fetchCsv={fetchCsv}
        defaultTableSettings={placeAllocationsTableDefaults}
        getTableSettings={getPlaceAllocationTableSettings}
        saveTableSettings={savePlaceAllocationTableSettings}
        dataTypeName="Place Allocations"
        emptyDataMsg="Create a place allocation above to get started"
        TableButtons={
          <div>
            {isAdminOrUser() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setSelectedAllocation(undefined);
                      setEditModalOpen(true);
                    }}
                  >
                    Create
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <PrimaryIconBtn
                      onClick={() => {
                        setSelectedAllocation(undefined);
                        setEditModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryBtn disabled={true}>Create</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryIconBtn>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
        }
      />
      {editModalOpen && (
        <EditPlaceAllocationModal
          allocation={selectedAllocation}
          placeId={placeId}
          setData={setData}
          modalOpen={editModalOpen}
          setModalOpen={setEditModalOpen}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          title="Delete Place Allocation"
          body={<span>Are you sure you want to delete this place allocation?</span>}
          successMsg="Place Allocation Deleted"
          onDelete={() => setData((prev: any) => prev.filter((row: any) => row.id !== selectedAllocation.id))}
          onClose={() => setSelectedAllocation(undefined)}
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          deleteService={deletePlaceAllocation}
          serviceParams={[selectedAllocation.id]}
        />
      )}
    </>
  );
};

export default PlaceAllocationsTable;
