import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { FormError } from "../FormComponents";
import { exists } from "../../util/formValidations";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { removeAllContents } from "../../services/contents";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import { AsyncSelect } from "../Select";
import { fetchAutoComplete } from "../../services/autoComplete";

const RemoveAllContentsModal: FC<any> = ({ manifestId, onSuccess, modalOpen, setModalOpen }) => {
  const [formData, setFormData] = useState<any>({ placeRemoved: null });
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "placeRemoved":
          currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const handleSubmit = () => {
    const placeRemoved = formData.placeRemoved && formData.placeRemoved.value !== undefined ? formData.placeRemoved.value : null;
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      removeAllContents(source, manifestId, placeRemoved)
        .then((response) => {
          onSuccess(response);
          setSubmittedMsg("All Contents Removed");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={handleSubmit}
      onClose={handleClose}
      title="Remove All Contents"
      success={submittedMsg}
      error={submittingErr}
      body={
        <LoadingContainer loading={submitting}>
          <div>
            <p style={{ marginBottom: "15px" }}>Please select the place where all contents were removed from the manifest</p>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <ModalFormContainer>
                <label>Place Removed</label>
                <AsyncSelect
                  closeMenuOnSelect={true}
                  defaultOptions={true}
                  isClearable={true}
                  isError={formErrors.placeRemoved}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                  name="placeRemoved"
                  onChange={handleSelectChange}
                  placeholder="Select..."
                  value={formData.placeRemoved}
                />
                <FormError error={formErrors.placeRemoved}>{formErrors.placeRemoved}</FormError>
              </ModalFormContainer>
            </form>
          </div>
        </LoadingContainer>
      }
      footer={
        submittedMsg ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : submittingErr ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : (
          <>
            <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
            <PrimaryBtn onClick={() => handleSubmit()}>Remove All</PrimaryBtn>
          </>
        )
      }
    />
  );
};

export default RemoveAllContentsModal;
