import axios, { CancelTokenSource } from "axios";

// regular expressions to extract IP and country values
const countryCodeExpression = /loc=([\w]{2})/;

export const fetchCountry = async (source: CancelTokenSource) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    timeout: 3000,
    url: "https://www.cloudflare.com/cdn-cgi/trace",
  });

  const countryCode = countryCodeExpression.exec(data);

  if (countryCode == null || countryCode[1] === "") {
    return null;
  } else {
    return countryCode[1];
  }
};
