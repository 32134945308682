import styled from "../Themes";

export const MapContainer = styled<any>(`div`)`
  position: absolute;
  overflow: hidden;
  top: 57px;
  right: 0;
  bottom: 0;
  left: ${({ listOpen }) => (listOpen ? "400px" : 0)};
  transition: ${({ theme }) => theme.transition.m};

  @media (max-width: 950px) {
    right: 0;
  }

  @media (max-width: 500px) {
    left: ${({ listOpen }) => (listOpen ? "75%" : 0)};
  }
`;

export const MapLoading = styled(`div`)`
  position: absolute;
  width: 100px;
  padding: 4px;
  background: ${({ theme }) => theme.color.main_bg[2]};
  border: 1px solid ${({ theme }) => theme.color.main_fg[2]};
  border-radius: 3px;
  top: 76px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 1000;
  text-align: center;
`;
