import axios from "axios";
import matchSorter from "match-sorter";
import moment from "moment";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { ThemeContext } from "styled-components";
import { fetchEditBeer } from "../../services/editBeer";
import MoreIcon from "../../svgs/MoreIcon";
import { isAdminOrUser } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import { printFixedTemp, printTemp, printTempUnit } from "../../util/formatUnits";
import Badge from "../Badge";
import Bold from "../Bold";
import { PrimaryBtn } from "../Buttons";
import EditBeerModal from "../EditBeerModal";
import FlatpickrRangePicker from "../FlatpickrRangePicker";
import { PrimaryIconBtn } from "../IconButtons";
import LoadingContainer from "../LoadingContainer";
import { DesktopDiv, MobileDiv, TableHeaderButtons } from "../NewTable/styles";
import Table, { CsvButtonsComponent } from "../Table";
import Tooltip from "../Tooltip";
import { MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

const BeersTable: FC<any> = () => {
  const { color, short_datetime, short_date } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [selectedBeer, setSelectedBeer] = useState<any>(null);

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchEditBeer(source)
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  const formatDataToCsv = (tableRef: any) => {
    const sortedData = tableRef.current.getResolvedState().sortedData;
    let headers = [];

    headers = ["Name", "Target ABV (%)", "Life Expectancy (days)", `Temperature (${printTempUnit()})`, "Keg Count", "Date Created", "Active"];

    return [
      headers,
      ...sortedData.map((row: any) => {
        return [
          row.name,
          row.targetAbv,
          row.lifeExpectancy,
          printFixedTemp(row.temperature, 1),
          row.kegCount,
          moment.unix(row.dateCreated).format(short_datetime),
          row.active ? "True" : "False",
        ];
      }, []),
    ];
  };

  const columns: any = [];

  columns.push({
    Header: "Actions",
    minWidth: 110,
    maxWidth: 110,
    filterable: false,
    sortable: false,
    Cell: ({ original }: any) => {
      return (
        <Tooltip
          maxWidth="none"
          theme="binary-no-padding"
          content={
            <MenuList>
              {isAdminOrUser() ? (
                <MenuButton
                  onClick={() => {
                    setSelectedBeer(original);
                    setEditModalOpen(true);
                  }}
                >
                  Edit Beer
                </MenuButton>
              ) : (
                <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                  <div
                    style={{
                      cursor: "not-allowed",
                      userSelect: "none",
                    }}
                  >
                    <MenuButton disabled={true}>Edit Beer</MenuButton>
                  </div>
                </Tooltip>
              )}
            </MenuList>
          }
          interactive={true}
          touch={true}
          appendTo={document.body}
          trigger="click"
          placement="bottom-start"
        >
          <MoreIconContainer>
            <MoreIconSize>
              <MoreIcon fill={color.font[2]} />
            </MoreIconSize>
          </MoreIconContainer>
        </Tooltip>
      );
    },
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  columns.push({
    id: "name",
    Header: "Name",
    accessor: "name",
    minWidth: 150,
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["name"],
      }),
    filterAll: true,
    Cell: (props: any) => <span title={props.value}>{props.value}</span>,
  });

  columns.push({
    id: "targetAbv",
    Header: "Target ABV",
    accessor: "targetAbv",
    minWidth: 150,
    maxWidth: 150,
    Cell: (props: any) => <span title={`${props.value} %`}>{props.value} %</span>,
  });

  columns.push({
    id: "lifeExpectancy",
    Header: "Life Expectancy",
    accessor: "lifeExpectancy",
    minWidth: 180,
    maxWidth: 180,
    Cell: (props: any) => <span title={`${props.value} days`}>{props.value} days</span>,
  });

  columns.push({
    id: "temperature",
    Header: "Storage Temperature",
    accessor: "temperature",
    minWidth: 250,
    maxWidth: 250,
    Cell: (props: any) => printTemp(props.value, 1),
  });

  columns.push({
    id: "kegCount",
    Header: "Keg Count",
    accessor: "kegCount",
    minWidth: 140,
    maxWidth: 140,
  });

  columns.push({
    id: "dateCreated",
    Header: "Date Created",
    accessor: "dateCreated",
    minWidth: 170,
    maxWidth: 170,
    filterMethod: (filter: any, row: any) => {
      if (filter.value.length === 2) {
        if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
        else return false;
      } else return true;
    },
    Filter: ({ onChange }: any) => (
      <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
    ),
    Cell: (props: any) => (props.value ? moment.unix(props.value).format(short_datetime) : ""),
  });

  columns.push({
    id: "active",
    Header: "Active",
    accessor: "active",
    minWidth: 120,
    maxWidth: 120,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "active") {
        return row[filter.id] === true;
      }
      if (filter.value === "inactive") {
        return row[filter.id] === false;
      }
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="active">Active</option>
        <option value="inactive">Inactive</option>
      </select>
    ),
    Cell: (props: any) => (
      <Badge title={props.value ? "Active" : "Inactive"} background={props.value ? color.success_dark[2] : color.danger_dark[2]}>
        {props.value ? "Active" : "Inactive"}
      </Badge>
    ),
  });

  const defaultSorted = [
    {
      id: "name",
      desc: false,
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <LoadingContainer loading={dataLoading} err={dataErr}>
        <TableHeaderButtons>
          <div style={{ display: "flex" }}>
            {isAdminOrUser() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setSelectedBeer(null);
                      setEditModalOpen(true);
                    }}
                  >
                    Create
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <PrimaryIconBtn
                      onClick={() => {
                        setSelectedBeer(null);
                        setEditModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryBtn disabled={true}>Create</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryIconBtn>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
          <CsvButtonsComponent data={data} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName="Beer List.csv" />
        </TableHeaderButtons>
        <Table
          loading={dataLoading}
          filterable={true}
          style={{ clear: "both" }}
          data={data}
          columns={columns}
          defaultSorted={defaultSorted}
          ref={tableRef}
          defaultPageSize={15}
        />
      </LoadingContainer>
      {editModalOpen && <EditBeerModal beer={selectedBeer} setData={setData} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
    </div>
  );
};

export default BeersTable;
