import styled from "../Themes";

export const Label = styled<any>(`label`)`
  display: inline-block;
  user-select: none;
  cursor: ${({ loading }) => (loading ? "wait" : "pointer")};
  margin-bottom: 0;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  opacity: 0;
`;

export const StyledCheckbox = styled<any>(`div`)`
  position: relative;
  top: 2px;
  float: left;
  width: 20px;
  height: 20px;
  border: 1px solid
    ${({ checked, disabled, loading, theme }) => {
      if (disabled || loading) {
        return checked ? theme.color.disabled[2] : theme.color.disabled[2];
      }
      if (!disabled) {
        return checked ? theme.color.primary[2] : theme.color.font[2];
      }
    }};
  border-radius: 3px;
  background: ${({ checked, disabled, loading, theme }) => {
    if (disabled || loading) {
      return theme.color_50.disabled[2];
    }
    if (!disabled) {
      return checked ? theme.color_50.primary[0] : "none";
    }
  }};
  transition: ${({ theme }) => theme.transition.m};

  ${/* sc-selector */ Label}:hover & {
    border: ${({ disabled, loading, theme }) => {
      if (disabled || loading) {
        return `1px solid ${theme.color.disabled[2]}`;
      }
      if (!disabled) {
        return `1px solid ${theme.color.primary[2]}`;
      }
    }};

    background: ${({ checked, disabled, loading, theme }) => {
      if (!disabled) {
        return checked ? theme.color_50.primary[2] : "none";
      }
    }};
  }

  .indeterminate-line {
    content: "";
    position: absolute;
    width: 12px;
    height: 2px;
    background-color: ${({ theme }) => theme.color.font_bold[2]};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Svg = styled(`svg`)`
  position: absolute;
  top: -1px;
  left: -1px;
`;

export const Path = styled<any>(`path`)`
  fill: ${({ checked, disabled, loading, theme }) => {
    if (disabled || loading) {
      return checked ? theme.color.disabled[2] : theme.color_50.disabled[2];
    }
    if (!disabled) {
      return checked ? theme.color_50.primary[0] : "none";
    }
  }};
  stroke: none;
  stroke-width: 2px;
  transition: all 0.6s ease;

  ${/* sc-selector */ Label}:hover & {
    stroke: ${({ disabled, loading, theme }) => (disabled || loading ? "none" : theme.color.primary[2])};
  }
`;

export const Polyline = styled<any>(`polyline`)`
  fill: none;
  stroke: ${({ disabled, loading, theme }) => (disabled || loading ? theme.color.disabled[4] : theme.color.font_bold[2])};
  stroke-width: 1.5;
  stroke-linejoin: round;
  stroke-dasharray: 18px;
  stroke-dashoffset: ${({ checked }) => (checked ? "0px" : "18px")};
  transition: all 0.3s ease;
`;

export const LabelText = styled<any>(`span`)`
  margin-left: 8px;
  pointer-events: none;
  vertical-align: middle;
  color: ${({ disabled, theme }) => (disabled ? theme.color.disabled[2] : theme.color.font[2])};
`;
