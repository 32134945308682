import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import React, { FC, useContext, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { fetchVenueReport } from "../../../services/venueReport";
import downloadFile from "../../../util/downloadFile";
import errToStr from "../../../util/errToStr";
import { humaniseHours } from "../../../util/humaniseDurations";
import LoadingContainer from "../../LoadingContainer";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import DurationSpan from "../../DurationSpan";
import Table from "../../Table";
import { ClearPanel, Panel, StatPanel } from "../../Panel";
import moment from "moment";
import Drawer from "rc-drawer";
import Cross from "../../../svgs/Cross";
import { DangerAlert } from "../../Alerts";
import { PrimaryBtn } from "../../Buttons";
import { DrawContent, DrawHeader, DrawCloseButton, DrawBody, FilterInputContainer, DrawFooter } from "../../Drawer/styles";
import FlatpickrPicker from "../../FlatpickrPicker";
import { Title, Subtitle } from "../../PageTitle/styles";
import TrackerListModal from "../../TrackerListModal";
import { StatBlock, StatLabel, StatValue } from "../styles";
import getParameterByName from "../../../util/getParamByName";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { TbDownload } from "react-icons/tb";
import { IconContext } from "react-icons";

const formatDataToCsv = (tableRef: any) => {
  const headers = [
    "Place",
    "Keg Count Before Tap",
    "Minimum Duration Before Tap",
    "Average Duration Before Tap",
    "Maximum Duration Before Tap",
    "Keg Count On Tap",
    "Minimum Duration On Tap",
    "Average Duration On Tap",
    "Maximum Duration On Tap",
    "Keg Count After Tap",
    "Minimum Duration After Tap",
    "Average Duration After Tap",
    "Maximum Duration After Tap",
  ];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [
        row.place,
        row.kegsBeforeTap ? row.kegsBeforeTap.length : "-",
        humaniseHours(row.durationsBeforeTapMin),
        humaniseHours(row.durationsBeforeTapAvg),
        humaniseHours(row.durationsBeforeTapMax),
        row.kegsOnTap ? row.kegsOnTap.length : "-",
        humaniseHours(row.durationsOnTapMin),
        humaniseHours(row.durationsOnTapAvg),
        humaniseHours(row.durationsOnTapMax),
        row.kegsAfterTap ? row.kegsAfterTap.length : "-",
        humaniseHours(row.durationsAfterTapMin),
        humaniseHours(row.durationsAfterTapAvg),
        humaniseHours(row.durationsAfterTapMax),
      ];
    }, []),
  ];
};

const ConsumptionAudit: FC<any> = () => {
  const { color, short_date, long_datetime } = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const tableRef = useRef<any>(null);

  const startDateParam = getParameterByName("start-date", location.search) != null ? getParameterByName("start-date", location.search) : null;
  const endDateParam = getParameterByName("end-date", location.search) != null ? getParameterByName("end-date", location.search) : null;

  const [data, setData] = useState<any>({
    report: [],
    visits: 0,
    places: 0,
    beforeTapAvg: 0,
    onTapAvg: 0,
    afterTapAvg: 0,
  });
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [trackerList, setTrackerList] = useState<any>([]);
  const [trackerListModalOpen, setTrackerListModalOpen] = useState<boolean>(false);

  const [filtersOpen, setFiltersOpen] = useState<boolean>(true);

  // Report filters
  const [startDate, setStartDate] = useState<any>(startDateParam != null ? Number(startDateParam) * 1000 : moment().subtract(3, "months").valueOf());
  const [endDate, setEndDate] = useState<any>(endDateParam != null ? Number(endDateParam) * 1000 : moment().valueOf());

  const [appliedFilters, setAppliedFilters] = useState<number>(0);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // If start date and end date are in the url, fetch report
  useEffect(() => {
    const startDateParam = getParameterByName("start-date", location.search) != null ? getParameterByName("start-date", location.search) : null;
    const endDateParam = getParameterByName("end-date", location.search) != null ? getParameterByName("end-date", location.search) : null;

    if (startDateParam != null && endDateParam != null) {
      setFiltersOpen(false);
      fetchReport(Number(startDateParam) * 1000, Number(endDateParam) * 1000);
    }
  }, []);

  // On location change (e.g. url parameter changes because filters changed), update filters and fetch report
  useEffect(() => {
    let selectedStartDate = undefined;
    let selectedEndDate = undefined;

    const startDateParam = getParameterByName("start-date", location.search) != null ? getParameterByName("start-date", location.search) : null;
    if (startDateParam != null) {
      selectedStartDate = Number(startDateParam) * 1000;
      setStartDate(selectedStartDate);
    }

    const endDateParam = getParameterByName("end-date", location.search) != null ? getParameterByName("end-date", location.search) : null;
    if (endDateParam != null) {
      selectedEndDate = Number(endDateParam) * 1000;
      setEndDate(selectedEndDate);
    }

    if (!filtersOpen) fetchReport(selectedStartDate, selectedEndDate);
  }, [location]);

  const updateQueryParams = () => {
    const parsed = queryString.parse(location.search);
    const newQuery = { ...parsed };

    if (startDate != null) {
      newQuery["start-date"] = Math.round(startDate.valueOf() / 1000).toString();
    } else {
      delete newQuery["start-date"];
    }

    if (endDate != null) {
      newQuery["end-date"] = Math.round(endDate.valueOf() / 1000).toString();
    } else {
      delete newQuery["end-date"];
    }

    const stringified = queryString.stringify(newQuery);
    navigate({ ...location, search: stringified });
  };

  const fetchReport = (startDateParam?: any, endDateParam?: any) => {
    if (!startDate) setDataErr("Start date is required");
    else if (!endDate) setDataErr("End date is required");
    else {
      setDataLoading(true);
      setDataErr("");

      const startDateArg = startDateParam != null ? startDateParam : startDate;
      const endDateArg = endDateParam != null ? endDateParam : endDate;

      fetchVenueReport(source, startDateArg ? Math.round(startDateArg / 1000) : undefined, endDateArg ? Math.round(endDateArg / 1000) : undefined)
        .then((response) => {
          setData({
            report: response.report,
            visits: response.visits,
            places: response.report.length,
            beforeTapAvg: response.beforeTapAvg,
            onTapAvg: response.onTapAvg,
            afterTapAvg: response.afterTapAvg,
          });
          setDataLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setData({
              report: [],
              visits: 0,
              places: 0,
              beforeTapAvg: 0,
              onTapAvg: 0,
              afterTapAvg: 0,
            });
            setDataErr(errToStr(err));
            setDataLoading(false);
          }
        });

      let filters = 0;

      if (startDateArg != null) filters++;
      if (endDateArg != null) filters++;

      setAppliedFilters(filters);
    }
  };

  const columns = React.useMemo<any>(
    () => [
      {
        Header: "Place",
        columns: [
          {
            id: "place",
            Header: "Name",
            accessor: "placeName",
            Cell: (props: any) => (
              <Link to={`/places/${props.original.placeId}?start=${props.original.startDate}&end=${props.original.endDate}`}>{props.value}</Link>
            ),
            minWidth: 310,
          },
        ],
      },
      {
        Header: "Duration before tap",
        columns: [
          {
            id: "kegsBeforeTap",
            Header: "Kegs",
            accessor: "kegsBeforeTap",
            Cell: (props: any) =>
              !props.value ? (
                "-"
              ) : props.value.length === 0 ? (
                props.value.length
              ) : (
                <div
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => {
                    setTrackerList(props.value);
                    setTrackerListModalOpen(true);
                  }}
                >
                  {props.value.length}
                </div>
              ),
            minWidth: 130,
          },
          {
            id: "durationsBeforeTapMin",
            Header: "Min",
            accessor: "durationsBeforeTap.min",
            Cell: (props: any) => (props.value !== undefined ? <DurationSpan hours={props.value} /> : "-"),
            minWidth: 160,
          },
          {
            id: "durationsBeforeTapAvg",
            Header: "Avg",
            accessor: "durationsBeforeTap.avg",
            Cell: (props: any) => (props.value !== undefined ? <DurationSpan hours={props.value} /> : "-"),
            minWidth: 160,
          },
          {
            id: "durationsBeforeTapMax",
            Header: "Max",
            accessor: "durationsBeforeTap.max",
            Cell: (props: any) => (props.value !== undefined ? <DurationSpan hours={props.value} /> : "-"),
            minWidth: 160,
          },
        ],
      },
      {
        Header: "Duration on tap",
        columns: [
          {
            id: "kegsOnTap",
            Header: "Kegs",
            accessor: "kegsOnTap",
            Cell: (props: any) =>
              !props.value ? (
                "-"
              ) : props.value.length === 0 ? (
                props.value.length
              ) : (
                <div
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => {
                    setTrackerList(props.value);
                    setTrackerListModalOpen(true);
                  }}
                >
                  {props.value.length}
                </div>
              ),
            minWidth: 130,
          },
          {
            id: "durationsOnTapMin",
            Header: "Min",
            accessor: "durationsOnTap.min",
            Cell: (props: any) => (props.value !== undefined ? <DurationSpan hours={props.value} /> : "-"),
            minWidth: 160,
          },
          {
            id: "durationsOnTapAvg",
            Header: "Avg",
            accessor: "durationsOnTap.avg",
            Cell: (props: any) => (props.value !== undefined ? <DurationSpan hours={props.value} /> : "-"),
            minWidth: 160,
          },
          {
            id: "durationsOnTapMax",
            Header: "Max",
            accessor: "durationsOnTap.max",
            Cell: (props: any) => (props.value !== undefined ? <DurationSpan hours={props.value} /> : "-"),
            minWidth: 160,
          },
        ],
      },
      {
        Header: "Duration after tap",
        columns: [
          {
            id: "kegsAfterTap",
            Header: "Kegs",
            accessor: "kegsAfterTap",
            Cell: (props: any) =>
              !props.value ? (
                "-"
              ) : props.value.length === 0 ? (
                props.value.length
              ) : (
                <div
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                  onClick={() => {
                    setTrackerList(props.value);
                    setTrackerListModalOpen(true);
                  }}
                >
                  {props.value.length}
                </div>
              ),
            minWidth: 130,
          },
          {
            id: "durationsAfterTapMin",
            Header: "Min",
            accessor: "durationsAfterTap.min",
            Cell: (props: any) => (props.value !== undefined ? <DurationSpan hours={props.value} /> : "-"),
            minWidth: 160,
          },
          {
            id: "durationsAfterTapAvg",
            Header: "Avg",
            accessor: "durationsAfterTap.avg",
            Cell: (props: any) => (props.value !== undefined ? <DurationSpan hours={props.value} /> : "-"),
            minWidth: 160,
          },
          {
            id: "durationsAfterTapMax",
            Header: "Max",
            accessor: "durationsAfterTap.max",
            Cell: (props: any) => (props.value !== undefined ? <DurationSpan hours={props.value} /> : "-"),
            minWidth: 160,
          },
        ],
      },
    ],
    []
  );

  const defaultSorted = [
    {
      id: "durationsBeforeTapAvg",
      desc: true,
    },
  ];

  return (
    <>
      <Drawer
        placement="right"
        level={null}
        open={filtersOpen}
        onClose={() => {
          setFiltersOpen(false);
          updateQueryParams();
        }}
        onHandleClick={() => setFiltersOpen(!filtersOpen)}
        handler={false}
      >
        <DrawContent>
          <DrawHeader>
            <div
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                fontSize: "0",
                marginRight: "6px",
              }}
            >
              <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                <HiOutlineAdjustmentsHorizontal />
              </IconContext.Provider>
            </div>
            Filters
            <DrawCloseButton
              onClick={() => {
                setFiltersOpen(false);
              }}
              aria-label={`Close filters`}
            >
              <Cross />
            </DrawCloseButton>
          </DrawHeader>
          <DrawBody>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <label>Start Date</label>
              <FilterInputContainer>
                <FlatpickrPicker
                  name="startDate"
                  enableTime={true}
                  value={startDate}
                  clearable={false}
                  onChange={(d: any) => setStartDate(d[0])}
                  onClose={(d: any) => setStartDate(d[0])}
                  options={{ maxDate: Date.now() }}
                />
              </FilterInputContainer>
              <label>End Date</label>
              <FilterInputContainer>
                <FlatpickrPicker
                  name="endDate"
                  enableTime={true}
                  value={endDate}
                  clearable={false}
                  onChange={(d: any) => setEndDate(d[0])}
                  onClose={(d: any) => setEndDate(d[0])}
                  options={{ maxDate: Date.now() }}
                />
              </FilterInputContainer>
              {dataErr && <DangerAlert>{dataErr}</DangerAlert>}
            </form>
          </DrawBody>
          <DrawFooter>
            <PrimaryBtn
              onClick={() => {
                setFiltersOpen(false);
                updateQueryParams();
              }}
            >
              Apply Filters
            </PrimaryBtn>
          </DrawFooter>
        </DrawContent>
      </Drawer>
      <PageBreadcrumbs prevRoutes={[{ slug: "/reports", title: "Reports" }]} currRoute="Consumption Audit" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading} err={dataErr}>
            <ClearPanel style={{ textAlign: "center" }}>
              <Title style={{ marginBottom: "6px" }}>Consumption Audit</Title>
              <Subtitle>Review durations for kegs to arrive at a place, being placed on tap, and leave the place</Subtitle>
            </ClearPanel>
            <ClearPanel style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <PrimaryBtn style={{ height: "40px", minWidth: "unset", marginRight: "12px" }} padding="0 6px" onClick={() => setFiltersOpen(!filtersOpen)}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div
                      style={{
                        display: "inline-block",
                        width: "20px",
                        height: "20px",
                        fontSize: "0",
                        marginRight: "6px",
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiOutlineAdjustmentsHorizontal />
                      </IconContext.Provider>
                    </div>
                    <span>Filters</span>
                  </div>
                </PrimaryBtn>
                <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                  {appliedFilters} filter{appliedFilters === 1 ? "" : "s"} applied
                </span>
              </div>
              <PrimaryBtn
                style={{ height: "40px", minWidth: "unset", marginRight: "12px" }}
                padding="0 6px"
                onClick={() =>
                  downloadFile(
                    stringify(formatDataToCsv(tableRef), {
                      quoted: true,
                      quoted_string: true,
                    }),
                    "text/csv;charset=utf-8",
                    "Consumption Audit.csv"
                  )
                }
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "inline-block",
                      width: "20px",
                      height: "20px",
                      fontSize: "0",
                      marginRight: "6px",
                    }}
                  >
                    <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                      <TbDownload />
                    </IconContext.Provider>
                  </div>
                  <span
                    style={{
                      marginRight: "6px",
                    }}
                  >
                    CSV
                  </span>
                </div>
              </PrimaryBtn>
            </ClearPanel>
            <StatPanel>
              <StatBlock>
                <StatLabel>Date Range</StatLabel>
                <StatValue title={`${moment.unix(startDate / 1000).format(long_datetime)} - ${moment.unix(endDate / 1000).format(long_datetime)}`}>
                  {moment.unix(startDate / 1000).format(short_date)} - {moment.unix(endDate / 1000).format(short_date)}
                </StatValue>
              </StatBlock>
            </StatPanel>
            <StatPanel>
              <StatBlock style={{ width: "260px" }}>
                <StatLabel>Total Visits</StatLabel>
                <StatValue>{data.visits}</StatValue>
              </StatBlock>
              <StatBlock style={{ width: "260px" }}>
                <StatLabel>Total Places</StatLabel>
                <StatValue>{data.places}</StatValue>
              </StatBlock>
              <StatBlock style={{ width: "260px" }}>
                <StatLabel>Average Before Tap</StatLabel>
                <StatValue>{humaniseHours(data.beforeTapAvg)}</StatValue>
              </StatBlock>
              <StatBlock style={{ width: "260px" }}>
                <StatLabel>Average On Tap</StatLabel>
                <StatValue>{humaniseHours(data.onTapAvg)}</StatValue>
              </StatBlock>
              <StatBlock style={{ width: "260px" }}>
                <StatLabel>Average After Tap</StatLabel>
                <StatValue>{humaniseHours(data.afterTapAvg)}</StatValue>
              </StatBlock>
            </StatPanel>
            <Panel>
              <Table loading={dataLoading} style={{ clear: "both" }} data={data.report} columns={columns} defaultSorted={defaultSorted} ref={tableRef} />
            </Panel>
          </LoadingContainer>
        </div>
      </PageContainer>
      {trackerList?.length > 0 && (
        <TrackerListModal
          size="lg"
          data={trackerList}
          columns={["id", "startDate", "endDate", "duration"]}
          modalOpen={trackerListModalOpen}
          setModalOpen={setTrackerListModalOpen}
          onClose={() => setTrackerList([])}
        />
      )}
    </>
  );
};

export default ConsumptionAudit;
