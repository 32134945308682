import styled from "../Themes";

export const FlexRow = styled<any>(`div`)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HeadingWrapper = styled(`div`)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;

  width: 100%;
  overflow: auto;
  margin: ${({ theme }) => theme.space[4]};

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);
`;

export const MetaWrapper = styled(`div`)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  width: 100%;
  overflow: auto;
  padding: ${({ theme }) => theme.space[4]};
  margin: ${({ theme }) => theme.space[4]};

  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  box-shadow: ${({ theme }) => theme.boxShadow.s};
  text-align: center;

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);
`;

export const Section = styled(`section`)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const LogoContainer = styled("div")`
  width: 100%;
  max-width: 300px;
`;
