import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import React, { FC, useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";
import { fetchSensorCsv } from "../../services/sensorCsv";
import downloadFile from "../../util/downloadFile";
import { stringify } from "csv-stringify/browser/esm/sync";
import errToStr from "../../util/errToStr";
import { kegOrTracker } from "../../util/kegOrTracker";
import DateSelect from "../DateSelect";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import LoadingContainer from "../LoadingContainer";
import { fetchPlaceCsv } from "../../services/placeCsv";

const PlaceCsvDownloadModal: FC<any> = ({ modalOpen, setModalOpen, id, place, formatDataToCsv }) => {
  const { short_datetime } = useContext(ThemeContext);

  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [filterDates, setFilterDates] = useState<any>({
    start: moment().subtract(7, "days"),
    end: moment(),
    dateLabel: "Last 7 days",
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const downloadCsv = () => {
    // If end date is undefined set to current date
    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined ? filterDates.end.unix() : moment().unix(),
    };

    const fileName = `${place.placeName} - (${moment.unix(dates.start).format(short_datetime)} - ${moment.unix(dates.end).format(short_datetime)}).csv`;

    setDataLoading(true);
    setDataErr("");

    if (filterDates !== null) {
      fetchPlaceCsv(source, id, dates)
        .then((response) => {
          downloadFile(
            stringify(formatDataToCsv(response), {
              quoted: true,
              quoted_string: true,
            }),
            "text/csv;charset=utf-8",
            fileName
          );
          setDataLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setDataErr(errToStr(err));
            setDataLoading(false);
          }
        });
    }
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => downloadCsv()}
      onClose={() => {
        setModalOpen(false);
      }}
      submitBtnText="Download"
      cancelBtnText="Close"
      title="Download CSV"
      error={dataErr}
      body={
        <LoadingContainer loading={dataLoading}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer>
              <label>Date Range</label>
              <div>
                <DateSelect filterDates={filterDates} setFilterDates={setFilterDates} minDate={moment.unix(place.dateCreated)} maxDate={moment()} />
              </div>
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default PlaceCsvDownloadModal;
