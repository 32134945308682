import React, { FC } from "react";
import ReactSelect, { components as SelectComponents, createFilter } from "react-select";
import AsyncReactSelect from "react-select/async";
import AsyncCreatableReactSelect from "react-select/async-creatable";
import CreatableReactSelect from "react-select/creatable";
import getSelectStyles from "../GlobalStyles/react-select";
// import Tooltip from "../Tooltip";

// fix for lag when many options (along with filterOption below)
const CustomOption: FC<any> = (props: any) => {
  const { innerProps, isFocused, ...otherProps } = props;
  const { onMouseMove, onMouseOver, ...otherInnerProps } = innerProps;
  const newProps = { innerProps: { ...otherInnerProps }, ...otherProps };

  return (
    <SelectComponents.Option {...newProps} className="custom-react-select-option">
      {props.children}
    </SelectComponents.Option>
  );
};

// // Custom multiValue component to show tooltip on hover
// const CustomMultiValue: FC<any> = (props: any) => {
//   return (
//     <Tooltip trigger="mouseenter" content={"test"}>
//       <div>
//         <SelectComponents.MultiValue {...props}>{props.children}</SelectComponents.MultiValue>
//       </div>
//     </Tooltip>
//   );
// };

export const Select: FC<any> = ({ components, isError, center, ...props }) => {
  const styles = getSelectStyles(isError, center);

  return (
    <>
      <ReactSelect
        classNamePrefix="bt-select"
        // Workaround to close the options menu when scrolling anywhere but the menu list on the page.
        // This is needed because with menuPortalTarget={document.body}, when a user scrolls the page
        // the options menu list remains fixed in position - https://github.com/JedWatson/react-select/issues/4088
        closeMenuOnScroll={(e: any) => e.srcElement && e.srcElement.className && e.srcElement.className.indexOf("bt-select") === -1}
        components={{ Option: CustomOption, ...components }}
        filterOption={createFilter({ ignoreAccents: false })}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        styles={styles}
        {...props}
      />
    </>
  );
};

export const AsyncSelect: FC<any> = ({ components, isError, center, ...props }) => {
  const styles = getSelectStyles(isError, center);

  return (
    <>
      <AsyncReactSelect
        classNamePrefix="bt-select"
        closeMenuOnScroll={(e: any) => e.srcElement && e.srcElement.className && e.srcElement.className.indexOf("bt-select") === -1}
        components={{ Option: CustomOption, ...components }}
        filterOption={createFilter({ ignoreAccents: false })}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        styles={styles}
        {...props}
      />
    </>
  );
};

export const AsyncCreatableSelect: FC<any> = ({ components, isError, center, ...props }) => {
  const styles = getSelectStyles(isError, center);

  return (
    <>
      <AsyncCreatableReactSelect
        classNamePrefix="bt-select"
        closeMenuOnScroll={(e: any) => e.srcElement && e.srcElement.className && e.srcElement.className.indexOf("bt-select") === -1}
        components={{ Option: CustomOption, ...components }}
        filterOption={createFilter({ ignoreAccents: false })}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        styles={styles}
        {...props}
      />
    </>
  );
};

export const CreatableSelect: FC<any> = ({ components, isError, center, ...props }) => {
  const styles = getSelectStyles(isError, center);

  return (
    <>
      <CreatableReactSelect
        classNamePrefix="bt-select"
        closeMenuOnScroll={(e: any) => e.srcElement && e.srcElement.className && e.srcElement.className.indexOf("bt-select") === -1}
        components={{ Option: CustomOption, ...components }}
        filterOption={createFilter({ ignoreAccents: false })}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        styles={styles}
        {...props}
      />
    </>
  );
};
