import L, { LatLngBoundsLiteral } from "leaflet";
import { AntPath } from "leaflet-ant-path";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import "leaflet.fullscreen/Control.FullScreen";
import "leaflet.fullscreen/Control.FullScreen.css";
import "leaflet.gridlayer.googlemutant/dist/Leaflet.GoogleMutant";
import "leaflet/dist/leaflet.css";
import moment from "moment";
import queryString from "query-string";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce";
import SensorMarker from "../../svgs/SensorMarker";
import { printLength } from "../../util/formatUnits";
import getMarkerIcon from "../../util/getMarkerIcon";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import leafletShowAllButton from "../../util/leafletShowAllButton";
import { INITIAL_VIEW_STATE } from "../../util/mapUtils";
import LeafletStyling from "../GlobalStyles/leaflet";
import { MapContainer, MapLoading } from "./styles";

declare module "leaflet" {
  export interface MapOptions {
    fullscreenControl?: boolean;
    gestureHandling?: boolean;
  }
}

const initActiveLayer = (layer: any) => {
  const validLayers = ["Map", "Terrain", "Satellite", "Hybrid"];
  if (layer && validLayers.includes(layer)) return layer;
  else return "Map";
};

const SensorHops: FC<any> = ({ map, setMap, hops, places, dataLoading, cursor, meta }) => {
  const navigate = useNavigate();

  const { color, map_style, short_datetime } = useContext(ThemeContext);

  const [queryParams] = useState<any>(queryString.parse(location.search));
  const [mapControl, setMapControl] = useState<any>(undefined);
  const [bounds, setBounds] = useState<any>(undefined);
  const [mapLayer, setMapLayer] = useState<any>(undefined);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [activeLayer, setActiveLayer] = useState<string>(initActiveLayer(queryParams.layer));

  const [currPosState, setCurrPosState] = useState<any>(undefined);
  const [prevPosState, setPrevPosState] = useState<any>(undefined);
  const [antPathState, setAntPathState] = useState<any>(undefined);
  // used to update the query parameters of the map bounds if they haven't changed in 500 milliseconds
  const [boundsValue] = useDebounce(bounds, 500);

  const [placeCluster, setPlaceCluster] = useState<any>(undefined);

  const [currLayer, setCurrLayer] = useState<any>(L.layerGroup());
  const [pathLayer, setPathLayer] = useState<any>(L.layerGroup());
  const [nearbyPlacesLayer, setNearbyPlacesLayer] = useState<any>(L.layerGroup());

  const showAllBtn = useRef<any>(null);

  // load the Google Maps JavaScript API and initialise the map on page mount
  useEffect(() => {
    initLeaflet();

    return () => {
      if (map) {
        map.off();
      }
    };
  }, []);

  // when the date range (and hence hops) changes, updated path
  useEffect(() => {
    if (map) {
      updatePlaces(map, places);
      updatePath(map, hops, cursor);
    }
  }, [map, hops, color, cursor, places]);

  // On theme change, update the map's baselayers and layer controls
  useEffect(() => {
    if (map) {
      createMapControl(map);
    }
  }, [map_style]);

  // Updates the query string when the boundsValue changes (which is a debounce value)
  useEffect(() => {
    if (boundsValue) updateQueryString(boundsValue, activeLayer);
  }, [boundsValue, activeLayer]);

  // Checks if the map container size changed and updates the map if so
  useEffect(() => {
    const mapDiv = document.getElementById("map");

    if (!mapDiv || !map) return;

    const observer = new ResizeObserver(() => {
      if (map) {
        map.invalidateSize();
      }
    });

    observer.observe(mapDiv);

    return () => {
      observer.unobserve(mapDiv);
      observer.disconnect();
    };
  }, [map]);

  const initLeaflet = () => {
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

    const newMap = L.map("map", {
      fullscreenControl: true,
      doubleClickZoom: true,
      worldCopyJump: true,
      attributionControl: false,
      maxBoundsViscosity: 1,
      maxBounds: new L.LatLngBounds(new L.LatLng(-90, -Infinity), new L.LatLng(90, Infinity)),
      center: [INITIAL_VIEW_STATE.latitude, INITIAL_VIEW_STATE.longitude],
      zoomControl: false,
      zoom: INITIAL_VIEW_STATE.zoom,
      minZoom: 3,
      gestureHandling: true,
    });

    L.control
      .zoom({
        position: "topleft",
      })
      .addTo(newMap);

    setBounds(newMap.getBounds());

    createMapControl(newMap);

    newMap.on("baselayerchange", (e: any) => {
      // If the user changes the base layer, update the state with new layer and name
      setActiveLayer(e.name);
      setMapLayer(e.layer);
    });

    newMap.on("dragend", (e: any) => {
      setBounds(newMap.getBounds());
    });

    newMap.on("zoomend", (e: any) => {
      setBounds(newMap.getBounds());
    });

    newMap.on("enterFullscreen", (e) => {
      setFullscreen(true);
    });

    newMap.on("exitFullscreen", (e) => {
      setFullscreen(false);
    });

    updatePath(newMap, hops, cursor);
    setMap(newMap);
  };

  // Triggered on moveend and zoomend events to update the query params and replace the history object
  const updateQueryString = (newBounds: any, newActiveLayer: any) => {
    if (newBounds && newBounds._southWest && newBounds._northEast) {
      const parsed = queryString.parse(location.search);

      const query = {
        sw_lat: newBounds._southWest.lat,
        sw_lng: newBounds._southWest.lng,
        ne_lat: newBounds._northEast.lat,
        ne_lng: newBounds._northEast.lng,
        layer: newActiveLayer,
      };

      const newQuery = {
        ...parsed,
        ...query,
      };

      const stringified = queryString.stringify(newQuery);

      window.history.replaceState(null, "", `${location.pathname}?${stringified}`);
    }
  };

  const updatePath = (myMap: any, path: any, cursor: any) => {
    // when map, hops, color or cursor changes remove the old path and markers and create updated ones
    if (currPosState) currPosState.remove();
    if (prevPosState) prevPosState.remove();
    if (antPathState) myMap.removeLayer(antPathState);
    if (pathLayer) pathLayer.clearLayers();
    if (currLayer) currLayer.clearLayers();

    // filter out any points greater than the timestamp of the point the user clicked on sensor logs
    const p = cursor !== Infinity ? path.filter((point: any) => point.ts < cursor) : path;

    if (p.length > 0) {
      // first create the marker for the sensors latest/current location
      const currPoint = p[p.length - 1];

      // create current position variable
      let currPos: any = undefined;

      // If the current point is not at a place and the accuracy is greater than 1000m, then only show an accuracy circle
      // else show a marker icon and an accuracy circle on hover
      if (!meta.place && currPoint.acc > 1000) {
        // set currPos to circle of accuracy radius
        currPos = L.circle([currPoint.lat, currPoint.lng], {
          radius: currPoint.acc >= 0 ? currPoint.acc : 0,
          color: color.success[3],
          interactive: true,
        }).addTo(myMap);

        // add event listeners for circle to show/hide popup, and zoom in to the circle's radius
        currPos.on("click", () => {
          myMap.fitBounds(currPos.getBounds());
        });

        currPos.bindPopup(createPositionPopup(currPoint));

        currPos.on("mouseover", function (e: any) {
          currPos.openPopup();
        });

        currPos.on("mouseout", function (e: any) {
          currPos.closePopup();
        });
      } else {
        // set currPos to marker icon
        currPos = L.marker([currPoint.lat, currPoint.lng], {
          icon: L.icon({
            className: "leaflet-data-marker",
            iconUrl: "data:image/svg+xml," + encodeURIComponent(renderToStaticMarkup(<SensorMarker fill={color.success[3]} />)),
            iconSize: [24, 32],
            iconAnchor: [12, 32],
            popupAnchor: [0, -32],
          }),
          zIndexOffset: 1000,
        });

        // create accuracy radius
        const currPosRadius = L.circle([currPoint.lat, currPoint.lng], {
          radius: currPoint.acc >= 0 ? currPoint.acc : 0,
          color: color.success[3],
          interactive: false,
        });

        // add radius to map
        const currPosRadiusLayer = L.layerGroup().addTo(myMap);

        // add event listeners for marker to show/hide radius, popup, and zoom in to the marker's radius
        currPos.on("click", () => {
          myMap.fitBounds(currPosRadius.getBounds());
        });

        currPos.bindPopup(createPositionPopup(currPoint));

        currPos.on("mouseover", function (e: any) {
          currPosRadiusLayer.clearLayers();
          currPos.openPopup();
          currPosRadiusLayer.addLayer(currPosRadius);
        });

        currPos.on("mouseout", function (e: any) {
          currPos.closePopup();
          currPosRadiusLayer.clearLayers();
        });
      }

      // next create all the circle markers for previous locations
      const prevPos = L.layerGroup();
      const points: LatLngBoundsLiteral = [];
      const radii = [];
      for (let i = 0; i < p.length; i++) {
        points.push([p[i].lat, p[i].lng]);
        radii.push(
          L.circle([p[i].lat, p[i].lng], {
            radius: p[i].acc >= 0 ? p[i].acc : 0,
          })
        );

        // Don't add current location since we already display the marker for this
        if (i !== p.length - 1) {
          // create circle marker
          const pos = L.circleMarker([p[i].lat, p[i].lng], {
            radius: 5,
            opacity: 1,
            weight: 0.5,
            color: "#000",
            fillOpacity: 1,
            fillColor: color.primary[2],
          });

          // create accuracy radius
          const prevPosRadius = L.circle([p[i].lat, p[i].lng], {
            radius: p[i].acc >= 0 ? p[i].acc : 0,
            color: color.primary[2],
            interactive: false,
          });

          // add radius to map
          const prevPosRadiusLayer = L.layerGroup().addTo(myMap);

          // add event listeners for marker to show/hide radius, popup, and zoom in to the marker's radius
          pos.on("click", () => {
            myMap.fitBounds(prevPosRadius.getBounds());
          });

          pos.bindPopup(createPositionPopup(p[i]));

          pos.on("mouseover", function (e) {
            pos.openPopup();
            prevPosRadiusLayer.clearLayers();
            prevPosRadiusLayer.addLayer(prevPosRadius);
          });

          pos.on("mouseout", function (e) {
            pos.closePopup();
            prevPosRadiusLayer.clearLayers();
          });

          // add previous location to layer to save in state
          prevPos.addLayer(pos);
        }
      }

      const radiiGroup = L.featureGroup(radii);
      radiiGroup.addTo(map);

      if (cursor !== Infinity) {
        myMap.fitBounds(radii[radii.length - 1].getBounds(), {
          padding: [50, 50],
          maxZoom: 18,
        });
      } else if (queryParams.ne_lat && queryParams.ne_lng && queryParams.sw_lat && queryParams.sw_lng) {
        myMap.fitBounds(L.latLngBounds([+queryParams.ne_lat, +queryParams.ne_lng], [+queryParams.sw_lat, +queryParams.sw_lng]));
      } else {
        myMap.fitBounds(radiiGroup.getBounds(), {
          padding: [50, 50],
          maxZoom: 18,
        });
      }

      radiiGroup.clearLayers();
      leafletShowAllButton(showAllBtn, p, myMap, setBounds);

      const antPath = new AntPath(points, {
        delay: 1500,
        dashArray: [10, 100],
        weight: 2,
        opacity: 1,
        color: color.primary[2],
        pulseColor: color.font_bold[2],
        paused: false,
        reverse: false,
        hardwareAccelerated: false,
        interactive: false,
      });

      // add everything to the map
      antPath.addTo(myMap);
      prevPos.addTo(myMap);
      currPos.addTo(myMap);

      antPath.addTo(pathLayer);
      prevPos.addTo(pathLayer);
      currPos.addTo(currLayer);

      // save everything to state so it can be removed later
      setAntPathState(antPath);
      setPrevPosState(prevPos);
      setCurrPosState(currPos);
    }

    setPathLayer(pathLayer);
    setCurrLayer(currLayer);
  };

  const updatePlaces = (map: any, places: any) => {
    // Remove the place cluster layer from the map if it exists
    if (map && placeCluster) {
      map.removeLayer(placeCluster);
    }

    if (nearbyPlacesLayer) nearbyPlacesLayer.clearLayers();

    const markers: any = [];

    if (places.length > 0) {
      const placeRadiusGroup = L.layerGroup().addTo(map);

      const newPlaceCluster = L.markerClusterGroup({
        spiderfyDistanceMultiplier: 1.5,
        spiderLegPolylineOptions: {
          weight: 1,
          color: color.font[2],
          opacity: 0.5,
        },
        polygonOptions: {
          color: color.nearbyPlace[2],
          fillColor: color.nearbyPlace[2],
        },
        iconCreateFunction: (cluster: any) => {
          return getMarkerIcon(color, cluster.getChildCount(), "nearbyPlaceCluster");
        },
      });

      for (let i = 0; i < places.length; i++) {
        const row = places[i];
        if (row.coordinates.lat !== undefined && row.coordinates.lng !== undefined) {
          const marker = L.marker(new L.LatLng(row.coordinates.lat, row.coordinates.lng), {
            title: row.name,
            icon: getMarkerIcon(color, 1, "nearbyPlace"),
            //@ts-ignore
            sensors: row.sensors,
          });

          const placeRadius = L.circle([row.coordinates.lat, row.coordinates.lng], {
            radius: row.radius,
            color: color.nearbyPlace[2],
          });
          marker.on("click", () => {
            placeRadiusGroup.clearLayers();
            placeRadiusGroup.addLayer(placeRadius);
          });
          marker.on("dblclick", () => {
            map.fitBounds(placeRadius.getBounds());
          });

          marker.bindPopup(createPlacePopup(row));
          newPlaceCluster.addLayer(marker);
          markers.push({
            lat: row.coordinates.lat,
            lng: row.coordinates.lng,
          });
        }
      }

      newPlaceCluster.addTo(nearbyPlacesLayer);
      placeRadiusGroup.addTo(nearbyPlacesLayer);

      map.on("click", () => {
        placeRadiusGroup.clearLayers();
      });

      map.addLayer(newPlaceCluster);
      setPlaceCluster(newPlaceCluster);
      setNearbyPlacesLayer(nearbyPlacesLayer);
    }
  };

  const createPositionPopup = (pos: any) => {
    const container = L.DomUtil.create("div");
    const ts = L.DomUtil.create("span", "popup-title");
    const accuracy = L.DomUtil.create("span", "popup-text");
    const place = L.DomUtil.create("span", "popup-text");

    ts.innerHTML = moment.unix(pos.ts).format(short_datetime);
    accuracy.innerHTML = `Accuracy: ${printLength(pos.acc >= 0 ? pos.acc : 0)}`;
    place.innerHTML = `Place: ${pos.place}`;

    container.appendChild(ts);
    container.appendChild(accuracy);
    if (pos.place) container.appendChild(place);

    return L.popup().setContent(container);
  };

  const createPlacePopup = (place: any) => {
    const container = L.DomUtil.create("div");
    const name = L.DomUtil.create("span", "popup-title-link");
    const placeGroup = L.DomUtil.create("span", "popup-text");
    const sensors = L.DomUtil.create("span", "popup-text");

    name.addEventListener("mouseup", (e: any) => {
      if (e.which === 2) window.open(`/places/${place.id}`, "_blank");
      else navigate(`/places/${place.id}`);
    });

    name.innerHTML = place.name;
    placeGroup.innerHTML = place.placeGroup ? `Place Type: ${place.placeGroup}` : "";
    sensors.innerHTML = `${kegsOrTrackers("Kegs", "Trackers")}: ${place.sensors}`;

    container.appendChild(name);
    container.appendChild(placeGroup);
    container.appendChild(sensors);

    return L.popup().setContent(container);
  };

  const createMapControl = (currentMap: any) => {
    const roadLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "roadmap",
      styles: map_style,
    });

    const terrainLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "terrain",
      styles: map_style,
    });

    const satelliteLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "satellite",
    });

    const hybridLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "hybrid",
    });

    const layers: any = {
      Map: roadLayer,
      Terrain: terrainLayer,
      Satellite: satelliteLayer,
      Hybrid: hybridLayer,
    };

    const overlays: any = {
      Path: pathLayer,
      "Current Location": currLayer,
      "Nearby Places": nearbyPlacesLayer,
    };

    const control = L.control.layers(layers, overlays, {
      sortLayers: true,
      position: "topright",
    });

    // Remove old baselayer/control from map if set in state
    if (mapControl) currentMap.removeControl(mapControl);
    if (mapLayer) currentMap.removeLayer(mapLayer);

    // Add new baselayer/control to map
    layers[activeLayer].addTo(currentMap);
    nearbyPlacesLayer.addTo(currentMap);
    pathLayer.addTo(currentMap);
    currLayer.addTo(currentMap);

    control.addTo(currentMap);

    // Store new baselayer/control in state
    setMapLayer(layers[activeLayer]);
    setMapControl(control);
  };

  return (
    <>
      <LeafletStyling fullscreen={fullscreen} />
      <MapContainer id="map"></MapContainer>
      {dataLoading && <MapLoading>Loading...</MapLoading>}
    </>
  );
};

export default SensorHops;
