import styled from "../Themes";

export const Menu = styled<any>(`div`)`
  position: absolute;
  display: flex;
  width: 400px;
  top: 57px;
  bottom: 0;
  left: ${({ open }) => (open ? "0" : "-400px")};
  z-index: 1000100;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  flex-direction: column;
  align-items: center;
  transition: ${({ theme }) => theme.transition.m};
  box-shadow: 6px 4px 6px -2px ${({ theme }) => theme.color_15.shadow[2]};
  border-right: 1px solid ${({ theme }) => theme.color.subtle_border[2]};

  @media (max-width: 950px) {
    right: 25px;
    width: auto;
    min-width: 0px;
    max-width: 400px;
  }

  @media (max-width: 500px) {
    right: 25px;
    width: auto;
    min-width: 0px;
    max-width: ${({ open }) => (open ? "75%" : "400px")};
  }
`;

export const Toggle = styled(`div`)`
  width: 25px;
  height: 70px;

  position: absolute;
  left: 400px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    background-color: ${({ theme }) => theme.color.panel_bg[2]};
    box-shadow: 6px 0 6px -2px ${({ theme }) => theme.color_15.shadow[2]};
    border-radius: 0 4px 4px 0;
    top: 0;
    bottom: 0;
    left: -1px;
    right: 0;
    transform: perspective(50px) rotateY(25deg);
    border-top: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
    border-right: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
    border-bottom: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
  }

  @media (max-width: 950px) {
    top: 50%;
    transform: translateY(-50%);
    left: auto;
    right: -25px;

    &::before {
      box-shadow: 6px 0 6px -2px ${({ theme }) => theme.color_15.shadow[2]};
      background-color: ${({ theme }) => theme.color.panel_bg[2]};
      border-radius: 0 4px 4px 0;
      transform: perspective(50px) rotateY(25deg);
    }
  }
`;

export const ToggleText = styled(`div`)`
  transform: rotate(90deg) translateZ(100px);
  font-size: 14px;
  font-weight: 500;
  padding: 2px 0 0 22px;
  cursor: inherit;
`;

export const Header = styled<any>(`div`)`
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
`;

export const Content = styled<any>(`div`)`
  position: absolute;
  top: 150px;
  bottom: 0;
  width: 100%;
  display: flex;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  transition: ${({ theme }) => theme.transition.m};
  border-top: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
`;

export const SelectContainer = styled<any>(`div`)`
  width: 185px;
  display: inline-block;
`;

export const ListItem = styled<any>(`div`)`
  cursor: pointer;
  width: 100%;
  color: ${({ theme }) => theme.color.font[2]};
  background: ${({ theme }) => theme.color.main_bg[2]};
  border: 1px solid ${({ theme }) => theme.color.font[2]};
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;

  &:hover {
    color: ${({ theme }) => theme.color.font_bold[2]};
    background-color: ${({ theme }) => theme.color.tooltip_hover[2]};
    border: 1px solid ${({ theme }) => theme.color.font_bold[2]};
  }
`;
