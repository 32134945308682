import styled from "../Themes";

export const Section = styled(`section`)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Title = styled(`span`)`
  text-transform: uppercase;
  letter-spacing: ${({ theme }) => theme.letterSpacing.s};
`;
