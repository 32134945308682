import styled from "../Themes";

export const Bar = styled<any>(`div`)`
  position: absolute;
  width: 100%;
  height: 57px;
  padding: 8px;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  border-bottom: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
  z-index: 1;

  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

export const DashboardContainer = styled(`div`)`
  position: relative;
  max-width: 1800px;
  min-height: 100px;
  margin: 0 auto 12px auto;

  @media (max-width: 1800px) {
    width: 100%;
  }
`;

export const DashboardElement = styled(`div`)`
  padding: 12px;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  box-shadow: ${({ theme }) => theme.boxShadow.s};
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.body};

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);

  @media (max-width: 768px) {
    padding: 12px;
    margin: 0 0 12px;
  }
`;

export const DashboardElementText = styled(`span`)`
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
`;

export const TopContainer = styled(`div`)`
  position: relative;
  max-width: 1714px;
  min-height: 100px;
  padding: 24px;
  margin: 0 auto 24px auto;

  @media (max-width: 1200px) {
    width: 85%;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 12px;
    margin-bottom: 12px;
  }
`;

export const FlexRow = styled(`div`)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-end;
`;

export const TopItem = styled(`div`)`
  width: 550px;

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);
`;

export const ChartItem = styled(`div`)`
  width: 845px;
  height: 30vh;
  padding: 24px;
  margin: 0 12px 24px;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  box-shadow: ${({ theme }) => theme.boxShadow.s};
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.body};
  /* overflow: hidden; */

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);

  @media (max-width: 1200px) {
    width: 85%;
    min-width: 210px;
  }

  @media (max-height: 1000px) {
    height: 360px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 12px;
    margin: 0 0 12px;
  }
`;

export const TableContainer = styled(`div`)`
  width: 1714px;
  padding: 24px;
  margin-bottom: 24px;
  position: relative;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  box-shadow: ${({ theme }) => theme.boxShadow.s};
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.body};

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);

  @media (max-width: 1714px) {
    width: 85%;
    min-width: 210px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 12px;
    margin-bottom: 12px;
  }
`;

export const FleetContainer = styled(`div`)`
  width: 1714px;
  height: 30vh;
  padding: 24px;
  margin-bottom: 24px;
  position: relative;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  box-shadow: ${({ theme }) => theme.boxShadow.s};
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.body};

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);

  @media (max-width: 1714px) {
    width: 85%;
    min-width: 210px;
  }

  @media (max-height: 1000px) {
    height: 360px;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 12px;
    margin-bottom: 12px;
  }
`;

export const ChartContainer = styled(`div`)`
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
`;

export const Container = styled(`div`)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  height: 100%;
`;

export const ValueContainer = styled(`div`)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 18px;
  overflow: hidden;
  height: 100%;
  justify-content: space-evenly;
`;

export const IconContainer = styled(`div`)`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
  aspect-ratio: 1 / 1;
`;

export const Header = styled(`span`)`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.font[2]};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Value = styled(`span`)`
  font-size: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.font[2]};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const DateText = styled(`span`)`
  font-size: 11px;
  font-weight: 400;
  color: ${({ theme }) => theme.color.font[0]};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Icon = styled(`div`)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

export const InfoIconContainer = styled(`div`)`
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 8px;
  right: 8px;
`;
