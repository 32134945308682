import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { fetchBinaryAnalyticsDashboard } from "../../services/binaryAnalyticsDashboard";
import errToStr from "../../util/errToStr";
import LoadingContainer from "../LoadingContainer";
import { Section, GitLink } from "./styles";

const VersionScreen: FC<any> = () => {
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [uiGitLink] = useState<string>(`https://github.com/BinaryBeer/binary-tech-front-end/commit/${process.env.REACT_APP_GIT_SHA}`);
  const [apiGitLink, setApiGitLink] = useState<string>("");

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchBinaryAnalyticsDashboard(source)
      .then((response) => {
        setApiGitLink(`https://github.com/BinaryBeer/Binary-API/commit/${response.g}`);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  return (
    <LoadingContainer loading={dataLoading} err={dataErr}>
      <Section>
        <GitLink>
          <a href={uiGitLink}>{uiGitLink}</a>
        </GitLink>
        <GitLink>
          <a href={apiGitLink}>{apiGitLink}</a>
        </GitLink>
      </Section>
    </LoadingContainer>
  );
};

export default VersionScreen;
