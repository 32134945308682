import React, { FC, useState, useEffect } from "react";
import { ModalFilterContainer, ModalFilterHeader } from "./styles";
import { AsyncSelect } from "../Select";
import { fetchAutoComplete } from "../../services/autoComplete";

// Sets the initial values of the selected place types if there are URL query parameters.
// If there are none we use the default initial values []
const getValues = (placeFilters: any) => {
  const { placeGroups } = placeFilters;

  const placeGroupArray = placeGroups ? placeGroups.split(",") : [];

  if (placeGroupArray.length > 0) {
    return placeGroupArray.map((label: string) => ({ value: label, label }));
  }

  return [];
};

const PlaceGroups: FC<any> = ({ placeFilters, setModalQuery }) => {
  const [values, setValues] = useState<any>(getValues(placeFilters));

  // This is to update the values and button state when a user changes the query
  // parameters (e.g. when a user navigates using the back/forward buttons).
  // Each time the placeFilters change (whenever the place query parameters change),
  // if the new values don't match the current values, update the values and button state
  useEffect(() => {
    const newValues = getValues(placeFilters);
    if (JSON.stringify(values) !== JSON.stringify(newValues)) {
      setValues(newValues);
    }
  }, [placeFilters]);

  // On initial render, sets the modal query string state in
  // the parent component to the initial values
  useEffect(() => {
    updateModalQuery(values);
  }, []);

  const updateModalQuery = (values: any) => {
    if (values.length > 0) {
      const placeGroups = values.map((group: any) => group.value, []);
      setModalQuery((prev: any) => ({
        ...prev,
        placeGroups: placeGroups.join(","),
      }));
    } else {
      setModalQuery((prev: any) => ({
        ...prev,
        placeGroups: undefined,
      }));
    }
  };

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      // Set value the same as label (since label is unique too) so we can save the filter in the url params
      const placeGroups = response.map((group: any) => ({ ...group, value: group.label }));
      callback(placeGroups);
    });
  };

  return (
    <ModalFilterContainer>
      <ModalFilterHeader>Place Types</ModalFilterHeader>
      <AsyncSelect
        name="placeGroup"
        defaultOptions={true}
        closeMenuOnSelect={false}
        isClearable={true}
        isMulti={true}
        isSearchable={true}
        value={values}
        loadOptions={(inputValue: any, callback: any) => loadOptions("placetypes", inputValue, callback)}
        onChange={(selected: any) => {
          if (selected) {
            setValues(selected);
            updateModalQuery(selected);
          } else {
            setValues([]);
            updateModalQuery([]);
          }
        }}
        placeholder="Select..."
      />
    </ModalFilterContainer>
  );
};

export default PlaceGroups;
