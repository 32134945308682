import React, { FC } from "react";
import { PageContainer } from "../PageStyles";
import BeersTable from "../BeersTable";
import PageTitle from "../PageTitle";
import { Panel } from "../Panel";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import BeerBatchesTable from "../BeerBatchesTable";

const BeersScreen: FC<any> = () => {
  return (
    <>
      <Tabs>
        <TabList className="react-tabs__tab-list__background_no-margin">
          <Tab>Beer Batches</Tab>
          <Tab>Beers</Tab>
        </TabList>
        <TabPanel>
          <PageContainer>
            <Panel>
              <BeerBatchesTable />
            </Panel>
          </PageContainer>
        </TabPanel>
        <TabPanel>
          <PageContainer>
            <Panel>
              <BeersTable />
            </Panel>
          </PageContainer>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default BeersScreen;
