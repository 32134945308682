import styled from "../Themes";

export const SampleTooltipContainer = styled<any>(`div`)`
  cursor: pointer;
  position: absolute;
  z-index: 1;
  left: 45px;
  top: 32px;

  @media (max-width: 768px) {
    left: 58px;
    top: 85px;
  }
`;
