import wiliotTag from "../imgs/wiliot-tag-front.png";
import CA1Icon from "../svgs/CA1Icon";
import CA2Icon from "../svgs/CA2Icon";
import CH1Icon from "../svgs/CH1Icon";
import CH2Icon from "../svgs/CH2Icon";
import EngineIcon from "../svgs/EngineIcon";
import IKegKegIcon from "../svgs/IKegKegIcon";
import KegLinkIcon from "../svgs/KegLinkIcon";
import QrScanIcon from "../svgs/QrScanIcon";

const getSensorIcon = (productId: number, productName: string, color: any) => {
  switch (productId) {
    // QR Sticker
    case 0:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <QrScanIcon outline={color.font_bold[2]} />
        </div>
      );

    // Cicada
    case 1:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <CA1Icon />
        </div>
      );

    // Cicada Pro
    case 2:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <CA2Icon />
        </div>
      );

    // NO LONGER USED
    case 3:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <CH1Icon />
        </div>
      );

    // NO LONGER USED
    case 4:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <CH2Icon />
        </div>
      );

    // NO LONGER USED
    case 5:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <EngineIcon fill={color.font_bold[2]} />
        </div>
      );

    // Keglink
    case 6:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <KegLinkIcon />
        </div>
      );

    // Keglink Pro
    case 7:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <KegLinkIcon />
        </div>
      );

    // Keglink Patriot
    case 8:
      return (
        <div
          title={productName}
          style={{
            width: "140px",
            height: "70px",
          }}
        >
          <KegLinkIcon />
        </div>
      );

    // Keglink Demo
    case 9:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <KegLinkIcon />
        </div>
      );

    // iKeg
    case 10:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <IKegKegIcon />
        </div>
      );

    // Wiliot
    case 19:
      return (
        <div
          title={productName}
          style={{
            width: "80px",
          }}
        >
          <img width="100%" src={wiliotTag} alt="Wiliot" />
        </div>
      );

    // Cicada Sentinel
    case 22:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <CH1Icon />
        </div>
      );

    default:
      return (
        <div
          title={productName}
          style={{
            width: "60px",
          }}
        >
          <QrScanIcon outline={color.font_bold[2]} />
        </div>
      );
  }
};

export default getSensorIcon;
