import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { getAccount, saveAccount, updateOrganisationId } from "../../services/localStorage";
import { postDemo } from "../../services/demo";
import { fetchEditAccount } from "../../services/editAccount";
import { useNavigate } from "react-router-dom";

const ConfirmJoinDemoModal: FC<any> = ({ modalOpen, setModalOpen }) => {
  const navigate = useNavigate();

  const [accountInfo] = useState<any>(getAccount());

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [nextRoute, setNextRoute] = useState<any>(undefined);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // on submit add the user to the demo org then switch to it
  const handleSubmit = () => {
    const body = { email: accountInfo.email };

    setSubmitting(true);
    postDemo(source, body)
      .then((response) => {
        handleOrgChange(response.id);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  const handleOrgChange = (id: string) => {
    updateOrganisationId(id);

    fetchEditAccount(source)
      .then((response) => {
        saveAccount(response);
        setNextRoute("/");
        setSubmitting(false);
        const accountInfoUpdatedEvent = new Event("accountinfoupdated");
        document.dispatchEvent(accountInfoUpdatedEvent);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          updateOrganisationId(undefined);
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  if (nextRoute) {
    navigate(nextRoute);
  }

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      title="Join Demo Organisation"
      showSuccessIcon={false}
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Yes"
      cancelBtnText="No"
      body={
        <LoadingContainer loading={submitting}>
          <span>Are you sure you want to join the demo organisation?</span>
        </LoadingContainer>
      }
    />
  );
};

export default ConfirmJoinDemoModal;
