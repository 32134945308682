import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IconContext } from "react-icons";
import { MdEdit } from "react-icons/md";
import { TbDownload } from "react-icons/tb";
import { useLocation, useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ThemeContext } from "styled-components";
import { hideAll } from "tippy.js";
import { fetchPlaceMeta } from "../../services/placeMeta";
import MoreIcon from "../../svgs/MoreIcon";
import { isBinaryBeer } from "../../util/checkDomain";
import errToStr from "../../util/errToStr";
import { printFixedLength, printFixedTemp, printLengthUnit, printTempUnit } from "../../util/formatUnits";
import getParameterByName from "../../util/getParamByName";
import isSafari from "../../util/isSafari";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import useWindowSize from "../../util/useWindowSize";
import BeerBatchesTable from "../BeerBatchesTable";
import { OutlineBtn } from "../Buttons";
import ChartHeading from "../ChartHeading";
import DateRangeList from "../DateRangeList";
import EditPlaceModal from "../EditPlaceModal";
import PageBreadcrumbs from "../PageBreadcrumbs";
import { PageContainer } from "../PageStyles";
import PlaceAlertsTable from "../PlaceAlertsTable";
import PlaceAllocationsTable from "../PlaceAllocationsTable";
import PlaceCsvDownloadModal from "../PlaceCsvDownloadModal";
import PlaceMap from "../PlaceMap";
import PlaceMeta from "../PlaceMeta";
import PlaceMetrics from "../PlaceMetrics";
import PlaceProductsTable from "../PlaceProductsTable";
import PlaceTrackerChart from "../PlaceTrackerChart";
import PlaceTrackersArrivedTable from "../PlaceTrackersArrivedTable";
import PlaceTrackersDepartedTable from "../PlaceTrackersDepartedTable";
import PlaceTrackersHereTable from "../PlaceTrackersHereTable";
import Tooltip from "../Tooltip";
import { MenuButton, MenuList } from "../Tooltip/styles";
import UnsupportedBrowser from "../UnsupportedBrowser";
import { FlexRow, FlexRowColumn, KegDetailsWrapper } from "./styles";

const formatDataToCsv = ({ logs, series, short_date, time }: any) => {
  if (logs.length > 0) {
    const keys = [];
    const headings: any = [];

    // for each series we have push into keys array
    for (const [key, value] of Object.entries(series)) {
      if (value === true) keys.push(key);
    }

    // check series in keys array and push heading for those that exist
    if (keys.includes("date")) {
      headings.push("Date");
      headings.push("Local Date");
      headings.push("Local Time");
    }
    if (keys.includes("sensorId")) headings.push("Tracker ID");
    if (keys.includes("temperature")) headings.push(`Temperature (${printTempUnit()})`);
    if (keys.includes("light")) headings.push("Light");
    if (keys.includes("orientation")) headings.push("Orientation");
    if (keys.includes("freshness")) headings.push("Freshness (%)");
    if (keys.includes("moved")) headings.push("Moved");
    if (keys.includes("latitude")) headings.push("Latitude");
    if (keys.includes("longitude")) headings.push("Longitude");
    if (keys.includes("locationAccuracy")) headings.push(`Location Accuracy (${printLengthUnit()})`);

    const rows = [headings];

    for (let i = 0; i < logs.length; i++) {
      const row = [];

      // for each series check if exists in row and format accordingly
      for (let j = 0; j < keys.length; j++) {
        const key = keys[j];

        if (logs[i][key] !== undefined) {
          if (key === "date") {
            row.push(moment.unix(logs[i][key]).format());
            row.push(moment.unix(logs[i][key]).format(short_date));
            row.push(moment.unix(logs[i][key]).format(time));
          } else if (key === "orientation") row.push(logs[i][key] === "U" ? "Up" : "Down");
          else if (key === "freshness") row.push(`${+logs[i][key].toFixed(1)}`);
          else if (key === "moved") row.push(logs[i][key] ? "True" : "False");
          else if (key === "temperature") row.push(printFixedTemp(logs[i][key]));
          else if (key === "locationAccuracy") row.push(printFixedLength(logs[i][key]));
          else row.push(logs[i][key]);
        } else {
          row.push("");
        }
      }

      rows.push(row);
    }

    return rows;
  } else {
    return [];
  }
};

const PlaceScreen: FC<any> = () => {
  const { color, short_date } = useContext(ThemeContext);

  const { width } = useWindowSize();

  const location = useLocation();
  const { id = "0" } = useParams();

  // Gets details from URL query parameters if available to set date range
  const startDateParam = getParameterByName("start", location.search);
  const endDateParam = getParameterByName("end", location.search);
  const dateLabelParam = getParameterByName("label", location.search);

  const [filterDates, setFilterDates] = useState<any>({
    start: startDateParam !== null ? moment.unix(+startDateParam) : moment().subtract(3, "months"),
    end: endDateParam !== null ? moment.unix(+endDateParam) : moment(),
    dateLabel: startDateParam !== null || endDateParam !== null ? dateLabelParam : "Last 3 Months",
    now: endDateParam !== null ? (moment.unix(+endDateParam).isSame(moment(), "minute") ? true : false) : true,
  });

  const [map, setMap] = useState<any>(undefined);
  const [meta, setMeta] = useState<any>({});
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [dataErr, setDataErr] = useState<string>("");

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [csvModalOpen, setCsvModalOpen] = useState<boolean>(false);

  const [tabIndex, setTabIndex] = useState(0);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const fetchMeta = useCallback(() => {
    // If end date is undefined set to current date
    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined ? filterDates.end.unix() : moment().unix(),
    };

    setDataLoading(true);
    setDataErr("");

    fetchPlaceMeta(source, id, dates)
      .then((response) => {
        setMeta(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setMeta(errToStr(err));
          setDataLoading(false);
        }
      });
  }, [id, filterDates]);

  useEffect(() => {
    fetchMeta();
  }, [id, filterDates, fetchMeta]);

  useEffect(() => {
    const startDateParam = getParameterByName("start", location.search);
    const endDateParam = getParameterByName("end", location.search);
    const dateLabelParam = getParameterByName("label", location.search);

    setFilterDates({
      start: startDateParam !== null ? moment.unix(+startDateParam) : moment().subtract(3, "months"),
      end: endDateParam !== null ? moment.unix(+endDateParam) : moment(),
      dateLabel: startDateParam !== null || endDateParam !== null ? dateLabelParam : "Last 3 Months",
      now: endDateParam !== null ? (moment.unix(+endDateParam).isSame(moment(), "minute") ? true : false) : true,
    });
  }, [location]);

  const trackersHereNowTitle = useMemo<string>(
    () => `${kegsOrTrackers("Kegs", "Trackers")} Here ${filterDates.now ? "Now" : `On ${filterDates.end.format(short_date)}`}`,
    [kegsOrTrackers, filterDates]
  );

  const trackersArrivedTitle = useMemo<string>(
    () =>
      `${kegsOrTrackers("Kegs", "Trackers")} Arrived ${
        filterDates.now
          ? `Since ${filterDates.start.format(short_date)}`
          : `Between ${filterDates.start.format(short_date)} - ${filterDates.end.format(short_date)}`
      }`,
    [kegsOrTrackers, filterDates]
  );

  const trackersDepartedTitle = useMemo<string>(
    () =>
      `${kegsOrTrackers("Kegs", "Trackers")} Departed ${
        filterDates.now
          ? `Since ${filterDates.start.format(short_date)}`
          : `Between ${filterDates.start.format(short_date)} - ${filterDates.end.format(short_date)}`
      }`,
    [kegsOrTrackers, filterDates]
  );

  return (
    <>
      <PageBreadcrumbs
        height="57px"
        prevRoutes={[{ slug: "/places", title: "Places" }]}
        currRoute={meta.placeName || id}
        rightContent={
          width < 768 ? (
            <>
              <DateRangeList
                filterDates={filterDates}
                setFilterDates={setFilterDates}
                minDate={moment.unix(meta.dateCreated)}
                maxDate={moment()}
                minWidth={768}
                enableTime={false}
              />
              <Tooltip
                maxWidth="none"
                theme="binary-no-padding"
                content={
                  <MenuList>
                    <MenuButton
                      onClick={() => {
                        setEditModalOpen(true);
                        hideAll();
                      }}
                    >
                      Edit Place
                    </MenuButton>
                    <MenuButton
                      onClick={() => {
                        setCsvModalOpen(true);
                        hideAll();
                      }}
                    >
                      Download CSV
                    </MenuButton>
                  </MenuList>
                }
                interactive={true}
                touch={true}
                appendTo={document.body}
                trigger="click"
                placement="bottom-start"
              >
                <div style={{ display: "inline-block", margin: "12px 6px" }}>
                  <Tooltip content="More" hideOnClick={true} trigger="mouseenter">
                    <OutlineBtn key="edit" style={{ height: "40px", minWidth: "unset" }} padding="0 6px">
                      <div
                        style={{
                          padding: "4px",
                          width: "26px",
                          height: "26px",
                          lineHeight: "0",
                          margin: "0 2px",
                        }}
                      >
                        <MoreIcon fill={color.font_bold[2]} />
                      </div>
                    </OutlineBtn>
                  </Tooltip>
                </div>
              </Tooltip>
            </>
          ) : (
            <>
              <DateRangeList
                filterDates={filterDates}
                setFilterDates={setFilterDates}
                minDate={moment.unix(meta.dateCreated)}
                maxDate={moment()}
                minWidth={768}
                enableTime={false}
              />
              <Tooltip content="Download CSV">
                <OutlineBtn
                  style={{
                    height: "40px",
                    margin: "0 6px",
                    minWidth: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  key="downloadCsv"
                  onClick={() => setCsvModalOpen(true)}
                >
                  <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                    <TbDownload />
                  </IconContext.Provider>
                </OutlineBtn>
              </Tooltip>
              <Tooltip content="Edit Place">
                <OutlineBtn
                  style={{
                    height: "40px",
                    margin: "0 6px",
                    minWidth: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  key="edit"
                  onClick={() => setEditModalOpen(true)}
                >
                  <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                    <MdEdit />
                  </IconContext.Provider>
                </OutlineBtn>
              </Tooltip>
            </>
          )
        }
      />
      <PageContainer top="57px" slim={true}>
        <FlexRow>
          <FlexRowColumn>
            <PlaceMeta id={id} fetchMeta={fetchMeta} data={meta} dataLoading={dataLoading} dataErr={dataErr} />
          </FlexRowColumn>
          <FlexRowColumn>
            <PlaceMetrics id={id} filterDates={filterDates} meta={meta} />
          </FlexRowColumn>
          <FlexRowColumn style={{ minHeight: "300px" }}>
            <PlaceMap id={id} filterDates={filterDates} map={map} setMap={setMap} meta={meta} />
          </FlexRowColumn>
        </FlexRow>
        <FlexRow>
          <KegDetailsWrapper>
            <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
              <TabList>
                <Tab>{trackersHereNowTitle}</Tab>
                <Tab>{trackersArrivedTitle}</Tab>
                <Tab>{trackersDepartedTitle}</Tab>
              </TabList>
              <TabPanel>
                <PlaceTrackersHereTable id={id} filterDates={filterDates} meta={meta} />
              </TabPanel>
              <TabPanel>
                <PlaceTrackersArrivedTable id={id} filterDates={filterDates} meta={meta} />
              </TabPanel>
              <TabPanel>
                <PlaceTrackersDepartedTable id={id} filterDates={filterDates} meta={meta} />
              </TabPanel>
            </Tabs>
          </KegDetailsWrapper>
        </FlexRow>
        {meta.placeAllocations > 0 && (
          <FlexRow style={{ flexDirection: "column" }}>
            <KegDetailsWrapper>
              <ChartHeading style={{ marginBottom: "8px" }}>Place Allocations</ChartHeading>
              <PlaceAllocationsTable placeId={id} />
            </KegDetailsWrapper>
          </FlexRow>
        )}
        {isBinaryBeer() && meta.fillsBeerKegs && (
          <FlexRow>
            <KegDetailsWrapper>
              <ChartHeading style={{ marginBottom: "8px" }}>Batches Brewed Here</ChartHeading>
              <BeerBatchesTable placeId={id} />
            </KegDetailsWrapper>
          </FlexRow>
        )}
        {isBinaryBeer() && (
          <FlexRow>
            <KegDetailsWrapper>
              <ChartHeading style={{ marginBottom: "8px" }}>Products</ChartHeading>
              <PlaceProductsTable id={id} filterDates={filterDates} meta={meta} />
            </KegDetailsWrapper>
          </FlexRow>
        )}
        <FlexRow>{isSafari() ? <UnsupportedBrowser /> : <PlaceTrackerChart id={id} filterDates={filterDates} meta={meta} />}</FlexRow>
        <FlexRow>
          <KegDetailsWrapper>
            <ChartHeading style={{ marginBottom: "8px" }}>Alerts</ChartHeading>
            <PlaceAlertsTable placeId={id} meta={meta} />
          </KegDetailsWrapper>
        </FlexRow>
      </PageContainer>
      {editModalOpen && <EditPlaceModal id={id} updateData={fetchMeta} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
      {csvModalOpen && <PlaceCsvDownloadModal modalOpen={csvModalOpen} setModalOpen={setCsvModalOpen} id={id} place={meta} formatDataToCsv={formatDataToCsv} />}
    </>
  );
};

export default PlaceScreen;
