import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 234 360.04" {...props}>
      <rect x={3} y={3} width={228} height={354.04} rx={108.81} fill="#fff" />
      <path d="M122.19 6.02A106.15 106.15 0 01228 111.81v136.42a106.15 106.15 0 01-105.81 105.79h-10.38A106.15 106.15 0 016 248.23V111.81A106.15 106.15 0 01111.81 6.02h10.38m0-6h-10.38C50.31.02 0 50.32 0 111.81v136.42c0 61.49 50.31 111.79 111.81 111.79h10.38c61.5 0 111.81-50.3 111.81-111.79V111.81C234 50.32 183.69.02 122.19.02z" />
      <rect x={37.48} y={49.13} width={159.04} height={261.77} rx={73.39} fill="#ddd" />
      <path d="M123.13 52.14a70.61 70.61 0 0170.39 70.38v115a70.61 70.61 0 01-70.39 70.38h-12.26a70.61 70.61 0 01-70.39-70.38v-115a70.61 70.61 0 0170.39-70.38h12.26m0-6h-12.26a76.61 76.61 0 00-76.39 76.38v115a76.61 76.61 0 0076.39 76.38h12.26a76.61 76.61 0 0076.39-76.38v-115a76.61 76.61 0 00-76.39-76.38z" />
      <rect x={105.59} y={223.77} width={22.83} height={41.85} rx={11.41} />
      <path d="M151.14 127.14a20.48 20.48 0 00-8.67-14.12 21.76 21.76 0 00-5.94-2.88 20.67 20.67 0 00-6.38-.72h-.06a18.84 18.84 0 00-11.9 4.24 46.13 46.13 0 00-4.19 3.86l-1 1c-1.43 1.41-2.87 2.86-4.26 4.26l-.56.55a.38.38 0 01-.49.13 7 7 0 103.31 10.36 6.7 6.7 0 00.75-6.18.49.49 0 01.14-.66l4.84-4.83 3-3a15.32 15.32 0 014.22-3 15.57 15.57 0 016.65-1.54 15.49 15.49 0 014.34.65 15.26 15.26 0 0111 15.65 14.56 14.56 0 01-5.91 11.25c-4.89 3.67-10.28 4.26-16 1.76a.89.89 0 00-1.14.22l-1.68 1.69-.71.7c-.44.44-.66.66-.61 1s.32.44.88.73a20.71 20.71 0 0012.36 2.3A20 20 0 00144 145.8a20.58 20.58 0 007.35-15.8 21.85 21.85 0 00-.21-2.86zm-48.61 2.88a2.65 2.65 0 01.77-1.88 2.72 2.72 0 011.93-.76 2.58 2.58 0 012.59 2.62 2.66 2.66 0 01-2.65 2.66 2.65 2.65 0 01-2.64-2.64z" />
      <path d="M135.75 129.09a7.08 7.08 0 00-3.05-4.93 7 7 0 00-10.07 2.55 7.09 7.09 0 00-.38 5.67.46.46 0 01-.15.59l-1.38 1.37c-2 2-4.09 4-6.08 6.09a15.49 15.49 0 01-10.58 4.9 15.37 15.37 0 01-16-17.1 14.46 14.46 0 014.83-9.55 15.33 15.33 0 0113-3.9 16.42 16.42 0 014 1.21l.16.06a.76.76 0 00.94-.11c.95-1 1.92-1.93 2.89-2.88a.68.68 0 00.23-.53.69.69 0 00-.36-.46 23.07 23.07 0 00-4.31-1.84 16.15 16.15 0 00-4.28-.68h-.2a19.69 19.69 0 00-3.52 0 22.33 22.33 0 00-4.31.8 20 20 0 00-7.69 4.44 20.41 20.41 0 00-6.69 15 20.9 20.9 0 002.52 10.23 20.59 20.59 0 0018.09 10.61c.49 0 1 0 1.51-.05a18.26 18.26 0 006.54-1.54 27.34 27.34 0 006.94-4.95c1.83-1.68 3.59-3.47 5.3-5.2l2.1-2.11a.66.66 0 01.82-.19 7 7 0 009.18-7.51zm-4.31.91a2.6 2.6 0 01-2.66 2.63 2.59 2.59 0 01-1.84-.79 2.72 2.72 0 01-.78-1.88 2.61 2.61 0 012.69-2.61 2.53 2.53 0 012.59 2.67z" />
    </svg>
  );
}

export default SvgComponent;
