import React, { FC } from "react";
import { InfoModal } from "../Modal";

const PhotoModal: FC<any> = ({ modalOpen, setModalOpen, name, src }) => {
  return (
    <InfoModal
      isOpen={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      okayBtnText="Close"
      okayBtnProps={{ width: "100%" }}
      title={name}
      body={
        <div style={{ display: "flex", flexDirection: "column" }}>
          <img src={src} />
        </div>
      }
    />
  );
};

export default PhotoModal;
