import styled from "../Themes";

export const MapContainer = styled(`div`)`
  height: 100%;
  width: 100%;
`;

export const MapLoading = styled(`div`)`
  position: absolute;
  width: 100px;
  padding: 4px;
  background: ${({ theme }) => theme.color.main_bg[2]};
  border: 1px solid ${({ theme }) => theme.color.main_fg[2]};
  border-radius: 3px;
  top: 16px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 1000;
  text-align: center;
`;
