import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { PageContainer } from "../PageStyles";
import axios, { CancelTokenSource } from "axios";
import { fetchAlertDetails } from "../../services/alertDetails";
import errToStr from "../../util/errToStr";
import { FlexRow, HeadingWrapper, LogoContainer, MetaWrapper, Section } from "./styles";
import { getLogo } from "../../util/getLogo";
import { ThemeContext } from "styled-components";
import StateContext from "../StateContext";
import Bold from "../Bold";
import { Link, useParams } from "react-router-dom";
import { kegOrTracker } from "../../util/kegOrTracker";
import UnsubscribeAlertModal from "../UnsubscribeAlertModal";
import LoadingContainer from "../LoadingContainer";

const AlertScreen: FC = () => {
  const { id } = useParams();

  const { color } = useContext(ThemeContext);
  const { theme } = useContext(StateContext);

  const [data, setData] = useState<any>({});
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [unsubscribeModalOpen, setUnsubscribeModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const fetchData = useCallback(() => {
    if (id !== undefined) {
      setDataLoading(true);
      setDataErr("");

      fetchAlertDetails(source, id)
        .then((response) => {
          setData(response);
          setDataLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setDataErr(errToStr(err));
            setDataLoading(false);
          }
        });
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <div style={{ overflow: "auto", width: "100%", height: "100%" }}>
      <PageContainer>
        <FlexRow>
          <HeadingWrapper>
            <LogoContainer>{getLogo(color, theme)}</LogoContainer>
          </HeadingWrapper>
          <MetaWrapper>
            <LoadingContainer loading={dataLoading} err={dataErr}>
              <Section style={{ minHeight: "100px" }}>
                {data.alertName && <Bold style={{ marginBottom: "12px" }}>{data.alertName}</Bold>}
                {data.message}
                {data.sensorId && (
                  <span>
                    {kegOrTracker("Keg", "Tracker")}:{" "}
                    <Link title={data.sensorId} to={`/${kegOrTracker("kegs", "trackers")}/${data.sensorId}`}>
                      {data.sensorId}
                    </Link>
                  </span>
                )}
                {data.placeId && (
                  <span>
                    Place :{" "}
                    <Link title={data.placeName} to={`/places/${data.placeId}`}>
                      {data.placeName}
                    </Link>
                  </span>
                )}
                {data.message && (
                  <span
                    style={{ fontSize: "12px", marginTop: "32px", cursor: "pointer", textDecoration: "underline" }}
                    onClick={() => setUnsubscribeModalOpen(true)}
                  >
                    Unsubscribe
                  </span>
                )}
              </Section>
            </LoadingContainer>
          </MetaWrapper>
        </FlexRow>
      </PageContainer>
      {unsubscribeModalOpen && <UnsubscribeAlertModal token={data.token} modalOpen={unsubscribeModalOpen} setModalOpen={setUnsubscribeModalOpen} />}
    </div>
  );
};

export default AlertScreen;
