// src/utils/dateUtils.ts
import { DateTime } from "luxon";

/**
 * Parses an ISO 8601 UTC datetime string and returns a Luxon DateTime object in the user's local timezone.
 *
 * @param isoString - The ISO 8601 datetime string in UTC.
 * @returns A Luxon DateTime object in the local timezone or null if invalid.
 */
export const parseUTCToLocal = (isoString: string): DateTime | null => {
  const dateTime = DateTime.fromISO(isoString, { zone: "utc" });
  return dateTime.isValid ? dateTime.toLocal() : null;
};

/**
 * Formats a Luxon DateTime object to a human-readable date string.
 * Accepts either predefined format types or a custom format string.
 *
 * @param dateTime - The Luxon DateTime object to format.
 * @param formatType - The format type ('DATE_SHORT', 'DATE_MED', 'DATE_FULL', 'DATETIME_SHORT', 'DATETIME_MED', 'DATETIME_FULL') or a custom format string.
 * @returns The formatted date string or 'Invalid Date' if the input is invalid.
 */
export const formatDate = (dateTime: DateTime | null, formatType = "DATETIME_MED") => {
  if (!dateTime || !dateTime.isValid) return "Invalid Date";

  switch (formatType) {
    case "DATE_SHORT":
      return dateTime.toLocaleString(DateTime.DATE_SHORT);
    case "DATE_MED":
      return dateTime.toLocaleString(DateTime.DATE_MED);
    case "DATE_FULL":
      return dateTime.toLocaleString(DateTime.DATE_FULL);
    case "DATETIME_SHORT":
      return dateTime.toLocaleString(DateTime.DATETIME_SHORT);
    case "DATETIME_MED":
      return dateTime.toLocaleString(DateTime.DATETIME_MED);
    case "DATETIME_FULL":
      return dateTime.toLocaleString(DateTime.DATETIME_FULL);
    default:
      // Treat formatType as a custom format string
      return dateTime.toFormat(formatType);
  }
};

/**
 * Combines parsing a UTC ISO string and formatting it to a local date string.
 * Accepts an optional custom format string for formatting.
 *
 * @param isoString - The ISO 8601 datetime string in UTC.
 * @param formatType - The format type or custom format string for formatting.
 * @returns The formatted local date string or 'Invalid Date' if parsing fails.
 */
export const formatUTCDateToLocal = (isoString: string, formatType = "DATETIME_MED") => {
  const localDateTime = parseUTCToLocal(isoString);
  return formatDate(localDateTime, formatType);
};
