import React, { FC, useEffect, useState } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { postMarkAsEmpty } from "../../services/markAsEmpty";
import { WarningAlert } from "../Alerts";
import UpdateLocationModal from "../UpdateLocationModal";

const MarkAsEmptyModal: FC<any> = ({ sensor, onSuccess, modalOpen, setModalOpen }) => {
  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [updateLocationModalOpen, setUpdateLocationModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleSubmit = () => {
    setSubmitting(true);
    postMarkAsEmpty(source, sensor.sensorId)
      .then(() => {
        onSuccess();
        setSubmittedMsg("Marked as Emptied");
        setSubmittingErr("");
        setSubmitting(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  return (
    <>
      <SubmitModal
        isOpen={modalOpen}
        onSubmit={() => handleSubmit()}
        onClose={() => {
          if (!submitting) setModalOpen(false);
        }}
        title="Mark as Empty"
        success={submittedMsg}
        error={submittingErr}
        submitBtnText="Yes"
        body={
          <LoadingContainer loading={submitting}>
            <span>Are you sure you want to mark this keg as empty?</span>
            {sensor.latestSampleDate &&
              Date.now() - sensor.latestSampleDate * 1000 > 86400000 && ( // longer than 24 hours ago
                <WarningAlert style={{ margin: "20px 0 0 0" }}>
                  This keg was last seen over 24 hours ago.
                  <br />
                  <span style={{ textDecoration: "underline", cursor: "pointer", fontWeight: "500" }} onClick={() => setUpdateLocationModalOpen(true)}>
                    Click here
                  </span>{" "}
                  if you would like to update it's location first.
                </WarningAlert>
              )}
          </LoadingContainer>
        }
      />
      {updateLocationModalOpen && (
        <UpdateLocationModal
          sensor={{ trackerId: sensor.sensorId, latitude: sensor.latitude, longitude: sensor.longitude }}
          onSuccess={onSuccess}
          modalOpen={updateLocationModalOpen}
          setModalOpen={setUpdateLocationModalOpen}
        />
      )}
    </>
  );
};

export default MarkAsEmptyModal;
