import { FC } from "react";
import { InfoModal } from "../Modal";
import TrackerServiceLogsTable from "../TrackerServiceLogsTable";

const TrackerServiceLogsModal: FC<any> = ({ trackerId, onSuccess, modalOpen, setModalOpen }) => {
  return (
    <InfoModal
      isOpen={modalOpen}
      onClose={() => {
        setModalOpen(false);
      }}
      title="Service Logs"
      size="xl"
      okayBtnText="Close"
      okayBtnProps={{ width: "100%" }}
      body={<TrackerServiceLogsTable onSuccess={onSuccess} trackerId={trackerId} />}
    />
  );
};

export default TrackerServiceLogsModal;
