import React, { FC, useState, useContext, useEffect } from "react";
import { SignInContainer, LogoContainer } from "./styles";
import { Form, FormInputContainer, FormInput, FormError } from "../FormComponents";
import { Link, useLocation } from "react-router-dom";
import { ThemeContext } from "styled-components";
import LoadingContainer from "../LoadingContainer";
import { PrimaryBtn } from "../Buttons";
import { getLogo } from "../../util/getLogo";
import ScreenHeading from "../ScreenHeading";
import { postForgotPassword } from "../../services/forgotPassword";
import { exists, validEmail } from "../../util/formValidations";
import { SuccessAlert, DangerAlert } from "../Alerts";
import { AlertSpan } from "../RegisterScreen/styles";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import isTouch from "../../util/isTouch";
import StateContext from "../StateContext";

const ForgotPasswordForm: FC<any> = () => {
  const location = useLocation();

  const { color } = useContext(ThemeContext);
  const { theme } = useContext(StateContext);

  const [formData, setFormData] = useState<any>({ email: "" });
  const [formErrors, setFormErrors] = useState<any>({});

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<any>(undefined);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "email":
          currValid = exists(name, value, setFormErrors) && validEmail(name, value, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const handleSubmit = () => {
    setError("");
    setSuccess(undefined);

    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      postForgotPassword(source, formData)
        .then((response) => {
          setSuccess(response);
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setError(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  return (
    <LoadingContainer loading={submitting}>
      <SignInContainer>
        <div style={{ width: "100%", margin: "auto", textAlign: "center" }}>
          <Form onSubmit={(e) => e.preventDefault()} noValidate>
            <LogoContainer>
              <Link to={`/sign-in${location.search}`}>{getLogo(color, theme)}</Link>
            </LogoContainer>
            <ScreenHeading style={{ marginBottom: "24px" }}>Forgot Password</ScreenHeading>
            <FormInputContainer style={{ marginBottom: "14px" }}>
              <label>Email</label>
              <FormInput
                type="email"
                name="email"
                autoComplete="email"
                value={formData.email}
                error={formErrors.email}
                onChange={handleChange}
                autoFocus={!isTouch()}
              />
              <FormError error={formErrors.email}>{formErrors.email}</FormError>
            </FormInputContainer>
            {!error && !success && (
              <div style={{ padding: "16px 0px 32px", width: "100%" }}>
                <PrimaryBtn width="100%" type="submit" onClick={() => handleSubmit()}>
                  Send password reset email
                </PrimaryBtn>
              </div>
            )}
            {error && (
              <DangerAlert>
                <AlertSpan>{error}</AlertSpan>
              </DangerAlert>
            )}
            {success && (
              <SuccessAlert>
                <AlertSpan>{success}</AlertSpan>
              </SuccessAlert>
            )}
          </Form>
          <Link to="/sign-in">Sign in</Link>
        </div>
      </SignInContainer>
    </LoadingContainer>
  );
};

export default ForgotPasswordForm;
