type HEX = string | null;
type RGB = number[];

const hexToRgb = (hex: HEX): RGB => {
  if (hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : [255, 0, 0]; // Return red if result is null
  } else {
    return [255, 0, 0]; // Return red if hex is null
  }
};

export default hexToRgb;
