import styled from "../Themes";

export const CenterCell = styled(`div`)`
  text-align: center;
`;

export const NoRowsContainer = styled(`div`)`
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.color.panel_bg[2]};
  transition: all 0.3s ease;
  z-index: 121;
  pointer-events: none;
  padding: 20px;
  color: ${({ theme }) => theme.color.font[2]};
  border: 1px solid ${({ theme }) => theme.color.border[2]};
`;
