import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const fetchReport11 = async (source: CancelTokenSource, batteryPercentage?: number, trackerTags?: string, assetType?: string, latestVisit?: number) => {
  const params = {
    organisationId: getAccount().organisationId,
    trackerTags,
    assetType,
    latestVisit,
    batteryPercentage,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/report11",
    params,
  });

  return data;
};
