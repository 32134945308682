import React, { FC, useState, useEffect, useContext } from "react";
import LoadingContainer from "../LoadingContainer";
import { getVerifyEmailVerify } from "../../services/verifyEmail";
import getParameterByName from "../../util/getParamByName";
import { setEmailVerified } from "../../services/localStorage";
import { Link, useLocation } from "react-router-dom";
import StateContext from "../StateContext";
import axios from "axios";
import errToStr from "../../util/errToStr";

const VerifyEmailScreen: FC<any> = () => {
  const location = useLocation();

  const { forceUpdate } = useContext(StateContext);

  // Gets code from URL query parameters if available
  const [code] = useState<string>(getParameterByName("code", location.search) || "");

  const [verified, setVerified] = useState<boolean>(false);
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    if (code) {
      setSubmitting(true);
      getVerifyEmailVerify(source, code)
        .then(() => {
          setEmailVerified(true);
          setVerified(true);
          setSubmittingErr("");
          setSubmitting(false);
          forceUpdate();
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setVerified(false);
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }

    return () => {
      source.cancel();
    };
  }, [code]);

  return (
    <LoadingContainer loading={submitting} err={submittingErr}>
      {verified === true && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "24px 12px",
          }}
        >
          <span style={{ fontSize: "1.2em" }}>Your email address is now verified</span>
          <span style={{ fontSize: "1.2em" }}>
            Click <Link to="/">here</Link> to go home
          </span>
        </div>
      )}
      {verified === false && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "24px 12px",
          }}
        >
          <span style={{ fontSize: "1.2em" }}>Incorrect verification code</span>
        </div>
      )}
    </LoadingContainer>
  );
};

export default VerifyEmailScreen;
