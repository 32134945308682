import styled from "../../Themes";

export const Form = styled<any>(`div`)`
  max-width: 720px;
  padding: ${({ theme }) => theme.space[4]};
  margin: 0 auto;

  -webkit-overflow-scrolling: touch;
`;

export const StepContents = styled(`div`)`
  padding: ${({ theme }) => theme.space[3]} 0;
`;

export const StepLabel = styled(`p`)`
  text-align: left;
`;

export const SelectContainer = styled<any>(`div`)`
  padding-bottom: 12px;
`;

export const MapContainer = styled(`div`)`
  clear: both;
  height: 400px;
  margin: 12px 0;
  border: 1px solid ${({ theme }) => theme.color.font_bold[2]};
  border-radius: 3px;
`;

export const LocationLoading = styled(`div`)`
  position: absolute;
  width: 160px;
  padding: 4px;
  background: ${({ theme }) => theme.color.main_bg[2]};
  border: 1px solid ${({ theme }) => theme.color.main_fg[2]};
  border-radius: 3px;
  top: 32px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 1000;
  text-align: center;
`;
