import { FC, useContext, useMemo } from "react";
import { IconContext } from "react-icons";
import { BsReception1, BsReception2, BsReception3, BsReception4 } from "react-icons/bs";
import { ThemeContext } from "styled-components";
import Tooltip from "../Tooltip";
import getSignalStrength from "../../util/getSignalStrength";

const SignalIcon: FC<any> = ({ csq }) => {
  const { color } = useContext(ThemeContext);

  const signal = useMemo(() => getSignalStrength(csq), [csq]);

  const signalIcon = useMemo(() => {
    if (signal === 4) {
      return (
        <IconContext.Provider value={{ color: color.great[2], size: "32px" }}>
          <BsReception4 />
        </IconContext.Provider>
      );
    } else if (signal === 3) {
      return (
        <IconContext.Provider value={{ color: color.good[2], size: "32px" }}>
          <BsReception3 />
        </IconContext.Provider>
      );
    } else if (signal === 2) {
      return (
        <IconContext.Provider value={{ color: color.bad[2], size: "32px" }}>
          <BsReception2 />
        </IconContext.Provider>
      );
    } else {
      return (
        <IconContext.Provider value={{ color: color.worse[2], size: "32px" }}>
          <BsReception1 />
        </IconContext.Provider>
      );
    }
  }, [signal]);

  const signalLabel = useMemo(() => {
    if (signal === 4) {
      return "Great";
    } else if (signal === 3) {
      return "Good";
    } else if (signal === 2) {
      return "Bad";
    } else {
      return "Terrible";
    }
  }, [signal]);

  return (
    <Tooltip placement="bottom" content={signalLabel}>
      {<div>{signalIcon}</div>}
    </Tooltip>
  );
};

export default SignalIcon;
