import axios from "axios";
import matchSorter from "match-sorter";
import { FC, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { fetchPlaceGroupList } from "../../services/placeGroupList";
import errToStr from "../../util/errToStr";
import Bold from "../Bold";
import LoadingContainer from "../LoadingContainer";
import { InfoModal } from "../Modal";
import Table, { CsvButtonsComponent } from "../Table";
import { TableHeaderButtons } from "./../NewTable/styles";

const PlaceTypePlaceList: FC<any> = ({ type, modalOpen, setModalOpen }) => {
  const { color } = useContext(ThemeContext);

  const [places, setPlaces] = useState<any>([]);
  const [placesErr, setPlacesErr] = useState<string>("");
  const [placesLoading, setPlacesLoading] = useState<boolean>(false);

  const tableRef = useRef<any>(null);

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (type) {
      setPlacesLoading(true);
      fetchPlaceGroupList(source, type.groupId)
        .then((response) => {
          setPlaces(response);
          setPlacesLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setPlacesErr(errToStr(err));
            setPlacesLoading(false);
          }
        });
    }

    return () => {
      source.cancel();
    };
  }, [type]);

  const formatDataToCsv = (tableRef: any) => {
    const sortedData = tableRef.current.getResolvedState().sortedData;
    let headers = [];

    headers = ["Name"];

    return [
      headers,
      ...sortedData.map((row: any) => {
        return [row.placeName];
      }, []),
    ];
  };

  const columns: any = [];

  columns.push({
    id: "placeName",
    Header: "Name",
    accessor: "placeName",
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["placeName"],
      }),
    filterAll: true,
    Cell: (props: any) => (
      <Link title={props.value} to={`/places/${props.original.placeId}`}>
        {props.value}
      </Link>
    ),
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  const defaultSorted = [
    {
      id: "placeName",
      desc: false,
    },
  ];

  return (
    <InfoModal
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      title="Places"
      okayBtnText="Close"
      okayBtnProps={{ width: "100%" }}
      body={
        <LoadingContainer loading={placesLoading} err={placesErr}>
          <TableHeaderButtons>
            <div style={{ display: "flex" }}></div>
            <CsvButtonsComponent data={places} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName="Place List.csv" />
          </TableHeaderButtons>
          <Table
            loading={placesLoading}
            filterable={true}
            style={{ clear: "both" }}
            data={places}
            columns={columns}
            defaultSorted={defaultSorted}
            ref={tableRef}
            defaultPageSize={15}
          />
        </LoadingContainer>
      }
    />
  );
};

export default PlaceTypePlaceList;
