import { Link } from "react-router-dom";
import styled from "../Themes";

export const BreadcrumbsContainer = styled<any>(`div`)`
  padding: 10px 16px 8px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
  background: ${({ theme }) => theme.color.panel_bg[2]};
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  place-content: space-between;
  max-height: ${({ height }) => (height !== undefined ? height : "40px")};
`;

export const BreadcrumbLink = styled<any>(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.color.primary[2]};
`;

export const BreadcrumbPage = styled<any>(`span`)`
  color: ${({ theme }) => theme.color.font_bold[2]};
`;

export const LeftContent = styled(`div`)`
  float: left;
`;

export const RightContent = styled(`div`)`
  float: right;
  display: flex;
  align-items: center;
`;
