import humanizeDuration from "humanize-duration";

export const humaniseSeconds = (duration: number, units?: any) => {
  return humanizeDuration(duration, {
    unitMeasures: {
      y: 31540000,
      mo: 2628000,
      w: 604800,
      d: 86400,
      h: 3600,
      m: 60,
      s: 1,
    },
    units: units ? units : ["h", "m"],
    round: true,
  });
};

export const humaniseMinutes = (duration: number, units?: any) => {
  return humanizeDuration(duration, {
    unitMeasures: {
      y: 3679204.032,
      mo: 306600.336,
      w: 10080,
      d: 1440,
      h: 60,
      m: 1,
      s: 0.0166667,
    },
    units: units ? units : ["w", "d", "h", "m"],
    round: true,
  });
};

export const humaniseHours = (duration: number, units?: any) => {
  return humanizeDuration(duration, {
    unitMeasures: {
      y: 8760,
      mo: 730,
      w: 168,
      d: 24,
      h: 1,
    },
    units: units ? units : ["d", "h"],
    round: true,
  });
};

export const humaniseDays = (duration: number, units?: any) => {
  return humanizeDuration(duration, {
    unitMeasures: {
      y: 365,
      mo: 30.4167,
      w: 7,
      d: 1,
      h: 0.0416667,
    },
    units: units ? units : ["d", "h"],
    round: true,
  });
};
