import axios, { CancelTokenSource } from "axios";
import matchSorter from "match-sorter";
import moment from "moment";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { fetchEditAlerts, postEditAlerts } from "../../services/alertNotifications";
import { deleteAlerts } from "../../services/editAlerts";
import MoreIcon from "../../svgs/MoreIcon";
import addOrUpdate from "../../util/addOrUpdate";
import { isReadOnly } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import { kegOrTracker, kegsOrTrackers } from "../../util/kegOrTracker";
import sortTags from "../../util/sortTags";
import AssetTypeLabel from "../AssetTypeLabel";
import Badge from "../Badge";
import Bold from "../Bold";
import BulkResolveSensorAlerts from "../BulkResolveSensorAlerts";
import BulkUnresolveSensorAlerts from "../BulkUnresolveSensorAlerts";
import DeleteModal from "../DeleteModal";
import EditAlertModal from "../EditAlertModal";
import FlatpickrRangePicker from "../FlatpickrRangePicker";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import LoadingContainer from "../LoadingContainer";
import { ErrorModal } from "../Modal";
import Table from "../Table";
import Tag from "../Tag";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

const AlertLogsTable: FC<any> = ({ sensorId, filterDates }) => {
  const { color, short_datetime, short_date, long_datetime } = useContext(ThemeContext);

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataFetchErr, setDataFetchErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [selectedAlert, setSelectedAlert] = useState<any>(undefined);
  const [selectedAlertId, setSelectedAlertId] = useState<any>(undefined);

  const [errorModalOpen, setErrorModalOpen] = useState<boolean>(false);
  const [resolveSensorAlertsOpen, setResolveSensorAlertsOpen] = useState<boolean>(false);
  const [unresolveSensorAlertsOpen, setUnresolveSensorAlertsOpen] = useState<boolean>(false);
  const [editAlertModalOpen, setEditAlertModalOpen] = useState<boolean>(false);
  const [deleteAlertModalOpen, setDeleteAlertModalOpen] = useState<boolean>(false);

  const tableRef = useRef<any>(null);

  const [filterOptions, setFilterOptions] = useState<any>({
    assetTypes: [],
    trackerTags: [],
    placeTypes: [],
    placeTags: [],
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const fetchAlerts = useCallback(() => {
    // If end date is undefined set to current date
    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined ? filterDates.end.unix() : moment().unix(),
    };

    setDataLoading(true);
    setDataFetchErr("");

    fetchEditAlerts(source, sensorId, dates)
      .then((response: any) => {
        // get distinct strings for use in the select input filters
        if (response.length > 0) {
          const assetTypes = new Set();
          const trackerTags = new Set();
          const placeTypes = new Set();
          const placeTags = new Set();

          for (let i = 0; i < response.length; i++) {
            if (response[i].assetTypeName) assetTypes.add(response[i].assetTypeName);
            if (response[i].trackerTags) response[i].trackerTags.forEach((tag: any) => trackerTags.add(tag.name));
            if (response[i].placeGroup) placeTypes.add(response[i].placeGroup);
            if (response[i].placeTags) response[i].placeTags.forEach((tag: any) => placeTags.add(tag.name));
          }

          setFilterOptions({
            assetTypes: Array.from(assetTypes).sort(),
            trackerTags: Array.from(trackerTags).sort(),
            placeTypes: Array.from(placeTypes).sort(),
            placeTags: Array.from(placeTags).sort(),
          });
        }

        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataFetchErr(errToStr(err));
          setDataLoading(false);
        }
      });
  }, [sensorId, filterDates]);

  useEffect(() => {
    fetchAlerts();
  }, [sensorId, filterDates, fetchAlerts]);

  const handleResolve = (id: string) => {
    const body = {
      action: "resolve",
      alertId: id,
    };

    setDataLoading(true);
    postEditAlerts(source, body)
      .then((response: any) => {
        setData((prev: any) => addOrUpdate(prev, response, "alertId"));
        setDataErr("");
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setErrorModalOpen(true);
          setDataLoading(false);
        }
      });
  };

  const handleUnresolve = (id: string) => {
    const body = {
      action: "unresolve",
      alertId: id,
    };

    setDataLoading(true);
    postEditAlerts(source, body)
      .then((response: any) => {
        setData((prev: any) => addOrUpdate(prev, response, "alertId"));
        setDataErr("");
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setErrorModalOpen(true);
          setDataLoading(false);
        }
      });
  };

  const columns: any = [];

  columns.push({
    Header: "Actions",
    minWidth: 110,
    maxWidth: 110,
    filterable: false,
    sortable: false,
    Cell: ({ original }: any) => {
      return (
        <Tooltip
          maxWidth="none"
          theme="binary-no-padding"
          content={
            <MenuList>
              {!isReadOnly() ? (
                <>
                  {!original.resolvedByName ? (
                    <MenuButton onClick={() => handleResolve(original.alertId)}>Resolve</MenuButton>
                  ) : (
                    <MenuButton onClick={() => handleUnresolve(original.alertId)}>Unresolve</MenuButton>
                  )}
                  {original.sensorId && (
                    <MenuButton
                      onClick={() => {
                        setSelectedAlert(original);
                        setResolveSensorAlertsOpen(true);
                      }}
                    >
                      Resolve all for this {kegOrTracker("Keg", "Tracker")}
                    </MenuButton>
                  )}
                  {original.sensorId && (
                    <MenuButton
                      onClick={() => {
                        setSelectedAlert(original);
                        setUnresolveSensorAlertsOpen(true);
                      }}
                    >
                      Unresolve all for this {kegOrTracker("Keg", "Tracker")}
                    </MenuButton>
                  )}
                  {original.eventAlertId && (
                    <MenuButton
                      onClick={() => {
                        setSelectedAlertId(original.eventAlertId);
                        setEditAlertModalOpen(true);
                      }}
                    >
                      Edit Alert
                    </MenuButton>
                  )}
                  {original.eventAlertId && (
                    <DangerMenuButton
                      onClick={() => {
                        setSelectedAlertId(original.eventAlertId);
                        setDeleteAlertModalOpen(true);
                      }}
                    >
                      Delete Alert
                    </DangerMenuButton>
                  )}
                </>
              ) : (
                <>
                  {!original.resolvedByName ? (
                    <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                      <div
                        style={{
                          cursor: "not-allowed",
                          userSelect: "none",
                        }}
                      >
                        <MenuButton disabled={true}>Resolve</MenuButton>
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                      <div
                        style={{
                          cursor: "not-allowed",
                          userSelect: "none",
                        }}
                      >
                        <MenuButton disabled={true}>Unresolve</MenuButton>
                      </div>
                    </Tooltip>
                  )}
                  {original.sensorId && (
                    <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                      <div
                        style={{
                          cursor: "not-allowed",
                          userSelect: "none",
                        }}
                      >
                        <MenuButton disabled={true}>Resolve all for this {kegOrTracker("Keg", "Tracker")}</MenuButton>
                      </div>
                    </Tooltip>
                  )}
                  {original.sensorId && (
                    <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                      <div
                        style={{
                          cursor: "not-allowed",
                          userSelect: "none",
                        }}
                      >
                        <MenuButton disabled={true}>Unresolve all for this {kegOrTracker("Keg", "Tracker")}</MenuButton>
                      </div>
                    </Tooltip>
                  )}
                  {original.eventAlertId && (
                    <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                      <div
                        style={{
                          cursor: "not-allowed",
                          userSelect: "none",
                        }}
                      >
                        <MenuButton disabled={true}>Edit Alert</MenuButton>
                      </div>
                    </Tooltip>
                  )}
                  {original.eventAlertId && (
                    <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                      <div
                        style={{
                          cursor: "not-allowed",
                          userSelect: "none",
                        }}
                      >
                        <MenuButton disabled={true}>Delete Alert</MenuButton>
                      </div>
                    </Tooltip>
                  )}
                </>
              )}
            </MenuList>
          }
          interactive={true}
          touch={true}
          appendTo={document.body}
          trigger="click"
          placement="bottom-start"
        >
          <MoreIconContainer>
            <MoreIconSize>
              <MoreIcon fill={color.font[2]} />
            </MoreIconSize>
          </MoreIconContainer>
        </Tooltip>
      );
    },
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  columns.push({
    id: "alertText",
    Header: "Alert",
    accessor: "alertText",
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["alertText"],
      }),
    filterAll: true,
    Cell: (props: any) => <span title={props.value}>{props.value}</span>,
  });

  columns.push({
    id: "resolvedDate",
    minWidth: 200,
    maxWidth: 200,
    Header: "Status",
    accessor: "resolvedDate",
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") return true;
      if (filter.value === "resolved") return row.resolvedDate;
      if (filter.value === "unresolved") return !row.resolvedDate;
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter.value}>
        <option value="all">All</option>
        <option value="unresolved">Unresolved</option>
        <option value="resolved">Resolved</option>
      </select>
    ),
    Cell: (props: any) => {
      if (props.value) {
        return (
          <Badge
            title={`Resolved by ${props.original.resolvedByName} ${moment.unix(props.original.resolvedDate).format(short_datetime)}`}
            background={color.success_dark[2]}
          >
            Resolved by {props.original.resolvedByName}
          </Badge>
        );
      }
      return (
        <Badge title="Unresolved" background={color.danger_dark[2]}>
          Unresolved
        </Badge>
      );
    },
  });

  columns.push({
    id: "timestamp",
    minWidth: 150,
    maxWidth: 150,
    Header: "Timestamp",
    accessor: "timestamp",
    filterMethod: (filter: any, row: any) => {
      if (filter.value.length === 2) {
        if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
        else return false;
      } else return true;
    },
    Filter: ({ onChange }: any) => (
      <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
    ),
    Cell: (props: any) =>
      props.value ? <span title={moment.unix(props.value).format(long_datetime)}>{moment.unix(props.value).format(short_datetime)}</span> : "",
  });

  columns.push({
    id: "sentTo",
    minWidth: 260,
    maxWidth: 260,
    Header: "Sent To",
    accessor: "sentTo",
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["sentTo"],
      }),
    filterAll: true,
    Cell: (props: any) => (props.value.length > 0 ? <span title={props.value.join(", ")}>{props.value.join(", ")}</span> : ""),
  });

  columns.push({
    id: "sensorId",
    minWidth: 120,
    maxWidth: 120,
    Header: "Tracker ID",
    accessor: "sensorId",
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["sensorId"],
      }),
    filterAll: true,
    Cell: (props: any) => (
      <Link title={props.value} to={`/${kegsOrTrackers("kegs", "trackers")}/${props.value}`}>
        {props.value}
      </Link>
    ),
  });

  columns.push({
    id: "placeName",
    minWidth: 200,
    maxWidth: 200,
    Header: "Place",
    accessor: "placeName",
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["placeName"],
      }),
    filterAll: true,
    Cell: (props: any) => (
      <Link title={props.value} to={`/places/${props.original.placeId}`}>
        {props.value}
      </Link>
    ),
  });

  columns.push({
    id: "assetTypeName",
    minWidth: 160,
    maxWidth: 160,
    Header: "Asset Type",
    accessor: "assetTypeName",
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      return row[filter.id] === filter.value;
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        {filterOptions.assetTypes.map((type: any) => {
          return (
            <option key={type} value={type}>
              {type}
            </option>
          );
        }, [])}
      </select>
    ),
    Cell: (props: any) => (
      <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
        <AssetTypeLabel name={props.value} colour={props.original.assetTypeColour} icon={props.original.assetTypeIcon} />
      </div>
    ),
  });

  columns.push({
    id: "trackerTags",
    minWidth: 160,
    maxWidth: 160,
    Header: kegsOrTrackers("Keg Tags", "Tracker Tags"),
    accessor: "trackerTags",
    style: { textOverflow: "unset", whiteSpace: "normal" },
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") return true;
      if (filter.value === "none") return row[filter.id] === undefined;
      if (Array.isArray(row[filter.id])) return row[filter.id].some((tag: any) => tag.name === filter.value);
      return false;
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="none">None</option>
        {filterOptions.trackerTags.map((tag: any) => {
          return (
            <option key={tag} value={tag}>
              {tag}
            </option>
          );
        }, [])}
      </select>
    ),
    Cell: (props: any) =>
      props.original.trackerTags ? (
        props.original.trackerTags.sort(sortTags).map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
      ) : (
        <></>
      ),
  });

  columns.push({
    id: "placeGroup",
    minWidth: 160,
    maxWidth: 160,
    Header: "Place Type",
    accessor: "placeGroup",
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      return row[filter.id] === filter.value;
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        {filterOptions.placeTypes.map((type: any) => {
          return (
            <option key={type} value={type}>
              {type}
            </option>
          );
        }, [])}
      </select>
    ),
    Cell: (props: any) => (
      <>
        <ColoredDot color={props.original.placeGroupColour} />
        <span title={props.value}>{props.value}</span>
      </>
    ),
  });

  columns.push({
    id: "placeTags",
    minWidth: 160,
    maxWidth: 160,
    Header: "Place Tags",
    accessor: "placeTags",
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") return true;
      if (filter.value === "none") return row[filter.id] === undefined;
      if (Array.isArray(row[filter.id])) return row[filter.id].some((tag: any) => tag.name === filter.value);
      return false;
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="none">None</option>
        {filterOptions.placeTags.map((tag: any) => {
          return (
            <option key={tag} value={tag}>
              {tag}
            </option>
          );
        }, [])}
      </select>
    ),
    Cell: (props: any) =>
      props.original.placeTags ? (
        props.original.placeTags.sort(sortTags).map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
      ) : (
        <></>
      ),
  });

  const defaultSorted = [
    {
      id: "timestamp",
      desc: true,
    },
  ];

  const defaultFiltered = [
    {
      id: "resolvedDate",
      value: "all",
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <LoadingContainer loading={dataLoading} err={dataFetchErr}>
        <Table
          loading={dataLoading}
          filterable={true}
          style={{ clear: "both" }}
          data={data}
          columns={columns}
          defaultSorted={defaultSorted}
          defaultFiltered={defaultFiltered}
          ref={tableRef}
          defaultPageSize={15}
        />
      </LoadingContainer>
      <ErrorModal isOpen={errorModalOpen} onClose={() => setErrorModalOpen(false)} error={dataErr} />
      {resolveSensorAlertsOpen && (
        <BulkResolveSensorAlerts alert={selectedAlert} onSuccess={fetchAlerts} modalOpen={resolveSensorAlertsOpen} setModalOpen={setResolveSensorAlertsOpen} />
      )}
      {unresolveSensorAlertsOpen && (
        <BulkUnresolveSensorAlerts
          alert={selectedAlert}
          onSuccess={fetchAlerts}
          modalOpen={unresolveSensorAlertsOpen}
          setModalOpen={setUnresolveSensorAlertsOpen}
        />
      )}
      {editAlertModalOpen && <EditAlertModal id={selectedAlertId} modalOpen={editAlertModalOpen} setModalOpen={setEditAlertModalOpen} />}
      {deleteAlertModalOpen && (
        <DeleteModal
          title="Delete Alert"
          body={<span>Are you sure you want to delete this alert?</span>}
          successMsg="Alert Deleted"
          onDelete={fetchAlerts}
          onClose={() => setSelectedAlertId(undefined)}
          modalOpen={deleteAlertModalOpen}
          setModalOpen={setDeleteAlertModalOpen}
          deleteService={deleteAlerts}
          serviceParams={[selectedAlertId]}
        />
      )}
    </div>
  );
};

export default AlertLogsTable;
