import React, { FC } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Checkbox from "../Checkbox";
import { DraggableContainer, DroppableContainer as DroppableContainer, DragHandle } from "./styles";
import DragIcon from "../../svgs/DragIcon";
import { OutlineBtn } from "../Buttons";
import { Table as ReactTable } from "@tanstack/react-table";
const reorder = (list: Iterable<unknown> | ArrayLike<unknown>, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ColumnList: FC<any> = ({ table, defaultVisibility }: { table: ReactTable<any>; defaultVisibility: any }) => {
  // ignore actions column
  const columns = table.getAllLeafColumns().filter((column: any) => column.id !== "actions");

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) return;
    const updatedItems = reorder(columns, result.source.index, result.destination.index);
    // add actions column back so it doesn't disappear
    table.setColumnOrder(["actions", ...updatedItems.map((d: any) => d.id)]);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <DroppableContainer {...provided.droppableProps} ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver}>
            <OutlineBtn style={{ margin: "0 12px 12px 0" }} onClick={() => table.resetColumnOrder()}>
              Reset Order
            </OutlineBtn>
            <OutlineBtn
              style={{ margin: "0 0 12px 0" }}
              onClick={() => (defaultVisibility ? table.setColumnVisibility(defaultVisibility) : table.resetColumnVisibility())}
            >
              Reset Visibility
            </OutlineBtn>
            {columns.map((column: any, index: number) => (
              <Draggable key={column.id} draggableId={column.id} index={index}>
                {(provided, snapshot) => (
                  <DraggableContainer
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{ ...provided.draggableProps.style }}
                    isDragging={snapshot.isDragging}
                  >
                    <DragHandle>
                      <DragIcon />
                    </DragHandle>
                    <Checkbox
                      style={{ display: "inline-block" }}
                      name={column.id}
                      label={column.columnDef.header}
                      checked={column.getIsVisible()}
                      onChange={column.getToggleVisibilityHandler()}
                    />
                  </DraggableContainer>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </DroppableContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ColumnList;
