import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";
import { FormInput, FormError } from "../FormComponents";
import { postEditUser } from "../../services/editUser";
import { exists, maxLength, validEmail, validPhoneNumber } from "../../util/formValidations";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import PhoneNumberInput from "../PhoneNumberInput";
import { Select } from "../Select";
import { getAccount, getCountry, saveCountry } from "../../services/localStorage";
import moment from "moment";
import { fetchCountry } from "../../services/fetchCountry";
import addOrUpdate from "../../util/addOrUpdate";

const defaultUser = {
  userId: "",
  fullName: "",
  email: "",
  phoneNumber: "",
  role: null,
};

const initUserForm = (user: any) => {
  return {
    ...defaultUser,
    ...user,
    role: user.role
      ? {
          value: user.role,
          label: user.role,
        }
      : null,
  };
};

const EditUserModal: FC<any> = ({ user, setData, modalOpen, setModalOpen }) => {
  const [accountInfo] = useState<any>(getAccount());

  const [formData, setFormData] = useState<any>(initUserForm(user));
  const [formErrors, setFormErrors] = useState<any>({});

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [country, setCountry] = useState<string>("AU");

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    const currCountry = getCountry();

    // If no country stored or older than 24 hours, fetch country code again
    if (currCountry && moment().diff(moment(currCountry.date), "hours") < 24) {
      setCountry(currCountry.code);
    } else {
      fetchCountry(source)
        .then((response) => {
          if (response) {
            setCountry(response);
            saveCountry({ date: moment(), code: response });
          } else {
            setCountry("AU");
            saveCountry({ date: moment(), code: "AU" });
          }
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            saveCountry({ date: moment(), code: "AU" });
            setCountry("AU");
          }
        });
    }
  }, [source]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "fullName":
          currValid = exists(name, value, setFormErrors) && maxLength(name, value, 200, setFormErrors);
          break;

        case "email":
          currValid = exists(name, value, setFormErrors) && validEmail(name, value, setFormErrors);
          break;

        case "phoneNumber":
          currValid = value ? validPhoneNumber(name, value, setFormErrors) : true;
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const formattedData: any = {
      userId: formData.userId,
      fullName: formData.fullName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      role: formData.role && formData.role.value ? formData.role.value : null,
    };

    return formattedData;
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      setSubmitting(true);
      postEditUser(source, body)
        .then((response) => {
          setData((prev: any) => addOrUpdate(prev, response, "userId"));
          setSubmittedMsg("User Updated");
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setSubmittingErr(errToStr(err));
            setSubmitting(false);
          }
        });
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handlePhoneChange = (value?: string) => {
    setFormData((prev: any) => ({ ...prev, phoneNumber: value }));
    setFormErrors((prev: any) => ({ ...prev, phoneNumber: undefined }));
  };

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      title="Edit User"
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Update User"
      body={
        <LoadingContainer loading={submitting}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <ModalFormContainer>
              <label>Name</label>
              <FormInput type="text" name="fullName" value={formData.fullName} error={formErrors.fullName} onChange={handleChange} />
              <FormError error={formErrors.fullName}>{formErrors.fullName}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <label>Email</label>
              <FormInput type="email" name="email" value={formData.email} error={formErrors.email} onChange={handleChange} autoComplete="email" />
              <FormError error={formErrors.email}>{formErrors.email}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <label>Phone Number</label>
              <PhoneNumberInput
                international={true}
                defaultCountry={country}
                value={formData.phoneNumber}
                error={formErrors.phoneNumber}
                onChange={handlePhoneChange}
                autoComplete="tel"
              />
              <FormError error={formErrors.phoneNumber}>{formErrors.phoneNumber}</FormError>
            </ModalFormContainer>
            <ModalFormContainer>
              <label>Role</label>
              <Select
                isDisabled={formData.userId === accountInfo.id}
                name="role"
                isError={formErrors.role}
                value={formData.role}
                onChange={handleSelectChange}
                placeholder="Select"
                options={[
                  { label: "User", value: "User" },
                  { label: "Admin", value: "Admin" },
                  { label: "Alerts Only", value: "AlertsOnly" },
                  { label: "Read Only", value: "ReadOnly" },
                ]}
              />
              <FormError error={formErrors.role}>{formErrors.role}</FormError>
            </ModalFormContainer>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default EditUserModal;
