import styled from "../Themes";

export const InviteIconContainer = styled(`div`)`
  position: relative;
  float: right;
  cursor: pointer;
  fill: ${({ theme }) => theme.color.font[2]};

  &:hover {
    fill: ${({ theme }) => theme.color.font_bold[2]};
  }
`;

export const InviteContainer = styled(`div`)`
  max-height: 50vh;
  text-align: left;
  overflow-y: auto;
  padding: 20px;

  @media (max-width: 576px) {
    width: 95vw;
  }
`;

export const InviteCard = styled(`div`)`
  border: 1px solid ${({ theme }) => theme.color.subtle_border[3]};
  padding: 12px;
  border-radius: 3px;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.tooltip_hover[2]};
  }
`;

export const InviteBody = styled(`div`)`
  display: flex;
  flex-direction: row;
`;

export const InviteImg = styled(`div`)`
  margin: 0 12px 0 0;
`;

export const InviteDate = styled(`p`)`
  font-size: 11px;
  margin-top: 4px;
  color: ${({ theme }) => theme.color.primary[2]};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const InviteFooter = styled(`div`)`
  display: flex;
  flex-flow: wrap;
  justify-content: flex-end;
  flex-shrink: 0;
`;
