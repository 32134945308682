import React, { FC } from "react";
import styled from "../components/Themes";

const Div = styled(`div`)`
  font-size: 1.3rem;
  fill: #fff;
`;

const ClusterMarker: FC<any> = ({ fill, count }) => (
  <Div>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70.323 70.323">
      <path
        fill={fill}
        d="M35.14.14c-9.736 0-17.761 3.344-24.718 10.3C3.465 17.397.12 25.423.12 35.158c0 9.738 3.347 17.77 10.3 24.714 5.837 5.828 12.337 9.054 20.58 10.212 2.745.385 8.005.141 11.134-.517 6.922-1.456 12.584-4.552 17.723-9.69 6.956-6.958 10.3-14.983 10.3-24.719 0-9.735-3.344-17.761-10.3-24.718C52.9 3.484 44.876.14 35.14.14zm0 3.756c2.611 0 5.223.288 7.225.863 5.589 1.605 11.348 4.839 14.842 8.332 3.493 3.494 6.727 9.253 8.332 14.842 1.15 4.005 1.15 10.446 0 14.451-1.605 5.589-4.839 11.348-8.332 14.842-3.454 3.454-9.248 6.724-14.659 8.274-3.464.992-9.52 1.245-12.7.53-6.37-1.43-12.747-4.777-16.775-8.804-3.494-3.494-6.727-9.253-8.332-14.842-1.15-4.005-1.15-10.446 0-14.451 1.605-5.589 4.838-11.348 8.332-14.842 3.494-3.493 9.253-6.727 14.841-8.332 2.003-.575 4.614-.863 7.226-.863z"
      />
      <path
        d="M30.911 70.059c-7.944-.999-15.396-4.765-21.013-10.62-13.563-14.137-13.137-35.997.966-49.527 13.775-13.216 34.786-13.216 48.562 0 14.102 13.53 14.528 35.39.965 49.527-7.712 8.038-18.654 11.98-29.48 10.62z"
        opacity={0.75}
        fill={fill}
      />
      <text x="50%" y="50%" textAnchor="middle" dominantBaseline="central">
        {count}
      </text>
    </svg>
  </Div>
);

export default ClusterMarker;
