import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import React, { FC, useContext, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { fetchStagnantSensors } from "../../../services/stagnantSensors";
import downloadFile from "../../../util/downloadFile";
import errToStr from "../../../util/errToStr";
import LoadingContainer from "../../LoadingContainer";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import Table from "../../Table";
import { ClearPanel, Panel, StatPanel } from "../../Panel";
import Drawer from "rc-drawer";
import Cross from "../../../svgs/Cross";
import { PrimaryBtn } from "../../Buttons";
import { StatBlock, StatLabel, StatValue } from "../styles";
import { AsyncSelect } from "../../Select";
import { Title, Subtitle } from "../../PageTitle/styles";
import { DrawBody, DrawCloseButton, DrawContent, DrawFooter, DrawHeader, FilterInputContainer } from "../../Drawer/styles";
import { fetchAutoComplete } from "../../../services/autoComplete";
import getParameterByName from "../../../util/getParamByName";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { TbDownload } from "react-icons/tb";
import { IconContext } from "react-icons";

const formatDataToCsv = (tableRef: any) => {
  const headers = ["Place Name", "Place Type", "Kegs at Place for 14 - 30 Days", "Kegs at Place for 30+ Days"];

  const data = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...data.map((row: any) => {
      return [row.placeName, row.placeGroup, row.here14to30days, row.here30plusDays];
    }, []),
  ];
};

const StagnantKegs: FC<any> = () => {
  const { color } = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const tableRef = useRef<any>(null);

  const [data, setData] = useState<any>({
    places: [],
    totalPlaces: 0,
    total14to30days: 0,
    total30plusDays: 0,
  });
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);

  // Report filters
  const [placeTypes, setPlaceTypes] = useState<any>([]);

  const [appliedFilters, setAppliedFilters] = useState<number>(0);

  const [autoCompletePlaceTypes, setAutoCompletePlaceTypes] = useState<any>(undefined);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Fetch all place types from autoComplete to use for matching params
  // e.g. match "?place-types=Venue" to {value: "a980bcaa-7ede-45b5-8ced-63e10284f994", label: "Venue", colour: "#c16807",…}
  useEffect(() => {
    const placeTypeParam = getParameterByName("place-types", location.search) !== null ? getParameterByName("place-types", location.search)?.split(",") : [];

    fetchAutoComplete("placetypes", "").then((response) => {
      if (placeTypeParam && placeTypeParam.length > 0) {
        setPlaceTypes(response.filter((type: any) => placeTypeParam.includes(type.label)));
      }
      setAutoCompletePlaceTypes(response);
    });
  }, []);

  // On location change (e.g. url parameter changes because filters changed), update filters and fetch report
  useEffect(() => {
    if (autoCompletePlaceTypes !== undefined) {
      const placeTypeParam = getParameterByName("place-types", location.search) !== null ? getParameterByName("place-types", location.search)?.split(",") : [];
      const filteredPlaceTypes = autoCompletePlaceTypes.filter((type: any) => placeTypeParam?.includes(type.label));
      setPlaceTypes(filteredPlaceTypes);

      fetchReport(filteredPlaceTypes);
    }
  }, [location]);

  // After autoCompletePlaceTypes is initially set on mount, fetch report
  useEffect(() => {
    if (autoCompletePlaceTypes !== undefined) {
      fetchReport();
    }
  }, [autoCompletePlaceTypes]);

  const updateQueryParams = () => {
    const parsed = queryString.parse(location.search);
    const newQuery = { ...parsed };

    if (placeTypes && placeTypes.length > 0) {
      newQuery["place-types"] = placeTypes.map((type: any) => type.label).join(",");
    } else if (autoCompletePlaceTypes !== undefined) {
      delete newQuery["place-types"];
    }

    const stringified = queryString.stringify(newQuery);
    navigate({ ...location, search: stringified });
  };

  const fetchReport = (types?: any) => {
    setDataLoading(true);
    setDataErr("");

    const placeTypesArg = types !== undefined ? types : placeTypes;

    fetchStagnantSensors(source, placeTypesArg.map((type: any) => type.label).join(","))
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setData({
            places: [],
            totalPlaces: 0,
            total14to30days: 0,
            total30plusDays: 0,
          });
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    let filters = 0;

    if (placeTypesArg.length > 0) filters += placeTypesArg.length;

    setAppliedFilters(filters);
  };

  const columns = [
    {
      Header: "Place",
      columns: [
        {
          id: "placeName",
          Header: "Name",
          accessor: "placeName",
          Cell: (props: any) => <Link to={`/places/${props.original.placeId}`}>{props.value}</Link>,
          minWidth: 310,
        },
        {
          id: "placeGroup",
          Header: "Place Type",
          accessor: "placeGroup",
          minWidth: 130,
        },
      ],
    },
    {
      Header: "Kegs at Place for",
      columns: [
        {
          id: "here14to30days",
          Header: "14 - 30 Days",
          accessor: "here14to30days",
          Cell: (props: any) => props.value,
          minWidth: 130,
        },
        {
          id: "here30plusDays",
          Header: "30+ Days",
          accessor: "here30plusDays",
          Cell: (props: any) => props.value,
          minWidth: 130,
        },
      ],
    },
  ];

  const defaultSorted = [
    {
      id: "here30plusDays",
      desc: true,
    },
    { id: "here14to30days", desc: true },
  ];

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  return (
    <>
      <Drawer
        placement="right"
        level={null}
        open={filtersOpen}
        onClose={() => {
          setFiltersOpen(false);
          updateQueryParams();
        }}
        onHandleClick={() => setFiltersOpen(!filtersOpen)}
        handler={false}
      >
        <DrawContent>
          <DrawHeader>
            <div
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                fontSize: "0",
                marginRight: "6px",
              }}
            >
              <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                <HiOutlineAdjustmentsHorizontal />
              </IconContext.Provider>
            </div>
            Filters
            <DrawCloseButton
              onClick={() => {
                setFiltersOpen(false);
              }}
              aria-label={`Close filters`}
            >
              <Cross />
            </DrawCloseButton>
          </DrawHeader>
          <DrawBody>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <label>Place Type</label>
              <FilterInputContainer>
                <AsyncSelect
                  name="placeTypes"
                  defaultOptions={true}
                  closeMenuOnSelect={false}
                  isClearable={true}
                  isMulti={true}
                  isSearchable={true}
                  value={placeTypes}
                  loadOptions={(inputValue: any, callback: any) => loadOptions("placetypes", inputValue, callback)}
                  onChange={(selected: any) => {
                    if (selected) {
                      setPlaceTypes(selected);
                    } else {
                      setPlaceTypes([]);
                    }
                  }}
                  placeholder="Select..."
                />
              </FilterInputContainer>
            </form>
          </DrawBody>
          <DrawFooter>
            <PrimaryBtn
              onClick={() => {
                setFiltersOpen(false);
                updateQueryParams();
              }}
            >
              Apply Filters
            </PrimaryBtn>
          </DrawFooter>
        </DrawContent>
      </Drawer>
      <PageBreadcrumbs prevRoutes={[{ slug: "/reports", title: "Reports" }]} currRoute="Stagnant Kegs" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading} err={dataErr}>
            <ClearPanel style={{ textAlign: "center" }}>
              <Title style={{ marginBottom: "6px" }}>Stagnant Kegs</Title>
              <Subtitle>Number of kegs that have been at a place for 14 - 30 days and {">"} 30 days.</Subtitle>
            </ClearPanel>
            <ClearPanel style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <PrimaryBtn style={{ height: "40px", minWidth: "unset", marginRight: "12px" }} padding="0 6px" onClick={() => setFiltersOpen(!filtersOpen)}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div
                      style={{
                        display: "inline-block",
                        width: "20px",
                        height: "20px",
                        fontSize: "0",
                        marginRight: "6px",
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiOutlineAdjustmentsHorizontal />
                      </IconContext.Provider>
                    </div>
                    <span>Filters</span>
                  </div>
                </PrimaryBtn>

                <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                  {appliedFilters} filter{appliedFilters === 1 ? "" : "s"} applied
                </span>
              </div>
              <PrimaryBtn
                style={{ height: "40px", minWidth: "unset", marginRight: "12px" }}
                padding="0 6px"
                onClick={() =>
                  downloadFile(
                    stringify(formatDataToCsv(tableRef), {
                      quoted: true,
                      quoted_string: true,
                    }),
                    "text/csv;charset=utf-8",
                    "Stagnant Kegs.csv"
                  )
                }
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "inline-block",
                      width: "20px",
                      height: "20px",
                      fontSize: "0",
                      marginRight: "6px",
                    }}
                  >
                    <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                      <TbDownload />
                    </IconContext.Provider>
                  </div>
                  <span
                    style={{
                      marginRight: "6px",
                    }}
                  >
                    CSV
                  </span>
                </div>
              </PrimaryBtn>
            </ClearPanel>
            <StatPanel>
              <StatBlock style={{ width: "260px" }}>
                <StatLabel>Total Places</StatLabel>
                <StatValue>{data.totalPlaces}</StatValue>
              </StatBlock>
              <StatBlock style={{ width: "260px" }}>
                <StatLabel>Kegs at Places for 14 - 30 Days </StatLabel>
                <StatValue>{data.total14to30days}</StatValue>
              </StatBlock>
              <StatBlock style={{ width: "260px" }}>
                <StatLabel>Kegs at Places for 30+ Days </StatLabel>
                <StatValue>{data.total30plusDays}</StatValue>
              </StatBlock>
            </StatPanel>
            <Panel>
              <Table loading={dataLoading} style={{ clear: "both" }} data={data.places} columns={columns} defaultSorted={defaultSorted} ref={tableRef} />
            </Panel>
          </LoadingContainer>
        </div>
      </PageContainer>
    </>
  );
};

export default StagnantKegs;
