import React, { FC } from "react";
import { PageContainer } from "../PageStyles";
import UsersTable from "../UsersTable";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import InvitesTable from "../InvitesTable";
import { Panel } from "../Panel";

const UsersScreen: FC<any> = () => {
  return (
    <>
      <Tabs>
        <TabList className="react-tabs__tab-list__background_no-margin">
          <Tab>Users</Tab>
          <Tab>Invites</Tab>
        </TabList>
        <TabPanel>
          <PageContainer>
            <Panel>
              <UsersTable />
            </Panel>
          </PageContainer>
        </TabPanel>
        <TabPanel>
          <PageContainer>
            <Panel>
              <InvitesTable />
            </Panel>
          </PageContainer>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default UsersScreen;
