import Tooltip from "../components/Tooltip";
import wiliotLogoDark from "../imgs/wiliot-logo-dark.png";
import wiliotLogoLight from "../imgs/wiliot-logo-light.png";
import ATAndTIcon from "../svgs/ATAndTIcon";
import AirtelIcon from "../svgs/AirtelIcon";
import BinaryBeerLogo from "../svgs/BinaryBeerLogo";
import BinaryLoopLogo from "../svgs/BinaryLoopLogo";
import BinaryMedLogo from "../svgs/BinaryMedLogo";
import BinaryTechLogo from "../svgs/BinaryTechLogo";
import HeliumLogo from "../svgs/HeliumLogo";
import RogersWirelessIcon from "../svgs/RogersWirelessIcon";
import SafaricomLogo from "../svgs/SafaricomLogo";
import SparkNzIcon from "../svgs/SparkNzIcon";
import TelstraIcon from "../svgs/TelstraIcon";
import ThreeIrelandLogo from "../svgs/ThreeIrelandLogo";
import VodafoneIcon from "../svgs/VodafoneIcon";
import { checkDomain } from "./checkDomain";

const getNetworkIcon = (network: string, color: any, theme: any) => {
  const { hostname } = window.location || "";

  switch (network) {
    case "Binary":
      return (
        <Tooltip placement="bottom" content={network}>
          <div style={{ width: "86px" }}>
            {checkDomain(<BinaryTechLogo color={color} />, <BinaryBeerLogo color={color} />, <BinaryMedLogo color={color} />, <BinaryLoopLogo color={color} />)}
          </div>
        </Tooltip>
      );

    case "Telstra MobileNet, Australia":
      return (
        <Tooltip placement="bottom" content={network}>
          <div style={{ width: "64px" }}>
            <TelstraIcon />
          </div>
        </Tooltip>
      );

    case "Vodafone, Australia":
      return (
        <Tooltip placement="bottom" content={network}>
          <div style={{ width: "72px" }}>
            <VodafoneIcon />
          </div>
        </Tooltip>
      );

    case "Safaricom Ltd., Kenya":
      return (
        <Tooltip placement="bottom" content={network}>
          <div style={{ width: "72px" }}>
            <SafaricomLogo color={color} />
          </div>
        </Tooltip>
      );

    case "Rogers Wireless, Canada":
      return (
        <Tooltip placement="bottom" content={network}>
          <div style={{ width: "86px" }}>
            <RogersWirelessIcon />
          </div>
        </Tooltip>
      );

    case "Spark NZ, New Zealand":
      return (
        <Tooltip placement="bottom" content={network}>
          <div style={{ width: "72px" }}>
            <SparkNzIcon />
          </div>
        </Tooltip>
      );

    case "Airtel-Vodafone, United Kingdom":
      return (
        <Tooltip placement="bottom" content={network}>
          <div style={{ width: "64px" }}>
            <AirtelIcon />
          </div>
        </Tooltip>
      );

    case "AT&T, United States":
      return (
        <Tooltip placement="bottom" content={network}>
          <div style={{ width: "64px" }}>
            <ATAndTIcon />
          </div>
        </Tooltip>
      );

    case "Helium":
      return (
        <Tooltip placement="bottom" content={network}>
          <div style={{ width: "64px" }}>
            <HeliumLogo fill={color.font_bold[2]} />
          </div>
        </Tooltip>
      );

    case "Three Ireland":
      return (
        <Tooltip placement="bottom" content={network}>
          <div style={{ width: "40px" }}>
            <ThreeIrelandLogo fill={color.font_bold[2]} />
          </div>
        </Tooltip>
      );

    case "Wiliot":
      return (
        <Tooltip placement="bottom" content={network}>
          <div style={{ width: "40px" }}>
            <img width="100%" src={theme === "dark" ? wiliotLogoLight : wiliotLogoDark} alt="Wiliot" />
          </div>
        </Tooltip>
      );

    default:
      return network;
  }
};

export default getNetworkIcon;
