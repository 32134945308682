import React, { FC, useEffect, useState } from "react";
import { InfoModal } from "../Modal";
import LoadingContainer from "../LoadingContainer";
import axios from "axios";
import errToStr from "../../util/errToStr";
import { fetchLegal } from "../../services/legal";
import { Terms, TermsContainer } from "../TermsAndConditionsScreen/styles";
import TermsStyling from "../GlobalStyles/terms-and-conditions";
import { OutlineBtn, PrimaryBtn } from "../Buttons";

const TermsAndConditionsModal: FC<any> = ({ modalOpen, setModalOpen }) => {
  const [data, setData] = useState<any>(undefined);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setDataLoading(true);
    setDataErr("");

    fetchLegal(source)
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, []);

  const saveTerms = () => {
    const element = document.createElement("a");
    const file = new Blob([data.text], { type: "html" });
    element.href = URL.createObjectURL(file);
    element.download = `Terms and Conditions - v${data.version}.html`;
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <>
      <TermsStyling />
      <InfoModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Terms and Conditions"
        size="lg"
        body={
          <LoadingContainer loading={dataLoading} err={dataErr}>
            <TermsContainer>
              {data && (
                <Terms
                  dangerouslySetInnerHTML={{
                    __html: data.text,
                  }}
                />
              )}
            </TermsContainer>
          </LoadingContainer>
        }
        footer={
          <>
            <OutlineBtn onClick={() => saveTerms()}>Save...</OutlineBtn>
            <PrimaryBtn onClick={() => setModalOpen(false)}>Okay</PrimaryBtn>
          </>
        }
      />
    </>
  );
};

export default TermsAndConditionsModal;
