import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";

const DeleteModal: FC<any> = ({
  title = "Delete",
  body,
  submitBtnText = "Delete",
  successMsg = "Deleted",
  onDelete,
  onClose,
  modalOpen,
  setModalOpen,
  deleteService,
  serviceParams,
}) => {
  const [deletedMsg, setDeletedMsg] = useState<string>("");
  const [deleteErr, setDeleteErr] = useState<string>("");
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleDelete = () => {
    setDeleteLoading(true);

    deleteService(source, ...serviceParams)
      .then(() => {
        if (onDelete) onDelete();
        setDeletedMsg(successMsg);
        setDeleteLoading(false);
      })
      .catch((err: any) => {
        setDeleteErr(errToStr(err));
        setDeleteLoading(false);
      });
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleDelete()}
      onClose={() => {
        if (onClose) onClose();
        setModalOpen(false);
      }}
      title={title}
      success={deletedMsg}
      error={deleteErr}
      submitBtnText={submitBtnText}
      submitBtn="danger"
      body={
        <LoadingContainer loading={deleteLoading}>
          <span>{body}</span>
        </LoadingContainer>
      }
    />
  );
};

export default DeleteModal;
