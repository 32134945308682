import axios, { CancelTokenSource } from "axios";
import { getAccount, getToken } from "../localStorage";

type FetchProps = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: any;
  filters?: any;
};

export const fetchPickupRequests = async (source: CancelTokenSource, { pageIndex, pageSize, orderBy, filters }: FetchProps) => {
  const params: any = {
    organisationId: getAccount().organisationId,
    count: true,
  };

  if (pageIndex !== undefined && pageSize !== undefined) params.skip = pageIndex * pageSize;

  if (pageSize !== undefined) params.top = pageSize;

  if (orderBy && orderBy.length > 0) params.orderby = orderBy.map((order: any) => `${order.id} ${order.desc ? "desc" : "asc"}`).join(", ");

  if (filters) params.filter = filters;

  const { data, headers } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/pickuprequests",
    params,
  });

  return { count: headers?.count, data };
};

export const createPickupRequest = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/v1/pickuprequests/private",
    data: JSON.stringify(body),
  });

  return data;
};

export const editPickupRequest = async (source: CancelTokenSource, id: string, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + `/v1/pickuprequests/private/${id ? id : ""}`,
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};

export const markAsCollected = async (source: CancelTokenSource, id: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + `/v1/pickuprequests/collect/private/${id ? id : ""}`,
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
    }),
  });

  return data;
};

export const deletePickupRequest = async (source: CancelTokenSource, id: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "delete",
    url: process.env.REACT_APP_API_URL + `/v1/pickuprequests/${id ? id : ""}`,
  });

  return data;
};

export const createPickupRequestPublic = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/v1/pickuprequests/public",
    data: JSON.stringify(body),
  });

  return data;
};

export const markAsCollectedPublic = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + `/v1/pickuprequests/collect/public`,
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};
