import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { fetchCountry } from "../../services/fetchCountry";
import { getCountry, getToken, saveCountry } from "../../services/localStorage";
import { fetchPhoneDetails } from "../../services/phoneDetails";
import { fetchSensorExists } from "../../services/sensorExists";
import errToStr from "../../util/errToStr";
import { getLogo } from "../../util/getLogo";
import { DangerAlert } from "../Alerts";
import { OutlineBtn } from "../Buttons";
import LoadingContainer from "../LoadingContainer";
import { PageContainer } from "../PageStyles";
import PhoneScanMeta from "../PhoneScanMeta";
import StateContext from "../StateContext";
import { FlexRow, HeadingWrapper, LogoContainer } from "./styles";

const PhoneScanScreen: FC<any> = () => {
  const { scanCode } = useParams();

  const { color } = useContext(ThemeContext);
  const { theme } = useContext(StateContext);

  const [country, setCountry] = useState<string>("AU");

  const [meta, setMeta] = useState<any>({});
  const [logs, setLogs] = useState<any>([]);
  const [series, setSeries] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [ownsTracker, setOwnsTracker] = useState<boolean>(false);
  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    const currCountry = getCountry();

    // If no country stored or older than 24 hours, fetch country code again
    if (currCountry && moment().diff(moment(currCountry.date), "hours") < 24) {
      setCountry(currCountry.code);
    } else {
      fetchCountry(source)
        .then((response) => {
          if (response) {
            setCountry(response);
            saveCountry({ date: moment(), code: response });
          } else {
            setCountry("AU");
            saveCountry({ date: moment(), code: "AU" });
          }
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            saveCountry({ date: moment(), code: "AU" });
            setCountry("AU");
          }
        });
    }
  }, [source]);

  // On page load, fetch the public tracker details
  const fetchData = useCallback(() => {
    if (scanCode !== undefined) {
      setDataLoading(true);
      setDataErr("");

      fetchPhoneDetails(source, scanCode)
        .then((response) => {
          if (response) {
            // if the response has an error and an id then public scans are disabled
            if (response.error && response.id) {
              setDataErr(response.error);
              setDataLoading(false);
              // else public scans are enabled so display the data returned from the server
            } else {
              setMeta(response.metadata);

              // the logs from the api are "compressed" by removing values from each log if it is the same
              // as the previous log, this code adds those back in so the graphs are displayed correctly
              if (response.logs.length > 0) {
                // gets the logs and series from the response
                const { logs, series } = response;

                // gets the log's keys from the series object
                const seriesNames = [];
                for (const [key, value] of Object.entries(series)) {
                  if (value === true) seriesNames.push(key);
                }
                setSeries(seriesNames);

                // gets the log's keys from the last index of the logs
                // removing "ts" as this is present in every log
                const props = Object.keys(logs[logs.length - 1]);
                props.splice(props.indexOf("ts"), 1);

                // adds first log to new logs
                const newLogs = [logs[0]];

                // loops through all logs
                for (let i = 1; i < logs.length; i++) {
                  // create new temp log to repopulate missing fields
                  const tempLog = logs[i];

                  // for each prop, check if missing and if so take value from previous log
                  for (let j = 0; j < props.length; j++) {
                    if (logs[i][props[j]] === undefined) {
                      tempLog[props[j]] = newLogs[i - 1][props[j]];
                    }
                  }

                  // add temp log to new logs array
                  newLogs[i] = tempLog;
                }
                setLogs(newLogs);
              }
            }
          }
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setDataErr(errToStr(err));
            setDataLoading(false);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (country) fetchData();
  }, [scanCode, country, fetchData]);

  // Once the public data as been retrieved check if the user is logged in
  useEffect(() => {
    const source = axios.CancelToken.source();

    if (meta && meta.sensorId) {
      // Check if logged in
      if (getToken()) {
        // If logged in check if the tracker belongs to the user
        fetchSensorExists(source, meta.sensorId)
          .then(() => {
            setDataLoading(false);
            setOwnsTracker(true);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setDataLoading(false);
            }
          });
      } else {
        setDataLoading(false);
      }
    }

    return () => {
      source.cancel();
    };
  }, [meta, source]);

  if (dataErr) {
    return (
      <PageContainer>
        <FlexRow>
          <HeadingWrapper>
            <LogoContainer>
              <Link to={getToken() ? "/" : "/sign-in"}>{getLogo(color, theme)}</Link>
            </LogoContainer>
          </HeadingWrapper>
        </FlexRow>
        <FlexRow>
          <DangerAlert>{dataErr}</DangerAlert>
          <Link style={{ textDecoration: "none" }} to={`/sign-in`}>
            <OutlineBtn>Sign In</OutlineBtn>
          </Link>
        </FlexRow>
      </PageContainer>
    );
  }

  return (
    <LoadingContainer loading={dataLoading}>
      <div style={{ overflow: "auto", width: "100%", height: "100%" }}>
        <PageContainer>
          <FlexRow>
            <HeadingWrapper>
              <LogoContainer>
                <Link to={getToken() ? "/" : "/sign-in"}>{getLogo(color, theme)}</Link>
              </LogoContainer>
            </HeadingWrapper>
            <PhoneScanMeta scanCode={scanCode} meta={meta} logs={logs} series={series} fetchData={fetchData} country={country} ownsTracker={ownsTracker} />
          </FlexRow>
        </PageContainer>
      </div>
    </LoadingContainer>
  );
};

export default PhoneScanScreen;
