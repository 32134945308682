import {
  FaAccessibleIcon,
  FaApple,
  FaBitcoin,
  FaBlackTie,
  FaBluetoothB,
  FaBluetooth,
  FaBtc,
  FaCanadianMapleLeaf,
  FaDiscord,
  FaEthereum,
  FaItunesNote,
  FaSketch,
  FaSnapchatGhost,
  FaTwitter,
  FaUntappd,
  FaAddressBook,
  FaAddressCard,
  FaAirFreshener,
  FaAllergies,
  FaAmbulance,
  FaAmericanSignLanguageInterpreting,
  FaAnchor,
  FaAngry,
  FaAppleAlt,
  FaArchive,
  FaArchway,
  FaAssistiveListeningSystems,
  FaAsterisk,
  FaAt,
  FaAtlas,
  FaAtom,
  FaAward,
  FaBabyCarriage,
  FaBaby,
  FaBacon,
  FaBacteria,
  FaBacterium,
  FaBahai,
  FaBalanceScaleLeft,
  FaBalanceScaleRight,
  FaBalanceScale,
  FaBan,
  FaBandAid,
  FaBarcode,
  FaBaseballBall,
  FaBasketballBall,
  FaBath,
  FaBatteryEmpty,
  FaBatteryFull,
  FaBatteryHalf,
  FaBatteryQuarter,
  FaBatteryThreeQuarters,
  FaBed,
  FaBeer,
  FaBellSlash,
  FaBell,
  FaBible,
  FaBicycle,
  FaBiking,
  FaBinoculars,
  FaBiohazard,
  FaBirthdayCake,
  FaBlenderPhone,
  FaBlender,
  FaBlind,
  FaBolt,
  FaBomb,
  FaBone,
  FaBookDead,
  FaBookMedical,
  FaBookOpen,
  FaBookReader,
  FaBook,
  FaBookmark,
  FaBowlingBall,
  FaBoxOpen,
  FaBoxTissue,
  FaBox,
  FaBoxes,
  FaBraille,
  FaBrain,
  FaBreadSlice,
  FaBriefcaseMedical,
  FaBriefcase,
  FaBroadcastTower,
  FaBroom,
  FaBrush,
  FaBug,
  FaBuilding,
  FaBullhorn,
  FaBullseye,
  FaBurn,
  FaBusAlt,
  FaBus,
  FaBusinessTime,
  FaCalculator,
  FaCalendarAlt,
  FaCalendarCheck,
  FaCalendarDay,
  FaCalendarMinus,
  FaCalendarPlus,
  FaCalendarTimes,
  FaCalendarWeek,
  FaCalendar,
  FaCameraRetro,
  FaCamera,
  FaCampground,
  FaCandyCane,
  FaCapsules,
  FaCarAlt,
  FaCarBattery,
  FaCarCrash,
  FaCarSide,
  FaCar,
  FaCaravan,
  FaCarrot,
  FaCartArrowDown,
  FaCartPlus,
  FaCashRegister,
  FaCat,
  FaCertificate,
  FaChair,
  FaChalkboardTeacher,
  FaChalkboard,
  FaChargingStation,
  FaChartArea,
  FaChartBar,
  FaChartLine,
  FaChartPie,
  FaCheckCircle,
  FaCheckDouble,
  FaCheckSquare,
  FaCheck,
  FaCheese,
  FaChessBishop,
  FaChessBoard,
  FaChessKing,
  FaChessKnight,
  FaChessPawn,
  FaChessQueen,
  FaChessRook,
  FaChess,
  FaChild,
  FaChurch,
  FaCircleNotch,
  FaCircle,
  FaCity,
  FaClinicMedical,
  FaClipboardCheck,
  FaClipboardList,
  FaClipboard,
  FaClock,
  FaClone,
  FaCloudDownloadAlt,
  FaCloudMeatball,
  FaCloudMoonRain,
  FaCloudMoon,
  FaCloudRain,
  FaCloudShowersHeavy,
  FaCloudSunRain,
  FaCloudSun,
  FaCloudUploadAlt,
  FaCloud,
  FaCocktail,
  FaCodeBranch,
  FaCode,
  FaCoffee,
  FaCog,
  FaCogs,
  FaCoins,
  FaColumns,
  FaCommentAlt,
  FaCommentDollar,
  FaCommentDots,
  FaCommentMedical,
  FaCommentSlash,
  FaComment,
  FaCommentsDollar,
  FaComments,
  FaCompactDisc,
  FaCompass,
  FaCompressAlt,
  FaCompressArrowsAlt,
  FaCompress,
  FaConciergeBell,
  FaCookieBite,
  FaCookie,
  FaCopy,
  FaCopyright,
  FaCouch,
  FaCreditCard,
  FaCropAlt,
  FaCrop,
  FaCross,
  FaCrosshairs,
  FaCrow,
  FaCrown,
  FaCrutch,
  FaCube,
  FaCubes,
  FaCut,
  FaDatabase,
  FaDeaf,
  FaDemocrat,
  FaDesktop,
  FaDharmachakra,
  FaDiagnoses,
  FaDiceD20,
  FaDiceD6,
  FaDiceFive,
  FaDiceFour,
  FaDiceOne,
  FaDiceSix,
  FaDiceThree,
  FaDiceTwo,
  FaDice,
  FaDigitalTachograph,
  FaDirections,
  FaDisease,
  FaDivide,
  FaDizzy,
  FaDna,
  FaDog,
  FaDollarSign,
  FaDollyFlatbed,
  FaDolly,
  FaDonate,
  FaDoorClosed,
  FaDoorOpen,
  FaDotCircle,
  FaDove,
  FaDownload,
  FaDraftingCompass,
  FaDragon,
  FaDrawPolygon,
  FaDrumSteelpan,
  FaDrum,
  FaDrumstickBite,
  FaDumbbell,
  FaDumpsterFire,
  FaDumpster,
  FaDungeon,
  FaEdit,
  FaEgg,
  FaEnvelopeOpenText,
  FaEnvelopeOpen,
  FaEnvelopeSquare,
  FaEnvelope,
  FaEquals,
  FaEraser,
  FaEthernet,
  FaEuroSign,
  FaExchangeAlt,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaExclamation,
  FaExpand,
  FaExternalLinkAlt,
  FaExternalLinkSquareAlt,
  FaEyeDropper,
  FaEyeSlash,
  FaEye,
  FaFan,
  FaFaucet,
  FaFax,
  FaFeatherAlt,
  FaFeather,
  FaFemale,
  FaFighterJet,
  FaFileAlt,
  FaFileArchive,
  FaFileAudio,
  FaFileCode,
  FaFileContract,
  FaFileCsv,
  FaFileDownload,
  FaFileExcel,
  FaFileExport,
  FaFileImage,
  FaFileImport,
  FaFileInvoiceDollar,
  FaFileInvoice,
  FaFileMedicalAlt,
  FaFileMedical,
  FaFilePdf,
  FaFilePowerpoint,
  FaFilePrescription,
  FaFileSignature,
  FaFileUpload,
  FaFileVideo,
  FaFileWord,
  FaFile,
  FaFillDrip,
  FaFill,
  FaFilm,
  FaFilter,
  FaFingerprint,
  FaFireAlt,
  FaFireExtinguisher,
  FaFire,
  FaFirstAid,
  FaFish,
  FaFistRaised,
  FaFlagCheckered,
  FaFlagUsa,
  FaFlag,
  FaFlask,
  FaFlushed,
  FaFolderMinus,
  FaFolderOpen,
  FaFolderPlus,
  FaFolder,
  FaFont,
  FaFootballBall,
  FaForward,
  FaFrog,
  FaFrownOpen,
  FaFrown,
  FaFunnelDollar,
  FaFutbol,
  FaGamepad,
  FaGasPump,
  FaGavel,
  FaGem,
  FaGenderless,
  FaGhost,
  FaGift,
  FaGifts,
  FaGlassCheers,
  FaGlassMartiniAlt,
  FaGlassMartini,
  FaGlassWhiskey,
  FaGlasses,
  FaGlobeAfrica,
  FaGlobeAmericas,
  FaGlobeAsia,
  FaGlobeEurope,
  FaGlobe,
  FaGolfBall,
  FaGopuram,
  FaGraduationCap,
  FaGreaterThanEqual,
  FaGreaterThan,
  FaGrimace,
  FaGrinAlt,
  FaGrinBeamSweat,
  FaGrinBeam,
  FaGrinHearts,
  FaGrinSquintTears,
  FaGrinSquint,
  FaGrinStars,
  FaGrinTears,
  FaGrinTongueSquint,
  FaGrinTongueWink,
  FaGrinTongue,
  FaGrinWink,
  FaGrin,
  FaGuitar,
  FaHSquare,
  FaHamburger,
  FaHammer,
  FaHamsa,
  FaHandHoldingHeart,
  FaHandHoldingMedical,
  FaHandHoldingUsd,
  FaHandHoldingWater,
  FaHandHolding,
  FaHandLizard,
  FaHandMiddleFinger,
  FaHandPaper,
  FaHandPeace,
  FaHandPointDown,
  FaHandPointLeft,
  FaHandPointRight,
  FaHandPointUp,
  FaHandPointer,
  FaHandRock,
  FaHandScissors,
  FaHandSparkles,
  FaHandSpock,
  FaHandsHelping,
  FaHandsWash,
  FaHands,
  FaHandshakeAltSlash,
  FaHandshakeSlash,
  FaHandshake,
  FaHanukiah,
  FaHardHat,
  FaHashtag,
  FaHatCowboySide,
  FaHatCowboy,
  FaHatWizard,
  FaHdd,
  FaHeadSideCoughSlash,
  FaHeadSideCough,
  FaHeadSideMask,
  FaHeadSideVirus,
  FaHeading,
  FaHeadphonesAlt,
  FaHeadphones,
  FaHeadset,
  FaHeartBroken,
  FaHeart,
  FaHeartbeat,
  FaHelicopter,
  FaHighlighter,
  FaHiking,
  FaHippo,
  FaHistory,
  FaHockeyPuck,
  FaHollyBerry,
  FaHome,
  FaHorseHead,
  FaHorse,
  FaHospitalAlt,
  FaHospitalSymbol,
  FaHospitalUser,
  FaHospital,
  FaHotTub,
  FaHotdog,
  FaHotel,
  FaHourglassEnd,
  FaHourglassHalf,
  FaHourglassStart,
  FaHourglass,
  FaHouseDamage,
  FaHouseUser,
  FaHryvnia,
  FaICursor,
  FaIceCream,
  FaIcicles,
  FaIcons,
  FaIdBadge,
  FaIdCardAlt,
  FaIdCard,
  FaIgloo,
  FaImage,
  FaImages,
  FaInbox,
  FaIndent,
  FaIndustry,
  FaInfinity,
  FaInfoCircle,
  FaInfo,
  FaItalic,
  FaJedi,
  FaJoint,
  FaJournalWhills,
  FaKaaba,
  FaKey,
  FaKeyboard,
  FaKhanda,
  FaKissBeam,
  FaKissWinkHeart,
  FaKiss,
  FaKiwiBird,
  FaLandmark,
  FaLanguage,
  FaLaptopCode,
  FaLaptopHouse,
  FaLaptopMedical,
  FaLaptop,
  FaLaughBeam,
  FaLaughSquint,
  FaLaughWink,
  FaLaugh,
  FaLayerGroup,
  FaLeaf,
  FaLemon,
  FaLessThanEqual,
  FaLessThan,
  FaLifeRing,
  FaLightbulb,
  FaLink,
  FaLiraSign,
  FaListAlt,
  FaLocationArrow,
  FaLockOpen,
  FaLock,
  FaLowVision,
  FaLuggageCart,
  FaLungsVirus,
  FaLungs,
  FaMagic,
  FaMagnet,
  FaMailBulk,
  FaMale,
  FaMapMarkedAlt,
  FaMapMarked,
  FaMapMarkerAlt,
  FaMapMarker,
  FaMapPin,
  FaMapSigns,
  FaMap,
  FaMarker,
  FaMarsDouble,
  FaMarsStrokeH,
  FaMarsStrokeV,
  FaMarsStroke,
  FaMars,
  FaMask,
  FaMedal,
  FaMedkit,
  FaMehBlank,
  FaMehRollingEyes,
  FaMeh,
  FaMemory,
  FaMenorah,
  FaMercury,
  FaMeteor,
  FaMicrochip,
  FaMicrophoneAltSlash,
  FaMicrophoneAlt,
  FaMicrophoneSlash,
  FaMicrophone,
  FaMicroscope,
  FaMinusCircle,
  FaMinusSquare,
  FaMinus,
  FaMitten,
  FaMobileAlt,
  FaMobile,
  FaMoneyBillAlt,
  FaMoneyBillWaveAlt,
  FaMoneyBillWave,
  FaMoneyBill,
  FaMoneyCheckAlt,
  FaMoneyCheck,
  FaMonument,
  FaMoon,
  FaMortarPestle,
  FaMosque,
  FaMotorcycle,
  FaMountain,
  FaMousePointer,
  FaMouse,
  FaMugHot,
  FaMusic,
  FaNetworkWired,
  FaNeuter,
  FaNewspaper,
  FaNotEqual,
  FaNotesMedical,
  FaObjectGroup,
  FaObjectUngroup,
  FaOilCan,
  FaOm,
  FaOtter,
  FaOutdent,
  FaPager,
  FaPaintBrush,
  FaPaintRoller,
  FaPalette,
  FaPallet,
  FaPaperPlane,
  FaPaperclip,
  FaParachuteBox,
  FaParagraph,
  FaParking,
  FaPassport,
  FaPastafarianism,
  FaPaste,
  FaPauseCircle,
  FaPause,
  FaPaw,
  FaPeace,
  FaPenAlt,
  FaPenFancy,
  FaPenNib,
  FaPenSquare,
  FaPen,
  FaPencilAlt,
  FaPencilRuler,
  FaPeopleArrows,
  FaPeopleCarry,
  FaPepperHot,
  FaPercent,
  FaPercentage,
  FaPersonBooth,
  FaPhoneAlt,
  FaPhoneSlash,
  FaPhoneSquareAlt,
  FaPhoneSquare,
  FaPhoneVolume,
  FaPhone,
  FaPhotoVideo,
  FaPiggyBank,
  FaPills,
  FaPizzaSlice,
  FaPlaceOfWorship,
  FaPlaneArrival,
  FaPlaneDeparture,
  FaPlaneSlash,
  FaPlane,
  FaPlayCircle,
  FaPlay,
  FaPlug,
  FaPlusCircle,
  FaPlusSquare,
  FaPlus,
  FaPodcast,
  FaPollH,
  FaPoll,
  FaPooStorm,
  FaPoo,
  FaPoop,
  FaPortrait,
  FaPoundSign,
  FaPowerOff,
  FaPray,
  FaPrayingHands,
  FaPrescriptionBottleAlt,
  FaPrescriptionBottle,
  FaPrescription,
  FaPrint,
  FaProcedures,
  FaProjectDiagram,
  FaPumpMedical,
  FaPumpSoap,
  FaPuzzlePiece,
  FaQrcode,
  FaQuestionCircle,
  FaQuestion,
  FaQuidditch,
  FaQuoteLeft,
  FaQuoteRight,
  FaQuran,
  FaRadiationAlt,
  FaRadiation,
  FaRainbow,
  FaRandom,
  FaReceipt,
  FaRecordVinyl,
  FaRecycle,
  FaRedoAlt,
  FaRedo,
  FaRegistered,
  FaRemoveFormat,
  FaReplyAll,
  FaReply,
  FaRepublican,
  FaRestroom,
  FaRetweet,
  FaRibbon,
  FaRing,
  FaRoad,
  FaRobot,
  FaRocket,
  FaRoute,
  FaRssSquare,
  FaRss,
  FaRubleSign,
  FaRulerCombined,
  FaRulerHorizontal,
  FaRulerVertical,
  FaRuler,
  FaRunning,
  FaRupeeSign,
  FaSadCry,
  FaSadTear,
  FaSatelliteDish,
  FaSatellite,
  FaSave,
  FaSchool,
  FaScrewdriver,
  FaScroll,
  FaSdCard,
  FaSearchDollar,
  FaSearchLocation,
  FaSearchMinus,
  FaSearchPlus,
  FaSearch,
  FaSeedling,
  FaServer,
  FaShapes,
  FaShareAltSquare,
  FaShareAlt,
  FaShareSquare,
  FaShare,
  FaShekelSign,
  FaShieldAlt,
  FaShieldVirus,
  FaShip,
  FaShippingFast,
  FaShoePrints,
  FaShoppingBag,
  FaShoppingBasket,
  FaShoppingCart,
  FaShower,
  FaShuttleVan,
  FaSignInAlt,
  FaSignLanguage,
  FaSignOutAlt,
  FaSign,
  FaSignal,
  FaSignature,
  FaSimCard,
  FaSink,
  FaSitemap,
  FaSkating,
  FaSkiingNordic,
  FaSkiing,
  FaSkullCrossbones,
  FaSkull,
  FaSlash,
  FaSleigh,
  FaSlidersH,
  FaSmileBeam,
  FaSmileWink,
  FaSmile,
  FaSmog,
  FaSmokingBan,
  FaSmoking,
  FaSms,
  FaSnowboarding,
  FaSnowflake,
  FaSnowman,
  FaSnowplow,
  FaSoap,
  FaSocks,
  FaSolarPanel,
  FaSpa,
  FaSpaceShuttle,
  FaSpellCheck,
  FaSpider,
  FaSpinner,
  FaSplotch,
  FaSprayCan,
  FaSquareFull,
  FaSquareRootAlt,
  FaSquare,
  FaStamp,
  FaStarAndCrescent,
  FaStarHalfAlt,
  FaStarHalf,
  FaStarOfDavid,
  FaStarOfLife,
  FaStar,
  FaStepBackward,
  FaStepForward,
  FaStethoscope,
  FaStickyNote,
  FaStopCircle,
  FaStop,
  FaStopwatch20,
  FaStopwatch,
  FaStoreAltSlash,
  FaStoreAlt,
  FaStoreSlash,
  FaStore,
  FaStream,
  FaStreetView,
  FaStrikethrough,
  FaStroopwafel,
  FaSubscript,
  FaSubway,
  FaSuitcaseRolling,
  FaSuitcase,
  FaSun,
  FaSuperscript,
  FaSurprise,
  FaSwatchbook,
  FaSwimmer,
  FaSwimmingPool,
  FaSynagogue,
  FaSyncAlt,
  FaSync,
  FaSyringe,
  FaTableTennis,
  FaTable,
  FaTabletAlt,
  FaTablet,
  FaTablets,
  FaTachometerAlt,
  FaTag,
  FaTags,
  FaTape,
  FaTasks,
  FaTaxi,
  FaTeethOpen,
  FaTeeth,
  FaTemperatureHigh,
  FaTemperatureLow,
  FaTenge,
  FaTerminal,
  FaTextHeight,
  FaTextWidth,
  FaThLarge,
  FaThList,
  FaTh,
  FaTheaterMasks,
  FaThermometerEmpty,
  FaThermometerFull,
  FaThermometerHalf,
  FaThermometerQuarter,
  FaThermometerThreeQuarters,
  FaThermometer,
  FaThumbsDown,
  FaThumbsUp,
  FaThumbtack,
  FaTicketAlt,
  FaTimesCircle,
  FaTimes,
  FaTintSlash,
  FaTint,
  FaTired,
  FaToggleOff,
  FaToggleOn,
  FaToiletPaperSlash,
  FaToiletPaper,
  FaToilet,
  FaToolbox,
  FaTools,
  FaTooth,
  FaTorah,
  FaToriiGate,
  FaTractor,
  FaTrademark,
  FaTrafficLight,
  FaTrailer,
  FaTrain,
  FaTram,
  FaTransgenderAlt,
  FaTransgender,
  FaTrashAlt,
  FaTrashRestoreAlt,
  FaTrashRestore,
  FaTrash,
  FaTree,
  FaTrophy,
  FaTruckLoading,
  FaTruckMonster,
  FaTruckMoving,
  FaTruckPickup,
  FaTruck,
  FaTshirt,
  FaTty,
  FaTv,
  FaUmbrellaBeach,
  FaUmbrella,
  FaUnderline,
  FaUndoAlt,
  FaUndo,
  FaUniversalAccess,
  FaUniversity,
  FaUnlink,
  FaUnlockAlt,
  FaUnlock,
  FaUpload,
  FaUserAltSlash,
  FaUserAlt,
  FaUserAstronaut,
  FaUserCheck,
  FaUserCircle,
  FaUserClock,
  FaUserCog,
  FaUserEdit,
  FaUserFriends,
  FaUserGraduate,
  FaUserInjured,
  FaUserLock,
  FaUserMd,
  FaUserMinus,
  FaUserNinja,
  FaUserNurse,
  FaUserPlus,
  FaUserSecret,
  FaUserShield,
  FaUserSlash,
  FaUserTag,
  FaUserTie,
  FaUserTimes,
  FaUser,
  FaUsersCog,
  FaUsersSlash,
  FaUsers,
  FaUtensilSpoon,
  FaUtensils,
  FaVectorSquare,
  FaVenusDouble,
  FaVenusMars,
  FaVenus,
  FaVestPatches,
  FaVest,
  FaVial,
  FaVials,
  FaVideoSlash,
  FaVideo,
  FaVihara,
  FaVirusSlash,
  FaVirus,
  FaViruses,
  FaVoicemail,
  FaVolleyballBall,
  FaVolumeDown,
  FaVolumeMute,
  FaVolumeOff,
  FaVolumeUp,
  FaVoteYea,
  FaVrCardboard,
  FaWalking,
  FaWallet,
  FaWarehouse,
  FaWater,
  FaWaveSquare,
  FaWeightHanging,
  FaWeight,
  FaWheelchair,
  FaWifi,
  FaWind,
  FaWindowClose,
  FaWindowMaximize,
  FaWindowMinimize,
  FaWindowRestore,
  FaWineBottle,
  FaWineGlassAlt,
  FaWineGlass,
  FaWonSign,
  FaWrench,
  FaXRay,
  FaYenSign,
  FaYinYang,
  FaRegAddressBook,
  FaRegAddressCard,
  FaRegAngry,
  FaRegBellSlash,
  FaRegBell,
  FaRegBookmark,
  FaRegBuilding,
  FaRegCalendarAlt,
  FaRegCalendarCheck,
  FaRegCalendarMinus,
  FaRegCalendarPlus,
  FaRegCalendarTimes,
  FaRegCalendar,
  FaRegChartBar,
  FaRegCheckCircle,
  FaRegCheckSquare,
  FaRegCircle,
  FaRegClipboard,
  FaRegClock,
  FaRegClone,
  FaRegClosedCaptioning,
  FaRegCommentAlt,
  FaRegCommentDots,
  FaRegComment,
  FaRegComments,
  FaRegCompass,
  FaRegCopy,
  FaRegCopyright,
  FaRegCreditCard,
  FaRegDizzy,
  FaRegDotCircle,
  FaRegEdit,
  FaRegEnvelopeOpen,
  FaRegEnvelope,
  FaRegEyeSlash,
  FaRegEye,
  FaRegFileAlt,
  FaRegFileArchive,
  FaRegFileAudio,
  FaRegFileCode,
  FaRegFileExcel,
  FaRegFileImage,
  FaRegFilePdf,
  FaRegFilePowerpoint,
  FaRegFileVideo,
  FaRegFileWord,
  FaRegFile,
  FaRegFlag,
  FaRegFlushed,
  FaRegFolderOpen,
  FaRegFolder,
  FaRegFrownOpen,
  FaRegFrown,
  FaRegFutbol,
  FaRegGem,
  FaRegGrimace,
  FaRegGrinAlt,
  FaRegGrinBeamSweat,
  FaRegGrinBeam,
  FaRegGrinHearts,
  FaRegGrinSquintTears,
  FaRegGrinSquint,
  FaRegGrinStars,
  FaRegGrinTears,
  FaRegGrinTongueSquint,
  FaRegGrinTongueWink,
  FaRegGrinTongue,
  FaRegGrinWink,
  FaRegGrin,
  FaRegHandLizard,
  FaRegHandPaper,
  FaRegHandPeace,
  FaRegHandPointDown,
  FaRegHandPointLeft,
  FaRegHandPointRight,
  FaRegHandPointUp,
  FaRegHandPointer,
  FaRegHandRock,
  FaRegHandScissors,
  FaRegHandSpock,
  FaRegHandshake,
  FaRegHdd,
  FaRegHeart,
  FaRegHospital,
  FaRegHourglass,
  FaRegIdBadge,
  FaRegIdCard,
  FaRegImage,
  FaRegImages,
  FaRegKeyboard,
  FaRegKissBeam,
  FaRegKissWinkHeart,
  FaRegKiss,
  FaRegLaughBeam,
  FaRegLaughSquint,
  FaRegLaughWink,
  FaRegLaugh,
  FaRegLemon,
  FaRegLifeRing,
  FaRegLightbulb,
  FaRegListAlt,
  FaRegMap,
  FaRegMehBlank,
  FaRegMehRollingEyes,
  FaRegMeh,
  FaRegMinusSquare,
  FaRegMoneyBillAlt,
  FaRegMoon,
  FaRegNewspaper,
  FaRegObjectGroup,
  FaRegObjectUngroup,
  FaRegPaperPlane,
  FaRegPauseCircle,
  FaRegPlayCircle,
  FaRegPlusSquare,
  FaRegQuestionCircle,
  FaRegRegistered,
  FaRegSadCry,
  FaRegSadTear,
  FaRegSave,
  FaRegShareSquare,
  FaRegSmileBeam,
  FaRegSmileWink,
  FaRegSmile,
  FaRegSnowflake,
  FaRegSquare,
  FaRegStarHalf,
  FaRegStar,
  FaRegStickyNote,
  FaRegStopCircle,
  FaRegSun,
  FaRegSurprise,
  FaRegThumbsDown,
  FaRegThumbsUp,
  FaRegTimesCircle,
  FaRegTired,
  FaRegTrashAlt,
  FaRegUserCircle,
  FaRegUser,
  FaRegWindowClose,
  FaRegWindowMaximize,
  FaRegWindowMinimize,
  FaRegWindowRestore,
} from "react-icons/fa";
import { GiBarrelLeak, GiBarrel, GiCellarBarrels, GiCooler, GiCube } from "react-icons/gi";
import { RiFridgeFill, RiFridgeLine } from "react-icons/ri";
import { IconContext } from "react-icons";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import BbKeg from "../../svgs/BbKeg";
import styled from "../Themes";

const FontAwesomeIcons = {
  FaAccessibleIcon,
  FaApple,
  FaBitcoin,
  FaBlackTie,
  FaBluetoothB,
  FaBluetooth,
  FaBtc,
  FaCanadianMapleLeaf,
  FaDiscord,
  FaEthereum,
  FaItunesNote,
  FaSketch,
  FaSnapchatGhost,
  FaTwitter,
  FaUntappd,
  FaAddressBook,
  FaAddressCard,
  FaAirFreshener,
  FaAllergies,
  FaAmbulance,
  FaAmericanSignLanguageInterpreting,
  FaAnchor,
  FaAngry,
  FaAppleAlt,
  FaArchive,
  FaArchway,
  FaAssistiveListeningSystems,
  FaAsterisk,
  FaAt,
  FaAtlas,
  FaAtom,
  FaAward,
  FaBabyCarriage,
  FaBaby,
  FaBacon,
  FaBacteria,
  FaBacterium,
  FaBahai,
  FaBalanceScaleLeft,
  FaBalanceScaleRight,
  FaBalanceScale,
  FaBan,
  FaBandAid,
  FaBarcode,
  FaBaseballBall,
  FaBasketballBall,
  FaBath,
  FaBatteryEmpty,
  FaBatteryFull,
  FaBatteryHalf,
  FaBatteryQuarter,
  FaBatteryThreeQuarters,
  FaBed,
  FaBeer,
  FaBellSlash,
  FaBell,
  FaBible,
  FaBicycle,
  FaBiking,
  FaBinoculars,
  FaBiohazard,
  FaBirthdayCake,
  FaBlenderPhone,
  FaBlender,
  FaBlind,
  FaBolt,
  FaBomb,
  FaBone,
  FaBookDead,
  FaBookMedical,
  FaBookOpen,
  FaBookReader,
  FaBook,
  FaBookmark,
  FaBowlingBall,
  FaBoxOpen,
  FaBoxTissue,
  FaBox,
  FaBoxes,
  FaBraille,
  FaBrain,
  FaBreadSlice,
  FaBriefcaseMedical,
  FaBriefcase,
  FaBroadcastTower,
  FaBroom,
  FaBrush,
  FaBug,
  FaBuilding,
  FaBullhorn,
  FaBullseye,
  FaBurn,
  FaBusAlt,
  FaBus,
  FaBusinessTime,
  FaCalculator,
  FaCalendarAlt,
  FaCalendarCheck,
  FaCalendarDay,
  FaCalendarMinus,
  FaCalendarPlus,
  FaCalendarTimes,
  FaCalendarWeek,
  FaCalendar,
  FaCameraRetro,
  FaCamera,
  FaCampground,
  FaCandyCane,
  FaCapsules,
  FaCarAlt,
  FaCarBattery,
  FaCarCrash,
  FaCarSide,
  FaCar,
  FaCaravan,
  FaCarrot,
  FaCartArrowDown,
  FaCartPlus,
  FaCashRegister,
  FaCat,
  FaCertificate,
  FaChair,
  FaChalkboardTeacher,
  FaChalkboard,
  FaChargingStation,
  FaChartArea,
  FaChartBar,
  FaChartLine,
  FaChartPie,
  FaCheckCircle,
  FaCheckDouble,
  FaCheckSquare,
  FaCheck,
  FaCheese,
  FaChessBishop,
  FaChessBoard,
  FaChessKing,
  FaChessKnight,
  FaChessPawn,
  FaChessQueen,
  FaChessRook,
  FaChess,
  FaChild,
  FaChurch,
  FaCircleNotch,
  FaCircle,
  FaCity,
  FaClinicMedical,
  FaClipboardCheck,
  FaClipboardList,
  FaClipboard,
  FaClock,
  FaClone,
  FaCloudDownloadAlt,
  FaCloudMeatball,
  FaCloudMoonRain,
  FaCloudMoon,
  FaCloudRain,
  FaCloudShowersHeavy,
  FaCloudSunRain,
  FaCloudSun,
  FaCloudUploadAlt,
  FaCloud,
  FaCocktail,
  FaCodeBranch,
  FaCode,
  FaCoffee,
  FaCog,
  FaCogs,
  FaCoins,
  FaColumns,
  FaCommentAlt,
  FaCommentDollar,
  FaCommentDots,
  FaCommentMedical,
  FaCommentSlash,
  FaComment,
  FaCommentsDollar,
  FaComments,
  FaCompactDisc,
  FaCompass,
  FaCompressAlt,
  FaCompressArrowsAlt,
  FaCompress,
  FaConciergeBell,
  FaCookieBite,
  FaCookie,
  FaCopy,
  FaCopyright,
  FaCouch,
  FaCreditCard,
  FaCropAlt,
  FaCrop,
  FaCross,
  FaCrosshairs,
  FaCrow,
  FaCrown,
  FaCrutch,
  FaCube,
  FaCubes,
  FaCut,
  FaDatabase,
  FaDeaf,
  FaDemocrat,
  FaDesktop,
  FaDharmachakra,
  FaDiagnoses,
  FaDiceD20,
  FaDiceD6,
  FaDiceFive,
  FaDiceFour,
  FaDiceOne,
  FaDiceSix,
  FaDiceThree,
  FaDiceTwo,
  FaDice,
  FaDigitalTachograph,
  FaDirections,
  FaDisease,
  FaDivide,
  FaDizzy,
  FaDna,
  FaDog,
  FaDollarSign,
  FaDollyFlatbed,
  FaDolly,
  FaDonate,
  FaDoorClosed,
  FaDoorOpen,
  FaDotCircle,
  FaDove,
  FaDownload,
  FaDraftingCompass,
  FaDragon,
  FaDrawPolygon,
  FaDrumSteelpan,
  FaDrum,
  FaDrumstickBite,
  FaDumbbell,
  FaDumpsterFire,
  FaDumpster,
  FaDungeon,
  FaEdit,
  FaEgg,
  FaEnvelopeOpenText,
  FaEnvelopeOpen,
  FaEnvelopeSquare,
  FaEnvelope,
  FaEquals,
  FaEraser,
  FaEthernet,
  FaEuroSign,
  FaExchangeAlt,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaExclamation,
  FaExpand,
  FaExternalLinkAlt,
  FaExternalLinkSquareAlt,
  FaEyeDropper,
  FaEyeSlash,
  FaEye,
  FaFan,
  FaFaucet,
  FaFax,
  FaFeatherAlt,
  FaFeather,
  FaFemale,
  FaFighterJet,
  FaFileAlt,
  FaFileArchive,
  FaFileAudio,
  FaFileCode,
  FaFileContract,
  FaFileCsv,
  FaFileDownload,
  FaFileExcel,
  FaFileExport,
  FaFileImage,
  FaFileImport,
  FaFileInvoiceDollar,
  FaFileInvoice,
  FaFileMedicalAlt,
  FaFileMedical,
  FaFilePdf,
  FaFilePowerpoint,
  FaFilePrescription,
  FaFileSignature,
  FaFileUpload,
  FaFileVideo,
  FaFileWord,
  FaFile,
  FaFillDrip,
  FaFill,
  FaFilm,
  FaFilter,
  FaFingerprint,
  FaFireAlt,
  FaFireExtinguisher,
  FaFire,
  FaFirstAid,
  FaFish,
  FaFistRaised,
  FaFlagCheckered,
  FaFlagUsa,
  FaFlag,
  FaFlask,
  FaFlushed,
  FaFolderMinus,
  FaFolderOpen,
  FaFolderPlus,
  FaFolder,
  FaFont,
  FaFootballBall,
  FaForward,
  FaFrog,
  FaFrownOpen,
  FaFrown,
  FaFunnelDollar,
  FaFutbol,
  FaGamepad,
  FaGasPump,
  FaGavel,
  FaGem,
  FaGenderless,
  FaGhost,
  FaGift,
  FaGifts,
  FaGlassCheers,
  FaGlassMartiniAlt,
  FaGlassMartini,
  FaGlassWhiskey,
  FaGlasses,
  FaGlobeAfrica,
  FaGlobeAmericas,
  FaGlobeAsia,
  FaGlobeEurope,
  FaGlobe,
  FaGolfBall,
  FaGopuram,
  FaGraduationCap,
  FaGreaterThanEqual,
  FaGreaterThan,
  FaGrimace,
  FaGrinAlt,
  FaGrinBeamSweat,
  FaGrinBeam,
  FaGrinHearts,
  FaGrinSquintTears,
  FaGrinSquint,
  FaGrinStars,
  FaGrinTears,
  FaGrinTongueSquint,
  FaGrinTongueWink,
  FaGrinTongue,
  FaGrinWink,
  FaGrin,
  FaGuitar,
  FaHSquare,
  FaHamburger,
  FaHammer,
  FaHamsa,
  FaHandHoldingHeart,
  FaHandHoldingMedical,
  FaHandHoldingUsd,
  FaHandHoldingWater,
  FaHandHolding,
  FaHandLizard,
  FaHandMiddleFinger,
  FaHandPaper,
  FaHandPeace,
  FaHandPointDown,
  FaHandPointLeft,
  FaHandPointRight,
  FaHandPointUp,
  FaHandPointer,
  FaHandRock,
  FaHandScissors,
  FaHandSparkles,
  FaHandSpock,
  FaHandsHelping,
  FaHandsWash,
  FaHands,
  FaHandshakeAltSlash,
  FaHandshakeSlash,
  FaHandshake,
  FaHanukiah,
  FaHardHat,
  FaHashtag,
  FaHatCowboySide,
  FaHatCowboy,
  FaHatWizard,
  FaHdd,
  FaHeadSideCoughSlash,
  FaHeadSideCough,
  FaHeadSideMask,
  FaHeadSideVirus,
  FaHeading,
  FaHeadphonesAlt,
  FaHeadphones,
  FaHeadset,
  FaHeartBroken,
  FaHeart,
  FaHeartbeat,
  FaHelicopter,
  FaHighlighter,
  FaHiking,
  FaHippo,
  FaHistory,
  FaHockeyPuck,
  FaHollyBerry,
  FaHome,
  FaHorseHead,
  FaHorse,
  FaHospitalAlt,
  FaHospitalSymbol,
  FaHospitalUser,
  FaHospital,
  FaHotTub,
  FaHotdog,
  FaHotel,
  FaHourglassEnd,
  FaHourglassHalf,
  FaHourglassStart,
  FaHourglass,
  FaHouseDamage,
  FaHouseUser,
  FaHryvnia,
  FaICursor,
  FaIceCream,
  FaIcicles,
  FaIcons,
  FaIdBadge,
  FaIdCardAlt,
  FaIdCard,
  FaIgloo,
  FaImage,
  FaImages,
  FaInbox,
  FaIndent,
  FaIndustry,
  FaInfinity,
  FaInfoCircle,
  FaInfo,
  FaItalic,
  FaJedi,
  FaJoint,
  FaJournalWhills,
  FaKaaba,
  FaKey,
  FaKeyboard,
  FaKhanda,
  FaKissBeam,
  FaKissWinkHeart,
  FaKiss,
  FaKiwiBird,
  FaLandmark,
  FaLanguage,
  FaLaptopCode,
  FaLaptopHouse,
  FaLaptopMedical,
  FaLaptop,
  FaLaughBeam,
  FaLaughSquint,
  FaLaughWink,
  FaLaugh,
  FaLayerGroup,
  FaLeaf,
  FaLemon,
  FaLessThanEqual,
  FaLessThan,
  FaLifeRing,
  FaLightbulb,
  FaLink,
  FaLiraSign,
  FaListAlt,
  FaLocationArrow,
  FaLockOpen,
  FaLock,
  FaLowVision,
  FaLuggageCart,
  FaLungsVirus,
  FaLungs,
  FaMagic,
  FaMagnet,
  FaMailBulk,
  FaMale,
  FaMapMarkedAlt,
  FaMapMarked,
  FaMapMarkerAlt,
  FaMapMarker,
  FaMapPin,
  FaMapSigns,
  FaMap,
  FaMarker,
  FaMarsDouble,
  FaMarsStrokeH,
  FaMarsStrokeV,
  FaMarsStroke,
  FaMars,
  FaMask,
  FaMedal,
  FaMedkit,
  FaMehBlank,
  FaMehRollingEyes,
  FaMeh,
  FaMemory,
  FaMenorah,
  FaMercury,
  FaMeteor,
  FaMicrochip,
  FaMicrophoneAltSlash,
  FaMicrophoneAlt,
  FaMicrophoneSlash,
  FaMicrophone,
  FaMicroscope,
  FaMinusCircle,
  FaMinusSquare,
  FaMinus,
  FaMitten,
  FaMobileAlt,
  FaMobile,
  FaMoneyBillAlt,
  FaMoneyBillWaveAlt,
  FaMoneyBillWave,
  FaMoneyBill,
  FaMoneyCheckAlt,
  FaMoneyCheck,
  FaMonument,
  FaMoon,
  FaMortarPestle,
  FaMosque,
  FaMotorcycle,
  FaMountain,
  FaMousePointer,
  FaMouse,
  FaMugHot,
  FaMusic,
  FaNetworkWired,
  FaNeuter,
  FaNewspaper,
  FaNotEqual,
  FaNotesMedical,
  FaObjectGroup,
  FaObjectUngroup,
  FaOilCan,
  FaOm,
  FaOtter,
  FaOutdent,
  FaPager,
  FaPaintBrush,
  FaPaintRoller,
  FaPalette,
  FaPallet,
  FaPaperPlane,
  FaPaperclip,
  FaParachuteBox,
  FaParagraph,
  FaParking,
  FaPassport,
  FaPastafarianism,
  FaPaste,
  FaPauseCircle,
  FaPause,
  FaPaw,
  FaPeace,
  FaPenAlt,
  FaPenFancy,
  FaPenNib,
  FaPenSquare,
  FaPen,
  FaPencilAlt,
  FaPencilRuler,
  FaPeopleArrows,
  FaPeopleCarry,
  FaPepperHot,
  FaPercent,
  FaPercentage,
  FaPersonBooth,
  FaPhoneAlt,
  FaPhoneSlash,
  FaPhoneSquareAlt,
  FaPhoneSquare,
  FaPhoneVolume,
  FaPhone,
  FaPhotoVideo,
  FaPiggyBank,
  FaPills,
  FaPizzaSlice,
  FaPlaceOfWorship,
  FaPlaneArrival,
  FaPlaneDeparture,
  FaPlaneSlash,
  FaPlane,
  FaPlayCircle,
  FaPlay,
  FaPlug,
  FaPlusCircle,
  FaPlusSquare,
  FaPlus,
  FaPodcast,
  FaPollH,
  FaPoll,
  FaPooStorm,
  FaPoo,
  FaPoop,
  FaPortrait,
  FaPoundSign,
  FaPowerOff,
  FaPray,
  FaPrayingHands,
  FaPrescriptionBottleAlt,
  FaPrescriptionBottle,
  FaPrescription,
  FaPrint,
  FaProcedures,
  FaProjectDiagram,
  FaPumpMedical,
  FaPumpSoap,
  FaPuzzlePiece,
  FaQrcode,
  FaQuestionCircle,
  FaQuestion,
  FaQuidditch,
  FaQuoteLeft,
  FaQuoteRight,
  FaQuran,
  FaRadiationAlt,
  FaRadiation,
  FaRainbow,
  FaRandom,
  FaReceipt,
  FaRecordVinyl,
  FaRecycle,
  FaRedoAlt,
  FaRedo,
  FaRegistered,
  FaRemoveFormat,
  FaReplyAll,
  FaReply,
  FaRepublican,
  FaRestroom,
  FaRetweet,
  FaRibbon,
  FaRing,
  FaRoad,
  FaRobot,
  FaRocket,
  FaRoute,
  FaRssSquare,
  FaRss,
  FaRubleSign,
  FaRulerCombined,
  FaRulerHorizontal,
  FaRulerVertical,
  FaRuler,
  FaRunning,
  FaRupeeSign,
  FaSadCry,
  FaSadTear,
  FaSatelliteDish,
  FaSatellite,
  FaSave,
  FaSchool,
  FaScrewdriver,
  FaScroll,
  FaSdCard,
  FaSearchDollar,
  FaSearchLocation,
  FaSearchMinus,
  FaSearchPlus,
  FaSearch,
  FaSeedling,
  FaServer,
  FaShapes,
  FaShareAltSquare,
  FaShareAlt,
  FaShareSquare,
  FaShare,
  FaShekelSign,
  FaShieldAlt,
  FaShieldVirus,
  FaShip,
  FaShippingFast,
  FaShoePrints,
  FaShoppingBag,
  FaShoppingBasket,
  FaShoppingCart,
  FaShower,
  FaShuttleVan,
  FaSignInAlt,
  FaSignLanguage,
  FaSignOutAlt,
  FaSign,
  FaSignal,
  FaSignature,
  FaSimCard,
  FaSink,
  FaSitemap,
  FaSkating,
  FaSkiingNordic,
  FaSkiing,
  FaSkullCrossbones,
  FaSkull,
  FaSlash,
  FaSleigh,
  FaSlidersH,
  FaSmileBeam,
  FaSmileWink,
  FaSmile,
  FaSmog,
  FaSmokingBan,
  FaSmoking,
  FaSms,
  FaSnowboarding,
  FaSnowflake,
  FaSnowman,
  FaSnowplow,
  FaSoap,
  FaSocks,
  FaSolarPanel,
  FaSpa,
  FaSpaceShuttle,
  FaSpellCheck,
  FaSpider,
  FaSpinner,
  FaSplotch,
  FaSprayCan,
  FaSquareFull,
  FaSquareRootAlt,
  FaSquare,
  FaStamp,
  FaStarAndCrescent,
  FaStarHalfAlt,
  FaStarHalf,
  FaStarOfDavid,
  FaStarOfLife,
  FaStar,
  FaStepBackward,
  FaStepForward,
  FaStethoscope,
  FaStickyNote,
  FaStopCircle,
  FaStop,
  FaStopwatch20,
  FaStopwatch,
  FaStoreAltSlash,
  FaStoreAlt,
  FaStoreSlash,
  FaStore,
  FaStream,
  FaStreetView,
  FaStrikethrough,
  FaStroopwafel,
  FaSubscript,
  FaSubway,
  FaSuitcaseRolling,
  FaSuitcase,
  FaSun,
  FaSuperscript,
  FaSurprise,
  FaSwatchbook,
  FaSwimmer,
  FaSwimmingPool,
  FaSynagogue,
  FaSyncAlt,
  FaSync,
  FaSyringe,
  FaTableTennis,
  FaTable,
  FaTabletAlt,
  FaTablet,
  FaTablets,
  FaTachometerAlt,
  FaTag,
  FaTags,
  FaTape,
  FaTasks,
  FaTaxi,
  FaTeethOpen,
  FaTeeth,
  FaTemperatureHigh,
  FaTemperatureLow,
  FaTenge,
  FaTerminal,
  FaTextHeight,
  FaTextWidth,
  FaThLarge,
  FaThList,
  FaTh,
  FaTheaterMasks,
  FaThermometerEmpty,
  FaThermometerFull,
  FaThermometerHalf,
  FaThermometerQuarter,
  FaThermometerThreeQuarters,
  FaThermometer,
  FaThumbsDown,
  FaThumbsUp,
  FaThumbtack,
  FaTicketAlt,
  FaTimesCircle,
  FaTimes,
  FaTintSlash,
  FaTint,
  FaTired,
  FaToggleOff,
  FaToggleOn,
  FaToiletPaperSlash,
  FaToiletPaper,
  FaToilet,
  FaToolbox,
  FaTools,
  FaTooth,
  FaTorah,
  FaToriiGate,
  FaTractor,
  FaTrademark,
  FaTrafficLight,
  FaTrailer,
  FaTrain,
  FaTram,
  FaTransgenderAlt,
  FaTransgender,
  FaTrashAlt,
  FaTrashRestoreAlt,
  FaTrashRestore,
  FaTrash,
  FaTree,
  FaTrophy,
  FaTruckLoading,
  FaTruckMonster,
  FaTruckMoving,
  FaTruckPickup,
  FaTruck,
  FaTshirt,
  FaTty,
  FaTv,
  FaUmbrellaBeach,
  FaUmbrella,
  FaUnderline,
  FaUndoAlt,
  FaUndo,
  FaUniversalAccess,
  FaUniversity,
  FaUnlink,
  FaUnlockAlt,
  FaUnlock,
  FaUpload,
  FaUserAltSlash,
  FaUserAlt,
  FaUserAstronaut,
  FaUserCheck,
  FaUserCircle,
  FaUserClock,
  FaUserCog,
  FaUserEdit,
  FaUserFriends,
  FaUserGraduate,
  FaUserInjured,
  FaUserLock,
  FaUserMd,
  FaUserMinus,
  FaUserNinja,
  FaUserNurse,
  FaUserPlus,
  FaUserSecret,
  FaUserShield,
  FaUserSlash,
  FaUserTag,
  FaUserTie,
  FaUserTimes,
  FaUser,
  FaUsersCog,
  FaUsersSlash,
  FaUsers,
  FaUtensilSpoon,
  FaUtensils,
  FaVectorSquare,
  FaVenusDouble,
  FaVenusMars,
  FaVenus,
  FaVestPatches,
  FaVest,
  FaVial,
  FaVials,
  FaVideoSlash,
  FaVideo,
  FaVihara,
  FaVirusSlash,
  FaVirus,
  FaViruses,
  FaVoicemail,
  FaVolleyballBall,
  FaVolumeDown,
  FaVolumeMute,
  FaVolumeOff,
  FaVolumeUp,
  FaVoteYea,
  FaVrCardboard,
  FaWalking,
  FaWallet,
  FaWarehouse,
  FaWater,
  FaWaveSquare,
  FaWeightHanging,
  FaWeight,
  FaWheelchair,
  FaWifi,
  FaWind,
  FaWindowClose,
  FaWindowMaximize,
  FaWindowMinimize,
  FaWindowRestore,
  FaWineBottle,
  FaWineGlassAlt,
  FaWineGlass,
  FaWonSign,
  FaWrench,
  FaXRay,
  FaYenSign,
  FaYinYang,
  FaRegAddressBook,
  FaRegAddressCard,
  FaRegAngry,
  FaRegBellSlash,
  FaRegBell,
  FaRegBookmark,
  FaRegBuilding,
  FaRegCalendarAlt,
  FaRegCalendarCheck,
  FaRegCalendarMinus,
  FaRegCalendarPlus,
  FaRegCalendarTimes,
  FaRegCalendar,
  FaRegChartBar,
  FaRegCheckCircle,
  FaRegCheckSquare,
  FaRegCircle,
  FaRegClipboard,
  FaRegClock,
  FaRegClone,
  FaRegClosedCaptioning,
  FaRegCommentAlt,
  FaRegCommentDots,
  FaRegComment,
  FaRegComments,
  FaRegCompass,
  FaRegCopy,
  FaRegCopyright,
  FaRegCreditCard,
  FaRegDizzy,
  FaRegDotCircle,
  FaRegEdit,
  FaRegEnvelopeOpen,
  FaRegEnvelope,
  FaRegEyeSlash,
  FaRegEye,
  FaRegFileAlt,
  FaRegFileArchive,
  FaRegFileAudio,
  FaRegFileCode,
  FaRegFileExcel,
  FaRegFileImage,
  FaRegFilePdf,
  FaRegFilePowerpoint,
  FaRegFileVideo,
  FaRegFileWord,
  FaRegFile,
  FaRegFlag,
  FaRegFlushed,
  FaRegFolderOpen,
  FaRegFolder,
  FaRegFrownOpen,
  FaRegFrown,
  FaRegFutbol,
  FaRegGem,
  FaRegGrimace,
  FaRegGrinAlt,
  FaRegGrinBeamSweat,
  FaRegGrinBeam,
  FaRegGrinHearts,
  FaRegGrinSquintTears,
  FaRegGrinSquint,
  FaRegGrinStars,
  FaRegGrinTears,
  FaRegGrinTongueSquint,
  FaRegGrinTongueWink,
  FaRegGrinTongue,
  FaRegGrinWink,
  FaRegGrin,
  FaRegHandLizard,
  FaRegHandPaper,
  FaRegHandPeace,
  FaRegHandPointDown,
  FaRegHandPointLeft,
  FaRegHandPointRight,
  FaRegHandPointUp,
  FaRegHandPointer,
  FaRegHandRock,
  FaRegHandScissors,
  FaRegHandSpock,
  FaRegHandshake,
  FaRegHdd,
  FaRegHeart,
  FaRegHospital,
  FaRegHourglass,
  FaRegIdBadge,
  FaRegIdCard,
  FaRegImage,
  FaRegImages,
  FaRegKeyboard,
  FaRegKissBeam,
  FaRegKissWinkHeart,
  FaRegKiss,
  FaRegLaughBeam,
  FaRegLaughSquint,
  FaRegLaughWink,
  FaRegLaugh,
  FaRegLemon,
  FaRegLifeRing,
  FaRegLightbulb,
  FaRegListAlt,
  FaRegMap,
  FaRegMehBlank,
  FaRegMehRollingEyes,
  FaRegMeh,
  FaRegMinusSquare,
  FaRegMoneyBillAlt,
  FaRegMoon,
  FaRegNewspaper,
  FaRegObjectGroup,
  FaRegObjectUngroup,
  FaRegPaperPlane,
  FaRegPauseCircle,
  FaRegPlayCircle,
  FaRegPlusSquare,
  FaRegQuestionCircle,
  FaRegRegistered,
  FaRegSadCry,
  FaRegSadTear,
  FaRegSave,
  FaRegShareSquare,
  FaRegSmileBeam,
  FaRegSmileWink,
  FaRegSmile,
  FaRegSnowflake,
  FaRegSquare,
  FaRegStarHalf,
  FaRegStar,
  FaRegStickyNote,
  FaRegStopCircle,
  FaRegSun,
  FaRegSurprise,
  FaRegThumbsDown,
  FaRegThumbsUp,
  FaRegTimesCircle,
  FaRegTired,
  FaRegTrashAlt,
  FaRegUserCircle,
  FaRegUser,
  FaRegWindowClose,
  FaRegWindowMaximize,
  FaRegWindowMinimize,
  FaRegWindowRestore,
};

const GameIcons = {
  GiBarrelLeak,
  GiBarrel,
  GiCellarBarrels,
  GiCooler,
  GiCube,
};

const RemixIcons = {
  RiFridgeFill,
  RiFridgeLine,
};

const BinaryIcons = {
  BbKeg,
};

export const icons = [
  { value: "FaAccessibleIcon", label: "Accessible Icon" },
  { value: "FaApple", label: "Apple" },
  { value: "FaBitcoin", label: "Bitcoin" },
  { value: "FaBlackTie", label: "Black Tie" },
  { value: "FaBluetoothB", label: "BluetoothB" },
  { value: "FaBluetooth", label: "Bluetooth" },
  { value: "FaBtc", label: "Btc" },
  { value: "FaCanadianMapleLeaf", label: "Canadian Maple Leaf" },
  { value: "FaDiscord", label: "Discord" },
  { value: "FaEthereum", label: "Ethereum" },
  { value: "FaItunesNote", label: "Note" },
  { value: "FaSketch", label: "Sketch" },
  { value: "FaSnapchatGhost", label: "Ghost" },
  { value: "FaTwitter", label: "Twitter" },
  { value: "FaUntappd", label: "Untappd" },
  { value: "FaAddressBook", label: "Address Book" },
  { value: "FaAddressCard", label: "Address Card" },
  { value: "FaAirFreshener", label: "Air Freshener" },
  { value: "FaAllergies", label: "Allergies" },
  { value: "FaAmbulance", label: "Ambulance" },
  { value: "FaAmericanSignLanguageInterpreting", label: "American Sign Language Interpreting" },
  { value: "FaAnchor", label: "Anchor" },
  { value: "FaAngry", label: "Angry" },
  { value: "FaAppleAlt", label: "Apple Alt" },
  { value: "FaArchive", label: "Archive" },
  { value: "FaArchway", label: "Archway" },
  { value: "FaAssistiveListeningSystems", label: "Assistive Listening Systems" },
  { value: "FaAsterisk", label: "Asterisk" },
  { value: "FaAt", label: "At" },
  { value: "FaAtlas", label: "Atlas" },
  { value: "FaAtom", label: "Atom" },
  { value: "FaAward", label: "Award" },
  { value: "FaBabyCarriage", label: "Baby Carriage" },
  { value: "FaBaby", label: "Baby" },
  { value: "FaBacon", label: "Bacon" },
  { value: "FaBacteria", label: "Bacteria" },
  { value: "FaBacterium", label: "Bacterium" },
  { value: "FaBahai", label: "Bahai" },
  { value: "FaBalanceScaleLeft", label: "Balance Scale Left" },
  { value: "FaBalanceScaleRight", label: "Balance Scale Right" },
  { value: "FaBalanceScale", label: "Balance Scale" },
  { value: "FaBan", label: "Ban" },
  { value: "FaBandAid", label: "Band Aid" },
  { value: "FaBarcode", label: "Barcode" },
  { value: "GiBarrel", label: "Barrel" },
  { value: "GiBarrelLeak", label: "Barrel Leak" },
  { value: "FaBaseballBall", label: "Baseball Ball" },
  { value: "FaBasketballBall", label: "Basketball Ball" },
  { value: "FaBath", label: "Bath" },
  { value: "FaBatteryEmpty", label: "Battery Empty" },
  { value: "FaBatteryFull", label: "Battery Full" },
  { value: "FaBatteryHalf", label: "Battery Half" },
  { value: "FaBatteryQuarter", label: "Battery Quarter" },
  { value: "FaBatteryThreeQuarters", label: "Battery Three Quarters" },
  { value: "FaBed", label: "Bed" },
  { value: "FaBeer", label: "Beer" },
  { value: "FaBellSlash", label: "Bell Slash" },
  { value: "FaBell", label: "Bell" },
  { value: "FaBible", label: "Bible" },
  { value: "FaBicycle", label: "Bicycle" },
  { value: "FaBiking", label: "Biking" },
  { value: "FaBinoculars", label: "Binoculars" },
  { value: "FaBiohazard", label: "Biohazard" },
  { value: "FaBirthdayCake", label: "Birthday Cake" },
  { value: "FaBlenderPhone", label: "Blender Phone" },
  { value: "FaBlender", label: "Blender" },
  { value: "FaBlind", label: "Blind" },
  { value: "FaBolt", label: "Bolt" },
  { value: "FaBomb", label: "Bomb" },
  { value: "FaBone", label: "Bone" },
  { value: "FaBookDead", label: "Book Dead" },
  { value: "FaBookMedical", label: "Book Medical" },
  { value: "FaBookOpen", label: "Book Open" },
  { value: "FaBookReader", label: "Book Reader" },
  { value: "FaBook", label: "Book" },
  { value: "FaBookmark", label: "Bookmark" },
  { value: "FaBowlingBall", label: "Bowling Ball" },
  { value: "FaBoxOpen", label: "Box Open" },
  { value: "FaBoxTissue", label: "Box Tissue" },
  { value: "FaBox", label: "Box" },
  { value: "FaBoxes", label: "Boxes" },
  { value: "FaBraille", label: "Braille" },
  { value: "FaBrain", label: "Brain" },
  { value: "FaBreadSlice", label: "Bread Slice" },
  { value: "FaBriefcaseMedical", label: "Briefcase Medical" },
  { value: "FaBriefcase", label: "Briefcase" },
  { value: "FaBroadcastTower", label: "Broadcast Tower" },
  { value: "FaBroom", label: "Broom" },
  { value: "FaBrush", label: "Brush" },
  { value: "FaBug", label: "Bug" },
  { value: "FaBuilding", label: "Building" },
  { value: "FaBullhorn", label: "Bullhorn" },
  { value: "FaBullseye", label: "Bullseye" },
  { value: "FaBurn", label: "Burn" },
  { value: "FaBusAlt", label: "Bus Alt" },
  { value: "FaBus", label: "Bus" },
  { value: "FaBusinessTime", label: "Business Time" },
  { value: "FaCalculator", label: "Calculator" },
  { value: "FaCalendarAlt", label: "Calendar Alt" },
  { value: "FaCalendarCheck", label: "Calendar Check" },
  { value: "FaCalendarDay", label: "Calendar Day" },
  { value: "FaCalendarMinus", label: "Calendar Minus" },
  { value: "FaCalendarPlus", label: "Calendar Plus" },
  { value: "FaCalendarTimes", label: "Calendar Times" },
  { value: "FaCalendarWeek", label: "Calendar Week" },
  { value: "FaCalendar", label: "Calendar" },
  { value: "FaCameraRetro", label: "Camera Retro" },
  { value: "FaCamera", label: "Camera" },
  { value: "FaCampground", label: "Campground" },
  { value: "FaCandyCane", label: "Candy Cane" },
  { value: "FaCapsules", label: "Capsules" },
  { value: "FaCarAlt", label: "Car Alt" },
  { value: "FaCarBattery", label: "Car Battery" },
  { value: "FaCarCrash", label: "Car Crash" },
  { value: "FaCarSide", label: "Car Side" },
  { value: "FaCar", label: "Car" },
  { value: "FaCaravan", label: "Caravan" },
  { value: "FaCarrot", label: "Carrot" },
  { value: "FaCartArrowDown", label: "Cart Arrow Down" },
  { value: "FaCartPlus", label: "Cart Plus" },
  { value: "FaCashRegister", label: "Cash Register" },
  { value: "FaCat", label: "Cat" },
  { value: "GiCellarBarrels", label: "Cellar Barrels" },
  { value: "FaCertificate", label: "Certificate" },
  { value: "FaChair", label: "Chair" },
  { value: "FaChalkboardTeacher", label: "Chalkboard Teacher" },
  { value: "FaChalkboard", label: "Chalkboard" },
  { value: "FaChargingStation", label: "Charging Station" },
  { value: "FaChartArea", label: "Chart Area" },
  { value: "FaChartBar", label: "Chart Bar" },
  { value: "FaChartLine", label: "Chart Line" },
  { value: "FaChartPie", label: "Chart Pie" },
  { value: "FaCheckCircle", label: "Check Circle" },
  { value: "FaCheckDouble", label: "Check Double" },
  { value: "FaCheckSquare", label: "Check Square" },
  { value: "FaCheck", label: "Check" },
  { value: "FaCheese", label: "Cheese" },
  { value: "FaChessBishop", label: "Chess Bishop" },
  { value: "FaChessBoard", label: "Chess Board" },
  { value: "FaChessKing", label: "Chess King" },
  { value: "FaChessKnight", label: "Chess Knight" },
  { value: "FaChessPawn", label: "Chess Pawn" },
  { value: "FaChessQueen", label: "Chess Queen" },
  { value: "FaChessRook", label: "Chess Rook" },
  { value: "FaChess", label: "Chess" },
  { value: "FaChild", label: "Child" },
  { value: "FaChurch", label: "Church" },
  { value: "FaCircleNotch", label: "Circle Notch" },
  { value: "FaCircle", label: "Circle" },
  { value: "FaCity", label: "City" },
  { value: "FaClinicMedical", label: "Clinic Medical" },
  { value: "FaClipboardCheck", label: "Clipboard Check" },
  { value: "FaClipboardList", label: "Clipboard List" },
  { value: "FaClipboard", label: "Clipboard" },
  { value: "FaClock", label: "Clock" },
  { value: "FaClone", label: "Clone" },
  { value: "FaCloudDownloadAlt", label: "Cloud Download Alt" },
  { value: "FaCloudMeatball", label: "Cloud Meatball" },
  { value: "FaCloudMoonRain", label: "Cloud Moon Rain" },
  { value: "FaCloudMoon", label: "Cloud Moon" },
  { value: "FaCloudRain", label: "Cloud Rain" },
  { value: "FaCloudShowersHeavy", label: "Cloud Showers Heavy" },
  { value: "FaCloudSunRain", label: "Cloud Sun Rain" },
  { value: "FaCloudSun", label: "Cloud Sun" },
  { value: "FaCloudUploadAlt", label: "Cloud Upload Alt" },
  { value: "FaCloud", label: "Cloud" },
  { value: "FaCocktail", label: "Cocktail" },
  { value: "FaCodeBranch", label: "Code Branch" },
  { value: "FaCode", label: "Code" },
  { value: "FaCoffee", label: "Coffee" },
  { value: "FaCog", label: "Cog" },
  { value: "FaCogs", label: "Cogs" },
  { value: "FaCoins", label: "Coins" },
  { value: "FaColumns", label: "Columns" },
  { value: "FaCommentAlt", label: "Comment Alt" },
  { value: "FaCommentDollar", label: "Comment Dollar" },
  { value: "FaCommentDots", label: "Comment Dots" },
  { value: "FaCommentMedical", label: "Comment Medical" },
  { value: "FaCommentSlash", label: "Comment Slash" },
  { value: "FaComment", label: "Comment" },
  { value: "FaCommentsDollar", label: "Comments Dollar" },
  { value: "FaComments", label: "Comments" },
  { value: "FaCompactDisc", label: "Compact Disc" },
  { value: "FaCompass", label: "Compass" },
  { value: "FaCompressAlt", label: "Compress Alt" },
  { value: "FaCompressArrowsAlt", label: "Compress Arrows Alt" },
  { value: "FaCompress", label: "Compress" },
  { value: "FaConciergeBell", label: "Concierge Bell" },
  { value: "FaCookieBite", label: "Cookie Bite" },
  { value: "FaCookie", label: "Cookie" },
  { value: "GiCooler", label: "Cooler" },
  { value: "FaCopy", label: "Copy" },
  { value: "FaCopyright", label: "Copyright" },
  { value: "FaCouch", label: "Couch" },
  { value: "FaCreditCard", label: "Credit Card" },
  { value: "FaCropAlt", label: "Crop Alt" },
  { value: "FaCrop", label: "Crop" },
  { value: "FaCross", label: "Cross" },
  { value: "FaCrosshairs", label: "Crosshairs" },
  { value: "FaCrow", label: "Crow" },
  { value: "FaCrown", label: "Crown" },
  { value: "FaCrutch", label: "Crutch" },
  { value: "FaCube", label: "Cube" },
  { value: "FaCubes", label: "Cubes" },
  { value: "FaCut", label: "Cut" },
  { value: "FaDatabase", label: "Database" },
  { value: "FaDeaf", label: "Deaf" },
  { value: "FaDemocrat", label: "Democrat" },
  { value: "FaDesktop", label: "Desktop" },
  { value: "FaDharmachakra", label: "Dharmachakra" },
  { value: "FaDiagnoses", label: "Diagnoses" },
  { value: "FaDiceD20", label: "Dice D20" },
  { value: "FaDiceD6", label: "Dice D6" },
  { value: "FaDiceFive", label: "Dice Five" },
  { value: "FaDiceFour", label: "Dice Four" },
  { value: "FaDiceOne", label: "Dice One" },
  { value: "FaDiceSix", label: "Dice Six" },
  { value: "FaDiceThree", label: "Dice Three" },
  { value: "FaDiceTwo", label: "Dice Two" },
  { value: "FaDice", label: "Dice" },
  { value: "FaDigitalTachograph", label: "Digital Tachograph" },
  { value: "FaDirections", label: "Directions" },
  { value: "FaDisease", label: "Disease" },
  { value: "FaDivide", label: "Divide" },
  { value: "FaDizzy", label: "Dizzy" },
  { value: "FaDna", label: "Dna" },
  { value: "FaDog", label: "Dog" },
  { value: "FaDollarSign", label: "Dollar Sign" },
  { value: "FaDollyFlatbed", label: "Dolly Flatbed" },
  { value: "FaDolly", label: "Dolly" },
  { value: "FaDonate", label: "Donate" },
  { value: "FaDoorClosed", label: "Door Closed" },
  { value: "FaDoorOpen", label: "Door Open" },
  { value: "FaDotCircle", label: "Dot Circle" },
  { value: "FaDove", label: "Dove" },
  { value: "FaDownload", label: "Download" },
  { value: "FaDraftingCompass", label: "Drafting Compass" },
  { value: "FaDragon", label: "Dragon" },
  { value: "FaDrawPolygon", label: "Draw Polygon" },
  { value: "FaDrumSteelpan", label: "Drum Steelpan" },
  { value: "FaDrum", label: "Drum" },
  { value: "FaDrumstickBite", label: "Drumstick Bite" },
  { value: "FaDumbbell", label: "Dumbbell" },
  { value: "FaDumpsterFire", label: "Dumpster Fire" },
  { value: "FaDumpster", label: "Dumpster" },
  { value: "FaDungeon", label: "Dungeon" },
  { value: "FaEdit", label: "Edit" },
  { value: "FaEgg", label: "Egg" },
  { value: "FaEnvelopeOpenText", label: "Envelope Open Text" },
  { value: "FaEnvelopeOpen", label: "Envelope Open" },
  { value: "FaEnvelopeSquare", label: "Envelope Square" },
  { value: "FaEnvelope", label: "Envelope" },
  { value: "FaEquals", label: "Equals" },
  { value: "FaEraser", label: "Eraser" },
  { value: "FaEthernet", label: "Ethernet" },
  { value: "FaEuroSign", label: "Euro Sign" },
  { value: "FaExchangeAlt", label: "Exchange Alt" },
  { value: "FaExclamationCircle", label: "Exclamation Circle" },
  { value: "FaExclamationTriangle", label: "Exclamation Triangle" },
  { value: "FaExclamation", label: "Exclamation" },
  { value: "FaExpand", label: "Expand" },
  { value: "FaExternalLinkAlt", label: "External Link Alt" },
  { value: "FaExternalLinkSquareAlt", label: "External Link Square Alt" },
  { value: "FaEyeDropper", label: "Eye Dropper" },
  { value: "FaEyeSlash", label: "Eye Slash" },
  { value: "FaEye", label: "Eye" },
  { value: "FaFan", label: "Fan" },
  { value: "FaFaucet", label: "Faucet" },
  { value: "FaFax", label: "Fax" },
  { value: "FaFeatherAlt", label: "Feather Alt" },
  { value: "FaFeather", label: "Feather" },
  { value: "FaFemale", label: "Female" },
  { value: "FaFighterJet", label: "Fighter Jet" },
  { value: "FaFileAlt", label: "File Alt" },
  { value: "FaFileArchive", label: "File Archive" },
  { value: "FaFileAudio", label: "File Audio" },
  { value: "FaFileCode", label: "File Code" },
  { value: "FaFileContract", label: "File Contract" },
  { value: "FaFileCsv", label: "File Csv" },
  { value: "FaFileDownload", label: "File Download" },
  { value: "FaFileExcel", label: "File Excel" },
  { value: "FaFileExport", label: "File Export" },
  { value: "FaFileImage", label: "File Image" },
  { value: "FaFileImport", label: "File Import" },
  { value: "FaFileInvoiceDollar", label: "File Invoice Dollar" },
  { value: "FaFileInvoice", label: "File Invoice" },
  { value: "FaFileMedicalAlt", label: "File Medical Alt" },
  { value: "FaFileMedical", label: "File Medical" },
  { value: "FaFilePdf", label: "File Pdf" },
  { value: "FaFilePowerpoint", label: "File Powerpoint" },
  { value: "FaFilePrescription", label: "File Prescription" },
  { value: "FaFileSignature", label: "File Signature" },
  { value: "FaFileUpload", label: "File Upload" },
  { value: "FaFileVideo", label: "File Video" },
  { value: "FaFileWord", label: "File Word" },
  { value: "FaFile", label: "File" },
  { value: "FaFillDrip", label: "Fill Drip" },
  { value: "FaFill", label: "Fill" },
  { value: "FaFilm", label: "Film" },
  { value: "FaFilter", label: "Filter" },
  { value: "FaFingerprint", label: "Fingerprint" },
  { value: "FaFireAlt", label: "Fire Alt" },
  { value: "FaFireExtinguisher", label: "Fire Extinguisher" },
  { value: "FaFire", label: "Fire" },
  { value: "FaFirstAid", label: "First Aid" },
  { value: "FaFish", label: "Fish" },
  { value: "FaFistRaised", label: "Fist Raised" },
  { value: "FaFlagCheckered", label: "Flag Checkered" },
  { value: "FaFlagUsa", label: "Flag Usa" },
  { value: "FaFlag", label: "Flag" },
  { value: "FaFlask", label: "Flask" },
  { value: "FaFlushed", label: "Flushed" },
  { value: "FaFolderMinus", label: "Folder Minus" },
  { value: "FaFolderOpen", label: "Folder Open" },
  { value: "FaFolderPlus", label: "Folder Plus" },
  { value: "FaFolder", label: "Folder" },
  { value: "FaFont", label: "Font" },
  { value: "FaFootballBall", label: "Football Ball" },
  { value: "FaForward", label: "Forward" },
  { value: "RiFridgeFill", label: "Fridge Fill" },
  { value: "RiFridgeLine", label: "Fridge Line" },
  { value: "FaFrog", label: "Frog" },
  { value: "FaFrownOpen", label: "Frown Open" },
  { value: "FaFrown", label: "Frown" },
  { value: "FaFunnelDollar", label: "Funnel Dollar" },
  { value: "FaFutbol", label: "Futbol" },
  { value: "FaGamepad", label: "Gamepad" },
  { value: "FaGasPump", label: "Gas Pump" },
  { value: "FaGavel", label: "Gavel" },
  { value: "FaGem", label: "Gem" },
  { value: "FaGenderless", label: "Genderless" },
  { value: "FaGhost", label: "Ghost" },
  { value: "FaGift", label: "Gift" },
  { value: "FaGifts", label: "Gifts" },
  { value: "FaGlassCheers", label: "Glass Cheers" },
  { value: "FaGlassMartiniAlt", label: "Glass Martini Alt" },
  { value: "FaGlassMartini", label: "Glass Martini" },
  { value: "FaGlassWhiskey", label: "Glass Whiskey" },
  { value: "FaGlasses", label: "Glasses" },
  { value: "FaGlobeAfrica", label: "Globe Africa" },
  { value: "FaGlobeAmericas", label: "Globe Americas" },
  { value: "FaGlobeAsia", label: "Globe Asia" },
  { value: "FaGlobeEurope", label: "Globe Europe" },
  { value: "FaGlobe", label: "Globe" },
  { value: "FaGolfBall", label: "Golf Ball" },
  { value: "FaGopuram", label: "Gopuram" },
  { value: "FaGraduationCap", label: "Graduation Cap" },
  { value: "FaGreaterThanEqual", label: "Greater Than Equal" },
  { value: "FaGreaterThan", label: "Greater Than" },
  { value: "FaGrimace", label: "Grimace" },
  { value: "FaGrinAlt", label: "Grin Alt" },
  { value: "FaGrinBeamSweat", label: "Grin Beam Sweat" },
  { value: "FaGrinBeam", label: "Grin Beam" },
  { value: "FaGrinHearts", label: "Grin Hearts" },
  { value: "FaGrinSquintTears", label: "Grin Squint Tears" },
  { value: "FaGrinSquint", label: "Grin Squint" },
  { value: "FaGrinStars", label: "Grin Stars" },
  { value: "FaGrinTears", label: "Grin Tears" },
  { value: "FaGrinTongueSquint", label: "Grin Tongue Squint" },
  { value: "FaGrinTongueWink", label: "Grin Tongue Wink" },
  { value: "FaGrinTongue", label: "Grin Tongue" },
  { value: "FaGrinWink", label: "Grin Wink" },
  { value: "FaGrin", label: "Grin" },
  { value: "FaGuitar", label: "Guitar" },
  { value: "FaHSquare", label: "H Square" },
  { value: "FaHamburger", label: "Hamburger" },
  { value: "FaHammer", label: "Hammer" },
  { value: "FaHamsa", label: "Hamsa" },
  { value: "FaHandHoldingHeart", label: "Hand Holding Heart" },
  { value: "FaHandHoldingMedical", label: "Hand Holding Medical" },
  { value: "FaHandHoldingUsd", label: "Hand Holding Usd" },
  { value: "FaHandHoldingWater", label: "Hand Holding Water" },
  { value: "FaHandHolding", label: "Hand Holding" },
  { value: "FaHandLizard", label: "Hand Lizard" },
  { value: "FaHandMiddleFinger", label: "Hand Middle Finger" },
  { value: "FaHandPaper", label: "Hand Paper" },
  { value: "FaHandPeace", label: "Hand Peace" },
  { value: "FaHandPointDown", label: "Hand Point Down" },
  { value: "FaHandPointLeft", label: "Hand Point Left" },
  { value: "FaHandPointRight", label: "Hand Point Right" },
  { value: "FaHandPointUp", label: "Hand Point Up" },
  { value: "FaHandPointer", label: "Hand Pointer" },
  { value: "FaHandRock", label: "Hand Rock" },
  { value: "FaHandScissors", label: "Hand Scissors" },
  { value: "FaHandSparkles", label: "Hand Sparkles" },
  { value: "FaHandSpock", label: "Hand Spock" },
  { value: "FaHandsHelping", label: "Hands Helping" },
  { value: "FaHandsWash", label: "Hands Wash" },
  { value: "FaHands", label: "Hands" },
  { value: "FaHandshakeAltSlash", label: "Handshake Alt Slash" },
  { value: "FaHandshakeSlash", label: "Handshake Slash" },
  { value: "FaHandshake", label: "Handshake" },
  { value: "FaHanukiah", label: "Hanukiah" },
  { value: "FaHardHat", label: "Hard Hat" },
  { value: "FaHashtag", label: "Hashtag" },
  { value: "FaHatCowboySide", label: "Hat Cowboy Side" },
  { value: "FaHatCowboy", label: "Hat Cowboy" },
  { value: "FaHatWizard", label: "Hat Wizard" },
  { value: "FaHdd", label: "Hdd" },
  { value: "FaHeadSideCoughSlash", label: "Head Side Cough Slash" },
  { value: "FaHeadSideCough", label: "Head Side Cough" },
  { value: "FaHeadSideMask", label: "Head Side Mask" },
  { value: "FaHeadSideVirus", label: "Head Side Virus" },
  { value: "FaHeading", label: "Heading" },
  { value: "FaHeadphonesAlt", label: "Headphones Alt" },
  { value: "FaHeadphones", label: "Headphones" },
  { value: "FaHeadset", label: "Headset" },
  { value: "FaHeartBroken", label: "Heart Broken" },
  { value: "FaHeart", label: "Heart" },
  { value: "FaHeartbeat", label: "Heartbeat" },
  { value: "FaHelicopter", label: "Helicopter" },
  { value: "FaHighlighter", label: "Highlighter" },
  { value: "FaHiking", label: "Hiking" },
  { value: "FaHippo", label: "Hippo" },
  { value: "FaHistory", label: "History" },
  { value: "FaHockeyPuck", label: "Hockey Puck" },
  { value: "FaHollyBerry", label: "Holly Berry" },
  { value: "FaHome", label: "Home" },
  { value: "FaHorseHead", label: "Horse Head" },
  { value: "FaHorse", label: "Horse" },
  { value: "FaHospitalAlt", label: "Hospital Alt" },
  { value: "FaHospitalSymbol", label: "Hospital Symbol" },
  { value: "FaHospitalUser", label: "Hospital User" },
  { value: "FaHospital", label: "Hospital" },
  { value: "FaHotTub", label: "Hot Tub" },
  { value: "FaHotdog", label: "Hotdog" },
  { value: "FaHotel", label: "Hotel" },
  { value: "FaHourglassEnd", label: "Hourglass End" },
  { value: "FaHourglassHalf", label: "Hourglass Half" },
  { value: "FaHourglassStart", label: "Hourglass Start" },
  { value: "FaHourglass", label: "Hourglass" },
  { value: "FaHouseDamage", label: "House Damage" },
  { value: "FaHouseUser", label: "House User" },
  { value: "FaHryvnia", label: "Hryvnia" },
  { value: "FaICursor", label: "I Cursor" },
  { value: "FaIceCream", label: "Ice Cream" },
  { value: "FaIcicles", label: "Icicles" },
  { value: "FaIcons", label: "Icons" },
  { value: "FaIdBadge", label: "Id Badge" },
  { value: "FaIdCardAlt", label: "Id Card Alt" },
  { value: "FaIdCard", label: "Id Card" },
  { value: "FaIgloo", label: "Igloo" },
  { value: "FaImage", label: "Image" },
  { value: "FaImages", label: "Images" },
  { value: "FaInbox", label: "Inbox" },
  { value: "FaIndent", label: "Indent" },
  { value: "FaIndustry", label: "Industry" },
  { value: "FaInfinity", label: "Infinity" },
  { value: "FaInfoCircle", label: "Info Circle" },
  { value: "FaInfo", label: "Info" },
  { value: "FaItalic", label: "Italic" },
  { value: "FaJedi", label: "Jedi" },
  { value: "FaJoint", label: "Joint" },
  { value: "FaJournalWhills", label: "Journal Whills" },
  { value: "FaKaaba", label: "Kaaba" },
  { value: "BbKeg", label: "Keg" },
  { value: "FaKey", label: "Key" },
  { value: "FaKeyboard", label: "Keyboard" },
  { value: "FaKhanda", label: "Khanda" },
  { value: "FaKissBeam", label: "Kiss Beam" },
  { value: "FaKissWinkHeart", label: "Kiss Wink Heart" },
  { value: "FaKiss", label: "Kiss" },
  { value: "FaKiwiBird", label: "Kiwi Bird" },
  { value: "FaLandmark", label: "Landmark" },
  { value: "FaLanguage", label: "Language" },
  { value: "FaLaptopCode", label: "Laptop Code" },
  { value: "FaLaptopHouse", label: "Laptop House" },
  { value: "FaLaptopMedical", label: "Laptop Medical" },
  { value: "FaLaptop", label: "Laptop" },
  { value: "FaLaughBeam", label: "Laugh Beam" },
  { value: "FaLaughSquint", label: "Laugh Squint" },
  { value: "FaLaughWink", label: "Laugh Wink" },
  { value: "FaLaugh", label: "Laugh" },
  { value: "FaLayerGroup", label: "Layer Group" },
  { value: "FaLeaf", label: "Leaf" },
  { value: "FaLemon", label: "Lemon" },
  { value: "FaLessThanEqual", label: "Less Than Equal" },
  { value: "FaLessThan", label: "Less Than" },
  { value: "FaLifeRing", label: "Life Ring" },
  { value: "FaLightbulb", label: "Lightbulb" },
  { value: "FaLink", label: "Link" },
  { value: "FaLiraSign", label: "Lira Sign" },
  { value: "FaListAlt", label: "List Alt" },
  { value: "FaLocationArrow", label: "Location Arrow" },
  { value: "FaLockOpen", label: "Lock Open" },
  { value: "FaLock", label: "Lock" },
  { value: "FaLowVision", label: "Low Vision" },
  { value: "FaLuggageCart", label: "Luggage Cart" },
  { value: "FaLungsVirus", label: "Lungs Virus" },
  { value: "FaLungs", label: "Lungs" },
  { value: "FaMagic", label: "Magic" },
  { value: "FaMagnet", label: "Magnet" },
  { value: "FaMailBulk", label: "Mail Bulk" },
  { value: "FaMale", label: "Male" },
  { value: "FaMapMarkedAlt", label: "Map Marked Alt" },
  { value: "FaMapMarked", label: "Map Marked" },
  { value: "FaMapMarkerAlt", label: "Map Marker Alt" },
  { value: "FaMapMarker", label: "Map Marker" },
  { value: "FaMapPin", label: "Map Pin" },
  { value: "FaMapSigns", label: "Map Signs" },
  { value: "FaMap", label: "Map" },
  { value: "FaMarker", label: "Marker" },
  { value: "FaMarsDouble", label: "Mars Double" },
  { value: "FaMarsStrokeH", label: "Mars Stroke H" },
  { value: "FaMarsStrokeV", label: "Mars Stroke V" },
  { value: "FaMarsStroke", label: "Mars Stroke" },
  { value: "FaMars", label: "Mars" },
  { value: "FaMask", label: "Mask" },
  { value: "FaMedal", label: "Medal" },
  { value: "FaMedkit", label: "Medkit" },
  { value: "FaMehBlank", label: "Meh Blank" },
  { value: "FaMehRollingEyes", label: "Meh Rolling Eyes" },
  { value: "FaMeh", label: "Meh" },
  { value: "FaMemory", label: "Memory" },
  { value: "FaMenorah", label: "Menorah" },
  { value: "FaMercury", label: "Mercury" },
  { value: "FaMeteor", label: "Meteor" },
  { value: "FaMicrochip", label: "Microchip" },
  { value: "FaMicrophoneAltSlash", label: "Microphone Alt Slash" },
  { value: "FaMicrophoneAlt", label: "Microphone Alt" },
  { value: "FaMicrophoneSlash", label: "Microphone Slash" },
  { value: "FaMicrophone", label: "Microphone" },
  { value: "FaMicroscope", label: "Microscope" },
  { value: "FaMinusCircle", label: "Minus Circle" },
  { value: "FaMinusSquare", label: "Minus Square" },
  { value: "FaMinus", label: "Minus" },
  { value: "FaMitten", label: "Mitten" },
  { value: "FaMobileAlt", label: "Mobile Alt" },
  { value: "FaMobile", label: "Mobile" },
  { value: "FaMoneyBillAlt", label: "Money Bill Alt" },
  { value: "FaMoneyBillWaveAlt", label: "Money Bill Wave Alt" },
  { value: "FaMoneyBillWave", label: "Money Bill Wave" },
  { value: "FaMoneyBill", label: "Money Bill" },
  { value: "FaMoneyCheckAlt", label: "Money Check Alt" },
  { value: "FaMoneyCheck", label: "Money Check" },
  { value: "FaMonument", label: "Monument" },
  { value: "FaMoon", label: "Moon" },
  { value: "FaMortarPestle", label: "Mortar Pestle" },
  { value: "FaMosque", label: "Mosque" },
  { value: "FaMotorcycle", label: "Motorcycle" },
  { value: "FaMountain", label: "Mountain" },
  { value: "FaMousePointer", label: "Mouse Pointer" },
  { value: "FaMouse", label: "Mouse" },
  { value: "FaMugHot", label: "Mug Hot" },
  { value: "FaMusic", label: "Music" },
  { value: "FaNetworkWired", label: "Network Wired" },
  { value: "FaNeuter", label: "Neuter" },
  { value: "FaNewspaper", label: "Newspaper" },
  { value: "FaNotEqual", label: "Not Equal" },
  { value: "FaNotesMedical", label: "Notes Medical" },
  { value: "FaObjectGroup", label: "Object Group" },
  { value: "FaObjectUngroup", label: "Object Ungroup" },
  { value: "FaOilCan", label: "Oil Can" },
  { value: "FaOm", label: "Om" },
  { value: "FaOtter", label: "Otter" },
  { value: "FaOutdent", label: "Outdent" },
  { value: "FaPager", label: "Pager" },
  { value: "FaPaintBrush", label: "Paint Brush" },
  { value: "FaPaintRoller", label: "Paint Roller" },
  { value: "FaPalette", label: "Palette" },
  { value: "FaPallet", label: "Pallet" },
  { value: "FaPaperPlane", label: "Paper Plane" },
  { value: "FaPaperclip", label: "Paperclip" },
  { value: "FaParachuteBox", label: "Parachute Box" },
  { value: "FaParagraph", label: "Paragraph" },
  { value: "FaParking", label: "Parking" },
  { value: "FaPassport", label: "Passport" },
  { value: "FaPastafarianism", label: "Pastafarianism" },
  { value: "FaPaste", label: "Paste" },
  { value: "FaPauseCircle", label: "Pause Circle" },
  { value: "FaPause", label: "Pause" },
  { value: "FaPaw", label: "Paw" },
  { value: "FaPeace", label: "Peace" },
  { value: "FaPenAlt", label: "Pen Alt" },
  { value: "FaPenFancy", label: "Pen Fancy" },
  { value: "FaPenNib", label: "Pen Nib" },
  { value: "FaPenSquare", label: "Pen Square" },
  { value: "FaPen", label: "Pen" },
  { value: "FaPencilAlt", label: "Pencil Alt" },
  { value: "FaPencilRuler", label: "Pencil Ruler" },
  { value: "FaPeopleArrows", label: "People Arrows" },
  { value: "FaPeopleCarry", label: "People Carry" },
  { value: "FaPepperHot", label: "Pepper Hot" },
  { value: "FaPercent", label: "Percent" },
  { value: "FaPercentage", label: "Percentage" },
  { value: "FaPersonBooth", label: "Person Booth" },
  { value: "FaPhoneAlt", label: "Phone Alt" },
  { value: "FaPhoneSlash", label: "Phone Slash" },
  { value: "FaPhoneSquareAlt", label: "Phone Square Alt" },
  { value: "FaPhoneSquare", label: "Phone Square" },
  { value: "FaPhoneVolume", label: "Phone Volume" },
  { value: "FaPhone", label: "Phone" },
  { value: "FaPhotoVideo", label: "Photo Video" },
  { value: "FaPiggyBank", label: "Piggy Bank" },
  { value: "FaPills", label: "Pills" },
  { value: "FaPizzaSlice", label: "Pizza Slice" },
  { value: "FaPlaceOfWorship", label: "Place Of Worship" },
  { value: "FaPlaneArrival", label: "Plane Arrival" },
  { value: "FaPlaneDeparture", label: "Plane Departure" },
  { value: "FaPlaneSlash", label: "Plane Slash" },
  { value: "FaPlane", label: "Plane" },
  { value: "FaPlayCircle", label: "Play Circle" },
  { value: "FaPlay", label: "Play" },
  { value: "FaPlug", label: "Plug" },
  { value: "FaPlusCircle", label: "Plus Circle" },
  { value: "FaPlusSquare", label: "Plus Square" },
  { value: "FaPlus", label: "Plus" },
  { value: "FaPodcast", label: "Podcast" },
  { value: "FaPollH", label: "Poll H" },
  { value: "FaPoll", label: "Poll" },
  { value: "FaPooStorm", label: "Poo Storm" },
  { value: "FaPoo", label: "Poo" },
  { value: "FaPoop", label: "Poop" },
  { value: "FaPortrait", label: "Portrait" },
  { value: "FaPoundSign", label: "Pound Sign" },
  { value: "FaPowerOff", label: "Power Off" },
  { value: "FaPray", label: "Pray" },
  { value: "FaPrayingHands", label: "Praying Hands" },
  { value: "FaPrescriptionBottleAlt", label: "Prescription Bottle Alt" },
  { value: "FaPrescriptionBottle", label: "Prescription Bottle" },
  { value: "FaPrescription", label: "Prescription" },
  { value: "FaPrint", label: "Print" },
  { value: "FaProcedures", label: "Procedures" },
  { value: "FaProjectDiagram", label: "Project Diagram" },
  { value: "FaPumpMedical", label: "Pump Medical" },
  { value: "FaPumpSoap", label: "Pump Soap" },
  { value: "FaPuzzlePiece", label: "Puzzle Piece" },
  { value: "FaQrcode", label: "Qrcode" },
  { value: "FaQuestionCircle", label: "Question Circle" },
  { value: "FaQuestion", label: "Question" },
  { value: "FaQuidditch", label: "Quidditch" },
  { value: "FaQuoteLeft", label: "Quote Left" },
  { value: "FaQuoteRight", label: "Quote Right" },
  { value: "FaQuran", label: "Quran" },
  { value: "FaRadiationAlt", label: "Radiation Alt" },
  { value: "FaRadiation", label: "Radiation" },
  { value: "FaRainbow", label: "Rainbow" },
  { value: "FaRandom", label: "Random" },
  { value: "FaReceipt", label: "Receipt" },
  { value: "FaRecordVinyl", label: "Record Vinyl" },
  { value: "FaRecycle", label: "Recycle" },
  { value: "FaRedoAlt", label: "Redo Alt" },
  { value: "FaRedo", label: "Redo" },
  { value: "FaRegistered", label: "Registered" },
  { value: "FaRemoveFormat", label: "Remove Format" },
  { value: "FaReplyAll", label: "Reply All" },
  { value: "FaReply", label: "Reply" },
  { value: "FaRepublican", label: "Republican" },
  { value: "FaRestroom", label: "Restroom" },
  { value: "FaRetweet", label: "Retweet" },
  { value: "FaRibbon", label: "Ribbon" },
  { value: "FaRing", label: "Ring" },
  { value: "FaRoad", label: "Road" },
  { value: "FaRobot", label: "Robot" },
  { value: "FaRocket", label: "Rocket" },
  { value: "FaRoute", label: "Route" },
  { value: "FaRssSquare", label: "Rss Square" },
  { value: "FaRss", label: "Rss" },
  { value: "FaRubleSign", label: "Ruble Sign" },
  { value: "FaRulerCombined", label: "Ruler Combined" },
  { value: "FaRulerHorizontal", label: "Ruler Horizontal" },
  { value: "FaRulerVertical", label: "Ruler Vertical" },
  { value: "FaRuler", label: "Ruler" },
  { value: "FaRunning", label: "Running" },
  { value: "FaRupeeSign", label: "Rupee Sign" },
  { value: "FaSadCry", label: "Sad Cry" },
  { value: "FaSadTear", label: "Sad Tear" },
  { value: "FaSatelliteDish", label: "Satellite Dish" },
  { value: "FaSatellite", label: "Satellite" },
  { value: "FaSave", label: "Save" },
  { value: "FaSchool", label: "School" },
  { value: "FaScrewdriver", label: "Screwdriver" },
  { value: "FaScroll", label: "Scroll" },
  { value: "FaSdCard", label: "Sd Card" },
  { value: "FaSearchDollar", label: "Search Dollar" },
  { value: "FaSearchLocation", label: "Search Location" },
  { value: "FaSearchMinus", label: "Search Minus" },
  { value: "FaSearchPlus", label: "Search Plus" },
  { value: "FaSearch", label: "Search" },
  { value: "FaSeedling", label: "Seedling" },
  { value: "FaServer", label: "Server" },
  { value: "FaShapes", label: "Shapes" },
  { value: "FaShareAltSquare", label: "Share Alt Square" },
  { value: "FaShareAlt", label: "Share Alt" },
  { value: "FaShareSquare", label: "Share Square" },
  { value: "FaShare", label: "Share" },
  { value: "FaShekelSign", label: "Shekel Sign" },
  { value: "FaShieldAlt", label: "Shield Alt" },
  { value: "FaShieldVirus", label: "Shield Virus" },
  { value: "FaShip", label: "Ship" },
  { value: "FaShippingFast", label: "Shipping Fast" },
  { value: "FaShoePrints", label: "Shoe Prints" },
  { value: "FaShoppingBag", label: "Shopping Bag" },
  { value: "FaShoppingBasket", label: "Shopping Basket" },
  { value: "FaShoppingCart", label: "Shopping Cart" },
  { value: "FaShower", label: "Shower" },
  { value: "FaShuttleVan", label: "Shuttle Van" },
  { value: "FaSignInAlt", label: "Sign In Alt" },
  { value: "FaSignLanguage", label: "Sign Language" },
  { value: "FaSignOutAlt", label: "Sign Out Alt" },
  { value: "FaSign", label: "Sign" },
  { value: "FaSignal", label: "Signal" },
  { value: "FaSignature", label: "Signature" },
  { value: "FaSimCard", label: "Sim Card" },
  { value: "FaSink", label: "Sink" },
  { value: "FaSitemap", label: "Sitemap" },
  { value: "FaSkating", label: "Skating" },
  { value: "FaSkiingNordic", label: "Skiing Nordic" },
  { value: "FaSkiing", label: "Skiing" },
  { value: "FaSkullCrossbones", label: "Skull Crossbones" },
  { value: "FaSkull", label: "Skull" },
  { value: "FaSlash", label: "Slash" },
  { value: "FaSleigh", label: "Sleigh" },
  { value: "FaSlidersH", label: "Sliders H" },
  { value: "FaSmileBeam", label: "Smile Beam" },
  { value: "FaSmileWink", label: "Smile Wink" },
  { value: "FaSmile", label: "Smile" },
  { value: "FaSmog", label: "Smog" },
  { value: "FaSmokingBan", label: "Smoking Ban" },
  { value: "FaSmoking", label: "Smoking" },
  { value: "FaSms", label: "Sms" },
  { value: "FaSnowboarding", label: "Snowboarding" },
  { value: "FaSnowflake", label: "Snowflake" },
  { value: "FaSnowman", label: "Snowman" },
  { value: "FaSnowplow", label: "Snowplow" },
  { value: "FaSoap", label: "Soap" },
  { value: "FaSocks", label: "Socks" },
  { value: "FaSolarPanel", label: "Solar Panel" },
  { value: "FaSpa", label: "Spa" },
  { value: "FaSpaceShuttle", label: "Space Shuttle" },
  { value: "FaSpellCheck", label: "Spell Check" },
  { value: "FaSpider", label: "Spider" },
  { value: "FaSpinner", label: "Spinner" },
  { value: "FaSplotch", label: "Splotch" },
  { value: "FaSprayCan", label: "Spray Can" },
  { value: "FaSquareFull", label: "Square Full" },
  { value: "FaSquareRootAlt", label: "Square Root Alt" },
  { value: "FaSquare", label: "Square" },
  { value: "FaStamp", label: "Stamp" },
  { value: "FaStarAndCrescent", label: "Star And Crescent" },
  { value: "FaStarHalfAlt", label: "Star Half Alt" },
  { value: "FaStarHalf", label: "Star Half" },
  { value: "FaStarOfDavid", label: "Star Of David" },
  { value: "FaStarOfLife", label: "Star Of Life" },
  { value: "FaStar", label: "Star" },
  { value: "FaStepBackward", label: "Step Backward" },
  { value: "FaStepForward", label: "Step Forward" },
  { value: "FaStethoscope", label: "Stethoscope" },
  { value: "FaStickyNote", label: "Sticky Note" },
  { value: "FaStopCircle", label: "Stop Circle" },
  { value: "FaStop", label: "Stop" },
  { value: "FaStopwatch20", label: "Stopwatch20" },
  { value: "FaStopwatch", label: "Stopwatch" },
  { value: "FaStoreAltSlash", label: "Store Alt Slash" },
  { value: "FaStoreAlt", label: "Store Alt" },
  { value: "FaStoreSlash", label: "Store Slash" },
  { value: "FaStore", label: "Store" },
  { value: "FaStream", label: "Stream" },
  { value: "FaStreetView", label: "Street View" },
  { value: "FaStrikethrough", label: "Strikethrough" },
  { value: "FaStroopwafel", label: "Stroopwafel" },
  { value: "FaSubscript", label: "Subscript" },
  { value: "FaSubway", label: "Subway" },
  { value: "FaSuitcaseRolling", label: "Suitcase Rolling" },
  { value: "FaSuitcase", label: "Suitcase" },
  { value: "FaSun", label: "Sun" },
  { value: "FaSuperscript", label: "Superscript" },
  { value: "FaSurprise", label: "Surprise" },
  { value: "FaSwatchbook", label: "Swatchbook" },
  { value: "FaSwimmer", label: "Swimmer" },
  { value: "FaSwimmingPool", label: "Swimming Pool" },
  { value: "FaSynagogue", label: "Synagogue" },
  { value: "FaSyncAlt", label: "Sync Alt" },
  { value: "FaSync", label: "Sync" },
  { value: "FaSyringe", label: "Syringe" },
  { value: "FaTableTennis", label: "Table Tennis" },
  { value: "FaTable", label: "Table" },
  { value: "FaTabletAlt", label: "Tablet Alt" },
  { value: "FaTablet", label: "Tablet" },
  { value: "FaTablets", label: "Tablets" },
  { value: "FaTachometerAlt", label: "Tachometer Alt" },
  { value: "FaTag", label: "Tag" },
  { value: "FaTags", label: "Tags" },
  { value: "FaTape", label: "Tape" },
  { value: "FaTasks", label: "Tasks" },
  { value: "FaTaxi", label: "Taxi" },
  { value: "FaTeethOpen", label: "Teeth Open" },
  { value: "FaTeeth", label: "Teeth" },
  { value: "FaTemperatureHigh", label: "Temperature High" },
  { value: "FaTemperatureLow", label: "Temperature Low" },
  { value: "FaTenge", label: "Tenge" },
  { value: "FaTerminal", label: "Terminal" },
  { value: "FaTextHeight", label: "Text Height" },
  { value: "FaTextWidth", label: "Text Width" },
  { value: "FaThLarge", label: "Th Large" },
  { value: "FaThList", label: "Th List" },
  { value: "FaTh", label: "Th" },
  { value: "FaTheaterMasks", label: "Theater Masks" },
  { value: "FaThermometerEmpty", label: "Thermometer Empty" },
  { value: "FaThermometerFull", label: "Thermometer Full" },
  { value: "FaThermometerHalf", label: "Thermometer Half" },
  { value: "FaThermometerQuarter", label: "Thermometer Quarter" },
  { value: "FaThermometerThreeQuarters", label: "Thermometer Three Quarters" },
  { value: "FaThermometer", label: "Thermometer" },
  { value: "FaThumbsDown", label: "Thumbs Down" },
  { value: "FaThumbsUp", label: "Thumbs Up" },
  { value: "FaThumbtack", label: "Thumbtack" },
  { value: "FaTicketAlt", label: "Ticket Alt" },
  { value: "FaTimesCircle", label: "Times Circle" },
  { value: "FaTimes", label: "Times" },
  { value: "FaTintSlash", label: "Tint Slash" },
  { value: "FaTint", label: "Tint" },
  { value: "FaTired", label: "Tired" },
  { value: "FaToggleOff", label: "Toggle Off" },
  { value: "FaToggleOn", label: "Toggle On" },
  { value: "FaToiletPaperSlash", label: "Toilet Paper Slash" },
  { value: "FaToiletPaper", label: "Toilet Paper" },
  { value: "FaToilet", label: "Toilet" },
  { value: "FaToolbox", label: "Toolbox" },
  { value: "FaTools", label: "Tools" },
  { value: "FaTooth", label: "Tooth" },
  { value: "FaTorah", label: "Torah" },
  { value: "FaToriiGate", label: "Torii Gate" },
  { value: "FaTractor", label: "Tractor" },
  { value: "FaTrademark", label: "Trademark" },
  { value: "FaTrafficLight", label: "Traffic Light" },
  { value: "FaTrailer", label: "Trailer" },
  { value: "FaTrain", label: "Train" },
  { value: "FaTram", label: "Tram" },
  { value: "FaTransgenderAlt", label: "Transgender Alt" },
  { value: "FaTransgender", label: "Transgender" },
  { value: "FaTrashAlt", label: "Trash Alt" },
  { value: "FaTrashRestoreAlt", label: "Trash Restore Alt" },
  { value: "FaTrashRestore", label: "Trash Restore" },
  { value: "FaTrash", label: "Trash" },
  { value: "FaTree", label: "Tree" },
  { value: "FaTrophy", label: "Trophy" },
  { value: "FaTruckLoading", label: "Truck Loading" },
  { value: "FaTruckMonster", label: "Truck Monster" },
  { value: "FaTruckMoving", label: "Truck Moving" },
  { value: "FaTruckPickup", label: "Truck Pickup" },
  { value: "FaTruck", label: "Truck" },
  { value: "FaTshirt", label: "Tshirt" },
  { value: "FaTty", label: "Tty" },
  { value: "FaTv", label: "Tv" },
  { value: "FaUmbrellaBeach", label: "Umbrella Beach" },
  { value: "FaUmbrella", label: "Umbrella" },
  { value: "FaUnderline", label: "Underline" },
  { value: "FaUndoAlt", label: "Undo Alt" },
  { value: "FaUndo", label: "Undo" },
  { value: "FaUniversalAccess", label: "Universal Access" },
  { value: "FaUniversity", label: "University" },
  { value: "FaUnlink", label: "Unlink" },
  { value: "FaUnlockAlt", label: "Unlock Alt" },
  { value: "FaUnlock", label: "Unlock" },
  { value: "FaUpload", label: "Upload" },
  { value: "FaUserAltSlash", label: "User Alt Slash" },
  { value: "FaUserAlt", label: "User Alt" },
  { value: "FaUserAstronaut", label: "User Astronaut" },
  { value: "FaUserCheck", label: "User Check" },
  { value: "FaUserCircle", label: "User Circle" },
  { value: "FaUserClock", label: "User Clock" },
  { value: "FaUserCog", label: "User Cog" },
  { value: "FaUserEdit", label: "User Edit" },
  { value: "FaUserFriends", label: "User Friends" },
  { value: "FaUserGraduate", label: "User Graduate" },
  { value: "FaUserInjured", label: "User Injured" },
  { value: "FaUserLock", label: "User Lock" },
  { value: "FaUserMd", label: "User Md" },
  { value: "FaUserMinus", label: "User Minus" },
  { value: "FaUserNinja", label: "User Ninja" },
  { value: "FaUserNurse", label: "User Nurse" },
  { value: "FaUserPlus", label: "User Plus" },
  { value: "FaUserSecret", label: "User Secret" },
  { value: "FaUserShield", label: "User Shield" },
  { value: "FaUserSlash", label: "User Slash" },
  { value: "FaUserTag", label: "User Tag" },
  { value: "FaUserTie", label: "User Tie" },
  { value: "FaUserTimes", label: "User Times" },
  { value: "FaUser", label: "User" },
  { value: "FaUsersCog", label: "Users Cog" },
  { value: "FaUsersSlash", label: "Users Slash" },
  { value: "FaUsers", label: "Users" },
  { value: "FaUtensilSpoon", label: "Utensil Spoon" },
  { value: "FaUtensils", label: "Utensils" },
  { value: "FaVectorSquare", label: "Vector Square" },
  { value: "FaVenusDouble", label: "Venus Double" },
  { value: "FaVenusMars", label: "Venus Mars" },
  { value: "FaVenus", label: "Venus" },
  { value: "FaVestPatches", label: "Vest Patches" },
  { value: "FaVest", label: "Vest" },
  { value: "FaVial", label: "Vial" },
  { value: "FaVials", label: "Vials" },
  { value: "FaVideoSlash", label: "Video Slash" },
  { value: "FaVideo", label: "Video" },
  { value: "FaVihara", label: "Vihara" },
  { value: "FaVirusSlash", label: "Virus Slash" },
  { value: "FaVirus", label: "Virus" },
  { value: "FaViruses", label: "Viruses" },
  { value: "FaVoicemail", label: "Voicemail" },
  { value: "FaVolleyballBall", label: "Volleyball Ball" },
  { value: "FaVolumeDown", label: "Volume Down" },
  { value: "FaVolumeMute", label: "Volume Mute" },
  { value: "FaVolumeOff", label: "Volume Off" },
  { value: "FaVolumeUp", label: "Volume Up" },
  { value: "FaVoteYea", label: "Vote Yea" },
  { value: "FaVrCardboard", label: "Vr Cardboard" },
  { value: "FaWalking", label: "Walking" },
  { value: "FaWallet", label: "Wallet" },
  { value: "FaWarehouse", label: "Warehouse" },
  { value: "FaWater", label: "Water" },
  { value: "FaWaveSquare", label: "Wave Square" },
  { value: "FaWeightHanging", label: "Weight Hanging" },
  { value: "FaWeight", label: "Weight" },
  { value: "FaWheelchair", label: "Wheelchair" },
  { value: "FaWifi", label: "Wifi" },
  { value: "FaWind", label: "Wind" },
  { value: "FaWindowClose", label: "Window Close" },
  { value: "FaWindowMaximize", label: "Window Maximize" },
  { value: "FaWindowMinimize", label: "Window Minimize" },
  { value: "FaWindowRestore", label: "Window Restore" },
  { value: "FaWineBottle", label: "Wine Bottle" },
  { value: "FaWineGlassAlt", label: "Wine Glass Alt" },
  { value: "FaWineGlass", label: "Wine Glass" },
  { value: "FaWonSign", label: "Won Sign" },
  { value: "FaWrench", label: "Wrench" },
  { value: "FaXRay", label: "X Ray" },
  { value: "FaYenSign", label: "Yen Sign" },
  { value: "FaYinYang", label: "Yin Yang" },
  { value: "FaRegAddressBook", label: "Reg Address Book" },
  { value: "FaRegAddressCard", label: "Reg Address Card" },
  { value: "FaRegAngry", label: "Reg Angry" },
  { value: "FaRegBellSlash", label: "Reg Bell Slash" },
  { value: "FaRegBell", label: "Reg Bell" },
  { value: "FaRegBookmark", label: "Reg Bookmark" },
  { value: "FaRegBuilding", label: "Reg Building" },
  { value: "FaRegCalendarAlt", label: "Reg Calendar Alt" },
  { value: "FaRegCalendarCheck", label: "Reg Calendar Check" },
  { value: "FaRegCalendarMinus", label: "Reg Calendar Minus" },
  { value: "FaRegCalendarPlus", label: "Reg Calendar Plus" },
  { value: "FaRegCalendarTimes", label: "Reg Calendar Times" },
  { value: "FaRegCalendar", label: "Reg Calendar" },
  { value: "FaRegChartBar", label: "Reg Chart Bar" },
  { value: "FaRegCheckCircle", label: "Reg Check Circle" },
  { value: "FaRegCheckSquare", label: "Reg Check Square" },
  { value: "FaRegCircle", label: "Reg Circle" },
  { value: "FaRegClipboard", label: "Reg Clipboard" },
  { value: "FaRegClock", label: "Reg Clock" },
  { value: "FaRegClone", label: "Reg Clone" },
  { value: "FaRegClosedCaptioning", label: "Reg Closed Captioning" },
  { value: "FaRegCommentAlt", label: "Reg Comment Alt" },
  { value: "FaRegCommentDots", label: "Reg Comment Dots" },
  { value: "FaRegComment", label: "Reg Comment" },
  { value: "FaRegComments", label: "Reg Comments" },
  { value: "FaRegCompass", label: "Reg Compass" },
  { value: "FaRegCopy", label: "Reg Copy" },
  { value: "FaRegCopyright", label: "Reg Copyright" },
  { value: "FaRegCreditCard", label: "Reg Credit Card" },
  { value: "FaRegDizzy", label: "Reg Dizzy" },
  { value: "FaRegDotCircle", label: "Reg Dot Circle" },
  { value: "FaRegEdit", label: "Reg Edit" },
  { value: "FaRegEnvelopeOpen", label: "Reg Envelope Open" },
  { value: "FaRegEnvelope", label: "Reg Envelope" },
  { value: "FaRegEyeSlash", label: "Reg Eye Slash" },
  { value: "FaRegEye", label: "Reg Eye" },
  { value: "FaRegFileAlt", label: "Reg File Alt" },
  { value: "FaRegFileArchive", label: "Reg File Archive" },
  { value: "FaRegFileAudio", label: "Reg File Audio" },
  { value: "FaRegFileCode", label: "Reg File Code" },
  { value: "FaRegFileExcel", label: "Reg File Excel" },
  { value: "FaRegFileImage", label: "Reg File Image" },
  { value: "FaRegFilePdf", label: "Reg File Pdf" },
  { value: "FaRegFilePowerpoint", label: "Reg File Powerpoint" },
  { value: "FaRegFileVideo", label: "Reg File Video" },
  { value: "FaRegFileWord", label: "Reg File Word" },
  { value: "FaRegFile", label: "Reg File" },
  { value: "FaRegFlag", label: "Reg Flag" },
  { value: "FaRegFlushed", label: "Reg Flushed" },
  { value: "FaRegFolderOpen", label: "Reg Folder Open" },
  { value: "FaRegFolder", label: "Reg Folder" },
  { value: "FaRegFrownOpen", label: "Reg Frown Open" },
  { value: "FaRegFrown", label: "Reg Frown" },
  { value: "FaRegFutbol", label: "Reg Futbol" },
  { value: "FaRegGem", label: "Reg Gem" },
  { value: "FaRegGrimace", label: "Reg Grimace" },
  { value: "FaRegGrinAlt", label: "Reg Grin Alt" },
  { value: "FaRegGrinBeamSweat", label: "Reg Grin Beam Sweat" },
  { value: "FaRegGrinBeam", label: "Reg Grin Beam" },
  { value: "FaRegGrinHearts", label: "Reg Grin Hearts" },
  { value: "FaRegGrinSquintTears", label: "Reg Grin Squint Tears" },
  { value: "FaRegGrinSquint", label: "Reg Grin Squint" },
  { value: "FaRegGrinStars", label: "Reg Grin Stars" },
  { value: "FaRegGrinTears", label: "Reg Grin Tears" },
  { value: "FaRegGrinTongueSquint", label: "Reg Grin Tongue Squint" },
  { value: "FaRegGrinTongueWink", label: "Reg Grin Tongue Wink" },
  { value: "FaRegGrinTongue", label: "Reg Grin Tongue" },
  { value: "FaRegGrinWink", label: "Reg Grin Wink" },
  { value: "FaRegGrin", label: "Reg Grin" },
  { value: "FaRegHandLizard", label: "Reg Hand Lizard" },
  { value: "FaRegHandPaper", label: "Reg Hand Paper" },
  { value: "FaRegHandPeace", label: "Reg Hand Peace" },
  { value: "FaRegHandPointDown", label: "Reg Hand Point Down" },
  { value: "FaRegHandPointLeft", label: "Reg Hand Point Left" },
  { value: "FaRegHandPointRight", label: "Reg Hand Point Right" },
  { value: "FaRegHandPointUp", label: "Reg Hand Point Up" },
  { value: "FaRegHandPointer", label: "Reg Hand Pointer" },
  { value: "FaRegHandRock", label: "Reg Hand Rock" },
  { value: "FaRegHandScissors", label: "Reg Hand Scissors" },
  { value: "FaRegHandSpock", label: "Reg Hand Spock" },
  { value: "FaRegHandshake", label: "Reg Handshake" },
  { value: "FaRegHdd", label: "Reg Hdd" },
  { value: "FaRegHeart", label: "Reg Heart" },
  { value: "FaRegHospital", label: "Reg Hospital" },
  { value: "FaRegHourglass", label: "Reg Hourglass" },
  { value: "FaRegIdBadge", label: "Reg Id Badge" },
  { value: "FaRegIdCard", label: "Reg Id Card" },
  { value: "FaRegImage", label: "Reg Image" },
  { value: "FaRegImages", label: "Reg Images" },
  { value: "FaRegKeyboard", label: "Reg Keyboard" },
  { value: "FaRegKissBeam", label: "Reg Kiss Beam" },
  { value: "FaRegKissWinkHeart", label: "Reg Kiss Wink Heart" },
  { value: "FaRegKiss", label: "Reg Kiss" },
  { value: "FaRegLaughBeam", label: "Reg Laugh Beam" },
  { value: "FaRegLaughSquint", label: "Reg Laugh Squint" },
  { value: "FaRegLaughWink", label: "Reg Laugh Wink" },
  { value: "FaRegLaugh", label: "Reg Laugh" },
  { value: "FaRegLemon", label: "Reg Lemon" },
  { value: "FaRegLifeRing", label: "Reg Life Ring" },
  { value: "FaRegLightbulb", label: "Reg Lightbulb" },
  { value: "FaRegListAlt", label: "Reg List Alt" },
  { value: "FaRegMap", label: "Reg Map" },
  { value: "FaRegMehBlank", label: "Reg Meh Blank" },
  { value: "FaRegMehRollingEyes", label: "Reg Meh Rolling Eyes" },
  { value: "FaRegMeh", label: "Reg Meh" },
  { value: "FaRegMinusSquare", label: "Reg Minus Square" },
  { value: "FaRegMoneyBillAlt", label: "Reg Money Bill Alt" },
  { value: "FaRegMoon", label: "Reg Moon" },
  { value: "FaRegNewspaper", label: "Reg Newspaper" },
  { value: "FaRegObjectGroup", label: "Reg Object Group" },
  { value: "FaRegObjectUngroup", label: "Reg Object Ungroup" },
  { value: "FaRegPaperPlane", label: "Reg Paper Plane" },
  { value: "FaRegPauseCircle", label: "Reg Pause Circle" },
  { value: "FaRegPlayCircle", label: "Reg Play Circle" },
  { value: "FaRegPlusSquare", label: "Reg Plus Square" },
  { value: "FaRegQuestionCircle", label: "Reg Question Circle" },
  { value: "FaRegRegistered", label: "Reg Registered" },
  { value: "FaRegSadCry", label: "Reg Sad Cry" },
  { value: "FaRegSadTear", label: "Reg Sad Tear" },
  { value: "FaRegSave", label: "Reg Save" },
  { value: "FaRegShareSquare", label: "Reg Share Square" },
  { value: "FaRegSmileBeam", label: "Reg Smile Beam" },
  { value: "FaRegSmileWink", label: "Reg Smile Wink" },
  { value: "FaRegSmile", label: "Reg Smile" },
  { value: "FaRegSnowflake", label: "Reg Snowflake" },
  { value: "FaRegSquare", label: "Reg Square" },
  { value: "FaRegStarHalf", label: "Reg Star Half" },
  { value: "FaRegStar", label: "Reg Star" },
  { value: "FaRegStickyNote", label: "Reg Sticky Note" },
  { value: "FaRegStopCircle", label: "Reg Stop Circle" },
  { value: "FaRegSun", label: "Reg Sun" },
  { value: "FaRegSurprise", label: "Reg Surprise" },
  { value: "FaRegThumbsDown", label: "Reg Thumbs Down" },
  { value: "FaRegThumbsUp", label: "Reg Thumbs Up" },
  { value: "FaRegTimesCircle", label: "Reg Times Circle" },
  { value: "FaRegTired", label: "Reg Tired" },
  { value: "FaRegTrashAlt", label: "Reg Trash Alt" },
  { value: "FaRegUserCircle", label: "Reg User Circle" },
  { value: "FaRegUser", label: "Reg User" },
  { value: "FaRegWindowClose", label: "Reg Window Close" },
  { value: "FaRegWindowMaximize", label: "Reg Window Maximize" },
  { value: "FaRegWindowMinimize", label: "Reg Window Minimize" },
  { value: "FaRegWindowRestore", label: "Reg Window Restore" },
];

const ReactIconHover = styled.div<{ color?: string; hoverColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  svg:hover {
    color: ${({ hoverColor }) => `${hoverColor} !important`};
  }
`;

type ReactIconProps = {
  name?: string;
  defaultColour: string;
  colour?: string;
  size?: string;
  style?: object;
  title?: string;
};

const ReactIcon = ({ name, defaultColour, colour, size = "1em", style = {}, title }: ReactIconProps) => {
  const color = colour !== undefined ? colour : defaultColour;

  // Check Font Awesome Icons for icon name
  // @ts-ignore
  let IconComponent = FontAwesomeIcons[name];

  // Check Game Icons for icon name
  if (!IconComponent) {
    // @ts-ignore
    IconComponent = GameIcons[name];
  }

  // Check Remix Icons for icon name
  if (!IconComponent) {
    // @ts-ignore
    IconComponent = RemixIcons[name];
  }

  // Check Binary Icons for icon name
  if (!IconComponent) {
    // @ts-ignore
    IconComponent = BinaryIcons[name];
  }

  if (!IconComponent) {
    return <ColoredDot color={color} />;
  }

  return (
    <IconContext.Provider value={{ color, size, style }}>
      <IconComponent title={title} colour={color} size={size} style={style} />
    </IconContext.Provider>
  );
};

export default ReactIcon;

export { ReactIconHover };
