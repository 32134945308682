import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

type FetchProps = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: any;
  filters?: any;
};

export const fetchTrackerServicesReport = async (source: CancelTokenSource, { pageIndex, pageSize, orderBy, filters }: FetchProps) => {
  const params: any = {
    organisationId: getAccount().organisationId,
    count: true,
  };

  if (pageIndex !== undefined && pageSize !== undefined) params.skip = pageIndex * pageSize;

  if (pageSize !== undefined) params.top = pageSize;

  if (orderBy && orderBy.length > 0) params.orderby = orderBy.map((order: any) => `${order.id} ${order.desc ? "desc" : "asc"}`).join(", ");

  if (filters) params.filter = filters;

  const { data, headers } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/trackerservicesreport",
    params,
  });

  return { count: headers?.count, data };
};
