import styled from "../Themes";

export const ColoredDot = styled<any>(`span`)`
  display: ${({ color }) => (color ? "inline-block" : "none")};

  &::before {
    background-color: ${({ color }) => color};
    border-radius: 10px;
    content: "";
    display: block;
    margin: ${({ margin }) => (margin === undefined ? "0 8px 0 4px" : margin)};
    height: 10px;
    width: 10px;
  }
`;
