import axios, { CancelTokenSource } from "axios";
import { stringify } from "csv-stringify/browser/esm/sync";
import React, { FC, useContext, useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "styled-components";
import downloadFile from "../../../util/downloadFile";
import errToStr from "../../../util/errToStr";
import LoadingContainer from "../../LoadingContainer";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { PageContainer } from "../../PageStyles";
import Table from "../../Table";
import { ClearPanel, Panel } from "../../Panel";
import { PrimaryBtn } from "../../Buttons";
import { Title, Subtitle } from "../../PageTitle/styles";
import Drawer from "rc-drawer";
import Cross from "../../../svgs/Cross";
import moment from "moment";
import { kegsOrTrackers } from "../../../util/kegOrTracker";
import { Select, AsyncSelect } from "../../Select";
import { fetchAutoComplete } from "../../../services/autoComplete";
import Bold from "../../Bold";
import FlatpickrRangePicker from "../../FlatpickrRangePicker";
import { TemperatureSpan } from "../../ReportsScreen/styles";
import { DangerAlert } from "../../Alerts";
import FlatpickrPicker from "../../FlatpickrPicker";
import { printFixedTemp, printTempUnit } from "../../../util/formatUnits";
import { DrawBody, DrawCloseButton, DrawContent, DrawFooter, DrawHeader, FilterInputContainer } from "../../Drawer/styles";
import { fetchTemperatureEventsReport } from "../../../services/temperatureEventsReport";
import sortTags from "../../../util/sortTags";
import { FormInput } from "../../FormComponents";
import Tag from "../../Tag";
import AssetTypeLabel from "../../AssetTypeLabel";
import { ColoredDot } from "../../GlobalStyles/coloredDot";
import getParameterByName from "../../../util/getParamByName";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import matchSorter from "match-sorter";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { TbDownload } from "react-icons/tb";
import { IconContext } from "react-icons";

const formatDataToCsv = (tableRef: any, short_datetime: string) => {
  const headers = [
    "Log Date",
    kegsOrTrackers("Keg", "Tracker"),
    "Asset Type",
    kegsOrTrackers("Keg Tags", "Tracker Tags"),
    "Address",
    "Place",
    "Place Type",
    "Place Tags",
    `Temperature (${printTempUnit()})`,
  ];

  const sortedData = tableRef.current.getResolvedState().sortedData;

  return [
    headers,
    ...sortedData.map((row: any) => {
      return [
        moment.unix(row.logDateUnix).format(short_datetime),
        `${row.trackerId}${row.trackerName ? " (" + row.trackerName + ")" : ""}`,
        row.assetTypeName,
        row.trackerTags
          ? row.trackerTags
              .sort(sortTags)
              .map((tag: any) => tag.name)
              .join(", ")
          : "",
        row.address,
        row.placeName,
        row.placeType,
        row.placeTags
          ? row.placeTags
              .sort(sortTags)
              .map((tag: any) => tag.name)
              .join(", ")
          : "",
        printFixedTemp(row.temperature, 1),
      ];
    }, []),
  ];
};

const TemperatureEvents: FC<any> = () => {
  const { color, short_date, short_datetime } = useContext(ThemeContext);
  const location = useLocation();
  const navigate = useNavigate();
  const tableRef = useRef<any>(null);

  const startDateParam = getParameterByName("start-date", location.search) != null ? getParameterByName("start-date", location.search) : null;
  const endDateParam = getParameterByName("end-date", location.search) != null ? getParameterByName("end-date", location.search) : null;

  const [data, setData] = useState<any>([]);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const [filtersOpen, setFiltersOpen] = useState<boolean>(true);
  const [filtersLoading, setFiltersLoading] = useState<boolean>(true);

  // Report filters
  const [minTemp, setMinTemp] = useState<any>("");
  const [maxTemp, setMaxTemp] = useState<any>("40");
  const [startDate, setStartDate] = useState<any>(startDateParam != null ? Number(startDateParam) * 1000 : moment().subtract(7, "days").valueOf());
  const [endDate, setEndDate] = useState<any>(endDateParam != null ? Number(endDateParam) * 1000 : moment().valueOf());
  const [tracker, setTracker] = useState<any>(null);
  const [assetType, setAssetType] = useState<any>(null);
  const [trackerTags, setTrackerTags] = useState<any>([]);
  const [place, setPlace] = useState<any>(null);
  const [placeType, setPlaceType] = useState<any>(null);
  const [placeTags, setPlaceTags] = useState<any>([]);
  const [inTransit, setInTransit] = useState<any>(null);

  const [appliedFilters, setAppliedFilters] = useState<number>(0);

  const [autoCompleteTrackers, setAutoCompleteTrackers] = useState<any>(undefined);
  const [autoCompleteAssetTypes, setAutoCompleteAssetTypes] = useState<any>(undefined);
  const [autoCompleteTrackerTags, setAutoCompleteTrackerTags] = useState<any>(undefined);
  const [autoCompletePlaces, setAutoCompletePlaces] = useState<any>(undefined);
  const [autoCompletePlaceTypes, setAutoCompletePlaceTypes] = useState<any>(undefined);
  const [autoCompletePlaceTags, setAutoCompletePlaceTags] = useState<any>(undefined);

  const [filterOptions, setFilterOptions] = useState<any>({
    trackerIds: [],
    assetTypes: [],
    trackerTags: [],
    placeNames: [],
    placeTypes: [],
    placeTags: [],
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // Fetch all autoComplete fields to use for matching params
  // e.g. match "?asset-types=Keg" to {colour: "#e6b600", icon: "BbKeg", label: "Keg", value: "b08d3cf1-cb6e-4026-998b-5702ca4a4829"}
  useEffect(() => {
    const startDateParam = getParameterByName("start-date", location.search) != null ? getParameterByName("start-date", location.search) : null;
    const endDateParam = getParameterByName("end-date", location.search) != null ? getParameterByName("end-date", location.search) : null;
    const minTempParam = getParameterByName("min-temp", location.search) != null ? getParameterByName("min-temp", location.search) : null;
    const maxTempParam = getParameterByName("max-temp", location.search) != null ? getParameterByName("max-temp", location.search) : null;
    const trackerParam = getParameterByName("tracker", location.search) != null ? getParameterByName("tracker", location.search) : null;
    const assetTypeParam = getParameterByName("asset-type", location.search) != null ? getParameterByName("asset-type", location.search) : null;
    const trackerTagsParam = getParameterByName("tags", location.search) != null ? getParameterByName("tags", location.search)?.split(",") : [];
    const placeParam = getParameterByName("place", location.search) != null ? getParameterByName("place", location.search) : null;
    const placeTypeParam = getParameterByName("place-type", location.search) != null ? getParameterByName("place-type", location.search) : null;
    const placeTagsParam = getParameterByName("place-tags", location.search) != null ? getParameterByName("place-tags", location.search)?.split(",") : [];
    const inTransitParam = getParameterByName("in-transit", location.search) != null ? getParameterByName("in-transit", location.search) : null;

    fetchAutoComplete("trackers", "").then((response) => {
      if (trackerParam != null) {
        setTracker(response.find((tracker: any) => trackerParam == tracker.value));
      }
      setAutoCompleteTrackers(response);
    });

    fetchAutoComplete("assettypes", "").then((response) => {
      if (assetTypeParam != null) {
        setAssetType(response.find((type: any) => assetTypeParam == type.label));
      }
      setAutoCompleteAssetTypes(response);
    });

    fetchAutoComplete("trackertags", "").then((response) => {
      if (trackerTagsParam && trackerTagsParam.length > 0) {
        setTrackerTags(response.filter((tag: any) => trackerTagsParam.includes(tag.label)));
      }
      setAutoCompleteTrackerTags(response);
    });

    fetchAutoComplete("places", "").then((response) => {
      if (placeParam != null) {
        setPlace(response.find((place: any) => placeParam == place.value));
      }
      setAutoCompletePlaces(response);
    });

    fetchAutoComplete("placetypes", "").then((response) => {
      if (placeTypeParam != null) {
        setPlaceType(response.find((type: any) => placeTypeParam == type.label));
      }
      setAutoCompletePlaceTypes(response);
    });

    fetchAutoComplete("placetags", "").then((response) => {
      if (placeTagsParam && placeTagsParam.length > 0) {
        setPlaceTags(response.filter((tag: any) => placeTagsParam.includes(tag.label)));
      }
      setAutoCompletePlaceTags(response);
    });

    if (startDateParam != null && endDateParam != null && (minTempParam != null || maxTempParam != null)) {
      setFiltersOpen(false);
      fetchReport(
        Number(startDateParam) * 1000,
        Number(endDateParam) * 1000,
        minTempParam,
        maxTempParam,
        trackerParam,
        assetTypeParam,
        trackerTagsParam,
        placeParam,
        placeTypeParam,
        placeTagsParam,
        inTransitParam
      );
    }
  }, []);

  // On location change (e.g. url parameter changes because filters changed), update filters and fetch report
  useEffect(() => {
    let selectedStartDate = undefined;
    let selectedEndDate = undefined;
    let selectedMinTemp = undefined;
    let selectedMaxTemp = undefined;
    let selectedTracker = undefined;
    let selectedAssetType = undefined;
    let selectedTrackerTags = [];
    let selectedPlace = undefined;
    let selectedPlaceType = undefined;
    let selectedPlaceTags = [];
    let selectedInTransit = undefined;

    const startDateParam = getParameterByName("start-date", location.search) != null ? getParameterByName("start-date", location.search) : null;
    if (startDateParam != null) {
      selectedStartDate = Number(startDateParam) * 1000;
      setStartDate(selectedStartDate);
    }

    const endDateParam = getParameterByName("end-date", location.search) != null ? getParameterByName("end-date", location.search) : null;
    if (endDateParam != null) {
      selectedEndDate = Number(endDateParam) * 1000;
      setEndDate(selectedEndDate);
    }

    const minTempParam = getParameterByName("min-temp", location.search) != null ? getParameterByName("min-temp", location.search) : null;
    if (minTempParam != null) {
      selectedMinTemp = minTempParam;
      setMinTemp(selectedMinTemp);
    }

    const maxTempParam = getParameterByName("max-temp", location.search) != null ? getParameterByName("max-temp", location.search) : null;
    if (maxTempParam != null) {
      selectedMaxTemp = maxTempParam;
      setMaxTemp(selectedMaxTemp);
    }

    if (autoCompleteTrackers != null) {
      const trackerParam = getParameterByName("tracker", location.search) != null ? getParameterByName("tracker", location.search) : null;
      selectedTracker = autoCompleteTrackers.find((tracker: any) => trackerParam == tracker.value);
      setTracker(selectedTracker);
    }

    if (autoCompleteAssetTypes != null) {
      const assetTypeParam = getParameterByName("asset-type", location.search) != null ? getParameterByName("asset-type", location.search) : null;
      selectedAssetType = autoCompleteAssetTypes.find((type: any) => assetTypeParam == type.label);
      setAssetType(selectedAssetType);
    }

    if (autoCompleteTrackerTags != null) {
      const trackerTagsParam = getParameterByName("tags", location.search) != null ? getParameterByName("tags", location.search)?.split(",") : [];
      selectedTrackerTags = autoCompleteTrackerTags?.filter((tag: any) => trackerTagsParam?.includes(tag.label)) || [];
      setTrackerTags(selectedTrackerTags);
    }

    if (autoCompletePlaces != null) {
      const placeParam = getParameterByName("place", location.search) != null ? getParameterByName("place", location.search) : null;
      selectedPlace = autoCompletePlaces.find((place: any) => placeParam == place.value);
      setPlace(selectedPlace);
    }

    if (autoCompletePlaceTypes != null) {
      const placeTypeParam = getParameterByName("place-type", location.search) != null ? getParameterByName("place-type", location.search) : null;
      selectedPlaceType = autoCompletePlaceTypes.find((type: any) => placeTypeParam == type.label);
      setPlaceType(selectedPlaceType);
    }

    if (autoCompletePlaceTags != null) {
      const placeTagsParam = getParameterByName("place-tags", location.search) != null ? getParameterByName("place-tags", location.search)?.split(",") : [];
      selectedPlaceTags = autoCompletePlaceTags?.filter((tag: any) => placeTagsParam?.includes(tag.label)) || [];
      setPlaceTags(selectedPlaceTags);
    }

    const inTransitParam = getParameterByName("in-transit", location.search) != null ? getParameterByName("in-transit", location.search) : null;
    if (inTransitParam != null) {
      selectedInTransit = inTransitParam == "true" ? { label: "True", value: "true" } : { label: "False", value: "false" };
      setInTransit(selectedInTransit);
    }

    if (!filtersLoading) {
      fetchReport(
        selectedStartDate,
        selectedEndDate,
        selectedMinTemp,
        selectedMaxTemp,
        selectedTracker?.value,
        selectedAssetType?.label,
        selectedTrackerTags?.length > 0 ? selectedTrackerTags.map((tag: any) => tag.label).join(",") : [],
        selectedPlace?.value,
        selectedPlaceType?.label,
        selectedPlaceTags?.length > 0 ? selectedPlaceTags.map((tag: any) => tag.label).join(",") : [],
        selectedInTransit?.value
      );
    }
  }, [location]);

  // After autoCompletes are initially set on mount, update filtersLoading to false
  useEffect(() => {
    if (
      autoCompleteTrackers != null &&
      autoCompleteAssetTypes != null &&
      autoCompleteTrackerTags != null &&
      autoCompletePlaces != null &&
      autoCompletePlaceTypes != null &&
      autoCompletePlaceTags != null
    ) {
      setFiltersLoading(false);
    }
  }, [autoCompleteTrackers, autoCompleteAssetTypes, autoCompleteTrackerTags, autoCompletePlaces, autoCompletePlaceTypes, autoCompletePlaceTags]);

  const updateQueryParams = () => {
    const parsed = queryString.parse(location.search);
    const newQuery = { ...parsed };

    if (startDate != null) {
      newQuery["start-date"] = Math.round(startDate.valueOf() / 1000).toString();
    } else {
      delete newQuery["start-date"];
    }

    if (endDate != null) {
      newQuery["end-date"] = Math.round(endDate.valueOf() / 1000).toString();
    } else {
      delete newQuery["end-date"];
    }

    if (minTemp !== "") {
      newQuery["min-temp"] = minTemp;
    } else {
      delete newQuery["min-temp"];
    }

    if (maxTemp !== "") {
      newQuery["max-temp"] = maxTemp;
    } else {
      delete newQuery["max-temp"];
    }

    if (tracker != null) {
      newQuery["tracker"] = tracker?.value;
    } else if (autoCompleteTrackers != null) {
      delete newQuery["tracker"];
    }

    if (assetType != null) {
      newQuery["asset-type"] = assetType?.label;
    } else if (autoCompleteAssetTypes != null) {
      delete newQuery["asset-type"];
    }

    if (trackerTags && trackerTags.length > 0) {
      newQuery["tags"] = trackerTags.map((tag: any) => tag.label).join(",");
    } else if (autoCompleteTrackerTags != null) {
      delete newQuery["tags"];
    }

    if (place != null) {
      newQuery["place"] = place?.value;
    } else if (autoCompletePlaces != null) {
      delete newQuery["place"];
    }

    if (placeType != null) {
      newQuery["place-type"] = placeType?.label;
    } else if (autoCompletePlaceTypes != null) {
      delete newQuery["place-type"];
    }

    if (placeTags && placeTags.length > 0) {
      newQuery["place-tags"] = placeTags.map((tag: any) => tag.label).join(",");
    } else if (autoCompletePlaceTags != null) {
      delete newQuery["place-tags"];
    }

    if (inTransit != null) {
      newQuery["in-transit"] = inTransit?.value;
    } else {
      delete newQuery["in-transit"];
    }

    const stringified = queryString.stringify(newQuery);
    navigate({ ...location, search: stringified });
  };

  const fetchReport = (
    startDateParam?: any,
    endDateParam?: any,
    minTempParam?: any,
    maxTempParam?: any,
    trackerParam?: any,
    assetTypeParam?: any,
    trackerTagsParam?: any,
    placeParam?: any,
    placeTypeParam?: any,
    placeTagsParam?: any,
    inTransitParam?: any
  ) => {
    if (!startDate) setDataErr("Start date is required");
    else if (!endDate) setDataErr("End date is required");
    else if (minTemp === "" && maxTemp === "") setDataErr("Minimum or Maximum Temperature is required");
    else {
      setDataLoading(true);
      setDataErr("");

      const startDateArg = startDateParam != null ? startDateParam : startDate;
      const endDateArg = endDateParam != null ? endDateParam : endDate;
      const minTempArg = minTempParam != null ? minTempParam : minTemp;
      const maxTempArg = maxTempParam != null ? maxTempParam : maxTemp;
      const trackerArg = trackerParam != null ? trackerParam : tracker?.value;
      const assetTypeArg = assetTypeParam != null ? assetTypeParam : assetType?.label;
      const trackerTagsArg =
        trackerTagsParam?.length > 0 ? trackerTagsParam : trackerTags?.length > 0 ? trackerTags.map((tag: any) => tag.label).join(",") : [];
      const placeArg = placeParam != null ? placeParam : place?.value;
      const placeTypeArg = placeTypeParam != null ? placeTypeParam : placeType?.label;
      const placeTagsArg = placeTagsParam?.length > 0 ? placeTagsParam : placeTags?.length > 0 ? placeTags.map((tag: any) => tag.label).join(",") : [];
      const inTransitArg = inTransitParam != null ? inTransitParam : inTransit?.value;

      fetchTemperatureEventsReport(
        source,
        startDateArg ? Math.round(startDateArg / 1000) : undefined,
        endDateArg ? Math.round(endDateArg / 1000) : undefined,
        minTempArg,
        maxTempArg,
        trackerArg,
        assetTypeArg,
        trackerTagsArg,
        placeArg,
        placeTypeArg,
        placeTagsArg,
        inTransitArg
      )
        .then((response) => {
          // get distinct strings for use in the select input filters
          if (response.length > 0) {
            const trackerIds = new Set();
            const assetTypes = new Set();
            const trackerTags = new Set();
            const placeNames = new Set();
            const placeTypes = new Set();
            const placeTags = new Set();

            for (let i = 0; i < response.length; i++) {
              if (response[i].trackerId) trackerIds.add(response[i].trackerId);
              if (response[i].assetTypeName) assetTypes.add(response[i].assetTypeName);
              if (response[i].trackerTags) response[i].trackerTags.forEach((tag: any) => trackerTags.add(tag.name));
              if (response[i].placeName) placeNames.add(response[i].placeName);
              if (response[i].placeType) placeTypes.add(response[i].placeType);
              if (response[i].placeTags) response[i].placeTags.forEach((tag: any) => placeTags.add(tag.name));
            }

            setFilterOptions({
              trackerIds: Array.from(trackerIds).sort(),
              assetTypes: Array.from(assetTypes).sort(),
              trackerTags: Array.from(trackerTags).sort(),
              placeNames: Array.from(placeNames).sort(),
              placeTypes: Array.from(placeTypes).sort(),
              placeTags: Array.from(placeTags).sort(),
            });
          }

          setData(response);
          setDataLoading(false);
          setFiltersOpen(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setData([]);
            setDataErr(errToStr(err));
            setDataLoading(false);
          }
        });

      let filters = 0;

      if (startDateArg != null) filters++;
      if (endDateArg != null) filters++;
      if (minTempArg !== "") filters++;
      if (maxTempArg !== "") filters++;
      if (trackerArg != null) filters++;
      if (assetTypeArg != null) filters++;
      if (trackerTagsArg.length) filters += trackerTagsArg.length;
      if (placeArg != null) filters++;
      if (placeTypeArg != null) filters++;
      if (placeTagsArg.length) filters += placeTagsArg.length;
      if (inTransitArg != null) filters++;

      setAppliedFilters(filters);
    }
  };

  const columns = [
    {
      id: "logDateUnix",
      Header: "Log Date",
      accessor: "logDateUnix",
      filterMethod: (filter: any, row: any) => {
        if (filter.value.length === 2) {
          if (row[filter.id] >= moment(filter.value[0]).startOf("day").unix() && row[filter.id] <= moment(filter.value[1]).endOf("day").unix()) return true;
          else return false;
        } else return true;
      },
      Filter: ({ onChange }: any) => (
        <FlatpickrRangePicker options={{ mode: "range", formatDate: (d: any) => moment(d).format(short_date) }} onClose={(d: any) => onChange(d)} />
      ),
      Cell: (props: any) => moment.unix(props.value).format(short_datetime),
      Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
      minWidth: 160,
    },
    {
      id: "trackerId",
      Header: kegsOrTrackers("Keg", "Tracker"),
      accessor: "trackerId",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") {
          return true;
        }
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          {filterOptions.trackerIds.map((label: any) => {
            return (
              <option key={label} value={label}>
                {label}
              </option>
            );
          }, [])}
        </select>
      ),
      Cell: (props: any) => {
        const url = kegsOrTrackers("kegs", "trackers");
        let title = props.value ? props.value : "";
        title += `${props.original.trackerName ? " (" + props.original.trackerName + ")" : ""}`;

        return (
          <Link
            to={`/${url}/${props.value}?start=${moment.unix(props.original.logDateUnix).subtract(1, "days").unix()}&end=${moment
              .unix(props.original.logDateUnix)
              .add(1, "days")
              .unix()}`}
            title={title}
          >
            {title}
          </Link>
        );
      },
      minWidth: 200,
    },
    {
      id: "trackerName",
      accessor: "trackerName",
      show: false,
    },
    {
      id: "assetTypeName",
      Header: "Asset Type",
      accessor: "assetTypeName",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") return true;
        if (filter.value === "none") return row[filter.id] == null;
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          <option value="none">None</option>
          {filterOptions.assetTypes.map((name: any) => {
            return (
              <option key={name} value={name}>
                {name}
              </option>
            );
          }, [])}
        </select>
      ),
      Cell: (props: any) => (
        <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
          <AssetTypeLabel name={props.value} colour={props.original.assetTypeColour} icon={props.original.assetTypeIcon} />
        </div>
      ),
      minWidth: 125,
    },
    {
      id: "trackerTags",
      Header: kegsOrTrackers("Keg Tags", "Tracker Tags"),
      accessor: "trackerTags",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") return true;
        if (filter.value === "none") return row[filter.id].length === 0;
        if (Array.isArray(row[filter.id])) return row[filter.id].some((tag: any) => tag.name === filter.value);
        return false;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          <option value="none">None</option>
          {filterOptions.trackerTags.map((tag: any) => {
            return (
              <option key={tag} value={tag}>
                {tag}
              </option>
            );
          }, [])}
        </select>
      ),
      Cell: (props: any) =>
        props.value ? (
          props.value.sort(sortTags).map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
        ) : (
          <></>
        ),
      minWidth: 140,
    },
    {
      id: "address",
      Header: "Address",
      accessor: "address",
      minWidth: 400,
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["address"],
        }),
      filterAll: true,
    },
    {
      id: "placeName",
      Header: "Place",
      accessor: "placeName",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") return true;
        if (filter.value === "none") return row[filter.id] == null;
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          <option value="none">None</option>
          {filterOptions.placeNames.map((label: any) => {
            return (
              <option key={label} value={label}>
                {label}
              </option>
            );
          }, [])}
        </select>
      ),
      Cell: (props: any) =>
        props.value ? (
          <Link
            to={`/places/${props.original.placeId}?start=${moment.unix(props.original.logDateUnix).subtract(1, "days").unix()}&end=${moment
              .unix(props.original.logDateUnix)
              .add(1, "days")
              .unix()}`}
            title={props.value}
          >
            {props.value}
          </Link>
        ) : (
          ""
        ),
      minWidth: 180,
    },
    {
      id: "placeType",
      Header: "Place Type",
      accessor: "placeType",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") return true;
        if (filter.value === "none") return row[filter.id] == null;
        return row[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          <option value="none">None</option>
          {filterOptions.placeTypes.map((label: any) => {
            return (
              <option key={label} value={label}>
                {label}
              </option>
            );
          }, [])}
        </select>
      ),
      Cell: (props: any) => (
        <>
          <ColoredDot color={props.original.placeTypeColour} />
          <span title={props.value}>{props.value}</span>
        </>
      ),
      minWidth: 125,
    },
    {
      id: "placeTags",
      Header: "Place Tags",
      accessor: "placeTags",
      filterMethod: (filter: any, row: any) => {
        if (filter.value === "all") return true;
        if (filter.value === "none") return row[filter.id].length === 0;
        if (Array.isArray(row[filter.id])) return row[filter.id].some((tag: any) => tag.name === filter.value);
        return false;
      },
      Filter: ({ filter, onChange }: any) => (
        <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
          <option value="all">All</option>
          <option value="none">None</option>
          {filterOptions.placeTags.map((tag: any) => {
            return (
              <option key={tag} value={tag}>
                {tag}
              </option>
            );
          }, [])}
        </select>
      ),
      Cell: (props: any) =>
        props.value ? (
          props.value.sort(sortTags).map((tag: any) => <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />)
        ) : (
          <></>
        ),
      minWidth: 125,
    },
    {
      id: "temperature",
      Header: "Temperature",
      accessor: "temperature",
      Cell: (props: any) => <TemperatureSpan temp={props.value} />,
      minWidth: 140,
    },
  ];

  const defaultSorted = [
    {
      id: "startDate",
      desc: true,
    },
  ];

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  return (
    <>
      <Drawer
        placement="right"
        level={null}
        open={filtersOpen}
        onClose={() => {
          if (!filtersLoading) {
            setFiltersOpen(false);
            updateQueryParams();
          }
        }}
        onHandleClick={() => {
          if (!filtersLoading) setFiltersOpen(!filtersOpen);
        }}
        handler={false}
      >
        <DrawContent>
          <DrawHeader>
            <div
              style={{
                display: "inline-block",
                width: "20px",
                height: "20px",
                fontSize: "0",
                marginRight: "6px",
              }}
            >
              <IconContext.Provider value={{ color: color.font[2], size: "20px" }}>
                <HiOutlineAdjustmentsHorizontal />
              </IconContext.Provider>
            </div>
            Filters
            <DrawCloseButton
              onClick={() => {
                if (!filtersLoading) setFiltersOpen(false);
              }}
              aria-label={`Close filters`}
            >
              <Cross />
            </DrawCloseButton>
          </DrawHeader>
          <DrawBody>
            <LoadingContainer loading={filtersLoading}>
              <form noValidate onSubmit={(e) => e.preventDefault()}>
                <label>Start Date</label>
                <FilterInputContainer>
                  <FlatpickrPicker
                    name="startDate"
                    enableTime={true}
                    value={startDate}
                    clearable={false}
                    onChange={(d: any) => setStartDate(d[0])}
                    onClose={(d: any) => setStartDate(d[0])}
                    options={{ maxDate: Date.now() }}
                  />
                </FilterInputContainer>
                <label>End Date</label>
                <FilterInputContainer>
                  <FlatpickrPicker
                    name="endDate"
                    enableTime={true}
                    value={endDate}
                    clearable={false}
                    onChange={(d: any) => setEndDate(d[0])}
                    onClose={(d: any) => setEndDate(d[0])}
                    options={{ maxDate: Date.now() }}
                  />
                </FilterInputContainer>
                <label>Minimum Temperature</label>
                <FilterInputContainer>
                  <FormInput
                    type="number"
                    value={minTemp}
                    onChange={(e: any) => {
                      setMinTemp(e.target.value);
                    }}
                  />
                </FilterInputContainer>
                <label>Maximum Temperature</label>
                <FilterInputContainer>
                  <FormInput
                    type="number"
                    value={maxTemp}
                    onChange={(e: any) => {
                      setMaxTemp(e.target.value);
                    }}
                  />
                </FilterInputContainer>
                <label>{kegsOrTrackers("Keg", "Tracker")}</label>
                <FilterInputContainer>
                  <AsyncSelect
                    name="tracker"
                    defaultOptions={true}
                    isClearable={true}
                    isSearchable={true}
                    value={tracker}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("trackers", inputValue, callback)}
                    onChange={(selected: any) => {
                      if (selected) {
                        setTracker(selected);
                      } else {
                        setTracker(null);
                      }
                    }}
                    placeholder="Select..."
                  />
                </FilterInputContainer>
                <label>Asset Type</label>
                <FilterInputContainer>
                  <AsyncSelect
                    name="assetType"
                    defaultOptions={true}
                    isClearable={true}
                    isSearchable={true}
                    value={assetType}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("assetTypes", inputValue, callback)}
                    onChange={(selected: any) => {
                      if (selected) {
                        setAssetType(selected);
                      } else {
                        setAssetType(null);
                      }
                    }}
                    placeholder="Select..."
                  />
                </FilterInputContainer>
                <label>{kegsOrTrackers("Keg Tags", "Tracker Tags")}</label>
                <FilterInputContainer>
                  <AsyncSelect
                    name="trackerTags"
                    defaultOptions={true}
                    isClearable={true}
                    isSearchable={true}
                    value={trackerTags}
                    isMulti={true}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("trackerTags", inputValue, callback)}
                    onChange={(selected: any) => {
                      if (selected) {
                        setTrackerTags(selected);
                      } else {
                        setTrackerTags([]);
                      }
                    }}
                    placeholder="Select..."
                  />
                </FilterInputContainer>
                <label>Place</label>
                <FilterInputContainer>
                  <AsyncSelect
                    isDisabled={inTransit && inTransit.value === "true"}
                    name="place"
                    defaultOptions={true}
                    isClearable={true}
                    isSearchable={true}
                    value={place}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("places", inputValue, callback)}
                    onChange={(selected: any) => {
                      if (selected) {
                        setPlace(selected);
                      } else {
                        setPlace(null);
                      }
                    }}
                    placeholder="Select..."
                  />
                </FilterInputContainer>
                <label>Place Type</label>
                <FilterInputContainer>
                  <AsyncSelect
                    isDisabled={inTransit && inTransit.value === "true"}
                    name="placeType"
                    defaultOptions={true}
                    isClearable={true}
                    isSearchable={true}
                    value={placeType}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("placeTypes", inputValue, callback)}
                    onChange={(selected: any) => {
                      if (selected) {
                        setPlaceType(selected);
                      } else {
                        setPlaceType(null);
                      }
                    }}
                    placeholder="Select..."
                  />
                </FilterInputContainer>
                <label>Place Tags</label>
                <FilterInputContainer>
                  <AsyncSelect
                    isDisabled={inTransit && inTransit.value === "true"}
                    name="placeTags"
                    defaultOptions={true}
                    isClearable={true}
                    isSearchable={true}
                    value={placeTags}
                    isMulti={true}
                    loadOptions={(inputValue: any, callback: any) => loadOptions("placeTags", inputValue, callback)}
                    onChange={(selected: any) => {
                      if (selected) {
                        setPlaceTags(selected);
                      } else {
                        setPlaceTags([]);
                      }
                    }}
                    placeholder="Select..."
                  />
                </FilterInputContainer>
                <label>In Transit</label>
                <FilterInputContainer>
                  <Select
                    isDisabled={place != null || placeType != null || placeTags.length > 0}
                    name="inTransit"
                    isClearable={true}
                    isSearchable={true}
                    value={inTransit}
                    options={[
                      { label: "True", value: "true" },
                      { label: "False", value: "false" },
                    ]}
                    onChange={(selected: any) => {
                      if (selected) {
                        setInTransit(selected);
                      } else {
                        setInTransit(null);
                      }
                    }}
                    placeholder="Select..."
                  />
                </FilterInputContainer>
                {dataErr && <DangerAlert>{dataErr}</DangerAlert>}
              </form>
            </LoadingContainer>
          </DrawBody>
          <DrawFooter>
            <PrimaryBtn
              onClick={() => {
                if (!filtersLoading) {
                  setFiltersOpen(false);
                  updateQueryParams();
                }
              }}
            >
              Apply Filters
            </PrimaryBtn>
          </DrawFooter>
        </DrawContent>
      </Drawer>
      <PageBreadcrumbs prevRoutes={[{ slug: "/reports", title: "Reports" }]} currRoute="Temperature Events Audit" />
      <PageContainer top="40px">
        <div style={{ position: "relative" }}>
          <LoadingContainer loading={dataLoading}>
            <ClearPanel style={{ textAlign: "center" }}>
              <Title style={{ marginBottom: "6px" }}>Temperature Events Audit</Title>
              <Subtitle>Occasions where trackers recorded temperatures outside a specified range</Subtitle>
            </ClearPanel>
            <ClearPanel style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <div>
                <PrimaryBtn style={{ height: "40px", minWidth: "unset", marginRight: "12px" }} padding="0 6px" onClick={() => setFiltersOpen(!filtersOpen)}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div
                      style={{
                        display: "inline-block",
                        width: "20px",
                        height: "20px",
                        fontSize: "0",
                        marginRight: "6px",
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiOutlineAdjustmentsHorizontal />
                      </IconContext.Provider>
                    </div>
                    <span>Filters</span>
                  </div>
                </PrimaryBtn>
                <span style={{ fontSize: "12px", whiteSpace: "nowrap" }}>
                  {appliedFilters} filter{appliedFilters === 1 ? "" : "s"} applied
                </span>
              </div>
              <PrimaryBtn
                style={{ height: "40px", minWidth: "unset", marginRight: "12px" }}
                padding="0 6px"
                onClick={() =>
                  downloadFile(
                    stringify(formatDataToCsv(tableRef, short_datetime), {
                      quoted: true,
                      quoted_string: true,
                    }),
                    "text/csv;charset=utf-8",
                    "Temperature Events Audit.csv"
                  )
                }
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      display: "inline-block",
                      width: "20px",
                      height: "20px",
                      fontSize: "0",
                      marginRight: "6px",
                    }}
                  >
                    <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                      <TbDownload />
                    </IconContext.Provider>
                  </div>
                  <span
                    style={{
                      marginRight: "6px",
                    }}
                  >
                    CSV
                  </span>
                </div>
              </PrimaryBtn>
            </ClearPanel>
            <Panel>
              <Table
                loading={dataLoading}
                style={{ clear: "both" }}
                data={data}
                filterable={true}
                columns={columns}
                defaultSorted={defaultSorted}
                ref={tableRef}
              />
            </Panel>
            {dataErr && <DangerAlert>{dataErr}</DangerAlert>}
          </LoadingContainer>
        </div>
      </PageContainer>
    </>
  );
};

export default TemperatureEvents;
