import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { InfoModal, SubmitModal } from "../Modal";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import { kegOrTracker } from "../../util/kegOrTracker";
import { countUnresolvedAlerts, postEditBulkAlerts } from "../../services/alertNotifications";

const BulkResolveSensorAlerts: FC<any> = ({ alert = {}, onSuccess, modalOpen, setModalOpen }) => {
  const [count, setCount] = useState<number | undefined>(undefined);
  const [countErr, setCountErr] = useState<string>("");
  const [countLoading, setCountLoading] = useState<boolean>(true);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setCountLoading(true);
    countUnresolvedAlerts(source, alert.sensorId)
      .then((response) => {
        setCount(response);
        setCountErr("");
        setCountLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setCountErr(errToStr(err));
          setCountLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [source]);

  const handleSubmit = () => {
    setSubmittedMsg("");
    setSubmittingErr("");
    setSubmitting(true);
    postEditBulkAlerts(source, { sensorId: alert.sensorId, action: "resolve" })
      .then(() => {
        onSuccess();
        setSubmittedMsg(`All alerts for ${alert.sensorId} were resolved`);
        setSubmitting(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  if (count === 0) {
    return (
      <InfoModal
        isOpen={modalOpen}
        onClose={() => {
          if (!submitting) setModalOpen(false);
        }}
        title={"Resolve Alerts"}
        error={countErr || submittingErr}
        okayBtnText="Okay"
        body={
          <LoadingContainer loading={countLoading || submitting}>
            <span>
              All alerts for {kegOrTracker("Keg", "Tracker")} {alert.sensorId} are already resolved.
            </span>
          </LoadingContainer>
        }
      />
    );
  }

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) setModalOpen(false);
      }}
      title={"Resolve Alerts"}
      success={submittedMsg}
      error={countErr || submittingErr}
      submitBtnText="Yes"
      cancelBtnText="Cancel"
      body={
        <LoadingContainer loading={countLoading || submitting}>
          <span>
            Are you sure you want to resolve all alerts <b>( {count} )</b> for {kegOrTracker("Keg", "Tracker")} {alert.sensorId}?
          </span>
        </LoadingContainer>
      }
    />
  );
};

export default BulkResolveSensorAlerts;
