const getSignalStrength = (csq: number | null) => {
  if (csq !== null) {
    if (csq >= 15) return 4;
    else if (csq >= 10 && csq < 15) return 3;
    else if (csq >= 3 && csq < 10) return 2;
    else return 1;
  } else {
    return null;
  }
};

export default getSignalStrength;
