import ReactEcharts from "echarts-for-react";
import moment from "moment";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "styled-components";
import InfoIcon from "../../svgs/Legend";
import calculateGrid from "../../util/calculateGrid";
import { WarningAlert } from "../Alerts";
import {
  eventsSeries,
  eventsTooltip,
  freshSeries,
  freshTooltip,
  freshYAxis,
  input1Series,
  input1Tooltip,
  input1YAxis,
  input2Series,
  input2Tooltip,
  input2YAxis,
  input3Series,
  input3Tooltip,
  input3YAxis,
  input4Series,
  input4Tooltip,
  input4YAxis,
  input5Series,
  input5Tooltip,
  input5YAxis,
  input6Series,
  input6Tooltip,
  input6YAxis,
  input7Series,
  input7Tooltip,
  input7YAxis,
  lightInterruptSeries,
  lightInterruptTooltip,
  lightSeries,
  lightTooltip,
  lightYAxis,
  loadVoltageSeries,
  loadVoltageTooltip,
  moveSeries,
  moveTooltip,
  moveYAxis,
  networkSeries,
  networkTooltip,
  networkYAxis,
  onTapSeries,
  onTapTooltip,
  onTapYAxis,
  orientationSeries,
  orientationTooltip,
  orientationYAxis,
  placeSeries,
  placeTooltip,
  placeYAxis,
  runningSeries,
  runningTooltip,
  runningYAxis,
  sampleSeries,
  sampleYAxis,
  supplyVoltageSeries,
  supplyVoltageTooltip,
  supplyVoltageYAxis,
  tempSeries,
  tempTooltip,
  tempYAxis,
  timeOfFlightSeries,
  timeOfFlightTooltip,
  timeOfFlightYAxis,
  voltageSeries,
  voltageTooltip,
  voltageYAxis,
} from "../Charts";
import { InfoIconContainer } from "../FormComponents";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import { NoDataComponent } from "../Table";
import InfoTooltip from "../Tooltip";
import { SampleTooltipContainer } from "./styles";

const tooltips: any = {
  move: moveTooltip,
  events: eventsTooltip,
  place: placeTooltip,
  temp: tempTooltip,
  voltage: voltageTooltip,
  loadVoltage: loadVoltageTooltip,
  supplyVoltage: supplyVoltageTooltip,
  orientation: orientationTooltip,
  fresh: freshTooltip,
  light: lightTooltip,
  lightInterrupt: lightInterruptTooltip,
  running: runningTooltip,
  network: networkTooltip,
  input1: input1Tooltip,
  input2: input2Tooltip,
  input3: input3Tooltip,
  input4: input4Tooltip,
  input5: input5Tooltip,
  input6: input6Tooltip,
  input7: input7Tooltip,
  timeOfFlight: timeOfFlightTooltip,
  coupled: onTapTooltip,
};

const SensorLogs: FC<any> = ({ map, logs, pieces, events, meta, loading, series: presentSeries, setCursor }) => {
  const { color, sample_type_colors, echarts_palette, short_datetime, echarts_theme, echarts_opacity } = useContext(ThemeContext);

  const options = useRef<any>({ dataset: { dimensions: [] } });
  const echartsContainerRef = useRef(null);

  const [echartsInstance, setEchartsInstance] = useState<any>(undefined);
  const [height, setHeight] = useState<number>(550);

  useEffect(() => {
    if (echartsInstance) {
      // deep clone since we probably add "events" to dimensions
      const dimensions = JSON.parse(JSON.stringify(presentSeries));

      // Move "place" element to 4th position of array if exists
      const pIndex = dimensions.indexOf("place");
      if (pIndex > -1) {
        dimensions.splice(pIndex, 1);
        dimensions.unshift("place");
      }

      // Add "events" element to 3rd position of array if there is events
      if (events.length > 0) {
        dimensions.unshift("events");
      }

      // Move "move" element to 2nd position of array if exists
      const mIndex = dimensions.indexOf("move");
      if (mIndex > -1) {
        dimensions.splice(mIndex, 1);
        dimensions.unshift("move");
      }

      // Move "sample" element to start of array if exists
      const sIndex = dimensions.indexOf("sample");
      if (sIndex > -1) {
        dimensions.splice(sIndex, 1);
        dimensions.unshift("sample");
      }

      // dimensions that have their own chart, e.g. not light interrupt or load voltage
      const majorDimensions = dimensions.filter(
        (val: any) => val !== "lightInterrupt" && val !== "loadVoltage" && val !== "placeGroupColour" && val !== "const" && val !== "events"
      );

      const placeIndex = majorDimensions.indexOf("place");

      // if there are more than 5000 rows of data, downsample the data using the max values
      // overwise don't do any downsampling
      const sampling = "none";

      const grid = calculateGrid(majorDimensions, setHeight);
      const xAxis = [];
      const yAxis = [];
      const series = [];
      const dataZoom =
        majorDimensions.length > 0
          ? [
              {
                type: "inside",
                xAxisIndex: Array.from(Array(majorDimensions.length).keys()),
              },
            ]
          : [];

      let tsMin = 0;
      let tsMax = 0;

      if (events.length > 0) {
        tsMax = events[events.length - 1][0];
      }

      if (logs.length > 0) {
        tsMin = logs[0].ts;
        tsMax = logs[logs.length - 1].ts > tsMax ? logs[logs.length - 1].ts : tsMax;
      }

      for (let i = 0; i < majorDimensions.length; i++) {
        xAxis.push(
          i !== majorDimensions.length - 1
            ? {
                gridIndex: i,
                type: "time",
                min: tsMin,
                max: tsMax,
                minInterval: 3600 * 24,
                axisLine: {
                  show: majorDimensions[i] === "sample" ? false : true,
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  hideOverlap: true,
                  show: false,
                },
                axisPointer: {
                  label: {
                    show: false,
                  },
                },
              }
            : {
                gridIndex: i,
                type: "time",
                min: tsMin,
                max: tsMax,
                minInterval: 3600 * 24,
                axisLabel: {
                  hideOverlap: true,
                  formatter: (value: any): string => moment.unix(value).format(short_datetime),
                },
                axisPointer: {
                  label: {
                    show: false,
                  },
                },
              }
        );

        switch (majorDimensions[i]) {
          case "sample":
            yAxis.push(sampleYAxis(i));
            series.push(sampleSeries(i, sampling, echarts_opacity, sample_type_colors));
            break;
          case "move":
            yAxis.push(moveYAxis(i));
            series.push(moveSeries(i, sampling, echarts_opacity, echarts_palette));
            break;
          case "place":
            yAxis.push(placeYAxis(i));
            series.push(placeSeries(i, sampling, echarts_opacity, echarts_palette));
            if (dimensions.includes("events")) series.push(eventsSeries(i, sampling, echarts_opacity, echarts_palette, events));
            break;
          case "temp":
            yAxis.push(tempYAxis(i));
            series.push(tempSeries(i, sampling, echarts_opacity, echarts_palette));
            break;
          case "voltage":
            yAxis.push(voltageYAxis(i));
            series.push(voltageSeries(i, sampling, echarts_opacity, echarts_palette));
            if (dimensions.includes("loadVoltage")) series.push(loadVoltageSeries(i, sampling, echarts_opacity, echarts_palette));
            break;
          case "supplyVoltage":
            yAxis.push(supplyVoltageYAxis(i));
            series.push(supplyVoltageSeries(i, sampling, echarts_opacity, echarts_palette));
            break;
          case "orientation":
            yAxis.push(orientationYAxis(i));
            series.push(orientationSeries(i, sampling, echarts_opacity, echarts_palette));
            break;
          case "fresh":
            yAxis.push(freshYAxis(i));
            series.push(freshSeries(i, sampling, echarts_opacity, echarts_palette));
            break;
          case "light":
            yAxis.push(lightYAxis(i));
            series.push(lightSeries(i, sampling, echarts_opacity, echarts_palette));
            if (dimensions.includes("lightInterrupt")) series.push(lightInterruptSeries(i, sampling, echarts_opacity, echarts_palette));
            break;
          case "running":
            yAxis.push(runningYAxis(i));
            series.push(runningSeries(i, sampling, echarts_opacity, echarts_palette));
            break;
          case "network":
            yAxis.push(networkYAxis(i));
            series.push(networkSeries(i, sampling, echarts_opacity, echarts_palette));
            break;
          case "input1":
            yAxis.push(input1YAxis(i, meta.input1Label, meta.input1Unit, meta.input1Mode));
            series.push(input1Series(i, sampling, echarts_opacity, meta.input1Label));
            break;
          case "input2":
            yAxis.push(input2YAxis(i, meta.input2Label, meta.input2Unit));
            series.push(input2Series(i, sampling, echarts_opacity, meta.input2Label));
            break;
          case "input3":
            yAxis.push(input3YAxis(i, meta.input3Label, meta.input3Unit, meta.input3Mode));
            series.push(input3Series(i, sampling, echarts_opacity, meta.input3Label));
            break;
          case "input4":
            yAxis.push(input4YAxis(i, meta.input4Label, meta.input4Unit, meta.input4Mode));
            series.push(input4Series(i, sampling, echarts_opacity, meta.input4Label));
            break;
          case "input5":
            yAxis.push(input5YAxis(i, meta.input5Label, meta.input5Unit));
            series.push(input5Series(i, sampling, echarts_opacity, meta.input5Label));
            break;
          case "input6":
            yAxis.push(input6YAxis(i, meta.input6Label, meta.input6Unit));
            series.push(input6Series(i, sampling, echarts_opacity, meta.input6Label));
            break;
          case "input7":
            yAxis.push(input7YAxis(i, meta.input7Label, meta.input7Unit));
            series.push(input7Series(i, sampling, echarts_opacity, meta.input7Label));
            break;
          case "timeOfFlight":
            yAxis.push(timeOfFlightYAxis(i));
            series.push(timeOfFlightSeries(i, sampling, echarts_opacity));
            break;
          case "coupled":
            yAxis.push(onTapYAxis(i));
            series.push(onTapSeries(i, sampling, echarts_opacity));
            break;
        }
      }

      const tooltipFormatter = (params: any) => {
        if (params.length > 0) {
          const ts = params[0].data.ts || params[0].data[0];
          let tooltip = `<b>${moment.unix(ts).format(short_datetime)}</b><br />`;

          if (params[0].seriesId === "place" || params[0].seriesId === "events") {
            for (let i = 0; i < params.length; i++) {
              if (params[i].seriesId === "place" || params[i].seriesId === "events") {
                const tooltipFunction = tooltips[params[i].seriesId];
                if (tooltipFunction) {
                  tooltip = tooltipFunction(params[i], tooltip, meta);
                }
              }
            }
          } else {
            for (let i = 0; i < params.length; i++) {
              if (params[i].seriesId !== "events") {
                const tooltipFunction = tooltips[params[i].seriesId];
                if (tooltipFunction) {
                  tooltip = tooltipFunction(params[i], tooltip, meta);
                }
              }
            }
          }

          tooltip = `<span style="word-break: keep-all;white-space: normal;">` + tooltip + "</span>";
          return tooltip;
        }
      };

      options.current = {
        grid: grid,
        dataset: {
          dimensions: ["ts", ...dimensions],
          source: logs,
        },
        visualMap: {
          show: false,
          dimension: 0,
          pieces: pieces,
          seriesIndex: placeIndex,
        },
        tooltip: {
          confine: true,
          trigger: "axis",
          transitionDuration: 0,
          axisPointer: {
            type: "cross",
            snap: true,
          },
          formatter: tooltipFormatter,
        },
        xAxis: xAxis,
        yAxis: yAxis,
        axisPointer: {
          link: { xAxisIndex: "all" },
          snap: true,
        },
        series: series,
        dataZoom,
        animation: false,
      };

      // Updates the options of the chart each render
      if (options && options.current) {
        echartsInstance.clear();
        echartsInstance.setOption(options.current);
      }
    }
  }, [echartsInstance, logs, short_datetime, echarts_opacity]);

  useEffect(() => {
    if (echartsInstance && map) {
      const zr = echartsInstance.getZr();

      if (zr.handler) {
        const handleZrClick = (params: any) => {
          // gets the x-axis (time) value where clicked
          const [time] = echartsInstance.convertFromPixel("grid", [params.offsetX, params.offsetY]);

          setCursor(time);
        };

        // watches for click events on chart
        zr.off("click");
        zr.on("click", handleZrClick);
      }
    }
  }, [echartsInstance, map]);

  // listens for window resize events and triggers a chart resize so it fits
  // properly within it's container, fixes issue on iOS tablet when screen rotates
  const handleResizeEvent = useCallback(() => {
    echartsInstance.resize();
  }, [echartsInstance]);

  useEffect(() => {
    window.removeEventListener("resize", handleResizeEvent);

    if (echartsInstance) {
      window.addEventListener("resize", handleResizeEvent);
    }

    return () => {
      window.removeEventListener("resize", handleResizeEvent);
    };
  }, [echartsInstance]);

  return (
    <>
      {meta.logLimit && <WarningAlert style={{ margin: "0" }}>Some logs have been excluded from results. Please select a shorter date range.</WarningAlert>}
      <div style={{ position: "relative" }}>
        {options.current.dataset.dimensions.includes("sample") && logs.length > 0 && (
          <SampleTooltipContainer>
            <InfoTooltip
              content={
                <div style={{ textAlign: "left" }}>
                  <b>Sample Types</b>
                  <br />
                  <ColoredDot margin="0 4px 0 0" color={sample_type_colors.sample} /> Tracker Sample
                  <br />
                  <ColoredDot margin="0 4px 0 0" color={sample_type_colors.historic} /> Historic Tracker Sample
                  <br />
                  <ColoredDot margin="0 4px 0 0" color={sample_type_colors.userPrivate} /> Authenticated User Action
                  <br />
                  <ColoredDot margin="0 4px 0 0" color={sample_type_colors.userPublic} /> Public Scan
                </div>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
            >
              <InfoIconContainer>
                <InfoIcon fill={color.font[2]} />
              </InfoIconContainer>
            </InfoTooltip>
          </SampleTooltipContainer>
        )}
        {loading ? (
          <div></div>
        ) : logs.length > 0 ? (
          <div style={{ height: "100%", width: "100%" }} ref={echartsContainerRef}>
            {options && (
              <ReactEcharts
                key="sensor-logs-chart"
                onChartReady={(chart: any) => setEchartsInstance(chart)}
                style={{ height: height, width: "100%" }}
                option={options.current}
                notMerge={true}
                lazyUpdate={true}
                theme={echarts_theme}
                opts={{ renderer: "svg" }}
              />
            )}
          </div>
        ) : (
          <NoDataComponent style={{ top: "65%" }}>No logs found</NoDataComponent>
        )}
      </div>
    </>
  );
};

export default SensorLogs;
