import { createGlobalStyle } from "../Themes";

export default createGlobalStyle`
  .custom-react-select-option {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    alignItems: "center"
    background-color: ${({ theme }) => theme.color.main_bg[2]};
    box-sizing: border-box;
    color: ${({ theme }) => theme.color.font[2]};
    cursor: default;
    display: flex;
    font-size: ${({ theme }) => theme.fontSize.m};
    label: option;
    padding: 6px 12px;
    textAlign: "unset"
    user-select: none;
    width: 100%;
    border: none;
  }

  .custom-react-select-option:focus {
    border: 1px solid ${({ theme }) => theme.color.primary[2]};
  }

  .custom-react-select-option:active {
    background-color: ${({ theme }) => theme.color_15.primary[0]};
  }

  .custom-react-select-option:hover {
    background-color: ${({ theme }) => theme.color_15.primary[0]};
    cursor: pointer;
  }
`;
