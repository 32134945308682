import React, { FC, useState, useEffect, useRef, useCallback } from "react";
import { PageContainer } from "../../PageStyles";
import LoadingContainer from "../../LoadingContainer";
import { OutlineBtn, PrimaryBtn } from "../../Buttons";
import { FormContainer, FormError, FormInput, FormInputContainer } from "../../FormComponents";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../../util/errToStr";
import PageBreadcrumbs from "../../PageBreadcrumbs";
import { postAssetId } from "../../../services/assetId";
import { kegOrTracker } from "../../../util/kegOrTracker";
import { Link } from "react-router-dom";
import Quagga, { QuaggaJSCodeReader, QuaggaJSResultObject } from "@ericblade/quagga2";
// @ts-ignore
import QrCodeReader from "@ericblade/quagga2-reader-qr";
import { DangerAlert, SuccessAlert } from "../../Alerts";
import { AlertSpan } from "./styles";

Quagga.registerReader("qr_code", QrCodeReader);

function getMedian(arr: any[]) {
  arr.sort((a: number, b: number) => a - b);
  const half = Math.floor(arr.length / 2);
  if (arr.length % 2 === 1) {
    return arr[half];
  }
  return (arr[half - 1] + arr[half]) / 2;
}

function getMedianOfCodeErrors(decodedCodes: any[]) {
  const errors = decodedCodes.filter((x) => x.error !== undefined).map((x) => x.error);
  const medianOfErrors = getMedian(errors);
  return medianOfErrors;
}

const defaultConstraints = {
  width: 640,
  height: 480,
};

const defaultLocatorSettings = {
  patchSize: "medium",
  halfSample: true,
  willReadFrequently: true,
};

const defaultDecoders: (QuaggaJSCodeReader | "qr_code")[] = [
  "qr_code",
  "code_128_reader",
  "ean_reader",
  "ean_8_reader",
  "code_39_reader",
  "code_39_vin_reader",
  "codabar_reader",
  "upc_reader",
  "upc_e_reader",
  "i2of5_reader",
  "2of5_reader",
  "code_93_reader",
];

const AssignAssetId: FC<any> = () => {
  const scannerRef = useRef<any>(undefined);

  const [formData, setFormData] = useState<any>({
    trackerId: "",
    assetId: "",
  });
  const [formErrors, setFormErrors] = useState<any>({});

  const [responseMsg, setResponseMsg] = useState<any>("");
  const [responseErr, setResponseErr] = useState<string>("");
  const [responseLoading, setResponseLoading] = useState<boolean>(false);

  // Step in scanning process (0: Scan Tracker 1: Scan Asset)
  const [step, setStep] = useState<number>(0);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const onDetected = useCallback(
    (result: any) => {
      if (result) {
        if (step === 0) {
          let code = result;
          if (code.includes("10b.in/")) {
            code = code.split("10b.in/")[1];
          }
          setFormData((prev: any) => ({ ...prev, trackerId: code }));
          setFormErrors((prev: any) => ({
            ...prev,
            trackerId: undefined,
          }));
          setStep(1);
        } else {
          setFormData((prev: any) => ({ ...prev, assetId: result }));
          setFormErrors((prev: any) => ({
            ...prev,
            assetId: undefined,
          }));
        }
      }
    },
    [step]
  );

  const errorCheck = useCallback(
    (result: { codeResult: { decodedCodes: any[]; code: any } }) => {
      if (!onDetected) {
        return;
      }
      // @ts-ignore
      if (result.codeResult.format !== "qr_code") {
        const err = getMedianOfCodeErrors(result.codeResult.decodedCodes);
        // if Quagga is at least 92% certain that it read correctly, then accept the code.
        if (err < 0.08) {
          onDetected(result.codeResult.code);
        }
      } else {
        onDetected(result.codeResult.code);
      }
    },
    [onDetected]
  );

  const handleProcessed = (result: QuaggaJSResultObject) => {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;
    drawingCtx.font = "24px Arial";
    drawingCtx.fillStyle = "green";

    if (result) {
      if (result.boxes) {
        // @ts-ignore
        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
        result.boxes
          .filter((box: any) => box !== result.box)
          .forEach((box: any[]) => {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "purple", lineWidth: 2 });
          });
      }
      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "blue", lineWidth: 2 });
      }
    }
  };

  useEffect(() => {
    Quagga.init(
      {
        inputStream: {
          type: "LiveStream",
          constraints: defaultConstraints,
          target: scannerRef.current,
          willReadFrequently: true,
        },
        locator: defaultLocatorSettings,
        numOfWorkers: navigator.hardwareConcurrency || 0,
        // @ts-ignore
        decoder: { readers: defaultDecoders },
        locate: true,
        debug: false,
      },
      (err) => {
        Quagga.onProcessed(handleProcessed);

        if (err) {
          return console.log("Error starting Quagga:", err);
        }
        if (scannerRef && scannerRef.current) {
          Quagga.start();
        }
      }
    );
    Quagga.onDetected(errorCheck);

    return () => {
      Quagga.offDetected(errorCheck);
      Quagga.offProcessed(handleProcessed);
      Quagga.stop();
    };
  }, [onDetected, scannerRef, errorCheck]);

  const handleSubmit = () => {
    const body: any = {
      trackerId: formData.trackerId,
      assetId: formData.assetId,
    };

    setResponseLoading(true);
    postAssetId(source, body)
      .then((response) => {
        setResponseMsg(
          <span>
            Asset ID {response.assetId} assigned to Tracker ID{" "}
            <Link to={`/${kegOrTracker("kegs", "trackers")}/${response.trackerId}`}>{response.trackerId}</Link>
          </span>
        );
        setFormData({ trackerId: "", assetId: "" });
        setStep(0);
        setResponseErr("");
        setResponseLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setResponseErr(errToStr(err));
          setResponseLoading(false);
        }
      });
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setFormErrors((prev: any) => ({
      ...prev,
      [e.target.name]: undefined,
    }));
  };

  const handleNext = () => {
    if (formData.trackerId !== "") {
      setResponseMsg("");
      setResponseErr("");
      setStep(1);
    } else {
      setFormErrors((prev: any) => ({
        ...prev,
        trackerId: "Tracker ID is required",
      }));
    }
  };

  return (
    <>
      <PageBreadcrumbs prevRoutes={[{ slug: "/scanner", title: "Scanner" }]} currRoute="Assign Asset ID" />
      <PageContainer top="40px">
        <LoadingContainer loading={responseLoading}>
          <FormContainer>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              {responseErr && (
                <FormInputContainer style={{ marginBottom: "14px" }}>
                  <DangerAlert style={{ margin: 0 }}>
                    <AlertSpan>{responseErr}</AlertSpan>
                  </DangerAlert>
                </FormInputContainer>
              )}
              {responseMsg && responseErr === "" && (
                <FormInputContainer style={{ marginBottom: "14px" }}>
                  <SuccessAlert style={{ margin: 0 }}>
                    <AlertSpan>{responseMsg}</AlertSpan>
                  </SuccessAlert>
                </FormInputContainer>
              )}
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <label>Scan {step === 0 ? "Tracker" : "Asset"}</label>
                <div ref={scannerRef} style={{ position: "relative" }}>
                  <canvas
                    className="drawingBuffer"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: "0",
                      border: "1px solid gray",
                    }}
                  />
                </div>
              </FormInputContainer>
              <FormInputContainer style={{ marginBottom: "14px" }}>
                <label>Tracker ID</label>
                <FormInput
                  type="text"
                  name="trackerId"
                  value={formData.trackerId}
                  error={formErrors.trackerId}
                  onChange={handleChange}
                  style={{ height: "40px" }}
                />
                <FormError error={formErrors.trackerId}>{formErrors.trackerId}</FormError>
              </FormInputContainer>
              {step === 1 && (
                <FormInputContainer style={{ marginBottom: "14px" }}>
                  <label>Asset ID</label>
                  <FormInput
                    type="text"
                    name="assetId"
                    value={formData.assetId}
                    error={formErrors.assetId}
                    onChange={handleChange}
                    style={{ height: "40px" }}
                  />
                  <FormError error={formErrors.assetId}>{formErrors.assetId}</FormError>
                </FormInputContainer>
              )}
              {step === 0 ? (
                <FormInputContainer style={{ marginBottom: "14px" }}>
                  <div style={{ display: "flex" }}>
                    <PrimaryBtn width="100%" onClick={handleNext}>
                      Next
                    </PrimaryBtn>
                  </div>
                </FormInputContainer>
              ) : (
                <>
                  <FormInputContainer style={{ marginBottom: "14px" }}>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "100%", margin: "0 0 14px 0" }}>
                        <OutlineBtn width="100%" onClick={() => setStep(0)}>
                          Back
                        </OutlineBtn>
                      </div>
                      <div style={{ width: "100%", margin: "0 0 14px 12px" }}>
                        <PrimaryBtn width="100%" onClick={handleSubmit}>
                          Assign
                        </PrimaryBtn>
                      </div>
                    </div>
                  </FormInputContainer>
                </>
              )}
            </form>
          </FormContainer>
        </LoadingContainer>
      </PageContainer>
    </>
  );
};

export default AssignAssetId;
