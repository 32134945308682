import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";
import { GroupId } from "./types";

export const fetchPlaceGroupList = async (source: CancelTokenSource, groupId?: GroupId) => {
  const params = {
    groupId,
    organisationId: getAccount().organisationId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/placegrouplist",
    params,
  });

  return data;
};
