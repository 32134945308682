import styled from "../Themes";
import { humaniseHours } from "../../util/humaniseDurations";
import { printTemp } from "../../util/formatUnits";

export const DownloadButton = styled(`div`)`
  height: 35px;
  width: 35px;
  padding: 5px;
  cursor: pointer;
  float: right;
`;

export const TemperatureSpan = styled<any>(`span`)`
  &::after {
    content: "${({ temp }) => (temp !== undefined ? `${printTemp(temp, 1)}` : "-")}";
    color: ${({ theme, temp, cold, warm, hot }) =>
      temp > (hot !== undefined ? hot : 40)
        ? theme.color.hot[2]
        : temp > (warm !== undefined ? warm : 30)
        ? theme.color.warm[2]
        : temp < (cold !== undefined ? cold : -2)
        ? theme.color.cold[2]
        : theme.color.font[2]};
    font-weight: ${({ temp, cold, warm }) => (temp > (warm !== undefined ? warm : 30) ? 500 : temp < (cold !== undefined ? cold : -2) ? 500 : 200)};
  }
`;

export const PlainTemperatureSpan = styled<any>(`span`)`
  &::after {
    content: "${({ temp }) => (temp !== undefined ? `${printTemp(temp, 2)}` : "-")}";
    color: ${({ theme }) => theme.color.font[2]};
  }
`;

export const DurationSpan = styled<any>(`span`)`
  &::after {
    content: "${({ hours }) => humaniseHours(hours)}";
    color: ${({ theme, hours, long, longer }) =>
      hours > (longer !== undefined ? longer : 720)
        ? theme.color.worse[2]
        : hours > (long !== undefined ? long : 504)
        ? theme.color.bad[2]
        : theme.color.font[2]};
    font-weight: ${({ hours, long }) => (hours > (long !== undefined ? long : 504) ? 500 : 200)};
  }
`;

export const VoltageSpan = styled<any>(`span`)`
  &::after {
    content: "${({ voltage }) => (voltage !== undefined ? `${voltage} V` : "-")}";
    color: ${({ theme, voltage, low, lower }) =>
      voltage < (lower !== undefined ? lower : 4)
        ? theme.color.worse[2]
        : voltage < (low !== undefined ? low : 4.5)
        ? theme.color.bad[2]
        : theme.color.font[2]};
    font-weight: ${({ voltage, low }) => (voltage < (low !== undefined ? low : 4.5) ? 500 : 200)};
  }
`;

export const ReportPanelText = styled(`p`)`
  padding: 0 0 0 12px;

  @media (max-width: 768px) {
    padding: 6px;
  }
`;

export const ReportCard = styled(`div`)`
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.main_bg[2]};
  box-shadow: ${({ theme }) => theme.boxShadow.s};

  /* https://stackoverflow.com/a/10170170 */
  transform: translate3d(0, 0, 0);

  min-height: 100px;
  width: 420px;
  border-radius: 3px;
  margin: 12px;

  &:hover {
    background-color: ${({ theme }) => theme.color.tooltip_hover[2]};
  }

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    margin: 6px;
    width: 100%;
  }
`;

export const CardContainer = styled(`div`)`
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 12px;
  cursor: pointer;
`;

export const CardIcon = styled(`div`)`
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardText = styled(`div`)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardHeading = styled(`p`)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.font[2]};
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 6px;
`;

export const CardBody = styled(`p`)`
  font-weight: 400;
  color: ${({ theme }) => theme.color.font[2]};
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StatBlock = styled(`div`)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 12px;
`;

export const StatLabel = styled(`span`)`
  margin-bottom: 6px;
`;

export const StatValue = styled(`span`)`
  font-size: 28px;
  color: ${({ theme }) => theme.color.primary[2]};
  font-weight: 500;
`;

export const FreshnessContainer = styled<any>(`span`)`
  color: ${({ theme, freshness }) =>
    freshness === 0 ? theme.color.danger[2] : freshness > 0 && freshness <= 75 ? theme.color.warning[2] : theme.color.success[2]};
  font-weight: 500;
`;

export const BatteryContainer = styled<any>(`span`)`
  color: ${({ theme, percent }) =>
    percent <= 10
      ? theme.color.worse[2]
      : percent > 10 && percent <= 25
      ? theme.color.bad[2]
      : percent > 25 && percent <= 35
      ? theme.color.good[2]
      : theme.color.great[2]};
  font-weight: 500;
`;

export const Dot = styled(`span`)`
  height: 12px;
  width: 12px;
  background-color: ${({ theme }) => theme.color.danger[2]};
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 2px;
`;
