import axios from "axios";

export const isOnline = async () => {
  if (!window.navigator.onLine) return false;

  const url = window.location.origin;
  const params = { id: "checkifonline", date: Date.now() };

  try {
    await axios({
      method: "head",
      url,
      params,
    });

    return true;
  } catch {
    return false;
  }
};
