// Gets params for re-routing path
const getPathParams = (location: any) => {
  let params = "";
  if (location.search) {
    params = `${location.search}&path=${location.pathname}`;
  } else if (location.pathname) {
    params = `?path=${location.pathname}`;
  }
  return params;
};

export default getPathParams;
