import styled from "../Themes";

export const TitleContainer = styled<any>(`div`)`
  padding: 10px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
  background: ${({ theme }) => theme.color.panel_bg[2]};
`;

export const Title = styled<any>(`h1`)`
  color: ${({ theme }) => theme.color.font_bold[2]};
  font-size: 18px;
`;

export const Subtitle = styled<any>(`p`)`
  font-size: 13px;
`;
