import { getAccount } from "../services/localStorage";

export const isFloat = (val: any) => {
  val = parseFloat(val);
  return !isNaN(val);
};

export const printTemp = (temp: any, decimalPlaces = 2) => {
  if (!isFloat(temp)) return temp;

  const accountInfo = getAccount();

  if (accountInfo.imperial) return `${((+temp * 9) / 5 + 32).toFixed(decimalPlaces)} °F`;
  else {
    const tempNum = +temp;
    return `${tempNum.toFixed(decimalPlaces)} °C`;
  }
};

export const printFixedTemp = (temp: any, decimalPlaces = 2) => {
  if (!isFloat(temp)) return temp;

  const accountInfo = getAccount();

  if (accountInfo.imperial) return ((+temp * 9) / 5 + 32).toFixed(decimalPlaces);
  else {
    const tempNum = +temp;
    return tempNum.toFixed(decimalPlaces);
  }
};

export const printCountryTemp = (temp: any, decimalPlaces = 2, country: string) => {
  if (!isFloat(temp)) return temp;

  if (country === "US") return `${((+temp * 9) / 5 + 32).toFixed(decimalPlaces)} °F`;
  else return `${+temp.toFixed(decimalPlaces)} °C`;
};

export const printRoundedTemp = (temp: any) => {
  if (!isFloat(temp)) return temp;

  const accountInfo = getAccount();

  if (accountInfo.imperial) return `${Math.round((+temp * 9) / 5 + 32)} °F`;
  else return `${Math.round(+temp)} °C`;
};

export const printTempUnit = () => {
  const accountInfo = getAccount();

  if (accountInfo.imperial) return `°F`;
  else return `°C`;
};

export const convertRoundedTemp = (temp: any) => {
  if (!isFloat(temp)) return temp;

  const accountInfo = getAccount();

  if (accountInfo.imperial) return Math.round((+temp * 9) / 5 + 32);
  else return +temp;
};

export const convertTemp = (temp: any) => {
  if (!isFloat(temp)) return temp;

  const accountInfo = getAccount();

  if (accountInfo.imperial) return (+temp * 9) / 5 + 32;
  else return +temp;
};

export const toCelsius = (temp: any) => {
  if (!isFloat(temp)) return temp;

  const accountInfo = getAccount();

  if (accountInfo.imperial) return Math.round(((+temp - 32) * 5) / 9);
  else return +temp;
};

export const printLength = (meters: any, decimalPlaces = 2) => {
  if (!isFloat(meters)) return meters;

  const accountInfo = getAccount();

  if (accountInfo.imperial) {
    const feet = +meters * 3.281;
    if (feet < 5280) return `${feet.toFixed(decimalPlaces)} ft`;
    else return `${(feet / 5280).toFixed(decimalPlaces)} mi`;
  } else {
    if (meters < 1000) return `${meters} m`;
    else return `${(+meters / 1000).toFixed(decimalPlaces)} km`;
  }
};

export const printFixedLength = (meters: any, decimalPlaces = 2) => {
  if (!isFloat(meters)) return meters;

  const accountInfo = getAccount();

  if (accountInfo.imperial) return (+meters * 3.281).toFixed(decimalPlaces);
  else return meters;
};

export const printLengthUnit = () => {
  const accountInfo = getAccount();

  if (accountInfo.imperial) return `ft`;
  else return `m`;
};

export const convertRoundedLength = (meters: any) => {
  if (!isFloat(meters)) return meters;

  const accountInfo = getAccount();

  if (accountInfo.imperial) return Math.round(+meters * 3.281);
  else return +meters;
};

export const toMeters = (feet: any) => {
  if (!isFloat(feet)) return feet;

  const accountInfo = getAccount();

  if (accountInfo.imperial) return Math.round(+feet / 3.281);
  else return +feet;
};

export const printVelocity = (velocity: any, decimalPlaces = 2) => {
  if (!isFloat(velocity)) return velocity;

  const accountInfo = getAccount();

  if (accountInfo.imperial) return `${(+velocity * 3.281).toFixed(decimalPlaces)} ft/s`;
  else return `${+velocity.toFixed(decimalPlaces)} m/s`;
};

export const convertRoundedVelocity = (velocity: any) => {
  if (!isFloat(velocity)) return velocity;

  const accountInfo = getAccount();

  if (accountInfo.imperial) return Math.round(+velocity * 3.281);
  else return +velocity;
};

export const printVelocityUnit = () => {
  const accountInfo = getAccount();

  if (accountInfo.imperial) return `ft/s`;
  else return `m/s`;
};

export const toMetersPerSecond = (velocity: any) => {
  if (!isFloat(velocity)) return velocity;

  const accountInfo = getAccount();

  if (accountInfo.imperial) return Math.round(+velocity / 3.281);
  else return +velocity;
};

export const printPressure = (pressure: any, decimalPlaces = 2) => {
  if (!isFloat(pressure)) return pressure;

  const accountInfo = getAccount();

  if (accountInfo.imperial) return `${(+pressure / 6.895).toFixed(decimalPlaces)} psi`;
  else return `${+pressure.toFixed(decimalPlaces)} kPa`;
};

export const convertRoundedPressure = (pressure: any) => {
  if (!isFloat(pressure)) return pressure;

  const accountInfo = getAccount();

  if (accountInfo.imperial) return Math.round(+pressure / 6.895);
  else return +pressure;
};

export const printPressureUnit = () => {
  const accountInfo = getAccount();

  if (accountInfo.imperial) return `psi`;
  else return `kPa`;
};

export const toKilopascals = (pressure: any) => {
  if (!isFloat(pressure)) return pressure;

  const accountInfo = getAccount();

  if (accountInfo.imperial) return Math.round(+pressure * 6.895);
  else return +pressure;
};
