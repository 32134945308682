const roundBatteryPercent = (percent: number) => {
  if (percent < 0) {
    return 0;
  } else if (percent > 100) {
    return 100;
  } else if (percent == null) {
    return undefined;
  }
  return Math.round(percent / 5) * 5;
};

export default roundBatteryPercent;
