import styled from "../Themes";

export const MapContainer = styled<any>(`div`)`
  float: left;
  width: 100%;
  clear: both;
  height: 500px;
  border: 1px solid ${({ theme, error }) => (error ? theme.color.error[2] : theme.color.font_bold[2])};
  border-radius: 3px;

  @media (max-width: 768px) {
    height: 400px;
  }
`;
