import React, { FC } from "react";

const QrScanIcon: FC<any> = ({ outline }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
    <path d="M19.655 19.849l.767 30.393h29.34V19.85H19.654zm10.068 10.067l10.45.767v9.588h-10.45V29.916z" fillOpacity={0.3} />
    <path
      d="M1.534 1.632v29.147h8.725V10.26h20.998V1.536zM19.943 77.136l.767 30.394h29.339V77.136zM30.01 87.204l10.45.767v9.588H30.01zM1.48 126.999h29.148v-8.725H10.11V97.276H1.385zM126.272 126.61V97.463h-8.724v20.518H96.55v8.725z"
      fillOpacity={0.3}
    />
    <path d="M60.212 38.545v69.608h48.323V75.746H77.95v14.478h8.053v-5.945h14.67v15.82H68.362V38.833z" fillOpacity={0.3} />
    <path d="M77.374 19.417l.767 30.394h29.34V19.417zm10.068 10.068l10.45.767v9.588h-10.45z" fillOpacity={0.3} />
    <path d="M126.8 1.44H97.653v8.725h20.518v20.997h8.725zM78.141 60.214v7.574h30.298v-7.574z" fillOpacity={0.3} />
    <path d="M19.463 59.638v8.438h12.369V59.83zM42.09 59.926v7.67h7.863v-7.574z" fillOpacity={0.3} />
    <path d="M59.637 20.136v8.63h8.916V20.04z" fillOpacity={0.3} />
    <path
      fill={outline}
      d="M1.75 32.74h8a1.75 1.75 0 001.75-1.75V11.5h19.491a1.75 1.75 0 001.75-1.75v-8A1.75 1.75 0 0030.991 0H1.75A1.75 1.75 0 000 1.75v29.24a1.75 1.75 0 001.75 1.75zM3.5 3.5h25.741V8H9.75A1.75 1.75 0 008 9.75v19.49H3.5zM30.991 116.5H11.5V97.01a1.75 1.75 0 00-1.75-1.75h-8A1.75 1.75 0 000 97.01v29.24A1.75 1.75 0 001.75 128h29.241a1.75 1.75 0 001.75-1.75v-8a1.75 1.75 0 00-1.75-1.75zm-1.75 8H3.5V98.76H8v19.49A1.75 1.75 0 009.75 120h19.491zM126.25 0H97.009a1.75 1.75 0 00-1.75 1.75v8a1.75 1.75 0 001.75 1.75H116.5v19.49a1.75 1.75 0 001.75 1.75h8a1.75 1.75 0 001.75-1.75V1.75A1.75 1.75 0 00126.25 0zm-1.75 29.24H120V9.75A1.75 1.75 0 00118.25 8H98.759V3.5H124.5zM126.25 95.26h-8a1.75 1.75 0 00-1.75 1.75v19.49H97.009a1.75 1.75 0 00-1.75 1.75v8a1.75 1.75 0 001.75 1.75h29.241a1.75 1.75 0 001.75-1.75V97.01a1.75 1.75 0 00-1.75-1.75zm-1.75 29.24H98.759V120h19.491a1.75 1.75 0 001.75-1.75V98.76h4.5z"
    />
    <path
      fill={outline}
      d="M77.667 52.083h30.583a1.75 1.75 0 001.75-1.75V19.75a1.75 1.75 0 00-1.75-1.75H77.667a1.749 1.749 0 00-1.75 1.75v30.583a1.749 1.749 0 001.75 1.75zm1.75-30.583H106.5v27.083H79.417z"
    />
    <path
      fill={outline}
      d="M98.25 28H87.667a1.749 1.749 0 00-1.75 1.75v10.583a1.749 1.749 0 001.75 1.75H98.25a1.75 1.75 0 001.75-1.75V29.75A1.75 1.75 0 0098.25 28zM96.5 38.583h-7.083V31.5H96.5zM29.75 42.083h10.583a1.749 1.749 0 001.75-1.75V29.75a1.749 1.749 0 00-1.75-1.75H29.75A1.75 1.75 0 0028 29.75v10.583a1.75 1.75 0 001.75 1.75zM31.5 31.5h7.083v7.083H31.5zM29.75 99.456h10.583a1.749 1.749 0 001.75-1.75V87.123a1.749 1.749 0 00-1.75-1.75H29.75a1.75 1.75 0 00-1.75 1.75v10.583a1.75 1.75 0 001.75 1.75zm1.75-10.583h7.083v7.083H31.5z"
    />
    <path
      fill={outline}
      d="M50.333 18H19.75A1.75 1.75 0 0018 19.75v30.583a1.75 1.75 0 001.75 1.75h30.583a1.749 1.749 0 001.75-1.75V19.75a1.749 1.749 0 00-1.75-1.75zm-1.75 30.583H21.5V21.5h27.083zM50.333 75.373H19.75a1.75 1.75 0 00-1.75 1.75v30.583a1.75 1.75 0 001.75 1.75h30.583a1.749 1.749 0 001.75-1.75V77.123a1.749 1.749 0 00-1.75-1.75zm-1.75 30.583H21.5V78.873h27.083zM60 30.75h8A1.75 1.75 0 0069.75 29v-9.25A1.75 1.75 0 0068 18h-8a1.75 1.75 0 00-1.75 1.75V29A1.75 1.75 0 0060 30.75zm1.75-9.25h4.5v5.75h-4.5zM108.25 58.25H78.008a1.75 1.75 0 00-1.75 1.75v8a1.75 1.75 0 001.75 1.75h30.242A1.75 1.75 0 00110 68v-8a1.75 1.75 0 00-1.75-1.75zm-1.75 8H79.758v-4.5H106.5zM19.921 69.75h12.234a1.75 1.75 0 001.75-1.75v-8a1.75 1.75 0 00-1.75-1.75H19.921a1.75 1.75 0 00-1.75 1.75v8a1.75 1.75 0 001.75 1.75zm1.75-8h8.734v4.5h-8.734z"
    />
    <path
      fill={outline}
      d="M110 108.032V76a1.75 1.75 0 00-1.75-1.75H78.008a1.75 1.75 0 00-1.75 1.75v14.583a1.75 1.75 0 001.75 1.75h8a1.75 1.75 0 001.75-1.75V85.75H98.5v12.532H69.75V39.008a1.75 1.75 0 00-1.75-1.75h-8a1.75 1.75 0 00-1.75 1.75v69.024a1.75 1.75 0 001.75 1.75h48.25a1.75 1.75 0 001.75-1.75zm-3.5-1.75H61.75V40.758h4.5v59.274a1.75 1.75 0 001.75 1.75h32.25a1.75 1.75 0 001.75-1.75V84a1.75 1.75 0 00-1.75-1.75H86.008a1.75 1.75 0 00-1.75 1.75v4.833h-4.5V77.75H106.5zM50.163 69.75a1.75 1.75 0 001.75-1.75v-8a1.75 1.75 0 00-1.75-1.75h-8a1.749 1.749 0 00-1.75 1.75v8a1.749 1.749 0 001.75 1.75zm-6.25-8h4.5v4.5h-4.5z"
    />
  </svg>
);

export default QrScanIcon;
