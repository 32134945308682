// Calculates the eCharts grids for the sensor log charts
// Depending on the number of charts, this function generates the grids and container
// height in order to make everything fit nicely.
const calculateGrid = (dimensions: any, setHeight: any) => {
  const grid = [];
  const totalCharts = dimensions.length;
  let smallCharts = 0;
  let bigCharts = 0;
  const chartPadding = 30;
  const xAxisLabelPadding = 40;
  const smallChartHeight = 20;
  let bigChartHeight = 100;
  let totalHeight = 0;

  if (dimensions.includes("sample")) {
    grid.push({
      right: 40,
      left: 80,
      top: chartPadding,
      height: smallChartHeight,
    });
    smallCharts++;
  }

  if (dimensions.includes("move")) {
    grid.push({
      right: 40,
      left: 80,
      top: chartPadding + smallCharts * (chartPadding + smallChartHeight),
      height: smallChartHeight,
    });
    smallCharts++;
  }

  if (dimensions.includes("place")) {
    grid.push({
      right: 40,
      left: 80,
      top: chartPadding + smallCharts * (chartPadding + smallChartHeight),
      height: smallChartHeight,
    });
    smallCharts++;
  }

  bigCharts = totalCharts - smallCharts;

  if (bigCharts === 2) bigChartHeight = 150;
  else if (bigCharts === 3) bigChartHeight = 125;
  else if (bigCharts === 4) bigChartHeight = 100;

  totalHeight = chartPadding + xAxisLabelPadding + bigCharts * (bigChartHeight + chartPadding) + smallCharts * (smallChartHeight + chartPadding) - chartPadding;
  setHeight(totalHeight);

  for (let i = 0; i < bigCharts; i++) {
    grid.push({
      right: 40,
      left: 80,
      top: chartPadding + smallCharts * (chartPadding + smallChartHeight) + i * (bigChartHeight + chartPadding),
      height: bigChartHeight,
    });
  }
  return grid;
};

export default calculateGrid;

//////// TEST FOR MOVING SAMPLE TO BOTTOM OF CHART AND DOUBLING PLACE SIZE ////////
// // Calculates the eCharts grids for the sensor log charts
// // Depending on the number of charts, this function generates the grids and container
// // height in order to make everything fit nicely.
// const calculateGrid = (dimensions: any, setHeight: any) => {
//   const grid = [];
//   const totalCharts = dimensions.length;
//   let smallCharts = 0;
//   let mediumCharts = 0;
//   let bigCharts = 0;
//   const chartPadding = 30;
//   const xAxisLabelPadding = 40;
//   const smallChartHeight = 20;
//   const mediumChartHeight = 40;
//   let bigChartHeight = 100;
//   let totalHeight = 0;

//   if (dimensions.includes("place")) {
//     grid.push({
//       right: 40,
//       left: 80,
//       top: chartPadding + smallCharts * (chartPadding + smallChartHeight) + mediumCharts * (chartPadding + mediumChartHeight),
//       height: mediumChartHeight,
//     });
//     mediumCharts++;
//   }

//   if (dimensions.includes("move")) {
//     grid.push({
//       right: 40,
//       left: 80,
//       top: chartPadding + smallCharts * (chartPadding + smallChartHeight) + mediumCharts * (chartPadding + mediumChartHeight),
//       height: smallChartHeight,
//     });
//     smallCharts++;
//   }

//   bigCharts = totalCharts - smallCharts - mediumCharts;

//   if (dimensions.includes("sample")) {
//     bigCharts--;
//   }

//   if (bigCharts === 2) bigChartHeight = 150;
//   else if (bigCharts === 3) bigChartHeight = 125;
//   else if (bigCharts === 4) bigChartHeight = 100;

//   for (let i = 0; i < bigCharts; i++) {
//     grid.push({
//       right: 40,
//       left: 80,
//       top:
//         chartPadding +
//         smallCharts * (chartPadding + smallChartHeight) +
//         mediumCharts * (chartPadding + mediumChartHeight) +
//         i * (bigChartHeight + chartPadding),
//       height: bigChartHeight,
//     });
//   }

//   if (dimensions.includes("sample")) {
//     grid.push({
//       right: 40,
//       left: 80,
//       top:
//         chartPadding +
//         smallCharts * (smallChartHeight + chartPadding) +
//         mediumCharts * (mediumChartHeight + chartPadding) +
//         bigCharts * (bigChartHeight + chartPadding),
//       height: smallChartHeight,
//     });
//     smallCharts++;
//   }

//   totalHeight =
//     chartPadding +
//     xAxisLabelPadding +
//     smallCharts * (smallChartHeight + chartPadding) +
//     mediumCharts * (mediumChartHeight + chartPadding) +
//     bigCharts * (bigChartHeight + chartPadding) -
//     chartPadding;
//   setHeight(totalHeight);

//   return grid;
// };

// export default calculateGrid;
