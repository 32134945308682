import axios, { CancelTokenSource } from "axios";
import { FC, useContext, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Link, useLocation } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { postRegister } from "../../services/register";
import ErrIcon from "../../svgs/ErrIcon";
import InfoIcon from "../../svgs/Legend";
import SuccessIcon from "../../svgs/SuccessIcon";
import errToStr from "../../util/errToStr";
import { exists, maxLength, passwordsMatch, validEmail, validPassword } from "../../util/formValidations";
import { getLogo } from "../../util/getLogo";
import getParameterByName from "../../util/getParamByName";
import { DangerAlert, SuccessAlert } from "../Alerts";
import { PrimaryBtn } from "../Buttons";
import { Form, FormError, FormInput, FormInputContainer } from "../FormComponents";
import LoadingContainer from "../LoadingContainer";
import ScreenHeading from "../ScreenHeading";
import StateContext from "../StateContext";
import TermsAndConditionsModal from "../TermsAndConditionsModal";
import InfoTooltip from "../Tooltip";
import { AlertIconContainer, AlertSpan, FormContainer, InfoIconContainer, LogoContainer, Section, TermsLink } from "./styles";

const RegisterScreen: FC<any> = () => {
  const location = useLocation();

  // Gets details from URL query parameters if available to pre-fill form
  const emailQueryParam = getParameterByName("email", location.search) || "";

  const { color } = useContext(ThemeContext);
  const { theme } = useContext(StateContext);

  const { executeRecaptcha } = useGoogleReCaptcha();

  // disable email input if an email address is supplied in the query parameters
  const [emailDisabled] = useState<boolean>(!!emailQueryParam);

  const [formErrors, setFormErrors] = useState<any>({});
  const [formData, setFormData] = useState<any>({
    email: emailQueryParam,
    name: "",
    password: "",
    confirmPassword: "",
    botField: "",
  });

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);

  const [termsModalOpen, setTermsModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];
      const password = formData.password || "";
      const confirmPassword = formData.confirmPassword || "";

      switch (name) {
        case "name":
          currValid = exists(name, value, setFormErrors) && maxLength(name, value, 200, setFormErrors);
          break;

        case "email":
          currValid = exists(name, value, setFormErrors) && validEmail(name, value, setFormErrors);
          break;

        case "password":
          currValid = validPassword(name, value, setFormErrors) && passwordsMatch("confirmPassword", password, confirmPassword, setFormErrors);
          break;

        case "confirmPassword":
          currValid = exists(name, value, setFormErrors) && passwordsMatch(name, password, confirmPassword, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const handleSubmit = async (e: any) => {
    e.persist();
    e.preventDefault();

    if (formData.botField) {
      setError("An unexpected error occurred. Please try again.");
      setSuccess(false);
      return;
    }

    const valid = validateForm();

    if (valid) {
      if (!executeRecaptcha) {
        console.error("Google ReCaptcha Error");
        return;
      }

      const token = await executeRecaptcha("register");
      const body = { ...formData, recaptcha: token };

      setSubmitting(true);
      postRegister(source, body)
        .then(() => {
          setError("");
          setSuccess(true);
          setSubmitting(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setError(errToStr(err));
            setSuccess(false);
            setSubmitting(false);
          }
        });
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  return (
    <Section>
      <LoadingContainer loading={submitting}>
        <FormContainer>
          <Form onSubmit={handleSubmit} noValidate>
            <LogoContainer>
              <Link to={`/sign-in${location.search}`}>{getLogo(color, theme)}</Link>
            </LogoContainer>
            <ScreenHeading style={{ marginBottom: "24px" }}>Create Account</ScreenHeading>
            <div style={{ display: "none" }}>
              <FormInput type="text" name="botField" value={formData.botField} onChange={handleChange} autoComplete="off" tabIndex={-1} />
            </div>
            <FormInputContainer>
              <label>Full Name</label>
              <FormInput type="text" name="name" value={formData.name} error={formErrors.name} onChange={handleChange} autoComplete="name" />
              <FormError error={formErrors.name}>{formErrors.name}</FormError>
            </FormInputContainer>
            <FormInputContainer>
              <label style={{ float: "left" }}>Email</label>
              {emailDisabled && (
                <div style={{ cursor: "pointer" }}>
                  <InfoTooltip
                    content="Your invite was sent to this email address, you can change your email address after you've created an account"
                    interactive={true}
                    touch={true}
                    appendTo={document.body}
                  >
                    <InfoIconContainer>
                      <InfoIcon fill={color.font[2]} />
                    </InfoIconContainer>
                  </InfoTooltip>
                </div>
              )}
              <FormInput
                type="email"
                name="email"
                value={formData.email}
                error={formErrors.email}
                onChange={handleChange}
                disabled={emailDisabled}
                autoComplete="email"
              />
              <FormError error={formErrors.email}>{formErrors.email}</FormError>
            </FormInputContainer>
            <FormInputContainer>
              <label>Password</label>
              <FormInput
                type="password"
                name="password"
                value={formData.password}
                error={formErrors.password}
                onChange={handleChange}
                autoComplete="new-password"
              />
              <FormError error={formErrors.password}>{formErrors.password}</FormError>
            </FormInputContainer>
            <FormInputContainer>
              <label>Confirm Password</label>
              <FormInput
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                error={formErrors.confirmPassword}
                onChange={handleChange}
                autoComplete="new-password"
              />
              <FormError error={formErrors.confirmPassword}>{formErrors.confirmPassword}</FormError>
            </FormInputContainer>
            <span style={{ fontSize: "12px", textAlign: "center", marginTop: "12px" }}>
              By creating an account, you agree that you have read and accepted our{" "}
              <TermsLink onClick={() => setTermsModalOpen(true)}>Terms and Conditions</TermsLink>.
            </span>
            <div style={{ padding: "16px 0px 32px", width: "100%" }}>
              <PrimaryBtn width="100%" type="submit">
                Create Account
              </PrimaryBtn>
            </div>
            {error && (
              <DangerAlert>
                <AlertIconContainer>
                  <ErrIcon fill={color.danger[2]} />
                </AlertIconContainer>
                <AlertSpan>{error}</AlertSpan>
              </DangerAlert>
            )}
            {success && (
              <SuccessAlert>
                <AlertIconContainer>
                  <SuccessIcon fill={color.success[2]} />
                </AlertIconContainer>
                <AlertSpan>To continue please confirm your email address by clicking the link sent to {formData.email}</AlertSpan>
              </SuccessAlert>
            )}
            Already have an account? <Link to={`/sign-in${location.search}`}>Sign in here</Link>
          </Form>
        </FormContainer>
      </LoadingContainer>
      {termsModalOpen && <TermsAndConditionsModal modalOpen={termsModalOpen} setModalOpen={setTermsModalOpen} />}
    </Section>
  );
};

export default RegisterScreen;
