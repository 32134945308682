import matchSorter from "match-sorter";
import moment from "moment";
import { FC, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { ThemeContext } from "styled-components";
import { printTemp } from "../../util/formatUnits";
import { humaniseMinutes } from "../../util/humaniseDurations";
import { kegOrTracker, kegsOrTrackers } from "../../util/kegOrTracker";
import Bold from "../Bold";
import LoadingContainer from "../LoadingContainer";
import { InfoModal } from "../Modal";
import { TableHeaderButtons } from "../NewTable/styles";
import Table, { CsvButtonsComponent } from "../Table";

const SensorListModal: FC<any> = ({ sensors, sensorColumns = [], size = "sm", loading, err, modalOpen, setModalOpen }) => {
  const { long_datetime } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);

  const formatDataToCsv = (tableRef: any) => {
    const sortedData = tableRef.current.getResolvedState().sortedData;
    let headers = [];

    headers = ["Tracker ID"];

    return [
      headers,
      ...sortedData.map((row: any) => {
        return [row.sensorId];
      }, []),
    ];
  };

  const columns: any = [];

  let defaultSorted = [
    {
      id: "sensorId",
      desc: false,
    },
  ];

  columns.push({
    id: "sensorId",
    Header: "Tracker ID",
    accessor: "sensorId",
    filterMethod: (filter: any, rows: any) =>
      matchSorter(rows, filter.value, {
        threshold: matchSorter.rankings.CONTAINS,
        keys: ["sensorId"],
      }),
    filterAll: true,
    Cell: (props: any) => {
      const url = kegOrTracker("kegs", "trackers");
      let title = props.value ? props.value : "";
      title += `${props.original.sensorName ? " (" + props.original.sensorName + ")" : ""}`;

      return (
        <Link to={`/${url}/${props.value}`} title={title}>
          {title}
        </Link>
      );
    },
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  if (sensorColumns.includes("pickupIndicator")) {
    columns.push({
      id: "pickupIndicator",
      Header: "Pickup Indicator",
      accessor: "pickupIndicator",
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["pickupIndicator"],
        }),
      filterAll: true,
    });
  }

  if (sensorColumns.includes("placeName")) {
    columns.push({
      id: "placeName",
      Header: "Place",
      accessor: "placeName",
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["placeName"],
        }),
      filterAll: true,
      Cell: (props: any) =>
        props.value ? (
          <Link title={props.value} to={`/places/${props.original.placeId}`}>
            {props.value}
          </Link>
        ) : (
          "-"
        ),
    });
  }

  if (sensorColumns.includes("lastSampleDate")) {
    columns.push({
      id: "lastSampleDate",
      Header: "Last Seen",
      accessor: "lastSampleDate",
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["lastSampleDate"],
        }),
      filterAll: true,
      Cell: (props: any) => <ReactTimeago live={false} date={props.value * 1000} title={moment.unix(props.value).format(long_datetime)} />,
    });
    defaultSorted = [
      {
        id: "lastSampleDate",
        desc: false,
      },
    ];
  }

  if (sensorColumns.includes("minutesHere")) {
    columns.push({
      id: "minutesHere",
      Header: "Duration Here",
      accessor: "minutesHere",
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["minutesHere"],
        }),
      filterAll: true,
      Cell: (props: any) => <span title={humaniseMinutes(+props.value, ["d", "h", "m"])}>{humaniseMinutes(+props.value, ["d", "h"])}</span>,
    });
    defaultSorted = [
      {
        id: "minutesHere",
        desc: true,
      },
    ];
  }

  if (sensorColumns.includes("temperature")) {
    columns.push({
      id: "temperature",
      Header: "Temperature",
      accessor: "temperature",
      filterMethod: (filter: any, rows: any) =>
        matchSorter(rows, filter.value, {
          threshold: matchSorter.rankings.CONTAINS,
          keys: ["temperature"],
        }),
      filterAll: true,
      Cell: (props: any) => <span title={printTemp(props.value, 1)}>{printTemp(props.value, 1)}</span>,
    });
    defaultSorted = [
      {
        id: "temperature",
        desc: true,
      },
    ];
  }

  return (
    <InfoModal
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      title={kegsOrTrackers("Kegs", "Trackers")}
      size={size}
      okayBtnText="Close"
      okayBtnProps={{ width: "100%" }}
      body={
        <LoadingContainer loading={loading} err={err}>
          <TableHeaderButtons>
            <div style={{ display: "flex" }}></div>
            <CsvButtonsComponent
              data={sensors}
              formatCsv={formatDataToCsv}
              formatCsvParams={[tableRef]}
              fileName={kegsOrTrackers("Keg List.csv", "Tracker List.csv")}
            />
          </TableHeaderButtons>
          <Table
            loading={loading}
            filterable={true}
            style={{ clear: "both" }}
            data={sensors}
            columns={columns}
            defaultSorted={defaultSorted}
            ref={tableRef}
            defaultPageSize={15}
          />
        </LoadingContainer>
      }
    />
  );
};

export default SensorListModal;
