import styled from "../Themes";

export const HorizontalLine = styled(`div`)`
  margin: 32px 0;
  border-top: 1px solid ${({ theme }) => theme.color.border[2]};
`;

export const HorizontalLineWithText = styled(`h1`)`
  overflow: hidden;
  text-align: center;

  &:before {
    background-color: ${({ theme }) => theme.color.border[2]};
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    background-color: ${({ theme }) => theme.color.border[2]};
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
    left: 0.5em;
    margin-right: -50%;
  }
`;
