import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const fetchEditBeer = async (source: CancelTokenSource) => {
  const params = {
    organisationId: getAccount().organisationId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/editbeer",
    params,
  });

  return data;
};

export const postEditBeer = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/api/editbeer",
    data: JSON.stringify({
      ...body,
      organisationId: getAccount().organisationId,
    }),
  });

  return data;
};

export const putEditBeer = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + "/api/editbeer",
    data: JSON.stringify({
      ...body,
      organisationId: getAccount().organisationId,
    }),
  });

  return data;
};
