import styled from "../Themes";

export const BadgeContainer = styled<any>(`div`)`
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 10px;
`;

export const BadgeText = styled<any>(`span`)`
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 3px 9px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: ${({ background }) => background};
  border-radius: 10px;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.color.main_bg[2]};
  cursor: default;
  user-select: none;
`;
