import React, { FC, useContext } from "react";
import { ThemeContext } from "styled-components";
import isDark from "../../util/isDark";
import { TagContainer, TagText } from "./styles";
import Tooltip from "../Tooltip";

const Tag: FC<any> = ({ name, description, colour }) => {
  const theme = useContext(ThemeContext);

  const textColour = isDark(colour ? colour : theme.color.primary[0]) ? "#fff" : "#000";

  return description ? (
    <Tooltip key={name} trigger="mouseenter" content={description}>
      <TagContainer>
        <TagText title={name} color={textColour} background={colour}>
          {name}
        </TagText>
      </TagContainer>
    </Tooltip>
  ) : (
    <TagContainer>
      <TagText title={name} color={textColour} background={colour}>
        {name}
      </TagText>
    </TagContainer>
  );
};

export default Tag;
