import styled from "../Themes";

export const Section = styled("section")`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: radial-gradient(circle at top center, rgba(0, 0, 0, 0) 50%, ${({ theme }) => theme.color_50.sign_in_bg[2]} 75%);
`;

export const FormContainer = styled(`div`)`
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
`;

export const LogoContainer = styled("div")`
  width: 100%;
  max-width: 300px;
  margin-bottom: 48px;
`;

export const AlertIconContainer = styled<any>(`div`)`
  fill: ${({ theme }) => theme.color.font_bold[2]};
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 6px;
`;

export const AlertSpan = styled<any>(`span`)`
  color: ${({ theme }) => theme.color.font_bold[2]};
  line-height: 24px;
`;

export const InfoIconContainer = styled(`div`)`
  width: 17px;
  height: 17px;
  text-align: center;
  float: left;
  margin-left: 4px;
`;

export const TermsLink = styled(`span`)`
  cursor: pointer;
  text-decoration: underline;
`;
