import React, { FC } from "react";
import { TitleContainer, Title, Subtitle } from "./styles";

const PageTitle: FC<any> = ({ title, subtitle }) => {
  return (
    <TitleContainer>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </TitleContainer>
  );
};

export default PageTitle;
