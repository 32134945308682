import axios, { CancelTokenSource } from "axios";
import matchSorter from "match-sorter";
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { ThemeContext } from "styled-components";
import { deleteEditPlaceGroup, fetchEditPlaceGroup } from "../../services/editPlaceGroup";
import MoreIcon from "../../svgs/MoreIcon";
import { isAdminOrUser } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import { isBinaryBeer } from "../../util/checkDomain";
import Bold from "../Bold";
import { PrimaryBtn } from "../Buttons";
import DeleteModal from "../DeleteModal";
import EditPlaceTypeModal from "../EditPlaceTypeModal";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import { PrimaryIconBtn } from "../IconButtons";
import LoadingContainer from "../LoadingContainer";
import { DesktopDiv, MobileDiv, TableHeaderButtons } from "../NewTable/styles";
import PlaceTypePlaceList from "../PlaceTypePlaceList";
import Table, { CsvButtonsComponent } from "../Table";
import Tag from "../Tag";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

const PlaceTypesTable: FC<any> = () => {
  const { color } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);

  const [types, setTypes] = useState<any>([]);
  const [typesErr, setTypesErr] = useState<string>("");
  const [typesLoading, setTypesLoading] = useState<boolean>(false);

  const [selectedType, setSelectedType] = useState<any>(null);
  const [editModalOpen, setEditModalOpen] = useState<any>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [placeListModalOpen, setPlaceListModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  const fetchPlaceTypes = useCallback(() => {
    setTypesLoading(true);
    fetchEditPlaceGroup(source)
      .then((response) => {
        setTypes(response);
        setTypesLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setTypesErr(errToStr(err));
          setTypesLoading(false);
        }
      });
  }, [source]);

  useEffect(() => {
    if (source) {
      fetchPlaceTypes();
    }

    return () => {
      source.cancel();
    };
  }, [source, fetchPlaceTypes]);

  const formatDataToCsv = (tableRef: any) => {
    const sortedData = tableRef.current.getResolvedState().sortedData;
    let headers = [];

    headers = ["Name", "Place Count", ...(isBinaryBeer() ? ["Features"] : []), "Colour"];

    return [
      headers,
      ...sortedData.map((row: any) => {
        const features: string[] = [];
        if (row._original.fillsBeerKegs) features.push("Fills Beer Kegs");
        if (row._original.emptiesBeerKegs) features.push("Empties Beer Kegs");

        return [row.groupName, row.placeCount, ...(isBinaryBeer() ? [features.join(",")] : []), row.groupColour];
      }, []),
    ];
  };

  const columns = React.useMemo<any>(
    () => [
      {
        Header: "Actions",
        minWidth: 110,
        maxWidth: 110,
        filterable: false,
        sortable: false,
        Cell: ({ original }: any) => {
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  {isAdminOrUser() ? (
                    <>
                      <MenuButton
                        onClick={() => {
                          setSelectedType(original);
                          setEditModalOpen(true);
                        }}
                      >
                        Edit Place Type
                      </MenuButton>
                      <DangerMenuButton
                        onClick={() => {
                          setSelectedType(original);
                          setDeleteModalOpen(true);
                        }}
                      >
                        Delete Place Type
                      </DangerMenuButton>
                    </>
                  ) : (
                    <>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Edit Place Type</MenuButton>
                        </div>
                      </Tooltip>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Delete Place Type</MenuButton>
                        </div>
                      </Tooltip>
                    </>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
      },
      {
        id: "groupName",
        Header: "Name",
        accessor: "groupName",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["groupName"],
          }),
        filterAll: true,
      },
      {
        id: "placeCount",
        Header: "Place Count",
        accessor: "placeCount",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["placeCount"],
          }),
        filterAll: true,
        Cell: (props: any) => (
          <div
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => {
              setSelectedType(props.original);
              setPlaceListModalOpen(true);
            }}
          >
            {props.value}
          </div>
        ),
      },
      ...(isBinaryBeer()
        ? [
            {
              id: "features",
              Header: "Features",
              accessor: "features",
              minWidth: 250,
              maxWidth: 250,
              style: { textAlign: "left" },
              filterMethod: (filter: any, rows: any) =>
                matchSorter(rows, filter.value, {
                  threshold: matchSorter.rankings.CONTAINS,
                  keys: ["features"],
                }),
              filterAll: true,
              Cell: (props: any) => (
                <>
                  {props.original.fillsBeerKegs && (
                    <Tag name="Fills Beer Kegs" description="This place type fills beer kegs (e.g. Brewery)" colour={color.primary[3]} />
                  )}
                  {props.original.emptiesBeerKegs && (
                    <Tag name="Empties Beer Kegs" description="This place type empties beer kegs (e.g. Venue)" colour={color.primary[3]} />
                  )}
                </>
              ),
            },
          ]
        : []),
      {
        id: "groupColour",
        minWidth: 160,
        maxWidth: 160,
        Header: "Colour",
        accessor: "groupColour",
        Cell: (props: any) => <ColoredDot color={props.value} />,
      },
    ],
    [color]
  );

  const defaultSorted = [
    {
      id: "placeCount",
      desc: true,
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <LoadingContainer loading={typesLoading} err={typesErr}>
        <TableHeaderButtons>
          <div style={{ display: "flex" }}>
            {isAdminOrUser() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setSelectedType(null);
                      setEditModalOpen(true);
                    }}
                  >
                    Create
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <PrimaryIconBtn
                      onClick={() => {
                        setSelectedType(null);
                        setEditModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryBtn disabled={true}>Create</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryIconBtn>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
          <CsvButtonsComponent data={types} formatCsv={formatDataToCsv} formatCsvParams={[tableRef]} fileName="Place Type List.csv" />
        </TableHeaderButtons>
        <Table
          loading={typesLoading}
          filterable={true}
          style={{ clear: "both" }}
          data={types}
          columns={columns}
          defaultSorted={defaultSorted}
          ref={tableRef}
          defaultPageSize={15}
        />
      </LoadingContainer>
      {editModalOpen && <EditPlaceTypeModal group={selectedType} fetch={fetchPlaceTypes} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />}
      {deleteModalOpen && (
        <DeleteModal
          title="Delete Place Type"
          body={
            <>
              <span>Are you sure you want to delete this place type?</span>
              <br />
              <span>This will also remove the place type from any places it was assigned to</span>
            </>
          }
          successMsg="Place Type Deleted"
          onDelete={() => setTypes((prev: any) => prev.filter((row: any) => row.groupId !== selectedType.groupId))}
          onClose={() => setSelectedType(null)}
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          deleteService={deleteEditPlaceGroup}
          serviceParams={[selectedType.groupId]}
          // mutationConfig={{
          //   onSuccess: () =>
          //     queryCache.setQueryData("placeTypes", (prev: any) =>
          //       prev.filter((row: any) => row.groupId !== selectedType.groupId)
          //     )
          // }}
        />
      )}
      {placeListModalOpen && <PlaceTypePlaceList type={selectedType} modalOpen={placeListModalOpen} setModalOpen={setPlaceListModalOpen} />}
    </div>
  );
};

export default PlaceTypesTable;
