import axios, { CancelTokenSource } from "axios";
import { FC, useEffect, useState } from "react";
import { postCreateSensorIds } from "../../services/activateSensors";
import errToStr from "../../util/errToStr";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import Bold from "../Bold";
import { GhostBtn, OutlineBtn, PrimaryBtn } from "../Buttons";
import CreateSensorsTable from "../CreateSensorsTable";
import { FormError, FormInput } from "../FormComponents";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { ModalFormContainer } from "../Modal/styles";

const CreateSensorsModal: FC<any> = ({ onSuccess, modalOpen, setModalOpen }) => {
  const [formData, setFormData] = useState<any>({
    count: 0,
  });
  const [formErrors, setFormErrors] = useState<any>({});

  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [newSensors, setNewSensors] = useState<any>([]);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const formatFormData = () => {
    const formattedData: any = {
      count: formData.count ? formData.count : undefined,
    };

    return formattedData;
  };

  const handleSubmit = () => {
    const formattedData = formatFormData();

    setSubmitting(true);
    postCreateSensorIds(source, formattedData)
      .then((response) => {
        onSuccess();
        setNewSensors(response);
        setSubmitted(true);
        setSubmittingErr("");
        setSubmitting(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleClose = () => {
    if (!submitting) setModalOpen(false);
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={handleSubmit}
      onClose={handleClose}
      title={kegsOrTrackers("Create Kegs", "Create Trackers")}
      error={submittingErr}
      body={
        submitted ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Bold style={{ textAlign: "center", marginBottom: "1em" }}>{kegsOrTrackers("New Kegs Created", "New Trackers Created")}</Bold>
            <div>
              <CreateSensorsTable data={newSensors} />
            </div>
          </div>
        ) : (
          <LoadingContainer loading={submitting}>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <ModalFormContainer>
                <label>{kegsOrTrackers("Number of Kegs", "Number of Trackers")}</label>
                <FormInput type="number" name="count" min={0} value={formData.count} onChange={handleChange} />
                <FormError error={formErrors.count}>{formErrors.count}</FormError>
              </ModalFormContainer>
            </form>
          </LoadingContainer>
        )
      }
      footer={
        submitted ? (
          <OutlineBtn width="100%" onClick={handleClose}>
            Okay
          </OutlineBtn>
        ) : submittingErr ? (
          <OutlineBtn onClick={handleClose}>Okay</OutlineBtn>
        ) : (
          <>
            <GhostBtn onClick={handleClose}>Cancel</GhostBtn>
            <PrimaryBtn onClick={handleSubmit}>{kegsOrTrackers("Create Kegs", "Create Trackers")}</PrimaryBtn>
          </>
        )
      }
    />
  );
};

export default CreateSensorsModal;
