import styled from "../Themes";

export const Bar = styled<any>(`div`)`
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  height: 50px;
  top: 0;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  border-bottom: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
  overflow: hidden;
  z-index: 1000300;
`;

export const OrgAvatarContainer = styled<any>("button")`
  float: right;
  height: 40px;
  padding: 10px;
  margin: 5px 10px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

export const UserAvatarContainer = styled<any>("button")`
  float: right;
  height: 40px;
  padding: 10px;
  margin: 5px 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

export const OrgName = styled(`div`)`
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.color.font[2]};
  padding: 3px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
  max-width: 160px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MenuContainer = styled<any>(`div`)`
  width: 18px;
  height: 18px;
`;

export const MenuBtn = styled<any>("button")`
  padding: 16px;
  width: 50px;
  height: 50px;
  fill: ${({ theme }) => theme.color.font[2]};
  stroke: ${({ theme }) => theme.color.font[2]};
  float: left;
`;

export const Username = styled(`span`)`
  font-weight: 500;
  color: ${({ theme }) => theme.color.font[2]};
  padding: 3px 0;
`;

export const Email = styled(`span`)`
  color: ${({ theme }) => theme.color_80.font[2]};
  padding: 3px 0;
`;

export const Organisation = styled(`span`)`
  font-weight: 500;
  color: ${({ theme }) => theme.color.font[2]};
  padding: 3px 0;
`;

export const ProfileContainer = styled(`div`)`
  padding: 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
`;

export const OrganisationContainer = styled(`div`)`
  padding: 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
`;

export const DetailsContainer = styled(`div`)`
  margin-left: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NotificationIconContainer = styled(`div`)`
  position: relative;
  float: right;
  cursor: pointer;
  color: ${({ theme }) => theme.color.font[2]};

  &:hover {
    color: ${({ theme }) => theme.color.font_bold[2]};
    svg {
      color: ${({ theme }) => theme.color.font_bold[2]};
    }
  }
`;

export const NotificationContainer = styled(`div`)`
  max-height: 50vh;
  text-align: left;
  width: 360px;
  padding: 12px;
  overflow-y: auto;

  @media (max-width: 576px) {
    width: 95vw;
  }
`;

export const Notification = styled(`div`)`
  padding: 12px;
  border-radius: 3px;
  margin-bottom: 6px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.tooltip_hover[2]};
  }
`;

export const NotificationBody = styled(`div`)`
  display: flex;
  flex-direction: row;
`;

export const NotificationImg = styled(`div`)`
  margin: 0 12px 0 0;
`;

export const NotificationDate = styled(`p`)`
  font-size: 11px;
  margin-top: 4px;
  color: ${({ theme }) => theme.color.primary[2]};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const NotificationFooter = styled(`div`)`
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  margin-top: 12px;
`;

export const Badge = styled(`div`)`
  box-sizing: border-box;
  color: white;
  font-size: 16px;
  position: absolute;
  top: 10px;
  right: 6px;
  min-width: 1em;
  height: 1em;
  background-color: ${({ theme }) => theme.color.danger[2]};
  border-radius: 1em;
  text-align: center;
  line-height: 1em;
`;

export const BadgeNumber = styled(`div`)`
  font-size: 0.45em;
`;

export const LogoContainer = styled(`div`)`
  display: flex;
  margin-left: 10px;
  height: 25px;
  max-height: 25px;
  object-fit: contain;

  @media (max-width: 400px) {
    display: none;
  }
`;
