import React, { FC, useState, useEffect } from "react";
import { ModalFilterContainer, ModalFilterHeader } from "./styles";
import { AsyncSelect } from "../Select";
import { fetchAutoComplete } from "../../services/autoComplete";

// Sets the initial values of the selected tracker types if there are URL query parameters.
// If there are none we use the default initial values []
const getValues = (sensorFilters: any) => {
  const { productIds } = sensorFilters;

  const productIdArray = productIds ? productIds.split(",") : [];

  if (productIdArray.length > 0) {
    return productIdArray.map((label: string) => ({ value: label, label }));
  }

  return [];
};

const ProductTypes: FC<any> = ({ sensorFilters, setModalQuery }) => {
  const [values, setValues] = useState<any>(getValues(sensorFilters));
  const [formattedValues, setFormattedValues] = useState<any>([]);

  // Since we are filtering tracker types by id we must fetch the names and match them
  // in order to list the selected tracker types in the select input by name
  // We do this whenever values change
  useEffect(() => {
    fetchAutoComplete("trackertypes", "").then((response) => {
      setFormattedValues(() =>
        values.map((val: any) => {
          // Must use == to check for equality since the previous values are strings retrieved from the URL query parameters
          const trackerType = response.find((prod: any) => prod.value == val.value);
          if (trackerType) {
            return {
              label: trackerType.label,
              value: trackerType.value,
            };
          }
        })
      );
    });
  }, [values]);

  // This is to update the values and button state when a user changes the query
  // parameters (e.g. when a user navigates using the back/forward buttons).
  // Each time the sensorFilters change (whenever the sensor query parameters change),
  // if the new values don't match the current values, update the values and button state
  useEffect(() => {
    const newValues = getValues(sensorFilters);
    if (JSON.stringify(values) !== JSON.stringify(newValues)) {
      setValues(newValues);
    }
  }, [sensorFilters]);

  // On initial render, sets the modal query string state in
  // the parent component to the initial values
  useEffect(() => {
    updateModalQuery(values);
  }, []);

  const updateModalQuery = (values: any) => {
    if (values.length > 0) {
      const productIds = values.map((label: any) => label.value, []);
      setModalQuery((prev: any) => ({
        ...prev,
        productIds: productIds.join(","),
      }));
    } else {
      setModalQuery((prev: any) => ({
        ...prev,
        productIds: undefined,
      }));
    }
  };

  const loadOptions = (inputName: string, inputValue: string, callback: any) => {
    fetchAutoComplete(inputName, inputValue).then((response) => {
      callback(response);
    });
  };

  return (
    <ModalFilterContainer>
      <ModalFilterHeader>Tracker Types</ModalFilterHeader>
      <AsyncSelect
        name="productType"
        defaultOptions={true}
        closeMenuOnSelect={false}
        isClearable={true}
        isMulti={true}
        isSearchable={true}
        value={formattedValues}
        loadOptions={(inputValue: any, callback: any) => loadOptions("trackertypes", inputValue, callback)}
        onChange={(selected: any) => {
          if (selected) {
            setValues(selected);
            updateModalQuery(selected);
          } else {
            setValues([]);
            updateModalQuery([]);
          }
        }}
        placeholder="Select..."
      />
    </ModalFilterContainer>
  );
};

export default ProductTypes;
