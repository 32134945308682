import React, { FC } from "react";
import { PageContainer } from "../PageStyles";
import { Panel } from "../Panel";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import ManifestsTable from "../ManifestsTable";
import ContentsTable from "../ContentsTable";
import ContentTagsTable from "../ContentTagsTable";

const ManifestsScreen: FC<any> = () => {
  return (
    <>
      <Tabs>
        <TabList className="react-tabs__tab-list__background_no-margin">
          <Tab>Manifests</Tab>
          <Tab>Contents</Tab>
          <Tab>Content Tags</Tab>
        </TabList>
        <TabPanel>
          <PageContainer>
            <Panel>
              <ManifestsTable />
            </Panel>
          </PageContainer>
        </TabPanel>
        <TabPanel>
          <PageContainer>
            <Panel>
              <ContentsTable />
            </Panel>
          </PageContainer>
        </TabPanel>
        <TabPanel>
          <PageContainer>
            <Panel>
              <ContentTagsTable />
            </Panel>
          </PageContainer>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default ManifestsScreen;
