import React, { FC, useState } from "react";
import { SingleDatePicker as ReactSingleDatePicker } from "react-dates";
import Cross from "../../svgs/Cross";
import styled from "../Themes";

const SingleDatePicker: FC<any> = ({
  block,
  date,
  displayFormat,
  error,
  id,
  isOutsideRange,
  numberOfMonths,
  onDateChange,
  placeholder,
  showClearDate,
  small,
  withPortal,
  style,
}) => {
  const [focused, setFocused] = useState<boolean>(false);

  // Sets the border of the date input the red if there is an error
  // https://github.com/airbnb/react-dates/issues/1030#issuecomment-388227454
  const ErrorWrapper = styled<any>(`div`)`
    .DateInput_input {
      border: 1px solid ${({ theme, error }) => (error ? theme.color.error[2] : theme.color_30.panel_fg[0])};

      &:hover {
        border: 1px solid ${({ theme, error }) => (error ? theme.color.error[2] : theme.color_30.panel_fg[0])};
      }
    }
  `;

  const handleFocusChange = (focused: boolean | null) => {
    if (focused) setFocused(true);
    else setFocused(false);
  };

  return (
    <>
      <ErrorWrapper style={style} error={error}>
        <ReactSingleDatePicker
          block={block}
          customCloseIcon={<Cross />}
          date={date}
          displayFormat={displayFormat}
          focused={focused}
          hideKeyboardShortcutsPanel={true}
          id={id}
          isOutsideRange={isOutsideRange}
          numberOfMonths={numberOfMonths}
          onDateChange={onDateChange}
          onFocusChange={({ focused }) => handleFocusChange(focused)}
          placeholder={placeholder}
          showClearDate={showClearDate}
          small={small}
          withPortal={withPortal}
        />
      </ErrorWrapper>
    </>
  );
};

export default SingleDatePicker;
