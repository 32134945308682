import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

type GetTrackersProps = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: any;
  filters?: any;
};

export const getTrackers = async (source: CancelTokenSource, { pageIndex, pageSize, orderBy, filters }: GetTrackersProps) => {
  const params: any = {
    organisationId: getAccount().organisationId,
    count: true,
  };

  if (pageIndex !== undefined && pageSize !== undefined) params.skip = pageIndex * pageSize;

  if (pageSize !== undefined) params.top = pageSize;

  if (orderBy && orderBy.length > 0) params.orderby = orderBy.map((order: any) => `${order.id} ${order.desc ? "desc" : "asc"}`).join(", ");

  if (filters) params.filter = filters;

  const { data, headers } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/trackers",
    params,
  });

  return { count: headers?.count, data };
};

export const getTracker = async (source: CancelTokenSource, id: string) => {
  const params: any = {
    organisationId: getAccount().organisationId,
  };

  const { data, headers } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + `/v1/trackers/${id ? id : ""}`,
    params,
  });

  return data;
};

export const putTracker = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + `/v1/trackers/${body.id ? body.id : ""}`,
    data: JSON.stringify(body),
  });

  return data;
};
