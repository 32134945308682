import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

export const fetchEditAlerts = async (source: CancelTokenSource, sensorId?: string, dates?: any) => {
  const params = {
    organisationId: getAccount().organisationId,
    start: dates ? dates.start : undefined,
    end: dates ? dates.end : undefined,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + `/api/alertnotifications/${sensorId ? sensorId : ""}`,
    params,
  });
  return data;
};

export const countUnresolvedAlerts = async (source: CancelTokenSource, sensorId?: string) => {
  const params = {
    organisationId: getAccount().organisationId,
    sensorId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/api/alertnotifications/unresolved/",
    params,
  });
  return data;
};

export const postEditAlerts = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/api/alertnotifications",
    data: JSON.stringify(body),
  });

  return data;
};

export const postEditBulkAlerts = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/api/alertnotifications/bulk",
    data: JSON.stringify(body),
  });

  return data;
};
