import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import { FC, useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { FaMapMarkerAlt, FaRunning } from "react-icons/fa";
import { GiBackwardTime } from "react-icons/gi";
import { HiExclamationCircle, HiHashtag, HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { LiaFlagCheckeredSolid } from "react-icons/lia";
import { LuClock3 } from "react-icons/lu";
import { PiClipboardTextLight } from "react-icons/pi";
import { RxCrossCircled } from "react-icons/rx";
import { Link } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import { ThemeContext } from "styled-components";
import RocheLogo from "../../svgs/RocheLogo";
import copyToClipboard from "../../util/copyToClipboard";
import getSensorIcon from "../../util/getSensorIcon";
import { googleMapsAddressLink, googleMapsLink } from "../../util/googleMapsLink";
import { hasNavify } from "../../util/integrations";
import { kegsOrTrackers } from "../../util/kegOrTracker";
import sortTags from "../../util/sortTags";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import LoadingContainer from "../LoadingContainer";
import ReactIcon from "../ReactIcon";
import Tag from "../Tag";
import { BoxElement, BoxesContainer, ContentContainer, Footer, Header, Icon, IconContainer, Section, Value, ValueContainer } from "./styles";

const ManifestMeta: FC<any> = ({ trackerMeta, manifestMeta, dataLoading }) => {
  const { color, color_50, short_datetime, long_datetime, short_date } = useContext(ThemeContext);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const {
    productName = null,
    productId = null,
    latestSampleDate = null,
    place = null,
    placeId = null,
    placeTags = null,
    placeGroup = null,
    placeGroupColour = null,
    dateArrived = null,
    address = null,
    lat = null,
    lng = null,
    assetTypeName = null,
    assetTypeColour = null,
    assetTypeIcon = null,
    assetId = null,
    lastGoodLocationDate = null,
  } = trackerMeta ? trackerMeta : {};

  const {
    id = null,
    navifyTransportId = null,
    sensorId = null,
    name = null,
    description = null,
    startTime = null,
    startTimeUnix = null,
    endTime = null,
    endTimeUnix = null,
    deadlineTime = null,
    deadlineTimeUnix = null,
    startPlaceId = null,
    endPlaceId = null,
    targetPlaceId = null,
    startingUserId = null,
    endUserId = null,
    status = null,
    createdUserId = null,
    createdTime = null,
    createdTimeUnix = null,
    startPlaceName = null,
    endPlaceName = null,
    targetPlaceName = null,
    startingUserName = null,
    endUserName = null,
    createdUserName = null,
    alerts = null,
    dateMarkedForPickup = null,
    dateMarkedForPickupUnix = null,
    markedForPickupUserName = null,
  } = manifestMeta ? manifestMeta : {};

  return (
    <>
      <LoadingContainer loading={dataLoading}>
        <Section>
          {(id !== null || alerts !== null) && (
            <BoxesContainer>
              {id !== null && (
                <BoxElement>
                  <ContentContainer>
                    <IconContainer>
                      <Icon>
                        <IconContext.Provider value={{ color: alerts == null ? color.success[2] : color.danger[2], size: "28px" }}>
                          <PiClipboardTextLight />
                        </IconContext.Provider>
                      </Icon>
                    </IconContainer>
                    <ValueContainer>
                      <Header>Manifest</Header>
                      <Value style={{ fontSize: "14px", lineHeight: "17px" }}>{status !== null ? status : "Unknown"}</Value>
                      <Footer>ID: {id}</Footer>
                    </ValueContainer>
                  </ContentContainer>
                </BoxElement>
              )}
              {alerts !== null && (
                <BoxElement>
                  <ContentContainer>
                    <IconContainer>
                      <Icon>
                        <IconContext.Provider value={{ color: color.danger[2], size: "28px" }}>
                          <RxCrossCircled />
                        </IconContext.Provider>
                      </Icon>
                    </IconContainer>
                    <ValueContainer>
                      <Header>Fail Reason</Header>
                      <Value>Missed Deadline</Value>
                      <Footer>And 2 others</Footer>
                    </ValueContainer>
                  </ContentContainer>
                </BoxElement>
              )}
            </BoxesContainer>
          )}
          {(sensorId !== null || assetId !== null || assetTypeName !== null) && (
            <BoxesContainer>
              {sensorId !== null && (
                <BoxElement>
                  <ContentContainer>
                    <IconContainer>
                      <Icon>{getSensorIcon(productId, productName, color)}</Icon>
                    </IconContainer>
                    <ValueContainer>
                      <Header>Tracker</Header>
                      <Value>
                        <Link title={sensorId} to={`/${kegsOrTrackers("kegs", "trackers")}/${sensorId}`}>
                          {sensorId}
                        </Link>
                      </Value>
                      {productName !== null && <Footer>{productName}</Footer>}
                    </ValueContainer>
                  </ContentContainer>
                </BoxElement>
              )}
              {(assetId !== null || assetTypeName !== null) && (
                <BoxElement>
                  <ContentContainer>
                    <IconContainer>
                      <Icon>
                        <ReactIcon
                          name={assetTypeIcon !== null ? assetTypeIcon : "GiCube"}
                          defaultColour={color_50.font_bold[2]}
                          colour={assetTypeColour ? assetTypeColour : color_50.font_bold[2]}
                          title={assetTypeName}
                          size="32px"
                        />
                      </Icon>
                    </IconContainer>
                    <ValueContainer>
                      <Header>{assetId !== null && assetTypeName !== null ? "Asset" : assetId !== null ? "Asset ID" : "Asset Type"}</Header>
                      <Value>{assetId !== null ? assetId : assetTypeName}</Value>
                      {assetTypeName && assetId !== null && <Footer>{assetTypeName}</Footer>}
                    </ValueContainer>
                  </ContentContainer>
                </BoxElement>
              )}
            </BoxesContainer>
          )}
          {((hasNavify() && navifyTransportId !== null) || name !== null) && (
            <BoxesContainer>
              {hasNavify() && navifyTransportId !== null && (
                <BoxElement>
                  <ContentContainer>
                    <IconContainer>
                      <Icon>
                        <RocheLogo color={color_50.font_bold[2]} />
                      </Icon>
                    </IconContainer>
                    <ValueContainer>
                      <Header>Navify Transport</Header>
                      <Value
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          copyToClipboard(navifyTransportId);
                          toast.info("Copied to Clipboard");
                        }}
                      >
                        {navifyTransportId}
                      </Value>
                    </ValueContainer>
                  </ContentContainer>
                </BoxElement>
              )}
              {name !== null && (
                <BoxElement>
                  <ContentContainer>
                    <IconContainer>
                      <Icon>
                        <IconContext.Provider value={{ color: color_50.font_bold[2], size: "28px" }}>
                          <HiHashtag />
                        </IconContext.Provider>
                      </Icon>
                    </IconContainer>
                    <ValueContainer>
                      <Header>Name</Header>
                      <Value>{name}</Value>
                    </ValueContainer>
                  </ContentContainer>
                </BoxElement>
              )}
            </BoxesContainer>
          )}
          {(latestSampleDate !== null || lastGoodLocationDate !== null) && (
            <BoxesContainer>
              {latestSampleDate !== null && (
                <BoxElement>
                  <ContentContainer>
                    <IconContainer>
                      <Icon>
                        <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                          <GiBackwardTime />
                        </IconContext.Provider>
                      </Icon>
                    </IconContainer>
                    <ValueContainer>
                      <Header>Last Seen</Header>
                      <Value>
                        <ReactTimeago live={false} date={latestSampleDate * 1000} title={moment.unix(latestSampleDate).format(long_datetime)} />
                      </Value>
                      <Footer>{moment.unix(latestSampleDate).format(short_datetime)}</Footer>
                    </ValueContainer>
                  </ContentContainer>
                </BoxElement>
              )}
              {latestSampleDate !== null && lastGoodLocationDate !== null && latestSampleDate !== lastGoodLocationDate && (
                <BoxElement>
                  <ContentContainer>
                    <IconContainer>
                      <Icon>
                        <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                          <FaMapMarkerAlt />
                        </IconContext.Provider>
                      </Icon>
                    </IconContainer>
                    <ValueContainer>
                      <Header>Last Located</Header>
                      <Value>
                        <ReactTimeago live={false} date={lastGoodLocationDate * 1000} title={moment.unix(lastGoodLocationDate).format(long_datetime)} />
                      </Value>
                      <Footer>{moment.unix(lastGoodLocationDate).format(short_datetime)}</Footer>
                    </ValueContainer>
                  </ContentContainer>
                </BoxElement>
              )}
            </BoxesContainer>
          )}
          {createdTimeUnix !== null && createdUserName !== null && (
            <BoxesContainer>
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                        <LuClock3 />
                      </IconContext.Provider>
                    </Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>Created by {createdUserName}</Header>
                    <Value>
                      <ReactTimeago live={false} date={createdTimeUnix * 1000} title={moment.unix(createdTimeUnix).format(long_datetime)} />
                    </Value>
                    <Footer>{moment.unix(createdTimeUnix).format(short_datetime)}</Footer>
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            </BoxesContainer>
          )}
          {dateMarkedForPickupUnix !== null && (
            <BoxesContainer>
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                        <HiExclamationCircle />
                      </IconContext.Provider>
                    </Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>Marked for Pickup by {markedForPickupUserName}</Header>
                    <Value>
                      <ReactTimeago live={false} date={dateMarkedForPickupUnix * 1000} title={moment.unix(dateMarkedForPickupUnix).format(long_datetime)} />
                    </Value>
                    <Footer>{moment.unix(dateMarkedForPickupUnix).format(short_datetime)}</Footer>
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            </BoxesContainer>
          )}
          {startTimeUnix !== null && startPlaceId !== null && startingUserName !== null && (
            <BoxesContainer>
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                        <FaRunning />
                      </IconContext.Provider>
                    </Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>Started by {startingUserName}</Header>
                    <Value>
                      <ReactTimeago live={false} date={startTimeUnix * 1000} title={moment.unix(startTimeUnix).format(long_datetime)} />{" "}
                      {startPlaceName !== null && (
                        <>
                          at{" "}
                          <Link title={startPlaceName} to={`/places/${startPlaceId}`}>
                            {startPlaceName}
                          </Link>
                        </>
                      )}
                    </Value>
                    <Footer>{moment.unix(startTimeUnix).format(short_datetime)}</Footer>
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            </BoxesContainer>
          )}
          {endTimeUnix !== null && endPlaceId !== null && endUserName !== null ? (
            <BoxesContainer>
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                        <GiBackwardTime />
                      </IconContext.Provider>
                    </Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>Ended by {endUserName}</Header>
                    <Value>
                      <ReactTimeago live={false} date={endTimeUnix * 1000} title={moment.unix(endTimeUnix).format(long_datetime)} />{" "}
                      {endPlaceName !== null && (
                        <>
                          at{" "}
                          <Link title={endPlaceName} to={`/places/${endPlaceId}`}>
                            {endPlaceName}
                          </Link>
                        </>
                      )}
                    </Value>
                    <Footer>{moment.unix(endTimeUnix).format(short_datetime)}</Footer>
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            </BoxesContainer>
          ) : deadlineTimeUnix !== null ? (
            <BoxesContainer>
              <BoxElement>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <IconContext.Provider value={{ color: color_50.font_bold[2], size: "32px" }}>
                        <LiaFlagCheckeredSolid />
                      </IconContext.Provider>
                    </Icon>
                  </IconContainer>
                  <ValueContainer>
                    <Header>Deadline</Header>
                    <Value>
                      <ReactTimeago live={false} date={deadlineTimeUnix * 1000} title={moment.unix(deadlineTimeUnix).format(long_datetime)} />{" "}
                      {targetPlaceName !== null && `at ${targetPlaceName}`}
                    </Value>
                    <Footer>{moment.unix(deadlineTimeUnix).format(short_datetime)}</Footer>
                  </ValueContainer>
                </ContentContainer>
              </BoxElement>
            </BoxesContainer>
          ) : (
            <></>
          )}
          {((placeId !== null && place !== null) || address !== null) && (
            <BoxesContainer>
              <BoxElement style={{ display: "flex", flexDirection: "column", margin: "0" }}>
                <ContentContainer>
                  <IconContainer>
                    <Icon>
                      <IconContext.Provider
                        value={{ color: placeGroupColour ? placeGroupColour : color_50.font_bold[2], size: placeId && place ? "60px" : "28px" }}
                      >
                        {placeId !== null && place !== null ? <HiOutlineBuildingOffice2 /> : <FaMapMarkerAlt />}
                      </IconContext.Provider>
                    </Icon>
                  </IconContainer>
                  <ValueContainer style={{ justifyContent: "center" }}>
                    <Header>Current Location</Header>
                    {placeId !== null && place !== null ? (
                      <Value>
                        <Link title={place} to={`/places/${placeId}`}>
                          {place}
                        </Link>
                      </Value>
                    ) : address && lat !== null && lng !== null ? (
                      <Value title={address}>{googleMapsLink(lat, lng, address)}</Value>
                    ) : address ? (
                      <Value title={address}>{googleMapsAddressLink(address)}</Value>
                    ) : (
                      <></>
                    )}
                    {placeGroup !== null && (
                      <Footer>
                        {placeGroupColour !== null && <ColoredDot color={placeGroupColour} margin="0 4px" />}
                        {placeGroup}
                      </Footer>
                    )}
                  </ValueContainer>
                </ContentContainer>
                {(placeId !== null || place !== null) && (
                  <ContentContainer
                    style={{
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginTop: "3px",
                    }}
                  >
                    {dateArrived && (
                      <Footer>
                        Arrived <ReactTimeago live={false} date={dateArrived * 1000} title={moment.unix(dateArrived).format(long_datetime)} /> (
                        {moment.unix(dateArrived).format(short_date)})
                      </Footer>
                    )}
                    {address !== null && lat !== null && lng !== null ? (
                      <Footer>{googleMapsLink(lat, lng, address)}</Footer>
                    ) : address !== null ? (
                      <Footer>{googleMapsAddressLink(address)}</Footer>
                    ) : (
                      <></>
                    )}
                    {placeTags !== null && placeTags.length > 0 && (
                      <Value style={{ marginTop: "3px" }}>
                        {placeTags.sort(sortTags).map((tag: any) => (
                          <Tag key={tag.name} name={tag.name} description={tag.description} colour={tag.colour} />
                        ))}
                      </Value>
                    )}
                  </ContentContainer>
                )}
              </BoxElement>
            </BoxesContainer>
          )}
        </Section>
      </LoadingContainer>
    </>
  );
};

export default ManifestMeta;
