import { FC } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { isBinaryLoop } from "../../util/checkDomain";
import { PageContainer } from "../PageStyles";
import { Panel } from "../Panel";
import PickupRequestsTable from "../PickupRequestsTable";
import PickupRequestTypesTable from "../PickupRequestTypesTable";

const PickupRequestsScreen: FC<any> = () => {
  return (
    <>
      <Tabs>
        <TabList className="react-tabs__tab-list__background_no-margin">
          <Tab>Pickup Requests</Tab>
          <Tab>{isBinaryLoop() ? "Contents Types" : "Pickup Request Type"}</Tab>
        </TabList>
        <TabPanel>
          <PageContainer>
            <Panel>
              <PickupRequestsTable />
            </Panel>
          </PageContainer>
        </TabPanel>
        <TabPanel>
          <PageContainer>
            <Panel>
              <PickupRequestTypesTable />
            </Panel>
          </PageContainer>
        </TabPanel>
      </Tabs>
    </>
  );
};

export default PickupRequestsScreen;
