import axios, { CancelTokenSource } from "axios";
import matchSorter from "match-sorter";
import React, { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { IconContext } from "react-icons";
import { HiPlus } from "react-icons/hi";
import { ThemeContext } from "styled-components";
import { deletePickupRequestType, fetchPickupRequestTypes } from "../../services/pickupRequestTypes";
import MoreIcon from "../../svgs/MoreIcon";
import { isBinaryLoop } from "../../util/checkDomain";
import { isAdminOrUser } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import Bold from "../Bold";
import { PrimaryBtn } from "../Buttons";
import DeleteModal from "../DeleteModal";
import EditPickupRequestTypeModal from "../EditPickupRequestTypeModal";
import { ColoredDot } from "../GlobalStyles/coloredDot";
import { PrimaryIconBtn } from "../IconButtons";
import LoadingContainer from "../LoadingContainer";
import { DesktopDiv, MobileDiv, TableHeaderButtons } from "../NewTable/styles";
import Table, { CsvButtonsComponent } from "../Table";
import Tooltip from "../Tooltip";
import { DangerMenuButton, MenuButton, MenuList } from "../Tooltip/styles";
import { MoreIconContainer, MoreIconSize } from "../UsersScreen/styles";

const PickupRequestTypesTable: FC<any> = () => {
  const { color } = useContext(ThemeContext);

  const tableRef = useRef<any>(null);

  const [types, setTypes] = useState<any>([]);
  const [typesErr, setTypesErr] = useState<string>("");
  const [typesLoading, setTypesLoading] = useState<boolean>(false);

  const [selectedType, setSelectedType] = useState<any>(null);
  const [editModalOpen, setEditModalOpen] = useState<any>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  const getPickupRequestTypes = useCallback(() => {
    setTypesLoading(true);
    fetchPickupRequestTypes(source)
      .then((response) => {
        setTypes(response);
        setTypesLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setTypesErr(errToStr(err));
          setTypesLoading(false);
        }
      });
  }, [source]);

  useEffect(() => {
    if (source) {
      getPickupRequestTypes();
    }

    return () => {
      source.cancel();
    };
  }, [source, getPickupRequestTypes]);

  const formatDataToCsv = (tableRef: any) => {
    const sortedData = tableRef.current.getResolvedState().sortedData;
    let headers = [];

    headers = ["Name", "Colour"];

    return [
      headers,
      ...sortedData.map((row: any) => {
        return [row.name, row.colour];
      }, []),
    ];
  };

  const columns = React.useMemo<any>(
    () => [
      {
        Header: "Actions",
        minWidth: 110,
        maxWidth: 110,
        filterable: false,
        sortable: false,
        Cell: ({ original }: any) => {
          return (
            <Tooltip
              maxWidth="none"
              theme="binary-no-padding"
              content={
                <MenuList>
                  {isAdminOrUser() ? (
                    <>
                      <MenuButton
                        onClick={() => {
                          setSelectedType(original);
                          setEditModalOpen(true);
                        }}
                      >
                        Edit {isBinaryLoop() ? "Contents Type" : "Pickup Request Type"}
                      </MenuButton>
                      <DangerMenuButton
                        onClick={() => {
                          setSelectedType(original);
                          setDeleteModalOpen(true);
                        }}
                      >
                        Delete {isBinaryLoop() ? "Contents Type" : "Pickup Request Type"}
                      </DangerMenuButton>
                    </>
                  ) : (
                    <>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Edit {isBinaryLoop() ? "Contents Type" : "Pickup Request Type"}</MenuButton>
                        </div>
                      </Tooltip>
                      <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                        <div
                          style={{
                            cursor: "not-allowed",
                            userSelect: "none",
                          }}
                        >
                          <MenuButton disabled={true}>Delete {isBinaryLoop() ? "Contents Type" : "Pickup Request Type"}</MenuButton>
                        </div>
                      </Tooltip>
                    </>
                  )}
                </MenuList>
              }
              interactive={true}
              touch={true}
              appendTo={document.body}
              trigger="click"
              placement="bottom-start"
            >
              <MoreIconContainer>
                <MoreIconSize>
                  <MoreIcon fill={color.font[2]} />
                </MoreIconSize>
              </MoreIconContainer>
            </Tooltip>
          );
        },
        Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
      },
      {
        id: "name",
        Header: "Name",
        accessor: "name",
        filterMethod: (filter: any, rows: any) =>
          matchSorter(rows, filter.value, {
            threshold: matchSorter.rankings.CONTAINS,
            keys: ["name"],
          }),
        filterAll: true,
      },
      {
        id: "colour",
        minWidth: 160,
        maxWidth: 160,
        Header: "Colour",
        accessor: "colour",
        Cell: (props: any) => <ColoredDot color={props.value} />,
      },
    ],
    [color]
  );

  const defaultSorted = [
    {
      id: "name",
      desc: true,
    },
  ];

  return (
    <div style={{ position: "relative" }}>
      <LoadingContainer loading={typesLoading} err={typesErr}>
        <TableHeaderButtons>
          <div style={{ display: "flex" }}>
            {isAdminOrUser() ? (
              <>
                <DesktopDiv>
                  <PrimaryBtn
                    onClick={() => {
                      setSelectedType(null);
                      setEditModalOpen(true);
                    }}
                  >
                    Create
                  </PrimaryBtn>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip content="Create">
                    <PrimaryIconBtn
                      onClick={() => {
                        setSelectedType(null);
                        setEditModalOpen(true);
                      }}
                    >
                      <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                        <HiPlus />
                      </IconContext.Provider>
                    </PrimaryIconBtn>
                  </Tooltip>
                </MobileDiv>
              </>
            ) : (
              <>
                <DesktopDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryBtn disabled={true}>Create</PrimaryBtn>
                    </div>
                  </Tooltip>
                </DesktopDiv>
                <MobileDiv>
                  <Tooltip trigger="mouseenter" content="Insufficient Permissions">
                    <div
                      style={{
                        cursor: "not-allowed",
                        userSelect: "none",
                      }}
                    >
                      <PrimaryIconBtn>
                        <IconContext.Provider value={{ color: color.button_font_bold[2], size: "20px" }}>
                          <HiPlus />
                        </IconContext.Provider>
                      </PrimaryIconBtn>
                    </div>
                  </Tooltip>
                </MobileDiv>
              </>
            )}
          </div>
          <CsvButtonsComponent
            data={types}
            formatCsv={formatDataToCsv}
            formatCsvParams={[tableRef]}
            fileName={isBinaryLoop() ? "Contents Type List.csv" : "Pickup Request Type List.csv"}
          />
        </TableHeaderButtons>
        <Table
          loading={typesLoading}
          filterable={true}
          style={{ clear: "both" }}
          data={types}
          columns={columns}
          defaultSorted={defaultSorted}
          ref={tableRef}
          defaultPageSize={15}
        />
      </LoadingContainer>
      {editModalOpen && (
        <EditPickupRequestTypeModal type={selectedType} fetch={getPickupRequestTypes} modalOpen={editModalOpen} setModalOpen={setEditModalOpen} />
      )}
      {deleteModalOpen && (
        <DeleteModal
          title={isBinaryLoop() ? "Delete Contents Type" : "Delete Pickup Request Type"}
          body={
            <>
              <span>Are you sure you want to delete this {isBinaryLoop() ? "contents type" : "pickup request type"}?</span>
              <br />
              <span>This will also remove the {isBinaryLoop() ? "contents type" : "pickup request type"} from any pickup requests it was assigned to.</span>
            </>
          }
          successMsg={isBinaryLoop() ? "Contents Type Deleted" : "Pickup Request Type Deleted"}
          onDelete={() => setTypes((prev: any) => prev.filter((row: any) => row.id !== selectedType.id))}
          onClose={() => setSelectedType(null)}
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          deleteService={deletePickupRequestType}
          serviceParams={[selectedType.id]}
        />
      )}
    </div>
  );
};

export default PickupRequestTypesTable;
