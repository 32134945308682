import styled from "../Themes";

export const Container = styled<any>(`div`)`
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: ${({ theme }) => theme.color_80.main_bg[2]};
  padding: 10px;
  border-radius: 5px;
  transition: ${({ theme }) => theme.transition.m};
  font-size: 14px;
  overflow: auto;
  opacity: ${({ legendOpen }) => (legendOpen ? "1" : "0")};
  transform-origin: bottom right;
  transform: ${({ legendOpen }) => (legendOpen ? "scaleY(1) scaleX(1)" : "scaleY(0) scaleX(0)")};
  box-shadow: -2px 2px 8px 2px ${({ theme }) => theme.color_15.shadow[2]};
  z-index: 1000;
`;

export const Toggle = styled<any>(`div`)`
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  border: 2px solid ${({ theme }) => theme.color.font[2]};
  background-color: ${({ theme }) => theme.color.font[2]};
  transition: ${({ theme }) => theme.transition.m};
  cursor: pointer;
  z-index: 1001;
`;

export const LegendTitle = styled<any>(`div`)`
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 90%;
`;

export const LegendList = styled<any>(`ul`)`
  margin: 0;
  margin-bottom: 5px;
  padding: 0;
  float: left;
  list-style: none;
`;

export const LegendItem = styled<any>(`li`)`
  font-size: 80%;
  list-style: none;
  margin-left: 0;
  line-height: 24px;
  margin-bottom: 2px;
`;

export const LegendImage = styled<any>(`div`)`
  display: block;
  float: left;
  height: 23px;
  width: 23px;
  margin-right: 5px;
  margin-left: 0;
`;

export const LegendText = styled<any>(`span`)`
  display: block;
  float: left;
  height: 24px;
  width: 24px;
  margin-right: 5px;
  margin-left: 0;
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.color.font[2]};
`;
