import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

type FetchPlaceProps = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: any;
  filters?: any;
};

export const fetchPlaces = async (source: CancelTokenSource, { pageIndex, pageSize, orderBy, filters }: FetchPlaceProps) => {
  const params: any = {
    organisationId: getAccount().organisationId,
    count: true,
  };

  if (pageIndex !== undefined && pageSize !== undefined) params.skip = pageIndex * pageSize;

  if (pageSize !== undefined) params.top = pageSize;

  if (orderBy && orderBy.length > 0) params.orderby = orderBy.map((order: any) => `${order.id} ${order.desc ? "desc" : "asc"}`).join(", ");

  if (filters) params.filter = filters;

  const { data, headers } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/places",
    params,
  });

  return { count: headers?.count, data };
};

export const fetchPlace = async (source: CancelTokenSource, id: number) => {
  const params = {
    organisationId: getAccount().organisationId,
  };

  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/places/" + id,
    params,
  });

  return data;
};

export const postPlace = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/v1/places",
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};

export const putPlace = async (source: CancelTokenSource, id: string, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + `/v1/places/${id ? id : ""}`,
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};

export const deletePlace = async (source: CancelTokenSource, id: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "delete",
    url: process.env.REACT_APP_API_URL + `/v1/places/${id ? id : ""}`,
  });

  return data;
};
