import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

type FetchProps = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: any;
  filters?: any;
};

export const fetchTrackerServiceLogs = async (source: CancelTokenSource, { pageIndex, pageSize, orderBy, filters }: FetchProps) => {
  const params: any = {
    organisationId: getAccount().organisationId,
    count: true,
  };

  if (pageIndex !== undefined && pageSize !== undefined) params.skip = pageIndex * pageSize;

  if (pageSize !== undefined) params.top = pageSize;

  if (orderBy && orderBy.length > 0) params.orderby = orderBy.map((order: any) => `${order.id} ${order.desc ? "desc" : "asc"}`).join(", ");

  if (filters) params.filter = filters;

  const { data, headers } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/trackerservicelogs",
    params,
  });

  return { count: headers?.count, data };
};

export const fetchTrackerServiceActions = async (source: CancelTokenSource) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/trackerservicelogs/actions",
  });

  return data;
};

export const fetchTrackerServicePdfUrl = async (source: CancelTokenSource, serviceId: number) => {
  const params: any = {
    organisationId: getAccount().organisationId,
  };

  const response = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + `/v1/trackerservicelogs/${serviceId}/report`,
    params,
    responseType: "blob",
  });

  const blob = new Blob([response.data], { type: response.headers["content-type"] });
  const blobUrl = window.URL.createObjectURL(blob);

  return blobUrl;
};

export const createTrackerServiceLog = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/v1/trackerservicelogs",
    data: JSON.stringify(body),
  });

  return data;
};

export const deleteTrackerServiceLog = async (source: CancelTokenSource, id: number) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "delete",
    url: process.env.REACT_APP_API_URL + `/v1/trackerservicelogs/${id ? id : ""}`,
  });

  return data;
};
