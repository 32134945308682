import { createGlobalStyle } from "../Themes";

export default createGlobalStyle<any>`
  .react-tabs {
    -webkit-tap-highlight-color: transparent;
  }

  .react-tabs__tab-list {
    border-bottom: 1px solid ${({ theme }) => theme.color.border[2]};
    margin: 0 0 10px;
    padding: 0;

    display: block;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  .react-tabs__tab-list::-webkit-scrollbar {
    display: none;
  }

  .react-tabs__tab-list__background_no-margin {
    border-bottom: 1px solid ${({ theme }) => theme.color.border[2]};
    background: ${({ theme }) => theme.color.panel_bg[2]};
    padding: 0;
    
    position: unset;
    top: 0;
    right: 0;
    left: 0;

    display: block;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  .react-tabs__tab-list__background_no-margin::-webkit-scrollbar {
    display: none;
  }

  .react-tabs__tab {
    display: inline-block;
    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;
    bottom: 0px;
    position: relative;
    list-style: none;
    padding: 7.5px 12px;
    margin: 0px 6px;
    cursor: pointer;
    border-radius: 0;
  }

  .react-tabs__tab__error {
    display: inline-block;
    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;
    bottom: 0px;
    position: relative;
    list-style: none;
    padding: 7.5px 12px;
    margin: 0px 6px;
    cursor: pointer;
    border-radius: 0;
    border-top: 1px solid ${({ theme }) => theme.color.danger[2]};
    border-right: 1px solid ${({ theme }) => theme.color.danger[2]};
    border-left: 1px solid ${({ theme }) => theme.color.danger[2]};
  }

  .react-tabs__tab:hover {
    border-bottom-color: ${({ theme }) => theme.color.border[2]};
    color: ${({ theme }) => theme.color.font_bold[2]};
  }

  .react-tabs__tab--selected {
    border-bottom-color: ${({ theme }) => theme.color.primary[2]};
    color: ${({ theme }) => theme.color.font_bold[2]};
    font-weight: 500;    
  }

  .react-tabs__tab--selected:hover {
    border-bottom-color: ${({ theme }) => theme.color.primary[2]};
  }

  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }

  .react-tabs__tab:focus {
    box-shadow: 0 0 5px ${({ theme }) => theme.color.tooltip_shadow[2]};
    outline: none;
  }

  .react-tabs__tab-panel {
    display: none;
    overflow: unset;
    position: unset;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }
`;
