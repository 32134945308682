import React, { FC, useState, useEffect } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import { postInvite } from "../../services/invite";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";

const CancelInviteModal: FC<any> = ({ setData, modalOpen, setModalOpen, selectedRow, setSelectedRow }) => {
  const [canceledMsg, setCanceledMsg] = useState<string>("");
  const [cancelErr, setCancelErr] = useState<string>("");
  const [cancelLoading, setCancelLoading] = useState<boolean>(false);

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  const handleCancel = (selectedRow: any) => {
    setCancelLoading(true);

    const body = {
      action: "cancel",
      inviteId: selectedRow.inviteId,
    };

    postInvite(source, body)
      .then(() => {
        setSelectedRow({});
        setCanceledMsg("Invite Canceled");
        setData((prev: any) => prev.filter((row: any) => row.inviteId !== selectedRow.inviteId));
        setCancelLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSelectedRow({});
          setCancelErr(errToStr(err));
          setCancelLoading(false);
        }
      });
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleCancel(selectedRow)}
      onClose={() => {
        if (!cancelLoading) {
          setSelectedRow({});
          setCancelErr("");
          setModalOpen(false);
        }
      }}
      title="Cancel Invite"
      success={canceledMsg}
      error={cancelErr}
      submitBtnText="Cancel Invite"
      submitBtn="danger"
      body={
        <LoadingContainer loading={cancelLoading}>
          <span>Are you sure you want to cancel this invite?</span>
        </LoadingContainer>
      }
    />
  );
};

export default CancelInviteModal;
