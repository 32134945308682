import styled from "../Themes";

export const Label = styled<any>(`label`)`
  display: inline-block;
  user-select: none;
  cursor: ${({ loading }) => (loading ? "wait" : "pointer")};
  margin-bottom: 0;
`;

export const HiddenRadioButton = styled.input.attrs({ type: "radio" })`
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  opacity: 0;
`;

export const StyledRadioButton = styled<any>(`div`)`
  position: relative;
  top: 2px;
  float: left;
  width: 20px;
  height: 20px;
  border: 1px solid
    ${({ checked, disabled, loading, theme }) => {
      if (disabled || loading) {
        return checked ? theme.color.disabled[2] : theme.color.disabled[2];
      }
      if (!disabled) {
        return checked ? theme.color.primary[2] : theme.color.font[2];
      }
    }};
  border-radius: 10px;
  background: ${({ checked, disabled, loading, theme }) => {
    if (disabled || loading) {
      return theme.color_50.disabled[2];
    }
    if (!disabled) {
      return checked ? theme.color_50.primary[0] : "none";
    }
  }};
  transition: ${({ theme }) => theme.transition.m};

  ${/* sc-selector */ Label}:hover & {
    border: ${({ disabled, loading, theme }) => {
      if (disabled || loading) {
        return `1px solid ${theme.color.disabled[2]}`;
      }
      if (!disabled) {
        return `1px solid ${theme.color.primary[2]}`;
      }
    }};

    background: ${({ checked, disabled, loading, theme }) => {
      if (!disabled) {
        return checked ? theme.color_50.primary[2] : "none";
      }
    }};
  }
`;

export const Svg = styled(`svg`)`
  position: absolute;
  top: -1px;
  left: -1px;
`;

export const Path = styled<any>(`path`)`
  fill: ${({ checked, disabled, loading, theme }) => {
    if (disabled || loading) {
      return checked ? theme.color.disabled[2] : theme.color_50.disabled[2];
    }
    if (!disabled) {
      return checked ? theme.color_50.primary[0] : "none";
    }
  }};
  stroke: none;
  stroke-width: 2px;
  transition: all 0.6s ease;

  ${/* sc-selector */ Label}:hover & {
    stroke: ${({ disabled, loading, theme }) => (disabled || loading ? "none" : theme.color.primary[2])};
  }
`;

export const Circle = styled<any>(`circle`)`
  fill: ${({ disabled, loading, theme }) => (disabled || loading ? theme.color.disabled[4] : theme.color.font_bold[2])};
  r: ${({ checked }) => (checked ? "3" : "0")};
  transition: all 0.3s ease;
`;

export const LabelText = styled<any>(`span`)`
  margin-left: 8px;
  pointer-events: none;
  vertical-align: middle;
  color: ${({ disabled, theme }) => (disabled ? theme.color.disabled[2] : theme.color.font[2])};
`;
