import axios, { CancelTokenSource } from "axios";
import matchSorter from "match-sorter";
import React, { FC, useContext, useEffect, useState } from "react";
import Avatar from "react-avatar";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { useDebounce } from "use-debounce/lib";
import { postDemo } from "../../services/demo";
import { fetchEditAccount } from "../../services/editAccount";
import { fetchEditOrganisations, postEditOrganisation } from "../../services/editOrganisation";
import { updateOrganisationId, saveAccount, getAccount } from "../../services/localStorage";
import { fetchMyInvites } from "../../services/myInvites";
import ErrIcon from "../../svgs/ErrIcon";
import { getRole } from "../../util/checkRole";
import errToStr from "../../util/errToStr";
import { getLogo } from "../../util/getLogo";
import getNextRoute from "../../util/getNextRoute";
import isTouch from "../../util/isTouch";
import { DangerAlert } from "../Alerts";
import { PrimaryBtn } from "../Buttons";
import { FormInput, FormInputContainer } from "../FormComponents";
import { HorizontalLineWithText } from "../HorizontalLine";
import LoadingContainer from "../LoadingContainer";
import PendingInvitesModal from "../PendingInvitesModal";
import queryString from "query-string";
import { AlertIconContainer, AlertSpan } from "../RegisterScreen/styles";
import {
  Container,
  Section,
  LogoContainer,
  SelectOrgContainer,
  CreateOrgContainer,
  Heading,
  SelectOrgForm,
  CreateOrgForm,
  OrgList,
  OrgCard,
  OrgAvatar,
  OrgName,
  OrgDetails,
  OrgRoleHeading,
  OrgInfoCount,
  JoinDemoContainer,
  JoinDemoForm,
  OrgErrContainer,
} from "./styles";
import { fetchSensorExists } from "../../services/sensorExists";
import StateContext from "../StateContext";

const SelectOrganisationScreen: FC<any> = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { color } = useContext(ThemeContext);
  const { theme } = useContext(StateContext);

  const [accountInfo] = useState<any>(getAccount());

  const [pageLoading, setPageLoading] = useState<boolean>(true);

  const [orgs, setOrgs] = useState<any>([]);
  const [orgsErr, setOrgsErr] = useState<string>("");
  const [orgsLoading, setOrgsLoading] = useState<boolean>(false);
  const [orgsLoaded, setOrgsLoaded] = useState<boolean>(false);

  const [invites, setInvites] = useState<any>([]);
  const [invitesErr, setInvitesErr] = useState<string>("");
  const [invitesLoading, setInvitesLoading] = useState<boolean>(false);

  const [orgName, setOrgName] = useState<string>(accountInfo.fullName + " Organisation");
  const [createOrgErr, setCreateOrgErr] = useState<string>("");
  const [joinDemoErr, setJoinDemoErr] = useState<string>("");

  const [submitting, setSubmitting] = useState<boolean>(false);

  const [pendingInvitesModalOpen, setPendingInvitesModalOpen] = useState<boolean>(false);

  const [filteredOrgs, setFilteredOrgs] = useState<any>([]);
  const [filter, setFilter] = useState<string>("");

  const [nextRoute, setNextRoute] = useState<any>(undefined);

  const [filterValue] = useDebounce(filter, 250);

  const [checkedSensor, setCheckedSensor] = useState<boolean>(false);

  // Only show join demo button if not activating a sensor
  const [showJoinDemo] = useState<any>(() => {
    const params = queryString.parse(location.search);
    if (params && params.sensorId && params.activateSensor === "true") return false;
    else return true;
  });

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // check URL parameters for sensor path, if exists check if sensor belongs to user and select org for that sensor
  // then redirect to the sensor page skipping this screen
  useEffect(() => {
    const queryParams = queryString.parse(location.search);

    if (queryParams.path) {
      const { path } = queryParams;
      const pathComponents = path.toString().split("/");

      // Checks if there is a redirect to a sensor page based on the "path" query parameter
      if (pathComponents.length === 3) {
        // If redirecting to a keg/trackers page, check the sensor belongs to the user
        if (pathComponents[1] === "kegs" || pathComponents[1] === "trackers") {
          fetchSensorExists(source, pathComponents[2])
            .then((response) => {
              // If the sensor belongs to the user change to the org that sensor belongs to
              handleOrgChange(response.organisationId);
            })
            .catch((err) => {
              // If the sensor DOESN'T belong to the user redirect to the home page
              if (!axios.isCancel(err)) {
                setCheckedSensor(true);
              }
            });
        } else {
          setCheckedSensor(true);
        }
      } else {
        setCheckedSensor(true);
      }
    } else {
      setCheckedSensor(true);
    }
  }, []);

  useEffect(() => {
    fetchOrganisations();
  }, [checkedSensor]);

  useEffect(() => {
    if (orgsLoaded) {
      setInvitesLoading(true);
      fetchMyInvites(source)
        .then((response) => {
          if (response.length > 0) setPendingInvitesModalOpen(true);
          setInvites(response.sort((a: any, b: any) => b.invitedDate - a.invitedDate));
          setInvitesErr("");
          setInvitesLoading(false);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setInvitesErr(errToStr(err));
            setInvitesLoading(false);
          }
        });
    }
  }, [orgsLoaded]);

  // if user has 1 org, select this org automatically and continue to home or the path param route
  // else also load invites and display select org page
  const fetchOrganisations = () => {
    setOrgsLoading(true);
    setOrgsErr("");
    fetchEditOrganisations(source)
      .then((response) => {
        if (response.length === 1) {
          handleOrgChange(response[0].id);
        } else {
          const sorted = response.sort((a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
          setOrgs(sorted);
          setFilteredOrgs(sorted);
          setOrgsLoaded(true);
          setPageLoading(false);
        }
        setOrgsLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setOrgsErr(errToStr(err));
          setPageLoading(false);
          setOrgsLoading(false);
        }
      });
  };

  const handleOrgChange = (id: string) => {
    updateOrganisationId(id);

    setPageLoading(true);
    setOrgsErr("");
    fetchEditAccount(source)
      .then((response) => {
        saveAccount(response);
        getNextRoute(source, location, setNextRoute);
        const accountInfoUpdatedEvent = new Event("accountinfoupdated");
        document.dispatchEvent(accountInfoUpdatedEvent);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          updateOrganisationId(undefined);
          setOrgsErr(errToStr(err));
          setPageLoading(false);
        }
      });
  };

  const handleOrgCreation = () => {
    const valid = !!orgName;

    if (valid) {
      setSubmitting(true);
      postEditOrganisation(source, {
        name: orgName,
      })
        .then((response) => {
          handleOrgChange(response.id);
        })
        .catch((err) => {
          if (!axios.isCancel(err)) {
            setCreateOrgErr(errToStr(err));
            setSubmitting(false);
          }
        });
    } else {
      setCreateOrgErr("Organisation Name Required");
    }
  };

  const handleJoinDemo = () => {
    const body = {
      email: accountInfo.email,
    };

    setSubmitting(true);
    postDemo(source, body)
      .then((response) => {
        handleOrgChange(response.id);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setJoinDemoErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  // Filter out the places that don't match the filtered text
  useEffect(() => {
    const filtered = matchSorter(orgs, filterValue, {
      threshold: matchSorter.rankings.CONTAINS,
      keys: ["name"],
    });
    const sorted = filtered.sort((a: any, b: any) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    setFilteredOrgs(sorted);
  }, [filterValue]);

  const handleFilterChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFilter(e.target.value);
  };

  const handleNameChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setOrgName(e.target.value);
    setCreateOrgErr("");
  };

  if (nextRoute) {
    navigate(nextRoute);
  }

  if (pageLoading) {
    return <LoadingContainer loading={pageLoading} />;
  }

  return (
    <>
      <LoadingContainer loading={orgsLoading || invitesLoading || submitting}>
        <Container>
          <Section>
            {orgs.length > 0 ? (
              <>
                <LogoContainer>{getLogo(color, theme)}</LogoContainer>
                <SelectOrgContainer>
                  <Heading>Select Organisation</Heading>
                  {orgs.length > 5 && (
                    <SelectOrgForm noValidate onSubmit={(e) => e.preventDefault()}>
                      <FormInputContainer>
                        <FormInput placeholder="Filter..." type="text" onChange={handleFilterChange} value={filter} autoFocus={!isTouch()} />
                      </FormInputContainer>
                    </SelectOrgForm>
                  )}
                  <OrgList>
                    <div
                      style={{
                        position: "relative",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      {filteredOrgs.map((org: any) => (
                        <OrgCard onClick={() => handleOrgChange(org.id)} key={org.id}>
                          <OrgAvatar>
                            <Avatar name={org.name} src={org.orgPhotoUrl} size="50px" round={true} />
                          </OrgAvatar>
                          <OrgDetails>
                            <OrgName>{org.name}</OrgName>
                            <OrgRoleHeading>
                              Role: <OrgInfoCount>{getRole(org.role)}</OrgInfoCount>
                            </OrgRoleHeading>
                          </OrgDetails>
                        </OrgCard>
                      ))}
                    </div>
                  </OrgList>
                </SelectOrgContainer>
                {orgsErr && (
                  <OrgErrContainer>
                    <DangerAlert
                      style={{
                        margin: 0,
                      }}
                    >
                      <AlertIconContainer>
                        <ErrIcon fill={color.danger[2]} />
                      </AlertIconContainer>
                      <AlertSpan>{orgsErr}</AlertSpan>
                    </DangerAlert>
                  </OrgErrContainer>
                )}
              </>
            ) : (
              <>
                <Heading>Welcome {accountInfo.fullName} to</Heading>
                <LogoContainer>{getLogo(color, theme)}</LogoContainer>
                <CreateOrgContainer>
                  <Heading>Create Organisation</Heading>
                  <CreateOrgForm noValidate onSubmit={(e) => e.preventDefault()}>
                    <FormInputContainer>
                      <FormInput
                        style={{
                          marginBottom: "24px",
                        }}
                        placeholder="Organisation Name"
                        type="text"
                        onChange={handleNameChange}
                        value={orgName}
                        autoFocus={!isTouch()}
                      />
                    </FormInputContainer>
                    <PrimaryBtn onClick={handleOrgCreation} type="submit" width="100%">
                      Create
                    </PrimaryBtn>
                    {createOrgErr && (
                      <DangerAlert
                        style={{
                          margin: "24px 0 0",
                        }}
                      >
                        <AlertIconContainer>
                          <ErrIcon fill={color.danger[2]} />
                        </AlertIconContainer>
                        <AlertSpan>{createOrgErr}</AlertSpan>
                      </DangerAlert>
                    )}
                  </CreateOrgForm>
                </CreateOrgContainer>
              </>
            )}
            {!accountInfo.joinedDemo && showJoinDemo && (
              <JoinDemoContainer>
                <HorizontalLineWithText
                  style={{
                    margin: "24px 0",
                  }}
                >
                  Or
                </HorizontalLineWithText>
                <JoinDemoForm noValidate onSubmit={(e) => e.preventDefault()}>
                  <PrimaryBtn onClick={handleJoinDemo} type="submit" width="100%">
                    Join the demo organisation
                  </PrimaryBtn>
                  {joinDemoErr && (
                    <DangerAlert
                      style={{
                        margin: "24px 0 0",
                      }}
                    >
                      <AlertIconContainer>
                        <ErrIcon fill={color.danger[2]} />
                      </AlertIconContainer>
                      <AlertSpan>{joinDemoErr}</AlertSpan>
                    </DangerAlert>
                  )}
                </JoinDemoForm>
              </JoinDemoContainer>
            )}
            {invitesErr && (
              <DangerAlert
                style={{
                  margin: "24px 0 0",
                }}
              >
                <AlertIconContainer>
                  <ErrIcon fill={color.danger[2]} />
                </AlertIconContainer>
                <AlertSpan>{invitesErr}</AlertSpan>
              </DangerAlert>
            )}
          </Section>
        </Container>
      </LoadingContainer>
      {pendingInvitesModalOpen && invites.length > 0 && (
        <PendingInvitesModal
          invites={invites}
          setInvites={setInvites}
          onSuccess={fetchOrganisations}
          modalOpen={pendingInvitesModalOpen}
          setModalOpen={setPendingInvitesModalOpen}
        />
      )}
    </>
  );
};

export default SelectOrganisationScreen;
