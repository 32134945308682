import L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet.gridlayer.googlemutant/dist/Leaflet.GoogleMutant";
import "leaflet.fullscreen/Control.FullScreen";
import "leaflet.fullscreen/Control.FullScreen.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import React, { useEffect, FC, useState, useContext, useRef } from "react";
import { MapContainer, MapLoading } from "./styles";
import { INITIAL_VIEW_STATE } from "../../util/mapUtils";
import { ThemeContext } from "styled-components";
import LeafletStyling from "../GlobalStyles/leaflet";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import leafletShowAllButton from "../../util/leafletShowAllButton";
import { renderToStaticMarkup } from "react-dom/server";
import axios from "axios";
import { fetchPlaceMap } from "../../services/placeMap";
import errToStr from "../../util/errToStr";
import getMarkerIcon from "../../util/getMarkerIcon";
import ReactTimeago from "react-timeago";
import LoadingContainer from "../LoadingContainer";
import { kegOrTracker, kegsOrTrackers } from "../../util/kegOrTracker";
import { printTemp, printLength } from "../../util/formatUnits";

const PlaceMap: FC<any> = ({ id, filterDates, map, setMap, meta }) => {
  const navigate = useNavigate();

  const { color, map_style, long_datetime } = useContext(ThemeContext);

  const [mapControl, setMapControl] = useState<any>(undefined);
  const [mapLayer, setMapLayer] = useState<any>(undefined);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [activeLayer, setActiveLayer] = useState<string>("Map");
  const [bounds, setBounds] = useState<any>(undefined);

  const [placeMarkerState, setPlaceMarkerState] = useState<any>(undefined);
  const [placeCircleState, setPlaceCircleState] = useState<any>(undefined);
  const [sensorCluster, setSensorCluster] = useState<any>(undefined);

  const showAllBtn = useRef<any>(null);

  const [data, setData] = useState<any>(undefined);
  const [dataErr, setDataErr] = useState<string>("");
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  const [placeCluster, setPlaceCluster] = useState<any>(undefined);

  const [placeLayer, setPlaceLayer] = useState<any>(L.layerGroup());
  const [nearbyPlacesLayer, setNearbyPlacesLayer] = useState<any>(L.layerGroup());
  const [sensorsLayer, setSensorsLayer] = useState<any>(L.layerGroup());

  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  // Fetch data on mount and when meta changes because meta is updated when the place is edited
  useEffect(() => {
    const source = axios.CancelToken.source();

    // If end date is undefined set to current date
    const dates = {
      start: filterDates && filterDates.start !== undefined ? filterDates.start.unix() : undefined,
      end: filterDates && filterDates.end !== undefined ? filterDates.end.unix() : moment().unix(),
    };

    setDataLoading(true);
    setDataErr("");

    fetchPlaceMap(source, id, dates)
      .then((response) => {
        setData(response);
        setDataLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setDataErr(errToStr(err));
          setDataLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [id, filterDates, meta]);

  // load the Google Maps JavaScript API and initialise the map on page mount
  useEffect(() => {
    initLeaflet();

    return () => {
      if (map) {
        map.off();
      }
    };
  }, []);

  // On theme change, update the map's base layers and layer controls
  useEffect(() => {
    if (map) {
      createMapControl(map);
    }
  }, [map_style]);

  useEffect(() => {
    if (map && data) {
      leafletShowAllButton(showAllBtn, [data, ...data.sensors, ...data.places], map, setBounds);
      updateSensors(map);
      updatePlace(map);
      updateNearbyPlaces(map);
      fitBounds(map);
    }
  }, [map, data]);

  // Checks if the map container size changed and updates the map if so
  useEffect(() => {
    const mapDiv = document.getElementById("map");

    if (!mapDiv || !map) return;

    const observer = new ResizeObserver(() => {
      if (map) {
        map.invalidateSize();
      }
    });

    observer.observe(mapDiv);

    return () => {
      observer.unobserve(mapDiv);
      observer.disconnect();
    };
  }, [map]);

  const initLeaflet = () => {
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

    const newMap = L.map("map", {
      // @ts-ignore
      fullscreenControl: true,
      doubleClickZoom: true,
      worldCopyJump: true,
      attributionControl: false,
      maxBoundsViscosity: 1,
      maxBounds: new L.LatLngBounds(new L.LatLng(-90, -Infinity), new L.LatLng(90, Infinity)),
      center: [INITIAL_VIEW_STATE.latitude, INITIAL_VIEW_STATE.longitude],
      zoomControl: false,
      zoom: INITIAL_VIEW_STATE.zoom,
      minZoom: 3,
      gestureHandling: true,
    });

    L.control
      .zoom({
        position: "topleft",
      })
      .addTo(newMap);

    createMapControl(newMap);

    newMap.on("baselayerchange", (e: any) => {
      // If the user changes the baselayer, update the state with new layer and name
      setActiveLayer(e.name);
      setMapLayer(e.layer);
    });

    newMap.on("enterFullscreen", (e) => {
      setFullscreen(true);
    });

    newMap.on("exitFullscreen", (e) => {
      setFullscreen(false);
    });

    setMap(newMap);
  };

  const fitBounds = (myMap: any) => {
    if (data) {
      let sensorBounds = undefined;
      let placeBounds = undefined;
      let nearbyPlaceBounds = undefined;
      const bounds = new L.LatLngBounds([]);

      if (data.sensors.length > 0) {
        sensorBounds = new L.LatLngBounds(
          data.sensors.map((sensor: any) => {
            if (sensor.lat !== undefined && sensor.lng !== undefined) {
              return [sensor.lat, sensor.lng];
            }
          }, [])
        );
      }

      if (data.lat !== undefined && data.lng !== undefined) {
        const placeCircle = L.circle([data.lat, data.lng], { radius: data.radius });
        // To get the bounds of a circle it must be added to the map otherwise it will throw an error
        placeCircle.addTo(myMap);
        placeBounds = placeCircle.getBounds();
        placeCircle.remove();
      }

      if (data.places.length > 0) {
        nearbyPlaceBounds = new L.LatLngBounds(
          data.places.map((place: any) => {
            if (place.lat !== undefined && place.lng !== undefined) {
              return [place.lat, place.lng];
            }
          }, [])
        );
      }

      if (sensorBounds) {
        bounds.extend(sensorBounds);
      }
      if (placeBounds) {
        bounds.extend(placeBounds);
      }
      if (nearbyPlaceBounds) {
        bounds.extend(nearbyPlaceBounds);
      }

      if (bounds) {
        myMap.fitBounds(bounds, {
          padding: [50, 50],
          maxZoom: 18,
        });
      }
    }
  };

  const updatePlace = (myMap: any) => {
    if (placeLayer) placeLayer.clearLayers();

    if (placeMarkerState) {
      placeMarkerState.closePopup();
      placeMarkerState.remove();
      placeCircleState.remove();
    }

    if (placeCircleState) {
      placeCircleState.remove();
    }

    if (data) {
      // create place marker
      const placeMarker = L.marker([data.lat, data.lng], {
        title: data.placeName,
        icon: getMarkerIcon(color, 1, "place"),
        zIndexOffset: 1000,
      });

      // create place circle layer
      const placeCircle = L.layerGroup();

      // create place circle object
      const placeCircleObject = L.circle([data.lat, data.lng], {
        radius: data.radius,
        color: color.secondary[2],
        interactive: false,
      });

      placeMarker.bindPopup(createPlacePopup());

      placeCircle.addLayer(placeCircleObject);

      // add event listeners for marker to show/hide radius, popup, and zoom in to the marker's radius
      placeMarker.on("click", () => {
        placeMarker.openPopup();
      });

      placeMarker.on("dblclick", () => {
        myMap.fitBounds(placeCircleObject.getBounds());
      });

      myMap.on("click", () => {
        placeMarker.closePopup();
      });

      placeMarker.addTo(myMap);
      placeCircle.addTo(myMap);

      placeMarker.addTo(placeLayer);
      placeCircle.addTo(placeLayer);

      if (firstLoad) {
        placeMarker.openPopup();
        setFirstLoad(false);
      }

      // save everything to state so it can be removed later
      setPlaceMarkerState(placeMarker);
      setPlaceCircleState(placeCircle);
      setPlaceLayer(placeLayer);
    }
  };

  const updateNearbyPlaces = (map: any) => {
    // Remove the place cluster layer from the map if it exists
    if (map && placeCluster) {
      map.removeLayer(placeCluster);
    }

    if (nearbyPlacesLayer) nearbyPlacesLayer.clearLayers();

    const markers: any = [];

    if (data.places && data.places.length > 0) {
      const placeRadiusGroup = L.layerGroup().addTo(map);

      const newPlaceCluster = L.markerClusterGroup({
        spiderfyDistanceMultiplier: 1.5,
        spiderLegPolylineOptions: {
          weight: 1,
          color: color.font[2],
          opacity: 0.5,
        },
        polygonOptions: {
          color: color.nearbyPlace[2],
          fillColor: color.nearbyPlace[2],
        },
        iconCreateFunction: (cluster: any) => {
          return getMarkerIcon(color, cluster.getChildCount(), "nearbyPlaceCluster");
        },
      });

      for (let i = 0; i < data.places.length; i++) {
        const row = data.places[i];
        if (row.lat !== undefined && row.lng !== undefined) {
          const marker = L.marker(new L.LatLng(row.lat, row.lng), {
            title: row.name,
            icon: getMarkerIcon(color, 1, "nearbyPlace"),
            //@ts-ignore
            sensors: row.sensors,
          });

          const placeRadius = L.circle([row.lat, row.lng], {
            radius: row.radius,
            color: color.nearbyPlace[2],
          });
          marker.on("click", () => {
            placeRadiusGroup.clearLayers();
            placeRadiusGroup.addLayer(placeRadius);
          });
          marker.on("dblclick", () => {
            map.fitBounds(placeRadius.getBounds());
          });

          marker.bindPopup(createNearbyPlacePopup(row, placeRadiusGroup));
          newPlaceCluster.addLayer(marker);
          markers.push({
            lat: row.lat,
            lng: row.lng,
          });
        }
      }

      newPlaceCluster.addTo(nearbyPlacesLayer);
      placeRadiusGroup.addTo(nearbyPlacesLayer);

      map.on("click", () => {
        placeRadiusGroup.clearLayers();
      });

      map.addLayer(newPlaceCluster);
      setPlaceCluster(newPlaceCluster);
      setNearbyPlacesLayer(nearbyPlacesLayer);
    }
  };

  const updateSensors = (myMap: any) => {
    // Remove the sensor cluster layer from the map if it exists
    if (myMap && sensorCluster) {
      sensorCluster.clearLayers();
      myMap.removeLayer(sensorCluster);
    }

    if (sensorsLayer) sensorsLayer.clearLayers();

    if (data && data.sensors.length > 0) {
      const newSensorCluster = L.markerClusterGroup({
        spiderfyDistanceMultiplier: 1.5,
        spiderLegPolylineOptions: {
          weight: 1,
          color: color.font[2],
          opacity: 0.5,
        },
        polygonOptions: {
          color: color.primary[2],
          fillColor: color.primary[2],
        },
        iconCreateFunction: (cluster: any) => {
          return getMarkerIcon(color, cluster.getChildCount(), "sensorCluster");
        },
      });

      const sensorAccuracy = L.layerGroup().addTo(myMap);

      for (let i = 0; i < data.sensors.length; i++) {
        const row = data.sensors[i];
        if (row.lat !== undefined && row.lng !== undefined) {
          const sensorMarker = L.marker(new L.LatLng(row.lat, row.lng), {
            title: row.sensorId,
            icon: getMarkerIcon(color, 1, "sensor"),
          });

          const sensorCircle = L.circle([row.lat, row.lng], {
            radius: row.accuracy,
            color: color.primary[2],
          });

          sensorMarker.on("click", () => {
            sensorAccuracy.clearLayers();
            sensorAccuracy.addLayer(sensorCircle);
          });

          sensorMarker.on("dblclick", () => {
            myMap.fitBounds(sensorCircle.getBounds());
          });

          sensorMarker.bindPopup(createSensorPopup(row));
          newSensorCluster.addLayer(sensorMarker);
        }
      }

      newSensorCluster.addTo(sensorsLayer);
      sensorAccuracy.addTo(sensorsLayer);

      myMap.on("click", () => {
        sensorAccuracy.clearLayers();
      });

      myMap.addLayer(newSensorCluster);
      setSensorCluster(newSensorCluster);
      setSensorsLayer(sensorsLayer);
    }
  };

  const createPlacePopup = () => {
    const container = L.DomUtil.create("div");
    const name = L.DomUtil.create("span", "popup-title");
    const placeGroup = L.DomUtil.create("span", "popup-text");
    const radius = L.DomUtil.create("span", "popup-text");

    name.innerHTML = data.placeName;
    placeGroup.innerHTML = data.placeGroup ? `Place Type: ${data.placeGroup}` : "";
    radius.innerHTML = `Radius: ${printLength(data.radius)}`;

    container.appendChild(name);
    container.appendChild(placeGroup);
    container.appendChild(radius);

    return L.popup().setContent(container);
  };

  const createNearbyPlacePopup = (row: any, placeRadiusGroup: any) => {
    const container = L.DomUtil.create("div");
    const name = L.DomUtil.create("span", "popup-title-link");
    const placeGroup = L.DomUtil.create("span", "popup-text");
    const radius = L.DomUtil.create("span", "popup-text");

    name.addEventListener("mouseup", (e: any) => {
      if (placeRadiusGroup) placeRadiusGroup.clearLayers();
      if (e.which === 2) window.open(`/places/${row.id}`, "_blank");
      else navigate(`/places/${row.id}`);
    });

    name.innerHTML = row.name;
    placeGroup.innerHTML = row.placeGroup ? `Place Type: ${row.placeGroup}` : "";
    radius.innerHTML = `Radius: ${printLength(row.radius)}`;

    container.appendChild(name);
    container.appendChild(placeGroup);
    container.appendChild(radius);

    return L.popup().setContent(container);
  };

  const createSensorPopup = (sensor: any) => {
    const container = L.DomUtil.create("div");
    const sensorId = L.DomUtil.create("span", "popup-title-link");
    const assetType = L.DomUtil.create("span", "popup-text");
    const trackerTags = L.DomUtil.create("span", "popup-text");
    const lastSeen = L.DomUtil.create("span", "popup-text");
    const temperature = L.DomUtil.create("span", "popup-text");
    const url = kegOrTracker("kegs", "trackers");

    sensorId.addEventListener("mouseup", (e: any) => {
      if (e.which === 2) window.open(`/${url}/${sensor.sensorId}`, "_blank");
      else navigate(`/${url}/${sensor.sensorId}`);
    });

    sensorId.innerHTML = sensor.sensorName ? `${sensor.sensorName} (${sensor.sensorId})` : sensor.sensorId;
    assetType.innerHTML = sensor.assetTypeName ? `Asset Type: ${sensor.assetTypeName}` : "";
    trackerTags.innerHTML =
      sensor.trackerTags && sensor.trackerTags.length > 0
        ? `${kegsOrTrackers("Kegs Tags", "Tracker Tags")}: ${sensor.trackerTags.map((tag: any) => tag.name).join(", ")}`
        : "";
    lastSeen.innerHTML = `Last Seen: ${renderToStaticMarkup(
      <ReactTimeago live={false} date={sensor.lastSeen * 1000} title={moment.unix(sensor.lastSeen).format(long_datetime)} />
    )}`;
    temperature.innerHTML = sensor.temperature !== undefined ? `Temperature: ${printTemp(sensor.temperature)}` : ``;

    container.appendChild(sensorId);
    container.appendChild(assetType);
    container.appendChild(trackerTags);
    container.appendChild(lastSeen);
    container.appendChild(temperature);

    return L.popup().setContent(container);
  };

  const createMapControl = (currentMap: any) => {
    const roadLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "roadmap",
      styles: map_style,
    });

    const terrainLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "terrain",
      styles: map_style,
    });

    const satelliteLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "satellite",
    });

    const hybridLayer = L.gridLayer.googleMutant({
      maxZoom: 22,
      type: "hybrid",
    });

    const layers: any = {
      Map: roadLayer,
      Terrain: terrainLayer,
      Satellite: satelliteLayer,
      Hybrid: hybridLayer,
    };

    const overlays: any = {
      Place: placeLayer,
      "Nearby Places": nearbyPlacesLayer,
      [kegsOrTrackers("Kegs", "Trackers")]: sensorsLayer,
    };

    const control = L.control.layers(layers, overlays, { sortLayers: true, position: "topright" });

    // Remove old baselayer/control from map if set in state
    if (mapControl) currentMap.removeControl(mapControl);
    if (mapLayer) currentMap.removeLayer(mapLayer);

    // Add new baselayer/control to map
    layers[activeLayer].addTo(currentMap);
    placeLayer.addTo(currentMap);
    nearbyPlacesLayer.addTo(currentMap);
    sensorsLayer.addTo(currentMap);

    control.addTo(currentMap);

    // Store new baselayer/control in state
    setMapLayer(layers[activeLayer]);
    setMapControl(control);
  };

  if (dataErr) {
    return <LoadingContainer err={dataErr}></LoadingContainer>;
  }

  return (
    <>
      <LeafletStyling fullscreen={fullscreen} />
      <MapContainer id="map"></MapContainer>
      {dataLoading && <MapLoading>Loading...</MapLoading>}
    </>
  );
};

export default PlaceMap;
