import styled from "../Themes";

export const Bar = styled<any>(`div`)`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: ${({ open }) => (open ? "160px" : "50px")};
  height: 100%;
  background-color: ${({ theme }) => theme.color.panel_bg[2]};
  z-index: 1000250;
  border-right: 1px solid ${({ theme }) => theme.color.subtle_border[2]};
  padding-top: 50px;

  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }

  @media (max-width: 576px) {
    left: ${({ open }) => (open ? "0" : "-1000%")};
    width: 160px;
  }
`;

export const TooltipTrigger = styled<any>(`div`)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;

  border-left: 3px outset transparent;
  border-left-color: ${({ theme, active }) => (active ? theme.color.primary[2] : "transparent")};
  box-shadow: inset 8px 0 8px -10px ${({ theme, active }) => (active ? theme.color.primary[2] : "transparent")};

  &:hover {
    border-left-color: ${({ theme, active }) => (active ? theme.color.primary[2] : theme.color.border[2])};
    box-shadow: inset 8px 0 8px -10px ${({ theme, active }) => (active ? theme.color.primary[2] : "transparent")};
  }
`;

export const IconContainer = styled<any>(`div`)`
  width: 20px;
  height: 20px;
  margin-right: ${({ open }) => (open ? "8px" : "0")};
  margin-left: -1px;
  flex: 0 0 20px;

  &:hover {
    svg {
      outline: ${({ theme }) => theme.color.font_bold[2]} !important;
    }
  }
`;

export const Button = styled<any>("button")`
  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  float: left;

  border-left: 3px outset transparent;
  outline: none;
  cursor: pointer;
  fill: ${({ theme }) => theme.color.side_nav_inactive[2]};
`;

export const MenuLabel = styled<any>(`span`)`
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  font-weight: 500;
`;

export const CloseMenuSection = styled<any>(`div`)`
  display: none;

  @media (max-width: 576px) {
    display: ${({ open }) => (open ? "block" : "none")};
    width: 100vw;
    height: 100vh;
    position: fixed;
    cursor: pointer;
    left: 160px;
    background-color: hsla(0, 0%, 0%, 0.45);
  }
`;
