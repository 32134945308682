import styled from "../Themes";
import { Link } from "react-router-dom";

export const LogoContainer = styled("div")`
  width: 100%;
  max-width: 300px;
  margin-bottom: 48px;
`;

export const SignInContainer = styled(`div`)`
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
`;

export const ForgotPasswordLink = styled(Link)`
  float: right;
  font-size: 0.8rem;
  line-height: 1.5rem;
`;
