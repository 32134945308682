import React, { FC, useState, useEffect, useContext } from "react";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";
import Bold from "../Bold";
import { FormError, FormInput, InfoIconContainer } from "../FormComponents";
import { exists, validName, maxLength } from "../../util/formValidations";
import { ModalFormContainer } from "../Modal/styles";
import matchSorter from "match-sorter";
import { Link } from "react-router-dom";
import Table from "../Table";
import Checkbox from "../Checkbox";
import axios, { CancelTokenSource } from "axios";
import errToStr from "../../util/errToStr";
import ColorPicker from "../ColorPicker";
import { ThemeContext } from "styled-components";
import { kegOrTracker, kegsOrTrackers } from "../../util/kegOrTracker";
import { postAssetTypes, putAssetTypes } from "../../services/editAssetTypes";
import AssetTypeLabel from "../AssetTypeLabel";
import { icons } from "../ReactIcon";
import { Select } from "../Select";
import { getTrackers } from "../../services/trackers";
import { getAccount } from "../../services/localStorage";

const defaultAssetType = {
  id: null,
  name: "",
  colour: "",
  description: "",
  icon: null,
};

const initAssetType = (assetType: any, echarts_palette: any, color: any) => {
  if (assetType) {
    const assetTypeIcon = icons.find((icon: any) => icon.value === assetType.icon);
    return {
      ...defaultAssetType,
      ...assetType,
      icon: assetTypeIcon
        ? {
            label: assetTypeIcon.label,
            value: assetTypeIcon.value,
            icon: assetTypeIcon.value,
            colour: assetType.colour,
          }
        : null,
    };
  } else {
    return { ...defaultAssetType, colour: echarts_palette[Math.floor(Math.random() * echarts_palette.length)] };
  }
};

const formatIconOptions = (colour: string) => {
  return icons.map((icon: any) => ({
    label: icon.label,
    value: icon.value,
    icon: icon.value,
    colour,
  }));
};

const EditAssetTypeModel: FC<any> = ({ assetType, fetch, modalOpen, setModalOpen }) => {
  const { color, echarts_palette } = useContext(ThemeContext);

  const [formData, setFormData] = useState<any>(initAssetType(assetType, echarts_palette, color));
  const [formErrors, setFormErrors] = useState<any>({});

  const [trackers, setTrackers] = useState<any>([]);
  const [trackersErr, setTrackersErr] = useState<string>("");
  const [trackersLoading, setTrackersLoading] = useState<boolean>(false);

  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [trackerChanges, setTrackerChanges] = useState<any>({});

  const [filterOptions, setFilterOptions] = useState<any>({
    assetTypeNames: [],
    trackerTypeNames: [],
  });

  const [iconOptions, setIconOptions] = useState<any>(formatIconOptions(formData.colour));

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setTrackersLoading(true);
    getTrackers(source, { filters: `organisationId eq ${getAccount().organisationId}` })
      .then((response) => {
        // Sets selected and initSelected property to indicate if trackers are included in the asset type or not and to sort the rows
        // by initSelected to prevent the rows bouncing around when editing
        setTrackers(
          response.data.map((tracker: any) => ({
            ...tracker,
            selected: tracker.assetTypeId === formData.id,
            initSelected: tracker.assetTypeId === formData.id,
          }))
        );
        setTrackersLoading(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setTrackersErr(errToStr(err));
          setTrackersLoading(false);
        }
      });

    return () => {
      source.cancel();
    };
  }, [formData.id, source]);

  // Get distinct strings for use in the select input filters when trackers changes
  useEffect(() => {
    if (Array.isArray(trackers)) {
      const assetTypeNames = new Set();
      const trackerTypeNames = new Set();

      for (let i = 0; i < trackers.length; i++) {
        if (trackers[i].assetTypeName) assetTypeNames.add(trackers[i].assetTypeName);
        if (trackers[i].trackerTypeName) trackerTypeNames.add(trackers[i].trackerTypeName);
      }

      setFilterOptions({
        assetTypeNames: Array.from(assetTypeNames).sort(),
        trackerTypeNames: Array.from(trackerTypeNames).sort(),
      });
    }
  }, [trackers]);

  // When the colour input changes update the icon options and the selected icon colour
  useEffect(() => {
    setIconOptions(formatIconOptions(formData.colour));
    if (formData.icon && formData.icon.value) {
      setFormData((prev: any) => ({
        ...prev,
        icon: {
          label: prev.icon.label,
          value: prev.icon.value,
          icon: prev.icon.value,
          colour: formData.colour,
        },
      }));
    }
  }, [formData.colour]);

  const validateForm = () => {
    const names = Object.keys(formData);
    let allValid = true;
    let currValid = true;

    for (let i = 0; i < names.length; i++) {
      const name = names[i];
      const value = formData[names[i]];

      switch (name) {
        case "name":
          currValid = exists(name, value, setFormErrors) && maxLength(name, value, 200, setFormErrors) && validName(name, value, setFormErrors);
          break;

        case "colour":
          currValid = exists(name, value, setFormErrors);
          break;

        case "description":
          currValid = maxLength(name, value, 512, setFormErrors);
          break;

        case "icon":
          currValid = exists(name, value ? value.value : null, setFormErrors);
          break;

        default:
          currValid = true;
      }
      allValid = allValid && currValid;
    }
    return allValid;
  };

  const formatFormData = () => {
    const trackersChangesArr = Object.values(trackerChanges);

    const addTrackerIds: any = [];
    const removeTrackerIds: any = [];

    trackersChangesArr.forEach((tracker: any) => {
      // If the tracker is selected it's asset type will be updated
      if (tracker.selected) addTrackerIds.push(tracker.id);

      // If the tracker is not selected and we are editing an existing asset type and we have
      // de-selected a tracker within the current asset type then remove the tracker from the asset type
      if (!tracker.selected && formData.id && tracker.assetTypeId === formData.id) removeTrackerIds.push(tracker.id);
    });

    const formattedData: any = {
      name: formData.name,
      colour: formData.colour,
      description: formData.description,
      icon: formData.icon && formData.icon.value !== undefined ? formData.icon.value : "",
      addTrackerIds,
      removeTrackerIds,
    };

    return formattedData;
  };

  const handleSubmit = () => {
    const body = formatFormData();
    const valid = validateForm();

    if (valid) {
      // If the asset type has an id then we are editing it
      if (formData.id) {
        setSubmitting(true);
        putAssetTypes(source, body, formData.id)
          .then(() => {
            if (fetch) fetch();
            setSubmittedMsg("Asset Type Updated");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      } else {
        setSubmitting(true);
        postAssetTypes(source, body)
          .then(() => {
            if (fetch) fetch();
            setSubmittedMsg("Asset Type Created");
            setSubmitting(false);
          })
          .catch((err) => {
            if (!axios.isCancel(err)) {
              setSubmittingErr(errToStr(err));
              setSubmitting(false);
            }
          });
      }
    }
  };

  const handleChange = (e: any) => {
    e.persist();
    e.preventDefault();
    setFormData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
    setFormErrors((prev: any) => ({ ...prev, [e.target.name]: undefined }));
  };

  const handleCheckboxChange = (e: any) => {
    e.persist();
    const index = e.target.name;
    const tracker = trackers[index];

    if (tracker) {
      setTrackerChanges((prev: any) => {
        const curr = JSON.parse(JSON.stringify(prev));
        curr[tracker.id] = tracker;
        return curr;
      });
      setTrackers((prev: any) => {
        const curr = [...prev];
        curr[index].selected = e.target.checked;
        return curr;
      });
    }
  };

  const columns: any = [];

  columns.push({
    id: "selected",
    Header: "Selected",
    accessor: "selected",
    minWidth: 120,
    maxWidth: 120,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "true") {
        return row[filter.id] === true;
      }
      if (filter.value === "false") {
        return row[filter.id] === false;
      }
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="true">True</option>
        <option value="false">False</option>
      </select>
    ),
    Cell: (props: any) => <Checkbox onChange={handleCheckboxChange} name={props.index} checked={props.value} />,
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
  });

  columns.push({
    id: "id",
    Header: kegsOrTrackers("Keg", "Tracker"),
    accessor: "id",
    Cell: (props: any) => {
      const url = kegOrTracker("kegs", "trackers");
      let title = props.value ? props.value : "";
      title += `${props.original.nickname ? " (" + props.original.nickname + ")" : ""}`;

      return (
        <Link to={`/${url}/${props.value}`} title={title}>
          {title}
        </Link>
      );
    },
    Footer: ({ data }: any) => <Bold>Total: {data.length}</Bold>,
    minWidth: 130,
  });

  columns.push({
    id: "trackerTypeName",
    Header: "Tracker Type",
    accessor: "trackerTypeName",
    minWidth: 160,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "none") {
        return row[filter.id] === undefined;
      }
      return row[filter.id] === filter.value;
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="none">None</option>
        {filterOptions.trackerTypeNames.map((label: any) => {
          return (
            <option key={label} value={label}>
              {label}
            </option>
          );
        }, [])}
      </select>
    ),
  });

  columns.push({
    id: "assetTypeName",
    Header: "Asset Type",
    accessor: "assetTypeName",
    minWidth: 140,
    maxWidth: 140,
    filterMethod: (filter: any, row: any) => {
      if (filter.value === "all") {
        return true;
      }
      if (filter.value === "none") {
        return row[filter.id] === undefined;
      }
      return row[filter.id] === filter.value;
    },
    Filter: ({ filter, onChange }: any) => (
      <select onChange={(event) => onChange(event.target.value)} style={{ width: "100%" }} value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        <option value="none">None</option>
        {filterOptions.assetTypeNames.map((label: any) => {
          return (
            <option key={label} value={label}>
              {label}
            </option>
          );
        }, [])}
      </select>
    ),
    Cell: (props: any) => (
      <div style={{ display: "flex", height: "100%", justifyContent: "center", alignItems: "center" }}>
        <AssetTypeLabel name={props.value} colour={props.original.assetTypeColour} icon={props.original.assetTypeIcon} />
      </div>
    ),
  });

  // exists here to sort the rows by selected and prevent them bouncing around when editing
  columns.push({
    id: "initSelected",
    accessor: "initSelected",
    show: false,
  });

  const defaultSorted = [
    {
      id: "initSelected",
      desc: true,
    },
    {
      id: "id",
      desc: false,
    },
  ];

  const handleSelectChange = (selected: any, action: any) => {
    setFormData((prev: any) => ({ ...prev, [action.name]: selected }));
    setFormErrors((prev: any) => ({ ...prev, [action.name]: undefined }));
  };

  return (
    <>
      <SubmitModal
        isOpen={modalOpen}
        onSubmit={() => handleSubmit()}
        onClose={() => {
          if (!submitting) setModalOpen(false);
        }}
        size={!submittedMsg && !submittingErr ? "lg" : "sm"}
        title={formData.id ? "Edit Asset Type" : "Create Asset Type"}
        success={submittedMsg}
        error={submittingErr}
        submitBtnText={formData.id ? "Update" : "Create"}
        body={
          <LoadingContainer loading={trackersLoading || submitting} err={trackersErr}>
            <form noValidate onSubmit={(e) => e.preventDefault()}>
              <ModalFormContainer>
                <label>Type Name</label>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "100%" }}>
                    <FormInput type="text" name="name" value={formData.name} error={formErrors.name} onChange={handleChange} />
                  </div>
                  <div style={{ width: "100%", maxWidth: "34px" }}>
                    <ColorPicker color={formData.colour} setColor={(color: any) => setFormData((prev: any) => ({ ...prev, colour: color.hex }))} />
                  </div>
                </div>
                <FormError error={formErrors.name || formErrors.colour}>
                  <span>{formErrors.name}</span>
                  <span style={{ position: "absolute", right: "20px" }}>{formErrors.colour}</span>
                </FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label>Description</label>
                <FormInput type="text" name="description" value={formData.description} error={formErrors.description} onChange={handleChange} />
                <FormError error={formErrors.description}>{formErrors.description}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <label>Icon</label>
                <Select name="icon" isError={formErrors.icon} value={formData.icon} onChange={handleSelectChange} placeholder="Select" options={iconOptions} />
                <FormError error={formErrors.icon}>{formErrors.icon}</FormError>
              </ModalFormContainer>
              <ModalFormContainer>
                <Table
                  loading={trackersLoading}
                  filterable={true}
                  style={{ clear: "both" }}
                  data={trackers}
                  columns={columns}
                  defaultSorted={defaultSorted}
                  defaultPageSize={10}
                />
              </ModalFormContainer>
            </form>
          </LoadingContainer>
        }
      />
    </>
  );
};

export default EditAssetTypeModel;
