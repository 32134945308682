import axios, { CancelTokenSource } from "axios";
import { FC, useEffect, useState } from "react";
import { markAsCollectedPublic } from "../../services/pickupRequests";
import { checkDomain } from "../../util/checkDomain";
import errToStr from "../../util/errToStr";
import isMobileDevice from "../../util/isMobileDevice";
import LoadingContainer from "../LoadingContainer";
import { SubmitModal } from "../Modal";

const PublicMarkAsCollectedModel: FC<any> = ({ scanCode, onSuccess, modalOpen, setModalOpen }) => {
  const [submittedMsg, setSubmittedMsg] = useState<string>("");
  const [submittingErr, setSubmittingErr] = useState<string>("");
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [coords, setCoords] = useState<any>({});

  const [source] = useState<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      source.cancel();
    };
  }, [source]);

  // If user is on a mobile device, get their location to update the tracker's location
  useEffect(() => {
    if (isMobileDevice()) {
      navigator.geolocation.getCurrentPosition((pos: any) => {
        if (pos.coords) setCoords(pos.coords);
      });
    }
  }, [source]);

  const formatFormData = () => {
    const formattedData: any = {
      scanCode,
      phoneLatitude: coords.latitude,
      phoneLongitude: coords.longitude,
      PhoneAccuracy: Math.round(coords.accuracy) || null,
    };

    return formattedData;
  };

  const handleSubmit = () => {
    const body = formatFormData();

    setSubmitting(true);
    markAsCollectedPublic(source, body)
      .then(() => {
        if (onSuccess) onSuccess();
        setSubmittedMsg("Marked as Collected");
        setSubmittingErr("");
        setSubmitting(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          setSubmittingErr(errToStr(err));
          setSubmitting(false);
        }
      });
  };

  return (
    <SubmitModal
      isOpen={modalOpen}
      onSubmit={() => handleSubmit()}
      onClose={() => {
        if (!submitting) {
          setSubmittingErr("");
          setModalOpen(false);
        }
      }}
      title="Mark as Collected"
      success={submittedMsg}
      error={submittingErr}
      submitBtnText="Yes"
      body={
        <LoadingContainer loading={submitting}>
          <form noValidate onSubmit={(e) => e.preventDefault()}>
            <span>Are you sure you want to mark this {checkDomain("tracker", "keg", "tracker", "asset")} as collected?</span>
          </form>
        </LoadingContainer>
      }
    />
  );
};

export default PublicMarkAsCollectedModel;
