import { createGlobalStyle } from "../Themes";

export const PhoneNumberInputStyles = createGlobalStyle<any>`
  .PhoneInput {
    transition: all 250ms;
    width: 100%;
    text-align: left;
    border: 1px solid
    ${({ theme, error }) => (error ? theme.color.error[2] : theme.color_30.panel_fg[0])};
    background: ${({ theme }) => theme.color.input_bg[2]};
    color: ${({ theme }) => theme.color.font_bold[2]};
    padding: 7px 7px;
    border-radius: 3px;
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: ${({ theme }) => theme.fontSize.m};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    min-height: 34px;
    outline: none;

    ::placeholder {
      opacity: 0.5;
    }

    &:hover {
      border: 1px solid ${({ theme, error }) => (error ? theme.color.error[2] : theme.color_80.panel_fg[0])};
    }

    :focus {
      box-shadow: 0 0 0 1px ${({ theme }) => theme.color.primary[2]};
    }

    :disabled {
      opacity: 0.33;
    }
  }

  .PhoneInputInput {
    border: none;
    background: none;
    color: ${({ theme }) => theme.color.font_bold[2]};
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: ${({ theme }) => theme.fontSize.m};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    outline: none;

    ::placeholder {
      opacity: 0.5;
    }

    :disabled {
      opacity: 0.33;
    }
  }

  .PhoneInputCountrySelect {
    background: ${({ theme }) => theme.color.input_bg[2]};
    color: ${({ theme }) => theme.color.font_bold[2]};
    border-radius: 3px;
    font-family: ${({ theme }) => theme.fontFamily.body};
    font-size: ${({ theme }) => theme.fontSize.m};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
`;
