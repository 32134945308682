import axios, { CancelTokenSource } from "axios";
import { getAccount } from "../localStorage";

type FetchPlaceAllocationsProps = {
  pageIndex?: number;
  pageSize?: number;
  orderBy?: any;
  filters?: any;
};

export const fetchAllPlaceAllocations = async (source: CancelTokenSource, { pageIndex, pageSize, orderBy, filters }: FetchPlaceAllocationsProps) => {
  const params: any = {
    organisationId: getAccount().organisationId,
    count: true,
  };

  if (pageIndex !== undefined && pageSize !== undefined) params.skip = pageIndex * pageSize;

  if (pageSize !== undefined) params.top = pageSize;

  if (orderBy && orderBy.length > 0) params.orderby = orderBy.map((order: any) => `${order.id} ${order.desc ? "desc" : "asc"}`).join(", ");

  if (filters) params.filter = filters;

  const { data, headers } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + "/v1/placeallocations",
    params,
  });

  return { count: headers?.count, data };
};

export const fetchPlaceAllocations = async (
  source: CancelTokenSource,
  placeId: number,
  { pageIndex, pageSize, orderBy, filters }: FetchPlaceAllocationsProps
) => {
  const params: any = {
    organisationId: getAccount().organisationId,
    count: true,
  };

  if (pageIndex !== undefined && pageSize !== undefined) params.skip = pageIndex * pageSize;

  if (pageSize !== undefined) params.top = pageSize;

  if (orderBy && orderBy.length > 0) params.orderby = orderBy.map((order: any) => `${order.id} ${order.desc ? "desc" : "asc"}`).join(", ");

  if (filters) params.filter = filters;

  const { data, headers } = await axios({
    cancelToken: source.token,
    method: "get",
    url: process.env.REACT_APP_API_URL + `/v1/placeallocations/${placeId ? placeId : ""}`,
    params,
  });

  return { count: headers?.count, data };
};

export const postPlaceAllocation = async (source: CancelTokenSource, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "post",
    url: process.env.REACT_APP_API_URL + "/v1/placeallocations",
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};

export const putPlaceAllocation = async (source: CancelTokenSource, id: string, body: any) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "put",
    url: process.env.REACT_APP_API_URL + "/v1/placeallocations",
    data: JSON.stringify({
      organisationId: getAccount().organisationId,
      ...body,
    }),
  });

  return data;
};

export const deletePlaceAllocation = async (source: CancelTokenSource, id: string) => {
  const { data } = await axios({
    cancelToken: source.token,
    method: "delete",
    url: process.env.REACT_APP_API_URL + `/v1/placeallocations/${id ? id : ""}`,
  });

  return data;
};
