import styled from "../Themes";

export const Section = styled("section")`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background-image: radial-gradient(circle at top center, rgba(0, 0, 0, 0) 50%, ${({ theme }) => theme.color_50.sign_in_bg[2]} 75%);
`;

export const GitLink = styled(`span`)`
  display: block;
  font-size: 16px;
  margin: 8px;
  font-weight: 500;
`;
